<template>
    <div id="calendar-picker" class="vdp-datepicker adjust-datepicker datepicker-close-main">
        <div>
            <input
                type="text"
                class="search__input input-search input-search-date"
                name="search__input-date"
                id="search__input-date"
                :placeholder="placeholder"
                clear-button="false"
                readonly="true"
                autocomplete="off"
                v-on:click="showCalendar"
                v-model="formatDate"
            />
        </div>
        <div id="calendar-picker__calendar" class="vdp-datepicker__calendar">
            <!-- カレンダーヘッダ -->
            <header>
                <span :class="{ prev: true, disabled: prevDisabled }" v-on:click="setLastMonth">＜</span>
                <span class="day__month_btn">{{ year }}年{{ month }}月</span>
                <span :class="{ next: true, disabled: nextDisabled }" v-on:click="setNextMonth">＞</span>
            </header>
            <div>
                <!-- 曜日 -->
                <template v-for="(dayname, index) in weekdays" :key="index">
                    <span class="cell day-header">{{ dayname }}</span>
                </template>
                <!-- 日付 -->
                <template v-for="(data, wIndex) in calendarData" :key="wIndex">
                    <span :class="calendarClass(data)" v-on:click="dateClick(data.dep_date)">{{ data.day }}</span>
                </template>
            </div>
            <div class="calender-set__buttons">
                <button id="btn-calendar-noday" class="btn btn-calendar-noday input-set__cansel" v-on:click="unspecifiedDate">日付指定なし</button>
                <button id="btn-calendar-close" class="btn btn-calendar-close input-set__cansel" v-on:click="hideCalendar">閉じる</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, computed, watch, onMounted } from 'vue'
import { DEP_STATUS_NORMAL, DEP_STATUS_DOCK } from '@/lib/constants'
import { isNull, nextMonth, castStringToDate } from '@/lib/util'
import $ from 'jquery'

export default defineComponent({
    name: 'CalendarPicker',
    emits: ['update:modelValue'],
    props: ['modelValue', 'placeholder'],

    setup(props, ctx) {
        /**
         * data
         */
        const weekdays = ['日', '月', '火', '水', '木', '金', '土']
        const year: Ref<number> = ref(2020)
        const month: Ref<number> = ref(3)
        const today: Ref<any> = ref(null)
        const calendarData: Ref<any> = ref([])

        /**
         * computed
         */
        /** ローカル変更用の値 */
        const localValue = computed<any>({
            get() {
                return props.modelValue
            },
            set(newVal: any) {
                ctx.emit('update:modelValue', newVal)
            },
        })
        /** フォーマットした年月日 */
        const formatDate = computed(() => {
            if (!localValue.value) {
                return null
            }

            const dayOFWeek = localValue.value.getDay()
            const dayOfWeekJp = ['日', '月', '火', '水', '木', '金', '土']

            const y = localValue.value.getFullYear()
            const m = ('0' + (localValue.value.getMonth() + 1)).slice(-2)
            const d = ('0' + localValue.value.getDate()).slice(-2)
            return `${y}年 ${m}月 ${d}日（${dayOfWeekJp[dayOFWeek]}）`
        })
        /**
         * 先月選択ボタンの制御（現在より前の月は表示しない）
         */
        const prevDisabled = computed(() => {
            const date = new Date()
            const y = date.getFullYear()
            const m = date.getMonth() + 1

            return y == year.value && m == month.value
        })
        /**
         * 翌月選択ボタンの制御（6ヶ月先までは表示）
         */
        const nextDisabled = computed(() => {
            const date = new Date()
            const maxDate = new Date(date.setMonth(date.getMonth() + 6))
            const y = maxDate.getFullYear()
            const m = maxDate.getMonth() + 1

            return y == year.value && m == month.value
        })

        /**
         * watch
         */
        watch(month, () => {
            createCalendar()
        })

        /**
         * methods
         */

        /**
         * 日付フォーマット
         * @param {*} date:日付
         * @param {*} zero_padding:0埋め対応
         * @param {*} join_type:0:'-'区切り, 1:'/'区切り, 2:年月日, 3:月日, 4:'/'区切り月日
         * @param {*} week_type:0:曜日無し, 1:日本語
         */
        const dateFormat = (date: Date | null): string => {
            let y = ''
            let m = ''
            let d = ''
            let week = ''
            if (isNull(date)) {
                return ''
            }
            y = date!.getFullYear() + ''
            m = ('00' + (date!.getMonth() + 1)).slice(-2)
            d = ('00' + date!.getDate()).slice(-2)

            return `${y + '-' + m + '-' + d + week}`
        }

        /**
         * 対象月のカレンダーを作成
         */
        const createCalendar = () => {
            console.log(year.value + '-' + month.value + 'のデータ作成')
            calendarData.value = []

            // 初日の曜日を取得
            var firstDay = new Date(year.value, month.value - 1, 1)
            const firstWeekDay = firstDay.getDay()
            // 月の日数
            const lastDay = new Date(year.value, month.value, 0)

            for (var i = 0; i < firstWeekDay; i++) {
                calendarData.value.push({
                    day: null,
                    dep_date: null,
                    dep_status: null,
                    empty_status: null,
                    ship_schedule_id: null,
                })
            }
            while (firstDay <= lastDay) {
                calendarData.value.push({
                    day: firstDay.getDate(),
                    dep_date: new Date(firstDay.getTime()),
                    dep_status: null,
                    empty_status: null,
                    ship_schedule_id: null,
                })
                firstDay.setDate(firstDay.getDate() + 1)
            }
            return calendarData.value
        }

        /**
         * 先月のカレンダーを取得
         */
        const setLastMonth = () => {
            if (prevDisabled.value) {
                return
            }

            if (month.value === 1) {
                year.value -= 1
                month.value = 12
            } else {
                month.value -= 1
            }
        }

        /**
         * 翌月のカレンダーを取得
         */
        const setNextMonth = () => {
            if (nextDisabled.value) {
                return
            }

            if (month.value === 12) {
                year.value += 1
                month.value = 1
            } else {
                month.value += 1
            }
        }

        /**
         * カレンダー日付クリック時の処理
         * @param depDate
         */
        const dateClick = (depDate: any) => {
            if (depDate == '' || depDate == null) {
                return
            }
            localValue.value = depDate
            hideCalendar()
        }

        /**
         * 日付指定無し
         */
        const unspecifiedDate = () => {
            localValue.value = null
            hideCalendar()
        }

        /**
         * クラス情報の設定
         * @param dateInfo
         */
        const calendarClass = (dateInfo: any) => {
            let ret = 'cell day vacancy '

            if (dateInfo.day == null) {
                ret += 'blank '
            } else {
                if (localValue.value && dateFormat(localValue.value) == dateFormat(dateInfo.dep_date)) {
                    // 選択された日付の場合
                    ret += 'selected '
                }
            }
            return ret
        }

        /**
         * カレンダー表示
         */
        const showCalendar = () => {
            if (localValue.value) {
                // 日付がある場合、年月を変更
                year.value = localValue.value.getFullYear()
                month.value = localValue.value.getMonth() + 1
            }
            $('#calendar-picker__calendar').toggle()
        }

        /**
         * カレンダー非表示
         */
        const hideCalendar = () => {
            $('#calendar-picker__calendar').hide()
        }

        /**
         * 初期化
         */
        const initialize = () => {
            const date = new Date()
            const d = ('0' + date.getDate()).slice(-2)
            year.value = date.getFullYear()
            month.value = date.getMonth() + 1

            today.value = year.value + '-' + ('0' + month.value).slice(-2) + '-' + d
        }

        /**
         * onMounted
         */
        onMounted(() => {
            hideCalendar()
            initialize()
        })

        return {
            weekdays,
            year,
            month,
            today,
            calendarData,

            localValue,
            formatDate,
            prevDisabled,
            nextDisabled,

            createCalendar,
            setLastMonth,
            setNextMonth,
            unspecifiedDate,
            calendarClass,
            dateClick,
            showCalendar,
            hideCalendar,
        }
    },

    mounted: function () {
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#calendar-picker').length) {
                $('#calendar-picker__calendar').hide()
            }
        })
    },
})
</script>

<style scoped>
.rtl {
    direction: rtl;
}

.vdp-datepicker {
    position: relative;
    text-align: left;
}

.vdp-datepicker * {
    box-sizing: border-box;
}

.vdp-datepicker__calendar {
    position: absolute;
    z-index: 100;
    background: #fff;
    width: 300px;
    border: 1px solid #ccc;
}

.vdp-datepicker__calendar header {
    display: block;
    line-height: 40px;
}

.vdp-datepicker__calendar header span {
    display: inline-block;
    text-align: center;
    width: 71.42857142857143%;
    float: left;
}

.vdp-datepicker__calendar header .prev,
.vdp-datepicker__calendar header .next {
    width: 14.285714285714286%;
    float: left;
    text-indent: -10000px;
    position: relative;
}

.vdp-datepicker__calendar header .prev:after,
.vdp-datepicker__calendar header .next:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    border: 6px solid transparent;
}

.vdp-datepicker__calendar header .prev:after {
    border-right: 10px solid #000;
    margin-left: -5px;
}

.vdp-datepicker__calendar header .prev.disabled:after {
    border-right: 10px solid #ddd;
}

.vdp-datepicker__calendar header .next:after {
    border-left: 10px solid #000;
    margin-left: 5px;
}

.vdp-datepicker__calendar header .next.disabled:after {
    border-left: 10px solid #ddd;
}

.vdp-datepicker__calendar header .prev:not(.disabled),
.vdp-datepicker__calendar header .next:not(.disabled),
.vdp-datepicker__calendar header .up:not(.disabled) {
    cursor: pointer;
}

.vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker__calendar header .up:not(.disabled):hover {
    background: #eee;
}

.vdp-datepicker__calendar .disabled {
    color: #ddd;
    cursor: default;
}

.vdp-datepicker__calendar .flex-rtl {
    display: flex;
    width: inherit;
    flex-wrap: wrap;
}

.vdp-datepicker__calendar .cell {
    display: inline-block;
    padding: 0 5px;
    width: 14.285714285714286%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year {
    cursor: pointer;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid #4bd;
}

.vdp-datepicker__calendar .cell.selected {
    background: #4bd;
}

.vdp-datepicker__calendar .cell.selected:hover {
    background: #4bd;
}

.vdp-datepicker__calendar .cell.selected.highlighted {
    background: #4bd;
}

.vdp-datepicker__calendar .cell.highlighted {
    background: #cae5ed;
}

.vdp-datepicker__calendar .cell.highlighted.disabled {
    color: #a3a3a3;
}

.vdp-datepicker__calendar .cell.grey {
    color: #888;
}

.vdp-datepicker__calendar .cell.grey:hover {
    background: inherit;
}

.vdp-datepicker__calendar .cell.day-header {
    font-size: 75%;
    white-space: nowrap;
    cursor: inherit;
}

.vdp-datepicker__calendar .cell.day-header:hover {
    background: inherit;
}

.vdp-datepicker__calendar .month,
.vdp-datepicker__calendar .year {
    width: 33.333%;
}

.vdp-datepicker__clear-button,
.vdp-datepicker__calendar-button {
    cursor: pointer;
    font-style: normal;
}

.vdp-datepicker__clear-button.disabled,
.vdp-datepicker__calendar-button.disabled {
    color: #999;
    cursor: default;
}
</style>
