<!-- eslint-disable no-irregular-whitespace -->
<template>
    <!-- data -->
    <section class="data data-top">
        <div class="data__container">
            <div class="data__header">
                <h2 class="data__name">同行者情報一覧</h2>
            </div>
            <ul id="data__list-passenger" class="data__list data__list-passenger">
                <li class="data__item data__item-header">
                    <ul class="data__line data__line-header">
                        <li class="data__line-item data__line-item-no">No.</li>
                        <li class="data__line-item data__line-item-firstname">セイ</li>
                        <li class="data__line-item data__line-item-lastname">メイ</li>
                        <li class="data__line-item data__line-item-sex">性別</li>
                        <li class="data__line-item data__line-item-btn"></li>
                    </ul>
                </li>
                <li class="data__item" v-for="(companion, idx) in companion_list" :key="idx">
                    <ul class="data__line">
                        <li class="data__line-item data__line-item-no">
                            {{ companion.no }}
                        </li>
                        <li class="data__line-item data__line-item-firstname">
                            {{ companion.family_name_katakana }}
                        </li>
                        <li class="data__line-item data__line-item-lastname">
                            {{ companion.given_name_katakana }}
                        </li>
                        <li class="data__line-item data__line-item-sex">
                            {{ convertText(GENDERS, companion.sex) }}
                        </li>
                        <li class="data__line-item data__line-item-btn">
                            <button class="btn btn-data-change" type="button" v-on:click="displayChangeCompanion(companion.no)">変更</button>
                            <button class="btn btn-data-delete" type="button" v-on:click="displayDeleteCompanion(companion.no)">削除</button>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="data__plus">
                <button class="btn-border btn-border-plus" type="button" v-on:click="displayAddCompanion">同行者情報を追加する</button>
            </div>
        </div>
        <div class="btn-container">
            <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
        </div>
    </section>
    <!-- /data -->
    <!-- setting change -->
    <button id="btn-change-open-modal" data-remodal-target="change" v-show="false">○</button>
    <section id="change" class="setting remodal normal" data-remodal-id="change">
        <div class="input">
            <dl class="input__list">
                <!-- line -->
                <div class="input__line">
                    <!-- fullname -->
                    <div id="input__item-fullname" class="input__item input__item-fullname">
                        <dt id="input__header-fullname" class="input__header input__header-fullname">
                            <label id="input__name-fullname" class="input__name input__name-fullname" for="input__firstname"
                                >お名前（全角カタカナ）</label
                            >
                            <span class="input__required">必須</span>
                        </dt>
                        <dd id="input__container-fullname" class="input__container input__container-fullname">
                            <div id="input__firstname-pack" class="input__firstname-pack">
                                <span>セイ</span>
                                <input
                                    id="input__firstname"
                                    class="input__form input__firstname"
                                    name="input__firstname"
                                    type="text"
                                    v-model="target_family_name_katakana"
                                />
                            </div>
                            <div id="input__lastname-pack" class="input__lastname-pack">
                                <span>メイ</span>
                                <input
                                    id="input__lastname"
                                    class="input__form input__lastname"
                                    name="input__lastname"
                                    type="text"
                                    v-model="target_given_name_katakana"
                                />
                            </div>
                        </dd>
                        <p class="input__error-text">{{ name_error }}</p>
                    </div>
                </div>
                <!-- line -->
                <div class="input__line">
                    <!-- sex -->
                    <div id="input__item-sex" class="input__item input__item-sex">
                        <dt id="input__header-sex" class="input__header input__header-sex">
                            <label id="input__name-sex" class="input__name input__name-sex" for="">性別</label>
                            <span class="input__required">必須</span>
                        </dt>
                        <dd id="input__container-sex" class="input__container input__container-sex">
                            <div id="input__radio-sex-man" class="input__radio input__radio-sex input__radio-sex-man">
                                <input
                                    id="input__man"
                                    class="input__radio-btn input__sex input__man"
                                    type="radio"
                                    name="input__sex"
                                    :value="GENDER_MALE"
                                    v-model="target_sex"
                                />
                                <label id="input__label-man" class="input__radio-label input__label-man" for="input__man">男</label>
                            </div>
                            <div id="input__radio-sex-woman" class="input__radio input__radio-sex input__radio-sex-woman">
                                <input
                                    id="input__woman"
                                    class="input__radio-btn input__sex input__woman"
                                    type="radio"
                                    name="input__sex"
                                    :value="GENDER_FEMALE"
                                    v-model="target_sex"
                                />
                                <label id="input__label-woman" class="input__radio-label input__label-woman" for="input__woman">女</label>
                            </div>
                        </dd>
                        <p class="input__error-text">性別を選択してください</p>
                    </div>
                </div>
            </dl>
        </div>
        <div class="btn-container btn-container-modal">
            <button class="btn btn-back" data-remodal-action="cancel" id="btn-change-close-modal">キャンセル</button>
            <button class="btn btn-next" type="button" v-on:click="commitCompanion">登録する</button>
        </div>
    </section>
    <!-- /setting change -->
    <!-- setting delete -->
    <button id="btn-delete-open-modal" data-remodal-target="delete" v-show="false">○</button>
    <section id="delete" class="setting remodal normal" data-remodal-id="delete">
        <div class="output">
            <dl class="output__list">
                <!-- line -->
                <div class="output__line output__line-passenger">
                    <p class="input__attention">この同行者情報を削除してもよろしいですか？</p>
                    <div class="output__item-passenger-pack">
                        <!-- kananeme -->
                        <div class="output__item">
                            <dt class="output__header">
                                <div class="output__name output__name-kananeme">お名前</div>
                            </dt>
                            <dd class="output__container output__container-kananeme">
                                <div class="output__data output__data-kananeme">
                                    {{ joinFirstNameAndLastName(target_family_name_katakana, target_given_name_katakana) }}
                                </div>
                            </dd>
                        </div>
                        <!-- sex -->
                        <div class="output__item">
                            <dt class="output__header">
                                <div class="output__name output__name-sex">性別</div>
                            </dt>
                            <dd class="output__container output__container-sex">
                                <div class="output__data output__data-sex">
                                    {{ convertText(GENDERS, target_sex) }}
                                </div>
                            </dd>
                        </div>
                    </div>
                </div>
            </dl>
        </div>
        <div class="btn-container btn-container-modal">
            <button class="btn btn-back" data-remodal-action="cancel" id="btn-delete-close-modal">キャンセル</button>
            <button class="btn btn-remove" type="button" v-on:click="commitCompanion">削除する</button>
        </div>
    </section>
    <!-- /setting delete -->
    <ShipAnimationEnd></ShipAnimationEnd>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { useStore } from '@/store'
import { API_USERS, API_ORDER, isNull, joinFirstNameAndLastName } from '@/lib/util'
import { convertText, GENDERS, GENDER_MALE, GENDER_FEMALE } from '@/lib/constants'
import { isZenKatakana } from '@/lib/validation_util'

export default defineComponent({
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'CompanionInfo',
    setup(props, context) {
        const router = useRouter()
        const store = useStore()

        const companion_list = ref<CompanionDetail[]>()

        const MODE_LIST = 0
        const MODE_ADD = 1
        const MODE_UPDATE = 2
        const MODE_DELETE = 3
        const mode = ref<number>(MODE_LIST)
        const target_no = ref<number | null>(null)
        const target_family_name_katakana = ref('')
        const target_given_name_katakana = ref('')
        const target_sex = ref<number | null>(null)
        const name_error = ref('')

        // リスト表示用
        type CompanionDetail = {
            no: number
            id: number
            passenger_type: number
            family_name_katakana: string
            given_name_katakana: string
            sex: number
            member_id: string
            deleted: boolean
        }

        // 同行者一覧取得
        const getCompanionList = () => {
            companion_list.value = []
            const postData = {}
            apiClient
                .get(`${API_USERS}/companion`, { params: postData })
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        companion_list.value?.push({
                            no: i + 1,
                            id: data[i].id,
                            passenger_type: data[i].passenger_type,
                            family_name_katakana: data[i].family_name_katakana,
                            given_name_katakana: data[i].given_name_katakana,
                            sex: data[i].sex,
                            member_id: '',
                            deleted: false,
                        })
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
        }
        // 同行者情報追加画面表示
        const displayAddCompanion = () => {
            console.log('↓↓↓Companion.addCompanion↓↓↓')
            // 初期化
            $('#input__item-fullname').removeClass('input__error')
            $('#input__item-sex').removeClass('input__error')
            mode.value = MODE_ADD
            target_no.value = null
            name_error.value = ''
            target_family_name_katakana.value = ''
            target_given_name_katakana.value = ''
            target_sex.value = null
            // location.href = '#change';
            $('#btn-change-open-modal').click()
            console.log('↑↑↑Companion.addCompanion↑↑↑')
        }
        // 同行者情報更新画面表示
        const displayChangeCompanion = (no: number) => {
            console.log('↓↓↓Companion.changeCompanion↓↓↓')
            // 初期化
            $('#input__item-fullname').removeClass('input__error')
            $('#input__item-sex').removeClass('input__error')
            mode.value = MODE_UPDATE
            name_error.value = ''
            if (!isNull(companion_list.value)) {
                for (let i = 0; i < companion_list.value!.length; i++) {
                    if (companion_list.value![i].no == no) {
                        target_no.value = companion_list.value![i].no
                        target_family_name_katakana.value = companion_list.value![i].family_name_katakana
                        target_given_name_katakana.value = companion_list.value![i].given_name_katakana
                        target_sex.value = companion_list.value![i].sex
                        // location.href = '#change';
                        $('#btn-change-open-modal').click()
                        break
                    }
                }
            }
            console.log('↑↑↑Companion.changeCompanion↑↑↑')
        }
        // 同行者情報削除画面表示
        const displayDeleteCompanion = (no: number) => {
            console.log('↓↓↓Companion.deleteCompanion↓↓↓')
            // 初期化
            $('#input__item-fullname').removeClass('input__error')
            $('#input__item-sex').removeClass('input__error')
            mode.value = MODE_DELETE
            name_error.value = ''
            if (!isNull(companion_list.value)) {
                for (let i = 0; i < companion_list.value!.length; i++) {
                    if (companion_list.value![i].no == no) {
                        target_no.value = companion_list.value![i].no
                        target_family_name_katakana.value = companion_list.value![i].family_name_katakana
                        target_given_name_katakana.value = companion_list.value![i].given_name_katakana
                        target_sex.value = companion_list.value![i].sex
                        // location.href = '#delete';
                        $('#btn-delete-open-modal').click()
                        break
                    }
                }
            }
            console.log('↑↑↑Companion.deleteCompanion↑↑↑')
        }
        // 入力チェック
        const chkValidation = (): boolean => {
            let err_count = 0
            name_error.value = ''
            // 初期化
            $('#input__item-fullname').removeClass('input__error')
            $('#input__item-sex').removeClass('input__error')

            // お名前
            if (
                (mode.value == MODE_ADD || mode.value == MODE_UPDATE) &&
                (isNull(target_family_name_katakana.value) || isNull(target_given_name_katakana.value))
            ) {
                $('#input__item-fullname').addClass('input__error')
                name_error.value = 'お名前を入力してください'
                err_count++
            } else if (
                (mode.value == MODE_ADD || mode.value == MODE_UPDATE) &&
                !isNull(target_family_name_katakana.value) &&
                !isNull(target_given_name_katakana.value) &&
                (!isZenKatakana(target_family_name_katakana.value) || !isZenKatakana(target_given_name_katakana.value))
            ) {
                $('#input__item-fullname').addClass('input__error')
                name_error.value = 'お名前は全角カタカナで入力してください'
                err_count++
            }
            // 性別
            if ((mode.value == MODE_ADD || mode.value == MODE_UPDATE) && isNull(target_sex.value)) {
                $('#input__item-sex').addClass('input__error')
                err_count++
            }

            return err_count == 0
        }
        const commitCompanion = () => {
            // 入力チェック
            if (chkValidation()) {
                if (mode.value == MODE_ADD) {
                    // 同行者情報追加
                    addCompanion()
                } else if (mode.value == MODE_UPDATE) {
                    // 同行者情報更新
                    changeCompanion()
                } else if (mode.value == MODE_DELETE) {
                    // 同行者情報削除
                    deleteCompanion()
                }
            }
        }
        // 同行者情報追加
        const addCompanion = async () => {
            console.log('↓↓↓Companion.addCompanion↓↓↓')
            let companion_detail = {
                id: null,
                passenger_type: 0,
                family_name_katakana: target_family_name_katakana.value,
                given_name_katakana: target_given_name_katakana.value,
                sex: target_sex.value,
                member_id: '',
                deleted: false,
            }
            const postData = {
                companion: [companion_detail],
            }
            await apiClient
                .post(`${API_USERS}/companion`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    $('#btn-change-close-modal').click()
                    // 同行者一覧取得
                    getCompanionList()
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Companion.addCompanion↑↑↑')
        }
        // 同行者情報更新
        const changeCompanion = async () => {
            console.log('↓↓↓Companion.changeCompanion↓↓↓')
            let target_companion = {} as CompanionDetail
            for (let i = 0; i < companion_list.value!.length; i++) {
                if (companion_list.value![i].no == target_no.value) {
                    target_companion = companion_list.value![i]
                    break
                }
            }
            let companion_detail = {
                id: target_companion.id,
                passenger_type: target_companion.passenger_type,
                family_name_katakana: target_family_name_katakana.value,
                given_name_katakana: target_given_name_katakana.value,
                sex: target_sex.value,
                member_id: target_companion.member_id,
                deleted: false,
            }
            const postData = {
                companion: [companion_detail],
            }
            await apiClient
                .post(`${API_USERS}/companion`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    $('#btn-change-close-modal').click()
                    // 同行者一覧取得
                    getCompanionList()
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Companion.changeCompanion↑↑↑')
        }
        // 同行者情報削除
        const deleteCompanion = async () => {
            console.log('↓↓↓Companion.deleteCompanion↓↓↓')
            let target_companion = {} as CompanionDetail
            for (let i = 0; i < companion_list.value!.length; i++) {
                if (companion_list.value![i].no == target_no.value) {
                    target_companion = companion_list.value![i]
                    break
                }
            }
            let companion_detail = {
                id: target_companion.id,
                passenger_type: target_companion.passenger_type,
                family_name_katakana: target_companion.family_name_katakana,
                given_name_katakana: target_companion.given_name_katakana,
                sex: target_companion.sex,
                member_id: target_companion.member_id,
                deleted: true,
            }
            const postData = {
                companion: [companion_detail],
            }
            await apiClient
                .post(`${API_USERS}/companion`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    $('#btn-delete-close-modal').click()
                    // 同行者一覧取得
                    getCompanionList()
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Companion.deleteCompanion↑↑↑')
        }
        // マイページに移動
        let moveMyPage = () => {
            router.push('/member/my_page')
        }

        onMounted(async () => {
            console.log('↓↓↓Companion.onMounted↓↓↓')
            if (!store.state.is_logged_in) {
                // ログイン状態で無い場合、使用不可
                router.push('/member/login')
            }
            // 同行者一覧取得
            getCompanionList()
            console.log('↑↑↑MyPage.onMounted↑↑↑')
        })

        return {
            companion_list,
            target_family_name_katakana,
            target_given_name_katakana,
            target_sex,
            name_error,

            displayAddCompanion,
            displayChangeCompanion,
            displayDeleteCompanion,
            commitCompanion,
            moveMyPage,

            GENDERS,
            GENDER_MALE,
            GENDER_FEMALE,
            convertText,
            joinFirstNameAndLastName,
        }
    },
    components: {
        ShipAnimationEnd,
    },
    data() {
        return {}
    },
    methods: {},
})
</script>
