<!-- eslint-disable no-irregular-whitespace -->
<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">現在の予約</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- ticket -->
        <section class="ticket ticket-booking none">
            <div class="ticket__container">
                <div class="ticket__information">
                    <div class="ticket__count">
                        件数<span>{{ historyCount }}</span
                        >件
                    </div>
                </div>
                <!-- ticket__list -->
                <ul id="ticket__list" class="ticket__list">
                    <li class="ticket__item" v-for="(history, idx) in historyList" :key="idx">
                        <div class="ticket__item-header">
                            <h3 class="ticket__item-name">チケット情報（{{ idx + 1 }}）</h3>
                        </div>
                        <div class="ticket__item-container">
                            <div class="output">
                                <dl class="output__list">
                                    <!-- line -->
                                    <div class="output__line">
                                        <!-- ticketnumber -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-ticketnumber">乗船番号</div>
                                            </dt>
                                            <dd class="output__container output__container-ticketnumber">
                                                <div class="output__data output__data-ticketnumber">
                                                    {{ history.boarding_no }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- fee -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-fee">運賃合計</div>
                                            </dt>
                                            <dd class="output__container output__container-fee">
                                                <div class="output__data output__data-fee">
                                                    {{ (isNull(history.amount) ? 0 : history.amount).toLocaleString() }}円
                                                </div>
                                            </dd>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="output__line">
                                        <!-- date -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-date">乗船日</div>
                                            </dt>
                                            <dd class="output__container output__container-date">
                                                <div class="output__data output__data-date">
                                                    {{ formatDate(history.dep_date, false, 2, 1) }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- route -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-route">ご利用区間</div>
                                            </dt>
                                            <dd class="output__container output__container-route">
                                                <div class="output__data output__data-route">
                                                    {{ history.sea_route_name }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- time -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-time">出航時刻</div>
                                            </dt>
                                            <dd class="output__container output__container-time">
                                                <div class="output__data output__data-time">
                                                    {{
                                                        formatDateTime(
                                                            castStringToDate(formatDate(history.dep_date, true, 0, 0) + ' ' + history.dep_time),
                                                            false,
                                                            1,
                                                        )
                                                    }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- ship-name -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-ship">客室タイプ</div>
                                            </dt>
                                            <dd class="output__container output__container-ship">
                                                <div class="output__data output__data-ship">
                                                    {{ history.seat_type_name }}
                                                </div>
                                            </dd>
                                        </div>
                                    </div>
                                    <dl class="output__status">
                                        <div class="output__status-pack">
                                            <!-- resevdate -->
                                            <div class="output__status-container output__status-container-resevdate">
                                                <dt class="output__status-name output__status-name-resevdate">予約日</dt>
                                                <dt class="output__status-data output__status-data-resevdate">
                                                    {{ formatDate(history.reserved_date, false, 2, 1) }}
                                                </dt>
                                            </div>
                                            <!-- resevstatus -->
                                            <div class="output__status-container output__status-container-resevstatus">
                                                <dt class="output__status-name output__status-name-resevstatus">状態</dt>
                                                <dt class="output__status-data output__status-data-resevstatus">
                                                    {{ history.booking_status_name }}
                                                </dt>
                                            </div>
                                            <!-- payment -->
                                            <div class="output__status-container output__status-container-payment">
                                                <dt class="output__status-name output__status-name-payment">決済方法</dt>
                                                <dt class="output__status-data output__status-data-payment">
                                                    {{ history.payment_method }}
                                                </dt>
                                            </div>
                                        </div>
                                    </dl>
                                    <div class="output__btn">
                                        <button
                                            class="btn btn-bank"
                                            type="button"
                                            v-show="!isCounterPayment(history.payment_method)"
                                            v-on:click="displayAuthori(history.boarding_no, history.payment_method)"
                                        >
                                            お支払状況確認
                                        </button>
                                        <!-- <button class="btn btn-cansell" type="button" v-on:click="cancelBoardingStart(history.boarding_no)">予約キャンセル</button> -->
                                        <button
                                            class="btn btn-confirm"
                                            type="button"
                                            v-on:click="displayBoardingDetail(history.boarding_no, history.sea_route_code)"
                                        >
                                            予約内容確認
                                        </button>
                                        <!-- <button class="btn btn-change" type="button" v-on:click="changeBoarding(history)">予約内容変更</button> -->
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
                <button class="btn btn-mypage" type="button" v-on:click="moveBookingHistory">過去の予約へ</button>
            </div>
        </section>
        <!-- /ticket -->
        <!-- bank -->
        <button id="btn-bank-open-modal" data-remodal-target="bank" v-show="false">○</button>
        <button id="btn-bank-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section id="bank" class="confirm bank remodal normal" data-remodal-id="bank">
            <div class="confirm__header">
                <h3 class="confirm__name">お支払状況</h3>
                <button class="confirm__close" data-remodal-action="cancel">×</button>
            </div>
            <div class="confirm__container">
                <ul class="confirm__list">
                    <!-- infoset -->
                    <template v-for="(authori, idx) in authori_array" :key="idx">
                        <template v-if="authori.payment_method == AUTHORI_PAYMENT_METHOD_CREDIT && idx == 0">
                            <li class="confirm__item" v-for="(authori_detail, idx2) in authori_credit_array" :key="idx2">
                                <div class="confirm__item-container">
                                    <div class="confirm__disp">
                                        <dl class="confirm__disp-list">
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">更新日時</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.sales_datetime }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">運賃（変更後）</dt>
                                                <dd class="confirm__disp-data">{{ authori_detail.amount?.toLocaleString() }}円</dd>
                                            </div>
                                            <div
                                                class="confirm__disp-line"
                                                v-show="authori_detail.cancel_amount != null && authori_detail.cancel_amount! > 0"
                                            >
                                                <dt class="confirm__disp-name">内キャンセル料</dt>
                                                <dd class="confirm__disp-data">{{ authori_detail.cancel_amount?.toLocaleString() }}円</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </li>
                        </template>
                        <template v-else-if="authori.payment_method == AUTHORI_PAYMENT_METHOD_PAY_EASY && idx == 0">
                            <li class="confirm__item" v-for="(authori_detail, idx3) in authori_payeasy_array" :key="idx3">
                                <template v-if="idx3 == 0">
                                    <div class="confirm__item-header">
                                        <h3 class="confirm__item-name">ご入金履歴</h3>
                                    </div>
                                </template>
                                <div class="confirm__item-container">
                                    <div class="confirm__disp">
                                        <dl class="confirm__disp-list">
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金期限日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.transfer_deadline_datetime }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.confirmed_sales_datetime }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">収納機関番号</dt>
                                                <dd class="confirm__disp-data-cvs">
                                                    {{ authori_detail.payeasy_collection_agency_number }}&emsp;
                                                    <a href="https://static.mul-pay.jp/customer-convenience-store/pay-easy/" target="_blank"
                                                        >支払手順はこちら</a
                                                    >
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">お客様番号</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.payeasy_customer_number }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">確認番号</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.payeasy_confirmation_number }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金額</dt>
                                                <dd class="confirm__disp-data">{{ authori_detail.amount?.toLocaleString() }}円</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </li>
                        </template>
                        <template v-else-if="authori.payment_method == AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE && idx == 0">
                            <li class="confirm__item" v-for="(authori_detail, idx4) in authori_convenience_array" :key="idx4">
                                <template v-if="idx4 == 0">
                                    <div class="confirm__item-header">
                                        <h3 class="confirm__item-name">ご入金履歴</h3>
                                    </div>
                                </template>
                                <div class="confirm__item-container">
                                    <div class="confirm__disp">
                                        <dl class="confirm__disp-list">
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金期限日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.transfer_deadline_datetime }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.confirmed_sales_datetime }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">コンビニ</dt>
                                                <dd class="confirm__disp-data-cvs">
                                                    {{ authori_detail.convenience_name }}&emsp;
                                                    <a
                                                        v-show="authori_convenience_array[0]?.convenience_type == CONVENIENCE_TYPE_LAWSON"
                                                        href="https://static.mul-pay.jp/customer-convenience-store/lawson/"
                                                        target="_blank"
                                                        >支払手順はこちら</a
                                                    >
                                                    <a
                                                        v-show="authori_convenience_array[0]?.convenience_type == CONVENIENCE_TYPE_FAMILY_MART"
                                                        href="https://static.mul-pay.jp/customer-convenience-store/familymart/"
                                                        target="_blank"
                                                        >支払手順はこちら</a
                                                    >
                                                    <a
                                                        v-show="authori_convenience_array[0]?.convenience_type == CONVENIENCE_TYPE_MINI_STOP"
                                                        href="https://static.mul-pay.jp/customer-convenience-store/ministop/"
                                                        target="_blank"
                                                        >支払手順はこちら</a
                                                    >
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">お客様番号</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.convenience_receipt_number }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">確認番号</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.convenience_confirmation_number }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金額</dt>
                                                <dd class="confirm__disp-data">{{ authori_detail.amount?.toLocaleString() }}円</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </li>
                            <template v-if="authori_convenience_array[0]?.convenience_type == CONVENIENCE_TYPE_FAMILY_MART">
                                <span class="input__required-note"
                                    >※ファミリーマートでお支払の場合、第一番号が【確認番号】、第二番号が【お客様番号】となります。</span
                                >
                            </template>
                        </template>
                        <template v-if="remittance_array.length > 0 && idx == 0">
                            <li class="confirm__item" v-for="(remittance_detail, idx5) in remittance_array" :key="idx5">
                                <template v-if="idx5 == 0">
                                    <div class="confirm__item-header">
                                        <h3 class="confirm__item-name">ご返金履歴</h3>
                                    </div>
                                </template>
                                <div class="confirm__item-container">
                                    <div class="confirm__disp">
                                        <dl class="confirm__disp-list">
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">返金発生日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ remittance_detail.ins_date }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">返金日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ remittance_detail.deposit_date }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">取消運賃</dt>
                                                <dd class="confirm__disp-data">{{ remittance_detail.payment_amount?.toLocaleString() }}円</dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">キャンセル料</dt>
                                                <dd class="confirm__disp-data">{{ remittance_detail.cancel_amount?.toLocaleString() }}円</dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">振込手数料</dt>
                                                <dd class="confirm__disp-data">{{ remittance_detail.transfer_fee?.toLocaleString() }}円</dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">返金額</dt>
                                                <dd class="confirm__disp-data">{{ remittance_detail.refunded_amount?.toLocaleString() }}円</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </template>
                </ul>
            </div>
            <div class="confirm__btn">
                <button class="btn btn-back" data-remodal-action="cancel">閉じる</button>
            </div>
        </section>
        <!-- /bank -->
        <!-- confirm -->
        <button id="btn-confirm-open-modal" data-remodal-target="confirm" v-show="false">○</button>
        <button id="btn-confirm-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section v-show="isDisplayBoardingDetail" id="confirm" class="confirm remodal normal remodal-is-initialized" data-remodal-id="confirm">
            <div class="confirm__header">
                <h3 class="confirm__name">予約内容確認</h3>
            </div>
            <div class="confirm__container confirm__container-regular_order">
                <ul class="confirm__list">
                    <!-- change info -->
                    <li class="confirm__item">
                        <div v-show="contract?.dep_date_change_limit">
                            <span class="input__required-note"
                                >乗船日の変更：予約中の乗船日から{{ contract?.dep_date_change_limit_days }}日前の{{
                                    formatDateTime(
                                        castStringToDate(
                                            formatDate(castStringToDate(boarding?.dep_date), true, 0, 0) + ' ' + contract?.dep_date_change_limit_time,
                                        ),
                                        false,
                                        1,
                                    )
                                }}まで</span
                            >
                        </div>
                        <div v-show="contract?.passenger_change_limit">
                            <span class="input__required-note"
                                >乗船者の増減：予約中の乗船日から{{ contract?.passenger_change_limit_days }}日前の{{
                                    formatDateTime(
                                        castStringToDate(
                                            formatDate(castStringToDate(boarding?.dep_date), true, 0, 0) +
                                                ' ' +
                                                contract?.passenger_change_limit_time,
                                        ),
                                        false,
                                        1,
                                    )
                                }}まで</span
                            >
                        </div>
                        <div v-show="contract?.boarding_car_change_limit">
                            <span class="input__required-note"
                                >車両の増減&emsp;：予約中の乗船日から{{ contract?.boarding_car_change_limit_days }}日前の{{
                                    formatDateTime(
                                        castStringToDate(
                                            formatDate(castStringToDate(boarding?.dep_date), true, 0, 0) +
                                                ' ' +
                                                contract?.boarding_car_change_limit_time,
                                        ),
                                        false,
                                        1,
                                    )
                                }}まで</span
                            >
                        </div>
                    </li>
                    <!-- price -->
                    <li id="confirm-price" class="confirm__item">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">運賃</h3>
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">合計金額</dt>
                                        <dd class="confirm__disp-data">{{ displayAmount.toLocaleString() }}円</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- ticket -->
                    <li class="confirm__item">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">チケット情報</h3>
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">乗船番号</dt>
                                        <dd class="confirm__disp-data">
                                            {{ boarding?.boarding_no }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">船室</dt>
                                        <dd class="confirm__disp-data">{{ dispSeatTypeName }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">プラン</dt>
                                        <dd class="confirm__disp-data">
                                            {{ contract?.contract_name }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ご利用区間</dt>
                                        <dd class="confirm__disp-data">
                                            {{ boarding?.sea_route_name }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">船名</dt>
                                        <dd class="confirm__disp-data">
                                            {{ boarding?.ship_name }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">乗船日</dt>
                                        <dd class="confirm__disp-data">
                                            {{ formatDate(castStringToDate(boarding?.dep_date), false, 2, 1) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">出航時刻</dt>
                                        <dd class="confirm__disp-data">
                                            {{
                                                formatDateTime(
                                                    castStringToDate(
                                                        formatDate(castStringToDate(boarding?.dep_date), true, 0, 0) + ' ' + boarding?.dep_time,
                                                    ),
                                                    false,
                                                    1,
                                                )
                                            }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- master -->
                    <li class="confirm__item">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">代表者</h3>
                            <img
                                class="confirm__item-icon"
                                src="@/images/items/accessory-ancer-01.svg"
                                height="48"
                                width="54"
                                alt="代表者情報アイコン"
                            />
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">区分</dt>
                                        <dd class="confirm__disp-data">
                                            {{ convertText(PASSENGER_TYPES, represent_passenger?.passenger_type) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">お名前</dt>
                                        <dd class="confirm__disp-data">
                                            {{
                                                joinFirstNameAndLastName(
                                                    represent_passenger?.family_name_katakana,
                                                    represent_passenger?.given_name_katakana,
                                                )
                                            }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">電話番号</dt>
                                        <dd class="confirm__disp-data">
                                            {{ boarding?.represent_passenger_tel }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">性別</dt>
                                        <dd class="confirm__disp-data">
                                            {{ convertText(GENDERS, represent_passenger?.gender) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">乗船時年齢</dt>
                                        <dd class="confirm__disp-data">{{ represent_passenger?.age }}歳</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">住所</dt>
                                        <dd class="confirm__disp-data">
                                            {{ boarding?.represent_passenger_post_code }}　{{
                                                convertText(PREFECTURES, boarding?.represent_passenger_prefectures)
                                            }}　{{ boarding?.represent_passenger_address }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">運賃</dt>
                                        <dd class="confirm__disp-data">{{ getPassengerPrice(represent_passenger).toLocaleString() }}円</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- passenger -->
                    <li class="confirm__item" v-for="(passenger, idx) in passenger_array" :key="idx">
                        <div class="confirm__item-header">
                            <h3
                                class="confirm__item-name"
                                v-if="passenger.passenger_type == PASSENGER_TYPE_ADULT || passenger.passenger_type == PASSENGER_TYPE_CHILD"
                            >
                                同行者
                            </h3>
                            <h3
                                class="confirm__item-name"
                                v-if="passenger.passenger_type == PASSENGER_TYPE_INFANT || passenger.passenger_type == PASSENGER_TYPE_BABY"
                            >
                                乳幼児
                            </h3>
                            <img
                                class="confirm__item-icon"
                                src="@/images/items/accessory-strawhat-01.svg"
                                height="48"
                                width="54"
                                alt="同行者情報アイコン"
                            />
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">区分</dt>
                                        <dd class="confirm__disp-data">
                                            {{ convertText(PASSENGER_TYPES, passenger.passenger_type) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">お名前</dt>
                                        <dd class="confirm__disp-data">
                                            {{ joinFirstNameAndLastName(passenger.family_name_katakana, passenger.given_name_katakana) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">性別</dt>
                                        <dd class="confirm__disp-data">
                                            {{ convertText(GENDERS, passenger.gender) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">乗船時年齢</dt>
                                        <dd class="confirm__disp-data">{{ passenger.age }}歳</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">運賃</dt>
                                        <dd class="confirm__disp-data">{{ getPassengerPrice(passenger).toLocaleString() }}円</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- car -->
                    <li class="confirm__item" v-for="(car, idx) in car_array" :key="idx">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">乗用車</h3>
                            <img
                                class="confirm__item-icon"
                                src="@/images/items/accessory-car-01.svg"
                                height="48"
                                width="54"
                                alt="乗用車情報アイコン"
                            />
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">運転手</dt>
                                        <dd class="confirm__disp-data">
                                            {{ getDriverName(car.passenger_line_no) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">車長</dt>
                                        <dd class="confirm__disp-data">{{ car.car_length }}m未満</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">車種</dt>
                                        <dd class="confirm__disp-data">{{ car.note }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ナンバープレート</dt>
                                        <dd class="confirm__disp-data">
                                            {{ car.car_place_name }} {{ car.car_class_number }} {{ car.car_hiragana }} {{ car.car_number }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">運賃</dt>
                                        <dd class="confirm__disp-data">{{ getCarPrice(car).toLocaleString() }}円</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- bike -->
                    <li class="confirm__item" v-for="(vehicle, idx) in vehicle_array" :key="idx">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">二輪車</h3>
                            <img
                                class="confirm__item-icon"
                                src="@/images/items/accessory-bike-01.svg"
                                height="48"
                                width="54"
                                alt="二輪車情報アイコン"
                            />
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">種別/排気量</dt>
                                        <dd class="confirm__disp-data">
                                            {{ getCarTypeName(vehicle.car_type_code) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">メーカー/車名</dt>
                                        <dd class="confirm__disp-data">{{ vehicle.note }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ナンバープレート</dt>
                                        <dd class="confirm__disp-data">
                                            {{ vehicle.car_place_name }}
                                            {{ vehicle.car_class_number }} {{ vehicle.car_hiragana }}
                                            {{ vehicle.car_number }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">運賃</dt>
                                        <dd class="confirm__disp-data">{{ getCarPrice(vehicle).toLocaleString() }}円</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- pet -->
                    <li class="confirm__item" v-show="isShowPetCage">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">ペット情報</h3>
                            <img
                                class="confirm__item-icon"
                                src="@/images/items/accessory-pet-01.svg"
                                height="48"
                                width="54"
                                alt="ペット情報アイコン"
                            />
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ペットルーム</dt>
                                        <dd class="confirm__disp-data">{{ displayCageNum }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ペット区分</dt>
                                        <dd class="confirm__disp-data">{{ displayPetNum }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ペット種類</dt>
                                        <dd class="confirm__disp-data">{{ boarding?.pet_note }}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- note -->
                    <li class="confirm__item">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">特記事項</h3>
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">妊婦</dt>
                                        <dd class="confirm__disp-data">{{ displayPregnant }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">車椅子</dt>
                                        <dd class="confirm__disp-data">{{ displayWheelChair }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">医療用酸素ボンベ</dt>
                                        <dd class="confirm__disp-data">
                                            {{ displayOxygenCylinder }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="confirm__btn confirm__btn-regular_order">
                <button class="btn btn-cansell btn-cansell-short" type="button" v-on:click="cancelBoardingStart(boarding?.boarding_no)">
                    予約キャンセル
                </button>
                <button class="btn btn-change btn-change-short" type="button" v-on:click="changeBoarding(boarding)">予約内容変更</button>
                <button class="btn btn-back btn-back-short" data-remodal-action="cancel">閉じる</button>
            </div>
        </section>
        <!-- /confirm -->
        <!-- cancel -->
        <section id="cancel-booking" class="confirm remodal normal" data-remodal-id="cancel-booking">
            <button id="btn-cancel-booking-open-modal" data-remodal-target="cancel-booking" v-show="false">○</button>
            <button id="btn-cancel-booking-close-modal" data-remodal-action="cancel" v-show="false">×</button>
            <CancelBookingDialog
                ref="cancelBookingDialog"
                v-on:displayMessage="displayMessage"
                v-on:cancelBooking="getBookingHistory"
            ></CancelBookingDialog>
        </section>
        <!-- /cancel -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch, nextTick } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { setBookingData } from '@/lib/localStorageUtil'
import {
    API_USERS,
    API_ORDER,
    CAR_TYPE_BICYCLE,
    CAR_TYPE_PASSENGER,
    CAR_TYPE_TWO_WHEEL_LARGE,
    CAR_TYPE_TWO_WHEEL_MEDIUM,
    CAR_TYPE_TWO_WHEEL_SMALL,
    isNull,
    castStringToDate,
    formatDate,
    formatDateTime,
    calcDiffDays,
    getCarTypeName,
    splitFullname,
    joinFirstNameAndLastName,
    DEFAULT_TWO_WHEEL_WIDTH,
    DEFAULT_CAR_WIDTH,
    getBoardingContract,
    getPassengerPrice,
    getCarPrice,
    getPaymentMethod,
    getSeatTypeName,
    getTransferCharge,
} from '@/lib/util'
import {
    convertText,
    PASSENGER_TYPES,
    GENDERS,
    PREFECTURES,
    PERSONALITY_TYPE_INDIVIDUAL,
    CAGE_TYPES_LARGE,
    CAGE_TYPES_SMALL,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_BABY,
    PASSENGER_TYPE_INFANT,
    CONVENIENCE_TYPE_FAMILY_MART,
    AUTHORI_PAYMENT_METHOD_CREDIT,
    AUTHORI_PAYMENT_METHOD_PAY_EASY,
    AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE,
    CONVENIENCE_TYPE_LAWSON,
    CONVENIENCE_TYPE_MINI_STOP,
} from '@/lib/constants'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import {
    Booking,
    Boarding,
    HeadcountDistribution,
    PassengerDetail,
    CarDetail,
    Contract,
    CageDetail,
    BookingHistoryDetail,
    AuthoriHistoryDetail,
    AuthoriCreditHistoryDetail,
    AuthoriPayeasyHistoryDetail,
    AuthoriConvenienceHistoryDetail,
    RemittanceHistoryDetail,
} from '@/lib/types'
import CancelBookingDialog from '@/components/booking/BookingCurrent/CancelBookingDialog.vue'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        const historyList = ref<BookingHistoryDetail[]>([])
        const historyListSorted = ref<BookingHistoryDetail[]>([])
        const sort_param = ref<number[]>([0, 0])
        const cancelBookingDialog = ref<InstanceType<typeof CancelBookingDialog>>()

        const isDisplayBoardingDetail = ref(false)

        // 入金内容確認
        const authori_array = ref<AuthoriHistoryDetail[]>([])
        const authori_credit_array = ref<AuthoriCreditHistoryDetail[]>([])
        const authori_payeasy_array = ref<AuthoriPayeasyHistoryDetail[]>([])
        const authori_convenience_array = ref<AuthoriConvenienceHistoryDetail[]>([])
        // 返金情報確認
        const remittance_array = ref<RemittanceHistoryDetail[]>([])

        // 予約内容確認
        const booking = ref<Booking>()
        const boarding = ref<Boarding>()
        const represent_passenger = ref<PassengerDetail>()
        const contract = ref<Contract>()
        const passenger_array = ref<PassengerDetail[]>([])
        const car_array = ref<CarDetail[]>([])
        const vehicle_array = ref<CarDetail[]>([])
        const petcage_array = ref<CageDetail[]>([])

        const cancel_boarding_no = ref()

        const historyCount = computed(() => {
            return historyList.value.length
        })

        const sortList = (sort_type: number | null) => {
            console.log('↓↓↓BookingCurrent.sortList↓↓↓')
            if (sort_type != null) {
                if (sort_param.value[0] == sort_type) {
                    sort_param.value[1] = (sort_param.value[1] + 1) % 2
                } else {
                    sort_param.value[0] = sort_type!
                    sort_param.value[1] = 0
                }
            }

            if (sort_param.value[0] == 0) {
                // 出発日順
                if (sort_param.value[1] == 0) {
                    // 昇順
                    historyListSorted.value = historyList.value.sort((a, b) => {
                        if (calcDiffDays(a.dep_date, b.dep_date) == 0) {
                            if (a.boarding_no > b.boarding_no) {
                                return 1
                            } else {
                                return -1
                            }
                        } else if (a.dep_date > b.dep_date) {
                            return 1
                        } else {
                            return -1
                        }
                    }) as BookingHistoryDetail[]
                } else {
                    // 降順
                    historyListSorted.value = historyList.value.sort((a, b) => {
                        if (calcDiffDays(a.dep_date, b.dep_date) == 0) {
                            if (a.boarding_no > b.boarding_no) {
                                return -1
                            } else {
                                return 1
                            }
                        } else if (a.dep_date > b.dep_date) {
                            return -1
                        } else {
                            return 1
                        }
                    }) as BookingHistoryDetail[]
                }
            } else {
                // 予約日順
                if (sort_param.value[1] == 0) {
                    // 昇順
                    historyListSorted.value = historyList.value.sort((a, b) => {
                        if (calcDiffDays(a.reserved_date, b.reserved_date) == 0) {
                            if (a.boarding_no > b.boarding_no) {
                                return 1
                            } else {
                                return -1
                            }
                        } else if (a.reserved_date > b.reserved_date) {
                            return 1
                        } else {
                            return -1
                        }
                    }) as BookingHistoryDetail[]
                } else {
                    // 降順
                    historyListSorted.value = historyList.value.sort((a, b) => {
                        if (calcDiffDays(a.reserved_date, b.reserved_date) == 0) {
                            if (a.boarding_no > b.boarding_no) {
                                return -1
                            } else {
                                return 1
                            }
                        } else if (a.reserved_date > b.reserved_date) {
                            return -1
                        } else {
                            return 1
                        }
                    }) as BookingHistoryDetail[]
                }
            }
            console.log('↑↑↑BookingCurrent.sortList↑↑↑')
        }

        // 乗船履歴取得
        const getBookingHistory = async (sort_type: number | null) => {
            historyList.value = []
            await getBookingHistoryCurrent()
            sortList(sort_type)
        }

        // 乗船履歴取得(通常)
        const getBookingHistoryCurrent = async () => {
            console.log('↓↓↓BookingCurrent.getBookingHistoryCurrent↓↓↓')
            await apiClient
                .get(`${API_ORDER}/history/current`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        historyList.value.push({
                            dep_date: castStringToDate(data[i].dep_date)!,
                            dep_time: data[i].dep_time,
                            boarding_no: data[i].boarding_no,
                            amount: data[i].amount,
                            seats: data[i].seats,
                            seat_type_name: getSeatTypeNames(data[i].seats),
                            contract_name: data[i].contract_name,
                            sea_route_code: data[i].sea_route_code,
                            sea_route_name: data[i].sea_route_name,
                            ship_name: data[i].ship_name,
                            reserved_date: castStringToDate(data[i].reserved_date)!,
                            booking_status: data[i].booking_status,
                            booking_status_name: data[i].paid_status_name,
                            payment_method: data[i].payment_method,
                            receipt_flg: 0,

                            canceled: false,
                            min_transfer_deadline_date: null,
                            paid_status_name: '',
                        })
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑BookingCurrent.getBookingHistoryCurrent↑↑↑')
        }
        const isCounterPayment = (payment_method: string) => {
            return payment_method == '当日決済'
        }

        // 入金情報確認
        const displayAuthori = async (boarding_no: number, payment_method: string) => {
            // 入金内容取得
            try {
                store.dispatch('setIsProcessing', true)
                let isErr = false
                await Promise.all([getAuthoriHistory(boarding_no), getAuthoriHistoryDetail(boarding_no, getPaymentMethod(payment_method)!)]).then(
                    (res) => {
                        for (let i = 0; i < res.length; i++) {
                            if (!res[i]) {
                                isErr = true
                                break
                            }
                        }
                    },
                )
                if (isErr) {
                    store.dispatch('setIsProcessing', false)
                    context.emit('displayMessage', {
                        msg_title: '現在の予約',
                        msg: ['入金情報の取得に失敗しました。'],
                        close_btn: true,
                    })
                    return
                }
                // if (!await getAuthoriHistory(boarding_no)) {
                //   store.dispatch('setIsProcessing', false);
                //   context.emit("displayMessage", {
                //     msg_title: '現在の予約',
                //     msg: ['入金情報の取得に失敗しました。'],
                //     close_btn: true,
                //   });
                //   return;
                // }
                // if (!await getAuthoriHistoryDetail(boarding_no)) {
                //   store.dispatch('setIsProcessing', false);
                //   context.emit("displayMessage", {
                //     msg_title: '現在の予約',
                //     msg: ['入金情報の取得に失敗しました。'],
                //     close_btn: true,
                //   });
                //   return;
                // }
                store.dispatch('setIsProcessing', false)
            } catch (e) {
                console.log(e)
                store.dispatch('setIsProcessing', false)
                return
            }
            // location.href = '#bank';
            setTimeout(() => {
                $('#btn-bank-open-modal').click()
            }, 300)
        }
        const getAuthoriHistory = async (boarding_no: number): Promise<boolean> => {
            console.log('↓↓↓BookingCurrent.getAuthori↓↓↓')
            let isError = false
            authori_array.value = []
            await apiClient
                .get(`${API_ORDER}/boarding/${boarding_no}/authori`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        authori_array.value.push({
                            id: data[i].id,
                            payment_method: data[i].payment_method,
                            type: data[i].type,
                            result: data[i].result,
                            authori_datetime: data[i].authori_datetime,
                            amount: data[i].amount,
                            sales_datetime: data[i].sales_datetime,
                        })
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    isError = false
                })
            console.log('↑↑↑BookingCurrent.getAuthori↑↑↑')
            return !isError
        }
        const getAuthoriHistoryDetail = async (boarding_no: number, payment_method: number): Promise<boolean> => {
            console.log('↓↓↓BookingCurrent.getAuthoriDetail↓↓↓')
            let isError = false
            authori_credit_array.value = []
            authori_payeasy_array.value = []
            authori_convenience_array.value = [] as AuthoriConvenienceHistoryDetail[]
            remittance_array.value = []

            if (payment_method == AUTHORI_PAYMENT_METHOD_CREDIT) {
                // クレジット
                await apiClient
                    .get(`${API_ORDER}/boarding/${boarding_no}/payment_credit`)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        for (let i = 0; i < data.length; i++) {
                            authori_credit_array.value.push({
                                cancel_amount: data[i].cancel_amount,
                                sales_datetime: data[i].sales_datetime,
                                modify_reason: data[i].modify_reason,
                                amount: data[i].amount,
                                seq: data[i].seq,
                            })
                        }
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        console.log(e.message)
                        isError = true
                    })
            } else if (payment_method == AUTHORI_PAYMENT_METHOD_PAY_EASY) {
                // Pay-easy
                await apiClient
                    .get(`${API_ORDER}/boarding/${boarding_no}/payment_payeasy`)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        for (let i = 0; i < data.authori_info.length; i++) {
                            authori_payeasy_array.value.push({
                                transfer_deadline_datetime: formatDate(
                                    castStringToDate(data.authori_info[i].transfer_deadline_datetime),
                                    false,
                                    1,
                                    0,
                                ),
                                confirmed_sales_datetime: formatDate(castStringToDate(data.authori_info[i].confirmed_sales_datetime), false, 1, 0),
                                amount: data.authori_info[i].amount,
                                payeasy_customer_number: data.authori_info[i].payeasy_customer_number,
                                payeasy_collection_agency_number: data.authori_info[i].payeasy_collection_agency_number,
                                payeasy_confirmation_number: data.authori_info[i].payeasy_confirmation_number,
                            })
                        }
                        for (let i = 0; i < data.remittance_info.length; i++) {
                            remittance_array.value.push({
                                ins_date: formatDate(castStringToDate(data.remittance_info[i].ins_date), false, 1, 0),
                                deposit_date: formatDate(castStringToDate(data.remittance_info[i].deposit_date), false, 1, 0),
                                refunded_status: data.remittance_info[i].refunded_status,
                                refunded_status_name: data.remittance_info[i].refunded_status_name,
                                refunded_amount: data.remittance_info[i].refunded_amount,
                                payment_amount: data.remittance_info[i].payment_amount,
                                cancel_amount: data.remittance_info[i].cancel_amount,
                                transfer_fee: data.remittance_info[i].transfer_fee,
                            })
                        }
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        console.log(e.message)
                        isError = true
                    })
            } else if (payment_method == AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE) {
                // コンビニ払い
                await apiClient
                    .get(`${API_ORDER}/boarding/${boarding_no}/payment_convenience`)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        for (let i = 0; i < data.authori_info.length; i++) {
                            authori_convenience_array.value.push({
                                transfer_deadline_datetime: formatDate(
                                    castStringToDate(data.authori_info[i].transfer_deadline_datetime),
                                    false,
                                    1,
                                    0,
                                ),
                                confirmed_sales_datetime: formatDate(castStringToDate(data.authori_info[i].confirmed_sales_datetime), false, 1, 0),
                                amount: data.authori_info[i].amount,
                                convenience_type: data.authori_info[i].convenience_type,
                                convenience_name: data.authori_info[i].convenience_name,
                                convenience_confirmation_number: data.authori_info[i].convenience_confirmation_number,
                                convenience_receipt_number: data.authori_info[i].convenience_receipt_number,
                            })
                        }
                        for (let i = 0; i < data.remittance_info.length; i++) {
                            remittance_array.value.push({
                                ins_date: formatDate(castStringToDate(data.remittance_info[i].ins_date), false, 1, 0),
                                deposit_date: formatDate(castStringToDate(data.remittance_info[i].deposit_date), false, 1, 0),
                                refunded_status: data.remittance_info[i].refunded_status,
                                refunded_status_name: data.remittance_info[i].refunded_status_name,
                                refunded_amount: data.remittance_info[i].refunded_amount,
                                payment_amount: data.remittance_info[i].payment_amount,
                                cancel_amount: data.remittance_info[i].cancel_amount,
                                transfer_fee: data.remittance_info[i].transfer_fee,
                            })
                        }
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        console.log(e.message)
                        isError = true
                    })
            }
            console.log('↑↑↑BookingCurrent.getAuthoriDetail↑↑↑')
            return !isError
        }

        // 予約内容確認
        const displayBoardingDetail = async (boarding_no: number, sea_route_code: string) => {
            isDisplayBoardingDetail.value = true
            try {
                store.dispatch('setIsProcessing', true)
                // 予約内容取得
                await getBoardingDetail(boarding_no, sea_route_code)
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
            // location.href = '#confirm';
            $('#btn-confirm-open-modal').click()
            console.log('#confirm')
        }
        // 予約内容取得
        const getBoardingDetail = async (boarding_no: number, sea_route_code: string) => {
            // await getBoardingDetailBoarding(boarding_no),
            // await Promise.all([
            //   getBoardingDetailContract(boarding_no),
            //   getBoardingDetailPassenger(boarding_no),
            //   getBoardingDetailCar(boarding_no),
            //   getBoardingDetailCage(boarding_no),
            // ]);
            // // ドライバー設定反映
            // setPassengerCarRelation();
            store.dispatch('setIsProcessing', true)
            await getDisplayBooking(boarding_no, sea_route_code)
            store.dispatch('setIsProcessing', false)
        }
        // 予約内容取得（乗船情報）
        const getBoardingDetailBoarding = async (boarding_no: number) => {
            console.log('↓↓↓BookingCurrent.getBoardingDetailBoarding↓↓↓')
            boarding.value = {} as Boarding
            await apiClient
                .get(`${API_ORDER}/boarding/${boarding_no}`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    boarding.value = {
                        boarding_no: data.boarding_no,
                        ship_schedule_id: data.ship_schedule_id,
                        dep_date: data.dep_date,
                        dep_time: data.dep_time,
                        sea_route_code: data.sea_route_code,
                        sea_route_name: data.sea_route_name,
                        ship_name: data.ship_name,
                        booking_status: data.booking_status,
                        department_code: data.department_code,
                        supplier_joined_code: data.supplier_joined_code,
                        personality_type: data.personality_type,
                        corporate_name: data.corporate_name,
                        represent_passenger_name: data.represent_passenger_name,
                        represent_passenger_tel: data.represent_passenger_tel,
                        represent_passenger_gender: represent_passenger.value?.gender,
                        represent_passenger_birthday: '',
                        represent_passenger_age: represent_passenger.value?.age,
                        represent_passenger_post_code: data.represent_passenger_post_code,
                        represent_passenger_prefectures: data.represent_passenger_prefectures,
                        represent_passenger_address: data.represent_passenger_address,
                        wheelchair: data.wheelchair,
                        wheelchair_lending: data.wheelchair_lending,
                        oxygen_cylinder: data.oxygen_cylinder != 0,
                        oxygen_cylinder_count: data.oxygen_cylinder,
                        pregnant: Boolean(data.pregnant),
                        pregnant_months: data.pregnant_months,
                        dog_count: data.dog_count,
                        cat_count: data.cat_count,
                        other_count: data.other_count,
                        pet_num: data.pet_num,
                        pet_note: data.pet_note,
                        paid_passenger_count: data.paid_passenger_count,
                        paid_vehicle_count: data.paid_vehicle_count,
                        passenger_paid_total: data.passenger_paid_total,
                        vehicle_paid_total: data.vehicle_paid_total,
                        sales_total: data.sales_total,
                        canceled: data.canceled,
                        divide_type_id: data.divide_type_id,
                        deleted: data.deleted,
                        version: data.version,
                        upd_by: data.upd_by,
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑BookingCurrent.getBoardingDetailBoarding↑↑↑')
        }
        // 車椅子
        const displayWheelChair = computed(() => {
            let value = ''
            if (!isNull(boarding.value)) {
                if (boarding.value!.wheelchair) {
                    if (boarding.value!.wheelchair_lending) {
                        value = '貸出希望'
                    } else {
                        value = '持ち込みあり'
                    }
                } else {
                    value = '持ち込みなし'
                }
            }
            return value
        })
        // 妊婦
        const displayPregnant = computed(() => {
            let value = ''
            if (!isNull(boarding.value)) {
                if (boarding.value!.pregnant) {
                    value += '妊婦がいます'
                    value += `（乗船時${boarding.value!.pregnant_months}カ月）`
                } else {
                    value += '妊婦はいません'
                }
            }
            return value
        })
        // 医療用酸素ボンベ
        const displayOxygenCylinder = computed(() => {
            let value = ''
            if (!isNull(boarding.value)) {
                if (!isNull(boarding.value!.oxygen_cylinder_count) && boarding.value!.oxygen_cylinder_count != 0) {
                    value = `持ち込みあり（持ち込み${boarding.value!.oxygen_cylinder_count}本）`
                } else {
                    value = '持ち込みなし'
                }
            }
            return value
        })
        // 予約内容取得（乗船者）
        const getBoardingDetailPassenger = async (boarding_no: number) => {
            console.log('↓↓↓BookingCurrent.getBoardingDetailPassenger↓↓↓')
            passenger_array.value = []
            await apiClient
                .get(`${API_ORDER}/boarding/${boarding_no}/passenger/`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].represent_flg) {
                            // 代表者
                            represent_passenger.value = {
                                id: data[i].id,
                                line_no: data[i].line_no,
                                represent_flg: Boolean(data[i].represent_flg),
                                driver: Boolean(data[i].driver),
                                family_name_katakana: splitFullname(data[i].name_kana)[0],
                                given_name_katakana: splitFullname(data[i].name_kana)[1],
                                birthday_year: null,
                                birthday_month: null,
                                birthday_day: null,
                                age: data[i].age,
                                passenger_type: data[i].passenger_type,
                                grade: data[i].grade,
                                gender: data[i].gender,
                                version: data[i].version,
                                car_row_no: null,
                                boarding_car_id: data[i].boarding_car_id,
                                mcf_member_id: data[i].mcf_member_id,
                                sales_detail_id: data[i].sales_detail_id,
                                regular_price: data[i].regular_price,
                                baf_amount: data[i].baf_amount,
                                reserved_room: !isNull(data[i].reserve_amount) && data[i].reserve_amount != 0,
                                reserved_amount: data[i].reserve_amount,
                                with_pets_flg: !isNull(data[i].with_pets_amount),
                                with_pets_amount: data[i].with_pets_amount,
                                unit_price: data[i].unit_price,
                                discount_id: data[i].discount_id,
                                discount_rate: data[i].discount_rate,
                                discount_amount: data[i].discount_amount,
                                display_unit_price: data[i].display_unit_price,
                                display_baf_amount: data[i].display_baf_amount,
                                display_reserve_amount: data[i].display_reserve_amount,
                                display_with_pets_amount: data[i].display_with_pets_amount,
                                display_change_grade_amount: data[i].display_change_grade_amount,
                                cabin_id: data[i].cabin_id,
                                seat_type_name: data[i].seat_type_name,
                                seat_type: data[i].seat_type,
                                deleted: false,

                                register_companion: false,
                                companion_id: null,
                                unit_price_before: data[i].unit_price,
                                baf_amount_before: data[i].baf_amount,
                                with_pets_amount_before: data[i].with_pets_amount,
                                reserved_amount_before: data[i].reserve_amount,
                            }
                        } else {
                            // 同行者
                            passenger_array.value.push({
                                id: data[i].id,
                                line_no: data[i].line_no,
                                represent_flg: Boolean(data[i].represent_flg),
                                driver: Boolean(data[i].driver),
                                family_name_katakana: splitFullname(data[i].name_kana)[0],
                                given_name_katakana: splitFullname(data[i].name_kana)[1],
                                birthday_year: null,
                                birthday_month: null,
                                birthday_day: null,
                                age: data[i].age,
                                passenger_type: data[i].passenger_type,
                                grade: data[i].grade,
                                gender: data[i].gender,
                                version: data[i].version,
                                car_row_no: null,
                                boarding_car_id: data[i].boarding_car_id,
                                mcf_member_id: data[i].mcf_member_id,
                                sales_detail_id: data[i].sales_detail_id,
                                regular_price: data[i].regular_price,
                                baf_amount: data[i].baf_amount,
                                reserved_room: !isNull(data[i].reserve_amount) && data[i].reserve_amount != 0,
                                reserved_amount: data[i].reserve_amount,
                                with_pets_flg: !isNull(data[i].with_pets_amount),
                                with_pets_amount: data[i].with_pets_amount,
                                unit_price: data[i].unit_price,
                                discount_id: data[i].discount_id,
                                discount_rate: data[i].discount_rate,
                                discount_amount: data[i].discount_amount,
                                display_unit_price: data[i].display_unit_price,
                                display_baf_amount: data[i].display_baf_amount,
                                display_reserve_amount: data[i].display_reserve_amount,
                                display_with_pets_amount: data[i].display_with_pets_amount,
                                display_change_grade_amount: data[i].display_change_grade_amount,
                                cabin_id: data[i].cabin_id,
                                seat_type_name: data[i].seat_type_name,
                                seat_type: data[i].seat_type,
                                deleted: false,

                                register_companion: false,
                                companion_id: null,
                                unit_price_before: data[i].unit_price,
                                baf_amount_before: data[i].baf_amount,
                                with_pets_amount_before: data[i].with_pets_amount,
                                reserved_amount_before: data[i].reserve_amount,
                            })
                        }
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑BookingCurrent.getBoardingDetailPassenger↑↑↑')
        }
        // 予約内容取得（車両）
        const getBoardingDetailCar = async (boarding_no: number) => {
            console.log('↓↓↓BookingCurrent.getBoardingDetailCar↓↓↓')
            car_array.value = []
            vehicle_array.value = []
            await apiClient
                .get(`${API_ORDER}/boarding/${boarding_no}/vehicle`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].car_type_code == CAR_TYPE_PASSENGER) {
                            car_array.value.push({
                                id: data[i].id,
                                line_no: data[i].line_no,
                                car_type_code: data[i].car_type_code,
                                car_length: data[i].car_length,
                                car_width: DEFAULT_CAR_WIDTH,
                                car_place_name: data[i].car_place_name,
                                car_class_number: data[i].car_class_number,
                                car_hiragana: data[i].car_hiragana,
                                car_number: data[i].car_number,
                                note: data[i].note,
                                version: data[i].version,
                                sales_detail_id: data[i].sales_detail_id,
                                regular_price: data[i].regular_price,
                                baf_amount: data[i].baf_amount,
                                load_amount: data[i].load_amount,
                                unload_amount: data[i].unload_amount,
                                unit_price: data[i].unit_price,
                                discount_id: data[i].discount_id,
                                discount_rate: data[i].discount_rate,
                                discount_amount: data[i].discount_amount,
                                display_unit_price: data[i].display_unit_price,
                                display_baf_amount: data[i].display_baf_amount,
                                display_load_amount: data[i].display_load_amount,
                                display_unload_amount: data[i].display_unload_amount,

                                deleted: false,

                                register_car: false,
                                passenger_line_no: null,
                                vehicle_id: null,
                                unit_price_before: data[i].unit_price,
                                baf_amount_before: data[i].baf_amount,
                            })
                        } else {
                            vehicle_array.value.push({
                                id: data[i].id,
                                line_no: data[i].line_no,
                                car_type_code: data[i].car_type_code,
                                car_length: data[i].car_length,
                                car_width: DEFAULT_TWO_WHEEL_WIDTH,
                                car_place_name: data[i].car_place_name,
                                car_class_number: data[i].car_class_number,
                                car_hiragana: data[i].car_hiragana,
                                car_number: data[i].car_number,
                                note: data[i].note,
                                version: data[i].version,
                                sales_detail_id: data[i].sales_detail_id,
                                regular_price: data[i].regular_price,
                                baf_amount: data[i].baf_amount,
                                load_amount: data[i].load_amount,
                                unload_amount: data[i].unload_amount,
                                unit_price: data[i].unit_price,
                                discount_id: data[i].discount_id,
                                discount_rate: data[i].discount_rate,
                                discount_amount: data[i].discount_amount,
                                display_unit_price: data[i].display_unit_price,
                                display_baf_amount: data[i].display_baf_amount,
                                display_load_amount: data[i].display_load_amount,
                                display_unload_amount: data[i].display_unload_amount,

                                deleted: false,

                                register_car: false,
                                passenger_line_no: null,
                                vehicle_id: null,
                                unit_price_before: data[i].unit_price,
                                baf_amount_before: data[i].baf_amount,
                            })
                        }
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑BookingCurrent.getBoardingDetailCar↑↑↑')
        }
        // 予約内容取得（ペットルーム）
        const getBoardingDetailCage = async (boarding_no: number) => {
            console.log('↓↓↓BookingCurrent.getBoardingDetailCage↓↓↓')
            petcage_array.value = []
            await apiClient
                .get(`${API_ORDER}/boarding/${boarding_no}/petcage`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        petcage_array.value.push({
                            id: data[i].id,
                            cage_type: data[i].cage_type,
                            version: data[i].version,
                            deleted: false,
                        })
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑BookingCurrent.getBoardingDetailCage↑↑↑')
        }
        // ペット種類
        const displayPetNum = computed(() => {
            let values = []
            if (!isNull(boarding.value)) {
                if (boarding.value!.dog_count > 0) {
                    values.push(`犬 ${boarding.value?.dog_count}匹`)
                }
                if (boarding.value!.cat_count > 0) {
                    values.push(`猫 ${boarding.value?.cat_count}匹`)
                }
                if (boarding.value!.other_count > 0) {
                    values.push(`その他 ${boarding.value?.other_count}匹`)
                }
            }
            return values.join(',')
        })
        // ペット区分
        const displayCageNum = computed(() => {
            let values = []
            let large_cnt = 0
            let small_cnt = 0
            if (!isNull(petcage_array.value)) {
                for (let i = 0; i < petcage_array.value.length; i++) {
                    if (petcage_array.value[i].cage_type == CAGE_TYPES_LARGE) {
                        large_cnt++
                    } else if (petcage_array.value[i].cage_type == CAGE_TYPES_SMALL) {
                        small_cnt++
                    }
                }
            }
            if (large_cnt > 0) {
                values.push(`大型 ${large_cnt}部屋`)
            }
            if (small_cnt > 0) {
                values.push(`中小型 ${small_cnt}部屋`)
            }
            return values.join(',')
        })
        // ペットルーム表示有無
        const isShowPetCage = computed(() => {
            if (!isNull(petcage_array.value) && petcage_array.value!.length > 0) {
                return true
            }

            return false
        })
        // 予約内容取得（成約）
        const getBoardingDetailContract = async (boarding_no: number, sea_route_code: string) => {
            console.log('↓↓↓BookingCurrent.getBoardingDetailContract↓↓↓')
            contract.value = await getBoardingContract(String(boarding_no), sea_route_code)
            console.log('↑↑↑BookingCurrent.getBoardingDetailContract↑↑↑')
        }
        const setPassengerCarRelation = () => {
            if (represent_passenger.value!.driver) {
                for (let j = 0; j < car_array.value.length; j++) {
                    if (car_array.value[j].id == represent_passenger.value!.boarding_car_id) {
                        represent_passenger.value!.car_row_no = car_array.value[j].line_no
                        car_array.value[j].passenger_line_no = represent_passenger.value!.line_no
                        break
                    }
                }
            }
            for (let i = 0; i < passenger_array.value.length; i++) {
                if (passenger_array.value[i].driver) {
                    for (let j = 0; j < car_array.value.length; j++) {
                        if (car_array.value[j].id == passenger_array.value[i].boarding_car_id) {
                            passenger_array.value[i].car_row_no = car_array.value[j].line_no
                            car_array.value[j].passenger_line_no = passenger_array.value[i].line_no
                            break
                        }
                    }
                }
            }
        }
        const getDriverName = (passenger_line_no: number | null) => {
            let driverName = ''
            if (!isNull(passenger_line_no)) {
                if (represent_passenger.value!.line_no == passenger_line_no) {
                    driverName = joinFirstNameAndLastName(
                        represent_passenger.value!.family_name_katakana,
                        represent_passenger.value!.given_name_katakana,
                    )
                }
                for (let i = 0; i < passenger_array.value.length; i++) {
                    if (passenger_array.value[i].line_no == passenger_line_no) {
                        driverName = joinFirstNameAndLastName(
                            passenger_array.value[i].family_name_katakana,
                            passenger_array.value[i].given_name_katakana,
                        )
                        break
                    }
                }
            }

            return driverName
        }
        // 表示用料金
        const displayAmount = computed(() => {
            if (isNull(boarding.value?.boarding_no)) {
                return 0
            } else if (isNull(historyList) || historyList.value.length == 0) {
                return 0
            } else {
                for (let i = 0; i < historyList.value.length; i++) {
                    if (historyList.value[i].boarding_no == boarding.value!.boarding_no) {
                        return historyList.value[i].amount
                    }
                }
            }
            return 0
        })
        // 表示用予約内容部屋タイプ名
        const dispSeatTypeName = computed(() => {
            let seat_type_list = []
            let seat_name_list = [] as string[]
            if (!isNull(represent_passenger.value) && !isNull(represent_passenger.value!.seat_type)) {
                seat_type_list.push(represent_passenger.value!.seat_type)
            }
            if (!isNull(passenger_array.value)) {
                for (let i = 0; i < passenger_array.value.length; i++) {
                    if (!isNull(passenger_array.value[i].seat_type)) {
                        seat_type_list.push(passenger_array.value[i].seat_type)
                    }
                }
            }
            seat_type_list = [...new Set(seat_type_list)]
            for (let i = 0; i < seat_type_list.length; i++) {
                seat_name_list.push(getSeatTypeName(seat_type_list[i]))
            }
            return [...new Set(seat_name_list)].join('、')
        })

        /**
         * WEB予約変更可否
         * @param boarding_no 乗船番号
         * @returns [0:変更可否(true,false), 1:変更不可理由]
         */
        const getBoardingChangeable = async (boarding_no: number, mode: number) => {
            let isCanChange = [false, '']
            console.log('↓↓↓BookingCurrent.getBoardingChangeable↓↓↓')
            const postData = {
                division: mode,
            }
            isCanChange = await apiClient
                .post(`${API_ORDER}/boarding/${boarding_no}/changeable`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    return [data.changeable, data.reason]
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    return [false, '予約情報の取得に失敗しました。']
                })
            console.log('↑↑↑BookingCurrent.getBoardingChangeable↑↑↑')

            return isCanChange
        }
        // 予約内容変更
        const changeBoarding = async (history: Boarding | undefined) => {
            try {
                if (!store.state.is_logged_in) {
                    router.push('/member/logout')
                    return
                }

                store.dispatch('setIsProcessing', true)
                let boarding_changeable = await getBoardingChangeable(history!.boarding_no!, 0)
                if (boarding_changeable[0]) {
                    setBookingData(booking.value!)
                    store.dispatch('setIsProcessing', false)
                    nextTick(() => {
                        setTimeout(() => {
                            // 予約入力画面へ遷移
                            // router.push('/booking/registration');
                            location.href = '/booking/registration'
                            // location.reload();
                        }, 300)
                    })
                } else {
                    store.dispatch('setIsProcessing', false)
                    context.emit('displayMessage', {
                        msg_title: '予約内容変更',
                        msg: [boarding_changeable[1]],
                        close_btn: true,
                    })
                }
            } catch (e) {
                console.log(e)
                store.dispatch('setIsProcessing', false)
            }
        }
        // 表示乗船情報取得
        const getDisplayBooking = async (boarding_no: number, sea_route_code: string) => {
            try {
                // 乗船情報を取得
                await Promise.all([
                    getBoardingDetailBoarding(boarding_no),
                    getBoardingDetailContract(boarding_no, sea_route_code),
                    getBoardingDetailPassenger(boarding_no),
                    getBoardingDetailCar(boarding_no),
                    getBoardingDetailCage(boarding_no),
                ])
                // ドライバー設定反映
                setPassengerCarRelation()
                if (boarding.value?.dep_date) {
                    getTransferCharge(boarding.value?.dep_date)
                }

                booking.value = {} as Booking
                booking.value.contract_number = contract.value!.contract_number
                booking.value.cancel_policy_before = contract.value!.cancel_policy
                booking.value.sea_route_code = boarding.value!.sea_route_code!
                booking.value.amount = displayAmount.value
                booking.value.amount_before = displayAmount.value
                booking.value.isUpdate = true

                // 乗用車
                let car_detail_array = [] as CarDetail[]
                let car_3m_num = 0
                let car_4m_num = 0
                let car_5m_num = 0
                let car_6m_num = 0
                if (!isNull(car_array.value)) {
                    for (let i = 0; i < car_array.value.length; i++) {
                        car_detail_array.push({
                            id: car_array.value[i].id,
                            line_no: car_array.value[i].line_no,
                            car_type_code: car_array.value[i].car_type_code,
                            car_length: car_array.value[i].car_length,
                            car_width: DEFAULT_CAR_WIDTH,
                            car_place_name: car_array.value[i].car_place_name,
                            car_class_number: car_array.value[i].car_class_number,
                            car_hiragana: car_array.value[i].car_hiragana,
                            car_number: car_array.value[i].car_number,
                            note: car_array.value[i].note,
                            version: car_array.value[i].version,
                            sales_detail_id: car_array.value[i].sales_detail_id,
                            regular_price: car_array.value[i].regular_price,
                            baf_amount: car_array.value[i].baf_amount,
                            load_amount: car_array.value[i].load_amount,
                            unload_amount: car_array.value[i].unload_amount,
                            unit_price: car_array.value[i].unit_price,
                            discount_id: car_array.value[i].discount_id,
                            discount_rate: car_array.value[i].discount_rate,
                            discount_amount: car_array.value[i].discount_amount,
                            display_unit_price: car_array.value[i].display_unit_price,
                            display_baf_amount: car_array.value[i].display_baf_amount,
                            display_load_amount: car_array.value[i].display_load_amount,
                            display_unload_amount: car_array.value[i].display_unload_amount,

                            deleted: false,

                            register_car: false,
                            passenger_line_no: car_array.value[i].passenger_line_no,
                            vehicle_id: car_array.value[i].vehicle_id,
                            unit_price_before: car_array.value[i].unit_price_before,
                            baf_amount_before: car_array.value[i].baf_amount_before,
                        })
                        if (car_array.value[i].car_length == 3) {
                            car_3m_num++
                        } else if (car_array.value[i].car_length == 4) {
                            car_4m_num++
                        } else if (car_array.value[i].car_length == 5) {
                            car_5m_num++
                        } else if (car_array.value[i].car_length == 6) {
                            car_6m_num++
                        }
                    }
                }
                booking.value.car_detail_array = car_detail_array
                booking.value.car_detail_array_before = car_detail_array
                booking.value.car_3m_num = car_3m_num
                booking.value.car_4m_num = car_4m_num
                booking.value.car_5m_num = car_5m_num
                booking.value.car_6m_num = car_6m_num

                // 二輪車
                let two_wheel_detail_array = [] as CarDetail[]
                let car_bicycle_num = 0
                let car_two_wheel_small_num = 0
                let car_two_wheel_medium_num = 0
                let car_two_wheel_large_num = 0
                if (!isNull(vehicle_array.value)) {
                    for (let i = 0; i < vehicle_array.value.length; i++) {
                        two_wheel_detail_array.push({
                            id: vehicle_array.value[i].id,
                            line_no: vehicle_array.value[i].line_no,
                            car_type_code: vehicle_array.value[i].car_type_code,
                            car_length: vehicle_array.value[i].car_length,
                            car_width: DEFAULT_TWO_WHEEL_WIDTH,
                            car_place_name: vehicle_array.value[i].car_place_name,
                            car_class_number: vehicle_array.value[i].car_class_number,
                            car_hiragana: vehicle_array.value[i].car_hiragana,
                            car_number: vehicle_array.value[i].car_number,
                            note: vehicle_array.value[i].note,
                            version: vehicle_array.value[i].version,
                            sales_detail_id: vehicle_array.value[i].sales_detail_id,
                            regular_price: vehicle_array.value[i].regular_price,
                            baf_amount: vehicle_array.value[i].baf_amount,
                            load_amount: vehicle_array.value[i].load_amount,
                            unload_amount: vehicle_array.value[i].unload_amount,
                            unit_price: vehicle_array.value[i].unit_price,
                            discount_id: vehicle_array.value[i].discount_id,
                            discount_rate: vehicle_array.value[i].discount_rate,
                            discount_amount: vehicle_array.value[i].discount_amount,
                            display_unit_price: vehicle_array.value[i].display_unit_price,
                            display_baf_amount: vehicle_array.value[i].display_baf_amount,
                            display_load_amount: vehicle_array.value[i].display_load_amount,
                            display_unload_amount: vehicle_array.value[i].display_unload_amount,

                            deleted: false,

                            register_car: false,
                            passenger_line_no: vehicle_array.value[i].passenger_line_no,
                            vehicle_id: vehicle_array.value[i].vehicle_id,
                            unit_price_before: vehicle_array.value[i].unit_price_before,
                            baf_amount_before: vehicle_array.value[i].baf_amount_before,
                        })
                        if (vehicle_array.value[i].car_type_code == CAR_TYPE_BICYCLE) {
                            car_bicycle_num++
                        } else if (vehicle_array.value[i].car_type_code == CAR_TYPE_TWO_WHEEL_SMALL) {
                            car_two_wheel_small_num++
                        } else if (vehicle_array.value[i].car_type_code == CAR_TYPE_TWO_WHEEL_MEDIUM) {
                            car_two_wheel_medium_num++
                        } else if (vehicle_array.value[i].car_type_code == CAR_TYPE_TWO_WHEEL_LARGE) {
                            car_two_wheel_large_num++
                        }
                    }
                }
                booking.value.two_wheel_detail_array = two_wheel_detail_array
                booking.value.two_wheel_detail_array_before = two_wheel_detail_array
                booking.value.car_bicycle_num = car_bicycle_num
                booking.value.car_two_wheel_small_num = car_two_wheel_small_num
                booking.value.car_two_wheel_medium_num = car_two_wheel_medium_num
                booking.value.car_two_wheel_large_num = car_two_wheel_large_num

                // ペットケージ
                let cage_detail_array = [] as CageDetail[]
                let cage_small_num = 0
                let cage_large_num = 0
                if (!isNull(petcage_array.value)) {
                    for (let i = 0; i < petcage_array.value.length; i++) {
                        if (petcage_array.value[i].cage_type == CAGE_TYPES_SMALL) {
                            cage_small_num++
                        } else if (petcage_array.value[i].cage_type == CAGE_TYPES_LARGE) {
                            cage_large_num++
                        }
                        cage_detail_array.push({
                            id: petcage_array.value[i].id,
                            cage_type: petcage_array.value[i].cage_type,
                            version: petcage_array.value[i].version,
                            deleted: false,
                        })
                    }
                }
                booking.value.cage_detail_array = cage_detail_array
                booking.value.cage_detail_array_before = cage_detail_array
                booking.value.cage_small_num = cage_small_num
                booking.value.cage_large_num = cage_large_num

                // 乗船者
                let headcount_distributions = [] as HeadcountDistribution[]
                let passenger_detail_array = [] as PassengerDetail[]
                let passenger_detail_baby_array = [] as PassengerDetail[]
                let seat_type = null
                let grade = null
                let passenger_adult_num = 0
                let passenger_child_num = 0
                let passenger_infant_num = 0
                let passenger_baby_num = 0
                // 代表者
                if (!isNull(represent_passenger.value)) {
                    grade = represent_passenger.value?.grade
                    seat_type = represent_passenger.value?.seat_type
                    if (represent_passenger.value?.passenger_type == PASSENGER_TYPE_ADULT) {
                        passenger_adult_num++
                        passenger_detail_array.push(represent_passenger.value)
                    } else if (represent_passenger.value?.passenger_type == PASSENGER_TYPE_CHILD) {
                        passenger_child_num++
                        passenger_detail_array.push(represent_passenger.value)
                    } else if (represent_passenger.value?.passenger_type == PASSENGER_TYPE_INFANT) {
                        passenger_infant_num++
                        booking.value.passenger_detail_baby_array.push(represent_passenger.value)
                    } else if (represent_passenger.value?.passenger_type == PASSENGER_TYPE_BABY) {
                        passenger_baby_num++
                        booking.value.passenger_detail_baby_array.push(represent_passenger.value)
                    }
                    // 新規部屋追加
                    if (isNull(headcount_distributions.find((x) => x.cabin_id == represent_passenger.value?.cabin_id))) {
                        headcount_distributions.push({
                            cabin_id: represent_passenger.value?.cabin_id,
                            line_no: headcount_distributions.length + 1,
                            adult: 0,
                            child: 0,
                            infant: 0,
                            baby: 0,
                            reserved_room: false,
                            room_amount: 0,
                            passenger_detail_array: [],
                        } as HeadcountDistribution)
                    }
                    // 該当部屋検索
                    let room_idx = null
                    for (let i = 0; i < headcount_distributions.length; i++) {
                        if (headcount_distributions[i].cabin_id == represent_passenger.value?.cabin_id) {
                            room_idx = i
                            break
                        }
                    }
                    // 乗船者追加
                    headcount_distributions[room_idx!].passenger_detail_array.push(represent_passenger.value!)
                    if (represent_passenger.value!.passenger_type == PASSENGER_TYPE_ADULT) {
                        headcount_distributions[room_idx!].adult++
                    } else if (represent_passenger.value!.passenger_type == PASSENGER_TYPE_CHILD) {
                        headcount_distributions[room_idx!].child++
                    } else if (represent_passenger.value!.passenger_type == PASSENGER_TYPE_INFANT) {
                        headcount_distributions[room_idx!].infant++
                    } else if (represent_passenger.value!.passenger_type == PASSENGER_TYPE_BABY) {
                        headcount_distributions[room_idx!].baby++
                    }
                    if (represent_passenger.value!.reserved_room) {
                        headcount_distributions[room_idx!].reserved_room = true
                    }
                }
                // 同行者
                if (!isNull(passenger_array.value)) {
                    grade = represent_passenger.value?.grade
                    for (let i = 0; i < passenger_array.value.length; i++) {
                        if (
                            passenger_array.value[i].passenger_type == PASSENGER_TYPE_INFANT ||
                            passenger_array.value[i].passenger_type == PASSENGER_TYPE_BABY
                        ) {
                            // 乳幼児
                            if (passenger_array.value[i].passenger_type == PASSENGER_TYPE_INFANT) {
                                passenger_infant_num++
                            } else if (passenger_array.value[i].passenger_type == PASSENGER_TYPE_BABY) {
                                passenger_baby_num++
                            }
                            passenger_detail_baby_array.push(passenger_array.value[i])
                        } else {
                            // 同乗者
                            // 新規部屋追加
                            if (isNull(headcount_distributions.find((x) => x.cabin_id == passenger_array.value[i].cabin_id))) {
                                headcount_distributions.push({
                                    cabin_id: passenger_array.value[i].cabin_id,
                                    line_no: headcount_distributions.length + 1,
                                    adult: 0,
                                    child: 0,
                                    infant: 0,
                                    baby: 0,
                                    reserved_room: false,
                                    room_amount: 0,
                                    passenger_detail_array: [],
                                } as HeadcountDistribution)
                            }
                            // 該当部屋検索
                            let room_idx = null
                            for (let j = 0; j < headcount_distributions.length; j++) {
                                if (headcount_distributions[j].cabin_id == passenger_array.value[i].cabin_id) {
                                    room_idx = j
                                    break
                                }
                            }
                            // 乗船者追加
                            headcount_distributions[room_idx!].passenger_detail_array.push(passenger_array.value[i])
                            passenger_detail_array.push(passenger_array.value[i])
                            if (passenger_array.value[i].passenger_type == PASSENGER_TYPE_ADULT) {
                                headcount_distributions[room_idx!].adult++
                            } else if (passenger_array.value[i].passenger_type == PASSENGER_TYPE_CHILD) {
                                headcount_distributions[room_idx!].child++
                            } else if (passenger_array.value[i].passenger_type == PASSENGER_TYPE_INFANT) {
                                headcount_distributions[room_idx!].infant++
                            } else if (passenger_array.value[i].passenger_type == PASSENGER_TYPE_BABY) {
                                headcount_distributions[room_idx!].baby++
                            }
                            if (passenger_array.value[i].reserved_room) {
                                headcount_distributions[room_idx!].reserved_room = true
                            }
                        }
                    }
                }

                booking.value.headcount_distributions = headcount_distributions
                booking.value.headcount_distributions_before = headcount_distributions
                booking.value.grade = grade!
                booking.value.seat_type = seat_type!
                booking.value.passenger_adult_num = passenger_adult_num
                booking.value.passenger_child_num = passenger_child_num
                booking.value.passenger_infant_num = passenger_infant_num
                booking.value.passenger_baby_num = passenger_baby_num
                booking.value.passenger_detail_array = passenger_detail_array
                booking.value.passenger_detail_array_before = passenger_detail_array
                booking.value.passenger_detail_baby_array = passenger_detail_baby_array
                booking.value.passenger_detail_baby_array_before = passenger_detail_baby_array

                booking.value.dep_date = boarding.value!.dep_date
                booking.value.dep_date_before = boarding.value!.dep_date
                // 乗船情報
                booking.value.boarding = {
                    boarding_no: boarding.value!.boarding_no,
                    ship_schedule_id: boarding.value!.ship_schedule_id,
                    dep_date: boarding.value!.dep_date,
                    dep_time: boarding.value!.dep_time,
                    sea_route_code: boarding.value?.sea_route_code,
                    sea_route_name: boarding.value?.sea_route_name,
                    ship_name: boarding.value?.ship_name,
                    booking_status: boarding.value?.booking_status,
                    department_code: boarding.value?.department_code,
                    supplier_joined_code: boarding.value?.supplier_joined_code,
                    personality_type: PERSONALITY_TYPE_INDIVIDUAL,
                    corporate_name: boarding.value?.corporate_name,
                    represent_passenger_name: joinFirstNameAndLastName(
                        represent_passenger.value!.family_name_katakana,
                        represent_passenger.value!.given_name_katakana,
                    ),
                    represent_passenger_tel: boarding.value?.represent_passenger_tel,
                    represent_passenger_gender: represent_passenger.value?.gender,
                    represent_passenger_birthday: '',
                    represent_passenger_age: represent_passenger.value?.age,
                    represent_passenger_post_code: boarding.value?.represent_passenger_post_code,
                    represent_passenger_prefectures: boarding.value?.represent_passenger_prefectures + '',
                    represent_passenger_address: boarding.value?.represent_passenger_address,
                    wheelchair: boarding.value?.wheelchair,
                    wheelchair_lending: boarding.value?.wheelchair_lending,
                    oxygen_cylinder: boarding.value?.oxygen_cylinder,
                    oxygen_cylinder_count: boarding.value?.oxygen_cylinder_count,
                    pregnant: boarding.value?.pregnant,
                    pregnant_months: boarding.value?.pregnant_months,
                    dog_count: boarding.value?.dog_count,
                    cat_count: boarding.value?.cat_count,
                    other_count: boarding.value?.other_count,
                    pet_num: boarding.value?.pet_num,
                    pet_note: boarding.value?.pet_note,
                    paid_passenger_count: boarding.value?.paid_passenger_count,
                    paid_vehicle_count: boarding.value?.paid_vehicle_count,
                    passenger_paid_total: boarding.value?.passenger_paid_total,
                    vehicle_paid_total: boarding.value?.vehicle_paid_total,
                    sales_total: boarding.value?.sales_total,
                    canceled: boarding.value?.canceled,
                    divide_type_id: boarding.value?.divide_type_id,
                    deleted: boarding.value?.deleted,
                    version: boarding.value?.version,
                    upd_by: boarding.value?.upd_by,
                } as Boarding
            } catch (e) {
                console.log(e)
            }
        }

        // 予約キャンセル
        const cancelBoardingStart = (boarding_no: number | null | undefined) => {
            // 確認メッセージ
            cancel_boarding_no.value = boarding_no
            // context.emit("displayMessage", {
            //   msg_title: '予約キャンセル',
            //   msg: ['予約をキャンセルします。', 'よろしいですか？'],
            //   close_btn: true,
            //   ok_btn: true,
            //   submit_btn: false,
            //   ok_event: cancelBoarding,
            //   cancel_btn_value: 'いいえ',
            //   ok_btn_value: 'はい',
            // });
            cancelBookingDialog.value!.dispBooking(booking.value!, contract.value!)
            $('#btn-cancel-booking-open-modal').click()
        }
        const cancelBoarding = async () => {
            console.log('↓↓↓BookingCurrent.cancelBoarding↓↓↓')
            let ret = false
            try {
                store.dispatch('setIsProcessing', true)
                let boarding_changeable = await getBoardingChangeable(cancel_boarding_no.value, 1)
                if (boarding_changeable[0]) {
                    const postData = {
                        boarding_no: cancel_boarding_no.value + '',
                    }
                    ret = await apiClient
                        .put(`${API_ORDER}/cancel`, postData)
                        .then((res: AxiosResponse<any>) => {
                            store.dispatch('setIsProcessing', false)
                            const { status, data } = res
                            context.emit('displayMessage', {
                                msg_title: '予約キャンセル',
                                msg: ['予約キャンセルが完了しました。'],
                                ok_btn: true,
                            })
                            return true
                        })
                        .catch((e: AxiosError<{ error: string }>) => {
                            // エラー処理
                            store.dispatch('setIsProcessing', false)
                            console.log(e.message)
                            context.emit('displayMessage', {
                                msg_title: '予約キャンセル',
                                msg: ['予約キャンセルに失敗しました。'],
                                close_btn: true,
                            })
                            return false
                        })
                } else {
                    context.emit('displayMessage', {
                        msg_title: '予約キャンセル',
                        msg: [boarding_changeable[1]],
                        close_btn: true,
                    })
                }
                if (ret) {
                    await getBookingHistory(null)
                }
            } catch (e) {
                console.log(e)
            }
            console.log('↑↑↑BookingCurrent.cancelBoarding↑↑↑')
        }

        // 部屋タイプ名取得
        const getSeatTypeNames = (seats: string | null): string => {
            if (isNull(seats)) {
                return ''
            }
            let seat_list = [...new Set(seats!.split(','))]
            let seat_name_list = []
            for (let i = 0; i < seat_list.length; i++) {
                seat_name_list.push(getSeatTypeName(Number(seat_list[i])))
            }
            return [...new Set(seat_name_list)].join('、')
        }

        // マイページに移動
        const moveMyPage = () => {
            router.push('/member/my_page')
        }
        // 過去の予約
        const moveBookingHistory = () => {
            router.push('/booking/history')
        }
        // メッセージ表示
        const displayMessage = ({
            msg_title,
            msg,
            close_btn,
            ok_btn,
            submit_btn,
            close_event,
            ok_event,
            submit_event,
            cancel_btn_value,
            ok_btn_value,
            submit_btn_value,
        }: {
            msg_title: string
            msg: string[]
            close_btn: boolean
            ok_btn: boolean
            submit_btn: boolean
            close_event: any
            ok_event: any
            submit_event: any
            cancel_btn_value: string
            ok_btn_value: string
            submit_btn_value: string
        }) => {
            context.emit('displayMessage', {
                msg_title: msg_title,
                msg: msg,
                close_btn: close_btn,
                ok_btn: ok_btn,
                submit_btn: submit_btn,
                close_event: close_event,
                ok_event: ok_event,
                submit_event: submit_event,
                cancel_btn_value: cancel_btn_value,
                ok_btn_value: ok_btn_value,
                submit_btn_value: submit_btn_value,
            })
        }

        onMounted(async () => {
            console.log('↓↓↓BookingCurrent.onMounted↓↓↓')
            await getBookingHistory(null)
            nextTick(() => {
                try {
                    // モーダル再読み込み
                    const remodalInst = $('.remodal').remodal()
                    remodalInst.reload()
                } catch (e) {
                    console.log(e)
                }
            })
            $(document).on('opened', '.remodal', function () {
                setTimeout(function () {
                    $('.confirm__list').scrollTop(0)
                }, 30)
            })
            // $('#confirm').on('shown', function () {
            //   console.log('confirm open!!!!!!!!!!!!');
            //   $('#confirm-price').animate({ scrollTop: 0 }, 'slow');
            // });
            console.log('↑↑↑BookingCurrent.onMounted↑↑↑')
        })

        return {
            historyList,
            historyListSorted,
            authori_array,
            authori_credit_array,
            authori_payeasy_array,
            authori_convenience_array,
            remittance_array,
            cancelBookingDialog,
            isDisplayBoardingDetail,

            historyCount,
            boarding,
            displayWheelChair,
            displayPregnant,
            displayOxygenCylinder,
            represent_passenger,
            contract,
            passenger_array,
            car_array,
            vehicle_array,
            petcage_array,
            displayPetNum,
            displayCageNum,
            isShowPetCage,
            displayAmount,
            dispSeatTypeName,

            sortList,
            getBookingHistory,
            isCounterPayment,
            displayAuthori,
            displayBoardingDetail,
            moveMyPage,
            moveBookingHistory,
            getBoardingChangeable,
            changeBoarding,
            cancelBoarding,
            cancelBoardingStart,
            getDriverName,

            isNull,
            formatDate,
            formatDateTime,
            castStringToDate,
            convertText,
            getCarTypeName,
            joinFirstNameAndLastName,
            getPassengerPrice,
            getCarPrice,

            displayMessage,

            PASSENGER_TYPES,
            GENDERS,
            PREFECTURES,
            AUTHORI_PAYMENT_METHOD_CREDIT,
            AUTHORI_PAYMENT_METHOD_PAY_EASY,
            AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE,
            PASSENGER_TYPE_ADULT,
            PASSENGER_TYPE_CHILD,
            PASSENGER_TYPE_INFANT,
            PASSENGER_TYPE_BABY,
            CONVENIENCE_TYPE_FAMILY_MART,
            CONVENIENCE_TYPE_LAWSON,
            CONVENIENCE_TYPE_MINI_STOP,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'BookingCurrent',
    data() {
        return {}
    },
    components: {
        ShipAnimationEnd,
        CancelBookingDialog,
    },
    methods: {},
})
</script>
<style></style>
