import {
    Booking,
    CompanionDetail,
    VehicleDetail,
    MemberDetail,
    ReceiptDetail,
    SearchConfirmationVacancy,
    RouterPushProperties,
    HistoryDetail,
} from '@/lib/types'

// 予約情報
export const setBookingData = (data: Booking): void => {
    localStorage.setItem('booking', JSON.stringify(data))
}
export const getBookingData = (): Booking => {
    return JSON.parse(localStorage.getItem('booking') as string) as Booking
}
export const removeBookingData = (): void => {
    localStorage.removeItem('booking')
}

// 同乗者マスタ
export const setCompanionData = (data: CompanionDetail[]): void => {
    localStorage.setItem('companion', JSON.stringify(data))
}
export const getCompanionData = (): CompanionDetail[] => {
    return JSON.parse(localStorage.getItem('companion') as string) as CompanionDetail[]
}
export const removeCompanionData = (): void => {
    localStorage.removeItem('companion')
}
// 車両マスタ
export const setVehicleData = (data: VehicleDetail[]): void => {
    localStorage.setItem('vehicle', JSON.stringify(data))
}
export const getVehicleData = (): VehicleDetail[] => {
    return JSON.parse(localStorage.getItem('vehicle') as string) as VehicleDetail[]
}
export const removeVehicleData = (): void => {
    localStorage.removeItem('vehicle')
}

// 会員情報
export const setMemberData = (data: MemberDetail): void => {
    localStorage.setItem('member', JSON.stringify(data))
}
export const getMemberData = (): MemberDetail => {
    return JSON.parse(localStorage.getItem('member') as string) as MemberDetail
}
export const removeMemberData = (): void => {
    localStorage.removeItem('member')
}

// 領収書
export const setReceiptData = (data: ReceiptDetail): void => {
    localStorage.setItem('receipt', JSON.stringify(data))
}
export const getReceiptData = (): ReceiptDetail => {
    return JSON.parse(localStorage.getItem('receipt') as string) as ReceiptDetail
}
export const removeReceiptData = (): void => {
    localStorage.removeItem('receipt')
}

// 空席照会検索条件
export const setSearchConfirmationVacancyData = (data: SearchConfirmationVacancy): void => {
    localStorage.setItem('search_confirmation_vacancy', JSON.stringify(data))
}
export const getSearchConfirmationVacancyData = (): SearchConfirmationVacancy => {
    return JSON.parse(localStorage.getItem('search_confirmation_vacancy') as string) as SearchConfirmationVacancy
}
export const removeSearchConfirmationVacancyData = (): void => {
    localStorage.removeItem('search_confirmation_vacancy')
}

// ページ間データ保持用
export const setRouterPushPropertiesData = (data: RouterPushProperties): void => {
    localStorage.setItem('router_push_properties', JSON.stringify(data))
}
export const getRouterPushPropertiesData = (): RouterPushProperties => {
    return JSON.parse(localStorage.getItem('router_push_properties') as string) as RouterPushProperties
}
export const removeRouterPushPropertiesData = (): void => {
    localStorage.removeItem('router_push_properties')
}

// 履歴管理用
export const setHistoryDetailData = (data: HistoryDetail): void => {
    localStorage.setItem('history_detail', JSON.stringify(data))
}
export const getHistoryDetailData = (): HistoryDetail => {
    return JSON.parse(localStorage.getItem('history_detail') as string) as HistoryDetail
}
export const removeHistoryDetailData = (): void => {
    localStorage.removeItem('history_detail')
}
