<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">{{ isUpdate ? '予約更新' : '予約入力' }}</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- ticket -->
        <section class="ticket ticket-top none">
            <div class="ticket__container">
                <!-- ticket__list -->
                <ul id="ticket__list" class="ticket__list">
                    <li class="ticket__item">
                        <div class="ticket__item-header">
                            <h3 class="ticket__item-name">チケット情報</h3>
                        </div>
                        <div class="ticket__item-container">
                            <div class="output">
                                <dl class="output__list">
                                    <!-- line -->
                                    <div class="output__line">
                                        <!-- ship-room -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-room">船室</div>
                                            </dt>
                                            <dd class="output__container output__container-room">
                                                <div class="output__data output__data-room">
                                                    {{ getSeatTypeName(seat_type) }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- plan -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-plan">プラン</div>
                                            </dt>
                                            <dd class="output__container output__container-plan">
                                                <div class="output__data output__data-plan">
                                                    {{ contract?.contract_name }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- route -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-route">ご利用区間</div>
                                            </dt>
                                            <dd class="output__container output__container-route">
                                                <div class="output__data output__data-route">
                                                    {{ getSeaRouteCodeName(sea_route_code) }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- ship-name -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-ship">船名</div>
                                            </dt>
                                            <dd class="output__container output__container-ship">
                                                <div class="output__data output__data-ship">
                                                    {{ boarding?.ship_name }}
                                                </div>
                                            </dd>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="output__line">
                                        <!-- date -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-date">乗船日</div>
                                            </dt>
                                            <dd class="output__container output__container-date">
                                                <div class="output__data output__data-date">
                                                    {{ formatDate(castStringToDate(boarding?.dep_date), false, 2) }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- time -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-time">出航時刻</div>
                                            </dt>
                                            <dd class="output__container output__container-time">
                                                <div class="output__data output__data-time">
                                                    {{ boarding?.dep_time }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- calender -->
                                        <div class="output__item output__item-calender">
                                            <dt class="output__header output__header-calender">
                                                <div class="output__name output__name-calender"></div>
                                            </dt>
                                            <dd class="output__container output__container-calender" id="plan_list_calendar">
                                                <EmptyCalendar
                                                    v-model="change_dep_date"
                                                    mode="2"
                                                    :sea_route_code="sea_route_code"
                                                    :seat_type="seat_type"
                                                    :contract_number="contract_number"
                                                    :cabin_seat_count="getMinRoomSeatCount()"
                                                    :car_count="getCarCount()"
                                                    :bicycle_count="getTwoWheelCount()"
                                                    :contract_start_date="contract?.start_date"
                                                    :contract_end_date="contract?.end_date"
                                                />
                                            </dd>
                                        </div>
                                        <!-- ship-name -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-fee">運賃合計</div>
                                            </dt>
                                            <dd class="output__container output__container-fee">
                                                <div class="output__data output__data-fee">
                                                    {{ isDisplayAmount ? calcPrice().toLocaleString() + '円' : '計算中' }}
                                                </div>
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <!-- /ticket -->
        <!-- animation -->
        <ShipAnimationStart :sea_route_code="sea_route_code"></ShipAnimationStart>
        <!-- /animation -->

        <!-- form -->
        <section class="form">
            <div class="form__header none">
                <h2 class="form__name">予約情報のご入力</h2>
            </div>
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <!-- room -->
                    <RoomList
                        ref="roomList"
                        :dep_date="boarding?.dep_date"
                        :sea_route_code="sea_route_code"
                        :contract_number="contract_number"
                        :isUpdate="isUpdate"
                        v-on:commitPassengerList="commitPassengerList"
                        v-on:displayMessage="displayMessage"
                    ></RoomList>
                    <!-- room close -->
                    <!-- baby -->
                    <EditBabyList
                        ref="babyList"
                        :dep_date="boarding?.dep_date"
                        :sea_route_code="sea_route_code"
                        :contract_number="contract_number"
                        :grade="grade"
                        :seat_type="seat_type"
                        :isUpdate="isUpdate"
                    >
                    </EditBabyList>
                    <!-- baby close -->
                    <!-- baby-plus -->
                    <li class="form__plus">
                        <button class="btn-border btn-border-plus" type="button" v-on:click="addBaby">乳幼児を追加する</button>
                    </li>
                    <!-- car -->
                    <BoardingCarList
                        ref="carList"
                        :dep_date="boarding?.dep_date"
                        :sea_route_code="sea_route_code"
                        :contract_number="contract_number"
                        :car_type="0"
                        v-on:commitDriver="commitDriver"
                        v-on:displayMessage="displayMessage"
                        :isUpdate="isUpdate"
                    ></BoardingCarList>
                    <!-- car close -->
                    <!-- car-plus -->
                    <li class="form__plus">
                        <button class="btn-border btn-border-plus" type="button" :disabled="isCarRestrict" v-on:click="addCar">
                            乗用車を追加する
                        </button>
                    </li>
                    <!-- bike -->
                    <BoardingCarList
                        ref="twoWheelList"
                        :dep_date="boarding?.dep_date"
                        :sea_route_code="sea_route_code"
                        :contract_number="contract_number"
                        :car_type="1"
                        :isUpdate="isUpdate"
                        v-on:displayMessage="displayMessage"
                    ></BoardingCarList>
                    <!-- bike close -->
                    <!-- bike-plus -->
                    <li class="form__plus">
                        <button class="btn-border btn-border-plus" type="button" :disabled="isBycycleRestrict" v-on:click="addTwoWheel">
                            二輪車を追加する
                        </button>
                    </li>
                    <!-- pet -->
                    <CageList
                        ref="cageList"
                        :dep_date="boarding?.dep_date"
                        :sea_route_code="sea_route_code"
                        :contract_number="contract_number"
                        :seat_type="seat_type"
                        :isUpdate="isUpdate"
                    ></CageList>
                    <!-- 特記事項 -->
                    <li id="form__item" class="form__item">
                        <div class="form__item-header">
                            <h3 class="form__item-name">特記事項</h3>
                        </div>
                        <div class="form__item-container">
                            <div class="input">
                                <dl class="input__list">
                                    <!-- line -->
                                    <div id="line-pregnant" class="input__line input__line-pregnant">
                                        <!-- pregnant -->
                                        <div id="input__item-pregnant-pack" class="input__item-pregnant-pack">
                                            <div id="input__item-pregnant" class="input__item input__item-pregnant">
                                                <dt id="input__header-pregnant" class="input__header input__header-pregnant">
                                                    <label id="input__name-pregnant" class="input__name input__name-pregnant" for="">妊婦</label>
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd id="input__container-pregnant" class="input__container input__container-pregnant">
                                                    <div
                                                        id="input__radio-pregnant-none"
                                                        class="input__radio input__radio-pregnant input__radio-pregnant-none"
                                                    >
                                                        <input
                                                            id="input__pregnant-none"
                                                            class="input__radio-btn input__pregnant input__pregnant-none"
                                                            type="radio"
                                                            name="input__pregnant"
                                                            v-model="pregnant"
                                                            :value="false"
                                                        />
                                                        <label
                                                            id="input__label-pregnant-none"
                                                            class="input__radio-label input__label-pregnant-none"
                                                            for="input__pregnant-none"
                                                            >妊婦はいません</label
                                                        >
                                                    </div>
                                                    <div
                                                        id="input__radio-pregnant-exist"
                                                        class="input__radio input__radio-pregnant input__radio-pregnant-exist"
                                                    >
                                                        <input
                                                            id="input__pregnant-exist"
                                                            class="input__radio-btn input__pregnant input__pregnant-exist"
                                                            type="radio"
                                                            name="input__pregnant"
                                                            v-model="pregnant"
                                                            :value="true"
                                                        />
                                                        <label
                                                            id="input__label-pregnant-exist"
                                                            class="input__radio-label input__label-pregnant-exist"
                                                            for="input__pregnant-exist"
                                                            >妊婦がいます</label
                                                        >
                                                    </div>
                                                    <div
                                                        id="input__radio-pregnant-month"
                                                        class="input__form input__form-pregnant input__form-pregnant-month"
                                                    >
                                                        <span>（乗船時</span>
                                                        <input
                                                            id="input__pregnant-month"
                                                            class="input__form input__pregnant-month"
                                                            type="number"
                                                            name="input__pregnant-month"
                                                            min="1"
                                                            max="8"
                                                            v-model="pregnant_months"
                                                        />
                                                        <span>カ月）</span>
                                                    </div>
                                                </dd>
                                            </div>
                                        </div>
                                        <span id="pregnant-error-text">
                                            <p class="input__error-text">妊婦を選択してください</p>
                                        </span>
                                        <span id="pregnant-month-required-error-text">
                                            <p class="input__error-text">乗船時の月数は1ヶ月～7ヶ月で入力してください</p>
                                        </span>
                                        <span id="pregnant-month-error-text">
                                            <p class="input__error-text">ご本人様の安全確保のため、妊娠８か月以降の方のご乗船をお断りしております</p>
                                        </span>
                                        <p id="input__note-pregnant" class="input__note input__note-pregnant">
                                            妊婦のお客様のご乗船につきましては、ご本人様の安全確保を第一に考え、<b>妊娠8ヶ月（妊娠第28週）以降</b>の方の<b
                                                >ご乗船をお断りいたしております。</b
                                            ><br />
                                            妊娠8か月未満のお客様につきましても、航海中の天候等を勘案いたしまして、お身体の安全が確保できないと船長が判断した場合には、ご乗船をお断りすることがございます。
                                        </p>
                                    </div>
                                    <!-- line -->
                                    <div id="line-wheelchair" class="input__line">
                                        <!-- wheelchair -->
                                        <div id="input__item-wheelchair" class="input__item input__item-wheelchair">
                                            <dt id="input__header-wheelchair" class="input__header input__header-wheelchair">
                                                <label id="input__name-wheelchair" class="input__name input__name-wheelchair" for="">車椅子</label>
                                            </dt>
                                            <dd id="input__container-wheelchair" class="input__container input__container-wheelchair">
                                                <div
                                                    id="input__radio-wheelchair-none"
                                                    class="input__radio input__radio-wheelchair input__radio-wheelchair-none"
                                                >
                                                    <input
                                                        id="input__wheelchair-none"
                                                        class="input__radio-btn input__wheelchair input__wheelchair-none"
                                                        type="radio"
                                                        name="input__wheelchair"
                                                        v-model="wheelchair_select"
                                                        :value="WHEELCHAIR_NONE"
                                                    />
                                                    <label
                                                        id="input__label-wheelchair-none"
                                                        class="input__radio-label input__label-wheelchair-none"
                                                        for="input__wheelchair-none"
                                                        >持ち込みなし</label
                                                    >
                                                </div>
                                                <div
                                                    id="input__radio-wheelchair-exist"
                                                    class="input__radio input__radio-wheelchair input__radio-wheelchair-exist"
                                                >
                                                    <input
                                                        id="input__wheelchair-exist"
                                                        class="input__radio-btn input__wheelchair input__wheelchair-exist"
                                                        type="radio"
                                                        name="input__wheelchair"
                                                        v-model="wheelchair_select"
                                                        :value="WHEELCHAIR_EXIST"
                                                    />
                                                    <label
                                                        id="input__label-wheelchair-exist"
                                                        class="input__radio-label input__label-wheelchair-exist"
                                                        for="input__wheelchair-exist"
                                                        >持ち込みあり</label
                                                    >
                                                </div>
                                                <div
                                                    id="input__radio-wheelchair-rental"
                                                    class="input__radio input__radio-wheelchair input__radio-wheelchair-rental"
                                                >
                                                    <input
                                                        id="input__wheelchair-rental"
                                                        class="input__radio-btn input__wheelchair input__wheelchair-rental"
                                                        type="radio"
                                                        name="input__wheelchair"
                                                        v-model="wheelchair_select"
                                                        :value="WHEELCHAIR_RENTAL"
                                                    />
                                                    <label
                                                        id="input__label-wheelchair-rental"
                                                        class="input__radio-label input__label-wheelchair-rental"
                                                        for="input__wheelchair-rental"
                                                        >貸出希望</label
                                                    >
                                                </div>
                                            </dd>
                                            <span id="wheelchair-error-text">
                                                <p class="input__error-text">車椅子を選択してください</p>
                                            </span>
                                            <p id="input__note-pregnant" class="input__note input__note-pregnant">
                                                展望浴室や⼀部施設はご利⽤いただけない場合がございますのであらかじめご了承ください。詳しくは<a
                                                    target="_blank"
                                                    href="https://www.miyazakicarferry.com/booking/precautions/#wheelchair"
                                                    >こちら</a
                                                ><br />
                                                &lt; 貸出希望のお客様 &gt;<br />
                                                貸出できる車椅子には数に限りがあるため、ご要望にお応えできない場合がございます。
                                            </p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div id="line-cylinder" class="input__line input__line-cylinder">
                                        <!-- cylinder -->
                                        <div id="input__item-cylinder-pack" class="input__item-cylinder-pack">
                                            <div id="input__item-cylinder" class="input__item input__item-cylinder">
                                                <dt id="input__header-cylinder" class="input__header input__header-cylinder">
                                                    <label id="input__name-cylinder" class="input__name input__name-cylinder" for=""
                                                        >医療用酸素ボンベ</label
                                                    >
                                                </dt>
                                                <dd id="input__container-cylinder" class="input__container input__container-cylinder">
                                                    <div
                                                        id="input__radio-cylinder-none"
                                                        class="input__radio input__radio-cylinder input__radio-cylinder-none"
                                                    >
                                                        <input
                                                            id="input__cylinder-none"
                                                            class="input__radio-btn input__cylinder input__cylinder-none"
                                                            type="radio"
                                                            name="input__cylinder"
                                                            v-model="oxygen_cylinder"
                                                            :value="false"
                                                        />
                                                        <label
                                                            id="input__label-cylinder-none"
                                                            class="input__radio-label input__label-cylinder-none"
                                                            for="input__cylinder-none"
                                                            >持ち込みなし</label
                                                        >
                                                    </div>
                                                    <div
                                                        id="input__radio-cylinder-bring"
                                                        class="input__radio input__radio-cylinder input__radio-cylinder-bring"
                                                    >
                                                        <input
                                                            id="input__cylinder-bring"
                                                            class="input__radio-btn input__cylinder input__cylinder-bring"
                                                            type="radio"
                                                            name="input__cylinder"
                                                            v-model="oxygen_cylinder"
                                                            :value="true"
                                                        />
                                                        <label
                                                            id="input__label-cylinder-bring"
                                                            class="input__radio-label input__label-cylinder-bring"
                                                            for="input__cylinder-bring"
                                                            >持ち込みあり</label
                                                        >
                                                    </div>
                                                    <div
                                                        id="input__radio-cylinder-bring-number"
                                                        class="input__form input__form-cylinder input__radio-cylinder-number"
                                                    >
                                                        <span>（持ち込み</span>
                                                        <input
                                                            id="input__cylinder-bring-number"
                                                            class="input__form input__cylinder-number"
                                                            type="number"
                                                            name="input__cylinder-number"
                                                            min="1"
                                                            max="5"
                                                            v-model="oxygen_cylinder_count"
                                                        />
                                                        <span>本）</span>
                                                    </div>
                                                </dd>
                                            </div>
                                        </div>
                                        <span id="cylinder-error-text">
                                            <p class="input__error-text">医療用酸素ボンベを持ち込みの場合、1本以上を入力してください</p>
                                        </span>
                                        <p id="input__note-cylinder" class="input__note input__note-cylinder">
                                            医療用酸素ボンベを持ち込まれる場合は、<b>予約完了後、</b>必ず<b>予約センターまでお知らせ</b>ください。
                                        </p>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-back" type="button" v-if="!isUpdate" v-on:click="backPlanDetail">プラン詳細に戻る</button>
                <button class="btn btn-back" type="button" v-if="isUpdate" v-on:click="moveBookingCurrent">予約一覧に戻る</button>
                <button class="btn btn-next" type="button" v-on:click="showConfirmationBooking">予約確認に進む</button>
            </div>
        </section>
        <!-- /input -->
        <!-- confirm-booking -->
        <section id="confirm" class="confirm remodal normal" data-remodal-id="confirm-booking">
            <button id="btn-confirm-booking-open-modal" data-remodal-target="confirm-booking" v-show="false">○</button>
            <button id="btn-confirm-booking-close-modal" data-remodal-action="cancel-booking" v-show="false">×</button>
            <ConfirmationBooking ref="confirmationBookingDialog" v-on:displayMessage="displayMessage"> </ConfirmationBooking>
        </section>
        <!-- /confirm-booking -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { Booking, Boarding, Contract, CageDetail, CancelPolicyDetail } from '@/lib/types'
import { getBookingData, setBookingData } from '@/lib/localStorageUtil'
import {
    API_USERS,
    API_ORDER,
    CAR_TYPE_PASSENGER,
    CAR_TYPE_TWO_WHEEL_LARGE,
    CAR_TYPE_TWO_WHEEL_MEDIUM,
    CAR_TYPE_TWO_WHEEL_SMALL,
    CAR_TYPE_BICYCLE,
    getContract,
    isNull,
    getSeatTypeName,
    getSeaRouteCodeName,
    formatDate,
    castStringToDate,
    getShipSchedule,
    nextMonth,
    lastMonthDate,
    useMinRoomSeatCount,
    calcDiffDays,
    getStockCabin,
    getStockSeat,
    getStockCar,
    getStockBicycle,
    getStockPets,
    useMaxRoomPassengerCount,
    getMakeDateRange,
} from '@/lib/util'
import EditBabyList from './EditBabyList.vue'
import BoardingCarList from './BoardingCarList.vue'
import {
    PERSONALITY_TYPE_INDIVIDUAL,
    CAGE_TYPES_LARGE,
    CAGE_TYPES_SMALL,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_BABY,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_INFANT,
    SEAT_TYPE_FWP_TW,
    SEAT_TYPE_FWP_FO,
    SEAT_TYPE_D,
    GENDER_RESTRICT_NONE,
    GENDER_RESTRICT_MALE,
    GENDER_RESTRICT_FEMALE,
    VEHICLE_RESTRICT_NONE,
    VEHICLE_RESTRICT_CAR,
    VEHICLE_RESTRICT_TWO_AXLE_CAR,
    VEHICLE_RESTRICT_PASSENGER,
    PREFECTURES,
    convertTexts,
    CHILD_RESTRICT_NONE,
    CHILD_RESTRICT_CHILD,
    CHILD_RESTRICT_CHILD_OR_INFANT,
    DEP_STATUS_CANCEL_WEATHER,
    DEP_STATUS_CANCEL_ETC,
    DEP_STATUS_DOCK,
} from '@/lib/constants'
import RoomList from './RoomList.vue'
import CageList from './CageList.vue'
import ConfirmationBooking from './ConfirmationBooking.vue'
import ShipAnimationStart from '@/components/common/ShipAnimationStart.vue'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import EmptyCalendar from '@/components/common/EmptyCalendar.vue'
import $ from 'jquery'

const WHEELCHAIR_NONE = 'wheelchair-none'
const WHEELCHAIR_EXIST = 'wheelchair-exist'
const WHEELCHAIR_RENTAL = 'wheelchair-rental'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const confirmationBookingDialog = ref<InstanceType<typeof ConfirmationBooking>>()
        const contract = ref<Contract>()
        const contract_number = ref('')
        const dep_date = ref('')
        const dep_date_before = ref<string | null>()
        const change_dep_date = ref<Date | null>()
        const sea_route_code = ref('')
        const grade = ref(0)
        const seat_type = ref(0)
        const roomList = ref<InstanceType<typeof RoomList>>()
        const booking = ref<Booking>()
        const boarding = ref<Boarding>()
        const personality_type = ref(0)
        const wheelchair = ref<boolean | undefined>(false)
        const wheelchair_lending = ref<boolean | undefined>(false)
        const wheelchair_select = ref<string>(WHEELCHAIR_NONE)
        const pregnant = ref<boolean | undefined>(undefined)
        const pregnant_months = ref<number | undefined>(undefined)
        const oxygen_cylinder = ref<boolean>(false)
        const oxygen_cylinder_count = ref<number | undefined>(undefined)
        const babyList = ref<InstanceType<typeof EditBabyList>>()
        const carList = ref<InstanceType<typeof BoardingCarList>>()
        const twoWheelList = ref<InstanceType<typeof BoardingCarList>>()
        const cageList = ref<InstanceType<typeof CageList>>()
        const cage_detail_array = ref<CageDetail[]>([])
        const currentCancelPolicy = ref<CancelPolicyDetail | null>(null)
        const isDisplayAmount = ref(false)
        const warnings = ref()
        const dep_date_prev = ref()

        const calendar_cancel_weather_date = ref<Date[]>([])
        const calendar_cancel_etc_date = ref<Date[]>([])
        const calendar_dock_date = ref<Date[]>([])
        const calendar_fully_occupied_date = ref<Date[]>([])
        const no_schedule_date_arr = ref<Date[]>([])

        // ペットルーム表示有無
        const isShowPetCage = computed(() => {
            if (seat_type.value == SEAT_TYPE_FWP_TW || seat_type.value == SEAT_TYPE_FWP_FO) {
                return true
            }
            if (cage_detail_array.value.length > 0) {
                return true
            }

            return false
        })

        // 更新か？
        const isUpdate = computed(() => {
            // return !isNull(boarding.value?.boarding_no);
            return booking.value?.isUpdate
        })

        // プラン詳細へ戻る
        const backPlanDetail = async () => {
            await getDisplayData()
            router.push('/booking/plan_detail')
        }
        const moveBookingCurrent = () => {
            router.push('/booking/current')
        }
        // 予約確認へ進む
        const showConfirmationBooking = async () => {
            console.log('↓↓↓EditBooking.showConfirmationBooking↓↓↓')
            // 入力チェック
            if (await chkValidation()) {
                // 貸切料発生チェック
                // await chkReserved();
                let ifReserved = await chkReserved()
                // 画面データ取得
                await getDisplayData()
                confirmationBookingDialog.value?.dispBooking()
                if (!ifReserved) {
                    $('#btn-confirm-booking-open-modal').click()
                }
            }
            console.log('↑↑↑EditBooking.showConfirmationBooking↑↑↑')
        }

            // 入力チェック
            const chkValidation = async ():Promise<boolean> => {
              console.log('↓↓↓EditBooking.chkValidation↓↓↓');
              let err_count = 0;
              let err_messages = [] as string[];
              let adult_cnt = 0;
              let driver_cnt = 0;
              let car_cnt = 0;
              let infant_cnt = 0;
              let bycycle_num = 0;
              // 入力チェック（乗船者）
              if(!isNull(roomList.value)){
                let tmp_err = roomList.value!.chkValidation();
                err_count += tmp_err[0];
                if (tmp_err[1].length > 0) {
                    err_messages = err_messages.concat(tmp_err[1])
                }
                adult_cnt = roomList.value!.getPassengerCount(PASSENGER_TYPE_ADULT);
                driver_cnt = roomList.value!.getDriverCount();
              }
              // 入力チェック（乳幼児）
              if(!isNull(babyList.value)){
                let tmp_err = babyList.value!.chkValidation();
                err_count += tmp_err[0];
                if (tmp_err[1].length > 0) {
                    err_messages = err_messages.concat(tmp_err[1])
                }
                infant_cnt = babyList.value!.getPassengerCount(PASSENGER_TYPE_INFANT)
            }
            // 幼児の人数チェック
            if (adult_cnt < infant_cnt) {
                err_count++;
                err_messages.push('幼児の人数は大人の人数を超えて設定できません。小人としてご登録ください。');
              }
              // 入力チェック（乗用車）
              if(!isNull(carList.value)){
                car_cnt += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 3);
                car_cnt += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 4);
                car_cnt += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 5);
                car_cnt += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 6);

                if (driver_cnt != car_cnt) {
                  carList.value!.clearDriver();
                }

                let tmp_err = carList.value!.chkValidation();
                err_count += tmp_err[0];
                if (tmp_err[1].length > 0) {
                    err_messages = err_messages.concat(tmp_err[1])
                }
              }
              // 入力チェック（2輪）
              if(!isNull(twoWheelList.value)){
                let tmp_err = twoWheelList.value!.chkValidation();
                err_count += tmp_err[0];
                if (tmp_err[1].length > 0) {
                    err_messages = err_messages.concat(tmp_err[1])
                }
                const bycycle_cnt = twoWheelList.value!.getCarCount(CAR_TYPE_BICYCLE, 0)
                const small_cnt = twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_SMALL, 0)
                const medium_cnt = twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_MEDIUM, 0)
                const large_cnt = twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_LARGE, 0)

                car_cnt += bycycle_cnt;
                car_cnt += small_cnt;
                car_cnt += medium_cnt;
                car_cnt += large_cnt;

                bycycle_num += bycycle_cnt;
                bycycle_num += small_cnt;
                bycycle_num += medium_cnt;
                bycycle_num += large_cnt;
              }
              // 車両台数チェック
              if (adult_cnt < car_cnt) {
                err_count++;
                err_messages.push('大人の人数を超える車両・二輪車の予約はできません。');
            } else if (contract?.value?.vehicle_restrict == VEHICLE_RESTRICT_TWO_AXLE_CAR && adult_cnt != bycycle_num) {
                err_count++
                err_messages.push('大人1名につき二輪車1台付きのプランとなります。')
            }
            // 入力チェック（ペット）
            if (!isNull(cageList.value)) {
                err_count += cageList.value!.chkValidation()
            }
            // 入力チェック（特記事項）
            err_count += chkValidationSpecialRemarks()
            // 在庫チェック：新規登録・便変更時のみ
            if (!isUpdate.value) {
                let tmpArr = await chkStock()
                err_count += tmpArr.length
                if (tmpArr.length > 0) {
                    err_messages = err_messages.concat(tmpArr)
                }
            } else {
                let tmpArr = await chkStock()
                err_count += tmpArr.length
                if (tmpArr.length > 0) {
                    err_messages = err_messages.concat(tmpArr)
                }
            }
            // 入力チェック（成約情報）
            if (!isNull(contract.value)) {
                let tmpArr = chkValidationContract()
                err_count += tmpArr.length
                if (tmpArr.length > 0) {
                    err_messages = err_messages.concat(tmpArr)
                }
                // 乗船者
                if (!isNull(roomList.value)) {
                    tmpArr = roomList.value!.chkValidationContract()
                    err_count += tmpArr.length
                    if (tmpArr.length > 0) {
                        err_messages = err_messages.concat(tmpArr)
                    }
                }
                // 乳幼児
                if (!isNull(babyList.value)) {
                    tmpArr = babyList.value!.chkValidationContract()
                    err_count += tmpArr.length
                    if (tmpArr.length > 0) {
                        err_messages = err_messages.concat(tmpArr)
                    }
                }
                // 乗用車
                if (!isNull(carList.value)) {
                    tmpArr = carList.value!.chkValidationContract()
                    err_count += tmpArr.length
                    if (tmpArr.length > 0) {
                        err_messages = err_messages.concat(tmpArr)
                    }
                }
                // 2輪
                if (!isNull(twoWheelList.value)) {
                    tmpArr = twoWheelList.value!.chkValidationContract()
                    err_count += tmpArr.length
                    if (tmpArr.length > 0) {
                        err_messages = err_messages.concat(tmpArr)
                    }
                }
                // ペット
                if (!isNull(cageList.value)) {
                    tmpArr = cageList.value!.chkValidationContract()
                    err_count += tmpArr.length
                    if (tmpArr.length > 0) {
                        err_messages = err_messages.concat(tmpArr)
                    }
                }
            }

            // エラーメッセージ表示
            const set = new Set(err_messages)
            // err_messages = [...set];
            if (err_count > 0 && err_messages.length == 0) {
                err_messages = ['背景が赤色になっている入力内容に不備があります。', 'ご確認ください。']
            } else if (err_count > 0) {
                err_messages = ['入力内容に不備があります。ご確認ください。', ...set]
            }

            if (err_messages.length > 0) {
                // Scroll
                var landing = $('#form__list').offset()!.top
                $('html,body').animate({ scrollTop: landing })
                setTimeout(() => {
                    context.emit('displayMessage', {
                        msg_title: '予約入力',
                        msg: err_messages,
                        close_btn: true,
                    })
                }, 1000)
            }

            console.log('↑↑↑EditBooking.chkValidation↑↑↑')
            return err_count == 0
        }
        // 入力チェック（特記事項）
        const chkValidationSpecialRemarks = (): number => {
            let err_count = 0

            // 妊婦
            $('#input__container-pregnant').removeClass('input__error')
            $('#input__radio-pregnant-month').removeClass('input__error')
            $('#pregnant-error-text').removeClass('input__error')
            $('#pregnant-month-required-error-text').removeClass('input__error')
            $('#pregnant-month-error-text').removeClass('input__error')
            if (isNull(pregnant.value)) {
                $('#input__container-pregnant').addClass('input__error')
                $('#pregnant-error-text').addClass('input__error')
                err_count++
            } else if (pregnant.value == true) {
                if (isNull(pregnant_months.value)) {
                    $('#input__radio-pregnant-month').addClass('input__error')
                    $('#pregnant-month-required-error-text').addClass('input__error')
                    err_count++
                } else if (!isNull(pregnant_months.value) && pregnant_months.value! < 1) {
                    $('#input__radio-pregnant-month').addClass('input__error')
                    $('#pregnant-month-required-error-text').addClass('input__error')
                    err_count++
                } else if (!isNull(pregnant_months.value) && pregnant_months.value! >= 8) {
                    $('#input__radio-pregnant-month').addClass('input__error')
                    $('#pregnant-month-error-text').addClass('input__error')
                    err_count++
                }
            }
            // 酸素ボンベ
            $('#input__container-cylinder').removeClass('input__error')
            $('#input__radio-cylinder-bring-number').removeClass('input__error')
            $('#cylinder-error-text').removeClass('input__error')
            if (oxygen_cylinder.value == true && isNull(oxygen_cylinder_count.value)) {
                $('#input__radio-cylinder-bring-number').addClass('input__error')
                $('#cylinder-error-text').addClass('input__error')
                err_count++
            } else if (oxygen_cylinder.value == true && !isNull(oxygen_cylinder_count.value) && oxygen_cylinder_count.value! < 1) {
                $('#input__radio-cylinder-bring-number').addClass('input__error')
                $('#cylinder-error-text').addClass('input__error')
            }

            return err_count
        }
        // 入力チェック（成約制限）
        const chkValidationContract = (): string[] => {
            let err_messages = [] as string[]
            if (isNull(contract.value)) return err_messages

            let now = new Date()
            let passenger_adult_num = roomList.value!.getPassengerCount(PASSENGER_TYPE_ADULT)
            let passenger_child_num = roomList.value!.getPassengerCount(PASSENGER_TYPE_CHILD)
            let passenger_infant_num = babyList.value!.getBabyCount(PASSENGER_TYPE_INFANT)
            let passenger_baby_num = babyList.value!.getBabyCount(PASSENGER_TYPE_BABY)

            // 適用開始日
            if (!isNull(contract.value!.start_date) && !isNull(change_dep_date.value) && contract.value!.start_date! > change_dep_date.value!) {
                err_messages.push(`こちらのプランは${formatDate(change_dep_date.value!, false, 2)}のご予約は販売しておりません`)
            }
            // 適用終了日
            if (!isNull(contract.value!.end_date) && !isNull(change_dep_date.value) && contract.value!.end_date! < change_dep_date.value!) {
                err_messages.push(`こちらのプランは${formatDate(change_dep_date.value!, false, 2)}のご予約は販売しておりません`)
            }
            // sales_close_days: number,               // 手仕舞方法（日前）
            // 販売開始日時
            if (!isNull(contract.value!.sales_start_date) && contract.value!.sales_start_date > now) {
                err_messages.push(`こちらのプランは現在、販売しておりません`)
            }
            // 販売終了日時
            if (!isNull(contract.value!.sales_end_date) && contract.value!.sales_end_date < now) {
                err_messages.push(`こちらのプランは現在、販売しておりません`)
            }
            // 最小予約可能人数使用区分
            // 最小予約可能人数
            if (!isNull(contract.value!.use_booking_people_num_min) && contract.value!.use_booking_people_num_min) {
                let passenger_num = 0
                passenger_num += passenger_adult_num
                passenger_num += passenger_child_num
                passenger_num += passenger_infant_num
                passenger_num += passenger_baby_num
                if (!isNull(contract.value!.booking_people_num_min) && contract.value!.booking_people_num_min > passenger_num) {
                    err_messages.push(`こちらのプランは${contract.value!.booking_people_num_min}名からご予約可能です`)
                }
            }
            // 最大予約可能人数使用区分
            // 最大予約可能人数
            if (!isNull(contract.value!.use_booking_people_num_max) && contract.value!.use_booking_people_num_max) {
                let passenger_num = 0
                passenger_num += roomList.value!.getPassengerCount(PASSENGER_TYPE_ADULT)
                passenger_num += roomList.value!.getPassengerCount(PASSENGER_TYPE_CHILD)
                passenger_num += roomList.value!.getPassengerCount(PASSENGER_TYPE_INFANT)
                passenger_num += roomList.value!.getPassengerCount(PASSENGER_TYPE_BABY)
                if (!isNull(contract.value!.booking_people_num_max) && contract.value!.booking_people_num_max < passenger_num) {
                    err_messages.push(`こちらのプランは${contract.value!.booking_people_num_max}名までご予約可能です`)
                }
            }
            // お子様連れ制限
            if (!isNull(contract.value!.child_restrict) && contract.value!.child_restrict != CHILD_RESTRICT_NONE) {
                if (contract.value!.child_restrict == CHILD_RESTRICT_CHILD) {
                    // 小人
                    if (passenger_child_num == 0) {
                        err_messages.push(`こちらのプランは小人を含む場合のみご予約可能となっております`)
                    }
                } else if (contract.value!.child_restrict == CHILD_RESTRICT_CHILD_OR_INFANT) {
                    // 小人または乳幼児
                    if (passenger_child_num == 0 && passenger_infant_num == 0 && passenger_baby_num == 0) {
                        err_messages.push(`こちらのプランは小人または乳幼児を含む場合のみご予約可能となっております`)
                    }
                }
            }
            // ペット連れ制限
            if (!isNull(contract.value!.pets_restrict) && contract.value!.pets_restrict != 0) {
                if (isShowPetCage.value == false) {
                    err_messages.push(`こちらのプランはペットを含む場合のみご予約可能となっております`)
                }
            }
            // 更新時のみチェック
            if (isUpdate.value) {
                // 日付変更可否
                // 日付変更可否期日
                // 日付変更可否時刻
                if (dep_date.value != dep_date_before.value) {
                    if (!isNull(contract.value!.dep_date_change_limit) && contract.value!.dep_date_change_limit) {
                        let target_datetime = castStringToDate(dep_date_before.value! + ' ' + contract.value!.dep_date_change_limit_time)
                        target_datetime!.setDate(target_datetime!.getDate() - contract.value!.dep_date_change_limit_days)
                        if (target_datetime! < now) {
                            err_messages.push(`乗船日変更期限を過ぎているため、乗船日を変更することができません`)
                        }
                    } else {
                        err_messages.push(`こちらのプランは乗船日を変更することができません`)
                    }
                }
                // 乗船者情報変更可否
                // 乗船者情報変更可能期日
                // 乗船者情報変更可能時刻
                if (chkPassengerChanged()) {
                    if (!isNull(contract.value!.passenger_change_limit) && contract.value!.passenger_change_limit) {
                        let target_datetime = castStringToDate(dep_date_before.value! + ' ' + contract.value!.passenger_change_limit_time)
                        target_datetime!.setDate(target_datetime!.getDate() - contract.value!.passenger_change_limit_days)
                        if (target_datetime! < now) {
                            err_messages.push(`乗船者変更期限を過ぎているため、乗船者情報を変更することができません`)
                        }
                    } else {
                        err_messages.push(`こちらのプランは乗船者情報を変更することができません`)
                    }
                }
                // 車両情報変更可否
                // 車両情報変更可能期日
                // 車両情報変更可能時刻
                if (chkCarChanged() || chkTwoWheelChanged()) {
                    if (!isNull(contract.value!.boarding_car_change_limit) && contract.value!.boarding_car_change_limit) {
                        let target_datetime = castStringToDate(dep_date_before.value! + ' ' + contract.value!.boarding_car_change_limit_time)
                        target_datetime!.setDate(target_datetime!.getDate() - contract.value!.boarding_car_change_limit_days)
                        if (target_datetime! < now) {
                            err_messages.push(`車両変更期限を過ぎているため、車両情報を変更することができません`)
                        }
                    } else {
                        err_messages.push(`こちらのプランは車両情報を変更することができません`)
                    }
                }
            }
            // 発売停止
            if (!isNull(contract.value!.discontinued) && contract.value!.discontinued) {
                err_messages.push(`こちらのプランは現在、販売しておりません`)
            }

            return err_messages
        }
        // 入力チェック（在庫チェック）
        const chkStock = async (): Promise<string[]> => {
            console.log('↓↓↓EditBooking.chkStock↓↓↓')
            let err_count = 0
            let err_messages = [] as string[]

            if (seat_type.value < SEAT_TYPE_D) {
                // 船室
                if (!isNull(contract.value) && !isNull(sea_route_code.value)) {
                    let headcount_distributions = roomList.value!.getData()
                    let room_count = headcount_distributions.length
                    if (isUpdate.value && !isNull(booking.value) && !isNull(booking.value!.headcount_distributions_before)) {
                        // 更新の場合、差分をチェック
                        let room_count_before = booking.value!.headcount_distributions_before!.length
                        room_count = room_count - room_count_before
                    }

                    let stock = await getStockCabin(
                        contract.value!.divide_type_id,
                        seat_type.value,
                        sea_route_code.value,
                        formatDate(change_dep_date.value!, true, 0),
                        formatDate(change_dep_date.value!, true, 0),
                        room_count,
                    )
                    if (stock.empty_status == 0) {
                        err_count++
                        err_messages.push('船室に空きがございません。')
                    }
                }
            } else {
                // 船席
                if (!isNull(contract.value) && !isNull(sea_route_code.value)) {
                    let people_num = 0
                    let headcount_distributions = roomList.value!.getData()
                    for (let i = 0; i < headcount_distributions.length; i++) {
                        people_num += headcount_distributions[i].adult
                        people_num += headcount_distributions[i].child
                    }
                    if (isUpdate.value && !isNull(booking.value) && !isNull(booking.value!.headcount_distributions_before)) {
                        // 更新の場合、差分をチェック
                        let people_num_before = 0
                        for (let i = 0; i < booking.value!.headcount_distributions_before!.length; i++) {
                            people_num_before += booking.value!.headcount_distributions_before![i].adult
                            people_num_before += booking.value!.headcount_distributions_before![i].child
                        }
                        people_num = people_num - people_num_before
                    }
                    if (people_num > 0) {
                        let stock = await getStockSeat(
                            contract.value!.divide_type_id,
                            seat_type.value,
                            sea_route_code.value,
                            formatDate(change_dep_date.value!, true, 0),
                            formatDate(change_dep_date.value!, true, 0),
                            people_num,
                        )
                        if (stock.empty_status == 0) {
                            err_count++
                            err_messages.push('船席に空きがございません。')
                        }
                    }
                }
            }
            // 乗用車
            if (!isNull(contract.value) && !isNull(sea_route_code.value)) {
                let car_num = 0
                car_num += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 3)
                car_num += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 4)
                car_num += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 5)
                car_num += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 6)
                if (isUpdate.value && !isNull(booking.value) && !isNull(booking.value!.car_detail_array_before)) {
                    // 更新の場合、差分をチェック
                    let car_num_before = 0
                    for (let i = 0; i < booking.value!.car_detail_array_before!.length; i++) {
                        if (booking.value!.car_detail_array_before![i].car_type_code == CAR_TYPE_PASSENGER) {
                            car_num_before += 1
                        }
                    }
                    car_num = car_num - car_num_before
                }
                if (car_num > 0) {
                    let stock_car = await getStockCar(
                        contract.value!.divide_type_id,
                        sea_route_code.value,
                        formatDate(change_dep_date.value!, true, 0),
                        formatDate(change_dep_date.value!, true, 0),
                        car_num,
                    )
                    if (stock_car.empty_status == 0) {
                        err_count++
                        err_messages.push('乗用車の空きがございません。')
                    }
                }
            }
            // 二輪車
            if (!isNull(contract.value) && !isNull(sea_route_code.value)) {
                let bike_num = 0
                bike_num += twoWheelList.value!.getCarCount(CAR_TYPE_BICYCLE, 0)!
                bike_num += twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_SMALL, 0)!
                bike_num += twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_MEDIUM, 0)!
                bike_num += twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_LARGE, 0)!
                if (isUpdate.value && !isNull(booking.value) && !isNull(booking.value!.two_wheel_detail_array_before)) {
                    // 更新の場合、差分をチェック
                    let bike_num_before = 0
                    for (let i = 0; i < booking.value!.two_wheel_detail_array_before!.length; i++) {
                        if (booking.value!.two_wheel_detail_array_before![i].car_type_code != CAR_TYPE_PASSENGER) {
                            bike_num_before += 1
                        }
                    }
                    bike_num = bike_num - bike_num_before
                }
                if (bike_num > 0) {
                    let stock_bike = await getStockBicycle(
                        contract.value!.divide_type_id,
                        sea_route_code.value,
                        formatDate(change_dep_date.value!, true, 0),
                        formatDate(change_dep_date.value!, true, 0),
                        bike_num,
                    )
                    if (stock_bike.empty_status == 0) {
                        err_count++
                        err_messages.push('二輪車の空きがございません。')
                    }
                }
            }
            // ペットルーム
            let cage_large_num = cageList.value!.getLargeNum()!
            let cage_small_num = cageList.value!.getSmallNum()!
            if (isUpdate.value && !isNull(booking.value) && !isNull(booking.value!.cage_detail_array_before)) {
                // 更新の場合、差分をチェック
                let cage_large_num_before = 0
                let cage_small_num_before = 0
                for (let i = 0; i < booking.value!.cage_detail_array_before!.length; i++) {
                    if (booking.value!.cage_detail_array_before![i].cage_type == CAGE_TYPES_LARGE) {
                        cage_large_num_before += 1
                    }
                    if (booking.value!.cage_detail_array_before![i].cage_type == CAGE_TYPES_SMALL) {
                        cage_small_num_before += 1
                    }
                }
                cage_large_num = cage_large_num - cage_large_num_before
                cage_small_num = cage_small_num - cage_small_num_before
            }
            if (cage_large_num > 0 || cage_small_num > 0) {
                let stock_pets = await getStockPets(sea_route_code.value, formatDate(change_dep_date.value!, true, 0), cage_large_num, cage_small_num)
                if (cage_large_num > 0 && stock_pets.large_status == 0) {
                    err_count++
                    err_messages.push('ペットルーム（大型）の空きがございません。')
                }
                if (cage_small_num && stock_pets.middle_status == 0) {
                    err_count++
                    err_messages.push('ペットルーム（中小型）の空きがございません。')
                }
            }

            console.log('↑↑↑EditBooking.chkStock↑↑↑')
            return err_messages
        }
        // 乗員変更チェック
        const chkPassengerChanged = (): boolean => {
            let isChanged = false
            let now_room_array = []
            let before_room_array = []
            let now_headcount_distributions = roomList.value!.getData()
            for (let i = 0; i < now_headcount_distributions.length; i++) {
                now_room_array.push({
                    adult: 0,
                    child: 0,
                    reserved_room: 0,
                })
                if (now_headcount_distributions[i].reserved_room) {
                    now_room_array[i].reserved_room = 1
                }
                for (let j = 0; j < now_headcount_distributions[i].passenger_detail_array.length; j++) {
                    if (now_headcount_distributions[i].passenger_detail_array[j].deleted == true) {
                        continue
                    }
                    if (now_headcount_distributions[i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_ADULT) {
                        now_room_array[i].adult++
                    }
                    if (now_headcount_distributions[i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_CHILD) {
                        now_room_array[i].child++
                    }
                }
            }

            if (!isNull(booking.value?.headcount_distributions_before)) {
                for (let i = 0; i < booking.value!.headcount_distributions_before!.length; i++) {
                    before_room_array.push({
                        adult: 0,
                        child: 0,
                        reserved_room: 0,
                    })
                    if (booking.value!.headcount_distributions_before![i].reserved_room) {
                        before_room_array[i].reserved_room = 1
                    }
                    for (let j = 0; j < booking.value!.headcount_distributions_before![i].passenger_detail_array.length; j++) {
                        if (booking.value!.headcount_distributions_before![i].passenger_detail_array[j].deleted == true) {
                            continue
                        }
                        if (booking.value!.headcount_distributions_before![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_ADULT) {
                            before_room_array[i].adult++
                        }
                        if (booking.value!.headcount_distributions_before![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_CHILD) {
                            before_room_array[i].child++
                        }
                    }
                }
            }

            if (now_room_array.length != before_room_array.length) {
                isChanged = true
            } else {
                now_room_array.sort((first, second) => {
                    // 大人人数
                    if (first.adult > second.adult) {
                        return 1
                    } else if (first.adult < second.adult) {
                        return -1
                    }
                    // 子供人数
                    if (first.child > second.child) {
                        return 1
                    } else if (first.child < second.child) {
                        return -1
                    }
                    // 貸切
                    if (first.reserved_room > second.reserved_room) {
                        return 1
                    } else if (first.reserved_room < second.reserved_room) {
                        return -1
                    }

                    return 0
                })
                before_room_array.sort((first, second) => {
                    // 大人人数
                    if (first.adult > second.adult) {
                        return 1
                    } else if (first.adult < second.adult) {
                        return -1
                    }
                    // 子供人数
                    if (first.child > second.child) {
                        return 1
                    } else if (first.child < second.child) {
                        return -1
                    }
                    // 貸切
                    if (first.reserved_room > second.reserved_room) {
                        return 1
                    } else if (first.reserved_room < second.reserved_room) {
                        return -1
                    }

                    return 0
                })

                for (let i = 0; i < before_room_array.length; i++) {
                    if (
                        now_room_array[i].adult != before_room_array[i].adult ||
                        now_room_array[i].child != before_room_array[i].child ||
                        now_room_array[i].reserved_room != before_room_array[i].reserved_room
                    ) {
                        isChanged = true
                        break
                    }
                }
            }

            return isChanged
        }
        // 乗用車変更チェック
        const chkCarChanged = (): boolean => {
            let isChanged = false
            let now_length_array = []
            let before_length_array = []
            let now_car_detail_array = carList.value!.getData()
            for (let i = 0; i < now_car_detail_array.length; i++) {
                if (now_car_detail_array[i].deleted == true) {
                    continue
                }
                now_length_array.push(now_car_detail_array[i].car_length)
            }
            if (!isNull(booking.value?.car_detail_array_before)) {
                for (let i = 0; i < booking.value!.car_detail_array_before!.length; i++) {
                    if (booking.value!.car_detail_array_before![i].deleted == true) {
                        continue
                    }
                    before_length_array.push(booking.value!.car_detail_array_before![i].car_length)
                }
            }

            if (now_length_array.length != before_length_array.length) {
                // 台数変更
                isChanged = true
            } else {
                // 車長変更
                now_length_array.sort((first, second) => first - second)
                before_length_array.sort((first, second) => first - second)
                for (let i = 0; i < before_length_array.length; i++) {
                    if (now_length_array[i] != before_length_array[i]) {
                        isChanged = true
                        break
                    }
                }
            }

            return isChanged
        }
        // 二輪車変更チェック
        const chkTwoWheelChanged = (): boolean => {
            let isChanged = false
            let now_car_bicycle_num = twoWheelList.value!.getCarCount(CAR_TYPE_BICYCLE, 0)!
            let now_car_two_wheel_small_num = twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_SMALL, 0)!
            let now_car_two_wheel_medium_num = twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_MEDIUM, 0)!
            let now_car_two_wheel_large_num = twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_LARGE, 0)!
            let before_car_bicycle_num = 0
            let before_car_two_wheel_small_num = 0
            let before_car_two_wheel_medium_num = 0
            let before_car_two_wheel_large_num = 0

            if (!isNull(booking.value?.two_wheel_detail_array_before)) {
                for (let i = 0; i < booking.value!.two_wheel_detail_array_before!.length; i++) {
                    if (booking.value!.two_wheel_detail_array_before![i].deleted == true) {
                        continue
                    }
                    if (booking.value!.two_wheel_detail_array_before![i].car_type_code == CAR_TYPE_BICYCLE) {
                        before_car_bicycle_num++
                        continue
                    }
                    if (booking.value!.two_wheel_detail_array_before![i].car_type_code == CAR_TYPE_TWO_WHEEL_SMALL) {
                        before_car_two_wheel_small_num++
                        continue
                    }
                    if (booking.value!.two_wheel_detail_array_before![i].car_type_code == CAR_TYPE_TWO_WHEEL_MEDIUM) {
                        before_car_two_wheel_medium_num++
                        continue
                    }
                    if (booking.value!.two_wheel_detail_array_before![i].car_type_code == CAR_TYPE_TWO_WHEEL_LARGE) {
                        before_car_two_wheel_large_num++
                        continue
                    }
                }
            }

            if (
                now_car_bicycle_num != before_car_bicycle_num ||
                now_car_two_wheel_small_num != before_car_two_wheel_small_num ||
                now_car_two_wheel_medium_num != before_car_two_wheel_medium_num ||
                now_car_two_wheel_large_num != before_car_two_wheel_large_num
            ) {
                isChanged = true
            }

            return isChanged
        }

        const chkReserved = async (): Promise<boolean> => {
            let isReserved = roomList.value!.checkReserved()
            if (isReserved == true) {
                let close_event = () => {
                    $('#btn-confirm-booking-open-modal').click()
                }
                context.emit('displayMessage', {
                    msg_title: '貸切料',
                    msg: [`ご予約人数がお部屋の定員数に満たないため、貸切料が発生しています。`],
                    close_btn: true,
                    close_event: close_event,
                })
            }
            return isReserved
        }

        // キャンセルする
        const cancel = () => {
            console.log('cancel')
        }

        // 画面データ取得
        const getDisplayData = async () => {
            console.log('↓↓↓EditBooking.getDisplayData↓↓↓')
            let booking = getBookingData() as Booking
            booking.contract_number = contract_number.value
            booking.dep_date = dep_date.value
            booking.sea_route_code = sea_route_code.value
            booking.grade = grade.value
            booking.seat_type = seat_type.value
            booking.amount = calcPrice()
            // 乗船情報
            booking.boarding = boarding.value!
            booking.boarding.personality_type = roomList.value!.getPersonalityType()
            booking.boarding.corporate_name = roomList.value!.getCorporateName()
            booking.boarding.represent_passenger_name = roomList.value!.getRepresentPassengerName()
            booking.boarding.represent_passenger_post_code = roomList.value!.getRepresentPassengerPostCode()
            booking.boarding.represent_passenger_prefectures = roomList.value!.getRepresentPassengerPrefectures()
            booking.boarding.represent_passenger_address = roomList.value!.getRepresentPassengerAddress()
            booking.boarding.represent_passenger_tel = roomList.value!.getRepresentPassengerTel()
            // 車椅子
            if (wheelchair_select.value == WHEELCHAIR_NONE) {
                // 持ち込みなし
                booking.boarding.wheelchair = false
                booking.boarding.wheelchair_lending = false
            } else if (wheelchair_select.value == WHEELCHAIR_EXIST) {
                // 持ち込みあり
                booking.boarding.wheelchair = true
                booking.boarding.wheelchair_lending = false
            } else if (wheelchair_select.value == WHEELCHAIR_RENTAL) {
                // 貸出希望
                booking.boarding.wheelchair = true
                booking.boarding.wheelchair_lending = true
            }
            // 妊婦
            booking.boarding.pregnant = pregnant.value
            if (booking.boarding.pregnant) {
                booking.boarding.pregnant_months = pregnant_months.value
            } else {
                booking.boarding.pregnant_months = undefined
            }
            // 酸素ボンベ
            if (oxygen_cylinder.value) {
                booking.boarding.oxygen_cylinder = true
                booking.boarding.oxygen_cylinder_count = oxygen_cylinder_count.value
            } else {
                booking.boarding.oxygen_cylinder = false
                booking.boarding.oxygen_cylinder_count = undefined
            }
            // 乗船者
            booking.headcount_distributions = roomList.value!.getData()
            booking.passenger_adult_num = roomList.value!.getPassengerCount(PASSENGER_TYPE_ADULT)
            booking.passenger_child_num = roomList.value!.getPassengerCount(PASSENGER_TYPE_CHILD)
            booking.passenger_infant_num = roomList.value!.getPassengerCount(PASSENGER_TYPE_INFANT)
            booking.passenger_baby_num = roomList.value!.getPassengerCount(PASSENGER_TYPE_BABY)
            booking.passenger_detail_array = roomList.value!.getPassengerData()
            // 乳幼児
            booking.passenger_detail_baby_array = babyList.value!.getData()
            // 乗用車
            booking.car_3m_num = carList.value!.getCarCount(CAR_TYPE_PASSENGER, 3)
            booking.car_4m_num = carList.value!.getCarCount(CAR_TYPE_PASSENGER, 4)
            booking.car_5m_num = carList.value!.getCarCount(CAR_TYPE_PASSENGER, 5)
            booking.car_6m_num = carList.value!.getCarCount(CAR_TYPE_PASSENGER, 6)
            booking.car_num = booking.car_3m_num + booking.car_4m_num + booking.car_5m_num + booking.car_3m_num
            booking.car_detail_array = carList.value!.getData()!
            // 二輪車
            booking.car_bicycle_num = twoWheelList.value!.getCarCount(CAR_TYPE_BICYCLE, 0)!
            booking.car_two_wheel_small_num = twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_SMALL, 0)!
            booking.car_two_wheel_medium_num = twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_MEDIUM, 0)!
            booking.car_two_wheel_large_num = twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_LARGE, 0)!
            booking.two_wheel_detail_array = twoWheelList.value!.getData()!
            // ペット
            booking.cage_small_num = cageList.value!.getSmallNum()!
            booking.cage_large_num = cageList.value!.getLargeNum()!
            booking.cage_detail_array = cageList.value!.getData()!
            let pet_data = cageList.value!.getPetData()!
            booking.boarding.dog_count = pet_data[0]
            booking.boarding.cat_count = pet_data[1]
            booking.boarding.other_count = pet_data[2]
            booking.boarding.pet_note = pet_data[3]

            setBookingData(booking)
            console.log('↑↑↑EditBooking.getDisplayData↑↑↑')
        }

        /**
         * 最低必要部屋数の取得
         */
        const getMinRoomSeatCount = () => {
            if (!roomList.value) {
                return 0
            }
            let adult_num = roomList.value!.getPassengerCount(PASSENGER_TYPE_ADULT)
            let child_num = roomList.value!.getPassengerCount(PASSENGER_TYPE_CHILD)
            let infant_num = babyList.value!.getBabyCount(PASSENGER_TYPE_INFANT)
            let baby_num = babyList.value!.getBabyCount(PASSENGER_TYPE_BABY)

            return useMinRoomSeatCount(seat_type.value, adult_num, child_num, infant_num, baby_num, 0)
        }

        /**
         * 車両台数の取得
         */
        const getCarCount = () => {
            let count = 0

            if (!roomList.value) {
                return count
            }
            count += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 3)
            count += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 4)
            count += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 5)
            count += carList.value!.getCarCount(CAR_TYPE_PASSENGER, 6)
            return count
        }

        /**
         * 二輪台数の取得
         */
        const getTwoWheelCount = () => {
            let count = 0

            if (!roomList.value) {
                return count
            }
            count += twoWheelList.value!.getCarCount(CAR_TYPE_BICYCLE, 0)
            count += twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_SMALL, 0)
            count += twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_MEDIUM, 0)
            count += twoWheelList.value!.getCarCount(CAR_TYPE_TWO_WHEEL_LARGE, 0)
            return count
        }

        // 乳幼児追加
        const addBaby = () => {
            console.log('↓↓↓EditBooking.addBaby↓↓↓')
            babyList.value?.addBaby()
            console.log('↑↑↑EditBooking.addBaby↑↑↑')
        }
        // 車追加
        const addCar = () => {
            console.log('↓↓↓EditBooking.addCar↓↓↓')
            carList.value?.addCar()
            console.log('↑↑↑EditBooking.addCar↑↑↑')
        }
        // 2輪
        const addTwoWheel = () => {
            console.log('↓↓↓EditBooking.addTwoWheel↓↓↓')
            twoWheelList.value?.addCar()
            console.log('↑↑↑EditBooking.addTwoWheel↑↑↑')
        }
        // 乗船者リスト設定
        const commitPassengerList = () => {
            carList.value?.setDriverList(roomList.value?.getPassengerData())
        }
        // ドライバー設定
        const commitDriver = (driver_passenger_line_nos: any[]) => {
            roomList.value!.setDriver(driver_passenger_line_nos as any[])
        }
        // 運航予定取得
        const setShipScheduleData = async () => {
            warnings.value = undefined

            if (!isNull(dep_date.value) && !isNull(sea_route_code.value)) {
                let shipSchedule = await getShipSchedule(dep_date.value, sea_route_code.value)
                if (!isNull(shipSchedule)) {
                    boarding.value!.dep_time = shipSchedule.dep_time
                    boarding.value!.ship_name = shipSchedule.ship_name
                    warnings.value = shipSchedule.warnings
                }
            }
        }

        // 料金計算
        const calcPrice = (): number => {
            let price = 0
            // 乗船者
            if (!isNull(roomList.value)) {
                roomList.value?.calcPriceAll()
                price += roomList.value!.getTotalPrice()
            }
            // 乗用車
            if (!isNull(carList.value)) {
                carList.value?.calcPriceAll()
                price += carList.value!.getTotalPrice()
            }
            // 2輪車
            if (!isNull(twoWheelList.value)) {
                twoWheelList.value?.calcPriceAll()
                price += twoWheelList.value!.getTotalPrice()
            }
            return price
        }

        const init = async () => {
            console.log('↓↓↓EditBooking.init↓↓↓')
            let booking_data = getBookingData() as Booking
            booking.value = booking_data
            boarding.value = booking_data.boarding
            confirmationBookingDialog.value?.getPaymentMethod(boarding.value.boarding_no)
            contract_number.value = booking_data.contract_number
            dep_date.value = booking_data.dep_date
            dep_date_before.value = booking_data.dep_date_before
            change_dep_date.value = castStringToDate(dep_date.value)!
            sea_route_code.value = booking_data.sea_route_code
            contract.value = await getContract(contract_number.value, dep_date.value, sea_route_code.value)
            grade.value = booking_data.grade
            seat_type.value = booking_data.seat_type
            wheelchair.value = boarding.value!.wheelchair
            wheelchair_lending.value = boarding.value!.wheelchair_lending
            if (isNull(wheelchair.value)) {
                wheelchair_select.value = WHEELCHAIR_NONE
            } else {
                if (wheelchair.value) {
                    if (wheelchair_lending.value) {
                        wheelchair_select.value = WHEELCHAIR_RENTAL
                    } else {
                        wheelchair_select.value = WHEELCHAIR_EXIST
                    }
                } else {
                    wheelchair_select.value = WHEELCHAIR_NONE
                }
            }
            pregnant.value = boarding.value!.pregnant
            if (isNull(boarding.value!.pregnant_months) || !pregnant.value) {
                pregnant_months.value = undefined
            } else {
                pregnant_months.value = boarding.value!.pregnant_months
            }
            boarding.value!.pet_num = 0
            oxygen_cylinder.value = boarding.value!.oxygen_cylinder
            oxygen_cylinder_count.value = boarding.value!.oxygen_cylinder_count

            // 適用キャンセルポリシー取得
            currentCancelPolicy.value = null
            if (isUpdate.value) {
                let cancel_day_num = calcDiffDays(castStringToDate(booking.value!.dep_date_before!)!, new Date())
                let current_day = -1
                const cancel_policy_sorted = booking.value!.cancel_policy_before?.sort((a, b) => a.day - b.day)
                for (let i = 0; i < cancel_policy_sorted!.length; i++) {
                    if (!currentCancelPolicy.value) {
                        currentCancelPolicy.value = booking.value!.cancel_policy_before![i]
                        continue
                    }
                    current_day = booking.value!.cancel_policy_before![i].day

                    if (current_day <= cancel_day_num) {
                        currentCancelPolicy.value = booking.value!.cancel_policy_before![i]
                    }
                }
            }
            roomList.value?.init(
                booking_data.headcount_distributions,
                personality_type.value,
                boarding.value?.corporate_name,
                boarding.value?.represent_passenger_post_code,
                boarding.value?.represent_passenger_prefectures,
                boarding.value?.represent_passenger_address,
                boarding.value?.represent_passenger_tel,
                contract.value,
                currentCancelPolicy.value,
                grade.value,
                seat_type.value,
            )
            babyList.value?.init(booking_data.passenger_detail_baby_array, contract.value)
            carList.value?.setDriverList(roomList.value?.getPassengerData())
            carList.value?.init(booking_data.car_detail_array, contract.value, currentCancelPolicy.value)
            twoWheelList.value?.init(booking_data.two_wheel_detail_array, contract.value, currentCancelPolicy.value)
            cageList.value?.init(
                booking_data.cage_detail_array,
                boarding.value.dog_count,
                boarding.value.cat_count,
                boarding.value.other_count,
                boarding.value.pet_note,
                contract.value,
            )
            cage_detail_array.value = booking_data.cage_detail_array

            await Promise.all([
                roomList.value?.calcPriceAll(),
                carList.value?.calcPriceAll(),
                twoWheelList.value?.calcPriceAll(),
                // 運行状態設定
                setShipScheduleData(),
            ]).then((res) => {
                isDisplayAmount.value = true
            })
            console.log('↑↑↑EditBooking.init↑↑↑')
        }
        // メッセージ表示
        const displayMessage = ({
            msg_title,
            msg,
            close_btn,
            ok_btn,
            submit_btn,
            close_event,
            ok_event,
            submit_event,
            cancel_btn_value,
            ok_btn_value,
            submit_btn_value,
        }: {
            msg_title: string
            msg: string[]
            close_btn: boolean
            ok_btn: boolean
            submit_btn: boolean
            close_event: any
            ok_event: any
            submit_event: any
            cancel_btn_value: string
            ok_btn_value: string
            submit_btn_value: string
        }) => {
            context.emit('displayMessage', {
                msg_title: msg_title,
                msg: msg,
                close_btn: close_btn,
                ok_btn: ok_btn,
                submit_btn: submit_btn,
                close_event: close_event,
                ok_event: ok_event,
                submit_event: submit_event,
                cancel_btn_value: cancel_btn_value,
                ok_btn_value: ok_btn_value,
                submit_btn_value: submit_btn_value,
            })
        }
        // 空室か？
        const isEmpty = (empty_count: number, seat_type: number): boolean => {
            if (empty_count == 0) {
                return false
            }
            let passenger_num = 0
            passenger_num += roomList.value!.getPassengerCount(PASSENGER_TYPE_ADULT)
            passenger_num += roomList.value!.getPassengerCount(PASSENGER_TYPE_CHILD)
            let empty_par_seat_count = useMaxRoomPassengerCount(seat_type, 0)
            if (passenger_num > empty_count * empty_par_seat_count) {
                return false
            }
            return true
        }

        // 乗船日変更制限
        const isShowDepDateChange = computed(() => {
            // 新規登録時は非表示
            if (!isUpdate.value) {
                return false
            }
            if (contract?.value?.dep_date_change_limit == true) {
                if (!isNull(contract?.value?.dep_date_change_limit_days) && !isNull(contract?.value?.dep_date_change_limit_time)) {
                    let now = new Date()
                    let target_datetime = castStringToDate(dep_date_before.value! + ' ' + contract.value!.dep_date_change_limit_time)
                    target_datetime!.setDate(target_datetime!.getDate() - contract.value!.dep_date_change_limit_days)
                    if (target_datetime! < now) {
                        return false
                    } else {
                        return true
                    }
                }
                return false
            }
            return false
        })

        // 乗用車制限
        const isCarRestrict = computed(() => {
            if (contract?.value?.vehicle_restrict == VEHICLE_RESTRICT_NONE || contract?.value?.vehicle_restrict == VEHICLE_RESTRICT_CAR) {
                return false
            }
            return true
        })

        // 二輪車制限
        const isBycycleRestrict = computed(() => {
            if (contract?.value?.vehicle_restrict == VEHICLE_RESTRICT_NONE || contract?.value?.vehicle_restrict == VEHICLE_RESTRICT_TWO_AXLE_CAR) {
                return false
            }
            return true
        })

        // 出港便基本情報の警告があれば、表示、なければプラン詳細へ
        const openShipScheduleWarning = () => {
            if (!isNull(warnings.value) && warnings.value.length > 0) {
                const message = warnings.value.trim().split(/\r\n|\n/)
                context.emit('displayMessage', {
                    msg_title: 'お知らせ',
                    msg: message,
                    close_btn: true,
                    ok_btn: true,
                    submit_btn: false,
                    ok_event: depDateChanged,
                    close_event: restpreDepDate,
                    cancel_btn_value: '戻る',
                    ok_btn_value: '続ける',
                })
            } else {
                depDateChanged()
            }
        }

        const depDateChanged = async () => {
            // 更新時は元の乗船日が満席でもＯＫにする
            if (isUpdate.value && !isNull(dep_date_before.value) && dep_date_before.value == formatDate(change_dep_date.value!, true, 0)) return
            // 在庫チェック
            let err_messages = await chkStock()
            if (err_messages.length > 0) {
                context.emit('displayMessage', {
                    msg_title: '予約入力',
                    msg: err_messages,
                    close_btn: true,
                })
                // 在庫が無いため乗船日を戻す
                restpreDepDate()
            }
        }

        const restpreDepDate = async () => {
            // 乗船日を戻す
            if (isUpdate.value && !isNull(dep_date_before.value)) {
                change_dep_date.value = castStringToDate(dep_date_before.value!)!
            } else {
                change_dep_date.value = dep_date_prev.value
            }
            boarding.value!.dep_date = formatDate(change_dep_date.value!, true, 0)
            dep_date.value = boarding.value!.dep_date
        }

        // 乗船日変更
        watch(change_dep_date, async (next, prev) => {
            boarding.value!.dep_date = formatDate(change_dep_date.value!, true, 0)
            dep_date.value = boarding.value!.dep_date
            dep_date_prev.value = prev
            // 運航情報設定
            await setShipScheduleData()
            if (prev != undefined) {
                // 警告の有無確認
                openShipScheduleWarning()
            }
        })

        /**
         * onMounted
         */
        onMounted(async () => {
            console.log('↓↓↓EditBoarding.onMounted↓↓↓')
            init()
            nextTick(() => {
                // モーダル再読み込み
                try {
                    const remodalInst = $('.remodal').remodal()
                    remodalInst.reload()
                } catch (e) {
                    console.log(e)
                }
            })
            console.log('↑↑↑EditBoarding.onMounted↑↑↑')
        })

        return {
            confirmationBookingDialog,
            dep_date,
            change_dep_date,
            sea_route_code,
            grade,
            seat_type,
            roomList,
            contract,
            contract_number,
            boarding,
            wheelchair,
            wheelchair_lending,
            wheelchair_select,
            oxygen_cylinder,
            oxygen_cylinder_count,
            personality_type,
            pregnant,
            pregnant_months,
            babyList,
            carList,
            twoWheelList,
            cageList,
            isShowPetCage,
            isUpdate,
            isShowDepDateChange,
            isCarRestrict,
            isBycycleRestrict,
            currentCancelPolicy,
            isDisplayAmount,

            WHEELCHAIR_NONE,
            WHEELCHAIR_EXIST,
            WHEELCHAIR_RENTAL,

            backPlanDetail,
            moveBookingCurrent,
            showConfirmationBooking,
            cancel,
            getDisplayData,
            getMinRoomSeatCount,
            getCarCount,
            getTwoWheelCount,
            addBaby,
            addCar,
            addTwoWheel,
            commitPassengerList,
            commitDriver,
            calcPrice,
            setShipScheduleData,

            // メッセージ表示
            displayMessage,

            getSeatTypeName,
            getSeaRouteCodeName,
            formatDate,
            castStringToDate,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'EditBooking',
    data() {
        return {
            PERSONALITY_TYPE_INDIVIDUAL: PERSONALITY_TYPE_INDIVIDUAL as number,
            CAR_TYPE_PASSENGER: CAR_TYPE_PASSENGER as string,
            CAR_TYPE_BICYCLE: CAR_TYPE_BICYCLE as string,
            CAR_TYPE_TWO_WHEEL_SMALL: CAR_TYPE_TWO_WHEEL_SMALL as string,
            CAR_TYPE_TWO_WHEEL_MEDIUM: CAR_TYPE_TWO_WHEEL_MEDIUM as string,
            CAR_TYPE_TWO_WHEEL_LARGE: CAR_TYPE_TWO_WHEEL_LARGE as string,
            CAGE_TYPES_SMALL: CAGE_TYPES_SMALL as number,
            CAGE_TYPES_LARGE: CAGE_TYPES_LARGE as number,
        }
    },
    components: {
        RoomList,
        EditBabyList,
        BoardingCarList,
        CageList,
        ConfirmationBooking,
        ShipAnimationStart,
        ShipAnimationEnd,
        EmptyCalendar,
    },
    props: {},
    methods: {},
    mounted: function () {
        console.log('↓↓↓EditBooking.mounted↓↓↓')
        scrollTo(0, 0)

        console.log('↑↑↑EditBooking.mounted↑↑↑')
    },
})
</script>

<style></style>
