<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">退会</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- form -->
        <section class="form">
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <!-- ご予約の重要事項 -->
                    <li id="form__item" class="form__item">
                        <div class="form__item-container">
                            <div class="input">
                                <dl class="input__list">
                                    <!-- line -->
                                    <div class="input__line input__line-confirm">
                                        <!-- confirm -->
                                        <div id="input__item-confirm" class="input__item input__item-confirm">
                                            <dt id="input__header-confirm" class="input__header input__header-confirm">
                                                <label id="input__name-confirm" class="input__name input__name-confirm" for=""
                                                    >宮崎カーフェリーWEB予約会員退会規約</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <p class="input__attention">
                                                宮崎カーフェリーWEB予約会員利用規約に基づき会員登録された会員（以下、「会員」という）が会員登録を抹消し、退会する前に必ずお読み下さい。
                                            </p>
                                            <dd id="input__container-confirm" class="input__container input__container-confirm">
                                                <div id="input__note-confirm" class="input__note input__note-confirm">
                                                    <div class="note">
                                                        <h4 class="note__name">
                                                            1. 本規約は、当社が、会員が退会するにつき、その諸条件を定めるものです。
                                                        </h4>
                                                        <ul class="note__list">
                                                            <li class="note__name">
                                                                ご旅行予約がある場合、当該乗船前に退会することはできません。ご旅行が全て終了後の翌日からのお手続きとなります。
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="note">
                                                        <h4 class="note__name">
                                                            2. 会員が退会するにあたり下記の内容をご理解いただいたものと判断させていただきます。
                                                        </h4>
                                                        <ul class="note__list">
                                                            <li class="note__item">
                                                                退会日以降、本サイトにおいて、提供する会員向けの各種サービスが全てご利用いただけなくなります。
                                                            </li>
                                                            <li class="note__item">
                                                                会員は、退会した後も、ご利用クレジットカード等にて生じた一切の利用代金等については、支払の責を負うものとします。
                                                            </li>
                                                            <li class="note__item">
                                                                会員向けの各種サービスについて再度ご利用を希望される場合、新規のご入会、会員登録が必要となります。
                                                            </li>
                                                            <li class="note__item">
                                                                会員の退会手続きが受理されますと、当社で会員情報を削除管理いたします。
                                                            </li>
                                                            <li class="note__item">個人情報、プロフィール内容、メール内容等は一切復元できません。</li>
                                                            <li class="note__item">
                                                                退会した利用者IDやメールアドレスで再度会員登録を行うことはできません。
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </dd>
                                            <dd id="input__container-agree" class="input__container input__container-agree">
                                                <div id="input__checkbox-agree" class="input__checkbox input__checkbox-agree input__checkbox-agree">
                                                    <input
                                                        id="input__agree"
                                                        class="input__checkbox-btn input__agree"
                                                        type="checkbox"
                                                        name="input__agree"
                                                        v-model="agree"
                                                    />
                                                    <label id="input__label-agree" class="input__checkbox-label input__label-agree" for="input__agree"
                                                        >退会規約を確認しました。</label
                                                    >
                                                </div>
                                                <p class="input__error-text">退会規約の確認をチェックしてください</p>
                                                <span id="error_message"
                                                    ><p class="input__error-text">
                                                        {{ error_message }}
                                                    </p></span
                                                >
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
                <button class="btn btn-remove" type="button" v-on:click="withdrawal">退会する</button>
            </div>
        </section>
        <!-- /input -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { removeMemberData } from '@/lib/localStorageUtil'
import { API_USERS, API_ORDER } from '@/lib/util'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()
        const agree = ref(false)
        const error_message = ref('')

        // マイページへ
        const moveMyPage = () => {
            router.push('/member/my_page')
        }
        // 退会する
        const withdrawal = async (): Promise<void> => {
            // 入力チェック
            if (!(await chkValidation())) {
                return
            }
            const postData = {}
            apiClient
                .delete(`${API_USERS}/member`, { params: postData })
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    // ログアウト処理
                    // 認証トークンを削除
                    localStorage.removeItem('access')
                    // 会員情報を削除
                    removeMemberData()
                    // ストア会員情報更新
                    store.dispatch('setMemberId', '')
                    store.dispatch('setFamilyName', '')
                    store.dispatch('setGivenName', '')
                    store.dispatch('setEmail', '')
                    store.dispatch('setIsLoggedIn', false)
                    // 退会完了画面へ移動
                    router.push('/member/withdrawal_complete')
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    error_message.value = e.message
                    $('#error_message').addClass('input__error')
                    return
                })
        }
        // 入力チェック
        const chkValidation = async (): Promise<boolean> => {
            let err_count = 0
            // エラー表示初期化
            error_message.value = ''
            $('#input__container-agree').removeClass('input__error')
            $('#error_message').removeClass('input__error')

            // 確認チェック
            if (!agree.value) {
                $('#input__container-agree').addClass('input__error')
                err_count++
            }
            // 未乗船予約
            if (!(await chkValidationFutures())) {
                error_message.value = '乗船予定のご予約がある為、退会できません。'
                $('#error_message').addClass('input__error')
                err_count++
            }

            return err_count == 0
        }
        // 入力チェック（未乗船）
        const chkValidationFutures = async (): Promise<boolean> => {
            let booking_count = 0

            const postData = {}
            booking_count = await apiClient
                .get(`${API_USERS}/member/futures`, { params: postData })
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    return data.future_boarding_count
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    return -9999
                })

            return booking_count == 0
        }

        return {
            agree,
            error_message,

            moveMyPage,
            withdrawal,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'WithdrawalDialog',
    data() {
        return {}
    },
    components: {
        ShipAnimationEnd,
    },
    methods: {},
})
</script>

<style scoped></style>
