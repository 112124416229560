<template>
    <div class="confirm__header">
        <h3 class="confirm__name">入力内容のご確認</h3>
        <button id="btn-confirm-close" class="confirm__close" data-remodal-action="cancel">×</button>
    </div>
    <div class="confirm__container">
        <ul class="confirm__list">
            <!-- price -->
            <li class="confirm__item">
                <div class="confirm__item-header">
                    <h3 class="confirm__item-name">運賃</h3>
                </div>
                <div class="confirm__item-container">
                    <div class="confirm__disp">
                        <dl class="confirm__disp-list">
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">変更前運賃</dt>
                                <dd class="confirm__disp-data">{{ booking?.amount_before?.toLocaleString() }}円</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">変更後運賃</dt>
                                <dd class="confirm__disp-data">{{ disp_amount }}</dd>
                            </div>
                            <div
                                class="confirm__disp-line"
                                v-show="
                                    authori_payment_method == AUTHORI_PAYMENT_METHOD_PAY_EASY ||
                                    authori_payment_method == AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE
                                "
                            >
                                <dt class="confirm__disp-name">入金済金額</dt>
                                <dd class="confirm__disp-data">{{ deposit_amount.toLocaleString() }}円</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">キャンセル料</dt>
                                <dd class="confirm__disp-data-cur-plus">
                                    {{ isDisplayAmount ? cancel_amount.toLocaleString() + '円' : '計算中' }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">振込手数料</dt>
                                <dd class="confirm__disp-data-cur-plus">
                                    {{ isDisplayAmount ? transfer_charge.toLocaleString() + '円' : '計算中' }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">差額</dt>
                                <dd :class="`${difference_amount >= 0 ? 'confirm__disp-data-cur-plus' : 'confirm__disp-data-cur-minus'}`">
                                    {{ isDisplayAmount ? difference_amount.toLocaleString() + '円' : '計算中' }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
            <!-- <template v-for="(passenger, idx) in passenger_detail_array_before" :key="idx">
        <li class="confirm__item" v-show="passenger.represent_flg && passenger.deleted != true">
          <div class="confirm__item-header">
            <h3 class="confirm__item-name">代表者</h3>
            <img class="confirm__item-icon" src="@/images/items/accessory-ancer-01.svg" height="48" width="54"
              alt="代表者情報アイコン">
          </div>
          <div class="confirm__item-container">
            <div class="confirm__disp">
              <dl class="confirm__disp-list">
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">区分</dt>
                  <dd class="confirm__disp-data">{{ convertText(PASSENGER_TYPES, passenger?.passenger_type) }}</dd>
                </div>
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">お名前</dt>
                  <dd class="confirm__disp-data">{{ boarding?.represent_passenger_name }}</dd>
                </div>
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">電話番号</dt>
                  <dd class="confirm__disp-data">{{ boarding?.represent_passenger_tel }}</dd>
                </div>
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">性別</dt>
                  <dd class="confirm__disp-data">{{ convertText(GENDERS, passenger.gender) }}</dd>
                </div>
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">乗船時年齢</dt>
                  <dd class="confirm__disp-data">{{ passenger.age }}歳</dd>
                </div>
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">住所</dt>
                  <dd class="confirm__disp-data">{{ boarding?.represent_passenger_post_code }} {{ convertText(PREFECTURES,
                    boarding?.represent_passenger_prefectures) }} {{ boarding?.represent_passenger_address }}</dd>
                </div>
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">運賃</dt>
                  <dd class="confirm__disp-data">{{ getPassengerPrice(passenger).toLocaleString() }}円</dd>
                </div>
              </dl>
            </div>
          </div>
        </li>
        <li class="confirm__item" v-show="!passenger.represent_flg && passenger.deleted != true">
          <div class="confirm__item-header">
            <h3 class="confirm__item-name">同行者</h3>
            <img class="confirm__item-icon" src="@/images/items/accessory-strawhat-01.svg" height="48" width="54"
              alt="同行者情報アイコン">
          </div>
          <div class="confirm__item-container">
            <div class="confirm__disp">
              <dl class="confirm__disp-list">
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">区分</dt>
                  <dd class="confirm__disp-data">{{ convertText(PASSENGER_TYPES, passenger?.passenger_type) }}</dd>
                </div>
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">お名前</dt>
                  <dd class="confirm__disp-data">{{ joinFirstNameAndLastName(passenger.family_name_katakana,
                    passenger.given_name_katakana) }}</dd>
                </div>
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">性別</dt>
                  <dd class="confirm__disp-data">{{ convertText(GENDERS, passenger.gender) }}</dd>
                </div>
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">乗船時年齢</dt>
                  <dd class="confirm__disp-data">{{ passenger.age }}歳</dd>
                </div>
                <div class="confirm__disp-line">
                  <dt class="confirm__disp-name">運賃</dt>
                  <dd class="confirm__disp-data">{{ getPassengerPrice(passenger).toLocaleString() }}円</dd>
                </div>
              </dl>
            </div>
          </div>
        </li>
      </template> -->
            <!-- baby -->
            <!-- <li class="confirm__item" v-for="(baby, idx) in passenger_detail_baby_array_before" :key="idx"
        v-show="baby.deleted != true">
        <div class="confirm__item-header">
          <h3 class="confirm__item-name">乳幼児</h3>
          <img class="confirm__item-icon" src="@/images/items/accessory-strawhat-01.svg" height="48" width="54"
            alt="同行者情報アイコン">
        </div>
        <div class="confirm__item-container">
          <div class="confirm__disp">
            <dl class="confirm__disp-list">
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">区分</dt>
                <dd class="confirm__disp-data">{{ convertText(PASSENGER_TYPES, baby?.passenger_type) }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">お名前</dt>
                <dd class="confirm__disp-data">{{ joinFirstNameAndLastName(baby.family_name_katakana,
                  baby.given_name_katakana) }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">性別</dt>
                <dd class="confirm__disp-data">{{ convertText(GENDERS, baby.gender) }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">乗船時年齢</dt>
                <dd class="confirm__disp-data">{{ baby.age }}歳</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">運賃</dt>
                <dd class="confirm__disp-data">{{ getPassengerPrice(baby).toLocaleString() }}円</dd>
              </div>
            </dl>
          </div>
        </div>
      </li> -->
            <!-- car -->
            <!-- <li class="confirm__item" v-for="(car, idx) in car_detail_array_before" :key="idx" v-show="car.deleted != true">
        <div class="confirm__item-header">
          <h3 class="confirm__item-name">乗用車</h3>
          <img class="confirm__item-icon" src="@/images/items/accessory-car-01.svg" height="48" width="54"
            alt="乗用車情報アイコン">
        </div>
        <div class="confirm__item-container">
          <div class="confirm__disp">
            <dl class="confirm__disp-list">
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">運転手</dt>
                <dd class="confirm__disp-data">{{ getDriverName(car.passenger_line_no) }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">車長</dt>
                <dd class="confirm__disp-data">{{ car.car_length }}m未満</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">車種</dt>
                <dd class="confirm__disp-data">{{ car.note }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">ナンバープレート</dt>
                <dd class="confirm__disp-data">{{ car.car_place_name }} {{ car.car_class_number }} {{ car.car_hiragana }}
                  {{ car.car_number }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">運賃</dt>
                <dd class="confirm__disp-data">{{ getCarPrice(car).toLocaleString() }}円</dd>
              </div>
            </dl>
          </div>
        </div>
      </li> -->
            <!-- bike -->
            <!-- <li class="confirm__item" v-for="(two_wheel, idx) in two_wheel_detail_array_before" :key="idx"
        v-show="two_wheel.deleted != true">
        <div class="confirm__item-header">
          <h3 class="confirm__item-name">二輪車</h3>
          <img class="confirm__item-icon" src="@/images/items/accessory-bike-01.svg" height="48" width="54"
            alt="二輪車情報アイコン">
        </div>
        <div class="confirm__item-container">
          <div class="confirm__disp">
            <dl class="confirm__disp-list">
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">種別/排気量</dt>
                <dd class="confirm__disp-data">{{ getTwoWheelName(two_wheel.car_type_code) }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">メーカー/車名</dt>
                <dd class="confirm__disp-data">{{ two_wheel.note }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">ナンバープレート</dt>
                <dd class="confirm__disp-data">{{ two_wheel.car_place_name }} {{ two_wheel.car_class_number }} {{
                  two_wheel.car_hiragana }} {{ two_wheel.car_number }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">運賃</dt>
                <dd class="confirm__disp-data">{{ getCarPrice(two_wheel).toLocaleString() }}円</dd>
              </div>
            </dl>
          </div>
        </div>
      </li> -->
            <!-- pet -->
            <!-- <li class="confirm__item" v-show="isShowPet">
        <div class="confirm__item-header">
          <h3 class="confirm__item-name">ペット情報</h3>
          <img class="confirm__item-icon" src="@/images/items/accessory-pet-01.svg" height="48" width="54"
            alt="ペット情報アイコン">
        </div>
        <div class="confirm__item-container">
          <div class="confirm__disp">
            <dl class="confirm__disp-list">
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">ペットルーム</dt>
                <dd class="confirm__disp-data">{{ dispCageNum }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">ペット区分</dt>
                <dd class="confirm__disp-data">{{ dispPatNum }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">ペット種類</dt>
                <dd class="confirm__disp-data">{{ boarding?.pet_note }}</dd>
              </div>
            </dl>
          </div>
        </div>
      </li> -->
            <!-- note -->
            <!-- <li class="confirm__item">
        <div class="confirm__item-header">
          <h3 class="confirm__item-name">特記事項</h3>
        </div>
        <div class="confirm__item-container">
          <div class="confirm__disp">
            <dl class="confirm__disp-list">
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">妊婦</dt>
                <dd class="confirm__disp-data">{{ disp_pregnant }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">車椅子</dt>
                <dd class="confirm__disp-data">{{ disp_wheelchair }}</dd>
              </div>
              <div class="confirm__disp-line">
                <dt class="confirm__disp-name">医療用酸素ボンベ</dt>
                <dd class="confirm__disp-data">{{ disp_oxygen_cylinder }}</dd>
              </div>
            </dl>
          </div>
        </div>
      </li> -->
        </ul>
    </div>
    <div class="confirm__btn">
        <button class="btn btn-back" data-remodal-action="cancel">閉じる</button>
        <button
            :class="`btn ${isDisplayAmount ? 'btn-cansell' : 'btn-cansell-inactive'}`"
            type="button"
            v-on:click="cancelBoardingStart(boarding?.boarding_no)"
        >
            予約キャンセル
        </button>
    </div>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { Booking, Boarding, PassengerDetail, CarDetail, CageDetail, Contract } from '@/lib/types'
import {
    API_USERS,
    API_ORDER,
    isNull,
    joinFirstNameAndLastName,
    CAR_TYPE_BICYCLE,
    CAR_TYPE_TWO_WHEEL_SMALL,
    CAR_TYPE_TWO_WHEEL_MEDIUM,
    CAR_TYPE_TWO_WHEEL_LARGE,
    getStockCabin,
    getStockSeat,
    getStockCar,
    getStockBicycle,
    getStockPets,
    getContract,
    DEFAULT_TWO_WHEEL_WIDTH,
    DEFAULT_TWO_WHEEL_LENGTH,
    getPassengerPrice,
    getCarPrice,
    calcDiffDays,
    castStringToDate,
    getTransferCharge,
    setCompanionMaster,
    setVehicleMaster,
    getSeatTypeName,
    getSeaRouteCodeName,
    formatDate,
    formatDateTime,
} from '@/lib/util'
import {
    convertText,
    PASSENGER_TYPES,
    GENDERS,
    PREFECTURES,
    CAGE_TYPES_LARGE,
    CAGE_TYPES_SMALL,
    SEAT_TYPE_D,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    CANCEL_CALCULATION_METHODS_RETIO,
    AUTHORI_PAYMENT_METHOD_PAY_EASY,
    AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE,
    AUTHORI_PAYMENT_METHOD_CREDIT,
} from '@/lib/constants'
import { getBookingData } from '@/lib/localStorageUtil'

export default defineComponent({
    setup(props, context) {
        const store = useStore()
        const router = useRouter()
        const booking = ref<Booking>()
        const boarding = ref<Boarding>()
        const ship_schedule_id = ref()
        const contract = ref<Contract>()
        const passenger_detail_array = ref<PassengerDetail[]>()
        const passenger_detail_array_before = ref<PassengerDetail[]>()
        const passenger_detail_baby_array = ref<PassengerDetail[]>()
        const passenger_detail_baby_array_before = ref<PassengerDetail[]>()
        const car_detail_array = ref<CarDetail[]>()
        const car_detail_array_before = ref<CarDetail[]>()
        const two_wheel_detail_array = ref<CarDetail[]>()
        const two_wheel_detail_array_before = ref<CarDetail[]>()
        const cage_detail_array = ref<CageDetail[]>([])
        const cage_detail_array_before = ref<CageDetail[]>([])
        const cancel_amount = ref(0)
        const transfer_charge = ref(0)
        const isDisplayAmount = ref(false)
        const authori_payment_method = ref()
        const deposit_amount = ref(0)

        const cancel_boarding_no = ref()

        // 予約内容表示
        const dispBooking = async (booking_data: Booking, contract_data: Contract) => {
            console.log('↓↓↓CancelBookingDialog.dispBooking↓↓↓')
            booking.value = booking_data
            boarding.value = booking_data.boarding
            passenger_detail_array.value = booking_data.passenger_detail_array
            passenger_detail_array_before.value = booking_data.passenger_detail_array_before!
            passenger_detail_baby_array.value = booking_data.passenger_detail_baby_array
            passenger_detail_baby_array_before.value = booking_data.passenger_detail_baby_array_before!
            car_detail_array.value = booking_data.car_detail_array
            car_detail_array_before.value = booking_data.car_detail_array_before!
            two_wheel_detail_array.value = booking_data.two_wheel_detail_array
            two_wheel_detail_array_before.value = booking_data.two_wheel_detail_array_before!
            cage_detail_array.value = booking_data.cage_detail_array
            contract.value = contract_data
            ship_schedule_id.value = boarding.value.ship_schedule_id

            // 入金済金額
            const passenger_amount = boarding.value?.passenger_paid_total == null ? 0 : boarding.value?.passenger_paid_total
            const vehicle_amount = boarding.value?.vehicle_paid_total == null ? 0 : boarding.value?.vehicle_paid_total
            deposit_amount.value = passenger_amount + vehicle_amount

            // 全キャンセル
            booking.value!.amount = 0
            for (let i = 0; i < passenger_detail_array.value.length; i++) {
                passenger_detail_array.value[i].deleted = true
            }
            for (let i = 0; i < car_detail_array.value.length; i++) {
                car_detail_array.value[i].deleted = true
            }
            for (let i = 0; i < two_wheel_detail_array.value.length; i++) {
                two_wheel_detail_array.value[i].deleted = true
            }

            let payment_result = await getPaymentMethod()
            if (payment_result[0] >= 0) {
                if (payment_result[1] == 1) {
                    // 全入金のキャンセル料計算
                    calcCancelAmountPaid()
                } else if (payment_result[1] == 2) {
                    // 一部入金のキャンセル料計算
                    calcCancelAmountPartialPaid()
                }
            }
            transfer_charge.value = 0
            if (payment_result[0] > 0 && payment_result[1] > 0) {
                calcTransferCharge()
            }
            isDisplayAmount.value = true
            console.log('↑↑↑CancelBookingDialog.dispBooking↑↑↑')
        }

        // ペットルーム
        const isShowPet = computed(() => {
            if (!isNull(dispCageNum.value)) {
                return true
            }
            return false
        })
        const dispCageNum = computed(() => {
            let values = []

            let cage_large_num = 0
            let cage_small_num = 0
            for (let i = 0; i < cage_detail_array.value!.length; i++) {
                if (cage_detail_array.value![i].deleted) {
                    continue
                }
                if (cage_detail_array.value![i].cage_type == CAGE_TYPES_LARGE) {
                    cage_large_num++
                } else if (cage_detail_array.value![i].cage_type == CAGE_TYPES_SMALL) {
                    cage_small_num++
                }
            }

            if (cage_large_num > 0) {
                values.push(`大型${cage_large_num}部屋`)
            }
            if (cage_small_num > 0) {
                values.push(`中小型${cage_small_num}部屋`)
            }
            return values.join(',')
        })
        const dispPatNum = computed(() => {
            if (isNull(cage_detail_array.value) || cage_detail_array.value.length == 0) {
                return ''
            } else {
                let values = []

                if (boarding.value!.dog_count > 0) {
                    values.push(`犬${boarding.value!.dog_count}匹`)
                }
                if (boarding.value!.cat_count > 0) {
                    values.push(`猫${boarding.value!.cat_count}匹`)
                }
                if (boarding.value!.other_count > 0) {
                    values.push(`その他${boarding.value!.other_count}匹`)
                }
                return values.join(',')
            }
        })

        // 運転手名取得
        const getDriverName = (passenger_line_no: number | null): string => {
            let driver_name = ''
            if (passenger_line_no != null && !isNull(passenger_detail_array.value)) {
                for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                    if (passenger_detail_array.value![i].line_no == passenger_line_no) {
                        driver_name = joinFirstNameAndLastName(
                            passenger_detail_array.value![i].family_name_katakana,
                            passenger_detail_array.value![i].given_name_katakana,
                        )
                    }
                }
            }
            return driver_name
        }
        // 2輪名称取得
        const getTwoWheelName = (car_type_code: string): string => {
            let two_wheel_name = ''
            if (car_type_code == CAR_TYPE_BICYCLE) {
                two_wheel_name = '自転車'
            } else if (car_type_code == CAR_TYPE_TWO_WHEEL_SMALL) {
                two_wheel_name = '125cc未満'
            } else if (car_type_code == CAR_TYPE_TWO_WHEEL_MEDIUM) {
                two_wheel_name = '125cc〜750cc未満'
            } else if (car_type_code == CAR_TYPE_TWO_WHEEL_LARGE) {
                two_wheel_name = '750cc以上'
            }

            return two_wheel_name
        }
        // 車椅子
        const disp_wheelchair = computed(() => {
            if (boarding.value?.wheelchair) {
                if (boarding.value?.wheelchair_lending) {
                    return '貸出希望'
                } else {
                    return '持ち込みあり'
                }
            } else {
                return '持ち込みなし'
            }
        })
        // 妊婦
        const disp_pregnant = computed(() => {
            if (boarding.value?.pregnant) {
                return `妊婦がいます（乗船時${boarding.value?.pregnant_months}カ月）`
            } else {
                return '妊婦はいません'
            }
        })
        // 酸素ボンベ
        const disp_oxygen_cylinder = computed(() => {
            if (!isNull(boarding.value?.oxygen_cylinder_count)) {
                return `持ち込みあり（持ち込み${boarding.value?.oxygen_cylinder_count}本）`
            } else {
                return '持ち込みなし'
            }
        })

        // 運賃合計
        const disp_amount = computed(() => {
            if (isNull(booking.value) || isNull(booking.value!.amount)) {
                return ''
            } else {
                return booking.value!.amount!.toLocaleString() + '円'
            }
        })
        // 表示用予約内容部屋タイプ名
        const disp_seat_type_name = computed(() => {
            let seat_type_list = []
            let seat_name_list = [] as string[]
            if (!isNull(passenger_detail_array_before.value)) {
                for (let i = 0; i < passenger_detail_array_before.value!.length; i++) {
                    if (!isNull(passenger_detail_array_before.value![i].seat_type)) {
                        seat_type_list.push(passenger_detail_array_before.value![i].seat_type)
                    }
                }
            }
            seat_type_list = [...new Set(seat_type_list)]
            for (let i = 0; i < seat_type_list.length; i++) {
                seat_name_list.push(getSeatTypeName(seat_type_list[i]))
            }
            return [...new Set(seat_name_list)].join('、')
        })

        /**
         * 適用キャンセルポリシー取得
         */
        const getTargetCancelPolicy = () => {
            let current_cancel_policy = null
            let cancel_day_num = calcDiffDays(castStringToDate(booking.value!.dep_date_before!)!, new Date())
            let current_day = -1

            const cancel_policy_sorted = booking.value!.cancel_policy_before?.sort((a, b) => a.day - b.day)

            for (let i = 0; i < cancel_policy_sorted!.length; i++) {
                if (!current_cancel_policy) {
                    current_cancel_policy = booking.value!.cancel_policy_before![i]
                    continue
                }
                current_day = booking.value!.cancel_policy_before![i].day
                if (current_day <= cancel_day_num) {
                    current_cancel_policy = booking.value!.cancel_policy_before![i]
                }
            }
            return current_cancel_policy
        }

        // キャンセル料計算（全入金済）
        const calcCancelAmountPaid = () => {
            let amount = 0
            let isCancelPassenger = false
            let isCancelCar = false
            let isCancelBike = false
            // キャンセル有無確認
            // 乗船者
            if (!isNull(passenger_detail_array.value)) {
                let del_count = 0
                let add_count = 0
                for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                    if (!isNull(passenger_detail_array.value![i].id) && passenger_detail_array.value![i].deleted) {
                        del_count++
                    } else if (isNull(passenger_detail_array.value![i].id) && !passenger_detail_array.value![i].deleted) {
                        add_count++
                    }
                }
                if (del_count > add_count) {
                    isCancelPassenger = true
                }
            }
            // 乗用車
            if (!isNull(car_detail_array.value)) {
                let del_count = 0
                let add_count = 0
                for (let i = 0; i < car_detail_array.value!.length; i++) {
                    if (!isNull(car_detail_array.value![i].id) && car_detail_array.value![i].deleted) {
                        del_count++
                    } else if (isNull(car_detail_array.value![i].id) && !car_detail_array.value![i].deleted) {
                        add_count++
                    }
                }
                if (del_count > add_count) {
                    isCancelCar = true
                }
            }
            // 二輪車
            if (!isNull(two_wheel_detail_array.value)) {
                let del_count = 0
                let add_count = 0
                for (let i = 0; i < two_wheel_detail_array.value!.length; i++) {
                    if (!isNull(two_wheel_detail_array.value![i].id) && two_wheel_detail_array.value![i].deleted) {
                        del_count++
                    } else if (isNull(two_wheel_detail_array.value![i].id) && !two_wheel_detail_array.value![i].deleted) {
                        add_count++
                    }
                }
                if (del_count > add_count) {
                    isCancelBike = true
                }
            }
            if (
                (isCancelPassenger || isCancelCar || isCancelBike) &&
                !isNull(booking.value) &&
                !isNull(booking.value!.amount_before) &&
                !isNull(booking.value?.cancel_policy_before) &&
                booking.value!.amount! < booking.value!.amount_before!
            ) {
                // 適用キャンセルポリシー取得
                let current_cancel_policy = getTargetCancelPolicy()
                if (!isNull(current_cancel_policy)) {
                    // キャンセル料計算
                    // 乗船者
                    if (isCancelPassenger) {
                        for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                            if (passenger_detail_array.value![i].deleted) {
                                if (current_cancel_policy!.type == CANCEL_CALCULATION_METHODS_RETIO) {
                                    // ％
                                    amount +=
                                        Math.floor(
                                            ((passenger_detail_array.value![i].unit_price_before! +
                                                passenger_detail_array.value![i].baf_amount_before!) *
                                                current_cancel_policy!.passenger) /
                                                10,
                                        ) * 10
                                } else {
                                    // 円
                                    amount += Math.floor(current_cancel_policy!.passenger! / 10) * 10
                                }
                            }
                        }
                    }
                    // 乗用車
                    if (isCancelCar) {
                        for (let i = 0; i < car_detail_array.value!.length; i++) {
                            if (car_detail_array.value![i].deleted) {
                                if (current_cancel_policy!.type == CANCEL_CALCULATION_METHODS_RETIO) {
                                    // ％
                                    amount +=
                                        Math.floor(
                                            ((car_detail_array.value![i].unit_price_before! + car_detail_array.value![i].baf_amount_before!) *
                                                current_cancel_policy!.vehicle) /
                                                10,
                                        ) * 10
                                } else {
                                    // 円
                                    amount += Math.floor(current_cancel_policy!.vehicle! / 10) * 10
                                }
                            }
                        }
                    }
                    // 二輪車
                    if (isCancelBike) {
                        for (let i = 0; i < two_wheel_detail_array.value!.length; i++) {
                            if (two_wheel_detail_array.value![i].deleted) {
                                if (current_cancel_policy!.type == CANCEL_CALCULATION_METHODS_RETIO) {
                                    // ％
                                    amount +=
                                        Math.floor(
                                            ((two_wheel_detail_array.value![i].unit_price_before! +
                                                two_wheel_detail_array.value![i].baf_amount_before!) *
                                                current_cancel_policy!.vehicle) /
                                                10,
                                        ) * 10
                                } else {
                                    // 円
                                    amount += Math.floor(current_cancel_policy!.vehicle! / 10) * 10
                                }
                            }
                        }
                    }
                }
            }

            cancel_amount.value = amount
        }

        /**
         * キャンセル料計算（一部入金）
         */
        const calcCancelAmountPartialPaid = () => {
            let amount = 0
            const passenger_count = boarding.value?.paid_passenger_count == null ? 0 : boarding.value?.paid_passenger_count
            const vehicle_count = boarding.value?.paid_vehicle_count == null ? 0 : boarding.value?.paid_vehicle_count
            const passenger_amount = boarding.value?.passenger_paid_total == null ? 0 : boarding.value?.passenger_paid_total
            const vehicle_amount = boarding.value?.vehicle_paid_total == null ? 0 : boarding.value?.vehicle_paid_total
            // キャンセル有無確認
            if ((passenger_count == 0 && vehicle_count == 0) || (passenger_amount == 0 && vehicle_amount == 0)) {
                return
            }

            // 適用キャンセルポリシー取得
            const current_cancel_policy = getTargetCancelPolicy()

            if (isNull(current_cancel_policy)) {
                return
            }

            // キャンセル料計算
            if (current_cancel_policy!.type == CANCEL_CALCULATION_METHODS_RETIO) {
                // ％
                if (passenger_amount > 0) {
                    amount += Math.floor((current_cancel_policy!.passenger * passenger_amount) / 10) * 10
                }
                if (vehicle_amount > 0) {
                    amount += Math.floor((current_cancel_policy!.passenger * vehicle_amount) / 10) * 10
                }
            } else {
                // 円
                if (passenger_count > 0) {
                    amount += Math.floor((current_cancel_policy!.passenger! * passenger_count) / 10) * 10
                }
                if (vehicle_count > 0) {
                    amount += Math.floor((current_cancel_policy!.passenger! * vehicle_count) / 10) * 10
                }
            }
            cancel_amount.value = amount
        }

        /**
         * オーソリ支払方法取得
         */
        const getPaymentMethod = async () => {
            console.log('↓↓↓CancelBookingDialog.getAuthori↓↓↓')
            let isPaidStatus = 0
            let paySuccess = 0
            let reqSuccess = 0

            let payment_method = await apiClient
                .get(`${API_ORDER}/boarding/${boarding.value?.boarding_no}/authori`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].result == 'PAYSUCCESS' || data[i].result == 'CAPTURE') {
                            paySuccess += 1
                        } else if (data[i].result == 'REQSUCCESS') {
                            reqSuccess += 1
                        }
                    }
                    return data[0].payment_method
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    return -1
                })

            authori_payment_method.value = payment_method

            if (paySuccess > 0) {
                if (reqSuccess == 0) {
                    // 全入金
                    isPaidStatus = 1
                } else {
                    // 一部入金
                    isPaidStatus = 2
                }
            }
            console.log('↑↑↑CancelBookingDialog.getAuthori↑↑↑')
            return [payment_method, isPaidStatus]
        }
        // 振込手数料計算
        const calcTransferCharge = async () => {
            let amount = 0
            amount = await getTransferCharge(booking.value!.dep_date)

            transfer_charge.value = amount
        }

        /**
         * WEB予約変更可否
         * @param boarding_no 乗船番号
         * @returns [0:変更可否(true,false), 1:変更不可理由]
         */
        const getBoardingChangeable = async (boarding_no: number, mode: number) => {
            let isCanChange = [false, '']
            console.log('↓↓↓CancelBookingDialog.getBoardingChangeable↓↓↓')
            const postData = {
                division: mode,
            }
            isCanChange = await apiClient
                .post(`${API_ORDER}/boarding/${boarding_no}/changeable`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    return [data.changeable, data.reason]
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    return [false, '予約情報の取得に失敗しました。']
                })
            console.log('↑↑↑CancelBookingDialog.getBoardingChangeable↑↑↑')

            return isCanChange
        }
        // 予約キャンセル
        const cancelBoardingStart = (boarding_no: number | null | undefined) => {
            if (!isDisplayAmount.value) {
                return
            }
            // 確認メッセージ
            cancel_boarding_no.value = boarding_no
            context.emit('displayMessage', {
                msg_title: '予約キャンセル',
                msg: ['予約をキャンセルします。', 'よろしいですか？'],
                close_btn: true,
                ok_btn: true,
                submit_btn: false,
                ok_event: cancelBoarding,
                cancel_btn_value: 'いいえ',
                ok_btn_value: 'はい',
            })
        }
        const cancelBoarding = async () => {
            console.log('↓↓↓CancelBookingDialog.cancelBoarding↓↓↓')
            let ret = false
            try {
                store.dispatch('setIsProcessing', true)
                let boarding_changeable = await getBoardingChangeable(cancel_boarding_no.value, 1)
                if (boarding_changeable[0]) {
                    const postData = {
                        boarding_no: cancel_boarding_no.value + '',
                    }
                    ret = await apiClient
                        .put(`${API_ORDER}/cancel`, postData)
                        .then((res: AxiosResponse<any>) => {
                            store.dispatch('setIsProcessing', false)
                            const { status, data } = res
                            context.emit('displayMessage', {
                                msg_title: '予約キャンセル',
                                msg: ['予約キャンセルが完了しました。', '取消確認のメールが届かない場合、マイページの過去の予約をご確認ください。'],
                                ok_btn: true,
                            })
                            // 一覧更新
                            context.emit('cancelBooking')
                            return true
                        })
                        .catch((e: AxiosError<{ error: string }>) => {
                            // エラー処理
                            store.dispatch('setIsProcessing', false)
                            console.log(e.message)
                            context.emit('displayMessage', {
                                msg_title: '予約キャンセル',
                                msg: ['予約キャンセルに失敗しました。'],
                                close_btn: true,
                            })
                            return false
                        })
                } else {
                    store.dispatch('setIsProcessing', false)
                    context.emit('displayMessage', {
                        msg_title: '予約キャンセル',
                        msg: [boarding_changeable[1]],
                        close_btn: true,
                    })
                }
                if (ret) {
                    // await getBookingHistory(null);
                }
            } catch (e) {
                console.log(e)
            }
            console.log('↑↑↑CancelBookingDialog.cancelBoarding↑↑↑')
        }

        // 差額
        const difference_amount = computed(() => {
            if (isNull(booking.value) || isNull(booking.value!.amount_before)) {
                return 0
            }
            if (isNull(authori_payment_method.value)) {
                return 0
            } else {
                if (authori_payment_method.value == AUTHORI_PAYMENT_METHOD_CREDIT) {
                    return booking.value!.amount! + (cancel_amount.value + transfer_charge.value) - booking.value!.amount_before!
                } else {
                    return cancel_amount.value + transfer_charge.value - deposit_amount.value
                }
            }
        })

        onMounted(() => {
            console.log('↓↓↓CancelBookingDialog.onMounted↓↓↓')
            console.log('↑↑↑CancelBookingDialog.onMounted↑↑↑')
        })

        return {
            booking,
            boarding,
            passenger_detail_array,
            passenger_detail_array_before,
            passenger_detail_baby_array,
            passenger_detail_baby_array_before,
            car_detail_array,
            car_detail_array_before,
            two_wheel_detail_array,
            two_wheel_detail_array_before,
            cage_detail_array,
            contract,

            isShowPet,
            dispCageNum,
            dispPatNum,

            disp_wheelchair,
            disp_pregnant,
            disp_oxygen_cylinder,
            disp_amount,
            disp_seat_type_name,
            cancel_amount,
            transfer_charge,
            difference_amount,
            isDisplayAmount,
            authori_payment_method,
            deposit_amount,

            dispBooking,
            getDriverName,
            getTwoWheelName,
            getSeatTypeName,
            getSeaRouteCodeName,

            cancelBoardingStart,

            convertText,
            joinFirstNameAndLastName,
            getPassengerPrice,
            getCarPrice,
            castStringToDate,
            formatDate,
            formatDateTime,

            AUTHORI_PAYMENT_METHOD_PAY_EASY,
            AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE,
        }
    },
    emits: ['displayMessage', 'cancelBooking'],
    name: 'CancelBookingDialog',
    data() {
        return {
            PASSENGER_TYPES: PASSENGER_TYPES,
            GENDERS: GENDERS,
            PREFECTURES: PREFECTURES,
        }
    },
    components: {},
    methods: {},
})
</script>

<style scoped></style>
