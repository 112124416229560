<!-- eslint-disable no-irregular-whitespace -->
<template>
    <!-- data -->
    <section class="data data-top">
        <div class="data__container">
            <div class="data__header">
                <h2 class="data__name">乗用車情報一覧</h2>
            </div>
            <ul id="data__list-passenger" class="data__list data__list-passenger">
                <li class="data__item data__item-header">
                    <ul class="data__line data__line-header">
                        <li class="data__line-item data__line-item-no">No.</li>
                        <li class="data__line-item data__line-item-category">種別</li>
                        <li class="data__line-item data__line-item-carname">車名</li>
                        <li class="data__line-item data__line-item-length">車長</li>
                        <li class="data__line-item data__line-item-plate">ナンバープレート</li>
                        <li class="data__line-item data__line-item-btn"></li>
                    </ul>
                </li>
                <li class="data__item" v-for="(car, idx) in car_list" :key="idx">
                    <ul class="data__line">
                        <li class="data__line-item data__line-item-no">{{ car.no }}</li>
                        <li class="data__line-item data__line-item-category">
                            {{ getCarTypeName(car.car_type_code) }}
                        </li>
                        <li class="data__line-item data__line-item-carname">
                            {{ car.car_name }}
                        </li>
                        <li class="data__line-item data__line-item-length">{{ car.length }}m未満</li>
                        <li class="data__line-item data__line-item-plate">
                            {{ car.place_name }} {{ car.class_number }} {{ car.hiragana }}
                            {{ car.number }}
                        </li>
                        <li class="data__line-item data__line-item-btn">
                            <button class="btn btn-data-change" type="button" v-on:click="displayChangeCar(car.no)">変更</button>
                            <button class="btn btn-data-delete" type="button" v-on:click="displayDeleteCar(car.no)">削除</button>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="data__plus">
                <button class="btn-border btn-border-plus" type="button" v-on:click="displayAddCar">乗用車情報を追加する</button>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
            </div>
        </div>
        <div class="data__container">
            <div class="data__header">
                <h2 class="data__name">二輪車情報一覧</h2>
            </div>
            <ul id="data__list-passenger" class="data__list data__list-passenger">
                <li class="data__item data__item-header">
                    <ul class="data__line data__line-header">
                        <li class="data__line-item data__line-item-no">No.</li>
                        <li class="data__line-item data__line-item-exhaust">種別/排気量</li>
                        <li class="data__line-item data__line-item-bikename">メーカー/車名</li>
                        <li class="data__line-item data__line-item-plate">ナンバープレート</li>
                        <li class="data__line-item data__line-item-btn"></li>
                    </ul>
                </li>
                <li class="data__item" v-for="(bicycle, idx) in bicycle_list" :key="idx">
                    <ul class="data__line">
                        <li class="data__line-item data__line-item-no">{{ bicycle.no }}</li>
                        <li class="data__line-item data__line-item-exhaust">
                            {{ getCarTypeName(bicycle.car_type_code) }}
                        </li>
                        <li class="data__line-item data__line-item-bikename">
                            {{ bicycle.car_name }}
                        </li>
                        <li class="data__line-item data__line-item-plate">
                            {{ bicycle.place_name }} {{ bicycle.class_number }} {{ bicycle.hiragana }} {{ bicycle.number }}
                        </li>
                        <li class="data__line-item data__line-item-btn">
                            <button class="btn btn-data-change" type="button" v-on:click="displayChangeBicycle(bicycle.no)">変更</button>
                            <button class="btn btn-data-delete" type="button" v-on:click="displayDeleteBicycle(bicycle.no)">削除</button>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="data__plus">
                <button class="btn-border btn-border-plus" type="button" v-on:click="displayAddBicycle">二輪車情報を追加する</button>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
            </div>
        </div>
    </section>
    <!-- /data -->

    <!-- setting change-car -->
    <button id="btn-change-car-open-modal" data-remodal-target="change-car" v-show="false">○</button>
    <button id="btn-change-car-close-modal" data-remodal-action="cancel" v-show="false">×</button>
    <section id="change-car" class="setting remodal normal" data-remodal-id="change-car">
        <div class="input">
            <dl class="input__list">
                <!-- line -->
                <div class="input__line">
                    <!-- car-length -->
                    <div id="input__item-car-length" class="input__item input__item-car-length">
                        <dt id="input__header-car-length" class="input__header input__header-car-length">
                            <label id="input__name-car-length" class="input__name input__name-car-length" for="input__car-length">車長</label>
                            <span class="input__required">必須</span>
                        </dt>
                        <dd id="input__container-car-length" class="input__container input__container-car-length">
                            <input
                                id="input__car-length"
                                class="input__form input__car-length"
                                type="number"
                                name="input__car-length"
                                min="4"
                                max="6"
                                v-model="target_length"
                            />
                            <span>ｍ未満</span>
                        </dd>
                        <p class="input__error-text">{{ length_error }}</p>
                        <p class="input__text">
                            車長については車検証をご確認のうえ、対象m未満の数字を入力ください。<br />
                            例）アクア：4,050mm　→　5m未満
                        </p>
                    </div>
                    <!-- car-model -->
                    <div id="input__item-car-model" class="input__item input__item-car-model">
                        <dt id="input__header-car-model" class="input__header input__header-car-model">
                            <label id="input__name-car-model" class="input__name input__name-car-model" for="input__car-model">車名</label>
                        </dt>
                        <dd id="input__container-car-model" class="input__container input__container-car-model">
                            <input
                                id="input__car-model"
                                class="input__form input__car-model"
                                type="text"
                                name="input__car-model"
                                v-model="target_car_name"
                            />
                        </dd>
                    </div>
                </div>
                <!-- line -->
                <div class="input__line input__line-number">
                    <div id="input__item-number-wrapper" class="input__item-number-wrapper">
                        <div id="input__item-number-pack" class="input__item-number-pack">
                            <!-- number-area -->
                            <div id="input__item-number-area" class="input__item input__item-number-area">
                                <dt id="input__header-number-area" class="input__header input__header-number-area">
                                    <label id="input__name-number-area" class="input__name input__name-number-area" for="">地域</label>
                                </dt>
                                <dd id="input__container-number-area" class="input__container input__container-number-area">
                                    <input
                                        id="input__number-area"
                                        class="input__form input__number-area"
                                        type="text"
                                        name="input__number-area"
                                        v-model="target_place_name"
                                    />
                                </dd>
                            </div>
                            <!-- number-category -->
                            <div id="input__item-number-category" class="input__item input__item-number-category">
                                <dt id="input__header-numberplate" class="input__header input__header-number-category">
                                    <label id="input__name-number-category" class="input__name input__name-number-category" for="">分類</label>
                                </dt>
                                <dd id="input__container-number-category" class="input__container input__container-number-category">
                                    <input
                                        id="input__number-category"
                                        class="input__form input__number-category"
                                        type="text"
                                        name="input__number-category"
                                        v-model="target_class_number"
                                    />
                                </dd>
                            </div>
                            <!-- number-char -->
                            <div id="input__item-number-char" class="input__item input__item-number-char">
                                <dt id="input__header-number-char" class="input__header input__header-number-char">
                                    <label id="input__name-number-char" class="input__name input__name-number-char" for="">符号</label>
                                </dt>
                                <dd id="input__container-number-char" class="input__container input__container-number-char">
                                    <input
                                        id="input__number-char"
                                        class="input__form input__number-char"
                                        type="text"
                                        name="input__number-char"
                                        v-model="target_hiragana"
                                    />
                                </dd>
                            </div>
                            <!-- number-plate -->
                            <div id="input__item-number-plate" class="input__item input__item-number-plate">
                                <dt id="input__header-number-plate" class="input__header input__header-number-plate">
                                    <label id="input__name-number-plate" class="input__name input__name-number-plate" for=""
                                        >No.(1桁～4桁の数字のみ)</label
                                    >
                                    <span class="input__required">必須</span>
                                </dt>
                                <dd id="input__container-number-plate" class="input__container input__container-number-plate">
                                    <input
                                        id="input__number-plate"
                                        class="input__form input__number-plate"
                                        type="text"
                                        name="input__number-plate"
                                        v-model="target_number"
                                    />
                                </dd>
                                <p class="input__error-text">半角数字4桁以内で入力してください。</p>
                            </div>
                        </div>
                        <p class="input__text">
                            ナンバープレートの数字や車体形状によってはインターネット予約対象外となります。<br />
                            詳しくは<a href="" target="_blank">こちら</a>をご確認下さい。
                        </p>
                        <div id="input__item-explain-car" class="input__item-explain input__item-explain-car">
                            <img class="" src="@/images/items/plate-car.svg" width="247" height="67" alt="ナンバープレート説明画像" />
                        </div>
                    </div>
                </div>
            </dl>
        </div>
        <div class="btn-container btn-container-modal">
            <button class="btn btn-back" data-remodal-action="cancel">キャンセル</button>
            <button class="btn btn-next" type="button" v-on:click="commitCar">登録する</button>
        </div>
    </section>
    <!-- /setting change-car -->
    <!-- setting change-bike -->
    <button id="btn-change-bike-open-modal" data-remodal-target="change-bike" v-show="false">○</button>
    <button id="btn-change-bike-close-modal" data-remodal-action="cancel" v-show="false">×</button>
    <section id="change-bike" class="setting remodal normal" data-remodal-id="change-bike">
        <div class="input">
            <dl class="input__list">
                <!-- line -->
                <div class="input__line input__line-bike-info">
                    <div class="input__item-bike-info-pack">
                        <!-- engine -->
                        <div id="input__item-engine" class="input__item input__item-engine">
                            <dt id="input__header-engine" class="input__header input__header-engine">
                                <label id="input__name-engine" class="input__name input__name-engine" for="input__engine">種別/排気量</label>
                                <span class="input__required">必須</span>
                            </dt>
                            <dd id="input__container-engine" class="input__container input__container-engine">
                                <select id="input__engine" class="input__select input__engine" name="input__engine" v-model="target_car_type_code">
                                    <option id="input__" class="input__" value=""></option>
                                    <option class="selectable" :value="CAR_TYPE_TWO_WHEEL_SMALL">
                                        {{ getCarTypeName(CAR_TYPE_TWO_WHEEL_SMALL) }}
                                    </option>
                                    <option class="selectable" :value="CAR_TYPE_TWO_WHEEL_MEDIUM">
                                        {{ getCarTypeName(CAR_TYPE_TWO_WHEEL_MEDIUM) }}
                                    </option>
                                    <option class="selectable" :value="CAR_TYPE_TWO_WHEEL_LARGE">
                                        {{ getCarTypeName(CAR_TYPE_TWO_WHEEL_LARGE) }}
                                    </option>
                                </select>
                            </dd>
                            <p class="input__error-text">種別/排気量を選択してください</p>
                        </div>
                        <!-- maker -->
                        <div id="input__item-maker" class="input__item input__item-maker">
                            <dt id="input__header-maker" class="input__header input__header-maker">
                                <label id="input__name-maker" class="input__name input__name-maker" for="input__maker">メーカー/車名</label>
                            </dt>
                            <dd id="input__container-maker" class="input__container input__container-maker">
                                <input id="input__maker" class="input__form input__maker" type="text" name="input__maker" v-model="target_car_name" />
                            </dd>
                        </div>
                    </div>
                </div>
                <!-- line -->
                <div class="input__line input__line-number">
                    <div id="input__item-number-wrapper" class="input__item-number-wrapper">
                        <div id="input__item-number-pack" class="input__item-number-pack">
                            <!-- number-area -->
                            <div id="input__item-number-area" class="input__item input__item-number-area">
                                <dt id="input__header-number-area" class="input__header input__header-number-area">
                                    <label id="input__name-number-area" class="input__name input__name-number-area" for="">地域</label>
                                </dt>
                                <dd id="input__container-number-area" class="input__container input__container-number-area">
                                    <input
                                        id="input__number-area"
                                        class="input__form input__number-area"
                                        type="text"
                                        name="input__number-area"
                                        v-model="target_place_name"
                                    />
                                </dd>
                            </div>
                            <!-- number-category -->
                            <div id="input__item-number-category" class="input__item input__item-number-category">
                                <dt id="input__header-numberplate" class="input__header input__header-number-category">
                                    <label id="input__name-number-category" class="input__name input__name-number-category" for="">分類</label>
                                </dt>
                                <dd id="input__container-number-category" class="input__container input__container-number-category">
                                    <input
                                        id="input__number-category"
                                        class="input__form input__number-category"
                                        type="text"
                                        name="input__number-category"
                                        v-model="target_class_number"
                                    />
                                </dd>
                            </div>
                            <!-- number-char -->
                            <div id="input__item-number-char" class="input__item input__item-number-char">
                                <dt id="input__header-number-char" class="input__header input__header-number-char">
                                    <label id="input__name-number-char" class="input__name input__name-number-char" for="">符号</label>
                                </dt>
                                <dd id="input__container-number-char" class="input__container input__container-number-char">
                                    <input
                                        id="input__number-char"
                                        class="input__form input__number-char"
                                        type="text"
                                        name="input__number-char"
                                        v-model="target_hiragana"
                                    />
                                </dd>
                            </div>
                            <!-- number-plate -->
                            <div id="input__item-bike-number-plate" class="input__item input__item-number-plate">
                                <dt id="input__header-number-plate" class="input__header input__header-number-plate">
                                    <label id="input__name-number-plate" class="input__name input__name-number-plate" for=""
                                        >No.(1桁～5桁の数字のみ)</label
                                    >
                                    <span class="input__required" v-show="!isBicycle">必須</span>
                                </dt>
                                <dd id="input__container-number-plate" class="input__container input__container-number-plate">
                                    <input
                                        id="input__number-plate"
                                        class="input__form input__number-plate"
                                        type="text"
                                        name="input__number-plate"
                                        v-model="target_number"
                                    />
                                </dd>
                                <p class="input__error-text">半角数字5桁以内で入力してください。</p>
                            </div>
                        </div>
                        <p class="input__text">
                            ナンバープレートの数字や車体形状によってはインターネット予約対象外となります。<br />
                            詳しくは<a href="" target="_blank">こちら</a>をご確認下さい。
                        </p>
                        <div id="input__item-explain-bike" class="input__item-explain input__item-explain-bike">
                            <img
                                class="input__item-explain-bike-01"
                                src="@/images/items/plate-bike-01.svg"
                                width="247"
                                height="112"
                                alt="ナンバープレート説明画像01"
                            />
                            <img
                                class="input__item-explain-bike-02"
                                src="@/images/items/plate-bike-02.svg"
                                width="247"
                                height="85"
                                alt="ナンバープレート説明画像02"
                            />
                        </div>
                    </div>
                </div>
            </dl>
        </div>
        <div class="btn-container btn-container-modal">
            <button class="btn btn-back" data-remodal-action="cancel">キャンセル</button>
            <button class="btn btn-next" type="button" v-on:click="commitBicycle">登録する</button>
        </div>
    </section>
    <!-- /setting change-car -->
    <!-- setting delete-car -->
    <button id="btn-delete-car-open-modal" data-remodal-target="delete-car" v-show="false">○</button>
    <button id="btn-delete-car-close-modal" data-remodal-action="cancel" v-show="false">×</button>
    <section id="delete-car" class="setting remodal normal" data-remodal-id="delete-car">
        <div class="output">
            <dl class="output__list">
                <!-- line -->
                <div class="output__line output__line-car">
                    <p class="input__attention">この乗用車情報を削除してもよろしいですか？</p>
                    <div class="output__item-car-pack">
                        <!-- category -->
                        <div class="output__item">
                            <dt class="output__header">
                                <div class="output__name output__name-category">種別</div>
                            </dt>
                            <dd class="output__container output__container-category">
                                <div class="output__data output__data-category">
                                    {{ getCarTypeName(target_car_type_code) }}
                                </div>
                            </dd>
                        </div>
                        <!-- carname -->
                        <div class="output__item">
                            <dt class="output__header">
                                <div class="output__name output__name-carname">車名</div>
                            </dt>
                            <dd class="output__container output__container-carname">
                                <div class="output__data output__data-carname">
                                    {{ target_car_name }}
                                </div>
                            </dd>
                        </div>
                        <!-- length -->
                        <div class="output__item">
                            <dt class="output__header">
                                <div class="output__name output__name-length">車長</div>
                            </dt>
                            <dd class="output__container output__container-length">
                                <div class="output__data output__data-length">{{ target_length }}m未満</div>
                            </dd>
                        </div>
                    </div>
                </div>
                <!-- line -->
                <div class="output__line">
                    <!-- number -->
                    <div class="output__item">
                        <dt class="output__header">
                            <div class="output__name output__name-number">No.</div>
                        </dt>
                        <dd class="output__container output__container-number">
                            <div class="output__data output__data-number">
                                {{ target_number }}
                            </div>
                        </dd>
                    </div>
                    <!-- area -->
                    <div class="output__item">
                        <dt class="output__header">
                            <div class="output__name output__name-area">地域</div>
                        </dt>
                        <dd class="output__container output__container-area">
                            <div class="output__data output__data-area">
                                {{ target_place_name }}
                            </div>
                        </dd>
                    </div>
                    <!-- category -->
                    <div class="output__item">
                        <dt class="output__header">
                            <div class="output__name output__name-category">分類</div>
                        </dt>
                        <dd class="output__container output__container-category">
                            <div class="output__data output__data-category">
                                {{ target_class_number }}
                            </div>
                        </dd>
                    </div>
                    <!-- char -->
                    <div class="output__item">
                        <dt class="output__header">
                            <div class="output__name output__name-char">符号</div>
                        </dt>
                        <dd class="output__container output__container-char">
                            <div class="output__data output__data-char">
                                {{ target_hiragana }}
                            </div>
                        </dd>
                    </div>
                </div>
            </dl>
        </div>
        <div class="btn-container btn-container-modal">
            <button class="btn btn-back" data-remodal-action="cancel">キャンセル</button>
            <button class="btn btn-remove" type="button" v-on:click="commitCar">削除する</button>
        </div>
    </section>
    <!-- /setting delete-car -->
    <!-- setting delete-bike -->
    <button id="btn-delete-bike-open-modal" data-remodal-target="delete-bike" v-show="false">○</button>
    <button id="btn-delete-bike-close-modal" data-remodal-action="cancel" v-show="false">×</button>
    <section id="delete-bike" class="setting remodal normal" data-remodal-id="delete-bike">
        <div class="output">
            <dl class="output__list">
                <!-- line -->
                <div class="output__line output__line-bike">
                    <p class="input__attention">この二輪車情報を削除してもよろしいですか？</p>
                    <div class="output__item-bike-pack">
                        <!-- exhaust -->
                        <div class="output__item">
                            <dt class="output__header">
                                <div class="output__name output__name-exhaust">種別/排気量</div>
                            </dt>
                            <dd class="output__container output__container-exhaust">
                                <div class="output__data output__data-exhaust">
                                    {{ getCarTypeName(target_car_type_code) }}
                                </div>
                            </dd>
                        </div>
                        <!-- bikename -->
                        <div class="output__item">
                            <dt class="output__header">
                                <div class="output__name output__name-bikename">メーカー／車名</div>
                            </dt>
                            <dd class="output__container output__container-bikename">
                                <div class="output__data output__data-bikename">
                                    {{ target_car_name }}
                                </div>
                            </dd>
                        </div>
                    </div>
                </div>
                <!-- line -->
                <div class="output__line">
                    <!-- number -->
                    <div class="output__item">
                        <dt class="output__header">
                            <div class="output__name output__name-number">No.</div>
                        </dt>
                        <dd class="output__container output__container-number">
                            <div class="output__data output__data-number">
                                {{ target_number }}
                            </div>
                        </dd>
                    </div>
                    <!-- area -->
                    <div class="output__item">
                        <dt class="output__header">
                            <div class="output__name output__name-area">地域</div>
                        </dt>
                        <dd class="output__container output__container-area">
                            <div class="output__data output__data-area">
                                {{ target_place_name }}
                            </div>
                        </dd>
                    </div>
                    <!-- category -->
                    <div class="output__item">
                        <dt class="output__header">
                            <div class="output__name output__name-category">分類</div>
                        </dt>
                        <dd class="output__container output__container-category">
                            <div class="output__data output__data-category">
                                {{ target_class_number }}
                            </div>
                        </dd>
                    </div>
                    <!-- char -->
                    <div class="output__item">
                        <dt class="output__header">
                            <div class="output__name output__name-char">符号</div>
                        </dt>
                        <dd class="output__container output__container-char">
                            <div class="output__data output__data-char">
                                {{ target_hiragana }}
                            </div>
                        </dd>
                    </div>
                </div>
            </dl>
        </div>
        <div class="btn-container btn-container-modal">
            <button class="btn btn-back" data-remodal-action="cancel">キャンセル</button>
            <button class="btn btn-remove" type="button" v-on:click="commitBicycle">削除する</button>
        </div>
    </section>
    <!-- /setting delete-bike -->
    <ShipAnimationEnd></ShipAnimationEnd>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch, computed } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import {
    API_USERS,
    API_ORDER,
    isNull,
    getCarTypeName,
    ZenNumToHanNum,
    CAR_TYPE_PASSENGER,
    CAR_TYPE_BICYCLE,
    CAR_TYPE_TWO_WHEEL_SMALL,
    CAR_TYPE_TWO_WHEEL_MEDIUM,
    CAR_TYPE_TWO_WHEEL_LARGE,
} from '@/lib/util'
import { isNumeric } from '@/lib/validation_util'
import { number } from 'yup'

export default defineComponent({
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'VehicleInfo',
    setup(props, context) {
        const router = useRouter()
        const store = useStore()

        const car_list = ref<VehicleDetail[]>()
        const bicycle_list = ref<VehicleDetail[]>()

        const MODE_LIST = 0
        const MODE_ADD = 1
        const MODE_UPDATE = 2
        const MODE_DELETE = 3
        const mode = ref<number>(MODE_LIST)
        const target_no = ref<number | null>(null)
        const target_id = ref<number | null>(null)
        const target_car_type_code = ref('')
        const target_length = ref<number | null>(null)
        const target_car_name = ref('')
        const target_place_name = ref('')
        const target_class_number = ref('')
        const target_hiragana = ref('')
        const target_number = ref('')
        const length_error = ref('')

        const isBicycle = computed(() => {
            return target_car_type_code.value == CAR_TYPE_BICYCLE
        })

        watch(target_length, (next, prev) => {
            if (!isNull(target_length.value)) {
                if (target_length.value + '' != ZenNumToHanNum(target_length.value + '')) {
                    target_length.value = Number(ZenNumToHanNum(target_length.value + ''))
                }

                const length = isNull(target_length.value) ? 0 : Number(target_length.value)
                if (length < 4) {
                    target_length.value = 4
                }
            }
        })

        // リスト表示用
        type VehicleDetail = {
            no: number
            id: number
            car_type_code: string
            length: number | null
            car_name: string
            place_name: string
            class_number: string
            hiragana: string
            number: string
            deleted: boolean
        }

        // 車両一覧取得
        const getVehicleList = () => {
            car_list.value = []
            bicycle_list.value = []
            const postData = {}
            apiClient
                .get(`${API_USERS}/vehicle`, { params: postData })
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    // 乗用車
                    for (let i = 0; i < data.car.length; i++) {
                        car_list.value?.push({
                            no: i + 1,
                            id: data.car[i].id,
                            car_type_code: data.car[i].car_type_code,
                            length: data.car[i].length,
                            car_name: data.car[i].car_name,
                            place_name: data.car[i].place_name,
                            class_number: data.car[i].class_number,
                            hiragana: data.car[i].hiragana,
                            number: data.car[i].number,
                            deleted: false,
                        })
                    }
                    // 二輪車
                    for (let i = 0; i < data.bicycle.length; i++) {
                        bicycle_list.value?.push({
                            no: i + 1,
                            id: data.bicycle[i].id,
                            car_type_code: data.bicycle[i].car_type_code,
                            length: data.bicycle[i].length,
                            car_name: data.bicycle[i].car_name,
                            place_name: data.bicycle[i].place_name,
                            class_number: data.bicycle[i].class_number,
                            hiragana: data.bicycle[i].hiragana,
                            number: data.bicycle[i].number,
                            deleted: false,
                        })
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
        }
        // エラー表示クリア
        const clearErrorMessage = () => {
            $('#input__item-car-length').removeClass('input__error')
            $('#input__item-number-plate').removeClass('input__error')
            $('#input__item-engine').removeClass('input__error')
            $('#input__item-bike-number-plate').removeClass('input__error')
            length_error.value = ''
        }
        // 乗用車情報追加画面表示
        const displayAddCar = () => {
            // 初期化
            clearErrorMessage()
            mode.value = MODE_ADD
            target_no.value = null
            target_id.value = null
            target_car_type_code.value = ''
            target_length.value = null
            target_car_name.value = ''
            target_place_name.value = ''
            target_class_number.value = ''
            target_hiragana.value = ''
            target_number.value = ''
            // location.href = '#change-car';
            $('#btn-change-car-open-modal').click()
        }
        // 乗用車情報更新画面表示
        const displayChangeCar = (no: number) => {
            // 初期化
            clearErrorMessage()
            mode.value = MODE_UPDATE
            if (!isNull(car_list.value)) {
                for (let i = 0; i < car_list.value!.length; i++) {
                    if (car_list.value![i].no == no) {
                        target_no.value = car_list.value![i].no
                        target_id.value = car_list.value![i].id
                        target_car_type_code.value = car_list.value![i].car_type_code
                        target_length.value = car_list.value![i].length
                        target_car_name.value = car_list.value![i].car_name
                        target_place_name.value = car_list.value![i].place_name
                        target_class_number.value = car_list.value![i].class_number
                        target_hiragana.value = car_list.value![i].hiragana
                        target_number.value = car_list.value![i].number
                        // location.href = '#change-car';
                        $('#btn-change-car-open-modal').click()
                        break
                    }
                }
            }
        }
        // 乗用車情報削除画面表示
        const displayDeleteCar = (no: number) => {
            // 初期化
            mode.value = MODE_DELETE
            if (!isNull(car_list.value)) {
                for (let i = 0; i < car_list.value!.length; i++) {
                    if (car_list.value![i].no == no) {
                        target_no.value = car_list.value![i].no
                        target_id.value = car_list.value![i].id
                        target_car_type_code.value = car_list.value![i].car_type_code
                        target_length.value = car_list.value![i].length
                        target_car_name.value = car_list.value![i].car_name
                        target_place_name.value = car_list.value![i].place_name
                        target_class_number.value = car_list.value![i].class_number
                        target_hiragana.value = car_list.value![i].hiragana
                        target_number.value = car_list.value![i].number
                        // location.href = '#delete-car';
                        $('#btn-delete-car-open-modal').click()
                        break
                    }
                }
            }
        }
        // 入力チェック
        const chkValidationCar = (): boolean => {
            let err_count = 0
            // 初期化
            clearErrorMessage()
            // No.
            if ((mode.value == MODE_ADD || mode.value == MODE_UPDATE) && isNull(target_number.value)) {
                $('#input__item-number-plate').addClass('input__error')
                err_count++
            } else if ((mode.value == MODE_ADD || mode.value == MODE_UPDATE) && !isNull(target_number.value)) {
                // 半角数字のみ
                if (!isNumeric(target_number.value)) {
                    $('#input__item-number-plate').addClass('input__error')
                    err_count++
                }
                // 4桁以下
                if (target_number.value.length > 4) {
                    $('#input__item-number-plate').addClass('input__error')
                    err_count++
                }
            }
            // 車長
            if (mode.value == MODE_ADD || mode.value == MODE_UPDATE) {
                if (isNull(target_length.value)) {
                    $('#input__item-car-length').addClass('input__error')
                    length_error.value = '車長を入力してください'
                    err_count++
                } else if (!isNumeric(target_length.value + '')) {
                    $('#input__item-car-length').addClass('input__error')
                    length_error.value = '車長は数字で入力してください'
                    err_count++
                } else {
                    const length = isNull(target_length.value) ? 0 : Number(target_length.value)
                    if (length < 3 || length > 6) {
                        $('#input__item-car-length').addClass('input__error')
                        length_error.value = 'web予約は6ｍ未満までの自家用車両が対象です'
                        err_count++
                    }
                }
            }

            return err_count == 0
        }
        const commitCar = () => {
            // 入力チェック
            if (chkValidationCar()) {
                if (mode.value == MODE_ADD) {
                    // 同行者情報追加
                    addCar()
                } else if (mode.value == MODE_UPDATE) {
                    // 同行者情報更新
                    changeCar()
                } else if (mode.value == MODE_DELETE) {
                    // 同行者情報削除
                    deleteCar()
                }
            }
        }
        // 乗用車情報追加
        const addCar = async () => {
            console.log('↓↓↓Vehicle.addCar↓↓↓')
            let car_detail = {
                id: null,
                car_name: target_car_name.value,
                car_type_code: CAR_TYPE_PASSENGER,
                number: target_number.value,
                place_name: target_place_name.value,
                class_number: target_class_number.value,
                hiragana: target_hiragana.value,
                length: Number(target_length.value),
                deleted: false,
            }
            const postData = {
                vehicle: [car_detail],
            }
            await apiClient
                .post(`${API_USERS}/vehicle`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    // 画面を開きなおす
                    location.href = '/member/vehicle'
                    // // 車両一覧取得
                    // getVehicleList();
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Vehicle.addCar↑↑↑')
        }
        // 乗用車情報更新
        const changeCar = async () => {
            console.log('↓↓↓Vehicle.changeCar↓↓↓')
            let car_detail = {
                id: target_id.value,
                car_name: target_car_name.value,
                car_type_code: CAR_TYPE_PASSENGER,
                number: target_number.value,
                place_name: target_place_name.value,
                class_number: target_class_number.value,
                hiragana: target_hiragana.value,
                length: Number(target_length.value),
                deleted: false,
            }
            const postData = {
                vehicle: [car_detail],
            }
            await apiClient
                .post(`${API_USERS}/vehicle`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    // 画面を開きなおす
                    location.href = '/member/vehicle'
                    // // 車両一覧取得
                    // getVehicleList();
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Vehicle.changeCar↑↑↑')
        }
        // 乗用車情報削除
        const deleteCar = async () => {
            console.log('↓↓↓Vehicle.deleteCar↓↓↓')
            let car_detail = {
                id: target_id.value,
                car_name: target_car_name.value,
                car_type_code: CAR_TYPE_PASSENGER,
                number: target_number.value,
                place_name: target_place_name.value,
                class_number: target_class_number.value,
                hiragana: target_hiragana.value,
                length: Number(target_length.value),
                deleted: true,
            }
            const postData = {
                vehicle: [car_detail],
            }
            await apiClient
                .post(`${API_USERS}/vehicle`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    // 画面を開きなおす
                    location.href = '/member/vehicle'
                    // // 車両一覧取得
                    // getVehicleList();
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Vehicle.deleteCar↑↑↑')
        }

        // 二輪車情報追加画面表示
        const displayAddBicycle = () => {
            // 初期化
            clearErrorMessage()
            mode.value = MODE_ADD
            target_no.value = null
            target_id.value = null
            target_car_type_code.value = ''
            target_length.value = null
            target_car_name.value = ''
            target_place_name.value = ''
            target_class_number.value = ''
            target_hiragana.value = ''
            target_number.value = ''
            // location.href = '#change-bike';
            $('#btn-change-bike-open-modal').click()
        }
        // 二輪車情報更新画面表示
        const displayChangeBicycle = (no: number) => {
            // 初期化
            clearErrorMessage()
            mode.value = MODE_UPDATE
            if (!isNull(bicycle_list.value)) {
                for (let i = 0; i < bicycle_list.value!.length; i++) {
                    if (bicycle_list.value![i].no == no) {
                        target_no.value = bicycle_list.value![i].no
                        target_id.value = bicycle_list.value![i].id
                        target_car_type_code.value = bicycle_list.value![i].car_type_code
                        target_length.value = bicycle_list.value![i].length
                        target_car_name.value = bicycle_list.value![i].car_name
                        target_place_name.value = bicycle_list.value![i].place_name
                        target_class_number.value = bicycle_list.value![i].class_number
                        target_hiragana.value = bicycle_list.value![i].hiragana
                        target_number.value = bicycle_list.value![i].number
                        // location.href = '#change-bike';
                        $('#btn-change-bike-open-modal').click()
                        break
                    }
                }
            }
        }
        // 二輪車情報削除画面表示
        const displayDeleteBicycle = (no: number) => {
            // 初期化
            mode.value = MODE_DELETE
            if (!isNull(bicycle_list.value)) {
                for (let i = 0; i < bicycle_list.value!.length; i++) {
                    if (bicycle_list.value![i].no == no) {
                        target_no.value = bicycle_list.value![i].no
                        target_id.value = bicycle_list.value![i].id
                        target_car_type_code.value = bicycle_list.value![i].car_type_code
                        target_length.value = bicycle_list.value![i].length
                        target_car_name.value = bicycle_list.value![i].car_name
                        target_place_name.value = bicycle_list.value![i].place_name
                        target_class_number.value = bicycle_list.value![i].class_number
                        target_hiragana.value = bicycle_list.value![i].hiragana
                        target_number.value = bicycle_list.value![i].number
                        // location.href = '#delete-bike';
                        $('#btn-delete-bike-open-modal').click()
                        break
                    }
                }
            }
        }
        // 入力チェック
        const chkValidationBicycle = (): boolean => {
            let err_count = 0
            // 初期化
            clearErrorMessage()
            // No.
            if ((mode.value == MODE_ADD || mode.value == MODE_UPDATE) && !isBicycle.value && isNull(target_number.value)) {
                $('#input__item-bike-number-plate').addClass('input__error')
                err_count++
            } else if ((mode.value == MODE_ADD || mode.value == MODE_UPDATE) && !isBicycle.value && !isNull(target_number.value)) {
                // 半角数字のみ
                if (!isNumeric(target_number.value)) {
                    $('#input__item-bike-number-plate').addClass('input__error')
                    err_count++
                }
                // 4桁以下
                if (target_number.value.length > 5) {
                    $('#input__item-bike-number-plate').addClass('input__error')
                    err_count++
                }
            }
            // 種別/排気量
            if ((mode.value == MODE_ADD || mode.value == MODE_UPDATE) && isNull(target_car_type_code.value)) {
                $('#input__item-engine').addClass('input__error')
                err_count++
            }

            return err_count == 0
        }
        const commitBicycle = () => {
            // 入力チェック
            if (chkValidationBicycle()) {
                if (mode.value == MODE_ADD) {
                    // 同行者情報追加
                    addBicycle()
                } else if (mode.value == MODE_UPDATE) {
                    // 同行者情報更新
                    changeBicycle()
                } else if (mode.value == MODE_DELETE) {
                    // 同行者情報削除
                    deleteBicycle()
                }
            }
        }
        // 二輪車情報追加
        const addBicycle = async () => {
            console.log('↓↓↓Vehicle.addBicycle↓↓↓')
            let bicycle_detail = {
                id: null,
                car_name: target_car_name.value,
                car_type_code: target_car_type_code.value,
                number: target_number.value,
                place_name: target_place_name.value,
                class_number: target_class_number.value,
                hiragana: target_hiragana.value,
                length: null,
                deleted: false,
            }
            const postData = {
                vehicle: [bicycle_detail],
            }
            await apiClient
                .post(`${API_USERS}/vehicle`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    // 画面を開きなおす
                    location.href = '/member/vehicle'
                    // // 車両一覧取得
                    // getVehicleList();
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Vehicle.addBicycle↑↑↑')
        }
        // 二輪車情報更新
        const changeBicycle = async () => {
            console.log('↓↓↓Vehicle.changeBicycle↓↓↓')
            let bicycle_detail = {
                id: target_id.value,
                car_name: target_car_name.value,
                car_type_code: target_car_type_code.value,
                number: target_number.value,
                place_name: target_place_name.value,
                class_number: target_class_number.value,
                hiragana: target_hiragana.value,
                length: null,
                deleted: false,
            }
            const postData = {
                vehicle: [bicycle_detail],
            }
            await apiClient
                .post(`${API_USERS}/vehicle`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    // 画面を開きなおす
                    location.href = '/member/vehicle'
                    // // 車両一覧取得
                    // getVehicleList();
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Vehicle.changeBicycle↑↑↑')
        }
        // 二輪車情報削除
        const deleteBicycle = async () => {
            console.log('↓↓↓Vehicle.deleteBicycle↓↓↓')
            let bicycle_detail = {
                id: target_id.value,
                car_name: target_car_name.value,
                car_type_code: target_car_type_code.value,
                number: target_number.value,
                place_name: target_place_name.value,
                class_number: target_class_number.value,
                hiragana: target_hiragana.value,
                length: null,
                deleted: true,
            }
            const postData = {
                vehicle: [bicycle_detail],
            }
            await apiClient
                .post(`${API_USERS}/vehicle`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    // 画面を開きなおす
                    location.href = '/member/vehicle'
                    // // 車両一覧取得
                    // getVehicleList();
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Vehicle.deleteBicycle↑↑↑')
        }

        // マイページに移動
        let moveMyPage = () => {
            router.push('/member/my_page')
        }

        onMounted(async () => {
            console.log('↓↓↓Vehicle.onMounted↓↓↓')
            if (!store.state.is_logged_in) {
                // ログイン状態で無い場合、使用不可
                router.push('/member/login')
            }
            // 車両一覧取得
            getVehicleList()
            console.log('↑↑↑Vehicle.onMounted↑↑↑')
        })

        return {
            car_list,
            bicycle_list,

            target_no,
            target_id,
            target_car_type_code,
            target_length,
            target_car_name,
            target_place_name,
            target_class_number,
            target_hiragana,
            target_number,
            isBicycle,
            length_error,

            CAR_TYPE_PASSENGER,
            CAR_TYPE_BICYCLE,
            CAR_TYPE_TWO_WHEEL_SMALL,
            CAR_TYPE_TWO_WHEEL_MEDIUM,
            CAR_TYPE_TWO_WHEEL_LARGE,

            displayAddCar,
            displayChangeCar,
            displayDeleteCar,
            commitCar,
            displayAddBicycle,
            displayChangeBicycle,
            displayDeleteBicycle,
            commitBicycle,
            moveMyPage,

            getCarTypeName,
        }
    },
    components: {
        ShipAnimationEnd,
    },
    data() {
        return {}
    },
    methods: {},
})
</script>
