<template>
    <tr>
        <th>乳幼児</th>
        <td id="search__item-people">
            <span>{{ display_passengers() }}</span>
            <button id="btn-people" class="btn-border" type="button" v-on:click="toggleInputPeople()">変更</button>

            <div :id="'search__set-people-baby' + list_idx" class="search__set search__set-people none" style="max-width: 40rem">
                <ul class="input-set__list">
                    <!-- <li id="input-set__item-adult" class="input-set__item input-set__item-adult">
                  <label id="input-set__label-adult" class="input-set__label input-set__label-adult" for="input-set__number-adult">大人</label>
                  <div class="input-set__change">
                    <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_ADULT)">－</button>
                    <input id="input-set__number-adult" class="input-set__number input-set__number-adult" type="text" name="adult" v-model="passenger_adult_num_tmp" readonly>
                    <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_ADULT)">＋</button>
                  </div>
                </li> -->
                    <!-- <li id="input-set__item-child" class="input-set__item input-set__item-child">
                  <label id="input-set__label-child" class="input-set__label input-set__label-child" for="input-set__number-child">小人</label>
                  <div class="input-set__change">
                    <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_CHILD)">－</button>
                    <input id="input-set__number-child" class="input-set__number input-set__number-child" type="text" name="child" v-model="passenger_child_num_tmp" readonly>
                    <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_CHILD)">＋</button>
                  </div>
                </li> -->
                    <li id="input-set__item-toddler" class="input-set__item input-set__item-toddler">
                        <label id="input-set__label-toddler" class="input-set__label input-set__label-toddler" for="input-set__number-toddler"
                            >幼児（未就学児）</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_INFANT)">－</button>
                            <input
                                id="input-set__number-toddler"
                                class="input-set__number input-set__number-toddler"
                                type="text"
                                name="toddler"
                                v-model="passenger_infant_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_INFANT)">＋</button>
                        </div>
                    </li>
                    <li id="input-set__item-baby" class="input-set__item input-set__item-baby">
                        <label id="input-set__label-baby" class="input-set__label input-set__label-baby" for="input-set__number-baby"
                            >乳児（0才）</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_BABY)">－</button>
                            <input
                                id="input-set__number-baby"
                                class="input-set__number input-set__number-baby"
                                type="text"
                                name="baby"
                                v-model="passenger_baby_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_BABY)">＋</button>
                        </div>
                    </li>
                    <li class="input-set__link" v-on:click="aboutInfane()">
                        <a>※幼児について</a>
                    </li>
                </ul>
                <div class="input-set__buttons">
                    <button
                        id="input-set__cansel-people"
                        class="btn input-set__cansel input-set__cansel-people"
                        type="button"
                        v-on:click="cancelPassenger()"
                    >
                        キャンセル
                    </button>
                    <button
                        id="input-set__setting-people"
                        class="btn input-set__setting input-set__setting-people"
                        type="button"
                        v-on:click="commitBaby()"
                    >
                        設定
                    </button>
                </div>
            </div>
        </td>
        <td class="detail__td-count"></td>
    </tr>
</template>

<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, ref } from 'vue'
import $ from 'jquery'
import { isNull } from '@/lib/util'
import { HeadcountDistribution } from '@/lib/types'
import {
    PERSONALITY_TYPE_INDIVIDUAL,
    PERSONALITY_TYPE_CORPORATION,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_INFANT,
    PASSENGER_TYPE_BABY,
    PREFECTURES,
    GENDER_MALE,
    GENDER_FEMALE,
} from '@/lib/constants'

export default defineComponent({
    setup(props, context) {
        const passenger_adult_num_tmp = ref(0)
        const passenger_child_num_tmp = ref(0)
        const passenger_infant_num_tmp = ref(0)
        const passenger_baby_num_tmp = ref(0)

        // 初期化処理
        const init = async (rooms: HeadcountDistribution[]) => {
            console.log('↓↓↓PlanBabyList.init↓↓↓')
            console.log('↑↑↑PlanBabyList.init↑↑↑')
        }
        // 表示乗船者数
        const display_passengers = (): string => {
            let values = []

            if (props.passenger_infant_num! > 0) {
                values.push(`幼児：${props.passenger_infant_num}名`)
            }
            if (props.passenger_baby_num! > 0) {
                values.push(`乳児：${props.passenger_baby_num}名`)
            }

            return values.join(' ')
        }

        // 乗船者数変更
        const toggleInputPeople = () => {
            console.log('↓↓↓PlanRoomList.toggleInputPeople↓↓↓')
            passenger_infant_num_tmp.value = props.passenger_infant_num!
            passenger_baby_num_tmp.value = props.passenger_baby_num!
            $('#search__set-people-baby' + props.list_idx).toggle()
            console.log('↑↑↑PlanRoomList.toggleInputPeople↑↑↑')
        }
        const incrementPassenger = (passenger_type: number) => {
            if (passenger_type == PASSENGER_TYPE_ADULT) {
                passenger_adult_num_tmp.value += 1
            } else if (passenger_type == PASSENGER_TYPE_CHILD) {
                passenger_child_num_tmp.value += 1
            } else if (passenger_type == PASSENGER_TYPE_INFANT) {
                passenger_infant_num_tmp.value += 1
            } else if (passenger_type == PASSENGER_TYPE_BABY) {
                passenger_baby_num_tmp.value += 1
            }
        }
        const decrementPassenger = (passenger_type: number) => {
            if (passenger_type == PASSENGER_TYPE_ADULT) {
                passenger_adult_num_tmp.value -= 1
                if (passenger_adult_num_tmp.value < 0) {
                    passenger_adult_num_tmp.value = 0
                }
            } else if (passenger_type == PASSENGER_TYPE_CHILD) {
                passenger_child_num_tmp.value -= 1
                if (passenger_child_num_tmp.value < 0) {
                    passenger_child_num_tmp.value = 0
                }
            } else if (passenger_type == PASSENGER_TYPE_INFANT) {
                passenger_infant_num_tmp.value -= 1
                if (passenger_infant_num_tmp.value < 0) {
                    passenger_infant_num_tmp.value = 0
                }
            } else if (passenger_type == PASSENGER_TYPE_BABY) {
                passenger_baby_num_tmp.value -= 1
                if (passenger_baby_num_tmp.value < 0) {
                    passenger_baby_num_tmp.value = 0
                }
            }
        }
        const cancelPassenger = () => {
            $('div[id^="search__set-people-baby"]').hide()
        }
        const commitBaby = async () => {
            // 乗船者人数確定時処理
            $('div[id^="search__set-people-baby"]').hide()
            context.emit('commitBaby', passenger_infant_num_tmp.value, passenger_baby_num_tmp.value)
        }

        const aboutInfane = () => {
            context.emit('aboutInfane')
        }

        return {
            passenger_adult_num_tmp,
            passenger_child_num_tmp,
            passenger_infant_num_tmp,
            passenger_baby_num_tmp,

            init,
            display_passengers,

            toggleInputPeople,
            incrementPassenger,
            decrementPassenger,
            cancelPassenger,
            commitBaby,
            aboutInfane,
        }
    },
    emits: ['commitBaby', 'aboutInfane'],
    name: 'PlanBabyList',
    data() {
        return {
            PERSONALITY_TYPE_INDIVIDUAL: PERSONALITY_TYPE_INDIVIDUAL as number,
            PERSONALITY_TYPE_CORPORATION: PERSONALITY_TYPE_CORPORATION as number,

            PASSENGER_TYPE_ADULT: PASSENGER_TYPE_ADULT as number,
            PASSENGER_TYPE_CHILD: PASSENGER_TYPE_CHILD as number,
            PASSENGER_TYPE_INFANT: PASSENGER_TYPE_INFANT as number,
            PASSENGER_TYPE_BABY: PASSENGER_TYPE_BABY as number,

            PREFECTURES: PREFECTURES,
            GENDER_MALE: GENDER_MALE as number,
            GENDER_FEMALE: GENDER_FEMALE as number,
        }
    },

    components: {},
    props: {
        list_idx: Number,
        dep_date: String,
        sea_route_code: String,
        contract_number: String,
        grade: Number,
        seat_type: Number,
        passenger_infant_num: Number,
        passenger_baby_num: Number,
    },
    methods: {},
    mounted: function () {
        console.log('↓↓↓PlanBabyList.mounted↓↓↓')
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search__item-people').length) {
                $('div[id^="search__set-people-baby"]').hide()
            }
        })
        console.log('↑↑↑PlanBabyList.mounted↑↑↑')
    },
})
</script>

<style scoped></style>
