import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  id: "main",
  class: "main': ''"
}
const _hoisted_3 = {
  id: "btn-message-open-modal",
  "data-remodal-target": "message"
}
const _hoisted_4 = {
  id: "btn-message-close-modal",
  "data-remodal-action": "cancel"
}
const _hoisted_5 = {
  id: "message",
  class: "confirm remodal normal",
  "data-remodal-id": "message"
}
const _hoisted_6 = {
  id: "progress",
  class: "remodal normal progress",
  "data-remodal-id": "progress",
  style: {"background-color":"transparent","overflow":"hidden"}
}
const _hoisted_7 = {
  id: "btn-progress-open-modal",
  "data-remodal-target": "progress"
}
const _hoisted_8 = {
  id: "btn-progress-close-modal",
  "data-remodal-action": "cancel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CommonMessage = _resolveComponent("CommonMessage")!
  const _component_ProgressDialog = _resolveComponent("ProgressDialog")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ShowReceipt = _resolveComponent("ShowReceipt")!
  const _component_remodal = _resolveComponent("remodal")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.isShowBar ? 'wrapper' : '',
    class: _normalizeClass(_ctx.isShowBar ? 'wrapper' : '')
  }, [
    (_ctx.isShowBar)
      ? (_openBlock(), _createBlock(_component_HeaderBar, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_router_view, {
        onHideBar: _ctx.hideBar,
        onShowBar: _ctx.showBar,
        onShowPrintReceipt: _ctx.showPrintReceipt,
        onDisplayMessage: _ctx.displayMessage
      }, null, 8, ["onHideBar", "onShowBar", "onShowPrintReceipt", "onDisplayMessage"])
    ]),
    _withDirectives(_createElementVNode("button", _hoisted_3, "○", 512), [
      [_vShow, false]
    ]),
    _withDirectives(_createElementVNode("button", _hoisted_4, "×", 512), [
      [_vShow, false]
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createVNode(_component_CommonMessage, {
        ref: "commonMessage",
        onClose: _ctx.messageClose,
        onOk: _ctx.messageOk,
        onSubmit: _ctx.messageSubmit
      }, null, 8, ["onClose", "onOk", "onSubmit"])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _withDirectives(_createElementVNode("button", _hoisted_7, "○", 512), [
        [_vShow, false]
      ]),
      _withDirectives(_createElementVNode("button", _hoisted_8, "×", 512), [
        [_vShow, false]
      ]),
      _createVNode(_component_ProgressDialog, { ref: "progressDialog" }, null, 512)
    ]),
    (_ctx.isShowBar)
      ? (_openBlock(), _createBlock(_component_FooterBar, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isShowPrintReceipt)
      ? (_openBlock(), _createBlock(_component_ShowReceipt, { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode(_component_remodal)
  ], 10, _hoisted_1))
}