<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">仮会員登録</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- form -->
        <section class="form">
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <!-- メールアドレス登録 -->
                    <li id="form__item" class="form__item">
                        <div class="form__item-container">
                            <div class="input">
                                <dl class="input__list">
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- mail -->
                                        <div id="input__item-mail" class="input__item input__item-mail">
                                            <dt id="input__header-mail" class="input__header input__header-mail">
                                                <label id="input__name-mail" class="input__name input__name-mail" for="">メールアドレス</label>
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-mail" class="input__container input__container-mail">
                                                <input
                                                    id="input__mail"
                                                    class="input__form input__mail"
                                                    type="text"
                                                    name="input__mail"
                                                    v-model="mail"
                                                />
                                            </dd>
                                            <p class="input__error-text">{{ mail_error }}</p>
                                        </div>
                                        <!-- mail-confirm -->
                                        <div id="input__item-mail-confirm" class="input__item input__item-mail-confirm">
                                            <dt id="input__header-mail-confirm" class="input__header input__header-mail-confirm">
                                                <label id="input__name-mail-confirm" class="input__name input__name-mail-confirm" for=""
                                                    >メールアドレス（確認用）</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-mail-confirm" class="input__container input__container-mail-confirm">
                                                <input
                                                    id="input__mail-confirm"
                                                    class="input__form input__mail-confirm"
                                                    type="text"
                                                    name="input__mail-confirm"
                                                    v-model="mail_confirm"
                                                />
                                            </dd>
                                            <p class="input__error-text">{{ confirm_error }}</p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- confirm -->
                                        <div id="input__item-confirm" class="input__item input__item-confirm">
                                            <dd id="input__container-confirm" class="input__container input__container-confirm">
                                                <div id="input__note-confirm" class="input__note input__note-confirm">
                                                    <div class="note">
                                                        <p class="note__text">
                                                            仮会員登録を行うと、入力メールアドレス宛に仮登録メールが送信されます。送信されたメールに記載された確認用URLにアクセスすることで会員情報の入力に進みます。<br />
                                                            迷惑メール対策やドメイン指定受信等を設定している方は、メールが受信されますよう、【@miyazakicarferry.com】【@booking.miyazakicarferry.com】の登録をお願い致します。<br />
                                                            受信登録の設定をされていない場合、メールが届かないことがあります。<br />
                                                            <br />
                                                            <span class="note__annotation">[ドコモメールをご利用のお客様へ]</span><br />
                                                            ドコモメールにおいて、迷惑メール対策の「かんたん設定」で「受信拒否
                                                            強」を選択していた場合、弊社のメールが届かない事象が確認されております。<br />
                                                            「仮登録メールを送信」ボタンを押す前に、ドコモメールの受信リスト設定画面より、【@miyazakicarferry.com】【@booking.miyazakicarferry.com】からのメールを受信できるよう設定していただきますようお願いいたします。<br />
                                                            <br />
                                                            また、ドコモ以外のキャリアメールでも同様の事象が発生する可能性がございますので、同様に各キャリアの受信リスト設定画面より【@miyazakicarferry.com】【@booking.miyazakicarferry.com】からのメールを受信できるよう設定をお願いいたします。
                                                        </p>
                                                    </div>
                                                </div>
                                            </dd>
                                            <dd id="input__item-agree" class="input__container input__container-agree">
                                                <div id="input__checkbox-agree" class="input__checkbox input__checkbox-agree input__checkbox-agree">
                                                    <input
                                                        id="input__agree"
                                                        class="input__checkbox-btn input__agree"
                                                        type="checkbox"
                                                        name="input__agree"
                                                        v-model="agree"
                                                    />
                                                    <label id="input__label-agree" class="input__checkbox-label input__label-agree" for="input__agree"
                                                        >上記内容を確認しました。<span class="input__required">必須</span></label
                                                    >
                                                </div>
                                                <p class="input__error-text">上記内容を確認し、チェックを入れてください。</p>
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-back" type="button" v-on:click="backLogin">戻る</button>
                <button class="btn btn-next" type="button" v-on:click="send_mail">仮登録メールを送信</button>
            </div>
        </section>
        <!-- /form -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { API_USERS, API_ORDER, isNull } from '@/lib/util'
import { isNumeric, isNumericOrAlphabetic, isMail } from '@/lib/validation_util'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        const mail = ref('')
        const mail_confirm = ref('')
        const agree = ref(false)
        const requiredValidation = (value: string) => !!value || '必ず入力してください'
        const limitLengthValidation = (value: string) => value.length <= 10 || '10文字以内で入力してください'
        const mail_error = ref('')
        const confirm_error = ref('')

        // 仮登録メールを送信
        const send_mail = async () => {
            if (!chkValidation()) {
                return
            }
            const postData = {
                email: mail.value,
            }
            store.dispatch('setIsProcessing', true)
            await apiClient
                .post(`${API_USERS}/member/create`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    store.dispatch('setIsProcessing', false)
                    context.emit('displayMessage', {
                        msg_title: '仮会員登録',
                        msg: ['仮登録メールを送信しました。', 'メールに記載したURLにアクセスし、1時間以内に会員登録を完了してください。'],
                        close_btn: true,
                    })
                })
                .catch((e: AxiosError) => {
                    store.dispatch('setIsProcessing', false)
                    // エラー処理
                    if (e.response?.data !== undefined && e.response?.data == 'This email address cannot be used.') {
                        context.emit('displayMessage', {
                            msg_title: '仮会員登録',
                            msg: ['入力されたメールアドレスでは登録ができません。'],
                            close_btn: true,
                        })
                    } else {
                        console.log(e.message)
                    }
                })
        }

        // 入力チェック
        const chkValidation = (): boolean => {
            let err_count = 0
            mail_error.value = ''
            confirm_error.value = ''
            // エラー表示初期化
            $('#input__item-mail').removeClass('input__error')
            $('#input__item-mail-confirm').removeClass('input__error')
            $('#input__item-agree').removeClass('input__error')

            // 新しいメールアドレス
            if (isNull(mail.value)) {
                $('#input__item-mail').addClass('input__error')
                mail_error.value = 'メールアドレスを入力してください'
                err_count++
            } else if (!isMail(mail.value)) {
                $('#input__item-mail').addClass('input__error')
                mail_error.value = 'メールアドレスが正しくありません'
                err_count++
            }
            // 新しいメールアドレス（確認用）
            if (isNull(mail_confirm.value)) {
                $('#input__item-mail-confirm').addClass('input__error')
                confirm_error.value = 'メールアドレス（確認用）を入力してください'
                err_count++
            } else if (!isMail(mail_confirm.value)) {
                $('#input__item-mail-confirm').addClass('input__error')
                confirm_error.value = '確認用メールアドレスが正しくありません'
                err_count++
            } else if (mail.value != mail_confirm.value) {
                $('#input__item-mail-confirm').addClass('input__error')
                confirm_error.value = 'メールアドレスと確認用メールアドレスは同じものを入力してください'
                err_count++
            }
            // 確認
            if (!agree.value) {
                $('#input__item-agree').addClass('input__error')
                err_count++
            }

            return err_count == 0
        }

        // ログイン画面へ戻る
        const backLogin = () => {
            // ログイン画面へ遷移
            router.push('/member/login')
        }

        onMounted(() => {})

        return {
            mail,
            mail_confirm,
            agree,
            mail_error,
            confirm_error,
            requiredValidation,
            limitLengthValidation,

            send_mail,
            chkValidation,
            backLogin,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'ProvisionalMemberRegistration',
    data() {
        return {}
    },
    components: {
        ShipAnimationEnd,
    },
    props: {},
    methods: {},
})
</script>

<style></style>
