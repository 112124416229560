<template>
    <section></section>
    <!-- animation -->
    <ShipAnimationStart></ShipAnimationStart>
    <ShipAnimationEnd></ShipAnimationEnd>
    <!-- /animation -->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import ShipAnimationStart from '@/components/common/ShipAnimationStart.vue'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { API_USERS, API_ORDER, isNull } from '@/lib/util'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()

        // ログインページへ
        const moveLogin = () => {
            router.push('/member/login')
        }
        // マイページに移動
        const moveMyPage = () => {
            router.push('/member/my_page')
        }

        const UpdateMail = async (user_key: string): Promise<string> => {
            const postData = {
                user_key: user_key,
            }
            let data = await apiClient
                .patch(`${API_USERS}/member/other_emails/change`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    context.emit('displayMessage', {
                        msg_title: 'その他メールアドレス変更完了',
                        msg: ['その他メールアドレス変更が完了しました。'],
                        ok_btn: true,
                    })
                    // マイページに移動
                    moveMyPage()
                    return data
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    context.emit('displayMessage', {
                        msg_title: 'その他メールアドレス変更に失敗しました',
                        msg: ['URLの有効期限が過ぎています。最初から変更手続きをやり直してください。'],
                        close_btn: true,
                    })
                    // ログインページへ
                    moveLogin()
                })

            return data
        }

        onMounted(async () => {
            console.log('↓↓↓ChangeOtherMailComplete.onMounted↓↓↓')
            const route = useRoute()
            const user_key = route.params.user_key as string
            if (isNull(user_key)) {
                // リダイレクト以外からの場合は不正
                router.push('/member/login')
            }
            let data = await UpdateMail(user_key)
            console.log('↑↑↑ChangeOtherMailComplete.onMounted↑↑↑')
        })

        return {
            moveLogin,
            moveMyPage,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'ChangeOtherMailComplete',
    data() {
        return {}
    },
    components: {
        ShipAnimationStart,
        ShipAnimationEnd,
    },
    methods: {},
})
</script>
<style></style>
