<template>
    <!-- ticket -->
    <section class="ticket ticket-comp none">
        <div class="ticket__header">
            <h2 class="ticket__name">予約完了</h2>
        </div>
        <div class="ticket__container">
            <!-- ticket__list -->
            <ul id="ticket__list" class="ticket__list">
                <li class="ticket__item">
                    <div class="ticket__item-header">
                        <h3 class="ticket__item-name">チケット情報</h3>
                    </div>
                    <div class="ticket__item-container">
                        <div class="output">
                            <dl class="output__list">
                                <!-- line -->
                                <div class="output__line">
                                    <!-- ship-room -->
                                    <div class="output__item">
                                        <dt class="output__header">
                                            <div class="output__name output__name-room">船室</div>
                                        </dt>
                                        <dd class="output__container output__container-room">
                                            <div class="output__data output__data-room">
                                                {{ getSeatTypeName(booking?.seat_type) }}
                                            </div>
                                        </dd>
                                    </div>
                                    <!-- plan -->
                                    <div class="output__item">
                                        <dt class="output__header">
                                            <div class="output__name output__name-plan">プラン</div>
                                        </dt>
                                        <dd class="output__container output__container-plan">
                                            <div class="output__data output__data-plan">
                                                {{ contract?.contract_name }}
                                            </div>
                                        </dd>
                                    </div>
                                    <!-- route -->
                                    <div class="output__item">
                                        <dt class="output__header">
                                            <div class="output__name output__name-route">ご利用区間</div>
                                        </dt>
                                        <dd class="output__container output__container-route">
                                            <div class="output__data output__data-route">
                                                {{ getSeaRouteCodeName(sea_route_code) }}
                                            </div>
                                        </dd>
                                    </div>
                                    <!-- ship-name -->
                                    <div class="output__item">
                                        <dt class="output__header">
                                            <div class="output__name output__name-ship">船名</div>
                                        </dt>
                                        <dd class="output__container output__container-ship">
                                            <div class="output__data output__data-ship">
                                                {{ boarding?.ship_name }}
                                            </div>
                                        </dd>
                                    </div>
                                </div>
                                <!-- line -->
                                <div class="output__line">
                                    <!-- ship-room -->
                                    <div class="output__item">
                                        <dt class="output__header">
                                            <div class="output__name output__name-date">乗船日</div>
                                        </dt>
                                        <dd class="output__container output__container-date">
                                            <div class="output__data output__data-date">
                                                {{ disp_dep_date }}
                                            </div>
                                        </dd>
                                    </div>
                                    <!-- plan -->
                                    <div class="output__item">
                                        <dt class="output__header">
                                            <div class="output__name output__name-time">出航時刻</div>
                                        </dt>
                                        <dd class="output__container output__container-time">
                                            <div class="output__data output__data-time">
                                                {{ disp_dep_time }}
                                            </div>
                                        </dd>
                                    </div>
                                    <!-- route -->
                                    <div class="output__item">
                                        <dt class="output__header output__header-ticketnumber">
                                            <div class="output__name output__name-ticketnumber">乗船番号</div>
                                        </dt>
                                        <dd class="output__container output__container-ticketnumber">
                                            <div class="output__data output__data-ticketnumber">
                                                {{ boarding?.boarding_no }}
                                            </div>
                                        </dd>
                                    </div>
                                    <!-- ship-name -->
                                    <div class="output__item">
                                        <dt class="output__header">
                                            <div class="output__name output__name-fee">運賃合計</div>
                                        </dt>
                                        <dd class="output__container output__container-fee">
                                            <div class="output__data output__data-fee">
                                                {{ disp_amount }}
                                            </div>
                                        </dd>
                                    </div>
                                </div>
                            </dl>
                        </div>
                    </div>
                </li>
            </ul>
            <p id="complate__text" class="complate__text">
                予約が完了いたしました。<br />
                受付確認のメールを送信しましたのでご確認ください。<br />
                <span class="complate__warn">※メールソフトやウイルス対策ソフト等の設定により、迷惑メールフォルダやごみ箱に自動的に振り分けられている場合があります。</span><br />
                受付確認のメールが届かない場合、マイページの現在の予約でもご確認いただけます。<br />
                {{ isUpdate ? '' : '復路など、新規の予約を行う場合は「さらに予約を続ける」ボタンをクリックしてください。' }}<br />
            </p>
        </div>
        <div class="detail__btn-container btn-container">
            <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
            <button class="btn btn-next" type="button" v-on:click="gotoConfirmationVacancy" v-show="!isUpdate">さらに予約を続ける</button>
        </div>
    </section>
    <!-- /ticket -->
    <!-- animation -->
    <ShipAnimationStart :sea_route_code="sea_route_code"></ShipAnimationStart>
    <ShipAnimationEnd></ShipAnimationEnd>
    <!-- /animation -->
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { Booking, Boarding, Contract, HistoryDetail } from '@/lib/types'
import {
    API_USERS,
    API_ORDER,
    isNull,
    castStringToDate,
    formatDate,
    formatDateTime,
    getSeaRouteCodeName,
    getSeatTypeName,
    getContract,
    SEA_ROUTE_CODE_MIYAZAKI,
    SEA_ROUTE_CODE_KOBE,
} from '@/lib/util'
import {
    getBookingData,
    removeBookingData,
    removeSearchConfirmationVacancyData,
    getSearchConfirmationVacancyData,
    setSearchConfirmationVacancyData,
    getHistoryDetailData,
    setHistoryDetailData,
} from '@/lib/localStorageUtil'
import ShipAnimationStart from '@/components/common/ShipAnimationStart.vue'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const booking = ref<Booking>()
        const boarding = ref<Boarding>()
        const dep_date = ref('')
        const sea_route_code = ref('')
        const contract = ref<Contract>()

        // マイページに移動
        const moveMyPage = () => {
            removeSearchConfirmationVacancyData()
            router.push('/member/my_page')
        }
        // 空席照会に移動
        const gotoConfirmationVacancy = () => {
            const search_data = getSearchConfirmationVacancyData()
            if (!isNull(search_data)) {
                search_data.dep_date = null
                if (!isNull(search_data.sea_route_code)) {
                    if (search_data.sea_route_code == SEA_ROUTE_CODE_MIYAZAKI) {
                        search_data.sea_route_code = SEA_ROUTE_CODE_KOBE
                    } else {
                        search_data.sea_route_code = SEA_ROUTE_CODE_MIYAZAKI
                    }
                }
                setSearchConfirmationVacancyData(search_data)
            }
            router.push('/booking/confirmation_vacancy')
        }

        // 乗船日
        const disp_dep_date = computed(() => {
            if (isNull(boarding.value?.dep_date)) {
                return ''
            } else {
                return formatDate(castStringToDate(boarding.value!.dep_date), false, 2)
            }
        })
        // 乗船時刻
        const disp_dep_time = computed(() => {
            if (isNull(boarding.value?.dep_time)) {
                return ''
            } else {
                return formatDateTime(castStringToDate(boarding.value!.dep_date + ' ' + boarding.value!.dep_time), false, 1)
            }
        })
        // 運賃合計
        const disp_amount = computed(() => {
            if (isNull(booking.value?.amount)) {
                return ''
            } else {
                return booking.value!.amount!.toLocaleString() + '円'
            }
        })

        // 更新か？
        const isUpdate = computed(() => {
            return booking.value?.isUpdate
        })

        const init = async () => {
            const history = getHistoryDetailData() as HistoryDetail
            if (history.redirect_url != null) {
                router.push(history.redirect_url)
                return
            } else {
                const history_detail = {
                    redirect_url: '/booking/confirmation_vacancy',
                } as HistoryDetail
                setHistoryDetailData(history_detail)
            }
            booking.value = getBookingData() as Booking
            boarding.value = booking.value.boarding
            dep_date.value = booking.value.dep_date
            sea_route_code.value = booking.value.sea_route_code
            contract.value = await getContract(booking.value.contract_number, dep_date.value, sea_route_code.value)
            removeBookingData()
        }

        onMounted(async () => {
            console.log('↓↓↓BookingComplete.onMounted↓↓↓')
            scrollTo(0, 0)
            init()
            console.log('↑↑↑BookingComplete.onMounted↑↑↑')
        })

        return {
            booking,
            boarding,
            dep_date,
            sea_route_code,
            contract,

            formatDate,
            castStringToDate,

            moveMyPage,
            gotoConfirmationVacancy,

            disp_dep_date,
            disp_dep_time,
            disp_amount,
            isUpdate,
            getSeatTypeName,
            getSeaRouteCodeName,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'BookingComplete',
    data() {
        return {}
    },
    components: {
        ShipAnimationStart,
        ShipAnimationEnd,
    },
    props: {},
    methods: {},
})
</script>

<style></style>
