<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">特定商取引法に基づく表記</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- /mypage -->
        <section class="table__law">
            <div class="table__law__line">
                <div class="table__law__title">販売事業者名</div>
                <div class="table__law__data">宮崎カーフェリー株式会社</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">販売事業者所在地</div>
                <div class="table__law__data">〒880-0858 宮崎県宮崎市港3-14</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">代表者または運営統括責任者</div>
                <div class="table__law__data">郡司 行敏</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">代表者または運営統括責任者（フリガナ）</div>
                <div class="table__law__data">グンジ ユキトシ</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">連絡先／ホームページ</div>
                <div class="table__law__data">https://www.miyazakicarferry.com/</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">連絡先／電子メール</div>
                <div class="table__law__data">mcf-info@miyazakicarferry.com</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">連絡先／TEL</div>
                <div class="table__law__data">0985-29-5566（予約センター）</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">販売価格帯</div>
                <div class="table__law__data">各商品ページに表示する価格（税込)</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">商品等の引き渡し時期（日数）・発送方法</div>
                <div class="table__law__data">決済確認後に予約登録票を送信し、乗船当日に乗船券と引き換えます</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">代金の支払時期および方法</div>
                <div class="table__law__data">クレジットカードまたはコンビニエンスストアによる事前決済</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">商品代金以外に必要な費用／送料、消費税等</div>
                <div class="table__law__data">なし</div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">返品の取扱条件／返品期限、返品時の送料負担<br />または解約や退会条件</div>
                <div class="table__law__data">
                    ・キャンセルはWEB画面より承ります。<br />
                    ・商品により、キャンセル料や振込手数料がかかる場合がございます。<br />
                    &emsp;詳しくは商品詳細ページでご確認ください。
                </div>
            </div>
            <div class="table__law__line">
                <div class="table__law__title">不良品の取扱条件</div>
                <div class="table__law__data">なし</div>
            </div>
        </section>
    </main>
</template>
<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'

export default defineComponent({
    name: 'SpecifiedCommercialTransactionAct',
    setup() {
        const router = useRouter()
        const store = useStore()

        onMounted(async () => {})

        return {}
    },
    components: {},
    data() {
        return {}
    },
    methods: {},
})
</script>
