<template>
    <div :id="isShowBar ? 'wrapper' : ''" :class="isShowBar ? 'wrapper' : ''">
        <HeaderBar v-if="isShowBar"></HeaderBar>
        <main id="main" class="main': ''">
            <router-view
                v-on:hideBar="hideBar"
                v-on:showBar="showBar"
                v-on:showPrintReceipt="showPrintReceipt"
                v-on:displayMessage="displayMessage"
            />
        </main>
        <!-- message -->
        <button id="btn-message-open-modal" data-remodal-target="message" v-show="false">○</button>
        <button id="btn-message-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section id="message" class="confirm remodal normal" data-remodal-id="message">
            <CommonMessage ref="commonMessage" v-on:close="messageClose" v-on:ok="messageOk" v-on:submit="messageSubmit"></CommonMessage>
        </section>
        <!-- /message -->
        <!-- progress -->
        <section id="progress" class="remodal normal progress" data-remodal-id="progress" style="background-color: transparent; overflow: hidden">
            <button id="btn-progress-open-modal" data-remodal-target="progress" v-show="false">○</button>
            <button id="btn-progress-close-modal" data-remodal-action="cancel" v-show="false">×</button>
            <ProgressDialog ref="progressDialog"></ProgressDialog>
        </section>
        <!-- /progress -->
        <FooterBar v-if="isShowBar"></FooterBar>
        <ShowReceipt v-if="isShowPrintReceipt"></ShowReceipt>
        <remodal></remodal>
    </div>
</template>

<script lang="ts">
import '@/css/plugin/remodal.css'
import '@/css/plugin/remodal-default-theme.css'
import '@/css/plugin/vue-js-modal/styles.css'
import '@/js/plugin/remodal'
import { defineComponent, ref, watch, computed, nextTick, onMounted } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
// import remodal from "remodal";
import HeaderBar from '@/components/common/HeaderBar.vue'
import FooterBar from '@/components/common/FooterBar.vue'
import { delay, setStoreLoginStateFromMemberData, isNull } from '@/lib/util'
import ShowReceipt from '@/components/common/ShowReceipt.vue'
import CommonMessage from '@/components/common/CommonMessage.vue'
import ProgressDialog from '@/components/common/ProgressDialog.vue'
import { getSearchConfirmationVacancyData, setSearchConfirmationVacancyData } from './lib/localStorageUtil'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()
        // バー
        const isShowBar = ref(true)
        const hideBar = () => {
            isShowBar.value = false
        }
        const showBar = () => {
            isShowBar.value = true
        }
        // 領収書
        const showPrintReceipt = () => {
            isShowBar.value = false
            isShowPrintReceipt.value = true
        }
        const isShowPrintReceipt = ref(false)

        // メッセージ
        const commonMessage = ref<InstanceType<typeof CommonMessage>>()
        const messageCloseEvent = ref()
        const messageClose = () => {
            console.log('close message')
            if (!isNull(messageCloseEvent.value)) {
                messageCloseEvent.value()
            }
            // メッセージが閉じられた時、処理中の場合
            if (isProcessing.value) {
                clearInterval(timerProgress.value)
                timerProgress.value = setInterval(changeProgress, 50)
            }
            // イベント初期化
            messageCloseEvent.value = null
            messageOkEvent.value = null
            messageSubmitEvent.value = null
        }
        const messageOkEvent = ref()
        const messageOk = () => {
            console.log('ok message')
            if (!isNull(messageOkEvent.value)) {
                messageOkEvent.value()
            }
            // イベント初期化
            messageCloseEvent.value = null
            messageOkEvent.value = null
            messageSubmitEvent.value = null
        }
        const messageSubmitEvent = ref()
        const messageSubmit = () => {
            console.log('submit message')
            if (!isNull(messageSubmitEvent.value)) {
                messageSubmitEvent.value()
            }
            // イベント初期化
            messageCloseEvent.value = null
            messageOkEvent.value = null
            messageSubmitEvent.value = null
        }
        // メッセージ表示
        const displayMessage = ({
            msg_title,
            msg,
            close_btn,
            ok_btn,
            submit_btn,
            close_event,
            ok_event,
            submit_event,
            cancel_btn_value,
            ok_btn_value,
            submit_btn_value,
        }: {
            msg_title: string
            msg: string[]
            close_btn: boolean
            ok_btn: boolean
            submit_btn: boolean
            close_event: any
            ok_event: any
            submit_event: any
            cancel_btn_value: string
            ok_btn_value: string
            submit_btn_value: string
        }) => {
            if (isNull(msg_title)) msg_title = ''
            if (isNull(msg)) msg = ['']
            if (isNull(close_btn)) close_btn = false
            if (isNull(ok_btn)) ok_btn = false
            if (isNull(submit_btn)) submit_btn = false
            if (isNull(close_event)) close_event = null
            if (isNull(ok_event)) ok_event = null
            if (isNull(submit_event)) submit_event = null
            if (isNull(cancel_btn_value)) cancel_btn_value = 'CLOSE'
            if (isNull(ok_btn_value)) ok_btn_value = 'OK'
            if (isNull(submit_btn_value)) submit_btn_value = 'SUBMIT'

            messageCloseEvent.value = null
            messageOkEvent.value = null
            messageSubmitEvent.value = null
            messageCloseEvent.value = null
            if (!isNull(close_event)) messageCloseEvent.value = close_event
            if (!isNull(ok_event)) messageOkEvent.value = ok_event
            if (!isNull(submit_event)) messageSubmitEvent.value = submit_event
            commonMessage.value!.openMessage(msg_title, msg, [], close_btn, ok_btn, submit_btn, cancel_btn_value, ok_btn_value, submit_btn_value)
            $('#btn-message-open-modal').click()
        }

        // プログレス表示
        const progressDialog = ref<InstanceType<typeof ProgressDialog>>()
        const isProcessing = computed(() => {
            console.log('isProcessing:' + store.state.is_processing)
            return store.state.is_processing
        })
        let timerProgress = ref<any>()
        watch(isProcessing, (next, prev) => {
            clearInterval(timerProgress.value)
            timerProgress.value = setInterval(changeProgress, 50)
        })
        // プログレス表示切替
        const changeProgress = async () => {
            if (isProcessing.value) {
                console.log('changeProgress open')
                // モーダルを開く
                try {
                    $('#btn-progress-open-modal').click()
                    clearInterval(timerProgress.value)
                } catch (e) {
                    console.log(e)
                }
            } else {
                console.log('changeProgress close')
                // モーダルを閉じる
                try {
                    if (isNull(window.location.hash) || window.location.hash == '#progress') {
                        $('#btn-progress-close-modal').click()
                    }
                    // URLフラグメントでプログレス表示が閉じられた事を確認して処理を終える
                    if (window.location.hash != '#progress') {
                        clearInterval(timerProgress.value)
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }

        const lastHash = ref('')

        onMounted(() => {
            console.log('↓↓↓App.onMounted↓↓↓')
            router.beforeEach((to, from, next) => {
                if (from.path == '/member/login' && (to.path == '/member/my_page' || to.path == '/booking/confirmation_vacancy')) {
                    // ログイン時は何もしない
                    next()
                    return
                }
                if (!isNull(to.hash)) {
                    // ハッシュがある場合、ハッシュを履歴から消す
                    lastHash.value = to.hash.replace('#', '')
                    history.replaceState(null, String(null), window.location.pathname)
                } else if (!isNull(lastHash.value)) {
                    // ハッシュがない場合、モーダルを閉じる
                    try {
                        const remodalInst = $('.remodal').remodal()
                        remodalInst.hashChanged()
                    } catch (e) {
                        console.log(e)
                    }
                }
                if (from.path == '/booking/plan_detail' && (to.path == '/booking/confirmation_vacancy' || to.path == '/')) {
                    // プラン詳細から空席照会へ遷移した場合
                    next()
                    nextTick(() => {
                        console.log(window.location.pathname)
                        location.reload()
                    })
                } else {
                    next()
                }
            })
            console.log('↑↑↑App.onMounted↑↑↑')
        })

        return {
            isShowBar,
            hideBar,
            showBar,
            isShowPrintReceipt,
            showPrintReceipt,

            commonMessage,
            messageClose,
            messageOk,
            messageSubmit,
            displayMessage,

            progressDialog,
            isProcessing,
        }
    },
    name: 'App',
    data() {
        return {}
    },
    components: {
        HeaderBar,
        FooterBar,
        ShowReceipt,
        CommonMessage,
        ProgressDialog,
        // remodal,
    },
    methods: {},
    created: function () {
        setStoreLoginStateFromMemberData()
    },
    mounted: function () {
        console.log('↓↓↓App.mounted↓↓↓')
        // mymenu open
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#my-menu').length && !$(e.target).closest('#user-navi__item-name').length) {
                $('#my-menu').hide()
            }
        })
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#icon-menu').length && !$(e.target).closest('#icon-navi__item-menu').length) {
                $('#icon-menu').hide()
            }
        })
        console.log('↑↑↑App.mounted↑↑↑')
    },
})
</script>
<style lang="scss" src="./css/style.scss"></style>
