import { store } from '@/store'
import axios, { AxiosInstance } from 'axios'

const apiClient: AxiosInstance = axios.create({
    // リクエストヘッダ
    headers: {
        'Content-type': 'application/json',
    },
})
// APIのベースURL
apiClient.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
// 認証トークンをヘッダに設定
apiClient.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('access')
    if (accessToken) {
        config!.headers!.Authorization = `Bearer ${accessToken}`
    }
    return config
})

apiClient.interceptors.response.use(
    // 成功時のresponseはそのまま返す
    function (response) {
        return response
    },
    function (error) {
        const originalRequest = error.config

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            localStorage.removeItem('access')
            localStorage.removeItem('member')
            store.dispatch('logout')
        }
        return Promise.reject(error)
    },
)

export default apiClient
