<template>
    <!-- Passenger -->
    <template v-for="(passenger, idx) in passenger_detail_array" :key="idx">
        <li id="form__item" class="form__item" v-if="passenger.deleted != true">
            <div class="form__item-header">
                <h3 class="form__item-name">乳幼児（{{ passenger.line_no! }}）</h3>
                <button class="btn-delete" type="button" v-on:click="deleteBaby(passenger.line_no)">削除</button>
                <img class="form__item-icon" src="@/images/items/accessory-strawhat-01.svg" height="53" width="87" alt="同行者アイコン" />
            </div>
            <div class="form__item-set">
                <select
                    id="form__passenger"
                    class="form__passenger input__select"
                    v-model="passenger.companion_id"
                    v-on:change="changeCompanionId(passenger.line_no)"
                >
                    <option class="" :value="null">同行者情報から選択</option>
                    <template v-for="companion in companionList" :key="companion.id">
                        <option class="selectable" :value="companion.id">
                            {{ joinFirstNameAndLastName(companion.family_name_katakana, companion.given_name_katakana) }}
                        </option>
                    </template>
                </select>
                <div class="">
                    <input
                        :id="'form__regist-passenger-baby' + passenger.line_no!"
                        class="form__regist-passenger"
                        type="checkbox"
                        v-model="passenger.register_companion"
                    />
                    <label class="check" :for="'form__regist-passenger-baby' + passenger.line_no!">同行者情報に登録</label>
                </div>
            </div>
            <div class="form__item-container">
                <div class="input">
                    <dl class="input__list">
                        <!-- line -->
                        <div class="input__line">
                            <!-- fullname -->
                            <div :id="'input__item-fullname-' + passenger.line_no" class="input__item input__item-fullname">
                                <dt id="input__header-fullname" class="input__header input__header-fullname">
                                    <label id="input__name-fullname" class="input__name input__name-fullname" for="input__firstname"
                                        >お名前（全角カタカナ）</label
                                    >
                                    <span class="input__required">必須</span>
                                </dt>
                                <dd id="input__container-fullname" class="input__container input__container-fullname">
                                    <div id="input__firstname-pack" class="input__firstname-pack">
                                        <span>セイ</span>
                                        <input
                                            id="input__firstname"
                                            class="input__form input__firstname"
                                            name="input__firstname"
                                            type="text"
                                            v-model="passenger.family_name_katakana"
                                        />
                                    </div>
                                    <div id="input__lastname-pack" class="input__lastname-pack">
                                        <span>メイ</span>
                                        <input
                                            id="input__lastname"
                                            class="input__form input__lastname"
                                            name="input__lastname"
                                            type="text"
                                            v-model="passenger.given_name_katakana"
                                        />
                                    </div>
                                </dd>
                                <p :id="'name-error-' + passenger.line_no" class="input__error-text"></p>
                                <p id="input__text-fullname" class="input__text input__text-fullname">全角カタカナで入力してください。</p>
                            </div>
                        </div>
                        <!-- line -->
                        <div class="input__line">
                            <!-- sex -->
                            <div :id="'input__item-sex-' + passenger.line_no" class="input__item input__item-sex">
                                <dt id="input__header-sex" class="input__header input__header-sex">
                                    <label id="input__name-sex" class="input__name input__name-sex" for="">性別</label>
                                    <span class="input__required">必須</span>
                                </dt>
                                <dd id="input__container-sex" class="input__container input__container-sex">
                                    <div id="input__radio-sex-man" class="input__radio input__radio-sex input__radio-sex-man">
                                        <input
                                            :id="'input__man-' + passenger.line_no"
                                            class="input__radio-btn input__sex input__man"
                                            type="radio"
                                            v-model="passenger.gender"
                                            :value="GENDER_MALE"
                                        />
                                        <label
                                            id="input__label-man"
                                            class="input__radio-label input__label-man"
                                            :for="'input__man-' + passenger.line_no"
                                            >男</label
                                        >
                                    </div>
                                    <div id="input__radio-sex-woman" class="input__radio input__radio-sex input__radio-sex-woman">
                                        <input
                                            :id="'input__woman-' + passenger.line_no"
                                            class="input__radio-btn input__sex input__woman"
                                            type="radio"
                                            v-model="passenger.gender"
                                            :value="GENDER_FEMALE"
                                        />
                                        <label
                                            id="input__label-woman"
                                            class="input__radio-label input__label-woman"
                                            :for="'input__woman-' + passenger.line_no"
                                            >女</label
                                        >
                                    </div>
                                </dd>
                                <p class="input__error-text">性別を選択してください</p>
                            </div>
                            <!-- category -->
                            <div :id="'input__item-category-' + passenger.line_no" class="input__item input__item-category">
                                <dt id="input__header-category" class="input__header input__header-category">
                                    <label id="input__name-category" class="input__name input__name-category" for="input__category">区分</label>
                                    <span class="input__required">必須</span>
                                </dt>
                                <dd id="input__container-category" class="input__container input__container-category">
                                    <select
                                        id="input__category"
                                        class="input__select input__category"
                                        name="input__category"
                                        v-model="passenger.passenger_type"
                                    >
                                        <option id="input__toddler" class="input__toddler" :value="PASSENGER_TYPE_INFANT">幼児</option>
                                        <option id="input__baby" class="input__baby" :value="PASSENGER_TYPE_BABY">乳児</option>
                                    </select>
                                </dd>
                                <p class="input__error-text">区分を選択してください</p>
                            </div>
                        </div>
                        <!-- line -->
                        <div class="input__line">
                            <!-- age -->
                            <div :id="'input__item-age-' + passenger.line_no" class="input__item input__item-age">
                                <dt id="input__header-age" class="input__header input__header-age">
                                    <label id="input__name-age" class="input__name input__name-age" for="">乗船年齢</label>
                                    <span class="input__required">必須</span>
                                </dt>
                                <dd id="input__container-age" class="input__container input__container-age">
                                    <input
                                        id="input__age"
                                        class="input__form input__age"
                                        type="number"
                                        min="0"
                                        name="input__age"
                                        :value="passenger.age"
                                        @input="(event) => (passenger.age = event.target.value.replace(/[^0-9]/g, ''))"
                                    />
                                    <span>歳</span>
                                </dd>
                                <p :id="'age-error-' + passenger.line_no" class="input__error-text"></p>
                            </div>
                        </div>
                    </dl>
                </div>
            </div>
        </li>
    </template>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import $ from 'jquery'
import { isNull, joinFirstNameAndLastName, getCompanionDetail, checkPassengerTypeAgeRange } from '@/lib/util'
import { PassengerDetail, CompanionDetail, Contract } from '@/lib/types'
import {
    PERSONALITY_TYPE_INDIVIDUAL,
    PERSONALITY_TYPE_CORPORATION,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_INFANT,
    PASSENGER_TYPE_BABY,
    PREFECTURES,
    GENDER_MALE,
    GENDER_FEMALE,
    GENDER_RESTRICT_MALE,
    GENDER_RESTRICT_NONE,
    GENDER_RESTRICT_FEMALE,
} from '@/lib/constants'
import { getCompanionData } from '@/lib/localStorageUtil'
import { isZenKatakana } from '@/lib/validation_util'

export default defineComponent({
    setup(props, context) {
        const passenger_detail_array = ref<PassengerDetail[]>()
        const companionList = ref<CompanionDetail[]>()
        const contract = ref<Contract>()

        const init = (babys: PassengerDetail[], target_contract: Contract) => {
            console.log('↓↓↓EditBabyList.init↓↓↓')
            passenger_detail_array.value = babys
            contract.value = target_contract
            console.log('↑↑↑EditBabyList.init↑↑↑')
        }
        const getData = (): PassengerDetail[] => {
            passenger_detail_array.value!.forEach((element) => {
                element.family_name_katakana = element.family_name_katakana.trim()
                element.given_name_katakana = element.given_name_katakana.trim()
            })
            return passenger_detail_array.value!
        }
        // 乗船者削除
        const deleteBaby = (passenger_line_no: number | null) => {
            console.log('↓↓↓EditBabyList.deletePassenger↓↓↓')
            let tmpArray = []
            for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                if (passenger_detail_array.value![i].line_no != passenger_line_no) {
                    tmpArray.push(passenger_detail_array.value![i])
                } else {
                    if (!isNull(passenger_detail_array.value![i].id)) {
                        passenger_detail_array.value![i].deleted = true
                        tmpArray.push(passenger_detail_array.value![i])
                    }
                }
            }
            passenger_detail_array.value = tmpArray
            context.emit('commitPassengerList')
            console.log('↑↑↑EditBabyList.deletePassenger↑↑↑')
        }
        // 乗船者追加
        const addBaby = () => {
            console.log('↓↓↓EditBabyList.addPassenger↓↓↓')
            let new_line_no = 0
            for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                if (new_line_no < passenger_detail_array.value![i]!.line_no!) {
                    new_line_no = passenger_detail_array.value![i].line_no!
                }
            }
            new_line_no += 1
            passenger_detail_array.value!.push({
                id: null, // 乗船者ID
                line_no: new_line_no, // 行番号
                family_name_katakana: '', // 姓（カタカナ）
                given_name_katakana: '', // 名（カタカナ）
                birthday_year: null, // 生年月日（年）
                birthday_month: null, // 生年月日（月）
                birthday_day: null, // 生年月日（日）
                gender: undefined, // 性別
                age: null, // 年齢
                passenger_type: null, // 乗船者区分
                represent_flg: false, // 代表者フラグ
                mcf_member_id: '', // MCF会員ID
                with_pets_flg: false, // withペット料金フラグ
                driver: false, // ドライバー
                reserved_room: false, // 貸切（船室）
                register_companion: true, // 同乗者情報に登録
                companion_id: null, // 同乗者ID
                deleted: false,
            } as PassengerDetail)
            console.log('↑↑↑EditBabyList.addPassenger↑↑↑')
        }

        // 同乗者情報から選択時処理
        const changeCompanionId = (passenger_line_no: number | null) => {
            console.log('↓↓↓EditBabyList.changeCompanionId↓↓↓')
            for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                if (passenger_detail_array.value![i].line_no == passenger_line_no) {
                    let companion = getCompanionDetail(passenger_detail_array.value![i].companion_id!)
                    if (!isNull(companion)) {
                        passenger_detail_array.value![i].passenger_type = companion!.passenger_type
                        passenger_detail_array.value![i].family_name_katakana = companion!.family_name_katakana
                        passenger_detail_array.value![i].given_name_katakana = companion!.given_name_katakana
                        passenger_detail_array.value![i].gender = companion!.sex!
                        passenger_detail_array.value![i].register_companion = false
                    }
                }
            }
            console.log('↑↑↑EditBabyList.changeCompanionId↑↑↑')
        }
        // 乗船者人数取得
        const getBabyCount = (passenger_type: number): number => {
            let cnt = 0

            if (!passenger_detail_array.value) {
                return cnt
            }
            for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                if (passenger_detail_array.value![i].deleted == true) {
                    continue
                }
                if (passenger_detail_array.value![i].passenger_type != passenger_type) {
                    cnt += 1
                }
            }
            return cnt
        }
        // 入力チェック
        const chkValidation = (): any[] => {
            let err_count = 0
            let err_messages = [] as string[]

            for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                if (passenger_detail_array.value![i].deleted) {
                    continue
                }
                // 初期化
                $('#input__item-fullname-' + passenger_detail_array.value![i].line_no).removeClass('input__error')
                $('#input__item-sex-' + passenger_detail_array.value![i].line_no).removeClass('input__error')
                $('#input__item-category-' + passenger_detail_array.value![i].line_no).removeClass('input__error')
                $('#input__item-age-' + passenger_detail_array.value![i].line_no).removeClass('input__error')
                $('#name-error-' + passenger_detail_array.value![i].line_no).text('')
                $('#age-error-' + passenger_detail_array.value![i].line_no).text('')
                // お名前
                if (isNull(passenger_detail_array.value![i].family_name_katakana) || isNull(passenger_detail_array.value![i].given_name_katakana)) {
                    $('#input__item-fullname-' + passenger_detail_array.value![i].line_no).addClass('input__error')
                    $('#name-error-' + passenger_detail_array.value![i].line_no).text('お名前を入力してください')
                    err_count++
                } else if (
                    !isZenKatakana(passenger_detail_array.value![i].family_name_katakana) ||
                    !isZenKatakana(passenger_detail_array.value![i].given_name_katakana)
                ) {
                    $('#input__item-fullname-' + passenger_detail_array.value![i].line_no).addClass('input__error')
                    $('#name-error-' + passenger_detail_array.value![i].line_no).text('お名前は全角カタカナで入力してください')
                    err_count++
                }
                // 性別
                if (isNull(passenger_detail_array.value![i].gender)) {
                    $('#input__item-sex-' + passenger_detail_array.value![i].line_no).addClass('input__error')
                    err_count++
                }
                // 区分
                if (isNull(passenger_detail_array.value![i].passenger_type)) {
                    $('#input__item-category-' + passenger_detail_array.value![i].line_no).addClass('input__error')
                    err_count++
                }
                // 乗船年齢
                if (isNull(passenger_detail_array.value![i].age) || passenger_detail_array.value![i].age! < 0) {
                    $('#input__item-age-' + passenger_detail_array.value![i].line_no).addClass('input__error')
                    $('#age-error-' + passenger_detail_array.value![i].line_no).text('乗船年齢を入力してください')
                    err_count++
                } else {
                    const age_range_error = checkPassengerTypeAgeRange(
                        passenger_detail_array.value![i].passenger_type,
                        passenger_detail_array.value![i].age,
                    )
                    if (!isNull(age_range_error)) {
                        $('#input__item-age-' + passenger_detail_array.value![i].line_no).addClass('input__error')
                        $('#age-error-' + passenger_detail_array.value![i].line_no).text(age_range_error)
                        err_messages.push(age_range_error)
                        err_count++
                    }
                }
            }

            return [err_count, err_messages]
        }
        // 入力チェック（成約制限）
        const chkValidationContract = (): string[] => {
            let err_messages = [] as string[]
            let now = new Date()

            // 予約年齢制限使用区分
            // 最小予約年齢
            // 最大予約年齢
            if (!isNull(contract.value!.use_booking_age_limit) && contract.value!.use_booking_age_limit) {
                for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                    if (passenger_detail_array.value![i].deleted != true && !isNull(passenger_detail_array.value![i].age)) {
                        if (passenger_detail_array.value![i].age! < contract.value!.booking_age_limit_min) {
                            err_messages.push(`こちらのプランは${contract.value!.booking_age_limit_min}才以上の方のみご予約可能です`)
                            break
                        }
                    }
                }
                for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                    if (passenger_detail_array.value![i].deleted != true && !isNull(passenger_detail_array.value![i].age)) {
                        if (passenger_detail_array.value![i].age! > contract.value!.booking_age_limit_max) {
                            err_messages.push(`こちらのプランは${contract.value!.booking_age_limit_max}才以下の方のみご予約可能です`)
                            break
                        }
                    }
                }
            }
            // 性別制限
            if (!isNull(contract.value!.gender_restrict) && contract.value!.gender_restrict != GENDER_RESTRICT_NONE) {
                for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                    if (passenger_detail_array.value![i].deleted != true && !isNull(passenger_detail_array.value![i].gender)) {
                        if (contract.value!.gender_restrict == GENDER_RESTRICT_MALE && passenger_detail_array.value![i].gender != GENDER_MALE) {
                            // 男性のみ
                            err_messages.push(`こちらのプランは男性のみご予約可能となっております`)
                            break
                        } else if (
                            contract.value!.gender_restrict == GENDER_RESTRICT_FEMALE &&
                            passenger_detail_array.value![i].gender != GENDER_FEMALE
                        ) {
                            // 女性のみ
                            err_messages.push(`こちらのプランは女性のみご予約可能となっております`)
                            break
                        }
                    }
                }
            }

            if (props.isUpdate) {
                // 更新時のみチェック
            }

            return err_messages
        }

        // 乗船者人数取得
        const getPassengerCount = (passenger_type: number): number => {
            let cnt = 0
            for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                if (passenger_detail_array.value![i].deleted == true) {
                    continue
                }
                if (passenger_detail_array.value![i].passenger_type == passenger_type) {
                    cnt += 1
                }
            }
            return cnt
        }

        onMounted(() => {
            // companionList.value = getCompanionData();
            companionList.value = []
            let companionArray = getCompanionData()
            if (!isNull(companionArray)) {
                for (let i = 0; i < companionArray.length; i++) {
                    if ([PASSENGER_TYPE_INFANT, PASSENGER_TYPE_BABY].indexOf(companionArray[i].passenger_type!) >= 0) {
                        companionList.value.push(companionArray[i])
                    }
                }
            }
        })

        return {
            passenger_detail_array,
            companionList,

            init,
            getData,
            deleteBaby,
            addBaby,
            changeCompanionId,
            getBabyCount,
            chkValidation,
            chkValidationContract,
            getPassengerCount,

            joinFirstNameAndLastName,
        }
    },
    emits: ['commitPassengerList'],
    name: 'EditBabyList',
    data() {
        return {
            PERSONALITY_TYPE_INDIVIDUAL: PERSONALITY_TYPE_INDIVIDUAL as number,
            PERSONALITY_TYPE_CORPORATION: PERSONALITY_TYPE_CORPORATION as number,

            PASSENGER_TYPE_ADULT: PASSENGER_TYPE_ADULT as number,
            PASSENGER_TYPE_CHILD: PASSENGER_TYPE_CHILD as number,
            PASSENGER_TYPE_INFANT: PASSENGER_TYPE_INFANT as number,
            PASSENGER_TYPE_BABY: PASSENGER_TYPE_BABY as number,

            PREFECTURES: PREFECTURES,
            GENDER_MALE: GENDER_MALE as number,
            GENDER_FEMALE: GENDER_FEMALE as number,
        }
    },

    components: {},
    props: {
        dep_date: String,
        sea_route_code: String,
        contract_number: String,
        grade: Number,
        seat_type: Number,
        isUpdate: Boolean,
    },
    methods: {},
})
</script>

<style scoped></style>
