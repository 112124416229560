import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "confirm__header" }
const _hoisted_2 = { class: "confirm__name" }
const _hoisted_3 = { class: "confirm__container" }
const _hoisted_4 = { class: "confirm__list" }
const _hoisted_5 = { class: "confirm__item" }
const _hoisted_6 = { class: "confirm__item-container" }
const _hoisted_7 = { class: "confirm__disp" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "confirm__btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("button", {
        id: "btn-confirm-close",
        class: "confirm__close",
        "data-remodal-action": "cancel",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.btnCancelClick && _ctx.btnCancelClick(...args)))
      }, "×")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (msg, idx) => {
                return (_openBlock(), _createElementBlock("dl", {
                  class: "confirm__disp-list",
                  key: idx
                }, [
                  _createElementVNode("p", { innerHTML: msg }, null, 8, _hoisted_8)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.useCloseBtn)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-back",
            type: "button",
            "data-remodal-action": "cancel",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.btnCloseClick && _ctx.btnCloseClick(...args)))
          }, _toDisplayString(_ctx.cancelBtnValue), 1))
        : _createCommentVNode("", true),
      (_ctx.useOkBtn)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn btn-next",
            type: "button",
            "data-remodal-action": "cancel",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.btnOkClick && _ctx.btnOkClick(...args)))
          }, _toDisplayString(_ctx.okBtnValue), 1))
        : _createCommentVNode("", true),
      (_ctx.useSubmitBtn)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "btn btn-next",
            type: "button",
            "data-remodal-action": "cancel",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.btnSubmitClick && _ctx.btnSubmitClick(...args)))
          }, _toDisplayString(_ctx.submitBtnValue), 1))
        : _createCommentVNode("", true)
    ])
  ], 64))
}