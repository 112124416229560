<template>
    <div class="confirm__header">
        <h3 class="confirm__name">{{ title }}</h3>
        <button id="btn-confirm-close" class="confirm__close" data-remodal-action="cancel" v-on:click="btnCancelClick">×</button>
    </div>
    <div class="confirm__container">
        <ul class="confirm__list">
            <li class="confirm__item">
                <div class="confirm__item-container">
                    <div class="confirm__disp">
                        <dl class="confirm__disp-list" v-for="(msg, idx) in messages" :key="idx">
                            <p v-html="msg"></p>
                        </dl>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="confirm__btn">
        <button class="btn btn-back" type="button" data-remodal-action="cancel" v-on:click="btnCloseClick" v-if="useCloseBtn">
            {{ cancelBtnValue }}
        </button>
        <button class="btn btn-next" type="button" data-remodal-action="cancel" v-on:click="btnOkClick" v-if="useOkBtn">
            {{ okBtnValue }}
        </button>
        <button class="btn btn-next" type="button" data-remodal-action="cancel" v-on:click="btnSubmitClick" v-if="useSubmitBtn">
            {{ submitBtnValue }}
        </button>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import $ from 'jquery'

export default defineComponent({
    setup(props, context) {
        const title = ref('')
        const messages = ref<string[]>([])
        const keepArg = ref<any[]>([])
        const returnValues = ref<any[]>([])
        const useCloseBtn = ref(false)
        const useOkBtn = ref(false)
        const useSubmitBtn = ref(false)
        const cancelBtnValue = ref('')
        const okBtnValue = ref('')
        const submitBtnValue = ref('')
        const messageEvent = ref()
        const messageParentEvent = ref()

        // メッセージ表示
        const openMessage = (
            msg_title = '' as string,
            msg = [''] as string[],
            arg = [] as any[],
            close_btn = false as boolean,
            ok_btn = false as boolean,
            submit_btn = false as boolean,
            cancel_btn_value: string,
            ok_btn_value: string,
            submit_btn_value: string,
        ) => {
            console.log('↓↓↓CommonMessage.openMessage↓↓↓')
            title.value = msg_title
            messages.value = msg
            keepArg.value = arg
            useCloseBtn.value = close_btn
            useOkBtn.value = ok_btn
            useSubmitBtn.value = submit_btn
            cancelBtnValue.value = cancel_btn_value
            okBtnValue.value = ok_btn_value
            submitBtnValue.value = submit_btn_value
            // イベント再設定
            resetEvent()
            console.log('↑↑↑CommonMessage.openMessage↑↑↑')
        }
        // ×ボタン押下時処理
        const btnCancelClick = () => {
            // 閉じるボタンが有効な場合、閉じる処理を行う
            if (useCloseBtn.value) {
                context.emit('close', keepArg.value, returnValues.value)
            }
            // イベント削除
            deleteEvent()
        }
        // 閉じるボタン押下時処理
        const btnCloseClick = () => {
            context.emit('close', keepArg.value, returnValues.value)
            // イベント削除
            deleteEvent()
        }
        // OKボタン押下時処理
        const btnOkClick = () => {
            // イベント削除
            deleteEvent()
            context.emit('ok', keepArg.value, returnValues.value)
        }
        // 送信ボタン押下時処理
        const btnSubmitClick = () => {
            // イベント削除
            deleteEvent()
            context.emit('submit', keepArg.value, returnValues.value)
        }

        // イベント付与
        const setEvent = () => {
            messageEvent.value = (e: any) => {
                e.stopPropagation()
            }
            messageParentEvent.value = () => {
                // 閉じるボタンが有効な場合、閉じる処理を行う
                if (useCloseBtn.value) {
                    context.emit('close', keepArg.value, returnValues.value)
                }
            }
        }
        // イベント再設定
        const resetEvent = () => {
            let secMessage = document.getElementById('message')
            secMessage!.addEventListener('click', messageEvent.value)
            let secMessageParent = document.getElementById('message')!.parentElement
            secMessageParent!.addEventListener('click', messageParentEvent.value)
        }
        // イベント削除
        const deleteEvent = () => {
            let secMessage = document.getElementById('message')
            secMessage!.removeEventListener('click', messageEvent.value)
            let secMessageParent = document.getElementById('message')!.parentElement
            secMessageParent!.removeEventListener('click', messageParentEvent.value)
        }

        // メッセージ設定初期化
        const clearMessage = () => {
            title.value = ''
            messages.value = []
            keepArg.value = []
            returnValues.value = []
            useCloseBtn.value = false
            useOkBtn.value = false
            useSubmitBtn.value = false
            cancelBtnValue.value = ''
            okBtnValue.value = ''
            submitBtnValue.value = ''
        }

        onMounted(() => {
            // イベント付与
            setEvent()
        })

        return {
            title,
            messages,
            useCloseBtn,
            useOkBtn,
            useSubmitBtn,
            cancelBtnValue,
            okBtnValue,
            submitBtnValue,

            openMessage,
            btnCancelClick,
            btnCloseClick,
            btnOkClick,
            btnSubmitClick,
        }
    },
    name: 'CommonMessage',
    emits: ['close', 'ok', 'submit'],
    data() {
        return {}
    },
    components: {},
    methods: {},
})
</script>

<style scoped></style>
