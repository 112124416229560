import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

// ストアの型を定義する
export interface State {
    member_id: string // ログインID
    family_name: string // 姓
    given_name: string // 名
    email: string // メールアドレス
    is_logged_in: boolean // ログイン済か？
    is_processing: boolean // プログレス表示

    title: string // タイトル
}
// InjectionKeyを定義する
export const key: InjectionKey<Store<State>> = Symbol()
// Storeを作成する
export const store = createStore<State>({
    state: {
        member_id: '', // ログインID
        family_name: '', // 姓
        given_name: '', // 名
        email: '', // メールアドレス
        is_logged_in: false, // ログイン済か？
        is_processing: false, // プログレス表示

        title: '', // タイトル
    },
    getters: {
        member_id: (state) => {
            return state.member_id
        },
        family_name: (state) => {
            return state.family_name
        },
        given_name: (state) => {
            return state.given_name
        },
        email: (state) => {
            return state.email
        },
        is_logged_in: (state) => {
            return state.is_logged_in
        },
        is_processing: (state) => {
            return state.is_processing
        },
        title: (state) => {
            return state.title
        },
    },
    actions: {
        setMemberId({ commit, state }, member_id: string) {
            commit('setMemberId', { member_id: member_id })
        },
        setFamilyName({ commit, state }, family_name: string) {
            commit('setFamilyName', { family_name: family_name })
        },
        setGivenName({ commit, state }, given_name: string) {
            commit('setGivenName', { given_name: given_name })
        },
        setEmail({ commit, state }, email: string) {
            commit('setEmail', { email: email })
        },
        setIsLoggedIn({ commit, state }, is_logged_in: boolean) {
            commit('setIsLoggedIn', { is_logged_in: is_logged_in })
        },
        setIsProcessing({ commit, state }, is_processing: boolean) {
            commit('setIsProcessing', { is_processing: is_processing })
        },
        setTitle({ commit, state }, title: string) {
            commit('setTitle', { title: title })
        },
        logout({ commit, state }) {
            commit('setMemberId', { member_id: '' }),
                commit('setFamilyName', { family_name: '' }),
                commit('setGivenName', { given_name: '' }),
                commit('setEmail', { email: '' }),
                commit('setIsLoggedIn', { is_logged_in: false })
        },
    },
    mutations: {
        setMemberId(state, { member_id }) {
            state.member_id = member_id
        },
        setFamilyName(state, { family_name }) {
            state.family_name = family_name
        },
        setGivenName(state, { given_name }) {
            state.given_name = given_name
        },
        setEmail(state, { email }) {
            state.email = email
        },
        setIsLoggedIn(state, { is_logged_in }) {
            state.is_logged_in = is_logged_in
        },
        setIsProcessing(state, { is_processing }) {
            state.is_processing = is_processing
        },
        setTitle(state, { title }) {
            state.title = title
        },
    },
})
// 独自のuserStoreメソッドを定義する
export function useStore() {
    // InjectionKeyをuserStoreメソッドに渡す
    return baseUseStore(key)
}
