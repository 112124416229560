<template>
    <!-- footer -->
    <footer id="footer" class="footer">
        <div class="footer__container">
            <img class="footer__logo" src="@/images/items/footer-logo.png" alt="宮崎カーフェリー" width="98" height="135" loading="lazy" />
            <div class="footer__site-name">宮崎カーフェリー WEB予約サイト</div>
            <div class="footer__navi">
                <nav id="footer-navi" class="footer-navi">
                    <ul class="footer-navi__list">
                        <li class="footer-navi__item" v-for="item in footer_navi_items" :key="item.index">
                            <a v-bind:href="item.url" v-bind:target="item.target">
                                {{ item.name }}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <small class="footer__copy">Copyright &copy; Miyazaki car ferry. All rights reserved.</small>
        </div>
    </footer>
    <!-- /footer -->
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()

        const footer_navi_items = ref([
            {
                index: 0,
                url: 'https://www.miyazakicarferry.com/',
                target: '_blank',
                name: '公式サイトへ',
            },
            {
                index: 1,
                url: '/member/privacy_policy',
                target: '_blank',
                name: 'プライバシーポリシー',
            },
            {
                index: 2,
                url: 'https://www.miyazakicarferry.com/stipulation/',
                target: '_blank',
                name: '運送約款',
            },
            {
                index: 3,
                url: '/member/law',
                target: '_self',
                name: '特定商取引法に基づく表記',
            },
            {
                index: 4,
                url: 'https://www.miyazakicarferry.com/company/',
                target: '_blank',
                name: '会社概要',
            },
        ])

        return {
            footer_navi_items,
        }
    },
    name: 'FooterBar',
    data() {
        return {}
    },
    components: {},
    methods: {},
})
</script>
