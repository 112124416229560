<template>
    <tr v-for="(room, idx) in headcount_distributions" :key="idx">
        <th>{{ idx + 1 }}部屋目</th>
        <td id="search__item-people">
            <span>{{ disp_passengers(room) }}</span>
            <button id="btn-people" class="btn-border" type="button" v-on:click="toggleInputPeople(room.line_no)">変更</button>

            <div :id="'search__set-people-' + list_idx + '-' + room.line_no" class="search__set search__set-people none" style="max-width: 40rem">
                <ul class="input-set__list">
                    <li id="input-set__item-adult" class="input-set__item input-set__item-adult">
                        <label id="input-set__label-adult" class="input-set__label input-set__label-adult" for="input-set__number-adult"
                            >大人（中学生以上）</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_ADULT)">－</button>
                            <input
                                id="input-set__number-adult"
                                class="input-set__number input-set__number-adult"
                                type="text"
                                name="adult"
                                v-model="passenger_adult_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_ADULT)">＋</button>
                        </div>
                    </li>
                    <li id="input-set__item-child" class="input-set__item input-set__item-child">
                        <label id="input-set__label-child" class="input-set__label input-set__label-child" for="input-set__number-child"
                            >小人（小学生）</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_CHILD)">－</button>
                            <input
                                id="input-set__number-child"
                                class="input-set__number input-set__number-child"
                                type="text"
                                name="child"
                                v-model="passenger_child_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_CHILD)">＋</button>
                        </div>
                    </li>
                    <!-- <li id="input-set__item-toddler" class="input-set__item input-set__item-toddler">
                  <label id="input-set__label-toddler" class="input-set__label input-set__label-toddler" for="input-set__number-toddler">幼児</label>
                  <div class="input-set__change">
                    <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_INFANT)">－</button>
                    <input id="input-set__number-toddler" class="input-set__number input-set__number-toddler" type="text" name="toddler" v-model="passenger_infant_num_tmp" readonly>
                    <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_INFANT)">＋</button>
                  </div>
                </li> -->
                    <!-- <li id="input-set__item-baby" class="input-set__item input-set__item-baby">
                  <label id="input-set__label-baby" class="input-set__label input-set__label-baby" for="input-set__number-baby">乳児</label>
                  <div class="input-set__change">
                    <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_BABY)">－</button>
                    <input id="input-set__number-baby" class="input-set__number input-set__number-baby" type="text" name="baby" v-model="passenger_baby_num_tmp" readonly>
                    <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_BABY)">＋</button>
                  </div>
                </li> -->
                </ul>
                <div class="input-set__buttons">
                    <button
                        id="input-set__cansel-people"
                        class="btn input-set__cansel input-set__cansel-people"
                        type="button"
                        v-on:click="cancelPassenger()"
                    >
                        キャンセル
                    </button>
                    <button
                        id="input-set__setting-people"
                        class="btn input-set__setting input-set__setting-people"
                        type="button"
                        v-on:click="commitPassenger(room.line_no)"
                    >
                        設定
                    </button>
                </div>
            </div>
        </td>
        <td class="detail__td-count">
            {{ room.room_amount.toLocaleString() + '円' }}
        </td>
    </tr>
</template>

<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, ref } from 'vue'
import $ from 'jquery'
import { isNull, getPassengerFare, getReservedFare, getPassengerPrice, getSeatTypeName, ROOM_INFORMATIONS } from '@/lib/util'
import { HeadcountDistribution, RoomInformation } from '@/lib/types'
import {
    PERSONALITY_TYPE_INDIVIDUAL,
    PERSONALITY_TYPE_CORPORATION,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_INFANT,
    PASSENGER_TYPE_BABY,
    PREFECTURES,
    GENDER_MALE,
    GENDER_FEMALE,
} from '@/lib/constants'

export default defineComponent({
    setup(props, context) {
        const headcount_distributions = ref<HeadcountDistribution[]>()
        const passenger_adult_num_tmp = ref(0)
        const passenger_child_num_tmp = ref(0)
        const passenger_infant_num_tmp = ref(0)
        const passenger_baby_num_tmp = ref(0)

        // 初期化処理
        const init = async (rooms: HeadcountDistribution[]) => {
            console.log('↓↓↓PlanRoomList.init↓↓↓')
            headcount_distributions.value = rooms
            for (let i = 0; i < headcount_distributions.value.length; i++) {
                headcount_distributions.value[i].room_amount = await calc_room_amount(headcount_distributions.value[i])
            }
            console.log('↑↑↑PlanRoomList.init↑↑↑')
        }
        // 表示乗船者数
        const disp_passengers = (room: HeadcountDistribution): string => {
            let values = []

            if (room.adult > 0) {
                values.push(`大人：${room.adult}名`)
            }
            if (room.child > 0) {
                values.push(`小人：${room.child}名`)
            }
            // if (room.infant > 0) {
            //     values.push(`幼児：${room.infant}名`)
            // }
            // if (room.baby > 0) {
            //     values.push(`乳児：${room.baby}名`)
            // }
            if (room.reserved_room) {
                values.push(`貸切り`)
            }

            return values.join(' ')
        }
        // 表示金額
        const calc_room_amount = async (room: HeadcountDistribution): Promise<number> => {
            console.log('↓↓↓PlanRoomList.calc_room_amount↓↓↓')
            let amount = 0

            if (!isNull(room.passenger_detail_array)) {
                for (let i = 0; i < room.passenger_detail_array.length; i++) {
                    if (!isNull(room.passenger_detail_array[i].passenger_type)) {
                        const contract_fare = await getPassengerFare(
                            props.dep_date!,
                            props.sea_route_code!,
                            props.contract_number!,
                            props.grade!,
                            room.passenger_detail_array[i].passenger_type!,
                            room.passenger_detail_array[i].driver!,
                            room.passenger_detail_array[i].with_pets_flg!,
                        )
                        room.passenger_detail_array[i].unit_price = contract_fare.person_fare
                        room.passenger_detail_array[i].baf_amount = contract_fare.baf
                        // withペット料金
                        if (room.passenger_detail_array[i].with_pets_flg) {
                            room.passenger_detail_array[i].with_pets_amount = contract_fare.with_pets_amount
                        } else {
                            room.passenger_detail_array[i].with_pets_amount = 0
                        }
                        // 貸切料
                        if (room.passenger_detail_array[i].reserved_room) {
                            const reserved_fare = await getReservedFare(
                                props.dep_date!,
                                props.sea_route_code!,
                                props.contract_number!,
                                props.grade!,
                                props.seat_type!,
                                room.adult + room.child,
                            )
                            room.passenger_detail_array[i].reserved_amount = reserved_fare.person_fare
                        } else {
                            room.passenger_detail_array[i].reserved_amount = 0
                        }
                        amount += getPassengerPrice(room.passenger_detail_array[i])
                    }
                }
            }
            console.log('↑↑↑PlanRoomList.calc_room_amount↑↑↑')
            return amount
        }
        const calc_room_amount_by_parent = async (): Promise<number> => {
            let total_amount = 0
            let car_count = 0
            car_count += isNull(props.car_3m_num) ? 0 : props.car_3m_num!
            car_count += isNull(props.car_4m_num) ? 0 : props.car_4m_num!
            car_count += isNull(props.car_5m_num) ? 0 : props.car_5m_num!
            car_count += isNull(props.car_6m_num) ? 0 : props.car_6m_num!
            let driver_count = 0
            for (let i = 0; i < headcount_distributions.value!.length; i++) {
                for (let j = 0; j < headcount_distributions.value![i].passenger_detail_array.length; j++) {
                    if (
                        !isNull(headcount_distributions.value![i].passenger_detail_array[j].passenger_type) &&
                        headcount_distributions.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_ADULT &&
                        car_count > driver_count
                    ) {
                        headcount_distributions.value![i].passenger_detail_array[j].driver = true
                        driver_count++
                    } else {
                        headcount_distributions.value![i].passenger_detail_array[j].driver = false
                    }
                }
                headcount_distributions.value![i].room_amount = await calc_room_amount(headcount_distributions.value![i])
                total_amount += headcount_distributions.value![i].room_amount
            }
            return total_amount
        }

        // 貸切料判定
        const chkReserved = async (): Promise<boolean> => {
            for (let i = 0; i < headcount_distributions.value!.length; i++) {
                // const room = headcount_distributions.value![i];
                // if (!isNull(room.passenger_detail_array)) {
                //     for (let i = 0; i < room.passenger_detail_array.length; i++) {
                //         if (!isNull(room.passenger_detail_array[i].passenger_type)) {
                //             // 貸切料
                //             if (room.passenger_detail_array[i].reserved_amount == undefined) {
                //                 continue;
                //             } else if (room.passenger_detail_array[i].reserved_amount == null) {
                //                 continue;
                //             } else if (room.passenger_detail_array[i].reserved_amount == 0) {
                //                 continue;
                //             } else {
                //                 console.log(room.passenger_detail_array[i].reserved_amount);
                //                 return true;
                //             }
                //         }
                //     }
                // }
                if (headcount_distributions.value![i].reserved_room) {
                    return true
                }
            }
            return false
        }

        // 乗船者数変更
        const toggleInputPeople = (line_no: number) => {
            console.log('↓↓↓PlanRoomList.toggleInputPeople↓↓↓')
            for (let i = 0; i < headcount_distributions.value!.length; i++) {
                if (headcount_distributions.value![i].line_no == line_no) {
                    passenger_adult_num_tmp.value = headcount_distributions.value![i].adult
                    passenger_child_num_tmp.value = headcount_distributions.value![i].child
                    passenger_infant_num_tmp.value = headcount_distributions.value![i].infant
                    passenger_baby_num_tmp.value = headcount_distributions.value![i].baby
                    break
                }
            }
            $('#search__set-people-' + props.list_idx + '-' + line_no).toggle()
            console.log('↑↑↑PlanRoomList.toggleInputPeople↑↑↑')
        }
        const incrementPassenger = (passenger_type: number) => {
            if (passenger_type == PASSENGER_TYPE_ADULT) {
                passenger_adult_num_tmp.value += 1
            } else if (passenger_type == PASSENGER_TYPE_CHILD) {
                passenger_child_num_tmp.value += 1
            } else if (passenger_type == PASSENGER_TYPE_INFANT) {
                passenger_infant_num_tmp.value += 1
            } else if (passenger_type == PASSENGER_TYPE_BABY) {
                passenger_baby_num_tmp.value += 1
            }
        }
        const decrementPassenger = (passenger_type: number) => {
            if (passenger_type == PASSENGER_TYPE_ADULT) {
                passenger_adult_num_tmp.value -= 1
                if (passenger_adult_num_tmp.value < 0) {
                    passenger_adult_num_tmp.value = 0
                }
            } else if (passenger_type == PASSENGER_TYPE_CHILD) {
                passenger_child_num_tmp.value -= 1
                if (passenger_child_num_tmp.value < 0) {
                    passenger_child_num_tmp.value = 0
                }
            } else if (passenger_type == PASSENGER_TYPE_INFANT) {
                passenger_infant_num_tmp.value -= 1
                if (passenger_infant_num_tmp.value < 0) {
                    passenger_infant_num_tmp.value = 0
                }
            } else if (passenger_type == PASSENGER_TYPE_BABY) {
                passenger_baby_num_tmp.value -= 1
                if (passenger_baby_num_tmp.value < 0) {
                    passenger_baby_num_tmp.value = 0
                }
            }
        }
        const cancelPassenger = () => {
            $('div[id^="search__set-people-"]').hide()
        }
        const commitPassenger = async (line_no: number) => {
            // 乗船者人数確定時処理
            $('div[id^="search__set-people-"]').hide()
            context.emit(
                'commitPassenger',
                line_no,
                passenger_adult_num_tmp.value,
                passenger_child_num_tmp.value,
                passenger_infant_num_tmp.value,
                passenger_baby_num_tmp.value,
            )
        }

        return {
            headcount_distributions,
            passenger_adult_num_tmp,
            passenger_child_num_tmp,
            passenger_infant_num_tmp,
            passenger_baby_num_tmp,

            init,
            disp_passengers,
            calc_room_amount,
            calc_room_amount_by_parent,
            chkReserved,

            toggleInputPeople,
            incrementPassenger,
            decrementPassenger,
            cancelPassenger,
            commitPassenger,
        }
    },
    emits: ['commitPassenger', 'displayMessage'],
    name: 'PlanRoomList',
    data() {
        return {
            PERSONALITY_TYPE_INDIVIDUAL: PERSONALITY_TYPE_INDIVIDUAL as number,
            PERSONALITY_TYPE_CORPORATION: PERSONALITY_TYPE_CORPORATION as number,

            PASSENGER_TYPE_ADULT: PASSENGER_TYPE_ADULT as number,
            PASSENGER_TYPE_CHILD: PASSENGER_TYPE_CHILD as number,
            PASSENGER_TYPE_INFANT: PASSENGER_TYPE_INFANT as number,
            PASSENGER_TYPE_BABY: PASSENGER_TYPE_BABY as number,

            PREFECTURES: PREFECTURES,
            GENDER_MALE: GENDER_MALE as number,
            GENDER_FEMALE: GENDER_FEMALE as number,
        }
    },

    components: {},
    props: {
        list_idx: Number,
        dep_date: String,
        sea_route_code: String,
        contract_number: String,
        grade: Number,
        seat_type: Number,
        car_3m_num: Number,
        car_4m_num: Number,
        car_5m_num: Number,
        car_6m_num: Number,
    },
    methods: {},
    mounted: function () {
        console.log('↓↓↓PlanRoomList.mounted↓↓↓')
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search__item-people').length) {
                $('div[id^="search__set-people-"]').hide()
            }
        })
        console.log('↑↑↑PlanRoomList.mounted↑↑↑')
    },
})
</script>

<style scoped></style>
