<template>
    <span></span>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
    name: 'PrintReceipt',
    setup(props, context) {
        onMounted(async () => {
            console.log('↓↓↓PrintReceipt.onMounted↓↓↓')
            context.emit('showPrintReceipt')
            console.log('↑↑↑PrintReceipt.onMounted↑↑↑')
        })

        return {}
    },
    components: {},
    emits: ['showPrintReceipt'],
    data() {
        return {}
    },
    methods: {},
})
</script>
