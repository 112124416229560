<template>
    <main id="main" class="main">
        <!-- ticket -->
        <section class="ticket ticket-comp none">
            <div class="ticket__header">
                <h2 class="ticket__name">会員登録完了</h2>
            </div>
            <div class="ticket__container">
                <!-- ticket__list -->
                <p id="complate__text" class="complate__text">
                    会員登録が完了いたしました。<br />
                    登録アドレス宛に登録完了メールを送信しております。
                </p>
            </div>
            <div class="detail__btn-container btn-container">
                <button class="btn btn-next" type="button" v-on:click="moveLogin">ログインページへ</button>
            </div>
        </section>
        <!-- /ticket -->
        <!-- animation -->
        <ShipAnimationStart></ShipAnimationStart>
        <ShipAnimationEnd></ShipAnimationEnd>
        <!-- /animation -->
    </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import ShipAnimationStart from '@/components/common/ShipAnimationStart.vue'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()

        // ログインページへ
        const moveLogin = () => {
            router.push('/member/login')
        }

        return {
            moveLogin,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'MemberRegistrationComplete',
    data() {
        return {}
    },
    components: {
        ShipAnimationStart,
        ShipAnimationEnd,
    },
    methods: {},
})
</script>
<style></style>
