<template>
    <template v-for="(room, idx) in roomList" :key="idx">
        <li class="form__room">
            <div class="form__room-count">{{ idx + 1 }}部屋目</div>
        </li>
        <template v-for="(passenger, idx) in room.passenger_detail_array" :key="idx">
            <!-- master -->
            <li id="form__item" class="form__item" v-if="passenger.represent_flg">
                <div class="form__item-header">
                    <h3 class="form__item-name">代表者</h3>
                    <img class="form__item-icon" src="@/images/items/accessory-ancer-01.svg" height="53" width="48" alt="代表者アイコン" />
                </div>
                <div class="form__item-set">
                    <button
                        id="form__same"
                        class="form__same btn btn-same"
                        type="button"
                        v-on:click="setLoginMember(room.line_no, passenger.line_no)"
                    >
                        申込者と同じ
                    </button>
                    <select
                        id="form__passenger"
                        class="form__passenger input__select"
                        v-model="passenger.companion_id"
                        v-on:change="changeCompanionId(room.line_no, passenger.line_no)"
                    >
                        <option class="" :value="null">同行者情報から選択</option>
                        <template v-for="companion in companionList" :key="companion.id">
                            <option class="selectable" :value="companion.id">
                                {{ joinFirstNameAndLastName(companion.family_name_katakana, companion.given_name_katakana) }}
                            </option>
                        </template>
                    </select>
                    <div class="">
                        <input
                            :id="'form__regist-passenger' + passenger.line_no!"
                            class="form__regist-passenger"
                            type="checkbox"
                            v-model="passenger.register_companion"
                        />
                        <label class="check" :for="'form__regist-passenger' + passenger.line_no!">同行者情報に登録</label>
                    </div>
                </div>
                <div class="form__item-container">
                    <div class="input">
                        <dl class="input__list">
                            <!-- line -->
                            <div class="input__line">
                                <!-- fullname -->
                                <div :id="'input__item-fullname-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-fullname">
                                    <dt id="input__header-fullname" class="input__header input__header-fullname">
                                        <label id="input__name-fullname" class="input__name input__name-fullname" for="input__firstname"
                                            >お名前（全角カタカナ）</label
                                        >
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-fullname" class="input__container input__container-fullname">
                                        <div id="input__firstname-pack" class="input__firstname-pack">
                                            <span>セイ</span>
                                            <input
                                                id="input__firstname"
                                                class="input__form input__firstname"
                                                name="input__firstname"
                                                type="text"
                                                v-model="passenger.family_name_katakana"
                                            />
                                        </div>
                                        <div id="input__lastname-pack" class="input__lastname-pack">
                                            <span>メイ</span>
                                            <input
                                                id="input__lastname"
                                                class="input__form input__lastname"
                                                name="input__lastname"
                                                type="text"
                                                v-model="passenger.given_name_katakana"
                                            />
                                        </div>
                                    </dd>
                                    <p :id="'name-error-' + room.line_no + '-' + passenger.line_no" class="input__error-text"></p>
                                    <p id="input__text-fullname" class="input__text input__text-fullname">全角カタカナで入力してください。</p>
                                </div>
                            </div>
                            <!-- line -->
                            <div class="input__line">
                                <!-- tel -->
                                <div :id="'input__item-tel-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-tel">
                                    <dt id="input__header-tel" class="input__header input__header-tel">
                                        <label id="input__name-tel" class="input__name input__name-tel" for="input__tel01">電話番号</label>
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-tel" class="input__container input__container-tel">
                                        <input
                                            id="input__tel01"
                                            class="input__form input__tel01"
                                            name="input__tel01"
                                            type="tel"
                                            v-model="represent_passenger_tel01"
                                        />
                                        <span>-</span>
                                        <input
                                            id="input__tel02"
                                            class="input__form input__tel02"
                                            name="input__tel02"
                                            type="tel"
                                            v-model="represent_passenger_tel02"
                                        />
                                        <span>-</span>
                                        <input
                                            id="input__tel03"
                                            class="input__form input__tel03"
                                            name="input__tel03"
                                            type="tel"
                                            v-model="represent_passenger_tel03"
                                        />
                                    </dd>
                                    <p :id="'tel-error-' + room.line_no + '-' + passenger.line_no" class="input__error-text"></p>
                                </div>
                                <!-- sex -->
                                <div :id="'input__item-sex-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-sex">
                                    <dt id="input__header-sex" class="input__header input__header-sex">
                                        <label id="input__name-sex" class="input__name input__name-sex" for="">性別</label>
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-sex" class="input__container input__container-sex">
                                        <div id="input__radio-sex-man" class="input__radio input__radio-sex input__radio-sex-man">
                                            <input
                                                :id="'input__man-' + room.line_no + '-' + passenger.line_no"
                                                class="input__radio-btn input__sex input__man"
                                                type="radio"
                                                v-model="passenger.gender"
                                                :value="GENDER_MALE"
                                            />
                                            <label
                                                id="input__label-man"
                                                class="input__radio-label input__label-man"
                                                :for="'input__man-' + room.line_no + '-' + passenger.line_no"
                                                >男</label
                                            >
                                        </div>
                                        <div id="input__radio-sex-woman" class="input__radio input__radio-sex input__radio-sex-woman">
                                            <input
                                                :id="'input__woman-' + room.line_no + '-' + passenger.line_no"
                                                class="input__radio-btn input__sex input__woman"
                                                type="radio"
                                                v-model="passenger.gender"
                                                :value="GENDER_FEMALE"
                                            />
                                            <label
                                                id="input__label-woman"
                                                class="input__radio-label input__label-woman"
                                                :for="'input__woman-' + room.line_no + '-' + passenger.line_no"
                                                >女</label
                                            >
                                        </div>
                                    </dd>
                                    <p class="input__error-text">性別を選択してください</p>
                                </div>
                            </div>
                            <!-- line -->
                            <div class="input__line">
                                <!-- category -->
                                <div :id="'input__item-category-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-category">
                                    <dt id="input__header-category" class="input__header input__header-category">
                                        <label id="input__name-category" class="input__name input__name-category" for="input__category">区分</label>
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-category" class="input__container input__container-category">
                                        <select
                                            id="input__category"
                                            class="input__select input__category"
                                            name="input__category"
                                            v-model="passenger.passenger_type"
                                            v-on:change="commitPassengerList"
                                        >
                                            <option id="input__adult" class="input__adult" :value="PASSENGER_TYPE_ADULT">大人</option>
                                            <option id="input__child" class="input__child" :value="PASSENGER_TYPE_CHILD">小人</option>
                                        </select>
                                    </dd>
                                    <p class="input__error-text">区分を選択してください</p>
                                </div>
                                <!-- age -->
                                <div :id="'input__item-age-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-age">
                                    <dt id="input__header-age" class="input__header input__header-age">
                                        <label id="input__name-age" class="input__name input__name-age" for="">乗船年齢</label>
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-age" class="input__container input__container-age">
                                        <input
                                            id="input__age"
                                            class="input__form input__age"
                                            type="number"
                                            min="0"
                                            name="input__age"
                                            :value="passenger.age"
                                            @input="(event) => (passenger.age = event.target.value.replace(/[^0-9]/g, ''))"
                                        />
                                        <span>歳</span>
                                    </dd>
                                    <p :id="'age-error-' + room.line_no + '-' + passenger.line_no" class="input__error-text"></p>
                                </div>
                            </div>
                            <!-- line -->
                            <div class="input__line">
                                <!-- postnum -->
                                <div :id="'input__item-postnum-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-postnum">
                                    <dt id="input__header-postnum" class="input__header input__header-postnum">
                                        <label id="input__name-postnum" class="input__name input__name-postnum" for="input__postnum">郵便番号</label>
                                    </dt>
                                    <dd id="input__container-postnum" class="input__container input__container-postnum">
                                        <input
                                            id="input__postnum"
                                            class="input__form input__postnum"
                                            type="text"
                                            name="input__postnum"
                                            v-model="represent_passenger_post_code"
                                        />
                                        <button id="btn-address" class="btn btn-address" type="button" v-on:click="setAddressFromPostCode()">
                                            郵便番号から住所確認
                                        </button>
                                    </dd>
                                    <p class="input__error-text">郵便番号は000-0000形式で入力してください</p>
                                </div>
                                <!-- pref -->
                                <div :id="'input__item-pref-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-pref">
                                    <dt id="input__header-pref" class="input__header input__header-pref">
                                        <label id="input__name-pref" class="input__name input__name-pref" for="input__pref">都道府県</label>
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-pref" class="input__container input__container-pref">
                                        <select
                                            id="input__pref"
                                            class="input__select input__pref"
                                            name="input__pref"
                                            v-model="represent_passenger_prefectures"
                                        >
                                            <option class="" value="">選択してください</option>
                                            <template v-for="prefecture in PREFECTURES" :key="prefecture.value">
                                                <option class="" :value="prefecture.value">
                                                    {{ prefecture.text }}
                                                </option>
                                            </template>
                                        </select>
                                    </dd>
                                    <p class="input__error-text">都道府県を選択してください</p>
                                </div>
                            </div>
                            <!-- line -->
                            <div class="input__line">
                                <!-- address -->
                                <div :id="'input__item-address-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-address">
                                    <dt id="input__header-address" class="input__header input__header-address">
                                        <label id="input__name-address" class="input__name input__name-address" for="input__address">住所</label>
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-address" class="input__container input__container-address">
                                        <textarea
                                            id="input__address"
                                            class="input__form input__address"
                                            name="input__address"
                                            v-model="represent_passenger_address"
                                        ></textarea>
                                    </dd>
                                    <p class="input__error-text">住所を入力してください</p>
                                    <p class="input__text">マンション等にお住まいの方は、建物名まで記入してください。</p>
                                </div>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>

            <!-- Passenger -->
            <li id="form__item" class="form__item" v-else-if="passenger.deleted != true">
                <div class="form__item-header">
                    <h3 class="form__item-name">同行者（{{ passenger.line_no! - 1 }}）</h3>
                    <button class="btn-delete" type="button" v-on:click="confirmationDeletePassenger(room.line_no, passenger.line_no)">削除</button>
                    <img class="form__item-icon" src="@/images/items/accessory-strawhat-01.svg" height="53" width="87" alt="同行者アイコン" />
                </div>
                <div class="form__item-set">
                    <select
                        id="form__passenger"
                        class="form__passenger input__select"
                        v-model="passenger.companion_id"
                        v-on:change="changeCompanionId(room.line_no, passenger.line_no)"
                    >
                        <option class="" :value="null">同行者情報から選択</option>
                        <template v-for="companion in companionList" :key="companion.id">
                            <option class="selectable" :value="companion.id">
                                {{ joinFirstNameAndLastName(companion.family_name_katakana, companion.given_name_katakana) }}
                            </option>
                        </template>
                    </select>
                    <div class="">
                        <input
                            :id="'form__regist-passenger' + passenger.line_no!"
                            class="form__regist-passenger"
                            type="checkbox"
                            v-model="passenger.register_companion"
                        />
                        <label class="check" :for="'form__regist-passenger' + passenger.line_no!">同行者情報に登録</label>
                    </div>
                </div>
                <div class="form__item-container">
                    <div class="input">
                        <dl class="input__list">
                            <!-- line -->
                            <div class="input__line">
                                <!-- fullname -->
                                <div :id="'input__item-fullname-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-fullname">
                                    <dt id="input__header-fullname" class="input__header input__header-fullname">
                                        <label id="input__name-fullname" class="input__name input__name-fullname" for="input__firstname"
                                            >お名前（全角カタカナ）</label
                                        >
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-fullname" class="input__container input__container-fullname">
                                        <div id="input__firstname-pack" class="input__firstname-pack">
                                            <span>セイ</span>
                                            <input
                                                id="input__firstname"
                                                class="input__form input__firstname"
                                                name="input__firstname"
                                                type="text"
                                                v-model="passenger.family_name_katakana"
                                            />
                                        </div>
                                        <div id="input__lastname-pack" class="input__lastname-pack">
                                            <span>メイ</span>
                                            <input
                                                id="input__lastname"
                                                class="input__form input__lastname"
                                                name="input__lastname"
                                                type="text"
                                                v-model="passenger.given_name_katakana"
                                            />
                                        </div>
                                    </dd>
                                    <p :id="'name-error-' + room.line_no + '-' + passenger.line_no" class="input__error-text"></p>
                                    <p id="input__text-fullname" class="input__text input__text-fullname">全角カタカナで入力してください。</p>
                                </div>
                            </div>
                            <!-- line -->
                            <div class="input__line">
                                <!-- sex -->
                                <div :id="'input__item-sex-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-sex">
                                    <dt id="input__header-sex" class="input__header input__header-sex">
                                        <label id="input__name-sex" class="input__name input__name-sex" for="">性別</label>
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-sex" class="input__container input__container-sex">
                                        <div id="input__radio-sex-man" class="input__radio input__radio-sex input__radio-sex-man">
                                            <input
                                                :id="'input__man-' + room.line_no + '-' + passenger.line_no"
                                                class="input__radio-btn input__sex input__man"
                                                type="radio"
                                                v-model="passenger.gender"
                                                :value="GENDER_MALE"
                                            />
                                            <label
                                                id="input__label-man"
                                                class="input__radio-label input__label-man"
                                                :for="'input__man-' + room.line_no + '-' + passenger.line_no"
                                                >男</label
                                            >
                                        </div>
                                        <div id="input__radio-sex-woman" class="input__radio input__radio-sex input__radio-sex-woman">
                                            <input
                                                :id="'input__woman-' + room.line_no + '-' + passenger.line_no"
                                                class="input__radio-btn input__sex input__woman"
                                                type="radio"
                                                v-model="passenger.gender"
                                                :value="GENDER_FEMALE"
                                            />
                                            <label
                                                id="input__label-woman"
                                                class="input__radio-label input__label-woman"
                                                :for="'input__woman-' + room.line_no + '-' + passenger.line_no"
                                                >女</label
                                            >
                                        </div>
                                    </dd>
                                    <p class="input__error-text">性別を選択してください</p>
                                </div>
                                <!-- category -->
                                <div :id="'input__item-category-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-category">
                                    <dt id="input__header-category" class="input__header input__header-category">
                                        <label id="input__name-category" class="input__name input__name-category" for="input__category">区分</label>
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-category" class="input__container input__container-category">
                                        <select
                                            id="input__category"
                                            class="input__select input__category"
                                            name="input__category"
                                            v-model="passenger.passenger_type"
                                            v-on:change="commitPassengerList"
                                        >
                                            <option id="input__adult" class="input__adult" :value="PASSENGER_TYPE_ADULT">大人</option>
                                            <option id="input__child" class="input__child" :value="PASSENGER_TYPE_CHILD">小人</option>
                                        </select>
                                    </dd>
                                    <p class="input__error-text">区分を選択してください</p>
                                </div>
                            </div>
                            <!-- line -->
                            <div class="input__line">
                                <!-- age -->
                                <div :id="'input__item-age-' + room.line_no + '-' + passenger.line_no" class="input__item input__item-age">
                                    <dt id="input__header-age" class="input__header input__header-age">
                                        <label id="input__name-age" class="input__name input__name-age" for="">乗船年齢</label>
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-age" class="input__container input__container-age">
                                        <input
                                            id="input__age"
                                            class="input__form input__age"
                                            type="number"
                                            min="0"
                                            name="input__age"
                                            :value="passenger.age"
                                            @input="(event) => (passenger.age = event.target.value.replace(/[^0-9]/g, ''))"
                                        />
                                        <span>歳</span>
                                    </dd>
                                    <p :id="'age-error-' + room.line_no + '-' + passenger.line_no" class="input__error-text"></p>
                                </div>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
        </template>
        <!-- Passenger-plus -->
        <li class="form__plus">
            <button class="btn-border btn-border-plus" type="button" v-on:click="addPassenger(room.line_no)">同行者を追加する</button>
        </li>
    </template>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue'
import $ from 'jquery'
import {
    API_USERS,
    API_ORDER,
    isNull,
    joinFirstNameAndLastName,
    getCompanionDetail,
    calcAge,
    getPassengerTypeByAge,
    searchAddress,
    getPassengerFare,
    getReservedFare,
    ROOM_INFORMATIONS,
    getPassengerPrice,
    checkPassengerTypeAgeRange,
    useMaxRoomPassengerCount,
    getSeatTypeName,
    castStringToDate,
} from '@/lib/util'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { HeadcountDistribution, PassengerDetail, AddressDetail, CompanionDetail, Contract, RoomInformation, CancelPolicyDetail } from '@/lib/types'
import {
    PERSONALITY_TYPE_INDIVIDUAL,
    PERSONALITY_TYPE_CORPORATION,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_INFANT,
    PASSENGER_TYPE_BABY,
    PREFECTURES,
    GENDER_MALE,
    GENDER_FEMALE,
    SEAT_TYPE_FWP_FO,
    SEAT_TYPE_FWP_TW,
    SEAT_TYPE_S,
    SEAT_TYPE_D,
    convertTexts,
    GENDER_RESTRICT_NONE,
    GENDER_RESTRICT_MALE,
    GENDER_RESTRICT_FEMALE,
    SEAT_TYPE_DS,
} from '@/lib/constants'
import { isNumeric, isPostCode, isZenKatakana } from '@/lib/validation_util'
import { getMemberData, getCompanionData } from '@/lib/localStorageUtil'

export default defineComponent({
    setup(props, context) {
        const room_count = ref(0)
        const roomList = ref<HeadcountDistribution[]>()
        const companionList = ref<CompanionDetail[]>()
        const contract = ref<Contract>()
        const currentCancelPolicy = ref<CancelPolicyDetail | null>(null)
        const grade = ref(0)
        const seat_type = ref(0)

        const personality_type = ref(0)
        const corporate_name = ref('')
        const represent_passenger_post_code = ref('')
        const represent_passenger_prefectures = ref('')
        const represent_passenger_address = ref('')
        const represent_passenger_tel = ref('')
        const represent_passenger_tel01 = ref('')
        const represent_passenger_tel02 = ref('')
        const represent_passenger_tel03 = ref('')

        const init = (
            rooms: HeadcountDistribution[],
            init_personality_type: number,
            init_corporate_name: string,
            init_represent_passenger_post_code: string,
            init_represent_passenger_prefectures: string,
            init_represent_passenger_address: string,
            init_represent_passenger_tel: string,
            target_contract: Contract,
            cancel_policy: CancelPolicyDetail | null,
            init_grade: number,
            init_seat_type: number,
        ) => {
            console.log('↓↓↓RoomList.init↓↓↓')
            grade.value = init_grade
            seat_type.value = init_seat_type
            roomList.value = rooms
            room_count.value = roomList.value.length
            personality_type.value = init_personality_type
            corporate_name.value = init_corporate_name!
            represent_passenger_post_code.value = init_represent_passenger_post_code!
            represent_passenger_prefectures.value = init_represent_passenger_prefectures!
            represent_passenger_address.value = init_represent_passenger_address!
            represent_passenger_tel.value = init_represent_passenger_tel!
            if (isNull(represent_passenger_tel.value)) {
                represent_passenger_tel01.value = ''
                represent_passenger_tel02.value = ''
                represent_passenger_tel03.value = ''
            } else {
                if (represent_passenger_tel.value.split('-').length >= 1) {
                    represent_passenger_tel01.value = represent_passenger_tel.value.split('-')[0]
                }
                if (represent_passenger_tel.value.split('-').length >= 2) {
                    represent_passenger_tel02.value = represent_passenger_tel.value.split('-')[1]
                }
                if (represent_passenger_tel.value.split('-').length >= 3) {
                    represent_passenger_tel03.value = represent_passenger_tel.value.split('-')[2]
                }
            }
            contract.value = target_contract
            currentCancelPolicy.value = cancel_policy
            console.log('↑↑↑RoomList.init↑↑↑')
        }

        const getData = (): HeadcountDistribution[] => {
            if (!isNull(roomList.value)) {
                for (let i = 0; i < roomList.value!.length; i++) {
                    setRoomPassengerCount(roomList.value![i].line_no)
                }
            }
            return roomList.value!
        }
        // 乗船者削除確認
        const confirmationDeletePassenger = (room_line_no: number, passenger_line_no: number | null) => {
            deletePassenger(room_line_no, passenger_line_no)
        }
        // 乗船者削除
        const deletePassenger = (room_line_no: number, passenger_line_no: number | null) => {
            console.log('↓↓↓RoomList.deletePassenger↓↓↓')
            if (seat_type.value != SEAT_TYPE_S && seat_type.value != SEAT_TYPE_DS) {
                for (let i = 0; i < roomList.value!.length; i++) {
                    if (roomList.value![i].line_no == room_line_no) {
                        let tmpArray = []
                        for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                            if (roomList.value![i].passenger_detail_array[j].line_no != passenger_line_no) {
                                tmpArray.push(roomList.value![i].passenger_detail_array[j])
                            } else {
                                if (!isNull(roomList.value![i].passenger_detail_array[j].id)) {
                                    roomList.value![i].passenger_detail_array[j].deleted = true
                                    tmpArray.push(roomList.value![i].passenger_detail_array[j])
                                }
                            }
                        }
                        roomList.value![i].passenger_detail_array = tmpArray
                    }
                }
            } else {
                // シングルの場合、削除された乗船者が登録済なら先頭部屋に移動して部屋を削除
                let tmpRoomArray = []
                let tmpArray = []
                for (let i = 0; i < roomList.value!.length; i++) {
                    if (i == 0) {
                        // 先頭行退避
                        tmpArray.push(...roomList.value![i].passenger_detail_array)
                    }
                    if (roomList.value![i].line_no == room_line_no) {
                        if (!isNull(roomList.value![i].passenger_detail_array[0].id)) {
                            roomList.value![i].passenger_detail_array[0].deleted = true
                            tmpArray.push(roomList.value![i].passenger_detail_array[0])
                        }
                        // for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        //     if (roomList.value![i].passenger_detail_array[j].line_no != passenger_line_no) {
                        //         tmpArray.push(roomList.value![i].passenger_detail_array[j]);
                        //     } else {
                        //         if(!isNull(roomList.value![i].passenger_detail_array[j].id)) {
                        //             roomList.value![i].passenger_detail_array[j].deleted = true;
                        //             tmpArray.push(roomList.value![i].passenger_detail_array[j]);
                        //         }
                        //     }
                        // }
                        // roomList.value![i].passenger_detail_array = tmpArray;
                    } else {
                        tmpRoomArray.push(roomList.value![i])
                    }
                }
                tmpRoomArray[0].passenger_detail_array = tmpArray
                roomList.value = tmpRoomArray
            }
            // 室内人数更新
            setRoomPassengerCount(room_line_no)
            commitPassengerList()
            console.log('↑↑↑RoomList.deletePassenger↑↑↑')
        }
        // 乗船者追加
        const addPassenger = (room_line_no: number) => {
            console.log('↓↓↓RoomList.addPassenger↓↓↓')
            if (seat_type.value != SEAT_TYPE_S && seat_type.value != SEAT_TYPE_DS) {
                // シングルでない場合、部屋毎の定員チェック
                // 現在の室内乗船者数
                const room_passenger_count = getRoomPassengerCount(room_line_no)
                // 1部屋当たり乗船者数
                const passenger_par_room = useMaxRoomPassengerCount(seat_type.value!, 1)
                if (room_passenger_count >= passenger_par_room) {
                    if (props.isUpdate) {
                        context.emit('displayMessage', {
                            msg_title: '船室定員オーバー',
                            msg: [
                                `${getSeatTypeName(seat_type.value)}のご予約は1部屋に${passenger_par_room}名までになります。`,
                                '部屋の追加はできません。追加で別予約をお願いいたします。',
                            ],
                            close_btn: true,
                        })
                    } else {
                        context.emit('displayMessage', {
                            msg_title: '船室定員オーバー',
                            msg: [`${getSeatTypeName(seat_type.value)}のご予約は1部屋に${passenger_par_room}名までになります。`],
                            close_btn: true,
                        })
                    }
                    return
                }
            }

            // 乗船者行番号設定
            let new_line_no = 0
            for (let i = 0; i < roomList.value!.length; i++) {
                for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                    if (new_line_no < roomList.value![i]!.passenger_detail_array[j]!.line_no!) {
                        new_line_no = roomList.value![i].passenger_detail_array[j].line_no!
                    }
                }
            }
            new_line_no += 1

            // 部屋番号設定
            let new_room_line_no = room_line_no
            if (seat_type.value == SEAT_TYPE_S || seat_type.value == SEAT_TYPE_DS) {
                // シングルの場合、部屋を追加して追加した部屋を乗船者追加対象とする
                for (let i = 0; i < roomList.value!.length; i++) {
                    if (roomList.value![i].line_no > new_room_line_no) {
                        new_room_line_no = roomList.value![i].line_no
                    }
                }
                new_room_line_no += 1
                roomList.value!.push({
                    cabin_id: null,
                    line_no: new_room_line_no,
                    adult: 0,
                    child: 0,
                    infant: 0,
                    baby: 0,
                    reserved_room: false,
                    room_amount: 0,
                    passenger_detail_array: [],
                } as HeadcountDistribution)
            }

            // 同乗者追加
            for (let i = 0; i < roomList.value!.length; i++) {
                if (roomList.value![i].line_no == new_room_line_no) {
                    roomList.value![i].passenger_detail_array.push({
                        id: null, // 乗船者ID
                        line_no: new_line_no, // 行番号
                        family_name_katakana: '', // 姓（カタカナ）
                        given_name_katakana: '', // 名（カタカナ）
                        birthday_year: null, // 生年月日（年）
                        birthday_month: null, // 生年月日（月）
                        birthday_day: null, // 生年月日（日）
                        gender: undefined, // 性別
                        age: null, // 年齢
                        passenger_type: null, // 乗船者区分
                        represent_flg: false, // 代表者フラグ
                        mcf_member_id: '', // MCF会員ID
                        with_pets_flg: false, // withペット料金フラグ
                        driver: false, // ドライバー
                        reserved_room: false, // 貸切（船室）
                        register_companion: true, // 同乗者情報に登録
                        companion_id: null, // 同乗者ID
                        deleted: false,
                    } as PassengerDetail)
                }
            }
            // 室内人数更新
            setRoomPassengerCount(new_room_line_no)
            console.log('↑↑↑RoomList.addPassenger↑↑↑')
        }
        // 室内人数更新
        const setRoomPassengerCount = (room_line_no: number) => {
            for (let i = 0; i < roomList.value!.length; i++) {
                if (roomList.value![i].line_no == room_line_no) {
                    let adult_cnt = 0
                    let child_cnt = 0
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                            continue
                        }
                        if (roomList.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_ADULT) {
                            adult_cnt++
                        } else if (roomList.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_CHILD) {
                            child_cnt++
                        }
                    }
                    roomList.value![i].adult = adult_cnt
                    roomList.value![i].child = child_cnt
                    break
                }
            }
        }

        const setLoginMember = (room_line_no: number, passenger_line_no: number | null) => {
            console.log('↓↓↓RoomList.setLoginMember↓↓↓')
            for (let i = 0; i < roomList.value!.length; i++) {
                if (roomList.value![i].line_no == room_line_no) {
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        if (roomList.value![i].passenger_detail_array[j].line_no == passenger_line_no) {
                            const member_detail = getMemberData()
                            roomList.value![i].passenger_detail_array[j].mcf_member_id = member_detail!.member_id
                            if (!isNull(member_detail)) {
                                roomList.value![i].passenger_detail_array[j].family_name_katakana = member_detail!.family_name_katakana
                                roomList.value![i].passenger_detail_array[j].given_name_katakana = member_detail!.given_name_katakana
                                roomList.value![i].passenger_detail_array[j].gender = member_detail!.sex!
                                roomList.value![i].passenger_detail_array[j].birthday_year = member_detail!.birthday_year
                                roomList.value![i].passenger_detail_array[j].birthday_month = member_detail!.birthday_month
                                roomList.value![i].passenger_detail_array[j].birthday_day = member_detail!.birthday_day
                                roomList.value![i].passenger_detail_array[j].age = calcAge(
                                    props.dep_date!,
                                    member_detail!.birthday_year!,
                                    member_detail!.birthday_month!,
                                    member_detail!.birthday_day!,
                                )
                                roomList.value![i].passenger_detail_array[j].passenger_type = getPassengerTypeByAge(
                                    roomList.value![i].passenger_detail_array[j].age,
                                )
                                roomList.value![i].passenger_detail_array[j].register_companion = false
                                represent_passenger_tel01.value = member_detail.tel1 ? member_detail.tel1 : ''
                                represent_passenger_tel02.value = member_detail.tel2 ? member_detail.tel2 : ''
                                represent_passenger_tel03.value = member_detail.tel3 ? member_detail.tel3 : ''
                                represent_passenger_post_code.value = member_detail.post_code ? member_detail.post_code : ''
                                represent_passenger_address.value = member_detail.address ? member_detail.address : ''
                                represent_passenger_prefectures.value = member_detail.prefectures ? member_detail.prefectures : ''
                            }
                        }
                    }
                }
            }
            commitPassengerList()
            console.log('↑↑↑RoomList.setLoginMember↑↑↑')
        }

        // 同乗者情報から選択時処理
        const changeCompanionId = (room_line_no: number, passenger_line_no: number | null) => {
            console.log('↓↓↓RoomList.changeCompanionId↓↓↓')
            for (let i = 0; i < roomList.value!.length; i++) {
                if (roomList.value![i].line_no == room_line_no) {
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        if (roomList.value![i].passenger_detail_array[j].line_no == passenger_line_no) {
                            let companion = getCompanionDetail(roomList.value![i].passenger_detail_array[j].companion_id!)
                            if (!isNull(companion)) {
                                roomList.value![i].passenger_detail_array[j].passenger_type = companion!.passenger_type
                                roomList.value![i].passenger_detail_array[j].family_name_katakana = companion!.family_name_katakana
                                roomList.value![i].passenger_detail_array[j].given_name_katakana = companion!.given_name_katakana
                                roomList.value![i].passenger_detail_array[j].gender = companion!.sex!
                                roomList.value![i].passenger_detail_array[j].register_companion = false
                            }
                        }
                    }
                }
            }
            commitPassengerList()
            console.log('↑↑↑RoomList.changeCompanionId↑↑↑')
        }
        // 乗船者人数取得
        const getPassengerCount = (passenger_type: number): number => {
            let cnt = 0
            if (!roomList.value) {
                return cnt
            }
            for (let i = 0; i < roomList.value!.length; i++) {
                for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                    if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                        continue
                    }
                    if (roomList.value![i].passenger_detail_array[j].passenger_type == passenger_type) {
                        cnt += 1
                    }
                }
            }
            return cnt
        }

        /** ドライバー人数取得 */
        const getDriverCount = (): number => {
            let cnt = 0;
            for (let i = 0; i < roomList.value!.length; i++) {
                for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                    if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                        continue;
                    }
                    if (roomList.value![i].passenger_detail_array[j].driver) {
                        cnt+=1;
                    }
                }
            }
            return cnt;
        };

        const getRoomPassengerCount = (room_line_no: number): number => {
            let passenger_count = 0

            for (let i = 0; i < roomList.value!.length; i++) {
                if (roomList.value![i].line_no == room_line_no) {
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                            continue
                        }
                        passenger_count += 1
                    }
                    break
                }
            }

            return passenger_count
        }

        const getPassengerData = (): PassengerDetail[] => {
            let passenger_list = [] as PassengerDetail[]

            for (let i = 0; i < roomList.value!.length; i++) {
                roomList.value![i].passenger_detail_array.forEach((element) => {
                    element.given_name_katakana = element.given_name_katakana.trim()
                    element.family_name_katakana = element.family_name_katakana.trim()
                })
                passenger_list = passenger_list.concat(roomList.value![i].passenger_detail_array)
            }

            return passenger_list
        }

        const getPersonalityType = (): number => {
            return personality_type.value
        }

        const getCorporateName = (): string => {
            return corporate_name.value
        }
        // 代表者氏名
        const getRepresentPassengerName = (): string => {
            let val = ''
            if (!roomList.value) {
                return val
            }
            for (let i = 0; i < roomList.value!.length; i++) {
                for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                    if (roomList.value![i].passenger_detail_array[j].represent_flg) {
                        val = joinFirstNameAndLastName(
                            roomList.value![i].passenger_detail_array[j].family_name_katakana,
                            roomList.value![i].passenger_detail_array[j].given_name_katakana,
                        )
                    }
                }
            }
            return val
        }
        // 代表者郵便番号
        const getRepresentPassengerPostCode = (): string => {
            return represent_passenger_post_code.value
        }
        // 代表者都道府県
        const getRepresentPassengerPrefectures = (): string => {
            return represent_passenger_prefectures.value
        }
        // 代表者住所
        const getRepresentPassengerAddress = (): string => {
            return represent_passenger_address.value
        }
        // 代表者連絡先
        const getRepresentPassengerTel = (): string => {
            represent_passenger_tel.value =
                represent_passenger_tel01.value + '-' + represent_passenger_tel02.value + '-' + represent_passenger_tel03.value
            return represent_passenger_tel.value
        }

        // 部屋情報取得
        const getRoomInfo = (): RoomInformation => {
            let room_info = {} as RoomInformation
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type.value) {
                    room_info = info
                    break
                }
            }
            return room_info
        }

        // 郵便番号から住所確認
        const setAddressFromPostCode = async (): Promise<void> => {
            console.log('↓↓↓RoomList.setAddressFromPostCode↓↓↓')
            let isChk = false
            if (isNull(represent_passenger_post_code.value)) {
                return
            }
            let post_code = represent_passenger_post_code.value.split('-')
            let post_code_1 = ''
            let post_code_2 = ''
            if (post_code.length == 1 && post_code[0].length == 7) {
                post_code_1 = post_code[0].substring(0, 3)
                post_code_2 = post_code[0].substring(3, 7)
            } else if (post_code.length == 2) {
                post_code_1 = post_code[0]
                post_code_2 = post_code[1]
            }
            if (post_code_1.length == 3 && post_code_2.length == 4) {
                represent_passenger_post_code.value = post_code_1 + '-' + post_code_2
                isChk = true
            }
            if (isChk) {
                const address = await searchAddress(represent_passenger_post_code.value)
                console.log(address)
                represent_passenger_prefectures.value = address.region_id
                represent_passenger_address.value = address.locality
                represent_passenger_address.value += address.street
            }
            console.log('↑↑↑RoomList.setAddressFromPostCode↑↑↑')
        }

        // 貸切フラグ設定
        const setReservedFlg = () => {
            if (!isNull(roomList.value)) {
                for (let i = 0; i < roomList.value!.length; i++) {
                    let isReserved = false
                    let paying_passenger_count = 0
                    // 有料乗船者数チェック
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                            continue
                        }
                        if (
                            roomList.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_ADULT ||
                            roomList.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_CHILD
                        ) {
                            paying_passenger_count++
                        }
                    }
                    // 貸切人数チェック
                    for (const info of ROOM_INFORMATIONS) {
                        if (info.seat_type == seat_type.value) {
                            if (info.reserve_count > paying_passenger_count) {
                                if (
                                    (!isNull(contract.value?.reserved_change_start_date) || !isNull(contract.value?.reserved_change_end_date)) &&
                                    !isNull(contract.value?.reserved_change_start_date) &&
                                    contract.value!.reserved_change_start_date! <= castStringToDate(props.dep_date)! &&
                                    !isNull(contract.value?.reserved_change_end_date) &&
                                    contract.value!.reserved_change_end_date! >= castStringToDate(props.dep_date)!
                                ) {
                                    isReserved = true
                                }
                            }
                            break
                        }
                    }

                    // 貸切フラグ設定
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                            continue
                        }
                        roomList.value![i].passenger_detail_array[j].reserved_room = isReserved
                        if (isReserved) {
                            isReserved = false
                        }
                    }
                }
            }
        }
        // withペットフラグ設定
        const setPetsFlg = () => {
            if (!isNull(roomList.value)) {
                if (seat_type.value == SEAT_TYPE_FWP_FO || seat_type.value == SEAT_TYPE_FWP_TW) {
                    for (let i = 0; i < roomList.value!.length; i++) {
                        let isPetsFlg = true
                        // 大人に優先して設定
                        for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                            if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                                continue
                            }
                            if (isPetsFlg && roomList.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_ADULT) {
                                roomList.value![i].passenger_detail_array[j].with_pets_flg = true
                                isPetsFlg = false
                            } else {
                                roomList.value![i].passenger_detail_array[j].with_pets_flg = false
                            }
                        }
                        if (isPetsFlg) {
                            for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                                if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                                    continue
                                }
                                if (isPetsFlg && roomList.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_CHILD) {
                                    roomList.value![i].passenger_detail_array[j].with_pets_flg = true
                                    isPetsFlg = false
                                }
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < roomList.value!.length; i++) {
                        for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                            if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                                continue
                            }
                            roomList.value![i].passenger_detail_array[j].with_pets_flg = false
                        }
                    }
                }
            }
        }
        // ドライバーフラグ設定
        const setDriver = (driver_passenger_line_nos: any[]) => {
            console.log(driver_passenger_line_nos)
            // ドライバーフラグ初期化
            for (let i = 0; i < roomList.value!.length; i++) {
                for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                    // 削除の乗船者もドライバーフラグを初期化
                    // if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                    //     continue;
                    // }
                    roomList.value![i].passenger_detail_array[j].driver = false
                    roomList.value![i].passenger_detail_array[j].car_row_no = null
                }
            }
            for (let k = 0; k < driver_passenger_line_nos.length; k++) {
                if (!isNull(driver_passenger_line_nos[k])) {
                    for (let i = 0; i < roomList.value!.length; i++) {
                        for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                            if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                                continue
                            }
                            if (roomList.value![i].passenger_detail_array[j].line_no == driver_passenger_line_nos[k].passenger_line_no) {
                                roomList.value![i].passenger_detail_array[j].driver = true
                                roomList.value![i].passenger_detail_array[j].car_row_no = driver_passenger_line_nos[k].car_line_no
                                break
                            }
                        }
                    }
                }
            }
        }
        // 乗船者情報更新を通知
        const commitPassengerList = () => {
            context.emit('commitPassengerList')
        }
        // 料金計算
        const calcPrice = async (room_line_no: number, passenger_line_no: number | null) => {
            // 等級が初期値の場合、処理を行わない
            if (grade.value != 0) {
                for (let i = 0; i < roomList.value!.length; i++) {
                    if (roomList.value![i].line_no == room_line_no) {
                        for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                            if (
                                roomList.value![i].passenger_detail_array[j].line_no == passenger_line_no &&
                                !isNull(roomList.value![i].passenger_detail_array[j].passenger_type)
                            ) {
                                // 削除
                                if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                                    roomList.value![i].passenger_detail_array[j].unit_price = 0
                                    roomList.value![i].passenger_detail_array[j].baf_amount = 0
                                    roomList.value![i].passenger_detail_array[j].with_pets_amount = 0
                                    roomList.value![i].passenger_detail_array[j].reserved_amount = 0
                                    continue
                                }
                                const contract_fare = await getPassengerFare(
                                    props.dep_date!,
                                    props.sea_route_code!,
                                    props.contract_number!,
                                    grade.value!,
                                    roomList.value![i].passenger_detail_array[j].passenger_type!,
                                    roomList.value![i].passenger_detail_array[j].driver!,
                                    roomList.value![i].passenger_detail_array[j].with_pets_flg!,
                                )
                                roomList.value![i].passenger_detail_array[j].unit_price = contract_fare.person_fare
                                roomList.value![i].passenger_detail_array[j].baf_amount = contract_fare.baf
                                // withペット料金
                                if (roomList.value![i].passenger_detail_array[j].with_pets_flg) {
                                    roomList.value![i].passenger_detail_array[j].with_pets_amount = contract_fare.with_pets_amount
                                } else {
                                    roomList.value![i].passenger_detail_array[j].with_pets_amount = 0
                                }
                                // 貸切料
                                if (roomList.value![i].passenger_detail_array[j].reserved_room) {
                                    const reserved_fare = await getReservedFare(
                                        props.dep_date!,
                                        props.sea_route_code!,
                                        props.contract_number!,
                                        grade.value!,
                                        seat_type.value!,
                                        roomList.value![i].adult + roomList.value![i].child,
                                    )
                                    roomList.value![i].passenger_detail_array[j].reserved_amount = reserved_fare.person_fare
                                } else {
                                    roomList.value![i].passenger_detail_array[j].reserved_amount = 0
                                }
                            }
                        }
                    }
                }
            }
        }
        const calcPriceAll = async () => {
            // 貸切フラグ設定
            setReservedFlg()
            // withペットフラグ設定
            setPetsFlg()

            if (!isNull(roomList.value)) {
                for (let i = 0; i < roomList.value!.length; i++) {
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        await calcPrice(roomList.value![i].line_no, roomList.value![i].passenger_detail_array[j].line_no)
                    }
                }
            }
        }
        const getTotalPrice = (): number => {
            let total_price = 0
            if (!isNull(roomList.value)) {
                for (let i = 0; i < roomList.value!.length; i++) {
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        total_price += getPassengerPrice(roomList.value![i].passenger_detail_array![j])
                    }
                }
            }
            return total_price
        }
        // 入力チェック
        const chkValidation = (): any[] => {
            let err_count = 0
            let err_messages = [] as string[]

            const room_info = getRoomInfo()

            for (let i = 0; i < roomList.value!.length; i++) {
                let adult_cnt = 0
                let child_cnt = 0

                for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                    if (
                        roomList.value![i].passenger_detail_array[j].deleted == undefined ||
                        roomList.value![i].passenger_detail_array[j].deleted == false
                    ) {
                        if (roomList.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_ADULT) {
                            adult_cnt++
                        } else if (roomList.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_CHILD) {
                            child_cnt++
                        }

                        if (roomList.value![i].passenger_detail_array[j].represent_flg) {
                            // 代表者
                            // 初期化
                            $(
                                '#input__item-fullname-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $(
                                '#input__item-tel-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $(
                                '#input__item-sex-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $(
                                '#input__item-category-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $(
                                '#input__item-age-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $(
                                '#input__item-postnum-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $(
                                '#input__item-pref-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $(
                                '#input__item-address-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $('#name-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text('')
                            $('#tel-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text('')
                            $('#zip-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text('')
                            $('#age-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text('')
                            // お名前
                            if (
                                isNull(roomList.value![i].passenger_detail_array[j].family_name_katakana) ||
                                isNull(roomList.value![i].passenger_detail_array[j].given_name_katakana)
                            ) {
                                $(
                                    '#input__item-fullname-' +
                                        roomList.value![i].line_no +
                                        '-' +
                                        roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                $('#name-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                    'お名前を入力してください',
                                )
                                err_count++
                            } else if (
                                !isZenKatakana(roomList.value![i].passenger_detail_array[j].family_name_katakana) ||
                                !isZenKatakana(roomList.value![i].passenger_detail_array[j].given_name_katakana)
                            ) {
                                $(
                                    '#input__item-fullname-' +
                                        roomList.value![i].line_no +
                                        '-' +
                                        roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                $('#name-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                    'お名前は全角カタカナで入力してください',
                                )
                                err_count++
                            }
                            // 電話番号
                            if (
                                isNull(represent_passenger_tel01.value) ||
                                isNull(represent_passenger_tel02.value) ||
                                isNull(represent_passenger_tel03.value)
                            ) {
                                $(
                                    '#input__item-tel-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                $('#tel-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                    '電話番号を入力してください',
                                )
                                err_count++
                            } else if (
                                !isNumeric(represent_passenger_tel01.value) ||
                                !isNumeric(represent_passenger_tel02.value) ||
                                !isNumeric(represent_passenger_tel03.value)
                            ) {
                                $(
                                    '#input__item-tel-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                $('#tel-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                    '電話番号は半角数字で入力してください',
                                )
                                err_count++
                            } else {
                                const tel = represent_passenger_tel01.value + represent_passenger_tel02.value + represent_passenger_tel03.value
                                if (tel.length > 13) {
                                    $(
                                        '#input__item-tel-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                    ).addClass('input__error')
                                    $('#tel-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                        '電話番号は13文字以内で入力してください',
                                    )
                                    err_count++
                                }
                            }
                            // 性別
                            if (isNull(roomList.value![i].passenger_detail_array[j].gender)) {
                                $(
                                    '#input__item-sex-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                err_count++
                            }
                            // 区分
                            if (isNull(roomList.value![i].passenger_detail_array[j].passenger_type)) {
                                $(
                                    '#input__item-category-' +
                                        roomList.value![i].line_no +
                                        '-' +
                                        roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                err_count++
                            }
                            // 乗船年齢
                            if (isNull(roomList.value![i].passenger_detail_array[j].age) || roomList.value![i].passenger_detail_array[j].age! < 0) {
                                $(
                                    '#input__item-age-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                $('#age-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                    '乗船年齢を入力してください',
                                )
                                err_count++
                            } else {
                                const age_range_error = checkPassengerTypeAgeRange(
                                    roomList.value![i].passenger_detail_array[j].passenger_type,
                                    roomList.value![i].passenger_detail_array[j].age,
                                )
                                if (!isNull(age_range_error)) {
                                    $(
                                        '#input__item-age-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                    ).addClass('input__error')
                                    $('#age-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                        age_range_error,
                                    )
                                    err_messages.push(age_range_error)
                                    err_count++
                                }
                            }
                            // 郵便番号
                            if (!isNull(represent_passenger_post_code.value) && !isPostCode(represent_passenger_post_code.value)) {
                                $(
                                    '#input__item-postnum-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                err_count++
                            }
                            // 都道府県
                            if (isNull(represent_passenger_prefectures.value)) {
                                $(
                                    '#input__item-pref-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                err_count++
                            }
                            // 住所
                            if (isNull(represent_passenger_address.value)) {
                                $(
                                    '#input__item-address-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                err_count++
                            }
                        } else {
                            // 同行者
                            // 初期化
                            $(
                                '#input__item-fullname-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $(
                                '#input__item-sex-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $(
                                '#input__item-category-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $(
                                '#input__item-age-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                            ).removeClass('input__error')
                            $('#name-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text('')
                            $('#age-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text('')
                            // お名前
                            if (
                                isNull(roomList.value![i].passenger_detail_array[j].family_name_katakana) ||
                                isNull(roomList.value![i].passenger_detail_array[j].given_name_katakana)
                            ) {
                                $(
                                    '#input__item-fullname-' +
                                        roomList.value![i].line_no +
                                        '-' +
                                        roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                $('#name-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                    'お名前を入力してください',
                                )
                                err_count++
                            } else if (
                                !isZenKatakana(roomList.value![i].passenger_detail_array[j].family_name_katakana) ||
                                !isZenKatakana(roomList.value![i].passenger_detail_array[j].given_name_katakana)
                            ) {
                                $(
                                    '#input__item-fullname-' +
                                        roomList.value![i].line_no +
                                        '-' +
                                        roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                $('#name-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                    'お名前は全角カタカナで入力してください',
                                )
                                err_count++
                            }
                            // 性別
                            if (isNull(roomList.value![i].passenger_detail_array[j].gender)) {
                                $(
                                    '#input__item-sex-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                err_count++
                            }
                            // 区分
                            if (isNull(roomList.value![i].passenger_detail_array[j].passenger_type)) {
                                $(
                                    '#input__item-category-' +
                                        roomList.value![i].line_no +
                                        '-' +
                                        roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                err_count++
                            }
                            // 乗船年齢
                            if (isNull(roomList.value![i].passenger_detail_array[j].age)) {
                                $(
                                    '#input__item-age-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                ).addClass('input__error')
                                $('#age-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                    '乗船年齢を入力してください',
                                )
                                err_count++
                            } else {
                                const age_range_error = checkPassengerTypeAgeRange(
                                    roomList.value![i].passenger_detail_array[j].passenger_type,
                                    roomList.value![i].passenger_detail_array[j].age,
                                )
                                if (!isNull(age_range_error)) {
                                    $(
                                        '#input__item-age-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no,
                                    ).addClass('input__error')
                                    $('#age-error-' + roomList.value![i].line_no + '-' + roomList.value![i].passenger_detail_array[j].line_no).text(
                                        age_range_error,
                                    )
                                    err_messages.push(age_range_error)
                                    err_count++
                                }
                            }
                        }
                    }
                }

                if (
                    seat_type.value != undefined &&
                    seat_type.value < SEAT_TYPE_D &&
                    room_info.min_count > adult_cnt + child_cnt &&
                    (adult_cnt + child_cnt > 0 || !props.isUpdate)
                ) {
                    err_count++
                    let message_count = `${getSeatTypeName(seat_type.value)}のご予約は1部屋${room_info.min_count}名からとなります。`
                    err_messages.push(message_count)
                }
            }

            return [err_count, err_messages]
        }
        // 入力チェック（成約制限）
        const chkValidationContract = (): string[] => {
            let err_messages = [] as string[]
            let now = new Date()

            // 都道府県制限
            if (!isNull(contract.value!.prefectures_restrict)) {
                if (!contract.value!.prefectures_restrict.split(',').includes(represent_passenger_prefectures.value)) {
                    // 都道府県制限
                    err_messages.push(
                        `こちらのプランは${convertTexts(PREFECTURES, contract.value!.prefectures_restrict)}の方のみご予約可能となっております`,
                    )
                }
            }

            // 予約年齢制限使用区分
            // 最小予約年齢
            // 最大予約年齢
            if (!isNull(contract.value!.use_booking_age_limit) && contract.value!.use_booking_age_limit) {
                for (let i = 0; i < roomList.value!.length; i++) {
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        if (
                            roomList.value![i].passenger_detail_array[j].deleted != true &&
                            !isNull(roomList.value![i].passenger_detail_array[j].age)
                        ) {
                            if (roomList.value![i].passenger_detail_array[j].age! < contract.value!.booking_age_limit_min) {
                                err_messages.push(`こちらのプランは${contract.value!.booking_age_limit_min}才以上の方のみご予約可能です`)
                                break
                            }
                        }
                    }
                }
                for (let i = 0; i < roomList.value!.length; i++) {
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        if (
                            roomList.value![i].passenger_detail_array[j].deleted != true &&
                            !isNull(roomList.value![i].passenger_detail_array[j].age)
                        ) {
                            if (roomList.value![i].passenger_detail_array[j].age! > contract.value!.booking_age_limit_max) {
                                err_messages.push(`こちらのプランは${contract.value!.booking_age_limit_max}才以下の方のみご予約可能です`)
                                break
                            }
                        }
                    }
                }
            }
            // 性別制限
            if (!isNull(contract.value!.gender_restrict) && contract.value!.gender_restrict != GENDER_RESTRICT_NONE) {
                for (let i = 0; i < roomList.value!.length; i++) {
                    for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                        if (
                            roomList.value![i].passenger_detail_array[j].deleted != true &&
                            !isNull(roomList.value![i].passenger_detail_array[j].gender)
                        ) {
                            if (
                                contract.value!.gender_restrict == GENDER_RESTRICT_MALE &&
                                roomList.value![i].passenger_detail_array[j].gender != GENDER_MALE
                            ) {
                                // 男性のみ
                                err_messages.push(`こちらのプランは男性のみご予約可能となっております`)
                                break
                            } else if (
                                contract.value!.gender_restrict == GENDER_RESTRICT_FEMALE &&
                                roomList.value![i].passenger_detail_array[j].gender != GENDER_FEMALE
                            ) {
                                // 女性のみ
                                err_messages.push(`こちらのプランは女性のみご予約可能となっております`)
                                break
                            }
                        }
                    }
                }
            }

            if (props.isUpdate) {
                // 更新時のみチェック
            }

            return err_messages
        }
        // 貸切料チェック
        const checkReserved = (): boolean => {
            for (let i = 0; i < roomList.value!.length; i++) {
                for (let j = 0; j < roomList.value![i].passenger_detail_array.length; j++) {
                    if (roomList.value![i].passenger_detail_array[j].deleted == true) {
                        continue
                    }

                    if (roomList.value![i].passenger_detail_array[j].reserved_amount == undefined) {
                        continue
                    } else if (roomList.value![i].passenger_detail_array[j].reserved_amount == null) {
                        continue
                    } else if (roomList.value![i].passenger_detail_array[j].reserved_amount == 0) {
                        continue
                    } else {
                        return true
                    }
                }
            }
            return false
        }

        onMounted(() => {
            companionList.value = getCompanionData()
        })

        return {
            room_count,
            roomList,
            companionList,
            personality_type,
            corporate_name,
            represent_passenger_post_code,
            represent_passenger_prefectures,
            represent_passenger_address,
            represent_passenger_tel,
            represent_passenger_tel01,
            represent_passenger_tel02,
            represent_passenger_tel03,

                init,
                getData,
                confirmationDeletePassenger,
                deletePassenger,
                addPassenger,
                setLoginMember,
                changeCompanionId,
                getPassengerCount,
                getDriverCount,
                getPassengerData,
                getPersonalityType,
                getCorporateName,
                getRepresentPassengerName,
                getRepresentPassengerPostCode,
                getRepresentPassengerPrefectures,
                getRepresentPassengerAddress,
                getRepresentPassengerTel,
                setAddressFromPostCode,
                setDriver,
                commitPassengerList,
                calcPrice,
                calcPriceAll,
                getTotalPrice,
                chkValidation,
                chkValidationContract,
                checkReserved,

            joinFirstNameAndLastName,
        }
    },
    emits: ['commitPassengerList', 'displayMessage'],
    name: 'RoomList',
    data() {
        return {
            PERSONALITY_TYPE_INDIVIDUAL: PERSONALITY_TYPE_INDIVIDUAL as number,
            PERSONALITY_TYPE_CORPORATION: PERSONALITY_TYPE_CORPORATION as number,

            PASSENGER_TYPE_ADULT: PASSENGER_TYPE_ADULT as number,
            PASSENGER_TYPE_CHILD: PASSENGER_TYPE_CHILD as number,
            PASSENGER_TYPE_INFANT: PASSENGER_TYPE_INFANT as number,
            PASSENGER_TYPE_BABY: PASSENGER_TYPE_BABY as number,

            PREFECTURES: PREFECTURES,
            GENDER_MALE: GENDER_MALE as number,
            GENDER_FEMALE: GENDER_FEMALE as number,
        }
    },

    components: {},
    props: {
        dep_date: String,
        sea_route_code: String,
        contract_number: String,
        isUpdate: Boolean,
    },
    methods: {},
})
</script>

<style scoped></style>
