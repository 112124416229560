<template>
    <main id="main" class="main">
        <!-- form -->
        <section class="form form-top">
            <div class="form__header">
                <h2 class="form__name">メールアドレス変更</h2>
            </div>
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <!-- メールアドレス登録 -->
                    <li id="form__item" class="form__item">
                        <div class="form__item-container">
                            <div class="input">
                                <dl class="input__list">
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- dispmail -->
                                        <div id="input__item-dispmail" class="input__item input__item-dispmail">
                                            <dt id="input__header-dispmail" class="input__header input__header-dispmail">
                                                <label id="input__name-dispmail" class="input__name input__name-dispmail" for=""
                                                    >現在のメールアドレス</label
                                                >
                                            </dt>
                                            <dd id="input__container-dispmail" class="input__container input__container-dispmail">
                                                <div class="output__form output__dispmail">
                                                    {{ current_email }}
                                                </div>
                                            </dd>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- mail -->
                                        <div id="input__item-mail" class="input__item input__item-mail">
                                            <dt id="input__header-mail" class="input__header input__header-mail">
                                                <label id="input__name-mail" class="input__name input__name-mail" for="">新しいメールアドレス</label>
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-mail" class="input__container input__container-mail">
                                                <input
                                                    id="input__mail"
                                                    class="input__form input__mail"
                                                    type="text"
                                                    name="input__mail"
                                                    v-model="mail_new"
                                                />
                                            </dd>
                                            <p class="input__error-text">{{ error_message }}</p>
                                        </div>
                                        <!-- mail-confirm -->
                                        <div id="input__item-mail-confirm" class="input__item input__item-mail-confirm">
                                            <dt id="input__header-mail-confirm" class="input__header input__header-mail-confirm">
                                                <label id="input__name-mail-confirm" class="input__name input__name-mail-confirm" for=""
                                                    >新しいメールアドレス（確認用）</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-mail-confirm" class="input__container input__container-mail-confirm">
                                                <input
                                                    id="input__mail-confirm"
                                                    class="input__form input__mail-confirm"
                                                    type="text"
                                                    name="input__mail-confirm"
                                                    v-model="mail_confirm"
                                                />
                                            </dd>
                                            <p class="input__error-text">{{ error_confirm }}</p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line">
                                        <!-- confirm -->
                                        <div id="input__item-confirm" class="input__item input__item-confirm">
                                            <dd id="input__container-confirm" class="input__container input__container-confirm">
                                                <div id="input__note-confirm" class="input__note input__note-confirm">
                                                    <div class="note">
                                                        <p class="note__text">
                                                            申請を行うと、入力メールアドレス宛に確認メールが送信されます。送信されたメールに記載された確認用URLにアクセスすることでメールアドレスの変更が完了します。
                                                        </p>
                                                    </div>
                                                </div>
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
                <button class="btn btn-next" type="button" v-on:click="changeMail">確認メールを送信する</button>
            </div>
        </section>
        <!-- /form -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import $ from 'jquery'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { API_USERS, API_ORDER, isNull } from '@/lib/util'
import { isMail } from '@/lib/validation_util'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        const current_email = ref('')
        const mail_new = ref('')
        const mail_confirm = ref('')
        const error_message = ref('')
        const error_confirm = ref('')

        // マイページへ
        const moveMyPage = () => {
            router.push('/member/my_page')
        }
        // 会員情報取得
        const getMemberData = (): void => {
            console.log('getMemberData')
            apiClient
                .get(`${API_USERS}/member`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    current_email.value = data.email
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
        }
        // メールアドレス変更
        const changeMail = async () => {
            if (chkValidation()) {
                store.dispatch('setIsProcessing', true)
                const postData = {
                    email: mail_new.value,
                }
                let data = await apiClient
                    .patch(`${API_USERS}/member/email/confirm`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        store.dispatch('setIsProcessing', false)
                        context.emit('displayMessage', {
                            msg_title: 'メールアドレス変更',
                            msg: [
                                'メールアドレス変更確認メールを配信しました。',
                                '送信された確認用URLにアクセスしてメールアドレスの変更を完了してください。',
                            ],
                            close_btn: true,
                        })
                        moveMyPage()
                        return data
                    })
                    .catch((e: AxiosError) => {
                        store.dispatch('setIsProcessing', false)
                        let message = 'メールアドレス確認メールの送信に失敗しました。'
                        // エラー処理
                        if (e.response?.data !== undefined) {
                            if (e.response?.data == 'This email address cannot be used.' || e.response?.data == 'email already in use.') {
                                message = '入力されたメールアドレスでは変更ができません。'
                            }
                        } else {
                            console.log(e.message)
                        }
                        context.emit('displayMessage', {
                            msg_title: 'メールアドレス変更',
                            msg: [message],
                            close_btn: true,
                        })
                    })
            }
        }
        // 入力チェック
        const chkValidation = (): boolean => {
            let err_count = 0
            // エラー表示初期化
            $('#input__item-mail').removeClass('input__error')
            $('#input__item-mail-confirm').removeClass('input__error')

            // 新しいメールアドレス
            if (isNull(mail_new.value)) {
                $('#input__item-mail').addClass('input__error')
                error_message.value = '新しいメールアドレスを入力してください'
                err_count++
            } else {
                if (!isMail(mail_new.value)) {
                    $('#input__item-mail').addClass('input__error')
                    error_message.value = '新しいメールアドレスの形式が正しくありません'
                    err_count++
                }
            }
            // 新しいメールアドレス（確認用）
            if (isNull(mail_confirm.value) || mail_new.value != mail_confirm.value) {
                $('#input__item-mail-confirm').addClass('input__error')
                error_confirm.value = '新しいメールアドレス（確認用）を入力してください'
                err_count++
            } else {
                if (!isMail(mail_confirm.value)) {
                    $('#input__item-mail-confirm').addClass('input__error')
                    error_confirm.value = '新しいメールアドレス（確認用）の形式が正しくありません'
                    err_count++
                }
            }

            if (!isNull(mail_new.value) && current_email.value == mail_new.value) {
                $('#input__item-mail').addClass('input__error')
                error_message.value = '新しいメールアドレスには現在のメールアドレスと異なるものを入力してください'
                err_count++
            }

            if (!isNull(mail_new.value) && !isNull(mail_confirm.value) && mail_new.value != mail_confirm.value) {
                error_confirm.value = '新しいメールアドレスと確認用のアドレスは同じものを入力してください'
                err_count++
            }

            return err_count == 0
        }

        onMounted(() => {
            console.log('↓↓↓ChangeMail.onMounted↓↓↓')
            getMemberData()
            console.log('↑↑↑ChangeMail..onMounted↑↑↑')
        })

        return {
            current_email,
            mail_new,
            mail_confirm,
            error_message,
            error_confirm,

            moveMyPage,
            changeMail,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'ChangeMail',
    data() {
        return {}
    },
    components: {
        ShipAnimationEnd,
    },
    methods: {},
})
</script>
<style scoped></style>
