/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-control-regex */

// 数字
export function isNumeric(v: string): boolean {
    return v == null || v === '' || /^[0-9]*$/.test(v)
}
// 数字or英字
export function isNumericOrAlphabetic(v: string): boolean {
    return v == null || v === '' || /^[0-9a-zA-Z]*$/g.test(v)
}
// メール形式
export function isMail(v: string): boolean {
    return (
        v == null ||
        v === '' ||
        /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/.test(
            v,
        )
    )
}
// 会員ＩＤ形式
export function isMemberId(v: string): boolean {
    return v == null || v === '' || /^(?=.*\d)(?=.*[a-z])[0-9a-z]{6,20}$/.test(v)
}
// パスワード形式
export function isPassword(v: string): boolean {
    return v == null || v === '' || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!?_+*'"#$%&\-^\\@;:,./=|[\](){}<>]{8,20}$/.test(v)
}
export function isPasswordLonger(v: string): boolean {
    return v != null && v !== '' && v.length > 20
}
export function isPasswordShorter(v: string): boolean {
    return v == null || v === '' || v.length < 8
}
export function isPasswordCombination(v: string): boolean {
    return v != null && v !== '' && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]$/.test(v)
}
// 年月日形式
export function isDate(v: string): boolean {
    return /^\d{4}\/\d{1,2}\/\d{1,2}$/.test(v)
}
// レート形式
export function isRate(v: string): boolean {
    return /\d\.\d{3}/.test(v)
}
// 金額形式
export function isAmount(v: string): boolean {
    const digits = 9
    // 数字でない
    if (/\d+/.test(v) === false) {
        return false
    }
    // 桁数オーバー
    if (String(v).length > digits) {
        return false
    }
    return true
}
// 全角チェック
export function isFullWidth(v: string) {
    return !/[\x01-\x7E\uFF65-\uFF9F]/.test(v)
}
// 全角カタカナチェック
export function isZenKatakana(v: string) {
    v = v == null ? '' : v
    if (/^[ァ-ヶー　]+$/.test(v)) {
        //"ー"の後ろの文字は全角スペースです。
        return true
    } else {
        return false
    }
}
// 半角チェック
export function isHalfWidth(v: string) {
    return !v.match(/[^\x01-\x7E]/) || !v.match(/[^\uFF65-\uFF9F]/)
}
// 半角カタカナチェック
export function isHankakuKana(v: string) {
    return !v.match(/[^\uFF65-\uFF9F]/)
}
// 郵便番号
export function isPostCode(v: string): boolean {
    return /^\d{3}-?\d{4}$/.test(v)
}
// クレジットカード名義
export function isCardName(v: string) {
    return v.match(/^[A-Za-z0-9 ,.\-/]+$/g)
}
