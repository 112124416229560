<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">宮崎カーフェリー&emsp;WEB予約会員規約</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- form -->
        <section class="form">
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <!-- <li id="form__item" class="form__item"> -->
                    <div class="form__item-container">
                        <div class="input">
                            <dl class="input__list">
                                <!-- line -->
                                <div class="input__line input__line-confirm">
                                    <!-- confirm -->
                                    <div id="input__item-confirm" class="input__item input__item-confirm">
                                        <dt id="input__header-confirm" class="input__header input__header-confirm">
                                            <label id="input__name-confirm" class="input__name input__name-confirm"
                                                >宮崎カーフェリー&emsp;WEB予約会員規約</label
                                            >
                                        </dt>
                                        <dd id="input__container-confirm" class="input__container input__container-confirm">
                                            <div id="input__note-confirm" class="input__note input__note-confirm">
                                                <div class="note">
                                                    <p class="note__text">
                                                        いつも宮崎カーフェリーをご利用頂きましてありがとうございます。<br />
                                                        宮崎カーフェリー株式会社がお客様に対し、インターネット上で予約サービスを提供するに当たって、以下の規約を制定しております。<br />
                                                        本サービスをご利用なさることによりお客様は本規約に同意したものと見なされますので、ご了解頂いた上でご登録およびご利用頂きますようお願い申し上げます。
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第１条（定義）</h4>
                                                    <p class="note__text">本規約において、以下の用語は以下に定める意味を有するものとします。</p>
                                                    <ol class="rows">
                                                        <li class="note__text">
                                                            「宮崎カーフェリー&emsp;WEB予約会員（以下、会員）」とは、本規約に同意の上、所定の方法により会員登録をした個人をいいます。
                                                        </li>
                                                        <li class="note__text">
                                                            「サービス」とは、当社が保持し管理を行うドメイン「miyazakicarferry.com」上のウェブサイト（以下、当社サイト）において提供される会員向けの各種サービスをいいます。
                                                        </li>
                                                        <li class="note__text">
                                                            「本規約等」とは、本規約その他当社が 定める規約、ガイドライン等の総称をいいます。
                                                        </li>
                                                        <li class="note__text">
                                                            「会員情報」とは、会員の取引に関する履歴等の情報（第９条により収集された情報を含みます）をいいます。
                                                        </li>
                                                    </ol>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第２条（会員）</h4>
                                                    <ol class="rows">
                                                        <li class="note__text">
                                                            会員は、当社が定める条件に従って、当社サイトにおいて各種会員向けサービスを利用することができます。
                                                        </li>
                                                        <li class="note__text">
                                                            会員は、会員としての地位を譲渡、転貸、担保差入その他形態を問わず処分することはできません。
                                                        </li>
                                                    </ol>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第３条（本規約）</h4>
                                                    <p class="note__text">
                                                        本規約は、すべての会員に適用され、登録手続時および登録後にお守りいただく規約です。
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第４条（規約への同意）</h4>
                                                    <p class="note__text">
                                                        会員は各サービスの利用をすることにより、当規約に合意および承諾したものとします。
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第５条（会員登録手続）</h4>
                                                    <ol class="rows">
                                                        <li class="note__text">
                                                            本規約に同意のうえ所定の登録手続完了後に会員としての資格を有します。会員登録手続は、会員となるご本人が行ってください。代理による登録は一切認められません。なお、過去に会員資格が取り消された方やその他当社が相応しくないと判断した方からの登録についてはお断りする場合があります。
                                                        </li>
                                                        <li class="note__text">
                                                            会員登録手続の際には、入力上の注意をよく読み、所定の入力フォームに必要事項を正確に入力してください。
                                                        </li>
                                                    </ol>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第６条（IDおよびパスワードの管理）</h4>
                                                    <p class="note__text">
                                                        サービスを利用するためのIDとパスワードは、会員本人が責任をもって管理してください。また、他人に使用されることがないようパスワードは定期的に変更する等の対策を行ってください。入力されたIDおよびパスワードが登録されたものと一致することを所定の方法により確認した場合、会員による利用があったものとみなし、それらが盗用、不正使用その他の事情により会員以外の者が利用している場合であっても、それにより生じた損害について当社は一切責任を負いません。
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第７条（登録情報の変更）</h4>
                                                    <p class="note__text">
                                                        登録した情報に変更が生じた場合は、速やかに変更登録をお願いいたします。変更登録がなされなかったことにより生じた損害について、当社は一切責任を負いません。また、変更登録がなされた場合でも、変更登録前にすでに手続がなされた取引は、変更登録前の情報に基づいて行われることがあります。
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第８条（当社からのお知らせ）</h4>
                                                    <ol class="rows">
                                                        <li class="note__text">
                                                            会員サービスに関する当社から会員への通知等は、最新の登録情報におけるメールアドレス宛てへのメールの送信、当社が運営するウェブサイト上への掲示、または、その他当社が適当と判断する方法によって行います。
                                                        </li>
                                                        <li class="note__text">
                                                            会員は、当社がメールの送信による通知を行った場合、前項のメールアドレス宛てに当社がメールを送信し会員が当該メールを閲覧可能になった時または当社がメールを送信してから６時間後のいずれか早い時点に当社からの通知が到達したとみなされることに、あらかじめ同意し、前条の登録情報の変更を行うものとします。
                                                        </li>
                                                    </ol>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第９条（当社でのデータ利用）</h4>
                                                    <ol class="rows">
                                                        <li class="note__text">
                                                            会員は、当社が会員個人を特定しない形で閲覧履歴や利用履歴（予約便、決済方法、決済金額等）を集計し、当社の事業のためおよびサービス向上のためのマーケティングデータとして使用することに承諾するものとします。
                                                        </li>
                                                        <li class="note__text">
                                                            会員は、当社が会員の閲覧履歴や利用履歴（予約便、決済方法、決済金額等）を元に当社からのご案内が提供されることを承諾するものとします。ただし、ご案内については当社サイトまたは当社へご連絡頂くことで受け取りを停止することが出来ます
                                                        </li>
                                                        <li class="note__text">
                                                            当社は、前項にかかわらず、運行状況や会員全体にかかわると当社が判断した情報については、送信いたします。
                                                        </li>
                                                    </ol>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第10条（会員の退会）</h4>
                                                    <p class="note__text">
                                                        会員が退会を希望する場合には、当社サイトから退会処理を行ってください。所定の退会手続の終了後に退会となります。
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第11条（禁止事項）</h4>
                                                    <p class="note__text">
                                                        本サービスの利用に際して、会員に対し次の各号の行為またはそのおそれのある行為を行うことを禁止します。
                                                    </p>
                                                    <ol class="rows">
                                                        <li class="note__text">
                                                            法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為
                                                        </li>
                                                        <li class="note__text">公の秩序または善良の風俗を害する行為</li>
                                                        <li class="note__text">反社会的勢力に対する利益供与その他の協力行為</li>
                                                        <li class="note__text">当社、当社関連会社、または第三者の権利、利益、名誉等を侵害する行為</li>
                                                        <li class="note__text">第三者になりすます行為または意図的に虚偽の情報を送信する行為</li>
                                                        <li class="note__text">
                                                            第三者の個人情報その他のプライバシーに関する情報を不正に収集、開示、または提供する行為
                                                        </li>
                                                        <li class="note__text">
                                                            不正アクセス行為、第三者のアカウントを利用する行為、複数のアカウントを作成しまたは保有する行為、その他これらに類する行為
                                                        </li>
                                                        <li class="note__text">
                                                            IDおよびパスワードを第三者に貸与・譲渡すること、または第三者と共用すること
                                                        </li>
                                                        <li class="note__text">会員サービスの誤作動を誘引する行為</li>
                                                        <li class="note__text">
                                                            会員サービスの通常意図しないバグを利用する動作、その他通常意図しない効果を及ぼす外部ツールの利用・作成・頒布を行う行為
                                                        </li>
                                                        <li class="note__text">会員サービスまたは当社のサーバーに過度の負担をかける行為</li>
                                                        <li class="note__text">
                                                            当社および当社関連会社のサーバその他のコンピュータに不正にアクセスすること
                                                        </li>
                                                        <li class="note__text">
                                                            コンピュータウイルス等の有害なプログラムを当社、当社関連会社または第三者に送信し、または流布する行為
                                                        </li>
                                                        <li class="note__text">本規約に違反し、または、会員サービスの趣旨目的に反する行為</li>
                                                        <li class="note__text">
                                                            当社、当社関連会社およびその委託先の従業員等（派遣社員を含む）に対する暴言や威嚇その他その安全や精神衛生等を害する行為
                                                        </li>
                                                        <li class="note__text">
                                                            その他、手段の如何を問わず、社会通念に照らして著しく不相当な行為であって、会員サービスの運営を妨害するものであると当社が判断する行為
                                                        </li>
                                                    </ol>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第12条（クッキー等について）</h4>
                                                    <ol class="rows">
                                                        <li class="note__text">
                                                            当社は、会員としてサイトにアクセスしたことを認証するため、会員のアクセス履歴および利用状況の調査のため、その他会員に最適のサービスを提供するために、会員が当社のサイトにアクセスする際のIPアドレスに関する情報、携帯電話端末でアクセスした場合には携帯端末の機体識別番号に関する情報、およびクッキー（cookie）の技術を必要に応じて使用して会員のアクセス履歴等に関する情報を収集します。
                                                        </li>
                                                        <li class="note__text">
                                                            会員が会員としてサービスを利用するためには、前項を承諾し、クッキーを受け付けることが条件となります。したがって、ブラウザでクッキーを拒否するための設定を行った場合、会員としての各サービスの利用ができませんのであらかじめご了承願います。
                                                        </li>
                                                    </ol>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第13条（会員情報の取扱い）</h4>
                                                    <p class="note__text">会員の個人情報は、当社のプライバシーポリシーに従い取扱います。</p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第14条（サービスの中断・停止等）</h4>
                                                    <p class="note__text">
                                                        当社は、サービスを常に良好な状態でご利用いただくために、システムの定期保守
                                                        や緊急保守を行う場合、システムに負荷が集中した場合、サービスの運営に支障が生じると当社が判断した場合、会員のセキュリティを確保する必要が生じた場合、その他必要があると判断した場合には、事前に通知することなく、サービスの全部または一部の提供を中断または停止する等の必要な措置を取ること
                                                        ができるものとします。この場合に会員に生じた損害について、当社は一切責任を負わないものとします。
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第15条（免責）</h4>
                                                    <p class="note__text">
                                                        通信回線やコンピュータなどの障害によるシステムの中断・遅滞・中止・データの消失、経由アクセス識別に関する障害、データへの不正アクセスにより生じた損害、その他本サービスに関して会員に生じた損害について、当社は一切責任を負わないものとします。
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第16条（特定会員の利用停止・会員資格取消）</h4>
                                                    <ol class="rows">
                                                        <li class="note__text">
                                                            当社は、特定の会員が次の各号に該当すると判断した場合には、事前に通知することなく当該会員によるサービスの利用停止、当該会員
                                                            のパスワードの変更、または当該会員の会員資格の取消しを行うことができるものとします。これにより会員に何らかの損害が生じたとしても、当社は一切責任を負わないものとします。
                                                            <ul class="listno">
                                                                <span class="note__text">&emsp;・会員に法令や本規約等に違反する行為があった場合</span>
                                                                <span class="note__text">&emsp;・会員にサービス利用に関して不正行為があった場合</span>
                                                                <span class="note__text"
                                                                    >&emsp;・一定回数以上のパスワードの入力ミスがあるなど会員のセキュリティを確保するために必要な場合</span
                                                                >
                                                                <span class="note__text"
                                                                    >&emsp;・反社会的勢力またはそれに類似すると当社が認識した場合</span
                                                                >
                                                                <span class="note__text">&emsp;・その他当社が適当と判断した場合</span>
                                                            </ul>
                                                        </li>
                                                        <li class="note__text">
                                                            前項のほか、会員が当社の定める一定の期間内に一定回数のログインを行わなかった場合は、当社は、事前に通知することなく当該会員のパスワードの変更または会員資格の取消しを行うことができるものとします。
                                                        </li>
                                                    </ol>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第17条（サービスの変更・廃止）</h4>
                                                    <p class="note__text">
                                                        当社は、その判断によりサービスの全部または一部を適宜変更・廃止できるものとします。
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第18条（本規約の改定）</h4>
                                                    <p class="note__text">
                                                        当社は、本規約を任意に改定できるものとし、また、当社において本規約を補充する規約（以下「補充規約」といいま
                                                        す）を定めることができます。本規約の改定または補充は、改定後の本規約または補充規約を当社所定のサイトに掲示したときにその効力を生じるものとします。この場合、会員
                                                        は、改定後の規約および補充規約に従うものといたします。
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="note">
                                                    <h4 class="note__name">第19条（準拠法、合意管轄）</h4>
                                                    <p class="note__text">
                                                        本規約は日本法に基づき解釈されるものとし、本規約に関し訴訟の必要が生じた場合には、宮崎地方裁判所を第一審の専属的合意管轄裁判所といたします。<br />
                                                    </p>
                                                </div>
                                                <br />
                                                <div class="membership_terms_signature">
                                                    <p>
                                                        以上<br />
                                                        <span>2023年7月10日</span>
                                                        <span>宮崎カーフェリー株式会社</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <!-- </li> -->
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" type="button" v-on:click="closeThis">閉じる</button>
            </div>
        </section>
        <!-- /input -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { removeMemberData } from '@/lib/localStorageUtil'
import { API_USERS, API_ORDER } from '@/lib/util'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()

        // マイページへ
        const moveMyPage = () => {
            router.push('/member/my_page')
        }
        const closeThis = () => {
            window.close()
        }

        return {
            moveMyPage,
            closeThis,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'MembershipTerms',
    data() {
        return {}
    },
    components: {
        ShipAnimationEnd,
    },
    methods: {},
})
</script>

<style scoped>
.membership_terms_signature {
    text-align: right;
}
.rows {
    counter-reset: row_no;
}
li::before {
    counter-increment: row_no;
    content: counter(row_no) '. ';
}
span {
    display: block;
}

ol {
    padding-inline-start: 20px;
}
</style>
