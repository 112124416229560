<template>
    <div class="waving"></div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ShipAnimationEnd',
    components: {},
    setup() {
        return {}
    },
    computed: {},
    data() {
        return {}
    },
    methods: {},
})
</script>

<style scoped></style>
