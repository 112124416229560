import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { store } from '@/store'
import { setHistoryDetailData } from '@/lib/localStorageUtil'
import Login from '@/components/member/Login/Login.vue'
import IdNotification from '@/components/member/Login/MemberIdNotification.vue'
import PasswordResetting from '@/components/member/Login/ResetPassword.vue'
import MemberRegistration from '@/components/member/MemberRegistration/MemberRegistration.vue'
import MemberRegistrationComplete from '@/components/member/MemberRegistration/MemberRegistrationComplete.vue'
import ProvisionalMemberRegistration from '@/components/member/MemberRegistration/ProvisionalMemberRegistration.vue'
import MyPage from '@/components/member/MyPage/MyPage.vue'
import Companion from '@/components/member/Companion/Companion.vue'
import Vehicle from '@/components/member/Vehicle/Vehicle.vue'
import ConfirmationVacancy from '@/components/booking/ConfirmationVacancy/ConfirmationVacancy.vue'
import PlanDetail from '@/components/booking/PlanDetail/PlanDetail.vue'
import EditBooking from '@/components/booking/RegistrationBooking/EditBooking.vue'
import ConfirmationBooking from '@/components/booking/RegistrationBooking/ConfirmationBooking.vue'
import SettlementEntry from '@/components/booking/RegistrationBooking/SettlementEntry.vue'
import BookingComplete from '@/components/booking/RegistrationBooking/BookingComplete.vue'
import BookingCurrent from '@/components/booking/BookingCurrent/BookingCurrent.vue'
import BookingHistory from '@/components/booking/BookingHistory/BookingHistory.vue'
import PrintReceipt from '@/components/booking/BookingHistory/PrintReceipt.vue'
import ChangePassWord from '@/components/member/ChangePassword/ChangePassword.vue'
import ChangeMail from '@/components/member/ChangeMail/ChangeMail.vue'
import ChangeMailComplete from '@/components/member/ChangeMail/ChangeMailComplete.vue'
import MailSetting from '@/components/member/MailSetting/MailSetting.vue'
import ChangeOtherMailComplete from '@/components/member/MailSetting/ChangeOtherMailComplete.vue'
import ResetPassword from '@/components/member/Login/ResettingPassword.vue'
import MembershipTerms from '@/components/member/MembershipTerms/MembershipTerms.vue'
import PrivacyPolicy from '@/components/member/PrivacyPolicy/PrivacyPolicy.vue'
import Withdrawal from '@/components/member/MemberRegistration/Withdrawal.vue'
import WithdrawalComplete from '@/components/member/MemberRegistration/WithdrawalComplete.vue'
import Credit3dsAuthenticated from '@/components/booking/RegistrationBooking/Credit3dsAuthenticated.vue'
import SpecifiedCommercialTransactionAct from '@/components/member/SpecifiedCommercialTransactionAct/SpecifiedCommercialTransactionAct.vue'
import Logout from '@/components/member/Logout/Logout.vue'
import { HistoryDetail } from '@/lib/types'

const routes: Array<RouteRecordRaw> = [
    {
        // 空席照会
        path: '/',
        name: 'TopPage',
        component: ConfirmationVacancy,
    },
    {
        // ログイン
        path: '/member/login',
        name: 'Login',
        component: Login,
    },
    {
        // 利用者IDの通知
        path: '/member/id_notification',
        name: 'IdNotification',
        component: IdNotification,
    },
    {
        // パスワードの再設定
        path: '/member/password_resetting',
        name: 'PasswordResetting',
        component: PasswordResetting,
    },
    {
        // 会員情報更新
        path: '/member/member_update',
        name: 'MemberUpdate',
        component: MemberRegistration,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // 会員情報入力(新規登録)
        path: '/member/member_registration/:user_key',
        name: 'MemberRegistration',
        component: MemberRegistration,
    },
    {
        // 会員登録完了
        path: '/member/member_registration_complete',
        name: 'MemberRegistrationComplete',
        component: MemberRegistrationComplete,
    },
    {
        // 仮会員登録
        path: '/member/provisional_member_registration',
        name: 'ProvisionalMemberRegistration',
        component: ProvisionalMemberRegistration,
    },
    {
        // 会員規約
        path: '/member/membership_terms',
        name: 'MembershipTerms',
        component: MembershipTerms,
    },
    {
        // プライバシーポリシー
        path: '/member/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        // 退会
        path: '/member/withdrawal',
        name: 'Withdrawal',
        component: Withdrawal,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // 退会完了
        path: '/member/withdrawal_complete',
        name: 'WithdrawalComplete',
        component: WithdrawalComplete,
    },
    {
        // パスワード変更
        path: '/member/change_pass',
        name: 'ChangePassWord',
        component: ChangePassWord,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // パスワード再設定
        path: '/member/reset_pass/:user_key',
        name: 'ResetPassword',
        component: ResetPassword,
    },
    {
        // メールアドレス変更
        path: '/member/change_email',
        name: 'ChangeMail',
        component: ChangeMail,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // メールアドレス変更完了
        path: '/member/email/:user_key',
        name: 'ChangeMailComplete',
        component: ChangeMailComplete,
    },
    {
        // その他メールアドレス変更完了
        path: '/member/other_emails/change/:user_key',
        name: 'ChangeOtherMailComplete',
        component: ChangeOtherMailComplete,
    },
    {
        // メール設定
        path: '/member/email_setting',
        name: 'MailSetting',
        component: MailSetting,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // マイページ
        path: '/member/my_page',
        name: 'MyPage',
        component: MyPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // 同乗者情報変更
        path: '/member/companion',
        name: 'Companion',
        component: Companion,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // 車両情報変更
        path: '/member/vehicle',
        name: 'Vehicle',
        component: Vehicle,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // 空席照会
        path: '/booking/confirmation_vacancy',
        name: 'ConfirmationVacancy',
        component: ConfirmationVacancy,
    },
    {
        // プラン詳細
        path: '/booking/plan_detail',
        name: 'PlanDetail',
        component: PlanDetail,
    },
    {
        // 予約入力
        path: '/booking/registration',
        name: 'EditBooking',
        component: EditBooking,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // 予約確認
        path: '/booking/confirmation_booking',
        name: 'ConfirmationBooking',
        component: ConfirmationBooking,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // 決済入力
        path: '/booking/settlement_entry',
        name: 'SettlementEntry',
        component: SettlementEntry,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // 予約完了
        path: '/booking/complete',
        name: 'BookingComplete',
        component: BookingComplete,
    },
    {
        // 現在の予約
        path: '/booking/current',
        name: 'BookingCurrent',
        component: BookingCurrent,
        meta: {
            requiresAuth: true,
        },
    },
    {
        // 予約履歴
        path: '/booking/history',
        name: 'BookingHistory',
        component: BookingHistory,
    },
    {
        // 領収書印刷
        path: '/booking/print_receipt',
        name: 'PrintReceipt',
        component: PrintReceipt,
    },
    {
        // 3DS認証後呼び出し
        path: '/registration/credit/3ds/:payment_key',
        name: 'Credit3dsAuthenticated',
        component: Credit3dsAuthenticated,
    },
    {
        // 特定商取引法に基づく表記
        path: '/member/law',
        name: 'Law',
        component: SpecifiedCommercialTransactionAct,
    },
    {
        // ログアウト
        path: '/member/logout',
        name: 'Logout',
        component: Logout,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to, from, next) => {
    // リダイレクトURLの初期化
    const history_detail = { redirect_url: null } as HistoryDetail
    setHistoryDetailData(history_detail)

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.state.is_logged_in) {
            next({
                path: '/member/logout',
                query: {
                    redirect: to.fullPath,
                },
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
