<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">プライバシーポリシー</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- form -->
        <section class="form">
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <li id="form__item" class="form__item">
                        <div class="form__item-container">
                            <div class="input">
                                <dl class="input__list">
                                    <!-- line -->
                                    <div class="input__line input__line-confirm">
                                        <!-- confirm -->
                                        <div id="input__item-confirm" class="input__item input__item-confirm">
                                            <!-- <dt id="input__header-confirm" class="input__header input__header-confirm">
                          <label id="input__name-confirm" class="input__name input__name-confirm">プライバシーポリシー</label>
                        </dt> -->
                                            <dd id="input__container-confirm" class="input__container input__container-confirm">
                                                <div id="input__note-confirm" class="input__note input__note-confirm">
                                                    <div class="note">
                                                        <h4 class="note__name"></h4>
                                                        <p class="note__text">
                                                            宮崎カーフェリー株式会社（以下当社らと呼びます）では、お客様の個人情報が、重要な資産であることを認識し、細心の注意を払いお取扱い致しております。当社らがお取扱い致しましたお客様の個人情報は、以下の方針に基づき厳重に管理致します。
                                                        </p>
                                                    </div>
                                                    <br />
                                                    <div class="note">
                                                        <h4 class="note__name">個人情報の保護に関する法令や規律の遵守</h4>
                                                        <p class="note__text">
                                                            当社らは、個人情報の保護に関する法令及びその他の規範を遵守し、個人情報を適正に取り扱います。
                                                        </p>
                                                    </div>
                                                    <br />
                                                    <div class="note">
                                                        <h4 class="note__name">個人情報の取得及びその利用について</h4>
                                                        <p class="note__text">
                                                            当社らは下記の場合において、お客様の個人情報を適正かつ公正な手段によって取得し、利用します。
                                                        </p>
                                                        <br />
                                                        <p class="note__text">※ 乗船券の予約・販売 （乗船手続きの書類）</p>
                                                        <p class="note__text">※ その他商品の予約・販売</p>
                                                        <p class="note__text">※ WEB会員の入会及びサービスの提供</p>
                                                        <p class="note__text">※ 当社らのサービスに関するアンケートの実施</p>
                                                        <p class="note__text">※ 当社らの各種サービスに関するご案内</p>
                                                        <p class="note__text">※ 問い合わせ・依頼等への対応</p>
                                                    </div>
                                                    <br />
                                                    <div class="note">
                                                        <h4 class="note__name">第三者への開示・提供について</h4>
                                                        <p class="note__text">
                                                            ※
                                                            当社らが取得した個人情報は、以下のいずれかに該当する場合を除き、第三者への開示・提供は行いません。
                                                        </p>
                                                        <p class="note__text">※ お客様の同意がある場合</p>
                                                        <p class="note__text">※ 法令に基づき求められた場合</p>
                                                        <p class="note__text">
                                                            ※ 人命にかかわる事態が発生し、お客様の同意を得るのが困難と判断した場合
                                                        </p>
                                                        <p class="note__text">
                                                            ※
                                                            国もしくは地方公共団体またはその委託を受けた者が公的な事務を実施することに協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                                                        </p>
                                                        <p class="note__text">
                                                            ※
                                                            業務委託先などに対し、当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                                                        </p>
                                                        <p class="note__text">
                                                            ※ グループ企業で総合的なサービスを提供するために、利用目的の範囲内で情報を共同利用する場合
                                                        </p>
                                                    </div>
                                                    <br />
                                                    <div class="note">
                                                        <h4 class="note__name">個人情報の保護・管理について</h4>
                                                        <p class="note__text">
                                                            当社らは、個人情報の正確性および最新性を保ち、安全に管理するとともに個人情報の紛失、改ざん、漏えいなどを防止するための体制を構築し、必要かつ適正な対策を実施します。
                                                        </p>
                                                    </div>
                                                    <br />
                                                    <div class="note">
                                                        <h4 class="note__name">個人情報の開示・訂正・利用停止・消去</h4>
                                                        <p class="note__text">
                                                            当社らは、お客様が、ご自身の個人情報について、開示・訂正・利用停止・消去などを求める権利を有していることを認識し、個人情報相談窓口を設置して、これらの要望がある場合には、依頼人がご本人であることを確認させていただいた上で、法令に従って合理的な期間及び範囲で対応します。
                                                        </p>
                                                    </div>
                                                    <br />
                                                    <div class="note">
                                                        <h4 class="note__name">プライバシーポリシーの適用と変更について</h4>
                                                        <p class="note__text">
                                                            このプライバシーポリシーでは、当社らにおけるお客様の個人情報についてご説明しており、当社らのサービスを利用されるお客様は、この内容を十分ご理解いただいたものと解釈いたします。また、当社らのサービス内容の変更などに基づいて、いつでもこの内容を変更することができるものとします。
                                                        </p>
                                                    </div>
                                                    <br />
                                                    <div class="privacy_policy_signature">
                                                        <p>
                                                            &lt;個人情報相談窓口&gt;<br />
                                                            <span>宮崎カーフェリー株式会社 総務部</span>
                                                            <span>TEL：0985-29-8010&emsp;FAX：0985-31-6057</span><br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" type="button" v-on:click="closeThis">閉じる</button>
            </div>
        </section>
        <!-- /input -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { removeMemberData } from '@/lib/localStorageUtil'
import { API_USERS, API_ORDER } from '@/lib/util'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()

        // マイページへ
        const moveMyPage = () => {
            router.push('/my_page')
        }
        const closeThis = () => {
            window.close()
        }

        return {
            moveMyPage,
            closeThis,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'PrivacyPolicy',
    data() {
        return {}
    },
    components: {
        ShipAnimationEnd,
    },
    methods: {},
})
</script>

<style scoped>
.privacy_policy_signature {
    text-align: right;
}
h4 {
    color: #e39300;
}
span {
    display: block;
}
</style>
