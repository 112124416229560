<template>
    <div class="attention__header" v-show="!isLoading">
        <h3 class="attention__name">ご予約へ進む前にご確認ください</h3>
        <button id="attention__close" class="attention__close" data-remodal-action="cancel">×</button>
    </div>
    <div class="attention__container" v-show="!isLoading">
        <ul class="attention__list">
            <li class="attention__text">
                いつも宮崎カーフェリーをご利用いただきありがとうございます。<br />
                ご予約前に、お知らせ内容、注意事項をご確認のうえ、ご乗船お願いいたします。
            </li>
            <li class="attention__item" v-for="(information, idx) in sorted_information_array" :key="idx">
                <h4 class="attention__item-name attention__item-name-info" v-if="information.post_category == POST_CATEGORY_NOTICES">
                    {{ information.title }}
                </h4>
                <h4 class="attention__item-name attention__item-name-attention" v-if="information.post_category == POST_CATEGORY_PRECAUTIONS">
                    {{ information.title }}
                </h4>
                <ul
                    v-if="isListInformation(information.information)"
                    class="attention__item-list"
                    v-html="formatInformationList(information.information)"
                ></ul>
                <p v-else class="attention__item-text" v-html="formatHtml(information.information)"></p>
            </li>
        </ul>
        <div class="attention__btn">
            <button class="btn btn-back" data-remodal-action="cancel">プラン詳細に戻る</button>
            <button class="btn btn-next" type="button" v-on:click="registrationBooking">予約入力に進む</button>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { Information } from '@/lib/types'
import { POST_CATEGORY_NOTICES, POST_CATEGORY_PRECAUTIONS } from '@/lib/constants'
import { API_USERS, API_ORDER, isNull, formatHtml } from '@/lib/util'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const isLoading = ref(true)
        const information_array = ref<Information[]>()

        // 情報取得
        const getInformation = async () => {
            console.log('↓↓↓Information.getInformation↓↓↓')
            const postData = {
                dep_date: props.dep_date,
                sea_route_code: props.sea_route_code,
            }
            try {
                isLoading.value = true
                await apiClient
                    .post(`${API_ORDER}/information`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        information_array.value = []
                        for (let i = 0; i < data.length; i++) {
                            information_array.value.push({
                                id: data[i].id,
                                post_category: data[i].post_category,
                                title: data[i].title,
                                information: data[i].information,
                                priority: data[i].priority,
                            } as Information)
                        }
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        console.log(e.message)
                    })
            } catch (e) {
                console.log(e)
            } finally {
                isLoading.value = false
            }
            console.log('↑↑↑Information.getInformation↑↑↑')
        }
        // プラン詳細へ戻る
        const close = () => {
            // context.emit("close");
            $('#attention__close').click()
        }
        // 予約登録へ進む
        const registrationBooking = () => {
            // context.emit("close");
            $('#attention__close').click()
            router.push('/booking/registration')
        }
        // 注意事項整形
        const formatInformationList = (information: string): string => {
            if (isListInformation(information)) {
                return (
                    information
                        .replace(/\\n・/g, '</li><li class="attention__item-item">')
                        .replace(/\\n/g, '<br />')
                        .replace(/・/, '<li class="attention__item-item">') + '</li>'
                )
            }
            return information
        }
        // 注意事項タイプ判定
        const isListInformation = (information: string): boolean => {
            if (information != information.replace(/\\n・/g, '')) {
                return true
            }
            return false
        }
        const sorted_information_array = computed(() => {
            if (isNull(information_array.value)) {
                return []
            }
            if (information_array.value!.length == 0) {
                return []
            }
            return information_array.value!.sort((a, b) => {
                if (a.priority < b.priority) {
                    return 1
                } else if (a.priority > b.priority) {
                    return -1
                } else {
                    if (a.post_category < b.post_category) {
                        return 1
                    } else if (a.post_category > b.post_category) {
                        return -1
                    } else {
                        if (a.id < b.id) {
                            return -1
                        } else if (a.id > b.id) {
                            return 1
                        } else {
                            return 0
                        }
                    }
                }
            })
        })

        onMounted(() => {
            console.log('↓↓↓Information.onMount↓↓↓')
            scrollTo(0, 0)
            console.log('↑↑↑Information.onMount↑↑↑')
        })

        return {
            information_array,
            isLoading,
            getInformation,
            close,
            registrationBooking,
            formatInformationList,
            isListInformation,
            sorted_information_array,
            formatHtml,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage', 'close'],
    name: 'InformationDialog',
    data() {
        return {
            POST_CATEGORY_NOTICES: POST_CATEGORY_NOTICES as number,
            POST_CATEGORY_PRECAUTIONS: POST_CATEGORY_PRECAUTIONS as number,
        }
    },
    components: {},
    props: {
        dep_date: String,
        sea_route_code: String,
    },
    methods: {},
    mounted: function () {
        console.log('↓↓↓Information.mounted↓↓↓')
        console.log('↑↑↑Information.mounted↑↑↑')
    },
})
</script>

<style scoped></style>
