<!-- eslint-disable no-irregular-whitespace -->
<template>
    <div class="confirm__header">
        <h3 class="confirm__name">会員情報の確認</h3>
        <button class="confirm__close" data-remodal-action="cancel" id="btn-close-modal">×</button>
    </div>
    <div class="confirm__container">
        <ul class="confirm__list">
            <!-- master -->
            <li class="confirm__item">
                <div class="confirm__item-container">
                    <div class="confirm__disp">
                        <dl class="confirm__disp-list">
                            <div class="confirm__disp-line" v-if="isEditUpdate">
                                <dt class="confirm__disp-name">メールアドレス</dt>
                                <dd class="confirm__disp-data">{{ email }}</dd>
                            </div>
                            <div class="confirm__disp-line" v-if="isEditInput">
                                <dt class="confirm__disp-name">パスワード</dt>
                                <dd class="confirm__disp-data">{{ masked_password }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">利用者ID</dt>
                                <dd class="confirm__disp-data">{{ member_id }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">お名前</dt>
                                <dd class="confirm__disp-data">{{ name }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">お名前（カナ）</dt>
                                <dd class="confirm__disp-data">{{ name_katakana }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">電話番号</dt>
                                <dd class="confirm__disp-data">{{ tel }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">住所</dt>
                                <dd class="confirm__disp-data">{{ full_address }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">生年月日</dt>
                                <dd class="confirm__disp-data">
                                    {{ birthday_jp + '（年齢' + age + '歳）' }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">性別</dt>
                                <dd class="confirm__disp-data">
                                    {{ convertText(GENDERS, sex) }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">メールマガジン登録</dt>
                                <dd class="confirm__disp-data">
                                    {{ mail_magazine_request ? 'メールマガジンの配信を希望する' : 'メールマガジンの配信を希望しない' }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="confirm__btn">
        <button class="btn btn-back" data-remodal-action="cancel">会員情報を修正する</button>
        <button class="btn btn-next" type="button" v-on:click="registration">
            {{ isEditInput ? '会員登録を行う' : '会員情報を変更する' }}
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'
import { API_USERS, API_ORDER, joinFirstNameAndLastName, formatDate, castStringToDate, calcAgeToday, isNull } from '@/lib/util'
import { convertText, GENDERS, PREFECTURES } from '@/lib/constants'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const TYPE_INS = 0
        const TYPE_UPD = 1

        const masked_password = computed(() => {
            if (!isNull(props.password)) {
                return '*'.repeat(props.password!.length)
            } else {
                return ''
            }
        })
        const name = computed(() => {
            return joinFirstNameAndLastName(props.family_name as string, props.given_name as string)
        })
        const name_katakana = computed(() => {
            return joinFirstNameAndLastName(props.family_name_katakana as string, props.given_name_katakana as string)
        })
        const full_address = computed(() => {
            return (props.post_code + '　' + convertText(PREFECTURES, props.prefectures) + '　' + props.address).trim()
        })
        const birthday_jp = computed(() => {
            return formatDate(castStringToDate(props.birthday as string), false, 2)
        })
        const age = computed(() => {
            return calcAgeToday(castStringToDate(props.birthday as string))
        })
        const isEditInput = computed(() => {
            if (props.type == TYPE_INS) {
                return true
            } else {
                return false
            }
        })
        const isEditUpdate = computed(() => {
            if (props.type == TYPE_UPD) {
                return true
            } else {
                return false
            }
        })
        const registration = () => {
            // モーダルを閉じる
            $('#btn-close-modal').click()
            if (isEditInput.value) {
                context.emit('memberCreate')
            } else {
                context.emit('memberUpdate')
            }
        }

        onMounted(() => {
            console.log('↓↓↓MemberConfirm.onMounted↓↓↓')
            console.log('↑↑↑MemberConfirm.onMounted↑↑↑')
        })

        return {
            masked_password,
            name,
            name_katakana,
            full_address,
            birthday_jp,
            age,
            isEditInput,
            isEditUpdate,

            registration,

            joinFirstNameAndLastName,
            convertText,
        }
    },
    emits: ['memberCreate', 'memberUpdate'],
    name: 'MemberConfirm',
    data() {
        return {
            GENDERS: GENDERS,
            PREFECTURES: PREFECTURES,
        }
    },
    components: {},
    props: {
        type: Number,
        email: String,
        password: String,
        member_id: String,
        family_name: String,
        given_name: String,
        family_name_katakana: String,
        given_name_katakana: String,
        tel: String,
        post_code: String,
        prefectures: String,
        address: String,
        birthday: String,
        sex: Number,
        mail_magazine_request: Boolean,
    },
    methods: {},
})
</script>
<style></style>
