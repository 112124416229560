<template>
    <tr>
        <th>乗用車</th>
        <td id="search__item-car">
            <span>{{ dispCars() }}</span>
            <button class="btn-border" type="button" v-on:click="toggleInputCar()">変更</button>
            <div :id="'search__set-car-' + list_idx" class="search__set search__set-car none" style="max-width: 40rem">
                <ul class="input-set__list">
                    <li id="input-set__item-size4" class="input-set__item input-set__item-size4">
                        <label id="input-set__label-size4" class="input-set__label input-set__label-size4" for="input-set__number-size4"
                            >4m未満</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementCar(4)">－</button>
                            <input
                                id="input-set__number-size4"
                                class="input-set__number input-set__number-size4"
                                type="text"
                                name="size4"
                                v-model="car_4m_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementCar(4)">＋</button>
                        </div>
                    </li>
                    <li id="input-set__item-size5" class="input-set__item input-set__item-size5">
                        <label id="input-set__label-size5" class="input-set__label input-set__label-size5" for="input-set__number-size5"
                            >5m未満</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementCar(5)">－</button>
                            <input
                                id="input-set__number-size5"
                                class="input-set__number input-set__number-size5"
                                type="text"
                                name="size5"
                                v-model="car_5m_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementCar(5)">＋</button>
                        </div>
                    </li>
                    <li id="input-set__item-size6" class="input-set__item input-set__item-size6">
                        <label id="input-set__label-size6" class="input-set__label input-set__label-size6" for="input-set__number-size6"
                            >6m未満</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementCar(6)">－</button>
                            <input
                                id="input-set__number-size6"
                                class="input-set__number input-set__number-size6"
                                type="text"
                                name="size6"
                                v-model="car_6m_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementCar(6)">＋</button>
                        </div>
                    </li>
                </ul>
                <div class="input-set__buttons">
                    <button id="input-set__cansel-car" class="btn input-set__cansel input-set__cansel-car" type="button" v-on:click="cancelCar()">
                        キャンセル
                    </button>
                    <button id="input-set__setting-car" class="btn input-set__setting input-set__setting-car" type="button" v-on:click="commitCar()">
                        設定
                    </button>
                </div>
            </div>
        </td>
        <td class="detail__td-count">{{ car_amount.toLocaleString() + '円' }}</td>
    </tr>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, ref } from 'vue'
import $ from 'jquery'
import { getCarFare, isNull } from '@/lib/util'

export default defineComponent({
    setup(props, context) {
        const car_3m_num_tmp = ref(0)
        const car_4m_num_tmp = ref(0)
        const car_5m_num_tmp = ref(0)
        const car_6m_num_tmp = ref(0)

        // 初期化処理
        const init = async () => {
            console.log('↓↓↓CarList.init↓↓↓')
            console.log('↑↑↑CarList.init↑↑↑')
        }
        // 表示乗用車数
        const dispCars = (): string => {
            let values = []

            if (props.car_3m_num! > 0) {
                values.push(`3m未満：${props.car_3m_num}台`)
            }
            if (props.car_4m_num! > 0) {
                values.push(`4m未満：${props.car_4m_num}台`)
            }
            if (props.car_5m_num! > 0) {
                values.push(`5m未満：${props.car_5m_num}台`)
            }
            if (props.car_6m_num! > 0) {
                values.push(`6m未満：${props.car_6m_num}台`)
            }

            return values.join(' ')
        }

        // 乗用車数変更
        const toggleInputCar = () => {
            console.log('↓↓↓CarList.toggleInputCar↓↓↓')
            car_3m_num_tmp.value = props.car_3m_num!
            car_4m_num_tmp.value = props.car_4m_num!
            car_5m_num_tmp.value = props.car_5m_num!
            car_6m_num_tmp.value = props.car_6m_num!
            $('#search__set-car-' + props.list_idx).toggle()
            console.log('↑↑↑CarList.toggleInputCar↑↑↑')
        }
        const incrementCar = (car_length: number) => {
            if (car_length == 3) {
                car_3m_num_tmp.value += 1
            } else if (car_length == 4) {
                car_4m_num_tmp.value += 1
            } else if (car_length == 5) {
                car_5m_num_tmp.value += 1
            } else if (car_length == 6) {
                car_6m_num_tmp.value += 1
            }
        }
        const decrementCar = (car_length: number) => {
            if (car_length == 3) {
                car_3m_num_tmp.value -= 1
                if (car_3m_num_tmp.value < 0) {
                    car_3m_num_tmp.value = 0
                }
            } else if (car_length == 4) {
                car_4m_num_tmp.value -= 1
                if (car_4m_num_tmp.value < 0) {
                    car_4m_num_tmp.value = 0
                }
            } else if (car_length == 5) {
                car_5m_num_tmp.value -= 1
                if (car_5m_num_tmp.value < 0) {
                    car_5m_num_tmp.value = 0
                }
            } else if (car_length == 6) {
                car_6m_num_tmp.value -= 1
                if (car_6m_num_tmp.value < 0) {
                    car_6m_num_tmp.value = 0
                }
            }
        }
        const cancelCar = () => {
            $('div[id^="search__set-car-"]').hide()
        }
        const commitCar = async () => {
            // 乗船者人数確定時処理
            $('div[id^="search__set-car-"]').hide()
            context.emit('commitCar', car_3m_num_tmp.value, car_4m_num_tmp.value, car_5m_num_tmp.value, car_6m_num_tmp.value)
        }

        return {
            car_3m_num_tmp,
            car_4m_num_tmp,
            car_5m_num_tmp,
            car_6m_num_tmp,

            init,
            dispCars,

            toggleInputCar,
            incrementCar,
            decrementCar,
            cancelCar,
            commitCar,
        }
    },
    emits: ['commitCar'],
    name: 'CarList',
    data() {
        return {}
    },

    components: {},
    props: {
        list_idx: Number,
        dep_date: String,
        sea_route_code: String,
        contract_number: String,
        grade: Number,
        seat_type: Number,
        car_3m_num: Number,
        car_4m_num: Number,
        car_5m_num: Number,
        car_6m_num: Number,
        car_amount: Number,
    },
    methods: {},
    mounted: function () {
        console.log('↓↓↓CarList.mounted↓↓↓')
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search__item-car').length) {
                $('div[id^="search__set-car-"]').hide()
            }
        })
        console.log('↑↑↑CarList.mounted↑↑↑')
    },
})
</script>

<style scoped></style>
