<template>
    <main id="main" class="main">
        <section class="ticket">
            <!-- <section style="margin-left: auto; margin-right: auto;"> -->
            <div class="ticket__header">
                <h2 class="ticket__name">ログアウト</h2>
            </div>
            <div class="ticket__container">
                <p id="complate__text" class="complate__text">ログイン後、一定時間が経過したため、ログアウトされました。</p>
                <p id="complate__text" class="complate__text">お手数ですが、再度ログインしてください。</p>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" type="button" v-on:click="moveLogin">ログイン画面に戻る</button>
            </div>
        </section>
        <!-- animation -->
        <ShipAnimationStart></ShipAnimationStart>
        <ShipAnimationEnd></ShipAnimationEnd>
        <!-- /animation -->
    </main>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, nextTick } from 'vue'
import $ from 'jquery'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import ShipAnimationStart from '@/components/common/ShipAnimationStart.vue'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()

        /**
         * ログイン画面に遷移
         */
        const moveLogin = () => {
            router.push('/member/login')
        }

        onMounted(async () => {})

        return {
            moveLogin,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'Credit3dsAuthenticated',
    data() {
        return {}
    },
    components: {
        ShipAnimationStart,
        ShipAnimationEnd,
    },
    props: {},
    methods: {},
})
</script>

<style></style>
