<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">{{ member_name }}さんのマイページ</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- /mypage -->
        <section id="mypage" class="mypage">
            <div class="mypage__container">
                <ul class="mypage__list">
                    <!-- 空席照会へ  -->
                    <li id="mypage__item-01" class="mypage__item mypage__item-01">
                        <a class="mypage__item-link" href="" v-on:click="confirmationVacancy">
                            <div class="mypage__item-image-pack">
                                <img class="mypage__item-image" src="@/images/items/mypage-01.svg" width="67" height="67" alt="空席照会へ" />
                            </div>
                            <dl class="mypage__item-list">
                                <dt class="mypage__item-name">空席照会へ</dt>
                                <dd class="mypage__item-text">乗船予約はこちらから</dd>
                            </dl>
                        </a>
                    </li>
                    <!-- 現在の予約  -->
                    <li id="mypage__item-02" class="mypage__item mypage__item-02">
                        <a class="mypage__item-link" href="" v-on:click="bookingCurrent">
                            <div class="mypage__item-image-pack">
                                <img class="mypage__item-image" src="@/images/items/mypage-02.svg" width="67" height="67" alt="現在の予約" />
                            </div>
                            <dl class="mypage__item-list">
                                <dt class="mypage__item-name">現在の予約</dt>
                                <dd class="mypage__item-text">乗船予約の確認・変更・取消</dd>
                            </dl>
                        </a>
                    </li>
                    <!-- 過去の予約  -->
                    <li id="mypage__item-03" class="mypage__item mypage__item-03">
                        <a class="mypage__item-link" href="" v-on:click="bookingHistory">
                            <div class="mypage__item-image-pack">
                                <img class="mypage__item-image" src="@/images/items/mypage-03.svg" width="67" height="67" alt="過去の予約" />
                            </div>
                            <dl class="mypage__item-list">
                                <dt class="mypage__item-name">過去の予約</dt>
                                <dd class="mypage__item-text">乗船履歴の確認・領収書データの<br />取得</dd>
                            </dl>
                        </a>
                    </li>
                    <!-- 利用者情報  -->
                    <li id="mypage__item-04" class="mypage__item mypage__item-04">
                        <a class="mypage__item-link" href="" v-on:click="memberUpdate">
                            <div class="mypage__item-image-pack">
                                <img class="mypage__item-image" src="@/images/items/mypage-04.svg" width="67" height="67" alt="利用者情報" />
                            </div>
                            <dl class="mypage__item-list">
                                <dt class="mypage__item-name">利用者情報</dt>
                                <dd class="mypage__item-text">利用者情報の確認・変更</dd>
                            </dl>
                        </a>
                    </li>
                    <!-- 同乗者情報  -->
                    <li id="mypage__item-05" class="mypage__item mypage__item-05">
                        <a class="mypage__item-link" href="" v-on:click="companion">
                            <div class="mypage__item-image-pack">
                                <img class="mypage__item-image" src="@/images/items/mypage-05.svg" width="67" height="67" alt="同行者情報" />
                            </div>
                            <dl class="mypage__item-list">
                                <dt class="mypage__item-name">同行者情報</dt>
                                <dd class="mypage__item-text">同行者情報の確認・追加・変更・<br />削除</dd>
                            </dl>
                        </a>
                    </li>
                    <!-- 車両情報  -->
                    <li id="mypage__item-06" class="mypage__item mypage__item-06">
                        <a class="mypage__item-link" href="" v-on:click="vehicle">
                            <div class="mypage__item-image-pack">
                                <img class="mypage__item-image" src="@/images/items/mypage-06.svg" width="67" height="67" alt="車両情報" />
                            </div>
                            <dl class="mypage__item-list">
                                <dt class="mypage__item-name">車両情報</dt>
                                <dd class="mypage__item-text">乗用車・バイク情報の確認・<br />追加・変更・削除</dd>
                            </dl>
                        </a>
                    </li>
                    <!-- パスワード変更  -->
                    <li id="mypage__item-07" class="mypage__item mypage__item-07">
                        <a class="mypage__item-link" href="" v-on:click="resetPass">
                            <div class="mypage__item-image-pack">
                                <img class="mypage__item-image" src="@/images/items/mypage-07.svg" width="67" height="67" alt="パスワード変更" />
                            </div>
                            <dl class="mypage__item-list">
                                <dt class="mypage__item-name">パスワード変更</dt>
                                <dd class="mypage__item-text">パスワード変更</dd>
                            </dl>
                        </a>
                    </li>
                    <!-- メールアドレス変更  -->
                    <li id="mypage__item-08" class="mypage__item mypage__item-08">
                        <a class="mypage__item-link" href="" v-on:click="changeMail">
                            <div class="mypage__item-image-pack">
                                <img class="mypage__item-image" src="@/images/items/mypage-08.svg" width="67" height="67" alt="メールアドレス変更" />
                            </div>
                            <dl class="mypage__item-list">
                                <dt class="mypage__item-name">メールアドレス変更</dt>
                                <dd class="mypage__item-text">代表者メールアドレスの<br />確認・変更</dd>
                            </dl>
                        </a>
                    </li>
                    <!-- メール配信先の登録  -->
                    <li id="mypage__item-09" class="mypage__item mypage__item-09">
                        <a class="mypage__item-link" href="" v-on:click="mailSetting">
                            <div class="mypage__item-image-pack">
                                <img class="mypage__item-image" src="@/images/items/mypage-09.svg" width="67" height="67" alt="メール配信先の登録" />
                            </div>
                            <dl class="mypage__item-list">
                                <dt class="mypage__item-name">メール配信先の登録</dt>
                                <dd class="mypage__item-text">代表者メールアドレス以外の<br />メール配信先の登録・変更</dd>
                            </dl>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
        <!-- /mypage -->
        <!-- animation -->
        <ShipAnimationStart></ShipAnimationStart>
        <ShipAnimationEnd></ShipAnimationEnd>
        <!-- /animation -->
    </main>
</template>
<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import ShipAnimationStart from '@/components/common/ShipAnimationStart.vue'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { joinFirstNameAndLastName } from '@/lib/util'

export default defineComponent({
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'MyPage',
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        // 会員名
        const member_name = computed(() => {
            if (store.state.is_logged_in) {
                return joinFirstNameAndLastName(store.state.family_name, store.state.given_name)
            } else {
                return ''
            }
        })

        // 空席照会へ
        const confirmationVacancy = () => {
            try {
                store.dispatch('setIsProcessing', true)
                router.push('/booking/confirmation_vacancy')
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
        }
        // 現在の予約
        const bookingCurrent = () => {
            try {
                store.dispatch('setIsProcessing', true)
                router.push('/booking/current')
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
        }
        // 過去の予約
        const bookingHistory = () => {
            try {
                store.dispatch('setIsProcessing', true)
                router.push('/booking/history')
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
        }
        // 利用者情報
        const memberUpdate = () => {
            try {
                store.dispatch('setIsProcessing', true)
                router.push('/member/member_update')
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
        }
        // 同乗者情報
        const companion = () => {
            try {
                store.dispatch('setIsProcessing', true)
                router.push('/member/companion')
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
        }
        // 車両情報
        const vehicle = () => {
            try {
                store.dispatch('setIsProcessing', true)
                router.push('/member/vehicle')
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
        }
        // パスワード変更
        const resetPass = () => {
            try {
                store.dispatch('setIsProcessing', true)
                router.push('/member/change_pass')
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
        }
        // メールアドレス変更
        const changeMail = () => {
            try {
                store.dispatch('setIsProcessing', true)
                router.push('/member/change_email')
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
        }
        // メール配信先の登録
        const mailSetting = () => {
            try {
                store.dispatch('setIsProcessing', true)
                router.push('/member/email_setting')
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
        }

        onMounted(async () => {
            console.log('↓↓↓MyPage.onMounted↓↓↓')
            if (!store.state.is_logged_in) {
                // ログイン状態で無い場合、使用不可
                router.push('/member/login')
            }
            console.log('↑↑↑MyPage.onMounted↑↑↑')
        })

        return {
            member_name,

            confirmationVacancy,
            bookingCurrent,
            bookingHistory,
            memberUpdate,
            companion,
            vehicle,
            resetPass,
            changeMail,
            mailSetting,
        }
    },
    components: {
        ShipAnimationStart,
        ShipAnimationEnd,
    },
    data() {
        return {}
    },
    methods: {},
})
</script>
