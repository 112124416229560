<!-- eslint-disable vue/no-unused-components -->
<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">プランのご案内</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- detail -->
        <section id="detail" class="detail">
            <div id="detail__container" class="detail__container">
                <div id="detail__header" class="detail__header">
                    <h2 id="detail__title" class="detail__title" v-if="contract">
                        {{ get_seat_type_name(seat_type) }} {{ contract?.contract_name }}
                    </h2>
                </div>
                <ul id="detail__list" class="detail__list">
                    <li id="detail__item-plan" class="detail__item">
                        <h3 id="detail__name-plan" class="detail__name">
                            {{ contract?.sub_title }}
                        </h3>
                        <p class="detail__text" v-html="formatHtml(contract?.information)"></p>
                    </li>
                    <li id="detail__item-detail" class="detail__item detail__item-detail">
                        <h3 id="detail__name-detail" class="detail__name">お部屋詳細</h3>
                        <div class="detail__wrapper-detail">
                            <div class="detail__adjust-detail">
                                <table id="detail__table-detail" class="detail__table detail__table-detail">
                                    <tr>
                                        <th>名称</th>
                                        <td>{{ get_seat_type_name(seat_type) }}</td>
                                    </tr>
                                    <tr v-if="!isNull(get_seat_type_room_type(seat_type))">
                                        <th>タイプ</th>
                                        <td v-html="get_seat_type_room_type(seat_type)"></td>
                                    </tr>
                                    <tr v-if="!isNull(get_seat_type_wide(seat_type))">
                                        <th>広さ</th>
                                        <td v-html="get_seat_type_wide(seat_type)"></td>
                                    </tr>
                                    <tr v-if="!isNull(get_seat_type_view(seat_type))">
                                        <th>眺望</th>
                                        <td v-html="get_seat_type_view(seat_type)"></td>
                                    </tr>
                                    <tr v-if="!isNull(get_seat_type_capacity(seat_type))">
                                        <th>定員</th>
                                        <td v-html="get_seat_type_capacity(seat_type)"></td>
                                    </tr>
                                    <tr v-if="!isNull(get_seat_type_facility(seat_type))">
                                        <th>設備</th>
                                        <td v-html="get_seat_type_facility(seat_type)"></td>
                                    </tr>
                                </table>
                                <p class="detail__text detail__text-detail" v-html="get_seat_type_room_information(seat_type)"></p>
                            </div>
                            <div id="detail__slider" class="detail__slider swiper">
                                <swiper
                                    :modules="swiperModules"
                                    :pagination="swiperPagination(seat_type)"
                                    :navigation="swiperNavigation"
                                    :loop="true"
                                    :preload-images="false"
                                    :initial-slide="0"
                                    @swiper="onSwiper"
                                >
                                    <template v-if="seat_type == SEAT_TYPE_P_TW">
                                        <swiperSlide><img :src="images[0][0]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[0][1]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[0][2]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[0][3]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[0][4]" alt="" /></swiperSlide>
                                    </template>
                                    <template v-if="seat_type == SEAT_TYPE_P_TR">
                                        <swiperSlide><img :src="images[1][0]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[1][1]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[1][2]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[1][3]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[1][4]" alt="" /></swiperSlide>
                                    </template>
                                    <template v-if="seat_type == SEAT_TYPE_P_BA">
                                        <swiperSlide><img :src="images[2][0]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[2][1]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[2][2]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[2][3]" alt="" /></swiperSlide>
                                    </template>
                                    <template v-if="seat_type == SEAT_TYPE_F_TW">
                                        <swiperSlide><img :src="images[3][0]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[3][1]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[3][2]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[3][3]" alt="" /></swiperSlide>
                                    </template>
                                    <template v-if="seat_type == SEAT_TYPE_F_FO">
                                        <swiperSlide><img :src="images[4][0]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[4][1]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[4][2]" alt="" /></swiperSlide>
                                    </template>
                                    <template v-if="seat_type == SEAT_TYPE_FWP_TW">
                                        <swiperSlide><img :src="images[5][0]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[5][1]" alt="" /></swiperSlide>
                                    </template>
                                    <template v-if="seat_type == SEAT_TYPE_FWP_FO">
                                        <swiperSlide><img :src="images[6][0]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[6][1]" alt="" /></swiperSlide>
                                    </template>
                                    <template v-if="seat_type == SEAT_TYPE_S">
                                        <swiperSlide><img :src="images[7][0]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[7][1]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[7][2]" alt="" /></swiperSlide>
                                    </template>
                                    <template v-if="seat_type == SEAT_TYPE_DS">
                                        <!-- <swiperSlide><img :src="images[8][0]" alt=""></swiperSlide>
                    <swiperSlide><img :src="images[8][1]" alt=""></swiperSlide>
                    <swiperSlide><img :src="images[8][2]" alt=""></swiperSlide>
                    <swiperSlide><img :src="images[8][3]" alt=""></swiperSlide> -->
                                    </template>
                                    <template v-if="seat_type == SEAT_TYPE_D">
                                        <swiperSlide><img :src="images[9][0]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[9][1]" alt="" /></swiperSlide>
                                    </template>
                                    <template v-if="seat_type == SEAT_TYPE_T">
                                        <swiperSlide><img :src="images[10][0]" alt="" /></swiperSlide>
                                        <swiperSlide><img :src="images[10][1]" alt="" /></swiperSlide>
                                    </template>
                                    <!-- 左右移動 -->
                                    <span class="story-navi swiper-button-next"></span>
                                    <span class="story-navi swiper-button-prev"></span>
                                </swiper>
                                <!-- サムネイル一覧 -->
                                <div class="swiper-container slider-thumbnail"></div>
                            </div>
                        </div>
                    </li>
                    <li id="detail__item-count-01" class="detail__item detail__item-count">
                        <h3 id="detail__name-count-01" class="detail__name detail__name-count">部屋数・人数・車両を確認する</h3>
                        <div id="detail__set" class="detail__set">
                            <div class="detail__set-count" v-if="!isLowSeatType">{{ cabin_seat_count }}部屋</div>
                            <div class="detail__set-seat" v-else>&emsp;</div>
                            <div class="detail__set-pack" v-show="!isLowSeatType">
                                <button id="detail__set-plus" class="btn-border detail__set-plus" type="button" v-on:click="incrementCabinSeatCount">
                                    ＋部屋を増やす
                                </button>
                                <button
                                    id="detail__set-minus"
                                    class="btn-border detail__set-minus"
                                    type="button"
                                    v-on:click="decrementCabinSeatCount"
                                >
                                    －部屋を減らす
                                </button>
                            </div>
                        </div>
                        <table id="detail__table-count" class="detail__table detail__table-count">
                            <PlanRoomList
                                ref="planRoomList1"
                                :list_idx="1"
                                :dep_date="dep_date"
                                :sea_route_code="sea_route_code"
                                :contract_number="contract_number"
                                :grade="grade"
                                :seat_type="seat_type"
                                :car_3m_num="car_3m_num"
                                :car_4m_num="car_4m_num"
                                :car_5m_num="car_5m_num"
                                :car_6m_num="car_6m_num"
                                v-on:commitPassenger="commitPassenger"
                            ></PlanRoomList>
                            <PlanBabyList
                                ref="planBabyList1"
                                :list_idx="1"
                                :dep_date="dep_date"
                                :sea_route_code="sea_route_code"
                                :contract_number="contract_number"
                                :grade="grade"
                                :seat_type="seat_type"
                                :passenger_infant_num="passenger_infant_num"
                                :passenger_baby_num="passenger_baby_num"
                                v-on:commitBaby="commitBaby"
                                v-on:aboutInfane="aboutInfane"
                            ></PlanBabyList>
                            <CarList
                                ref="carList1"
                                :list_idx="1"
                                :dep_date="dep_date"
                                :sea_route_code="sea_route_code"
                                :contract_number="contract_number"
                                :grade="grade"
                                :seat_type="seat_type"
                                :car_3m_num="car_3m_num"
                                :car_4m_num="car_4m_num"
                                :car_5m_num="car_5m_num"
                                :car_6m_num="car_6m_num"
                                :car_amount="car_amount"
                                v-on:commitCar="commitCar"
                                v-show="isShowCar"
                            ></CarList>
                            <BicycleList
                                ref="bicycleList1"
                                :list_idx="1"
                                :dep_date="dep_date"
                                :sea_route_code="sea_route_code"
                                :contract_number="contract_number"
                                :grade="grade"
                                :seat_type="seat_type"
                                :car_bicycle_num="car_bicycle_num"
                                :car_two_wheel_small_num="car_two_wheel_small_num"
                                :car_two_wheel_medium_num="car_two_wheel_medium_num"
                                :car_two_wheel_large_num="car_two_wheel_large_num"
                                :bicycle_amount="bicycle_amount"
                                v-on:commitBicycle="commitBicycle"
                                v-show="isShowBycycle"
                            ></BicycleList>
                            <CageList
                                ref="cageList1"
                                :list_idx="1"
                                :dep_date="dep_date"
                                :sea_route_code="sea_route_code"
                                :contract_number="contract_number"
                                :grade="grade"
                                :seat_type="seat_type"
                                :cage_large_num="cage_large_num"
                                :cage_small_num="cage_small_num"
                                v-on:commitCage="commitCage"
                                v-show="isShowPetCage"
                            ></CageList>
                        </table>
                        <div class="detail__sumary">
                            <div class="detail__specify">
                                <dl class="detail__specify-list">
                                    <div class="detail__specify-pack">
                                        <dt class="detail__specify-name">乗船日</dt>
                                        <dd class="detail__specify-content">{{ disp_dep_date }}</dd>
                                    </div>
                                    <div class="detail__specify-pack">
                                        <dt class="detail__specify-name">出港時刻</dt>
                                        <dd class="detail__specify-content">{{ disp_dep_time }}</dd>
                                    </div>
                                    <div class="detail__specify-pack">
                                        <dt class="detail__specify-name">航路</dt>
                                        <dd class="detail__specify-content">
                                            {{ disp_sea_route_code }}
                                        </dd>
                                    </div>
                                    <div class="detail__specify-pack">
                                        <dt class="detail__specify-name">船名</dt>
                                        <dd class="detail__specify-content">
                                            {{ ship_schedule?.ship_name }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div class="detail__price">
                                <dl class="detail__price-list">
                                    <div class="detail__price-pack">
                                        <dt class="detail__price-name">運賃合計（消費税込）</dt>
                                        <dd class="detail__price-content">
                                            {{ total_amount.toLocaleString() + '円' }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div class="detail__btn-container btn-container">
                            <button class="btn btn-back" type="button" v-on:click="backConfirmationVacancy">空席照会に戻る</button>
                            <button class="btn btn-next" type="button" v-on:click="showInformation">予約入力に進む</button>
                        </div>
                    </li>
                    <li id="detail__item-note" class="detail__item">
                        <h3 id="detail__name-note" class="detail__name">注意事項</h3>
                        <table id="detail__table-note" class="detail__table">
                            <tr v-for="(limit, idx) in disp_contract_limit" :key="idx">
                                <th v-html="limit.title"></th>
                                <td v-html="limit.detail"></td>
                            </tr>
                        </table>
                        <p class="detail__text" v-html="formatHtml(contract?.precautions)"></p>
                    </li>
                    <li id="detail__item-cansel" class="detail__item">
                        <h3 id="detail__name-cansel" class="detail__name">キャンセルポリシー</h3>
                        <table id="detail__table-cansel" class="detail__table">
                            <tr v-for="(cancel_policy_detail, idx) in contract?.cancel_policy" :key="idx">
                                <th>{{ cancel_policy_detail.disp_day }}</th>
                                <td>{{ cancel_policy_detail.disp_value }}</td>
                            </tr>
                        </table>
                    </li>
                    <li id="detail__item-count-02" class="detail__item detail__item-count">
                        <h3 id="detail__name-count-02" class="detail__name detail__name-count">部屋数・人数・車両を確認する</h3>
                        <div id="detail__set" class="detail__set">
                            <div class="detail__set-count" v-if="!isLowSeatType">{{ cabin_seat_count }}部屋</div>
                            <div class="detail__set-seat" v-else>&emsp;</div>
                            <div class="detail__set-pack" v-show="!isLowSeatType">
                                <button id="detail__set-plus" class="btn-border detail__set-plus" type="button" v-on:click="incrementCabinSeatCount">
                                    ＋部屋を増やす
                                </button>
                                <button
                                    id="detail__set-minus"
                                    class="btn-border detail__set-minus"
                                    type="button"
                                    v-on:click="decrementCabinSeatCount"
                                >
                                    －部屋を減らす
                                </button>
                            </div>
                        </div>
                        <table id="detail__table-count" class="detail__table detail__table-count">
                            <PlanRoomList
                                ref="planRoomList2"
                                :list_idx="2"
                                :dep_date="dep_date"
                                :sea_route_code="sea_route_code"
                                :contract_number="contract_number"
                                :grade="grade"
                                :seat_type="seat_type"
                                :car_3m_num="car_3m_num"
                                :car_4m_num="car_4m_num"
                                :car_5m_num="car_5m_num"
                                :car_6m_num="car_6m_num"
                                v-on:commitPassenger="commitPassenger"
                            ></PlanRoomList>
                            <PlanBabyList
                                ref="planBabyList2"
                                :list_idx="2"
                                :dep_date="dep_date"
                                :sea_route_code="sea_route_code"
                                :contract_number="contract_number"
                                :grade="grade"
                                :seat_type="seat_type"
                                :passenger_infant_num="passenger_infant_num"
                                :passenger_baby_num="passenger_baby_num"
                                v-on:commitBaby="commitBaby"
                            ></PlanBabyList>
                            <CarList
                                ref="carList2"
                                :list_idx="2"
                                :dep_date="dep_date"
                                :sea_route_code="sea_route_code"
                                :contract_number="contract_number"
                                :grade="grade"
                                :seat_type="seat_type"
                                :car_3m_num="car_3m_num"
                                :car_4m_num="car_4m_num"
                                :car_5m_num="car_5m_num"
                                :car_6m_num="car_6m_num"
                                :car_amount="car_amount"
                                v-on:commitCar="commitCar"
                                v-show="isShowCar"
                            ></CarList>
                            <BicycleList
                                ref="bicycleList2"
                                :list_idx="2"
                                :dep_date="dep_date"
                                :sea_route_code="sea_route_code"
                                :contract_number="contract_number"
                                :grade="grade"
                                :seat_type="seat_type"
                                :car_bicycle_num="car_bicycle_num"
                                :car_two_wheel_small_num="car_two_wheel_small_num"
                                :car_two_wheel_medium_num="car_two_wheel_medium_num"
                                :car_two_wheel_large_num="car_two_wheel_large_num"
                                :bicycle_amount="bicycle_amount"
                                v-on:commitBicycle="commitBicycle"
                                v-show="isShowBycycle"
                            ></BicycleList>
                            <CageList
                                ref="cageList2"
                                :list_idx="2"
                                :dep_date="dep_date"
                                :sea_route_code="sea_route_code"
                                :contract_number="contract_number"
                                :grade="grade"
                                :seat_type="seat_type"
                                :cage_large_num="cage_large_num"
                                :cage_small_num="cage_small_num"
                                v-on:commitCage="commitCage"
                                v-show="isShowPetCage"
                            ></CageList>
                        </table>
                        <div class="detail__sumary">
                            <div class="detail__specify">
                                <dl class="detail__specify-list">
                                    <div class="detail__specify-pack">
                                        <dt class="detail__specify-name">乗船日</dt>
                                        <dd class="detail__specify-content">{{ disp_dep_date }}</dd>
                                    </div>
                                    <div class="detail__specify-pack">
                                        <dt class="detail__specify-name">出港時刻</dt>
                                        <dd class="detail__specify-content">{{ disp_dep_time }}</dd>
                                    </div>
                                    <div class="detail__specify-pack">
                                        <dt class="detail__specify-name">航路</dt>
                                        <dd class="detail__specify-content">
                                            {{ disp_sea_route_code }}
                                        </dd>
                                    </div>
                                    <div class="detail__specify-pack">
                                        <dt class="detail__specify-name">船名</dt>
                                        <dd class="detail__specify-content">
                                            {{ ship_schedule?.ship_name }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div class="detail__price">
                                <dl class="detail__price-list">
                                    <div class="detail__price-pack">
                                        <dt class="detail__price-name">運賃合計（消費税込）</dt>
                                        <dd class="detail__price-content">
                                            {{ total_amount.toLocaleString() + '円' }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div class="detail__btn-container btn-container">
                            <button class="btn btn-back" type="button" v-on:click="backConfirmationVacancy">空席照会に戻る</button>
                            <button class="btn btn-next" type="button" v-on:click="showInformation">予約入力に進む</button>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <!-- /detail -->
        <!-- attention -->
        <button id="btn-attention-open-modal" data-remodal-target="attention" v-show="false">○</button>
        <button id="btn-attention-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section id="attention" class="confirm attention remodal normal" data-remodal-id="attention">
            <Information ref="informationDialog" :dep_date="dep_date" :sea_route_code="sea_route_code" v-on:close="closeInformation"></Information>
        </section>
        <!-- /attention -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, onMounted, ref, computed, nextTick } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import {
    API_USERS,
    API_ORDER,
    useMinRoomSeatCount,
    getContract,
    getSeatTypeName,
    isNull,
    getCarFare,
    getTwoWheelFare,
    getShipSchedule,
    getStockPets,
    formatDate,
    castStringToDate,
    formatDateTime,
    formatHtml,
    SEA_ROUTE_CODE_MIYAZAKI,
    SEA_ROUTE_CODE_KOBE,
    CAR_TYPE_PASSENGER,
    CAR_TYPE_TWO_WHEEL_LARGE,
    CAR_TYPE_TWO_WHEEL_MEDIUM,
    CAR_TYPE_TWO_WHEEL_SMALL,
    CAR_TYPE_BICYCLE,
    ROOM_INFORMATIONS,
    SHIP_CODE_ROKKO,
    getRouterPushProperties,
} from '@/lib/util'
import {
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_INFANT,
    PASSENGER_TYPE_BABY,
    CAGE_TYPES_LARGE,
    CAGE_TYPES_SMALL,
    VEHICLE_RESTRICTS,
    VEHICLE_RESTRICT_NONE,
    VEHICLE_RESTRICT_CAR,
    VEHICLE_RESTRICT_TWO_AXLE_CAR,
    VEHICLE_RESTRICT_PASSENGER,
    CHILD_RESTRICTS,
    CHILD_RESTRICT_NONE,
    CHILD_RESTRICT_CHILD,
    CHILD_RESTRICT_CHILD_OR_INFANT,
    convertTexts,
    convertText,
    PREFECTURES,
    PLAN_GENDER_RESTRICTS,
    PLAN_GENDER_RESTRICT_NONE,
    PLAN_ONE_WAY_RESTRICTS,
    PLAN_ONE_WAY_RESTRICT_NOT_SET,
    COUNTER_PAYMENT_RESTRICTS,
    SEAT_TYPE_P_TW,
    SEAT_TYPE_P_TR,
    SEAT_TYPE_P_BA,
    SEAT_TYPE_F_TW,
    SEAT_TYPE_F_FO,
    SEAT_TYPE_FWP_FO,
    SEAT_TYPE_FWP_TW,
    SEAT_TYPE_S,
    SEAT_TYPE_DS,
    SEAT_TYPE_D,
    SEAT_TYPE_T,
} from '@/lib/constants'
import { Booking, PassengerDetail, CarDetail, CageDetail, Contract, HeadcountDistribution, ShipSchedule, RoomInformation } from '@/lib/types'
import { getBookingData, setBookingData } from '@/lib/localStorageUtil'
import PlanRoomList from './PlanRoomList.vue'
import PlanBabyList from './PlanBabyList.vue'
import CarList from './CarList.vue'
import BicycleList from './BicycleList.vue'
import CageList from './CageList.vue'
import Information from './Information.vue'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'

import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'

import imgRoom10_01 from '@/images/rooms/10/room10_01.jpg'
import imgRoom10_02 from '@/images/rooms/10/room10_02.jpg'
import imgRoom10_03 from '@/images/rooms/10/room10_03.jpg'
import imgRoom10_04 from '@/images/rooms/10/room10_04.jpg'
import imgRoom10_05 from '@/images/rooms/10/room10_05.jpg'
import imgRoom11_01 from '@/images/rooms/10/room11_01.jpg'
import imgRoom11_02 from '@/images/rooms/10/room11_02.jpg'
import imgRoom11_03 from '@/images/rooms/10/room11_03.jpg'
import imgRoom11_04 from '@/images/rooms/10/room11_04.jpg'
import imgRoom11_05 from '@/images/rooms/10/room11_05.jpg'
import imgRoom12_01 from '@/images/rooms/10/room12_01.jpg'
import imgRoom12_02 from '@/images/rooms/10/room12_02.jpg'
import imgRoom12_03 from '@/images/rooms/10/room12_03.jpg'
import imgRoom12_04 from '@/images/rooms/10/room12_04.jpg'
import imgRoom13_01 from '@/images/rooms/10/room13_01.jpg'
import imgRoom13_02 from '@/images/rooms/10/room13_02.jpg'
import imgRoom13_03 from '@/images/rooms/10/room13_03.jpg'
import imgRoom13_04 from '@/images/rooms/10/room13_04.jpg'
import imgRoom14_01 from '@/images/rooms/10/room14_01.jpg'
import imgRoom14_02 from '@/images/rooms/10/room14_02.jpg'
// import imgRoom14_03 from '@/images/rooms/10/room14_03.jpg';
import imgRoom14_04 from '@/images/rooms/10/room14_04.jpg'
import imgRoom15_01 from '@/images/rooms/10/room15_01.jpg'
import imgRoom15_02 from '@/images/rooms/10/room15_02.jpg'
import imgRoom15_03 from '@/images/rooms/10/room15_03.jpg'
import imgRoom17_01 from '@/images/rooms/10/room17_01.jpg'
import imgRoom17_02 from '@/images/rooms/10/room17_02.jpg'
import imgRoom17_03 from '@/images/rooms/10/room17_03.jpg'
import imgRoom19_01 from '@/images/rooms/10/room19_01.jpg'
import imgRoom19_02 from '@/images/rooms/10/room19_02.jpg'
import imgRoom20_01 from '@/images/rooms/10/room20_01.jpg'
import imgRoom20_02 from '@/images/rooms/10/room20_02.jpg'

const images = [
    [imgRoom10_01, imgRoom10_02, imgRoom10_03, imgRoom10_04, imgRoom10_05],
    [imgRoom11_01, imgRoom11_02, imgRoom11_03, imgRoom11_04, imgRoom11_05],
    [imgRoom12_01, imgRoom12_02, imgRoom12_03, imgRoom12_04],
    [imgRoom13_01, imgRoom13_02, imgRoom13_03, imgRoom13_04],
    [imgRoom14_01, imgRoom14_02, imgRoom14_04],
    [imgRoom15_01, imgRoom15_03],
    [imgRoom15_02, imgRoom15_03],
    [imgRoom17_01, imgRoom17_02, imgRoom17_03],
    [],
    [imgRoom19_01, imgRoom19_02],
    [imgRoom20_01, imgRoom20_02],
]

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        const planRoomList1 = ref<InstanceType<typeof PlanRoomList>>()
        const planRoomList2 = ref<InstanceType<typeof PlanRoomList>>()
        const carList1 = ref<InstanceType<typeof CarList>>()
        const carList2 = ref<InstanceType<typeof CarList>>()
        const bicycleList1 = ref<InstanceType<typeof BicycleList>>()
        const bicycleList2 = ref<InstanceType<typeof BicycleList>>()
        const cageList1 = ref<InstanceType<typeof CageList>>()
        const cageList2 = ref<InstanceType<typeof CageList>>()
        const informationDialog = ref<InstanceType<typeof Information>>()
        const swiperInstance = ref()

        // 予約情報
        const booking = ref<Booking>()
        const cabin_seat_count = ref(0)
        const headcount_distributions = ref<HeadcountDistribution[]>()

        const contract_number = ref('')
        // 成約情報
        const contract = ref<Contract>()
        const dep_date = ref('')
        const sea_route_code = ref('')
        const ship_schedule = ref<ShipSchedule>()
        const vehicle_restrict = ref(0)

        const grade = ref(0)
        const seat_type = ref()

        const passenger_detail_array = ref<PassengerDetail[]>()
        const passenger_detail_baby_array = ref<PassengerDetail[]>()
        const passenger_adult_num = ref(0)
        const passenger_child_num = ref(0)
        const passenger_infant_num = ref(0)
        const passenger_baby_num = ref(0)
        const passenger_amount = ref(0)

        const car_num = ref(0)
        const car_detail_array = ref<CarDetail[]>()
        const car_3m_num = ref(0)
        const car_4m_num = ref(0)
        const car_5m_num = ref(0)
        const car_6m_num = ref(0)
        const car_amount = ref(0)

        const two_wheel_detail_array = ref<CarDetail[]>()
        const car_bicycle_num = ref(0)
        const car_two_wheel_small_num = ref(0)
        const car_two_wheel_medium_num = ref(0)
        const car_two_wheel_large_num = ref(0)
        const bicycle_amount = ref(0)

        const cage_detail_array = ref<CageDetail[]>()
        const cage_small_num = ref(0)
        const cage_large_num = ref(0)

        const disp_dep_date = computed(() => {
            return formatDate(castStringToDate(dep_date.value), false, 2, 1)
        })
        const disp_dep_time = computed(() => {
            if (isNull(ship_schedule.value) || isNull(ship_schedule.value!.dep_time) || isNull(dep_date.value)) {
                return ''
            }
            return formatDateTime(castStringToDate(dep_date.value + ' ' + ship_schedule.value!.dep_time), false, 1)
        })
        const disp_sea_route_code = computed(() => {
            if (sea_route_code.value == SEA_ROUTE_CODE_MIYAZAKI) {
                return '宮崎発'
            } else if (sea_route_code.value == SEA_ROUTE_CODE_KOBE) {
                return '神戸発'
            }
            return ''
        })
        const total_amount = computed(() => {
            return passenger_amount.value + car_amount.value + bicycle_amount.value
        })

        type ContractLimitDetail = {
            display: boolean
            title: string
            detail: string
        }
        const disp_contract_limit = computed(() => {
            let contract_limits = [] as ContractLimitDetail[]
            // 予約期限
            // 最小予約可能人数
            if (contract.value?.use_booking_people_num_min) {
                contract_limits.push({
                    display: true,
                    title: '最小予約可能人数',
                    detail: `${contract.value.booking_people_num_min}名から`,
                } as ContractLimitDetail)
            }
            // 最大予約可能人数
            if (contract.value?.use_booking_people_num_max) {
                contract_limits.push({
                    display: true,
                    title: '最大予約可能人数',
                    detail: `${contract.value.booking_people_num_max}名まで`,
                } as ContractLimitDetail)
            }
            // 予約年齢制限
            if (contract.value?.use_booking_age_limit) {
                contract_limits.push({
                    display: true,
                    title: '予約年齢制限',
                    detail: `${contract.value.booking_age_limit_min} 歳から ${contract.value.booking_age_limit_max}歳まで`,
                } as ContractLimitDetail)
            }
            // 都道府県制限
            if (!isNull(contract.value?.prefectures_restrict)) {
                contract_limits.push({
                    display: true,
                    title: '都道府県制限',
                    detail: `${convertTexts(PREFECTURES, contract.value!.prefectures_restrict)}`,
                } as ContractLimitDetail)
            }
            // 性別制限
            if (contract.value?.gender_restrict != PLAN_GENDER_RESTRICT_NONE) {
                contract_limits.push({
                    display: true,
                    title: '性別制限',
                    detail: `${convertText(PLAN_GENDER_RESTRICTS, contract.value?.gender_restrict)}`,
                } as ContractLimitDetail)
            }
            // 片道制限
            if (contract.value?.one_way_restrict) {
                contract_limits.push({
                    display: true,
                    title: '片道制限',
                    detail: `あり`,
                } as ContractLimitDetail)
            }
            // 車両制限
            if (contract.value?.vehicle_restrict != VEHICLE_RESTRICT_NONE) {
                contract_limits.push({
                    display: true,
                    title: '車両制限',
                    detail: `${convertText(VEHICLE_RESTRICTS, contract.value?.vehicle_restrict)}`,
                } as ContractLimitDetail)
            }
            // お子様連れ制限
            if (contract.value?.child_restrict != CHILD_RESTRICT_NONE) {
                contract_limits.push({
                    display: true,
                    title: 'お子様連れ制限',
                    detail: `${convertText(CHILD_RESTRICTS, contract.value?.child_restrict)}`,
                } as ContractLimitDetail)
            }
            // ペット連れ制限
            if (contract.value?.pets_restrict) {
                contract_limits.push({
                    display: true,
                    title: 'ペット連れ制限',
                    detail: `あり`,
                } as ContractLimitDetail)
            }
            // 決済方法
            if (isNull(contract.value?.counter_payment_restrict) && contract.value?.counter_payment_restrict != 0) {
                contract_limits.push({
                    display: true,
                    title: '決済方法',
                    detail: `${convertText(COUNTER_PAYMENT_RESTRICTS, contract.value?.counter_payment_restrict)}`,
                } as ContractLimitDetail)
            }
            // 日付変更可否
            if (contract.value?.dep_date_change_limit) {
                contract_limits.push({
                    display: true,
                    title: '乗船日変更',
                    detail: `${contract.value.dep_date_change_limit_days}日前 ${contract.value.dep_date_change_limit_time}まで`,
                } as ContractLimitDetail)
            } else {
                contract_limits.push({
                    display: true,
                    title: '乗船日変更',
                    detail: '不可',
                } as ContractLimitDetail)
            }
            // 乗船者情報変更可否
            if (contract.value?.passenger_change_limit) {
                contract_limits.push({
                    display: true,
                    title: '乗船者情報変更',
                    detail: `${contract.value.passenger_change_limit_days}日前 ${contract.value.passenger_change_limit_time}まで`,
                } as ContractLimitDetail)
            } else {
                contract_limits.push({
                    display: true,
                    title: '乗船者情報変更',
                    detail: '不可',
                } as ContractLimitDetail)
            }
            // 車両情報変更可否
            if (contract.value?.boarding_car_change_limit) {
                contract_limits.push({
                    display: true,
                    title: '車両情報変更',
                    detail: `${contract.value.boarding_car_change_limit_days}日前 ${contract.value.boarding_car_change_limit_time}まで`,
                } as ContractLimitDetail)
            } else {
                contract_limits.push({
                    display: true,
                    title: '車両情報変更',
                    detail: '不可',
                } as ContractLimitDetail)
            }

            return contract_limits
        })

        // 乗用車表示有無
        const isShowCar = computed(() => {
            if (vehicle_restrict.value == VEHICLE_RESTRICT_NONE || vehicle_restrict.value == VEHICLE_RESTRICT_CAR) {
                return true
            }
            return false
        })

        // 二輪車表示有無
        const isShowBycycle = computed(() => {
            if (vehicle_restrict.value == VEHICLE_RESTRICT_NONE || vehicle_restrict.value == VEHICLE_RESTRICT_TWO_AXLE_CAR) {
                return true
            }
            return false
        })

        // ペットルーム表示有無
        const isShowPetCage = computed(() => {
            // if (seat_type.value == SEAT_TYPE_FWP_TW || seat_type.value == SEAT_TYPE_FWP_FO) {
            //   return true;
            // }
            // if (cage_small_num.value > 0 || cage_large_num.value > 0) {
            //   return true;
            // }

            // return false;
            return true
        })
        // 下等級か？
        const isLowSeatType = computed((): boolean => {
            if (seat_type.value == SEAT_TYPE_D || seat_type.value == SEAT_TYPE_T) {
                return true
            }
            return false
        })
        // 画面表示時処理
        const openPlanDetail = async () => {
            store.dispatch('setIsProcessing', true)
            console.log('↓↓↓PlanDetail.openPlanDetail↓↓↓')
            contract_number.value = booking.value!.contract_number
            dep_date.value = booking.value!.dep_date
            sea_route_code.value = booking.value!.sea_route_code
            // 成約情報取得
            await getContractDetail()
            vehicle_restrict.value = contract.value!.vehicle_restrict
            // 運航予定取得
            ship_schedule.value = await getShipSchedule(dep_date.value, sea_route_code.value)
            grade.value = booking.value!.grade
            seat_type.value = booking.value!.seat_type
            // 乗船者
            passenger_adult_num.value = booking.value!.passenger_adult_num
            passenger_child_num.value = booking.value!.passenger_child_num
            passenger_detail_array.value = booking.value!.passenger_detail_array
            passenger_detail_baby_array.value = booking.value!.passenger_detail_baby_array
            passenger_infant_num.value = 0
            passenger_baby_num.value = 0
            for (let i = 0; i < passenger_detail_baby_array.value.length; i++) {
                if (passenger_detail_baby_array.value[i].passenger_type == PASSENGER_TYPE_INFANT) {
                    passenger_infant_num.value++
                } else if (passenger_detail_baby_array.value[i].passenger_type == PASSENGER_TYPE_BABY) {
                    passenger_baby_num.value++
                }
            }
            // 乗用車
            car_num.value = booking.value!.car_num
            car_detail_array.value = booking.value!.car_detail_array
            car_3m_num.value = 0
            car_4m_num.value = 0
            car_5m_num.value = 0
            car_6m_num.value = 0
            for (let i = 0; i < car_detail_array.value.length; i++) {
                if (car_detail_array.value[i].car_length == 3) {
                    car_3m_num.value += 1
                } else if (car_detail_array.value[i].car_length == 4) {
                    car_4m_num.value += 1
                } else if (car_detail_array.value[i].car_length == 5) {
                    car_5m_num.value += 1
                } else if (car_detail_array.value[i].car_length == 6) {
                    car_6m_num.value += 1
                }
            }
            // 二輪車
            car_bicycle_num.value = booking.value!.car_bicycle_num
            car_two_wheel_small_num.value = booking.value!.car_two_wheel_small_num
            car_two_wheel_medium_num.value = booking.value!.car_two_wheel_medium_num
            car_two_wheel_large_num.value = booking.value!.car_two_wheel_large_num
            two_wheel_detail_array.value = booking.value!.two_wheel_detail_array
            // ペットルーム
            cage_small_num.value = booking.value!.cage_small_num
            cage_large_num.value = booking.value!.cage_large_num
            cage_detail_array.value = booking.value!.cage_detail_array

            cabin_seat_count.value = useMinRoomSeatCount(
                seat_type.value,
                passenger_adult_num.value,
                passenger_child_num.value,
                passenger_infant_num.value,
                passenger_baby_num.value,
                1,
            )
            headcount_distributions.value = booking.value!.headcount_distributions
            nextTick(async () => {
                // 貸切フラグ設定
                setReservedFlg()
                // withペットフラグ設定
                setPetsFlg()
                await planRoomList1.value?.init(headcount_distributions.value!)
                await planRoomList2.value?.init(headcount_distributions.value!)
                passenger_amount.value = (await planRoomList1.value?.calc_room_amount_by_parent()) as number
                await carList1.value?.init()
                await carList2.value?.init()
                calcCarAmount()
                await bicycleList1.value?.init()
                await bicycleList2.value?.init()
                calcBicycleAmount()
                await cageList1.value?.init()
                await cageList2.value?.init()

                // 注意事項表示
                informationDialog.value?.getInformation()
                store.dispatch('setIsProcessing', false)
            })
            console.log('↑↑↑PlanDetail.openPlanDetail↑↑↑')
        }

        // 成約情報取得
        const getContractDetail = async () => {
            contract.value = await getContract(contract_number.value, dep_date.value, sea_route_code.value)
        }

        // 部屋数
        const incrementCabinSeatCount = () => {
            cabin_seat_count.value += 1
            changeCabinSeatCount()
        }
        const decrementCabinSeatCount = () => {
            cabin_seat_count.value -= 1
            if (cabin_seat_count.value < 1) {
                cabin_seat_count.value = 1
            }
            changeCabinSeatCount()
        }
        const changeCabinSeatCount = async () => {
            if (cabin_seat_count.value == 0) {
                headcount_distributions.value = []
            } else {
                if (!headcount_distributions.value) {
                    headcount_distributions.value = []
                }
                let tmpArray = ref<HeadcountDistribution[]>()
                tmpArray.value = []
                for (var i = 0; i < cabin_seat_count.value; i++) {
                    if (headcount_distributions.value?.length > i) {
                        tmpArray.value!.push(headcount_distributions.value![i])
                    } else {
                        // 配列が指定台数より少ない場合、デフォルトを追加
                        let defaultArray = {
                            line_no: tmpArray.value!.length + 1,
                            adult: 0,
                            child: 0,
                            infant: 0,
                            baby: 0,
                            reserved_room: false,
                            room_amount: 0,
                        } as HeadcountDistribution
                        tmpArray.value!.push(defaultArray)
                    }
                }
                headcount_distributions.value = tmpArray.value
                await planRoomList1.value?.init(headcount_distributions.value)
                await planRoomList2.value?.init(headcount_distributions.value)
            }
            passenger_amount.value = (await planRoomList1.value?.calc_room_amount_by_parent()) as number
        }
        // 乗船者数
        const commitPassenger = async (room_line_no: number, adult_num: number, child_num: number, infant_num: number, baby_num: number) => {
            for (let i = 0; i < headcount_distributions.value!.length; i++) {
                if (headcount_distributions.value![i].line_no == room_line_no) {
                    headcount_distributions.value![i].adult = adult_num
                    headcount_distributions.value![i].child = child_num
                    headcount_distributions.value![i].infant = infant_num
                    headcount_distributions.value![i].baby = baby_num
                    // 有料乗船者数
                    let passenger_count = adult_num + child_num
                    headcount_distributions.value![i].reserved_room = false
                    for (let j = 0; j < ROOM_INFORMATIONS.length; j++) {
                        if (ROOM_INFORMATIONS[j].seat_type == seat_type.value) {
                            if (ROOM_INFORMATIONS[j].reserve_count > passenger_count) {
                                console.log(contract.value!.reserved_change_start_date)
                                console.log(contract.value!.reserved_change_end_date)
                                console.log(castStringToDate(dep_date.value))
                                console.log(contract.value!.reserved_change_start_date! <= castStringToDate(dep_date.value)!)
                                console.log(contract.value!.reserved_change_end_date! >= castStringToDate(dep_date.value)!)
                                if (
                                    (!isNull(contract.value?.reserved_change_start_date) || !isNull(contract.value?.reserved_change_end_date)) &&
                                    !isNull(contract.value?.reserved_change_start_date) &&
                                    contract.value!.reserved_change_start_date! <= castStringToDate(dep_date.value)! &&
                                    !isNull(contract.value?.reserved_change_end_date) &&
                                    contract.value!.reserved_change_end_date! >= castStringToDate(dep_date.value)!
                                ) {
                                    headcount_distributions.value![i].reserved_room = true
                                }
                            }
                            break
                        }
                    }
                    makePassengerArray()
                    break
                }
            }
            passenger_amount.value = (await planRoomList1.value?.calc_room_amount_by_parent()) as number
        }
        const commitBaby = async (infant_num: number, baby_num: number) => {
            passenger_infant_num.value = infant_num
            passenger_baby_num.value = baby_num
            makeBabyArray()
        }

        // 貸切フラグ設定
        const setReservedFlg = () => {
            for (let i = 0; i < headcount_distributions.value!.length; i++) {
                let isReserved = false
                let isReservedRoom = false
                let paying_passenger_count = 0
                // 有料乗船者数チェック
                if (!isNull(headcount_distributions.value![i].passenger_detail_array)) {
                    for (let j = 0; j < headcount_distributions.value![i].passenger_detail_array.length; j++) {
                        if (
                            headcount_distributions.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_ADULT ||
                            headcount_distributions.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_CHILD
                        ) {
                            paying_passenger_count++
                            if (headcount_distributions.value![i].passenger_detail_array[j].reserved_room) {
                                if (isReservedRoom) {
                                    // 2人目以降の貸切は解除
                                    headcount_distributions.value![i].passenger_detail_array[j].reserved_room = false
                                }
                                isReservedRoom = true
                            }
                        }
                    }
                }
                if (isReservedRoom) {
                    headcount_distributions.value![i].reserved_room = true
                } else if (isReservedRoom == false) {
                    // 最低人数チェック
                    for (const info of ROOM_INFORMATIONS) {
                        if (info.seat_type == seat_type.value && info.reserve_count > paying_passenger_count) {
                            if (
                                (!isNull(contract.value?.reserved_change_start_date) || !isNull(contract.value?.reserved_change_end_date)) &&
                                !isNull(contract.value?.reserved_change_start_date) &&
                                contract.value!.reserved_change_start_date! <= castStringToDate(dep_date.value)! &&
                                !isNull(contract.value?.reserved_change_end_date) &&
                                contract.value!.reserved_change_end_date! >= castStringToDate(dep_date.value)!
                            ) {
                                isReserved = true
                                headcount_distributions.value![i].reserved_room = true
                            }
                            break
                        }
                    }

                    // 貸切フラグ設定
                    if (isReserved) {
                        for (let j = 0; j < headcount_distributions.value![i].passenger_detail_array.length; j++) {
                            headcount_distributions.value![i].passenger_detail_array[j].reserved_room = isReserved
                            if (isReserved) {
                                isReserved = false
                            }
                        }
                    }
                }
            }
        }
        // withペットフラグ設定
        const setPetsFlg = () => {
            if (seat_type.value == SEAT_TYPE_FWP_FO || seat_type.value == SEAT_TYPE_FWP_TW) {
                for (let i = 0; i < headcount_distributions.value!.length; i++) {
                    let isPetsFlg = true
                    // 大人に優先して設定
                    for (let j = 0; j < headcount_distributions.value![i].passenger_detail_array.length; j++) {
                        if (isPetsFlg && headcount_distributions.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_ADULT) {
                            headcount_distributions.value![i].passenger_detail_array[j].with_pets_flg = true
                            isPetsFlg = false
                        } else {
                            headcount_distributions.value![i].passenger_detail_array[j].with_pets_flg = false
                        }
                    }
                    if (isPetsFlg) {
                        for (let j = 0; j < headcount_distributions.value![i].passenger_detail_array.length; j++) {
                            if (isPetsFlg && headcount_distributions.value![i].passenger_detail_array[j].passenger_type == PASSENGER_TYPE_CHILD) {
                                headcount_distributions.value![i].passenger_detail_array[j].with_pets_flg = true
                                isPetsFlg = false
                            }
                        }
                    }
                }
            } else {
                for (let i = 0; i < headcount_distributions.value!.length; i++) {
                    for (let j = 0; j < headcount_distributions.value![i].passenger_detail_array.length; j++) {
                        headcount_distributions.value![i].passenger_detail_array[j].with_pets_flg = false
                    }
                }
            }
        }
        const makePassengerArray = () => {
            passenger_detail_array.value = []
            for (let i = 0; i < headcount_distributions.value!.length; i++) {
                headcount_distributions.value![i].passenger_detail_array = []
                let isReserved = headcount_distributions.value![i].reserved_room

                // 大人
                for (let j = 0; j < headcount_distributions.value![i].adult; j++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let tmpLineNo = passenger_detail_array.value!.length + 1
                    let defaultPassengerDetail = {
                        id: null,
                        line_no: tmpLineNo,
                        gender: undefined,
                        age: null,
                        family_name_katakana: '',
                        given_name_katakana: '',
                        passenger_type: PASSENGER_TYPE_ADULT,
                        represent_flg: false,
                        mcf_member_id: '',
                        with_pets_flg: false,
                        driver: false,
                        reserved_room: isReserved,
                    } as PassengerDetail
                    passenger_detail_array.value!.push(defaultPassengerDetail)
                    headcount_distributions.value![i].passenger_detail_array.push(defaultPassengerDetail)
                    isReserved = false
                }
                // 小人
                for (let j = 0; j < headcount_distributions.value![i].child; j++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let tmpLineNo = passenger_detail_array.value!.length + 1
                    let defaultPassengerDetail = {
                        id: null,
                        line_no: tmpLineNo,
                        gender: undefined,
                        age: null,
                        family_name_katakana: '',
                        given_name_katakana: '',
                        passenger_type: PASSENGER_TYPE_CHILD,
                        represent_flg: false,
                        mcf_member_id: '',
                        with_pets_flg: false,
                        driver: false,
                        reserved_room: isReserved,
                    } as PassengerDetail
                    passenger_detail_array.value!.push(defaultPassengerDetail)
                    headcount_distributions.value![i].passenger_detail_array.push(defaultPassengerDetail)
                    isReserved = false
                }
            }
        }
        const makeBabyArray = () => {
            passenger_detail_baby_array.value = []
            // 幼児
            for (let j = 0; j < passenger_infant_num.value; j++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let tmpLineNo = passenger_detail_baby_array.value!.length + 1
                let defaultPassengerDetail = {
                    id: null,
                    line_no: tmpLineNo,
                    gender: undefined,
                    age: null,
                    family_name_katakana: '',
                    given_name_katakana: '',
                    passenger_type: PASSENGER_TYPE_INFANT,
                    represent_flg: false,
                    mcf_member_id: '',
                    with_pets_flg: false,
                    driver: false,
                    reserved_room: false,
                } as PassengerDetail
                passenger_detail_baby_array.value!.push(defaultPassengerDetail)
            }
            // 乳児
            for (let j = 0; j < passenger_baby_num.value; j++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let tmpLineNo = passenger_detail_baby_array.value!.length + 1
                let defaultPassengerDetail = {
                    id: null,
                    line_no: tmpLineNo,
                    gender: undefined,
                    age: null,
                    family_name_katakana: '',
                    given_name_katakana: '',
                    passenger_type: PASSENGER_TYPE_BABY,
                    represent_flg: false,
                    mcf_member_id: '',
                    with_pets_flg: false,
                    driver: false,
                    reserved_room: false,
                } as PassengerDetail
                passenger_detail_baby_array.value!.push(defaultPassengerDetail)
            }
        }
        // 乗用車数
        const commitCar = async (car_3m: number, car_4m: number, car_5m: number, car_6m: number) => {
            car_3m_num.value = car_3m
            car_4m_num.value = car_4m
            car_5m_num.value = car_5m
            car_6m_num.value = car_6m
            makeCarArray()
            calcCarAmount()
            nextTick(async () => {
                passenger_amount.value = (await planRoomList1.value?.calc_room_amount_by_parent()) as number
            })
        }
        const makeCarArray = () => {
            console.log('↓↓↓PlanDetail.makeCarArray↓↓↓')
            car_detail_array.value = []
            for (let i = 0; i < car_3m_num.value; i++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let defaultCarDetail = {
                    line_no: car_detail_array.value!.length + 1,
                    car_type_code: '04',
                    car_length: 3,
                    car_width: 2.5,
                    car_place_name: '',
                    car_class_number: '',
                    car_hiragana: '',
                    car_number: '',
                    note: '',
                } as CarDetail
                car_detail_array.value!.push(defaultCarDetail)
            }

            for (let i = 0; i < car_4m_num.value; i++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let defaultCarDetail = {
                    line_no: car_detail_array.value!.length + 1,
                    car_type_code: '04',
                    car_length: 4,
                    car_width: 2.5,
                    car_place_name: '',
                    car_class_number: '',
                    car_hiragana: '',
                    car_number: '',
                    note: '',
                } as CarDetail
                car_detail_array.value!.push(defaultCarDetail)
            }

            for (let i = 0; i < car_5m_num.value; i++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let defaultCarDetail = {
                    line_no: car_detail_array.value!.length + 1,
                    car_type_code: '04',
                    car_length: 5,
                    car_width: 2.5,
                    car_place_name: '',
                    car_class_number: '',
                    car_hiragana: '',
                    car_number: '',
                    note: '',
                } as CarDetail
                car_detail_array.value!.push(defaultCarDetail)
            }

            for (let i = 0; i < car_6m_num.value; i++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let defaultCarDetail = {
                    line_no: car_detail_array.value!.length + 1,
                    car_type_code: '04',
                    car_length: 6,
                    car_width: 2.5,
                    car_place_name: '',
                    car_class_number: '',
                    car_hiragana: '',
                    car_number: '',
                    note: '',
                } as CarDetail
                car_detail_array.value!.push(defaultCarDetail)
            }
            console.log('↑↑↑PlanDetail.makeCarArray↑↑↑')
        }
        const calcCarAmount = async (): Promise<number> => {
            console.log('↓↓↓PlanDetail.calcCarAmount↓↓↓')
            car_amount.value = 0
            if (!isNull(car_3m_num.value) && car_3m_num.value > 0) {
                const contract_fare = await getCarFare(dep_date.value, sea_route_code.value, contract_number.value, 3)
                car_amount.value += contract_fare.baf_in_person_fare * car_3m_num.value
            }
            if (!isNull(car_4m_num.value) && car_4m_num.value > 0) {
                const contract_fare = await getCarFare(dep_date.value, sea_route_code.value, contract_number.value, 4)
                car_amount.value += contract_fare.baf_in_person_fare * car_4m_num.value
            }
            if (!isNull(car_5m_num.value) && car_5m_num.value > 0) {
                const contract_fare = await getCarFare(dep_date.value, sea_route_code.value, contract_number.value, 5)
                car_amount.value += contract_fare.baf_in_person_fare * car_5m_num.value
            }
            if (!isNull(car_6m_num.value) && car_6m_num.value > 0) {
                const contract_fare = await getCarFare(dep_date.value, sea_route_code.value, contract_number.value, 6)
                car_amount.value += contract_fare.baf_in_person_fare * car_6m_num.value
            }
            console.log('↑↑↑PlanDetail.calcCarAmount↑↑↑')
            return car_amount.value
        }
        // 二輪車数
        const commitBicycle = (bicycle_num: number, car_two_wheel_small: number, car_two_wheel_medium: number, car_two_wheel_large: number) => {
            car_bicycle_num.value = bicycle_num
            car_two_wheel_small_num.value = car_two_wheel_small
            car_two_wheel_medium_num.value = car_two_wheel_medium
            car_two_wheel_large_num.value = car_two_wheel_large
            makeBicycleArray()
            calcBicycleAmount()
        }
        const makeBicycleArray = () => {
            console.log('↓↓↓PlanDetail.commitBicycle↓↓↓')
            two_wheel_detail_array.value = []
            for (let i = 0; i < car_two_wheel_large_num.value; i++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let defaultCarDetail = {
                    line_no: two_wheel_detail_array.value!.length + 1,
                    car_type_code: '05',
                    car_length: 4,
                    car_width: 2.5,
                    car_place_name: '',
                    car_class_number: '',
                    car_hiragana: '',
                    car_number: '',
                    note: '',
                } as CarDetail
                two_wheel_detail_array.value!.push(defaultCarDetail)
            }

            for (let i = 0; i < car_two_wheel_medium_num.value; i++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let defaultCarDetail = {
                    line_no: two_wheel_detail_array.value!.length + 1,
                    car_type_code: '06',
                    car_length: 4,
                    car_width: 2.5,
                    car_place_name: '',
                    car_class_number: '',
                    car_hiragana: '',
                    car_number: '',
                    note: '',
                } as CarDetail
                two_wheel_detail_array.value!.push(defaultCarDetail)
            }

            for (let i = 0; i < car_two_wheel_small_num.value; i++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let defaultCarDetail = {
                    line_no: two_wheel_detail_array.value!.length + 1,
                    car_type_code: '07',
                    car_length: 4,
                    car_width: 2.5,
                    car_place_name: '',
                    car_class_number: '',
                    car_hiragana: '',
                    car_number: '',
                    note: '',
                } as CarDetail
                two_wheel_detail_array.value!.push(defaultCarDetail)
            }

            for (let i = 0; i < car_bicycle_num.value; i++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let defaultCarDetail = {
                    line_no: two_wheel_detail_array.value!.length + 1,
                    car_type_code: '08',
                    car_length: 4,
                    car_width: 2.5,
                    car_place_name: '',
                    car_class_number: '',
                    car_hiragana: '',
                    car_number: '',
                    note: '',
                } as CarDetail
                two_wheel_detail_array.value!.push(defaultCarDetail)
            }
            console.log('↑↑↑PlanDetail.commitBicycle↑↑↑')
        }
        const calcBicycleAmount = async (): Promise<number> => {
            console.log('↓↓↓PlanDetail.calcBicycleAmount↓↓↓')
            bicycle_amount.value = 0
            if (!isNull(car_bicycle_num.value) && car_bicycle_num.value > 0) {
                const contract_fare = await getTwoWheelFare(dep_date.value, sea_route_code.value, contract_number.value, CAR_TYPE_BICYCLE)
                bicycle_amount.value += contract_fare.baf_in_person_fare * car_bicycle_num.value
            }
            if (!isNull(car_two_wheel_small_num.value) && car_two_wheel_small_num.value > 0) {
                const contract_fare = await getTwoWheelFare(dep_date.value, sea_route_code.value, contract_number.value, CAR_TYPE_TWO_WHEEL_SMALL)
                bicycle_amount.value += contract_fare.baf_in_person_fare * car_two_wheel_small_num.value
            }
            if (!isNull(car_two_wheel_medium_num.value) && car_two_wheel_medium_num.value > 0) {
                const contract_fare = await getTwoWheelFare(dep_date.value, sea_route_code.value, contract_number.value, CAR_TYPE_TWO_WHEEL_MEDIUM)
                bicycle_amount.value += contract_fare.baf_in_person_fare * car_two_wheel_medium_num.value
            }
            if (!isNull(car_two_wheel_large_num.value) && car_two_wheel_large_num.value > 0) {
                const contract_fare = await getTwoWheelFare(dep_date.value, sea_route_code.value, contract_number.value, CAR_TYPE_TWO_WHEEL_LARGE)
                bicycle_amount.value += contract_fare.baf_in_person_fare * car_two_wheel_large_num.value
            }
            console.log('↑↑↑PlanDetail.calcBicycleAmount↑↑↑')
            return bicycle_amount.value
        }
        // ペットルーム
        const makeCageArray = () => {
            console.log('↓↓↓PlanDetail.makeCageArray↓↓↓')
            cage_detail_array.value = []
            for (let i = 0; i < cage_small_num.value; i++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let defaultCageDetail = {
                    cage_type: CAGE_TYPES_SMALL,
                } as CageDetail
                cage_detail_array.value!.push(defaultCageDetail)
            }

            for (let i = 0; i < cage_large_num.value; i++) {
                // 配列が指定台数より少ない場合、デフォルトを追加
                let defaultCageDetail = {
                    cage_type: CAGE_TYPES_LARGE,
                } as CageDetail
                cage_detail_array.value!.push(defaultCageDetail)
            }
            console.log('↑↑↑PlanDetail.makeCageArray↑↑↑')
        }
        const commitCage = (large_num: number, small_num: number) => {
            cage_large_num.value = large_num
            cage_small_num.value = small_num
            makeCageArray()
        }

        // 空席照会へ戻る
        const backConfirmationVacancy = () => {
            // router.push("/booking/confirmation_vacancy");
            location.href = '/booking/confirmation_vacancy'
        }
        // 注意事項表示
        const showInformation = async () => {
            console.log('↓↓↓PlanDetail.showInformation↓↓↓')
            try {
                store.dispatch('setIsProcessing', true)
                let tmpLineNo = 1
                let representFlg = true
                let booking = getBookingData() as Booking
                // 乗船情報
                booking.boarding.dog_count = 0
                booking.boarding.cat_count = 0
                booking.boarding.other_count = 0
                booking.boarding.pet_note = ''
                // 乗船者
                passenger_adult_num.value = 0
                passenger_child_num.value = 0
                if (headcount_distributions.value!.length > 0) {
                    for (let i = 0; i < headcount_distributions.value!.length; i++) {
                        headcount_distributions.value![i].passenger_detail_array = []
                        passenger_adult_num.value += headcount_distributions.value![i].adult
                        for (let j = 0; j < headcount_distributions.value![i].adult; j++) {
                            headcount_distributions.value![i].passenger_detail_array.push({
                                id: null,
                                line_no: tmpLineNo,
                                family_name_katakana: '',
                                given_name_katakana: '',
                                passenger_type: PASSENGER_TYPE_ADULT,
                                represent_flg: representFlg,
                                mcf_member_id: '',
                                with_pets_flg: false,
                                driver: false,
                                reserved_room: false,
                                register_companion: true,
                            } as PassengerDetail)
                            tmpLineNo++
                            representFlg = false
                        }
                        passenger_child_num.value += headcount_distributions.value![i].child
                        for (let j = 0; j < headcount_distributions.value![i].child; j++) {
                            headcount_distributions.value![i].passenger_detail_array.push({
                                id: null,
                                line_no: tmpLineNo,
                                family_name_katakana: '',
                                given_name_katakana: '',
                                passenger_type: PASSENGER_TYPE_CHILD,
                                represent_flg: representFlg,
                                mcf_member_id: '',
                                with_pets_flg: false,
                                driver: false,
                                reserved_room: false,
                                register_companion: true,
                            } as PassengerDetail)
                            tmpLineNo++
                            representFlg = false
                        }
                    }
                }
                booking.passenger_detail_baby_array = passenger_detail_baby_array.value!
                booking.headcount_distributions = headcount_distributions.value!
                booking.passenger_adult_num = passenger_adult_num.value
                booking.passenger_child_num = passenger_child_num.value
                booking.passenger_infant_num = passenger_infant_num.value
                booking.passenger_baby_num = passenger_baby_num.value
                // 乗用車
                for (let i = 0; i < car_detail_array.value!.length; i++) {
                    car_detail_array.value![i].register_car = false
                }
                booking.car_detail_array = car_detail_array.value!
                booking.car_3m_num = car_3m_num.value
                booking.car_4m_num = car_4m_num.value
                booking.car_5m_num = car_5m_num.value
                booking.car_6m_num = car_6m_num.value
                // 二輪車
                for (let i = 0; i < two_wheel_detail_array.value!.length; i++) {
                    two_wheel_detail_array.value![i].register_car = false
                }
                booking.two_wheel_detail_array = two_wheel_detail_array.value!
                booking.car_bicycle_num = car_bicycle_num.value
                booking.car_two_wheel_small_num = car_two_wheel_small_num.value
                booking.car_two_wheel_medium_num = car_two_wheel_medium_num.value
                booking.car_two_wheel_large_num = car_two_wheel_large_num.value
                // ケージ
                booking.cage_detail_array = cage_detail_array.value!
                booking.cage_small_num = cage_small_num.value
                booking.cage_large_num = cage_large_num.value

                setBookingData(booking)
                // ログイン状況チェック
                if (!store.state.is_logged_in) {
                    // ログイン画面へ遷移
                    router.push('/member/login')
                    return
                }
                // ペット在庫チェック
                if (!(await chkPetsStock())) return
                // 入力チェック
                if (!(await chkValidation())) return

                // 貸切料チェック※メッセージを出して処理を進める
                if (await chkReserved()) return

                store.dispatch('setIsProcessing', false)
            } catch (e) {
                console.log(e)
                store.dispatch('setIsProcessing', false)
                return
            }

            $('#btn-attention-open-modal').click()
            console.log('↑↑↑PlanDetail.showInformation↑↑↑')
        }
        const closeInformation = () => {
            $('#btn-attention-close-modal').click()
        }
        // ペットケージの在庫チェック
        const chkPetsStock = async (): Promise<boolean> => {
            let err_messages = [] as string[]
            // ペットルーム
            if (cage_large_num.value > 0 || cage_small_num.value > 0) {
                let stock_pets = await getStockPets(sea_route_code.value, dep_date.value, cage_large_num.value, cage_small_num.value)
                if (cage_large_num.value > 0 && stock_pets.large_status == 0) {
                    err_messages.push('ペットルーム（大型）の空きがございません。')
                }
                if (cage_small_num.value && stock_pets.middle_status == 0) {
                    err_messages.push('ペットルーム（中小型）の空きがございません。')
                }
            }
            if (err_messages.length > 0) {
                store.dispatch('setIsProcessing', false)
                // Scroll
                var landing = $('#detail__name-count-01').offset()!.top
                $('html,body').animate({ scrollTop: landing })
                setTimeout(() => {
                    context.emit('displayMessage', {
                        msg_title: 'プラン詳細',
                        msg: err_messages,
                        close_btn: true,
                    })
                }, 900)

                return false
            } else {
                return true
            }
        }

        // 入力チェック
        const chkValidation = async (): Promise<boolean> => {
            let err_count = 0
            let err_messages = [] as string[]

            // 乗船者不在チェック
            if (headcount_distributions.value!.length > 0) {
                for (let i = 0; i < headcount_distributions.value!.length; i++) {
                    if (
                        headcount_distributions.value![i].adult! == 0 &&
                        headcount_distributions.value![i].child! == 0 &&
                        headcount_distributions.value![i].infant! == 0 &&
                        headcount_distributions.value![i].baby! == 0
                    ) {
                        err_count++
                        err_messages.push('乗船者が設定されていない部屋があります。')
                    }
                }
            } else {
                err_count++
                err_messages.push('乗船者が設定されていない部屋があります。')
            }
            // 空き部屋数チェック
            let router_push_properties = getRouterPushProperties()
            if (!isNull(router_push_properties.empty_count)) {
                if (seat_type.value >= SEAT_TYPE_D) {
                    // 人数で判定
                    let passenger_count = 0
                    for (let i = 0; i < headcount_distributions.value!.length; i++) {
                        passenger_count += headcount_distributions.value![i].passenger_detail_array.length
                    }
                    if (router_push_properties.empty_count! < passenger_count) {
                        err_count++
                        err_messages.push(`ご予約は${router_push_properties.empty_count}名まで可能です。`)
                    }
                } else {
                    // 部屋数で判定
                    if (router_push_properties.empty_count! < headcount_distributions.value!.length) {
                        err_count++
                        err_messages.push(`ご予約は${router_push_properties.empty_count}部屋まで可能です。`)
                    }
                }
            }
            // 部屋毎人数オーバーチェック
            let room_info = {} as RoomInformation
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type.value) {
                    room_info = info
                    break
                }
            }
            if (headcount_distributions.value!.length > 0) {
                for (let i = 0; i < headcount_distributions.value!.length; i++) {
                    if (
                        seat_type.value < SEAT_TYPE_D &&
                        room_info.min_count > headcount_distributions.value![i].adult! + headcount_distributions.value![i].child!
                    ) {
                        err_count++
                        let message_count = `${getSeatTypeName(seat_type.value)}のご予約は1部屋${room_info.min_count}名からとなります。`
                        err_messages.push(message_count)
                    }
                    if (room_info.max_count < headcount_distributions.value![i].adult! + headcount_distributions.value![i].child!) {
                        err_count++
                        err_messages.push('定員オーバーの部屋があります。')
                    }
                }
            }
            // 幼児人数チェック
            let adult_num = 0
            let infant_num = 0
            // 大人人数取得
            for (let i = 0; i < headcount_distributions.value!.length; i++) {
                adult_num += headcount_distributions.value![i].adult
            }
            // 幼児人数取得
            for (let i = 0; i < passenger_detail_baby_array.value!.length; i++) {
                if (passenger_detail_baby_array.value![i].passenger_type == PASSENGER_TYPE_INFANT) {
                    infant_num++
                }
            }
            if (!isNull(passenger_detail_baby_array.value) && passenger_detail_baby_array.value!.length > 0) {
                if (adult_num < infant_num) {
                    err_count++
                    err_messages.push('幼児の人数は大人の人数を超えて設定できません。小人としてご登録ください。')
                }
            }

            // 乗用車台数チェック
            let driver_num = 0
            let bycycle_num = 0
            for (let i = 0; i < car_detail_array.value!.length; i++) {
                if (car_detail_array.value![i].deleted != true) {
                    driver_num += 1
                }
            }
            for (let i = 0; i < two_wheel_detail_array.value!.length; i++) {
                if (
                    two_wheel_detail_array.value![i].deleted != true &&
                    [CAR_TYPE_BICYCLE, CAR_TYPE_TWO_WHEEL_SMALL, CAR_TYPE_TWO_WHEEL_MEDIUM, CAR_TYPE_TWO_WHEEL_LARGE].includes(
                        two_wheel_detail_array.value![i].car_type_code,
                    )
                ) {
                    driver_num += 1
                    bycycle_num += 1
                }
            }
            if (driver_num > passenger_adult_num.value) {
                err_count++
                err_messages.push('大人の人数を超える車両・二輪車の予約はできません。')
            } else if (vehicle_restrict.value == VEHICLE_RESTRICT_TWO_AXLE_CAR && bycycle_num != passenger_adult_num.value) {
                err_count++
                err_messages.push('大人1名につき二輪車1台付きのプランとなります。')
            }

            // エラーメッセージ表示
            if (err_count > 0) {
                store.dispatch('setIsProcessing', false)
                // Scroll
                var landing = $('#detail__name-count-01').offset()!.top
                $('html,body').animate({ scrollTop: landing })
                setTimeout(() => {
                    context.emit('displayMessage', {
                        msg_title: 'プラン詳細',
                        msg: err_messages,
                        close_btn: true,
                    })
                }, 900)
            }
            return err_count == 0
        }

        // 貸切料チェック
        const chkReserved = async (): Promise<boolean> => {
            const reserved = await planRoomList1.value?.chkReserved()
            if (reserved == true) {
                store.dispatch('setIsProcessing', false)
                let close_event = () => {
                    $('#btn-attention-open-modal').click()
                }
                context.emit('displayMessage', {
                    msg_title: '貸切料',
                    msg: [`ご予約人数がお部屋の定員数に満たないため、貸切料が発生しています。`],
                    close_btn: true,
                    close_event: close_event,
                    cancel_btn_value: 'OK',
                })
            }
            return reserved!
        }

        // 部屋情報
        // 部屋タイプ名称取得
        const get_seat_type_name = (seat_type: number): string => {
            return getSeatTypeName(seat_type)
        }
        // お部屋情報取得
        const get_seat_type_room_information = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.information.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // お部屋タイプ取得
        const get_seat_type_room_type = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.room_type.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // 眺望取得
        const get_seat_type_view = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.view.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // 定員取得
        const get_seat_type_capacity = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.capacity.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // 部屋の広さ取得
        const get_seat_type_wide = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.wide.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // 設備・備品取得
        const get_seat_type_facility = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.facility.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // 部屋タイプ等級取得
        const get_seat_type_grade = (seat_type: number): number => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.grade
                }
            }
            return 10
        }

        onMounted(() => {
            console.log('↓↓↓PlanDetail.onMounted↓↓↓')
            scrollTo(0, 0)
            booking.value = getBookingData() as Booking
            openPlanDetail()
            nextTick(() => {
                try {
                    // モーダル再読み込み
                    const remodalInst = $('.remodal').remodal()
                    remodalInst.reload()
                } catch (e) {
                    console.log(e)
                }
            })
            console.log('↑↑↑PlanDetail.onMounted↑↑↑')
        })

        const swiperModules = [Navigation, Pagination]
        const swiperNavigation = {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        }
        // ページネーションで使うエレメントのクラスを指定
        // 画像をサムネイルとして並べたいのでバレットの内容を指定
        const swiperPagination = (seat_type: number) => {
            let width = 25
            if (seat_type < SEAT_TYPE_P_BA) {
                width = 20
            }
            return {
                el: '.slider-thumbnail',
                clickable: true,
                renderBullet: (index: number, className: string) =>
                    `<span class="slider-thumbnail__item ${className} 1 swiper-slide" style="width: ${width}%;margin: 0 0 0 0;"><img src="${
                        images[seat_type ? seat_type - 10 : 0][index]
                    }"></span>`,
            }
        }

        const aboutInfane = () => {
            let messge: Array<string> = [
                '幼児は大人1名につき1名まで無料（お席は大人と同席）となりますが、',
                'それを超える場合は小人料金が必要となります。',
                '大人の人数を超える幼児の方は、小人としてご予約ください。',
            ]
            context.emit('displayMessage', {
                msg_title: '※幼児について',
                msg: messge,
                close_btn: true,
            })
        }

        const onSwiper = (swiper: any) => {
            swiperInstance.value = swiper
            setTimeout(() => {
                swiperInstance.value.slideToLoop(0, 100, true)
            }, 1000)
        }

        return {
            planRoomList1,
            planRoomList2,
            carList1,
            carList2,
            bicycleList1,
            bicycleList2,
            cageList1,
            cageList2,
            informationDialog,

            booking,
            cabin_seat_count,
            headcount_distributions,
            contract,
            contract_number,
            dep_date,
            sea_route_code,
            ship_schedule,
            grade,
            seat_type,

            passenger_detail_array,
            passenger_adult_num,
            passenger_child_num,
            passenger_infant_num,
            passenger_baby_num,

            car_num,
            car_detail_array,
            car_3m_num,
            car_4m_num,
            car_5m_num,
            car_6m_num,
            car_amount,

            car_bicycle_num,
            two_wheel_detail_array,
            car_two_wheel_small_num,
            car_two_wheel_medium_num,
            car_two_wheel_large_num,
            bicycle_amount,

            cage_small_num,
            cage_large_num,
            cage_detail_array,

            disp_dep_date,
            disp_dep_time,
            disp_sea_route_code,
            total_amount,
            disp_contract_limit,

            SHIP_CODE_ROKKO,

            isShowCar,
            isShowBycycle,
            isShowPetCage,
            isLowSeatType,

            incrementCabinSeatCount,
            decrementCabinSeatCount,
            changeCabinSeatCount,
            commitPassenger,
            commitBaby,
            commitCar,
            commitBicycle,
            commitCage,

            backConfirmationVacancy,
            showInformation,
            closeInformation,

            get_seat_type_name,
            get_seat_type_room_information,
            get_seat_type_room_type,
            get_seat_type_view,
            get_seat_type_capacity,
            get_seat_type_wide,
            get_seat_type_facility,

            isNull,
            formatHtml,

            images,
            swiperModules,
            swiperNavigation,
            swiperPagination,
            aboutInfane,
            onSwiper,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'PlanDetail',
    data() {
        return {
            SEA_ROUTE_CODE_MIYAZAKI: SEA_ROUTE_CODE_MIYAZAKI as string,
            SEA_ROUTE_CODE_KOBE: SEA_ROUTE_CODE_KOBE as string,

            PASSENGER_TYPE_ADULT: PASSENGER_TYPE_ADULT as number,
            PASSENGER_TYPE_CHILD: PASSENGER_TYPE_CHILD as number,
            PASSENGER_TYPE_INFANT: PASSENGER_TYPE_INFANT as number,
            PASSENGER_TYPE_BABY: PASSENGER_TYPE_BABY as number,

            CAR_TYPE_PASSENGER: CAR_TYPE_PASSENGER as string,
            CAR_TYPE_BICYCLE: CAR_TYPE_BICYCLE as string,
            CAR_TYPE_TWO_WHEEL_SMALL: CAR_TYPE_TWO_WHEEL_SMALL as string,
            CAR_TYPE_TWO_WHEEL_MEDIUM: CAR_TYPE_TWO_WHEEL_MEDIUM as string,
            CAR_TYPE_TWO_WHEEL_LARGE: CAR_TYPE_TWO_WHEEL_LARGE as string,

            CAGE_TYPES_SMALL: CAGE_TYPES_SMALL as number,
            CAGE_TYPES_LARGE: CAGE_TYPES_LARGE as number,

            VEHICLE_RESTRICT_NONE: VEHICLE_RESTRICT_NONE as number,
            VEHICLE_RESTRICT_CAR: VEHICLE_RESTRICT_CAR as number,
            VEHICLE_RESTRICT_TWO_AXLE_CAR: VEHICLE_RESTRICT_TWO_AXLE_CAR as number,
            VEHICLE_RESTRICT_PASSENGER: VEHICLE_RESTRICT_PASSENGER as number,

            CHILD_RESTRICT_NONE: CHILD_RESTRICT_NONE as number,
            CHILD_RESTRICT_CHILD: CHILD_RESTRICT_CHILD as number,
            CHILD_RESTRICT_CHILD_OR_INFANT: CHILD_RESTRICT_CHILD_OR_INFANT as number,

            SEAT_TYPE_P_TW: SEAT_TYPE_P_TW as number,
            SEAT_TYPE_P_TR: SEAT_TYPE_P_TR as number,
            SEAT_TYPE_P_BA: SEAT_TYPE_P_BA as number,
            SEAT_TYPE_F_TW: SEAT_TYPE_F_TW as number,
            SEAT_TYPE_F_FO: SEAT_TYPE_F_FO as number,
            SEAT_TYPE_FWP_TW: SEAT_TYPE_FWP_TW as number,
            SEAT_TYPE_FWP_FO: SEAT_TYPE_FWP_FO as number,
            SEAT_TYPE_S: SEAT_TYPE_S as number,
            SEAT_TYPE_DS: SEAT_TYPE_DS as number,
            SEAT_TYPE_D: SEAT_TYPE_D as number,
            SEAT_TYPE_T: SEAT_TYPE_T as number,
        }
    },
    components: {
        PlanRoomList,
        PlanBabyList,
        CarList,
        BicycleList,
        CageList,
        Information,
        ShipAnimationEnd,
        Swiper,
        SwiperSlide,
    },
    props: {
        empty_count: Number,
    },
    methods: {},
})
</script>

<style scoped></style>
