<template>
    <main id="main" class="main">
        <!-- data -->
        <section class="data data-top">
            <div class="data__container">
                <div class="data__header">
                    <h2 class="data__name">メインメールアドレス</h2>
                </div>
                <ul id="data__list-passenger" class="data__list data__list-passenger">
                    <li class="data__item data__item-header">
                        <ul class="data__line data__line-header">
                            <li class="data__line-item data__line-item-no">No.</li>
                            <li class="data__line-item data__line-item-status">区分</li>
                            <li class="data__line-item data__line-item-address">メールアドレス</li>
                            <li class="data__line-item data__line-item-magazine">メールマガジン</li>
                            <li class="data__line-item data__line-item-btn"></li>
                        </ul>
                    </li>
                    <li class="data__item">
                        <ul class="data__line">
                            <li class="data__line-item data__line-item-no">
                                {{ getMainMailSetting().display_no }}
                            </li>
                            <li class="data__line-item data__line-item-status">
                                {{ getEmailStatus(getMainMailSetting().status) }}
                            </li>
                            <li class="data__line-item data__line-item-address">
                                {{ getMainMailSetting().email }}
                            </li>
                            <li class="data__line-item data__line-item-magazine">
                                {{ getMainMailSetting().delivery_request ? 'メールマガジンを希望する' : 'メールマガジンを希望しない' }}
                            </li>
                            <li class="data__line-item data__line-item-btn"></li>
                        </ul>
                    </li>
                </ul>
                <div class="data__header">
                    <h2 class="data__name">その他のメールアドレス</h2>
                    <div class="note">
                        <p class="note__text">（会員様ご自身のメールアドレスを、最大２個まで登録することができます。）</p>
                    </div>
                </div>
                <ul id="data__list-passenger" class="data__list data__list-passenger">
                    <li class="data__item data__item-header">
                        <ul class="data__line data__line-header">
                            <li class="data__line-item data__line-item-no">No.</li>
                            <li class="data__line-item data__line-item-status">区分</li>
                            <li class="data__line-item data__line-item-address">メールアドレス</li>
                            <li class="data__line-item data__line-item-magazine">メールマガジン</li>
                            <li class="data__line-item data__line-item-btn"></li>
                        </ul>
                    </li>
                    <li class="data__item" v-show="!isNull(getOtherMailSettingByLineNo(MAIL_TYPE_OTHER1).email)">
                        <ul class="data__line">
                            <li class="data__line-item data__line-item-no">
                                {{ getOtherMailSettingByLineNo(MAIL_TYPE_OTHER1).display_no }}
                            </li>
                            <li class="data__line-item data__line-item-status">
                                {{ getEmailStatus(getOtherMailSettingByLineNo(MAIL_TYPE_OTHER1).status) }}
                            </li>
                            <li class="data__line-item data__line-item-address">
                                {{ getOtherMailSettingByLineNo(MAIL_TYPE_OTHER1).email }}
                            </li>
                            <li class="data__line-item data__line-item-magazine">
                                {{
                                    getOtherMailSettingByLineNo(MAIL_TYPE_OTHER1).delivery_request
                                        ? 'メールマガジンを希望する'
                                        : 'メールマガジンを希望しない'
                                }}
                            </li>
                            <li class="data__line-item data__line-item-btn">
                                <button class="btn btn-data-change" v-on:click="displayChange(MAIL_TYPE_OTHER1)">変更</button>
                                <button class="btn btn-data-delete" v-on:click="displayDelete(MAIL_TYPE_OTHER1)">削除</button>
                            </li>
                        </ul>
                    </li>
                    <li class="data__item" v-show="!isNull(getOtherMailSettingByLineNo(MAIL_TYPE_OTHER2).email)">
                        <ul class="data__line">
                            <li class="data__line-item data__line-item-no">
                                {{ getOtherMailSettingByLineNo(MAIL_TYPE_OTHER2).display_no }}
                            </li>
                            <li class="data__line-item data__line-item-status">
                                {{ getEmailStatus(getOtherMailSettingByLineNo(MAIL_TYPE_OTHER2).status) }}
                            </li>
                            <li class="data__line-item data__line-item-address">
                                {{ getOtherMailSettingByLineNo(MAIL_TYPE_OTHER2).email }}
                            </li>
                            <li class="data__line-item data__line-item-magazine">
                                {{
                                    getOtherMailSettingByLineNo(MAIL_TYPE_OTHER2).delivery_request
                                        ? 'メールマガジンを希望する'
                                        : 'メールマガジンを希望しない'
                                }}
                            </li>
                            <li class="data__line-item data__line-item-btn">
                                <button class="btn btn-data-change" v-on:click="displayChange(MAIL_TYPE_OTHER2)">変更</button>
                                <button class="btn btn-data-delete" v-on:click="displayDelete(MAIL_TYPE_OTHER2)">削除</button>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="data__plus" v-show="registered_other_mail_count < 2">
                    <button class="btn-border btn-border-plus" v-on:click="displayAdd()">メールアドレスを追加する</button>
                </div>
                <div class="btn-container">
                    <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
                </div>
            </div>
        </section>
        <!-- /data -->
        <!-- setting regist -->
        <button id="btn-regist-open-modal" data-remodal-target="regist" v-show="false">○</button>
        <button id="btn-regist-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section id="regist" class="setting remodal normal" data-remodal-id="regist">
            <div class="input">
                <dl class="input__list">
                    <!-- line -->
                    <div class="input__line input__line-submail">
                        <!-- submail -->
                        <div class="input__item input__item-submail-pack">
                            <div id="input__item-mail" class="input__item input__item-submail">
                                <dt id="input__header-submail" class="input__header input__header-submail">
                                    <label id="input__name-submail" class="input__name input__name-submail" for="input__submail"
                                        >メールアドレス</label
                                    >
                                    <span class="input__required">必須</span>
                                </dt>
                                <dd id="input__container-submail" class="input__container input__container-submail">
                                    <input
                                        id="input__submail"
                                        class="input__form input__submail"
                                        type="text"
                                        name="input__submail"
                                        v-model="regist_mail"
                                    />
                                </dd>
                                <p class="input__error-text">{{ mail_error }}</p>
                            </div>
                            <!-- submail-confirm -->
                            <div id="input__item-submail-confirm" class="input__item input__item-submail-confirm">
                                <dt id="input__header-submail-confirm" class="input__header input__header-submail-confirm">
                                    <label
                                        id="input__name-submail-confirm"
                                        class="input__name input__name-submail-confirm"
                                        for="input__submail-confirm"
                                        >メールアドレス（確認用）</label
                                    >
                                    <span class="input__required">必須</span>
                                </dt>
                                <dd id="input__container-submail-confirm" class="input__container input__container-submail-confirm">
                                    <input
                                        id="input__submail-confirm"
                                        class="input__form input__submail-confirm"
                                        type="text"
                                        name="input__submail-confirm"
                                        v-model="regist_mail_confirm"
                                    />
                                </dd>
                                <p class="input__error-text">{{ confirm_error }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- line -->
                    <div class="input__line">
                        <!-- confirm -->
                        <div id="input__item-confirm" class="input__item input__item-confirm">
                            <dd id="input__container-confirm" class="input__container input__container-confirm">
                                <div id="input__note-confirm" class="input__note input__note-confirm">
                                    <div class="note">
                                        <p class="note__text">
                                            申請を行うと、入力メールアドレス宛に確認メールが送信されます。送信されたメールに記載された確認用URLにアクセスすることでメールアドレスの登録が完了します。<br />
                                            送信されたメールに記載された確認用URLに6時間以内にアクセスされない場合、対象メールアドレス宛に各種メール配信は行われません。<br />
                                            迷惑メール対策やドメイン指定受信等を設定している方は、メールが受信されますよう、【@miyazakicarferry.com】【@booking.miyazakicarferry.com】の登録をお願い致します。<br />
                                            ドメイン登録の設定をされていない場合、メールが届かないことがあります。
                                        </p>
                                    </div>
                                </div>
                            </dd>
                        </div>
                    </div>
                </dl>
            </div>
            <div class="btn-container btn-container-modal">
                <button id="btn-regist-cancel" class="btn btn-back" data-remodal-action="cancel">キャンセル</button>
                <button id="btn-regist" class="btn btn-next" v-on:click="registMail()">登録する</button>
            </div>
        </section>
        <!-- /setting regist -->

        <!-- setting change -->
        <button id="btn-change-open-modal" data-remodal-target="change" v-show="false">○</button>
        <button id="btn-change-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section id="change" class="setting remodal normal" data-remodal-id="change">
            <div class="input">
                <dl class="input__list">
                    <!-- line -->
                    <div class="output__line output__line-submail-changedisp">
                        <div class="output__item-submail-changedisp-pack">
                            <!-- submail-changedisp -->
                            <div class="output__item output__item-submail-changedisp">
                                <dt class="output__header output__header-submail-changedisp">
                                    <div class="output__name output__name-submail-changedisp">メール<span class="spbr"></span>アドレス</div>
                                </dt>
                                <dd class="output__container output__container-submail-changedisp">
                                    <div class="output__data output__data-mail-disp">
                                        {{ getOtherMailSettingByLineNo(change_mail_type).email }}
                                    </div>
                                </dd>
                            </div>
                        </div>
                        <p class="input__text">
                            メールアドレスは登録時のみ入力できます。<br />メールアドレスを変更したい場合は、削除し新たに登録してください。
                        </p>
                    </div>
                    <!-- line -->
                    <div class="input__line">
                        <!-- magazine -->
                        <div id="input__item-magazine-change" class="input__item input__item-magazine-reset">
                            <dt id="input__header-magazine-reset" class="input__header input__header-magazine-reset">
                                <label id="input__name-magazine-reset" class="input__name input__name-magazine-reset" for=""
                                    >メールマガジン配信</label
                                >
                                <span class="input__required">必須</span>
                            </dt>
                            <dd id="input__container-magazine-reset" class="input__container input__container-magazine-reset">
                                <div id="input__radio-sex-man" class="input__radio input__radio-sex input__radio-sex-man">
                                    <input
                                        id="input__man"
                                        class="input__radio-btn input__sex input__man"
                                        type="radio"
                                        name="input__sex"
                                        v-bind:value="true"
                                        v-model="change_mail_magazine_request"
                                    />
                                    <label id="input__label-man" class="input__radio-label input__label-man" for="input__man">希望する</label>
                                </div>
                                <div id="input__radio-sex-woman" class="input__radio input__radio-sex input__radio-sex-woman">
                                    <input
                                        id="input__woman"
                                        class="input__radio-btn input__sex input__woman"
                                        type="radio"
                                        name="input__sex"
                                        v-bind:value="false"
                                        v-model="change_mail_magazine_request"
                                    />
                                    <label id="input__label-woman" class="input__radio-label input__label-woman" for="input__woman">希望しない</label>
                                </div>
                            </dd>
                            <p class="input__error-text">メールマガジン配信を選択してください</p>
                        </div>
                    </div>
                </dl>
            </div>
            <div class="btn-container btn-container-modal">
                <button id="btn-change-cancel" class="btn btn-back" data-remodal-action="cancel">キャンセル</button>
                <button id="btn-change" class="btn btn-next" v-on:click="changeMail()">登録する</button>
            </div>
        </section>
        <!-- /setting change -->
        <!-- setting delete -->
        <button id="btn-delete-open-modal" data-remodal-target="delete" v-show="false">○</button>
        <button id="btn-delete-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section id="delete" class="setting remodal normal" data-remodal-id="delete">
            <div class="output">
                <dl class="output__list">
                    <!-- line -->
                    <div class="output__line output__line-submail-changedisp">
                        <p class="input__attention">このメールアドレスを削除してもよろしいですか？</p>
                        <div class="output__item-submail-changedisp-pack">
                            <!-- submail-changedisp -->
                            <div class="output__item output__item-submail-changedisp">
                                <dt class="output__header output__header-submail-changedisp">
                                    <div class="output__name output__name-submail-changedisp">メール<span class="spbr"></span>アドレス</div>
                                </dt>
                                <dd class="output__container output__container-submail-changedisp">
                                    <div class="output__data output__data-mail-disp">
                                        {{ getOtherMailSettingByLineNo(change_mail_type).email }}
                                    </div>
                                </dd>
                            </div>
                        </div>
                    </div>
                </dl>
            </div>
            <div class="btn-container btn-container-modal">
                <button id="btn-delete-change" class="btn btn-back" data-remodal-action="cancel">キャンセル</button>
                <button id="btn-delete" class="btn btn-remove" v-on:click="deleteMail()">削除する</button>
            </div>
        </section>
        <!-- /setting delete -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import $ from 'jquery'
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { MemberDetail } from '@/lib/types'
import { getMemberData } from '@/lib/localStorageUtil'
import { convertText, EMAIL_STATUS, EMAIL_STATUS_UNVERIFIED, EMAIL_STATUS_VERIFIED } from '@/lib/constants'
import { API_USERS, setMemberDetail, isNull, getId } from '@/lib/util'
import { isMail } from '@/lib/validation_util'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'

export default defineComponent({
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'MailSetting',
    setup(props, context) {
        const MAIL_TYPE_MAIN = 'MAIN'
        const MAIL_TYPE_OTHER1 = 'OTHER1'
        const MAIL_TYPE_OTHER2 = 'OTHER2'
        const regist_mail = ref('')
        const regist_mail_confirm = ref('')
        const regist_mail_magazine_request = ref('')
        const change_mail_type = ref('')
        const change_mail_magazine_request = ref(false)
        const mail_error = ref('')
        const confirm_error = ref('')
        const magazine_error = ref('')

        type MailSettingDetail = {
            mail_type: string
            display_no: number
            email: string
            delivery_request: boolean
            status: number
        }

        const router = useRouter()
        const mail_settings = ref<MailSettingDetail[]>()

        // 登録済のその他アドレス数
        const registered_other_mail_count = computed(() => {
            let cnt = 0

            if (!isNull(mail_settings.value)) {
                for (let i = 0; i < mail_settings.value!.length; i++) {
                    if (mail_settings.value![i].mail_type != MAIL_TYPE_MAIN) {
                        cnt++
                    }
                }
            }
            return cnt
        })

        // 初期化処理
        const init = async () => {
            // 初期化
            mail_settings.value = []
            await setMemberDetail()
            // メインアドレス情報取得
            const tmp_data = getMemberData()
            if (!isNull(tmp_data)) {
                mail_settings.value.push({
                    mail_type: MAIL_TYPE_MAIN,
                    display_no: 1,
                    email: tmp_data.email,
                    delivery_request: tmp_data.mail_magazine_request,
                    status: EMAIL_STATUS_VERIFIED,
                } as MailSettingDetail)
            } else {
                mail_settings.value.push({
                    mail_type: MAIL_TYPE_MAIN,
                    display_no: 1,
                    email: '',
                    delivery_request: false,
                    status: EMAIL_STATUS_UNVERIFIED,
                } as MailSettingDetail)
            }
            // その他アドレス情報取得
            const other_mail_detail = await getOtherMail()
            if (!isNull(other_mail_detail)) {
                let idx = 1
                // その他アドレス1
                if (other_mail_detail.other_address1) {
                    mail_settings.value.push({
                        mail_type: MAIL_TYPE_OTHER1,
                        display_no: idx,
                        email: other_mail_detail.other_address1,
                        delivery_request: other_mail_detail.delivery_request1,
                        status: other_mail_detail.application_status1,
                    } as MailSettingDetail)
                    if (!isNull(other_mail_detail.other_address1)) {
                        idx++
                    }
                }
                // その他アドレス2
                if (other_mail_detail.other_address2) {
                    mail_settings.value.push({
                        mail_type: MAIL_TYPE_OTHER2,
                        display_no: idx,
                        email: other_mail_detail.other_address2,
                        delivery_request: other_mail_detail.delivery_request2,
                        status: other_mail_detail.application_status2,
                    } as MailSettingDetail)
                }
            }
        }

        // その他メール情報取得
        const getOtherMail = async (): Promise<any> => {
            console.log('↓↓↓MailSetting.getOtherMail↓↓↓')
            let data = await apiClient
                .get(`${API_USERS}/member/other_emails`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    return data
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    return null
                })

            console.log('↑↑↑MailSetting.getOtherMail↑↑↑')
            return data
        }

        // メインメール設定取得
        const getMainMailSetting = (): MailSettingDetail => {
            let mail_setting = {
                mail_type: '',
                display_no: 0,
                email: '',
                delivery_request: false,
                status: 0,
            } as MailSettingDetail

            if (!isNull(mail_settings.value)) {
                for (let i = 0; i < mail_settings.value!.length; i++) {
                    if (mail_settings.value![i].mail_type == MAIL_TYPE_MAIN) {
                        mail_setting = mail_settings.value![i]
                        break
                    }
                }
            }

            return mail_setting
        }

        // その他アドレス設定取得
        const getOtherMailSettingByLineNo = (mail_type: string): MailSettingDetail => {
            let mail_setting = {
                mail_type: '',
                display_no: 0,
                email: '',
                delivery_request: false,
                status: 0,
            } as MailSettingDetail

            if (!isNull(mail_settings.value)) {
                for (let i = 0; i < mail_settings.value!.length; i++) {
                    if (mail_settings.value![i].mail_type == mail_type) {
                        mail_setting = mail_settings.value![i]
                        break
                    }
                }
            }
            return mail_setting
        }

        // 入力チェック
        const chkValidation = (): boolean => {
            let err_count = 0
            // 初期化
            mail_error.value = ''
            confirm_error.value = ''
            magazine_error.value = ''
            $('#input__item-mail').removeClass('input__error')
            $('#input__item-submail-confirm').removeClass('input__error')

            // メールアドレス
            if (isNull(regist_mail.value)) {
                $('#input__item-mail').addClass('input__error')
                mail_error.value = 'メールアドレスを入力してください'
                err_count++
            } else if (!isMail(regist_mail.value)) {
                $('#input__item-mail').addClass('input__error')
                mail_error.value = 'メールアドレスが正しくありません'
                err_count++
            }

            // 確認用メールアドレス
            if (isNull(regist_mail_confirm.value)) {
                $('#input__item-submail-confirm').addClass('input__error')
                confirm_error.value = '確認用メールアドレスを入力してください'
                err_count++
            } else if (!isMail(regist_mail_confirm.value)) {
                $('#input__item-submail-confirm').addClass('input__error')
                confirm_error.value = '確認用メールアドレスが正しくありません'
                err_count++
            } else if (regist_mail.value != regist_mail_confirm.value) {
                $('#input__item-submail-confirm').addClass('input__error')
                confirm_error.value = 'メールアドレスと確認用メールアドレスは同じものを入力してください'
                err_count++
            }
            return err_count == 0
        }

        // 入力チェック
        const chkValidationMagazine = (): boolean => {
            let err_count = 0
            // 初期化
            magazine_error.value = ''
            $('#input__item-magazine-change').removeClass('input__error')

            // メールマガジン希望
            if (isNull(change_mail_magazine_request.value)) {
                $('#input__item-magazine-change').addClass('input__error')
                err_count++
            }
            return err_count == 0
        }

        // メール検証状態名取得
        const getEmailStatus = (status: number): string => {
            return convertText(EMAIL_STATUS, status)
        }

        // 追加画面表示
        const displayAdd = () => {
            change_mail_type.value = MAIL_TYPE_OTHER1

            if (!isNull(mail_settings.value)) {
                for (let i = 0; i < mail_settings.value!.length; i++) {
                    if (mail_settings.value![i].mail_type == MAIL_TYPE_OTHER1) {
                        change_mail_type.value = MAIL_TYPE_OTHER2
                        break
                    }
                }
            }

            // location.href = '#regist';
            $('#btn-regist-open-modal').click()
        }

        // 変更画面表示
        const displayChange = (mail_type: string) => {
            change_mail_type.value = mail_type
            if (!isNull(mail_settings.value)) {
                for (let i = 0; i < mail_settings.value!.length; i++) {
                    if (mail_settings.value![i].mail_type == mail_type) {
                        change_mail_magazine_request.value = mail_settings.value![i].delivery_request
                        break
                    }
                }
            }
            // location.href = '#change';
            $('#btn-change-open-modal').click()
        }

        // 削除画面表示
        const displayDelete = (mail_type: string) => {
            change_mail_type.value = mail_type
            // location.href = '#delete';
            $('#btn-delete-open-modal').click()
        }

        // メールアドレス登録
        const registMail = async () => {
            console.log('↓↓↓ MailSetting.registMail ↓↓↓')
            if (!chkValidation()) {
                return
            }

            let mail_type = 1
            if (change_mail_type.value == MAIL_TYPE_OTHER2) {
                mail_type = 2
            }

            let postData = {
                division: mail_type,
                email: regist_mail.value,
            }

            await apiClient
                .patch(`${API_USERS}/member/other_emails/confirm`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    context.emit('displayMessage', {
                        msg_title: 'メール配信先登録',
                        msg: ['仮登録メールを送信しました。', 'メールに記載したURLにアクセスし、6時間以内にメールアドレス登録を完了してください。'],
                        close_btn: true,
                    })
                    $('#btn-regist-cancel').click()
                    init()
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    console.log(e.message)
                })

            console.log('↑↑↑ MailSetting.registMail ↑↑↑')
        }

        // メールアドレス更新
        const changeMail = async () => {
            console.log('↓↓↓ MailSetting.changeMail ↓↓↓')
            if (!chkValidationMagazine()) {
                return
            }

            let postData = {
                delivery_request1: false,
                delivery_request2: false,
            }

            if (!isNull(mail_settings.value)) {
                for (let i = 0; i < mail_settings.value!.length; i++) {
                    // 変更前
                    if (mail_settings.value![i].mail_type == MAIL_TYPE_OTHER1) {
                        postData.delivery_request1 = mail_settings.value![i].delivery_request
                    } else if (mail_settings.value![i].mail_type == MAIL_TYPE_OTHER2) {
                        postData.delivery_request2 = mail_settings.value![i].delivery_request
                    }
                    // 変更後
                    if (change_mail_type.value == MAIL_TYPE_OTHER1) {
                        postData.delivery_request1 = change_mail_magazine_request.value
                        continue
                    }
                    if (change_mail_type.value == MAIL_TYPE_OTHER2) {
                        postData.delivery_request2 = change_mail_magazine_request.value
                        continue
                    }
                }
            }

            await apiClient
                .patch(`${API_USERS}/member/other_emails/delivery`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    $('#btn-change-cancel').click()
                    init()
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    console.log(e.message)
                })

            console.log('↑↑↑ MailSetting.changeMail ↑↑↑')
        }

        // メールアドレス削除
        const deleteMail = async () => {
            console.log('↓↓↓ MailSetting.deleteMail ↓↓↓')
            let mail_type = 1
            if (change_mail_type.value == MAIL_TYPE_OTHER2) {
                mail_type = 2
            }

            const postData = {
                division: mail_type,
            }

            await apiClient
                .post(`${API_USERS}/member/other_emails/delete`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    $('#btn-delete-change').click()
                    init()
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    console.log(e.message)
                })
            console.log('↑↑↑ MailSetting.deleteMail ↑↑↑')
        }

        // マイページに移動
        let moveMyPage = () => {
            router.push('/member/my_page')
        }

        onMounted(() => {
            console.log('↓↓↓MailSetting.onMounted↓↓↓')
            init()
            console.log('↑↑↑MailSetting.onMounted↑↑↑')
        })

        return {
            MAIL_TYPE_MAIN,
            MAIL_TYPE_OTHER1,
            MAIL_TYPE_OTHER2,
            mail_error,
            confirm_error,
            magazine_error,

            registered_other_mail_count,
            regist_mail,
            regist_mail_confirm,
            regist_mail_magazine_request,
            change_mail_type,
            change_mail_magazine_request,

            getMainMailSetting,
            getOtherMailSettingByLineNo,
            displayAdd,
            displayChange,
            displayDelete,
            getEmailStatus,
            registMail,
            changeMail,
            deleteMail,
            moveMyPage,

            isNull,
        }
    },
    components: {
        ShipAnimationEnd,
    },
    data() {
        return {}
    },
    methods: {},
})
</script>
