<!-- eslint-disable no-irregular-whitespace -->
<template>
    <!-- search -->
    <section class="search">
        <h1 class="search__name">WEB予約</h1>
        <div class="search__container">
            <ul id="search__list" class="search__list">
                <li id="datepicker" class="search__item">
                    <CalendarPicker
                        v-model="dep_date"
                        placeholder="乗船日を選択してください"
                        mode="0"
                        index="-1"
                        @update:modelValue="updateDepDate"
                    />
                </li>
                <li id="search__item-route" class="search__item input-search-route">
                    <input
                        id="search__input-route"
                        class="search__input input-search input-search-route"
                        type="text"
                        placeholder="航路を選択してください"
                        readonly
                        v-model="disp_sea_route_code"
                    />
                    <div id="search__set-route" class="search__set search__set-route none">
                        <ul class="input-set__list input-set__list-route">
                            <li id="input-set__item-kobe" class="input-set__item input-set__item-route input-set__item-kobe">
                                <input
                                    id="input-set__route-kobe"
                                    class="input-set__route input-set__route-kobe"
                                    type="radio"
                                    name="input-set__route"
                                    :value="SEA_ROUTE_CODE_MIYAZAKI"
                                    v-model="sea_route_code"
                                />
                                <label class="input-set__route-label" for="input-set__route-kobe">宮崎港→神戸港</label>
                            </li>
                            <li id="input-set__item-miyazaki" class="input-set__item input-set__item-route input-set__item-miyazaki">
                                <input
                                    id="input-set__route-miyazaki"
                                    class="input-set__route input-set__route-miyazaki"
                                    type="radio"
                                    name="input-set__route"
                                    :value="SEA_ROUTE_CODE_KOBE"
                                    v-model="sea_route_code"
                                />
                                <label class="input-set__route-label" for="input-set__route-miyazaki">神戸港→宮崎港</label>
                            </li>
                        </ul>
                    </div>
                </li>
                <li id="search__item-people" class="search__item search__item-people">
                    <input
                        id="search__input-people"
                        class="search__input input-search input-search-people"
                        type="text"
                        placeholder="人数を選択してください"
                        v-model="disp_passenger"
                        readonly
                    />
                    <div id="search__set-people" class="search__set search__set-people none">
                        <ul class="input-set__list">
                            <li id="input-set__item-adult" class="input-set__item input-set__item-adult">
                                <label id="input-set__label-adult" class="input-set__label input-set__label-adult" for="input-set__number-adult"
                                    >大人（中学生以上）</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_ADULT)">
                                        －
                                    </button>
                                    <input
                                        id="input-set__number-adult"
                                        class="input-set__number input-set__number-adult"
                                        type="text"
                                        name="adult"
                                        v-model="passenger_adult_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_ADULT)">
                                        ＋
                                    </button>
                                </div>
                            </li>
                            <li id="input-set__item-child" class="input-set__item input-set__item-child">
                                <label id="input-set__label-child" class="input-set__label input-set__label-child" for="input-set__number-child"
                                    >小人（小学生）</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_CHILD)">
                                        －
                                    </button>
                                    <input
                                        id="input-set__number-child"
                                        class="input-set__number input-set__number-child"
                                        type="text"
                                        name="child"
                                        v-model="passenger_child_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_CHILD)">
                                        ＋
                                    </button>
                                </div>
                            </li>
                            <li id="input-set__item-toddler" class="input-set__item input-set__item-toddler">
                                <label id="input-set__label-toddler" class="input-set__label input-set__label-toddler" for="input-set__number-toddler"
                                    >幼児（未就学児）</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_INFANT)">
                                        －
                                    </button>
                                    <input
                                        id="input-set__number-toddler"
                                        class="input-set__number input-set__number-toddler"
                                        type="text"
                                        name="toddler"
                                        v-model="passenger_infant_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_INFANT)">
                                        ＋
                                    </button>
                                </div>
                            </li>
                            <li id="input-set__item-baby" class="input-set__item input-set__item-baby">
                                <label id="input-set__label-baby" class="input-set__label input-set__label-baby" for="input-set__number-baby"
                                    >乳児（0才）</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementPassenger(PASSENGER_TYPE_BABY)">
                                        －
                                    </button>
                                    <input
                                        id="input-set__number-baby"
                                        class="input-set__number input-set__number-baby"
                                        type="text"
                                        name="baby"
                                        v-model="passenger_baby_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementPassenger(PASSENGER_TYPE_BABY)">
                                        ＋
                                    </button>
                                </div>
                            </li>
                            <li class="input-set__link" v-on:click="aboutInfane()">
                                <a>※幼児について</a>
                            </li>
                        </ul>
                        <div class="input-set__buttons">
                            <button
                                id="input-set__setting-people"
                                class="btn input-set__setting-full input-set__setting-people"
                                type="button"
                                v-on:click="commitPassenger()"
                            >
                                閉じる
                            </button>
                        </div>
                    </div>
                </li>
                <li id="search__item-car" class="search__item search__item-car">
                    <input
                        id="search__input-car"
                        class="search__input input-search input-search-car"
                        type="text"
                        placeholder="車両を選択してください"
                        v-model="disp_car"
                        readonly
                    />
                    <div id="search__set-car" class="search__set search__set-car none">
                        <ul class="input-set__list">
                            <li id="input-set__item-size4" class="input-set__item input-set__item-size4">
                                <label id="input-set__label-size4" class="input-set__label input-set__label-size4" for="input-set__number-size4"
                                    >4m未満</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementCar(4)">－</button>
                                    <input
                                        id="input-set__number-size4"
                                        class="input-set__number input-set__number-size4"
                                        type="text"
                                        name="size4"
                                        v-model="car_4m_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementCar(4)">＋</button>
                                </div>
                            </li>
                            <li id="input-set__item-size5" class="input-set__item input-set__item-size5">
                                <label id="input-set__label-size5" class="input-set__label input-set__label-size5" for="input-set__number-size5"
                                    >5m未満</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementCar(5)">－</button>
                                    <input
                                        id="input-set__number-size5"
                                        class="input-set__number input-set__number-size5"
                                        type="text"
                                        name="size5"
                                        v-model="car_5m_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementCar(5)">＋</button>
                                </div>
                            </li>
                            <li id="input-set__item-size6" class="input-set__item input-set__item-size6">
                                <label id="input-set__label-size6" class="input-set__label input-set__label-size6" for="input-set__number-size6"
                                    >6m未満</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementCar(6)">－</button>
                                    <input
                                        id="input-set__number-size6"
                                        class="input-set__number input-set__number-size6"
                                        type="text"
                                        name="size6"
                                        v-model="car_6m_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementCar(6)">＋</button>
                                </div>
                            </li>
                        </ul>
                        <div class="input-set__buttons">
                            <button
                                id="input-set__setting-car"
                                class="btn input-set__setting-full input-set__setting-car"
                                type="button"
                                v-on:click="commitCar()"
                            >
                                閉じる
                            </button>
                        </div>
                    </div>
                </li>
                <li id="search__item-bike" class="search__item search__item-bike">
                    <input
                        id="search__input-bike"
                        class="search__input input-search input-search-bike"
                        type="text"
                        placeholder="二輪車を選択してください"
                        v-model="disp_two_wheel"
                        readonly
                    />
                    <div id="search__set-bike" class="search__set search__set-bike none">
                        <ul class="input-set__list">
                            <li id="input-set__item-bicycle" class="input-set__item input-set__item-bicycle">
                                <label id="input-set__label-bicycle" class="input-set__label input-set__label-bicycle" for="input-set__number-bicycle"
                                    >自転車</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementTwoWheel(CAR_TYPE_BICYCLE)">－</button>
                                    <input
                                        id="input-set__number-bicycle"
                                        class="input-set__number input-set__number-bicycle"
                                        type="text"
                                        name="bicycle"
                                        v-model="car_bicycle_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementTwoWheel(CAR_TYPE_BICYCLE)">＋</button>
                                </div>
                            </li>
                            <li id="input-set__item-under125" class="input-set__item input-set__item-under125">
                                <label
                                    id="input-set__label-under125"
                                    class="input-set__label input-set__label-under125"
                                    for="input-set__number-under125"
                                    >125cc未満</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementTwoWheel(CAR_TYPE_TWO_WHEEL_SMALL)">
                                        －
                                    </button>
                                    <input
                                        id="input-set__number-under125"
                                        class="input-set__number input-set__number-under125"
                                        type="text"
                                        name="under125"
                                        v-model="car_two_wheel_small_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementTwoWheel(CAR_TYPE_TWO_WHEEL_SMALL)">
                                        ＋
                                    </button>
                                </div>
                            </li>
                            <li id="input-set__item-under750" class="input-set__item input-set__item-under750">
                                <label
                                    id="input-set__label-under750"
                                    class="input-set__label input-set__label-under750"
                                    for="input-set__number-under750"
                                    >125cc〜750cc未満</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementTwoWheel(CAR_TYPE_TWO_WHEEL_MEDIUM)">
                                        －
                                    </button>
                                    <input
                                        id="input-set__number-under750"
                                        class="input-set__number input-set__number-under750"
                                        type="text"
                                        name="under750"
                                        v-model="car_two_wheel_medium_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementTwoWheel(CAR_TYPE_TWO_WHEEL_MEDIUM)">
                                        ＋
                                    </button>
                                </div>
                            </li>
                            <li id="input-set__item-over750" class="input-set__item input-set__item-over750">
                                <label id="input-set__label-over750" class="input-set__label input-set__label-over750" for="input-set__number-over750"
                                    >750cc以上</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementTwoWheel(CAR_TYPE_TWO_WHEEL_LARGE)">
                                        －
                                    </button>
                                    <input
                                        id="input-set__number-over750"
                                        class="input-set__number input-set__number-over750"
                                        type="text"
                                        name="over750"
                                        v-model="car_two_wheel_large_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementTwoWheel(CAR_TYPE_TWO_WHEEL_LARGE)">
                                        ＋
                                    </button>
                                </div>
                            </li>
                        </ul>
                        <div class="input-set__buttons">
                            <button
                                id="input-set__setting-bike"
                                class="btn input-set__setting-full input-set__setting-bike"
                                type="button"
                                v-on:click="commitTwoWheel()"
                            >
                                閉じる
                            </button>
                        </div>
                    </div>
                </li>
                <li id="search__item-pet" class="search__item search__item-pet">
                    <input
                        id="search__input-pet"
                        class="search__input input-search input-search-pet"
                        type="text"
                        placeholder="ペットケージを選択してください"
                        v-model="disp_cage"
                        readonly
                    />
                    <div id="search__set-pet" class="search__set search__set-pet none">
                        <ul class="input-set__list">
                            <li id="input-set__item-bigroom" class="input-set__item input-set__item-bigroom">
                                <label id="input-set__label-bigroom" class="input-set__label input-set__label-bigroom" for="input-set__number-bigroom"
                                    >大型ケージ</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementCage(CAGE_TYPES_LARGE)">－</button>
                                    <input
                                        id="input-set__number-bigroom"
                                        class="input-set__number input-set__number-bigroom"
                                        type="text"
                                        name="bigroom"
                                        v-model="cage_large_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementCage(CAGE_TYPES_LARGE)">＋</button>
                                </div>
                            </li>
                            <li id="input-set__item-smallroom" class="input-set__item input-set__item-smallroom">
                                <label
                                    id="input-set__label-smallroom"
                                    class="input-set__label input-set__label-smallroom"
                                    for="input-set__number-smallroom"
                                    >中小型ケージ</label
                                >
                                <div class="input-set__change">
                                    <button class="input-set__count minus" type="button" v-on:click="decrementCage(CAGE_TYPES_SMALL)">－</button>
                                    <input
                                        id="input-set__number-smallroom"
                                        class="input-set__number input-set__number-smallroom"
                                        type="text"
                                        name="smallroom"
                                        v-model="cage_small_num"
                                        readonly
                                    />
                                    <button class="input-set__count plus" type="button" v-on:click="incrementCage(CAGE_TYPES_SMALL)">＋</button>
                                </div>
                            </li>
                            <li id="input-set__item_notes" class="input-set__item">
                                <p>※ ペットケージにお預けの場合のみ選択してください。</p>
                            </li>
                            <li id="input-set__item_notes" class="input-set__item">
                                <p>
                                    ※ 1ケージ2匹までとなります。<br />
                                    　
                                    <a href="https://www.miyazakicarferry.com/booking/precautions/#pet" target="_blank">詳しくはこちら</a>
                                </p>
                            </li>
                        </ul>
                        <div class="input-set__buttons">
                            <button
                                id="input-set__setting-pet"
                                class="btn input-set__setting-full input-set__setting-pet"
                                type="button"
                                v-on:click="commitCage()"
                            >
                                閉じる
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
            <button id="btn-search" class="btn btn-search" type="button" v-on:click="searchPlan" jump="#specify">部屋・プランを検索する</button>
        </div>
    </section>
    <!-- /search -->
    <!-- animation -->
    <ShipAnimationStart :sea_route_code="sea_route_code"></ShipAnimationStart>
    <!-- /animation -->
    <!-- specify -->
    <section id="specify" class="specify">
        <div class="specify__container">
            <ul class="specify__data-list">
                <li v-show="!isNull(search_conditions_value.date)" class="specify__data-item specify__data-item-date" id="specify__data-item-date">
                    {{ search_conditions_value.date }}
                </li>
                <li v-show="!isNull(search_conditions_value.route)" class="specify__data-item specify__data-item-route" id="specify__data-item-route">
                    {{ search_conditions_value.route }}
                </li>
                <li
                    v-show="!isNull(search_conditions_value.people)"
                    class="specify__data-item specify__data-item-people"
                    id="specify__data-item-people"
                >
                    {{ search_conditions_value.people }}
                </li>
                <li v-show="!isNull(search_conditions_value.car)" class="specify__data-item specify__data-item-car" id="specify__data-item-car">
                    {{ search_conditions_value.car }}
                </li>
                <li v-show="!isNull(search_conditions_value.bike)" class="specify__data-item specify__data-item-bike" id="specify__data-item-bike">
                    {{ search_conditions_value.bike }}
                </li>
                <li v-show="!isNull(search_conditions_value.pet)" class="specify__data-item specify__data-item-pet" id="specify__data-item-pet">
                    {{ search_conditions_value.pet }}
                </li>
            </ul>
        </div>
    </section>
    <!-- /specify -->
    <!-- result -->
    <PlanList
        v-show="true"
        ref="searchedPlanList"
        :isShow="isShowPlanList"
        :dep_date="dep_date"
        :sea_route_code="sea_route_code"
        :ship_code="ship_schedule?.ship_code"
        :passenger_adult_num="passenger_adult_num"
        :passenger_child_num="passenger_child_num"
        :passenger_infant_num="passenger_infant_num"
        :passenger_baby_num="passenger_baby_num"
        :car_num="car_num"
        :car_3m_num="car_3m_num"
        :car_4m_num="car_4m_num"
        :car_5m_num="car_5m_num"
        :car_6m_num="car_6m_num"
        :car_bicycle_num="car_bicycle_num"
        :car_two_wheel_small_num="car_two_wheel_small_num"
        :car_two_wheel_medium_num="car_two_wheel_medium_num"
        :car_two_wheel_large_num="car_two_wheel_large_num"
        :cage_small_num="cage_small_num"
        :cage_large_num="cage_large_num"
        v-on:openPlanDetail="openShipScheduleWarning"
        v-on:openRoomDetails="openRoomDetails"
        v-on:selectPlanCalendarDepDate="selectPlanCalendarDepDate"
        v-on:displayMessage="displayMessage"
    ></PlanList>
    <!-- room -->
    <button id="btn-room-open-modal" data-remodal-target="room" v-show="false">○</button>
    <button id="btn-room-close-modal" data-remodal-action="cancel" v-show="false">×</button>
    <div id="room" class="room remodal" data-remodal-id="room">
        <button class="modal__close" data-remodal-action="cancel">×</button>
        <div class="room__name">
            {{ get_seat_type_name(room_detail_seat_type) }}
        </div>
        <div id="room__slider" class="room__slider swiper">
            <template v-for="seat_type in [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]" :key="seat_type">
                <div class="story-visuals" v-show="seat_type == room_detail_seat_type">
                    <swiper
                        ref="roomSwiper"
                        :modules="swiperModules"
                        :pagination="swiperPagination(seat_type)"
                        :navigation="swiperNavigation"
                        :loop="true"
                    >
                        <template v-if="room_detail_seat_type == SEAT_TYPE_P_TW">
                            <swiperSlide><img :src="images[0][0]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[0][1]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[0][2]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[0][3]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[0][4]" alt="" /></swiperSlide>
                        </template>
                        <template v-if="room_detail_seat_type == SEAT_TYPE_P_TR">
                            <swiperSlide><img :src="images[1][0]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[1][1]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[1][2]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[1][3]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[1][4]" alt="" /></swiperSlide>
                        </template>
                        <template v-if="room_detail_seat_type == SEAT_TYPE_P_BA">
                            <swiperSlide><img :src="images[2][0]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[2][1]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[2][2]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[2][3]" alt="" /></swiperSlide>
                        </template>
                        <template v-if="room_detail_seat_type == SEAT_TYPE_F_TW">
                            <swiperSlide><img :src="images[3][0]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[3][1]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[3][2]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[3][3]" alt="" /></swiperSlide>
                        </template>
                        <template v-if="room_detail_seat_type == SEAT_TYPE_F_FO">
                            <swiperSlide><img :src="images[4][0]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[4][1]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[4][2]" alt="" /></swiperSlide>
                        </template>
                        <template v-if="room_detail_seat_type == SEAT_TYPE_FWP_TW">
                            <swiperSlide><img :src="images[5][0]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[5][1]" alt="" /></swiperSlide>
                        </template>
                        <template v-if="room_detail_seat_type == SEAT_TYPE_FWP_FO">
                            <swiperSlide><img :src="images[6][0]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[6][1]" alt="" /></swiperSlide>
                        </template>
                        <template v-if="room_detail_seat_type == SEAT_TYPE_S">
                            <swiperSlide><img :src="images[7][0]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[7][1]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[7][2]" alt="" /></swiperSlide>
                        </template>
                        <template v-if="room_detail_seat_type == SEAT_TYPE_DS"> </template>
                        <template v-if="room_detail_seat_type == SEAT_TYPE_D">
                            <swiperSlide><img :src="images[9][0]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[9][1]" alt="" /></swiperSlide>
                        </template>
                        <template v-if="room_detail_seat_type == SEAT_TYPE_T">
                            <swiperSlide><img :src="images[10][0]" alt="" /></swiperSlide>
                            <swiperSlide><img :src="images[10][1]" alt="" /></swiperSlide>
                        </template>
                        <!-- 左右移動 -->
                        <span class="story-navi swiper-button-next"></span>
                        <span class="story-navi swiper-button-prev"></span>
                    </swiper>
                    <!-- サムネイル一覧 -->
                    <div :class="'swiper-container slider-thumbnail slider-thumbnail_' + seat_type"></div>
                </div>
            </template>
            <!-- </template> -->
        </div>
        <p class="room__text" v-html="get_seat_type_room_information(room_detail_seat_type)"></p>
        <dl id="room__list" class="room__list">
            <div id="room__type" class="room__type">
                <dt id="room__name-type" class="room__name-type">お部屋タイプ</dt>
                <dd id="room__content-type" class="room__content-type" v-html="get_seat_type_room_type(room_detail_seat_type)"></dd>
            </div>
            <div class="room__view">
                <dt id="room__name-view" class="room__name-view">眺望</dt>
                <dd id="room__content-view" class="room__content-view" v-html="get_seat_type_view(room_detail_seat_type)"></dd>
            </div>
            <div class="room__capacity">
                <dt id="room__name-capacity" class="room__name-capacity">定員</dt>
                <dd id="room__content-capacity" class="room__content-capacity" v-html="get_seat_type_capacity(room_detail_seat_type)"></dd>
            </div>
            <div class="room__wide">
                <dt id="room__name-wide" class="room__name-wide">部屋の広さ</dt>
                <dd id="room__content-wide" class="room__content-wide" v-html="get_seat_type_wide(room_detail_seat_type)"></dd>
            </div>
            <div class="room__facility">
                <dt id="room__name-facility" class="room__name-facility">設備・備品</dt>
                <dd id="room__content-facility" class="room__content-facility" v-html="get_seat_type_facility(room_detail_seat_type)"></dd>
            </div>
        </dl>
    </div>
    <!-- /room -->
    <!-- /result -->
    <ShipAnimationEnd></ShipAnimationEnd>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, ref, watch, onMounted, nextTick, computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import { useStore } from '@/store'
import * as yup from 'yup'
import $ from 'jquery'
import { useRoute, useRouter } from 'vue-router'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import PlanList from './PlanList.vue'
import CalendarPicker from '@/components/common/CalendarPicker.vue'
import {
    API_USERS,
    API_ORDER,
    isNull,
    formatDate,
    castStringToDate,
    nextMonth,
    lastMonthDate,
    useMinRoomSeatCount,
    getSeaRouteCodeName,
    getSeatTypeName,
    getShipSchedule,
    getStockPets,
    ROOM_INFORMATIONS,
    SEA_ROUTE_CODE_MIYAZAKI,
    SEA_ROUTE_CODE_KOBE,
    CAR_TYPE_TWO_WHEEL_LARGE,
    CAR_TYPE_TWO_WHEEL_MEDIUM,
    CAR_TYPE_TWO_WHEEL_SMALL,
    CAR_TYPE_BICYCLE,
    SHIP_CODE_ROKKO,
    setRouterPushProperties,
    useMaxRoomPassengerCount,
} from '@/lib/util'
import {
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_INFANT,
    PASSENGER_TYPE_BABY,
    CAGE_TYPES_LARGE,
    CAGE_TYPES_SMALL,
    VEHICLE_RESTRICT_NONE,
    VEHICLE_RESTRICT_CAR,
    VEHICLE_RESTRICT_TWO_AXLE_CAR,
    VEHICLE_RESTRICT_PASSENGER,
    CHILD_RESTRICT_NONE,
    CHILD_RESTRICT_CHILD,
    CHILD_RESTRICT_CHILD_OR_INFANT,
    PERSONALITY_TYPE_INDIVIDUAL,
    SEAT_TYPE_P_TW,
    SEAT_TYPE_P_TR,
    SEAT_TYPE_P_BA,
    SEAT_TYPE_F_TW,
    SEAT_TYPE_F_FO,
    SEAT_TYPE_FWP_TW,
    SEAT_TYPE_FWP_FO,
    SEAT_TYPE_S,
    SEAT_TYPE_DS,
    SEAT_TYPE_D,
    SEAT_TYPE_T,
    DEP_STATUS_NORMAL,
} from '@/lib/constants'
import {
    Booking,
    Boarding,
    PassengerDetail,
    CarDetail,
    CageDetail,
    HeadcountDistribution,
    ShipSchedule,
    SearchConfirmationVacancy,
} from '@/lib/types'
import {
    setBookingData,
    setSearchConfirmationVacancyData,
    getSearchConfirmationVacancyData,
    removeSearchConfirmationVacancyData,
} from '@/lib/localStorageUtil'
import ShipAnimationStart from '@/components/common/ShipAnimationStart.vue'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'

import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'

import imgRoom10_01 from '@/images/rooms/10/room10_01.jpg'
import imgRoom10_02 from '@/images/rooms/10/room10_02.jpg'
import imgRoom10_03 from '@/images/rooms/10/room10_03.jpg'
import imgRoom10_04 from '@/images/rooms/10/room10_04.jpg'
import imgRoom10_05 from '@/images/rooms/10/room10_05.jpg'
import imgRoom11_01 from '@/images/rooms/10/room11_01.jpg'
import imgRoom11_02 from '@/images/rooms/10/room11_02.jpg'
import imgRoom11_03 from '@/images/rooms/10/room11_03.jpg'
import imgRoom11_04 from '@/images/rooms/10/room11_04.jpg'
import imgRoom11_05 from '@/images/rooms/10/room11_05.jpg'
import imgRoom12_01 from '@/images/rooms/10/room12_01.jpg'
import imgRoom12_02 from '@/images/rooms/10/room12_02.jpg'
import imgRoom12_03 from '@/images/rooms/10/room12_03.jpg'
import imgRoom12_04 from '@/images/rooms/10/room12_04.jpg'
import imgRoom13_01 from '@/images/rooms/10/room13_01.jpg'
import imgRoom13_02 from '@/images/rooms/10/room13_02.jpg'
import imgRoom13_03 from '@/images/rooms/10/room13_03.jpg'
import imgRoom13_04 from '@/images/rooms/10/room13_04.jpg'
import imgRoom14_01 from '@/images/rooms/10/room14_01.jpg'
import imgRoom14_02 from '@/images/rooms/10/room14_02.jpg'
// import imgRoom14_03 from '@/images/rooms/10/room14_03.jpg';
import imgRoom14_04 from '@/images/rooms/10/room14_04.jpg'
import imgRoom15_01 from '@/images/rooms/10/room15_01.jpg'
import imgRoom15_02 from '@/images/rooms/10/room15_02.jpg'
import imgRoom15_03 from '@/images/rooms/10/room15_03.jpg'
import imgRoom17_01 from '@/images/rooms/10/room17_01.jpg'
import imgRoom17_02 from '@/images/rooms/10/room17_02.jpg'
import imgRoom17_03 from '@/images/rooms/10/room17_03.jpg'
import imgRoom19_01 from '@/images/rooms/10/room19_01.jpg'
import imgRoom19_02 from '@/images/rooms/10/room19_02.jpg'
import imgRoom20_01 from '@/images/rooms/10/room20_01.jpg'
import imgRoom20_02 from '@/images/rooms/10/room20_02.jpg'

const images = [
    [imgRoom10_01, imgRoom10_02, imgRoom10_03, imgRoom10_04, imgRoom10_05],
    [imgRoom11_01, imgRoom11_02, imgRoom11_03, imgRoom11_04, imgRoom11_05],
    [imgRoom12_01, imgRoom12_02, imgRoom12_03, imgRoom12_04],
    [imgRoom13_01, imgRoom13_02, imgRoom13_03, imgRoom13_04],
    [imgRoom14_01, imgRoom14_02, imgRoom14_04],
    [imgRoom15_01, imgRoom15_03],
    [imgRoom15_02, imgRoom15_03],
    [imgRoom17_01, imgRoom17_02, imgRoom17_03],
    [],
    [imgRoom19_01, imgRoom19_02],
    [imgRoom20_01, imgRoom20_02],
]

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        const searchedPlanList = ref<InstanceType<typeof PlanList>>()
        const roomSwiper = ref<InstanceType<typeof Swiper>>()

        //【注意】javascriptの月と表示の月に1カ月のズレあり
        const disabledDates = ref({
            to: new Date(), // 本日以前の日付は選択不可
            from: nextMonth(new Date(), 12) as Date | null, // nextMonth(new Date(), 2), // 2ヶ月先以降の日付が選択不可
            // days: [], // 曜日指定
            // daysOfMonth: [], // 各月の選択不可
            dates: [],
            // ranges: [{ // 選択不可の期間を指定
            //     from: new Date(2023, 1, 1),
            //     to: new Date(2023, 3, 15)
            // }],
        })

        const isShowPlanList = ref(false)
        const dep_date = ref()
        const sea_route_code = ref('')
        const ship_schedule = ref<ShipSchedule>()
        const disp_sea_route_code = ref('')

        const passenger_detail_array = ref<PassengerDetail[]>()
        const passenger_detail_baby_array = ref<PassengerDetail[]>()
        const disp_passenger = ref('')
        const passenger_adult_num = ref(0)
        const passenger_child_num = ref(0)
        const passenger_infant_num = ref(0)
        const passenger_baby_num = ref(0)

        const car_detail_array = ref<CarDetail[]>()
        const disp_car = ref('')
        const car_num = ref(0)
        const car_3m_num = ref(0)
        const car_4m_num = ref(0)
        const car_5m_num = ref(0)
        const car_6m_num = ref(0)

        const two_wheel_detail_array = ref<CarDetail[]>()
        const disp_two_wheel = ref('')
        const car_bicycle_num = ref(0)
        const car_two_wheel_small_num = ref(0)
        const car_two_wheel_medium_num = ref(0)
        const car_two_wheel_large_num = ref(0)

        const cage_detail_array = ref<CageDetail[]>()
        const disp_cage = ref('')
        const cage_small_num = ref(0)
        const cage_large_num = ref(0)

        type SearchConditions = {
            date: string
            route: string
            people: string
            car: string
            bike: string
            pet: string
        }
        const search_conditions_value = ref<SearchConditions>({
            date: '乗船日：指定なし',
            route: '航路：指定なし',
            people: '人数：指定なし',
            car: '乗用車：なし',
            bike: '二輪車：なし',
            pet: 'ペットルーム：なし',
        })
        const room_detail_seat_type = ref(10)

        const search_contract_number = ref('')
        const search_empty_count = ref(0)
        const search_seat_type = ref()
        const search_plan_dep_date = ref()

        // 入力チェック
        const schema = yup.object({
            name: yup.string().required('必須の項目です。').label('名前'),
            email: yup.string().required('必須の項目です。').email('メールアドレスの形式ではありません。').label('メールアドレス'),
        })
        const { errors } = useForm({ validationSchema: schema })
        const { value: name } = useField('name', undefined, { initialValue: '' })
        const { value: email } = useField('email', undefined, { initialValue: '' })

        const updateDepDate = (val: any) => {
            makeSearchConditionsValue()
        }

        // 日付指定
        const dispDepDay = () => {
            if (isNull(dep_date.value)) {
                return '指定なし'
            } else {
                return formatDate(dep_date.value, false, 3)
            }
        }

        // 航路選択
        const dispSeaRoute = () => {
            if (isNull(sea_route_code.value)) {
                return '指定なし'
            }
            return getSeaRouteCodeName(sea_route_code.value)
        }

        // 乗船者
        const incrementPassenger = (passenger_type: number) => {
            if (passenger_type == PASSENGER_TYPE_ADULT) {
                passenger_adult_num.value += 1
            } else if (passenger_type == PASSENGER_TYPE_CHILD) {
                passenger_child_num.value += 1
            } else if (passenger_type == PASSENGER_TYPE_INFANT) {
                passenger_infant_num.value += 1
            } else if (passenger_type == PASSENGER_TYPE_BABY) {
                passenger_baby_num.value += 1
            }
            commitPassenger(false)
        }
        const decrementPassenger = (passenger_type: number) => {
            if (passenger_type == PASSENGER_TYPE_ADULT && passenger_adult_num.value > 0) {
                passenger_adult_num.value -= 1
            } else if (passenger_type == PASSENGER_TYPE_CHILD && passenger_child_num.value > 0) {
                passenger_child_num.value -= 1
            } else if (passenger_type == PASSENGER_TYPE_INFANT && passenger_infant_num.value > 0) {
                passenger_infant_num.value -= 1
            } else if (passenger_type == PASSENGER_TYPE_BABY && passenger_baby_num.value > 0) {
                passenger_baby_num.value -= 1
            }
            commitPassenger(false)
        }
        const dispPassengerNum = () => {
            let values = []

            if (passenger_adult_num.value > 0) {
                values.push(`大人${passenger_adult_num.value}名`)
            }
            if (passenger_child_num.value > 0) {
                values.push(`小人${passenger_child_num.value}名`)
            }
            if (passenger_infant_num.value > 0) {
                values.push(`幼児${passenger_infant_num.value}名`)
            }
            if (passenger_baby_num.value > 0) {
                values.push(`乳児${passenger_baby_num.value}名`)
            }
            if (values.length == 0) {
                return '指定なし'
            }
            return values.join(',')
        }

        const commitPassenger = (hidden = true) => {
            if (hidden) {
                $('#search__set-people').hide()
            }
            // 乗船者人数確定時処理
            disp_passenger.value = ''
            if (passenger_adult_num.value > 0) {
                disp_passenger.value += `大人${passenger_adult_num.value}名`
            }
            if (passenger_child_num.value > 0) {
                if (!isNull(disp_passenger.value)) {
                    disp_passenger.value += ','
                }
                disp_passenger.value += `小人${passenger_child_num.value}名`
            }
            if (passenger_infant_num.value > 0) {
                if (!isNull(disp_passenger.value)) {
                    disp_passenger.value += ','
                }
                disp_passenger.value += `幼児${passenger_infant_num.value}名`
            }
            if (passenger_baby_num.value > 0) {
                if (!isNull(disp_passenger.value)) {
                    disp_passenger.value += ','
                }
                disp_passenger.value += `乳児${passenger_baby_num.value}名`
            }
        }

        // 車
        const incrementCar = (car_length: number) => {
            if (car_length == 3) {
                car_3m_num.value += 1
            } else if (car_length == 4) {
                car_4m_num.value += 1
            } else if (car_length == 5) {
                car_5m_num.value += 1
            } else if (car_length == 6) {
                car_6m_num.value += 1
            }
            commitCar(false)
        }
        const decrementCar = (car_length: number) => {
            if (car_length == 3 && car_3m_num.value > 0) {
                car_3m_num.value -= 1
            } else if (car_length == 4 && car_4m_num.value > 0) {
                car_4m_num.value -= 1
            } else if (car_length == 5 && car_5m_num.value > 0) {
                car_5m_num.value -= 1
            } else if (car_length == 6 && car_6m_num.value > 0) {
                car_6m_num.value -= 1
            }
            commitCar(false)
        }
        const dispCarNum = () => {
            let values = []
            if (car_3m_num.value > 0) {
                values.push(`3m未満${car_3m_num.value}台`)
            }
            if (car_4m_num.value > 0) {
                values.push(`4m未満${car_4m_num.value}台`)
            }
            if (car_5m_num.value > 0) {
                values.push(`5m未満${car_5m_num.value}台`)
            }
            if (car_6m_num.value > 0) {
                values.push(`6m未満${car_6m_num.value}台`)
            }
            if (values.length == 0) {
                return 'なし'
            }
            return values.join(',')
        }
        const commitCar = (hidden = true) => {
            if (hidden) {
                $('#search__set-bike').hide()
            }
            disp_car.value = ''
            if (car_3m_num.value > 0) {
                disp_car.value += `3m未満${car_3m_num.value}台`
            }
            if (car_4m_num.value > 0) {
                if (!isNull(disp_car.value)) {
                    disp_car.value += ','
                }
                disp_car.value += `4m未満${car_4m_num.value}台`
            }
            if (car_5m_num.value > 0) {
                if (!isNull(disp_car.value)) {
                    disp_car.value += ','
                }
                disp_car.value += `5m未満${car_5m_num.value}台`
            }
            if (car_6m_num.value > 0) {
                if (!isNull(disp_car.value)) {
                    disp_car.value += ','
                }
                disp_car.value += `6m未満${car_6m_num.value}台`
            }
        }

        // 2輪
        const incrementTwoWheel = (car_type: string) => {
            if (car_type == CAR_TYPE_BICYCLE) {
                car_bicycle_num.value += 1
            } else if (car_type == CAR_TYPE_TWO_WHEEL_SMALL) {
                car_two_wheel_small_num.value += 1
            } else if (car_type == CAR_TYPE_TWO_WHEEL_MEDIUM) {
                car_two_wheel_medium_num.value += 1
            } else if (car_type == CAR_TYPE_TWO_WHEEL_LARGE) {
                car_two_wheel_large_num.value += 1
            }
            commitTwoWheel(false)
        }
        const decrementTwoWheel = (car_type: string) => {
            if (car_type == CAR_TYPE_BICYCLE && car_bicycle_num.value > 0) {
                car_bicycle_num.value -= 1
            } else if (car_type == CAR_TYPE_TWO_WHEEL_SMALL && car_two_wheel_small_num.value > 0) {
                car_two_wheel_small_num.value -= 1
            } else if (car_type == CAR_TYPE_TWO_WHEEL_MEDIUM && car_two_wheel_medium_num.value > 0) {
                car_two_wheel_medium_num.value -= 1
            } else if (car_type == CAR_TYPE_TWO_WHEEL_LARGE && car_two_wheel_large_num.value > 0) {
                car_two_wheel_large_num.value -= 1
            }
            commitTwoWheel(false)
        }
        const dispTwoWheelNum = () => {
            let values = []
            if (car_bicycle_num.value > 0) {
                values.push(`自転車 ${car_bicycle_num.value}台`)
            }
            if (car_two_wheel_small_num.value > 0) {
                values.push(`125cc未満 ${car_two_wheel_small_num.value}台`)
            }
            if (car_two_wheel_medium_num.value > 0) {
                values.push(`125cc～750cc未満 ${car_two_wheel_medium_num.value}台`)
            }
            if (car_two_wheel_large_num.value > 0) {
                values.push(`750cc以上 ${car_two_wheel_large_num.value}台`)
            }
            if (values.length == 0) {
                return 'なし'
            }
            return values.join(',')
        }
        const commitTwoWheel = (hidden = true) => {
            if (hidden) {
                $('#search__set-bike').hide()
            }
            disp_two_wheel.value = ''
            if (car_bicycle_num.value > 0) {
                disp_two_wheel.value += `自転車${car_bicycle_num.value}台`
            }
            if (car_two_wheel_small_num.value > 0) {
                if (!isNull(disp_two_wheel.value)) {
                    disp_two_wheel.value += ','
                }
                disp_two_wheel.value += `125cc未満${car_two_wheel_small_num.value}台`
            }
            if (car_two_wheel_medium_num.value > 0) {
                if (!isNull(disp_two_wheel.value)) {
                    disp_two_wheel.value += ','
                }
                disp_two_wheel.value += `125cc〜750cc未満${car_two_wheel_medium_num.value}台`
            }
            if (car_two_wheel_large_num.value > 0) {
                if (!isNull(disp_two_wheel.value)) {
                    disp_two_wheel.value += ','
                }
                disp_two_wheel.value += `750cc以上${car_two_wheel_large_num.value}台`
            }
        }

        // ペットルーム
        const incrementCage = (cage_type: number) => {
            if (cage_type == CAGE_TYPES_SMALL) {
                cage_small_num.value += 1
            } else if (cage_type == CAGE_TYPES_LARGE) {
                cage_large_num.value += 1
            }
            commitCage(false)
        }
        const decrementCage = (cage_type: number) => {
            if (cage_type == CAGE_TYPES_SMALL && cage_small_num.value > 0) {
                cage_small_num.value -= 1
            } else if (cage_type == CAGE_TYPES_LARGE && cage_large_num.value > 0) {
                cage_large_num.value -= 1
            }
            commitCage(false)
        }
        const dispCageNum = () => {
            let values = []
            if (cage_large_num.value > 0) {
                values.push(`大型 ${cage_large_num.value}部屋`)
            }
            if (cage_small_num.value > 0) {
                values.push(`中小型 ${cage_small_num.value}部屋`)
            }
            if (values.length == 0) {
                return 'なし'
            }
            return values.join(',')
        }
        const commitCage = (hidden = true) => {
            if (hidden) {
                $('#search__set-pet').hide()
            }
            disp_cage.value = ''
            if (cage_large_num.value > 0) {
                disp_cage.value += `大型${cage_large_num.value}部屋`
            }
            if (cage_small_num.value > 0) {
                if (!isNull(disp_cage.value)) {
                    disp_cage.value += ','
                }
                disp_cage.value += `中小型${cage_small_num.value}部屋`
            }
        }

        // 部屋情報
        // 部屋タイプ名称取得
        const get_seat_type_name = (seat_type: number): string => {
            return getSeatTypeName(seat_type)
        }
        // お部屋情報取得
        const get_seat_type_room_information = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.information.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // お部屋タイプ取得
        const get_seat_type_room_type = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.room_type.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // 眺望取得
        const get_seat_type_view = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.view.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // 定員取得
        const get_seat_type_capacity = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.capacity.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // 部屋の広さ取得
        const get_seat_type_wide = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.wide.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // 設備・備品取得
        const get_seat_type_facility = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.facility.replace(/\n/g, '<br>')
                }
            }
            return ''
        }
        // 部屋タイプ等級取得
        const get_seat_type_grade = (seat_type: number): number => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.grade
                }
            }
            return 10
        }

        // プラン検索
        const searchPlan = async () => {
            console.log('↓↓↓ConfirmationVacancy.search_plan↓↓↓')
            isShowPlanList.value = false
            searchedPlanList.value!.clearPlanList()
            // 検索条件表示
            makeSearchConditionsValue()
            if (!(await chkSearchPlanValidation())) {
                return
            }
            // 航路
            let seaRouteCode = sea_route_code.value
            // 開始日・終了日
            let startDate = ''
            let endDate = ''
            if (!isNull(dep_date.value)) {
                startDate = formatDate(dep_date.value!, true, 0)
                endDate = formatDate(dep_date.value!, true, 0)
                // 運航予定取得
                ship_schedule.value = await getShipSchedule(formatDate(dep_date.value!, true, 0), sea_route_code.value)
                if (isNull(ship_schedule.value) || !('dep_status' in ship_schedule.value!)) {
                    context.emit('displayMessage', {
                        msg_title: '部屋・プラン検索',
                        msg: [
                            `${formatDate(dep_date.value, true, 1)}（${getSeaRouteCodeName(
                                sea_route_code.value,
                            )}）  ご予約できない日付となっております。`,
                            'お手数ですが、別の乗船日をご指定願います。',
                        ],
                        close_btn: true,
                    })
                    return
                } else if (ship_schedule.value.dep_status != DEP_STATUS_NORMAL) {
                    context.emit('displayMessage', {
                        msg_title: '部屋・プラン検索',
                        msg: [
                            `${formatDate(dep_date.value, true, 1)}（${getSeaRouteCodeName(
                                sea_route_code.value,
                            )}） こちらの便は運休日となっております。`,
                            'お手数ですが、別の乗船日をご指定願います。',
                        ],
                        close_btn: true,
                    })
                    return
                }
            } else {
                startDate = formatDate(new Date())
                endDate = formatDate(nextMonth(new Date(), 2))
            }
            // 有料乗船者人数合計
            let people_num = passenger_adult_num.value + passenger_child_num.value
            // 車両制限
            let vehicleRestrict = VEHICLE_RESTRICT_NONE
            // ドライバー人数
            let driver_num = 0
            if (!isNull(car_3m_num.value)) {
                driver_num += car_3m_num.value
            }
            if (!isNull(car_4m_num.value)) {
                driver_num += car_4m_num.value
            }
            if (!isNull(car_5m_num.value)) {
                driver_num += car_5m_num.value
            }
            if (!isNull(car_6m_num.value)) {
                driver_num += car_6m_num.value
            }
            if (
                car_3m_num.value == 0 &&
                car_4m_num.value == 0 &&
                car_5m_num.value == 0 &&
                car_6m_num.value == 0 &&
                car_bicycle_num.value == 0 &&
                car_two_wheel_small_num.value == 0 &&
                car_two_wheel_medium_num.value == 0 &&
                car_two_wheel_large_num.value == 0
            ) {
                vehicleRestrict = VEHICLE_RESTRICT_PASSENGER
            } else if (
                car_3m_num.value == 0 &&
                car_4m_num.value == 0 &&
                car_5m_num.value == 0 &&
                car_6m_num.value == 0 &&
                (car_bicycle_num.value > 0 ||
                    car_two_wheel_small_num.value > 0 ||
                    car_two_wheel_medium_num.value > 0 ||
                    car_two_wheel_large_num.value > 0)
            ) {
                vehicleRestrict = VEHICLE_RESTRICT_TWO_AXLE_CAR
            } else if (
                (car_3m_num.value > 0 || car_4m_num.value > 0 || car_5m_num.value > 0 || car_6m_num.value > 0) &&
                car_bicycle_num.value == 0 &&
                car_two_wheel_small_num.value == 0 &&
                car_two_wheel_medium_num.value == 0 &&
                car_two_wheel_large_num.value == 0
            ) {
                vehicleRestrict = VEHICLE_RESTRICT_CAR
            }
            // お子様連れ制限
            let childRestrict = CHILD_RESTRICT_NONE
            if (passenger_infant_num.value > 0 || passenger_baby_num.value > 0) {
                childRestrict = CHILD_RESTRICT_CHILD_OR_INFANT
            } else if (passenger_child_num.value > 0) {
                childRestrict = CHILD_RESTRICT_CHILD
            }
            // ペット連れ制限
            let petsRestrict = 0
            if (cage_small_num.value > 0 || cage_large_num.value > 0) {
                petsRestrict = 1
            }
            nextTick(async () => {
                let data = null
                try {
                    // store.dispatch('setIsProcessing', true);
                    isShowPlanList.value = true
                    const postData = {
                        sea_route_code: seaRouteCode,
                        start_date: startDate,
                        end_date: endDate,
                        people_num: people_num,
                        vehicle_restrict: vehicleRestrict,
                        child_restrict: childRestrict,
                        pets_restrict: petsRestrict,
                        adult_num: isNull(passenger_adult_num.value) ? 0 : Number(passenger_adult_num.value) - driver_num,
                        driver_num: driver_num,
                        child_num: isNull(passenger_child_num.value) ? 0 : Number(passenger_child_num.value),
                        car4m_num: isNull(car_4m_num.value) ? 0 : Number(car_4m_num.value),
                        car5m_num: isNull(car_5m_num.value) ? 0 : Number(car_5m_num.value),
                        car6m_num: isNull(car_6m_num.value) ? 0 : Number(car_6m_num.value),
                        bicycle_num: isNull(car_bicycle_num.value) ? 0 : Number(car_bicycle_num.value),
                        small_motorcycle_num: isNull(car_two_wheel_small_num.value) ? 0 : Number(car_two_wheel_small_num.value),
                        middle_motorcycle_num: isNull(car_two_wheel_medium_num.value) ? 0 : Number(car_two_wheel_medium_num.value),
                        large_motorcycle_num: isNull(car_two_wheel_large_num.value) ? 0 : Number(car_two_wheel_large_num.value),
                    }
                    data = await apiClient
                        .post(`${API_ORDER}/plan`, postData)
                        .then((res: AxiosResponse<any>) => {
                            const { status, data } = res
                            return data
                        })
                        .catch((e: AxiosError<{ error: string }>) => {
                            // エラー処理
                            console.log(e.message)
                        })
                    // await searchedPlanList.value!.openPlanList(data, car_detail_array.value!);
                    searchedPlanList.value!.openPlanList(data, car_detail_array.value!)
                } catch (e) {
                    console.log(e)
                } finally {
                    // store.dispatch('setIsProcessing', false);
                }
            })

            console.log('↑↑↑ConfirmationVacancy.search_plan↑↑↑')
        }
        const searchPlanSilent = async () => {
            console.log('↓↓↓ConfirmationVacancy.searchPlanSilent↓↓↓')
            // 検索条件表示
            makeSearchConditionsValue()
            if (!(await chkSearchPlanValidation())) {
                return
            }
            // 航路
            let seaRouteCode = sea_route_code.value
            // 開始日・終了日
            let startDate = ''
            let endDate = ''
            if (!isNull(dep_date.value)) {
                startDate = formatDate(dep_date.value!, true, 0)
                endDate = formatDate(dep_date.value!, true, 0)
                // 運航予定取得
                ship_schedule.value = await getShipSchedule(formatDate(dep_date.value!, true, 0), sea_route_code.value)
            } else {
                startDate = formatDate(new Date())
                endDate = formatDate(nextMonth(new Date(), 2))
            }
            // 有料乗船者人数合計
            let people_num = passenger_adult_num.value + passenger_child_num.value
            // 車両制限
            let vehicleRestrict = VEHICLE_RESTRICT_NONE
            // ドライバー人数
            let driver_num = 0
            if (!isNull(car_3m_num.value)) {
                driver_num += car_3m_num.value
            }
            if (!isNull(car_4m_num.value)) {
                driver_num += car_4m_num.value
            }
            if (!isNull(car_5m_num.value)) {
                driver_num += car_5m_num.value
            }
            if (!isNull(car_6m_num.value)) {
                driver_num += car_6m_num.value
            }
            if (
                car_3m_num.value == 0 &&
                car_4m_num.value == 0 &&
                car_5m_num.value == 0 &&
                car_6m_num.value == 0 &&
                car_bicycle_num.value == 0 &&
                car_two_wheel_small_num.value == 0 &&
                car_two_wheel_medium_num.value == 0 &&
                car_two_wheel_large_num.value == 0
            ) {
                vehicleRestrict = VEHICLE_RESTRICT_PASSENGER
            } else if (
                car_3m_num.value == 0 &&
                car_4m_num.value == 0 &&
                car_5m_num.value == 0 &&
                car_6m_num.value == 0 &&
                (car_bicycle_num.value > 0 ||
                    car_two_wheel_small_num.value > 0 ||
                    car_two_wheel_medium_num.value > 0 ||
                    car_two_wheel_large_num.value > 0)
            ) {
                vehicleRestrict = VEHICLE_RESTRICT_TWO_AXLE_CAR
            } else if (
                (car_3m_num.value > 0 || car_4m_num.value > 0 || car_5m_num.value > 0 || car_6m_num.value > 0) &&
                car_bicycle_num.value == 0 &&
                car_two_wheel_small_num.value == 0 &&
                car_two_wheel_medium_num.value == 0 &&
                car_two_wheel_large_num.value == 0
            ) {
                vehicleRestrict = VEHICLE_RESTRICT_CAR
            }
            // お子様連れ制限
            let childRestrict = CHILD_RESTRICT_NONE
            if (passenger_infant_num.value > 0 || passenger_baby_num.value > 0) {
                childRestrict = CHILD_RESTRICT_CHILD_OR_INFANT
            } else if (passenger_child_num.value > 0) {
                childRestrict = CHILD_RESTRICT_CHILD
            }
            // ペット連れ制限
            let petsRestrict = 0
            if (cage_small_num.value > 0 || cage_large_num.value > 0) {
                petsRestrict = 1
            }
            nextTick(async () => {
                let data = null
                try {
                    const postData = {
                        sea_route_code: seaRouteCode,
                        start_date: startDate,
                        end_date: endDate,
                        people_num: people_num,
                        vehicle_restrict: vehicleRestrict,
                        child_restrict: childRestrict,
                        pets_restrict: petsRestrict,
                        adult_num: isNull(passenger_adult_num.value) ? 0 : Number(passenger_adult_num.value) - driver_num,
                        driver_num: driver_num,
                        child_num: isNull(passenger_child_num.value) ? 0 : Number(passenger_child_num.value),
                        car4m_num: isNull(car_4m_num.value) ? 0 : Number(car_4m_num.value),
                        car5m_num: isNull(car_5m_num.value) ? 0 : Number(car_5m_num.value),
                        car6m_num: isNull(car_6m_num.value) ? 0 : Number(car_6m_num.value),
                        bicycle_num: isNull(car_bicycle_num.value) ? 0 : Number(car_bicycle_num.value),
                        small_motorcycle_num: isNull(car_two_wheel_small_num.value) ? 0 : Number(car_two_wheel_small_num.value),
                        middle_motorcycle_num: isNull(car_two_wheel_medium_num.value) ? 0 : Number(car_two_wheel_medium_num.value),
                        large_motorcycle_num: isNull(car_two_wheel_large_num.value) ? 0 : Number(car_two_wheel_large_num.value),
                    }
                    data = await apiClient
                        .post(`${API_ORDER}/plan`, postData)
                        .then((res: AxiosResponse<any>) => {
                            const { status, data } = res
                            return data
                        })
                        .catch((e: AxiosError<{ error: string }>) => {
                            // エラー処理
                            console.log(e.message)
                        })
                    await searchedPlanList.value!.setPlanListContracts(data, car_detail_array.value!)
                } catch (e) {
                    console.log(e)
                } finally {
                    //
                }
            })

            console.log('↑↑↑ConfirmationVacancy.searchPlanSilent↑↑↑')
        }
        // 入力チェック
        const chkSearchPlanValidation = async (): Promise<boolean> => {
            let err_count = 0
            let err_messages = []

            // エラー表示初期化
            $('#search__item-route').removeClass('input__error')
            $('#search__item-people').removeClass('input__error')
            $('#search__item-car').removeClass('input__error')
            $('#search__item-bike').removeClass('input__error')
            $('#search__item-pet').removeClass('input__error')
            // 航路
            if (isNull(sea_route_code.value)) {
                $('#search__item-route').addClass('input__error')
                err_count++
                err_messages.push('航路を選択してください。')
            }
            // 乗船者
            if (
                passenger_adult_num.value == 0 &&
                passenger_child_num.value == 0 &&
                passenger_infant_num.value == 0 &&
                passenger_baby_num.value == 0
            ) {
                $('#search__item-people').addClass('input__error')
                err_count++
                err_messages.push('人数を選択してください。')
            } else if (passenger_adult_num.value == 0 && passenger_child_num.value == 0 && passenger_baby_num.value > 0) {
                $('#search__item-people').addClass('input__error')
                err_count++
                err_messages.push('乳児のみでの予約はできません。')
            } else if (passenger_adult_num.value < passenger_infant_num.value) {
                $('#search__item-people').addClass('input__error')
                err_count++
                err_messages.push('大人の人数を超える幼児の方は、小人としてご予約ください。')
            }
            // 乗用車台数チェック
            // ドライバー人数
            let driver_num = 0
            if (!isNull(car_3m_num.value)) {
                driver_num += car_3m_num.value
            }
            if (!isNull(car_4m_num.value)) {
                driver_num += car_4m_num.value
            }
            if (!isNull(car_5m_num.value)) {
                driver_num += car_5m_num.value
            }
            if (!isNull(car_6m_num.value)) {
                driver_num += car_6m_num.value
            }
            if (!isNull(car_bicycle_num.value)) {
                driver_num += car_bicycle_num.value
            }
            if (!isNull(car_two_wheel_small_num.value)) {
                driver_num += car_two_wheel_small_num.value
            }
            if (!isNull(car_two_wheel_medium_num.value)) {
                driver_num += car_two_wheel_medium_num.value
            }
            if (!isNull(car_two_wheel_large_num.value)) {
                driver_num += car_two_wheel_large_num.value
            }
            if (driver_num > passenger_adult_num.value) {
                $('#search__item-car').addClass('input__error')
                $('#search__item-bike').addClass('input__error')
                err_count++
                err_messages.push('大人の人数を超える車両・二輪車の予約はできません。')
            }
            // 在庫チェック
            if (!isNull(dep_date.value)) {
                // ペットルーム
                if (cage_large_num.value > 0 || cage_small_num.value > 0) {
                    let stock_pets = await getStockPets(sea_route_code.value, formatDate(dep_date.value), cage_large_num.value, cage_small_num.value)
                    if ((cage_large_num.value > 0 && stock_pets.large_status == 0) || (cage_small_num.value > 0 && stock_pets.middle_status == 0)) {
                        $('#search__item-pet').addClass('input__error')
                        err_count++
                        err_messages.push('ペットルームに空きがありません。')
                    }
                }
            }

            if (err_count != 0) {
                context.emit('displayMessage', {
                    msg_title: '部屋・プラン検索',
                    msg: err_messages,
                    close_btn: true,
                })
            }

            return err_count == 0
        }

        // 出港便基本情報の警告があれば、表示、なければプラン詳細へ
        const openShipScheduleWarning = async (contract_number: string, empty_count: number, seat_type: number, plan_dep_date: Date) => {
            console.log('openShipScheduleWarning ↓↓↓↓↓')
            search_contract_number.value = contract_number
            search_empty_count.value = empty_count
            search_seat_type.value = seat_type
            search_plan_dep_date.value = plan_dep_date

            let warning_msg = ''

            const postData = {
                dep_date: formatDate(plan_dep_date, true, 0),
                sea_route_code: sea_route_code.value,
            }
            warning_msg = await apiClient
                .post(`${API_ORDER}/schedule`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    return data.warnings ?? ''
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    return ''
                })

            if (warning_msg?.length > 0) {
                const message = warning_msg.trim().split(/\r\n|\n/)
                context.emit('displayMessage', {
                    msg_title: 'お知らせ',
                    msg: message,
                    close_btn: true,
                    ok_btn: true,
                    submit_btn: false,
                    ok_event: openPlanDetail,
                    cancel_btn_value: 'キャンセル',
                    ok_btn_value: '詳細・予約へ',
                })
            } else {
                openPlanDetail()
            }
            console.log('openShipScheduleWarning ↑↑↑↑↑')
        }

        // プラン詳細
        const openPlanDetail = () => {
            console.log('↓↓↓ConfirmationVacancy.openPlanDetail↓↓↓')
            try {
                nextTick(() => {})
                store.dispatch('setIsProcessing', true)
                nextTick(() => {})
                dep_date.value = search_plan_dep_date.value

                // 乗用車
                car_detail_array.value = []
                for (let i = 0; i < car_3m_num.value; i++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let defaultCarDetail = {
                        line_no: car_detail_array.value!.length + 1,
                        car_type_code: '04',
                        car_length: 3,
                        car_width: 2.5,
                        car_place_name: '',
                        car_class_number: '',
                        car_hiragana: '',
                        car_number: '',
                        note: '',
                    } as CarDetail
                    car_detail_array.value!.push(defaultCarDetail)
                }
                for (let i = 0; i < car_4m_num.value; i++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let defaultCarDetail = {
                        line_no: car_detail_array.value!.length + 1,
                        car_type_code: '04',
                        car_length: 4,
                        car_width: 2.5,
                        car_place_name: '',
                        car_class_number: '',
                        car_hiragana: '',
                        car_number: '',
                        note: '',
                    } as CarDetail
                    car_detail_array.value!.push(defaultCarDetail)
                }
                for (let i = 0; i < car_5m_num.value; i++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let defaultCarDetail = {
                        line_no: car_detail_array.value!.length + 1,
                        car_type_code: '04',
                        car_length: 5,
                        car_width: 2.5,
                        car_place_name: '',
                        car_class_number: '',
                        car_hiragana: '',
                        car_number: '',
                        note: '',
                    } as CarDetail
                    car_detail_array.value!.push(defaultCarDetail)
                }
                for (let i = 0; i < car_6m_num.value; i++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let defaultCarDetail = {
                        line_no: car_detail_array.value!.length + 1,
                        car_type_code: '04',
                        car_length: 6,
                        car_width: 2.5,
                        car_place_name: '',
                        car_class_number: '',
                        car_hiragana: '',
                        car_number: '',
                        note: '',
                    } as CarDetail
                    car_detail_array.value!.push(defaultCarDetail)
                }

                // 船室
                let headcount_distributions = []
                const min_room_count = useMinRoomSeatCount(
                    search_seat_type.value,
                    passenger_adult_num.value,
                    passenger_child_num.value,
                    passenger_infant_num.value,
                    passenger_baby_num.value,
                    1,
                )
                for (let i = 0; i < min_room_count; i++) {
                    headcount_distributions.push({
                        line_no: i,
                        adult: 0,
                        child: 0,
                        infant: 0,
                        baby: 0,
                        reserved_room: false,
                        room_amount: 0,
                        passenger_detail_array: [] as PassengerDetail[],
                    } as HeadcountDistribution)
                }
                // 1部屋当たり乗船者数
                const passenger_par_room = useMaxRoomPassengerCount(search_seat_type.value, 1)

                // 乗船者
                passenger_detail_array.value = []
                for (let i = 0; i < passenger_adult_num.value; i++) {
                    // 配列が指定人数より少ない場合、デフォルトを追加
                    let tmpLineNo = passenger_detail_array.value!.length + 1
                    let isDriver = false
                    if (tmpLineNo <= car_detail_array.value.length) {
                        isDriver = true
                    }
                    let defaultPassengerDetail = {
                        id: null,
                        line_no: tmpLineNo,
                        gender: undefined,
                        age: null,
                        family_name_katakana: '',
                        given_name_katakana: '',
                        passenger_type: PASSENGER_TYPE_ADULT,
                        represent_flg: false,
                        mcf_member_id: '',
                        with_pets_flg: false,
                        driver: isDriver,
                        reserved_room: false,
                    } as PassengerDetail
                    passenger_detail_array.value!.push(defaultPassengerDetail)
                    headcount_distributions[Math.floor((passenger_detail_array.value!.length - 1) / passenger_par_room)].passenger_detail_array.push(
                        defaultPassengerDetail,
                    )
                    headcount_distributions[Math.floor((passenger_detail_array.value!.length - 1) / passenger_par_room)].adult += 1
                }
                for (let i = 0; i < passenger_child_num.value; i++) {
                    // 配列が指定人数より少ない場合、デフォルトを追加
                    let tmpLineNo = passenger_detail_array.value!.length + 1
                    let defaultPassengerDetail = {
                        id: null,
                        line_no: tmpLineNo,
                        gender: undefined,
                        age: null,
                        family_name_katakana: '',
                        given_name_katakana: '',
                        passenger_type: PASSENGER_TYPE_CHILD,
                        represent_flg: false,
                        mcf_member_id: '',
                        with_pets_flg: false,
                        driver: false,
                        reserved_room: false,
                    } as PassengerDetail
                    passenger_detail_array.value!.push(defaultPassengerDetail)
                    headcount_distributions[Math.floor((passenger_detail_array.value!.length - 1) / passenger_par_room)].passenger_detail_array.push(
                        defaultPassengerDetail,
                    )
                    headcount_distributions[Math.floor((passenger_detail_array.value!.length - 1) / passenger_par_room)].child += 1
                }
                // 乳幼児
                passenger_detail_baby_array.value = []
                for (let i = 0; i < passenger_infant_num.value; i++) {
                    // 配列が指定人数より少ない場合、デフォルトを追加
                    let tmpLineNo = passenger_detail_baby_array.value!.length + 1
                    let defaultPassengerDetail = {
                        id: null,
                        line_no: tmpLineNo,
                        gender: undefined,
                        age: null,
                        family_name_katakana: '',
                        given_name_katakana: '',
                        passenger_type: PASSENGER_TYPE_INFANT,
                        represent_flg: false,
                        mcf_member_id: '',
                        with_pets_flg: false,
                        driver: false,
                        reserved_room: false,
                    } as PassengerDetail
                    passenger_detail_baby_array.value!.push(defaultPassengerDetail)
                }
                for (let i = 0; i < passenger_baby_num.value; i++) {
                    // 配列が指定人数より少ない場合、デフォルトを追加
                    let tmpLineNo = passenger_detail_baby_array.value!.length + 1
                    let defaultPassengerDetail = {
                        id: null,
                        line_no: tmpLineNo,
                        gender: undefined,
                        age: null,
                        family_name_katakana: '',
                        given_name_katakana: '',
                        passenger_type: PASSENGER_TYPE_BABY,
                        represent_flg: false,
                        mcf_member_id: '',
                        with_pets_flg: false,
                        driver: false,
                        reserved_room: false,
                    } as PassengerDetail
                    passenger_detail_baby_array.value!.push(defaultPassengerDetail)
                }

                // 二輪車
                two_wheel_detail_array.value = []
                for (let i = 0; i < car_two_wheel_large_num.value; i++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let defaultCarDetail = {
                        line_no: two_wheel_detail_array.value!.length + 1,
                        car_type_code: '05',
                        car_length: 4,
                        car_width: 2.5,
                        car_place_name: '',
                        car_class_number: '',
                        car_hiragana: '',
                        car_number: '',
                        note: '',
                    } as CarDetail
                    two_wheel_detail_array.value!.push(defaultCarDetail)
                }
                for (let i = 0; i < car_two_wheel_medium_num.value; i++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let defaultCarDetail = {
                        line_no: two_wheel_detail_array.value!.length + 1,
                        car_type_code: '06',
                        car_length: 4,
                        car_width: 2.5,
                        car_place_name: '',
                        car_class_number: '',
                        car_hiragana: '',
                        car_number: '',
                        note: '',
                    } as CarDetail
                    two_wheel_detail_array.value!.push(defaultCarDetail)
                }
                for (let i = 0; i < car_two_wheel_small_num.value; i++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let defaultCarDetail = {
                        line_no: two_wheel_detail_array.value!.length + 1,
                        car_type_code: '07',
                        car_length: 4,
                        car_width: 2.5,
                        car_place_name: '',
                        car_class_number: '',
                        car_hiragana: '',
                        car_number: '',
                        note: '',
                    } as CarDetail
                    two_wheel_detail_array.value!.push(defaultCarDetail)
                }
                for (let i = 0; i < car_bicycle_num.value; i++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let defaultCarDetail = {
                        line_no: two_wheel_detail_array.value!.length + 1,
                        car_type_code: '08',
                        car_length: 4,
                        car_width: 2.5,
                        car_place_name: '',
                        car_class_number: '',
                        car_hiragana: '',
                        car_number: '',
                        note: '',
                    } as CarDetail
                    two_wheel_detail_array.value!.push(defaultCarDetail)
                }

                // ペットルーム
                cage_detail_array.value = []
                for (let i = 0; i < cage_small_num.value; i++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let defaultCageDetail = {
                        cage_type: CAGE_TYPES_SMALL,
                    } as CageDetail
                    cage_detail_array.value!.push(defaultCageDetail)
                }
                for (let i = 0; i < cage_large_num.value; i++) {
                    // 配列が指定台数より少ない場合、デフォルトを追加
                    let defaultCageDetail = {
                        cage_type: CAGE_TYPES_LARGE,
                    } as CageDetail
                    cage_detail_array.value!.push(defaultCageDetail)
                }

                let boardingData = {
                    dep_date: formatDate(dep_date.value!, true, 0),
                    dep_time: '',
                    personality_type: PERSONALITY_TYPE_INDIVIDUAL,
                    corporate_name: '',
                    represent_passenger_name: '',
                    represent_passenger_tel: '',
                    represent_passenger_gender: undefined,
                    represent_passenger_birthday: '',
                    represent_passenger_age: undefined,
                    represent_passenger_prefectures: '',
                    represent_passenger_post_code: '',
                    represent_passenger_address: '',
                    wheelchair: undefined,
                    wheelchair_lending: undefined,
                    pregnant: undefined,
                    pregnant_months: undefined,
                    pet_num: undefined,
                    oxygen_cylinder: false,
                    oxygen_cylinder_count: undefined,
                } as Boarding

                let bookingData = {
                    contract_number: search_contract_number.value,
                    dep_date: formatDate(dep_date.value!, true, 0),
                    sea_route_code: sea_route_code.value,
                    grade: get_seat_type_grade(search_seat_type.value),
                    seat_type: search_seat_type.value,
                    boarding: boardingData,
                    headcount_distributions: headcount_distributions,
                    passenger_adult_num: passenger_adult_num.value,
                    passenger_child_num: passenger_child_num.value,
                    passenger_infant_num: passenger_infant_num.value,
                    passenger_baby_num: passenger_baby_num.value,
                    passenger_detail_array: passenger_detail_array.value,
                    passenger_detail_baby_array: passenger_detail_baby_array.value,
                    car_num: car_num.value,
                    car_3m_num: car_3m_num.value,
                    car_4m_num: car_4m_num.value,
                    car_5m_num: car_5m_num.value,
                    car_6m_num: car_6m_num.value,
                    car_detail_array: car_detail_array.value,
                    car_two_wheel_large_num: car_two_wheel_large_num.value,
                    car_two_wheel_medium_num: car_two_wheel_medium_num.value,
                    car_two_wheel_small_num: car_two_wheel_small_num.value,
                    car_bicycle_num: car_bicycle_num.value,
                    two_wheel_detail_array: two_wheel_detail_array.value,
                    cage_small_num: cage_small_num.value,
                    cage_large_num: cage_large_num.value,
                    cage_detail_array: cage_detail_array.value,
                    isUpdate: false,
                } as Booking
                setBookingData(bookingData)
                setSearchConfirmationVacancyData({
                    dep_date: dep_date.value,
                    sea_route_code: sea_route_code.value,
                    passenger_adult_num: passenger_adult_num.value,
                    passenger_child_num: passenger_child_num.value,
                    passenger_infant_num: passenger_infant_num.value,
                    passenger_baby_num: passenger_baby_num.value,
                    car_3m_num: car_3m_num.value,
                    car_4m_num: car_4m_num.value,
                    car_5m_num: car_5m_num.value,
                    car_6m_num: car_6m_num.value,
                    car_bicycle_num: car_bicycle_num.value,
                    car_two_wheel_small_num: car_two_wheel_small_num.value,
                    car_two_wheel_medium_num: car_two_wheel_medium_num.value,
                    car_two_wheel_large_num: car_two_wheel_large_num.value,
                    cage_small_num: cage_small_num.value,
                    cage_large_num: cage_large_num.value,
                } as SearchConfirmationVacancy)

                // プラン詳細画面へ遷移
                setRouterPushProperties({ empty_count: search_empty_count.value })
                // ログイン状況チェック
                if (!store.state.is_logged_in) {
                    setRouterPushProperties({
                        move_after_login: '/booking/confirmation_vacancy',
                    })
                    // ログイン画面へ遷移
                    router.push('/member/login')
                    return
                }
                console.log('↑↑↑ConfirmationVacancy.openPlanDetail↑↑↑')
                router.push('/booking/plan_detail')
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }
        }
        // メッセージ表示
        const displayMessage = ({
            msg_title,
            msg,
            close_btn,
            ok_btn,
            submit_btn,
            close_event,
            ok_event,
            submit_event,
            cancel_btn_value,
            ok_btn_value,
            submit_btn_value,
        }: {
            msg_title: string
            msg: string[]
            close_btn: boolean
            ok_btn: boolean
            submit_btn: boolean
            close_event: any
            ok_event: any
            submit_event: any
            cancel_btn_value: string
            ok_btn_value: string
            submit_btn_value: string
        }) => {
            context.emit('displayMessage', {
                msg_title: msg_title,
                msg: msg,
                close_btn: close_btn,
                ok_btn: ok_btn,
                submit_btn: submit_btn,
                close_event: close_event,
                ok_event: ok_event,
                submit_event: submit_event,
                cancel_btn_value: cancel_btn_value,
                ok_btn_value: ok_btn_value,
                submit_btn_value: submit_btn_value,
            })
        }
        // 検索条件
        const makeSearchConditionsValue = () => {
            let search_value = {
                date: '乗船日：指定なし',
                route: '航路：指定なし',
                people: '人数：指定なし',
                car: '乗用車：指定',
                bike: '二輪車：指定',
                pet: 'ペットルーム：指定',
            }

            search_value.date = '乗船日：' + dispDepDay()
            search_value.route = '航路：' + dispSeaRoute()
            search_value.people = '人数：' + dispPassengerNum()
            search_value.car = '乗用車：' + dispCarNum()
            search_value.bike = '二輪車：' + dispTwoWheelNum()
            search_value.pet = 'ペットルーム：' + dispCageNum()

            search_conditions_value.value = search_value
        }

        // 船室詳細
        const openRoomDetails = (seat_type: number) => {
            room_detail_seat_type.value = seat_type
            $('#btn-room-open-modal').click()
        }
        // カレンダー日付選択
        const selectPlanCalendarDepDate = async (plan_dep_date: Date) => {
            dep_date.value = plan_dep_date
            // 運航予定取得
            ship_schedule.value = await getShipSchedule(formatDate(dep_date.value!, true, 0), sea_route_code.value)
            // 検索条件表示
            makeSearchConditionsValue()
            // searchedPlanList.value!.getAllEmptyCount();
            searchPlanSilent()
        }
        const swiperModules = [Navigation, Pagination]
        const swiperNavigation = {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        }
        // ページネーションで使うエレメントのクラスを指定
        // 画像をサムネイルとして並べたいのでバレットの内容を指定
        const swiperPagination = (seat_type: number) => {
            let width = 25
            if (seat_type < SEAT_TYPE_P_BA) {
                width = 20
            }
            return {
                el: '.slider-thumbnail_' + seat_type,
                clickable: true,
                renderBullet: (index: number, className: string) =>
                    `<span class="slider-thumbnail__item ${className} 1 swiper-slide" style="width: ${width}%;margin: 0 0 0 0;"><img src="${
                        images[seat_type - 10][index]
                    }"></span>`,
            }
        }

        const aboutInfane = () => {
            let messge: Array<string> = [
                '幼児は大人1名につき1名まで無料（お席は大人と同席）となりますが、',
                'それを超える場合は小人料金が必要となります。',
                '大人の人数を超える幼児の方は、小人としてご予約ください。',
            ]
            context.emit('displayMessage', {
                msg_title: '※幼児について',
                msg: messge,
                close_btn: true,
            })
        }

        watch(sea_route_code, (next, prev) => {
            if (sea_route_code.value == SEA_ROUTE_CODE_MIYAZAKI) {
                disp_sea_route_code.value = '宮崎港→神戸港'
            } else if (sea_route_code.value == SEA_ROUTE_CODE_KOBE) {
                disp_sea_route_code.value = '神戸港→宮崎港'
            } else {
                disp_sea_route_code.value = ''
            }
        })
        watch(room_detail_seat_type, (next, prev) => {
            if (!isNull(roomSwiper.value)) {
                roomSwiper.value!.init
            }
        })

        const isRokko = computed(() => {
            return ship_schedule.value?.ship_code == SHIP_CODE_ROKKO
        })
        onMounted(() => {
            console.log('↓↓↓ConfirmationVacancy.onMount↓↓↓')
            let isSearch = false
            // 外部サイトから来た場合
            const route = useRoute()
            // 航路
            if (!isNull(route.query.rt)) {
                sea_route_code.value = '0' + route.query.rt
                isSearch = true
            }
            // 乗船日
            if (!isNull(route.query.ymd)) {
                dep_date.value = new Date(
                    String(route.query.ymd).substr(0, 4) + '-' + String(route.query.ymd).substr(4, 2) + '-' + String(route.query.ymd).substr(6, 2),
                )
                isSearch = true
            }
            // 大人
            if (!isNull(route.query.a)) {
                passenger_adult_num.value = Number(route.query.a)
                isSearch = true
            }
            // 小人
            if (!isNull(route.query.rt)) {
                passenger_child_num.value = Number(route.query.c)
                isSearch = true
            }
            // 幼児
            if (!isNull(route.query.rt)) {
                passenger_infant_num.value = Number(route.query.i)
                isSearch = true
            }
            // 乳児
            if (!isNull(route.query.rt)) {
                passenger_baby_num.value = Number(route.query.b)
                isSearch = true
            }
            if (!isNull(route.query.a) || !isNull(route.query.rt) || !isNull(route.query.rt) || !isNull(route.query.rt)) {
                commitPassenger()
            }
            // 乗用車
            if (!isNull(route.query.car1)) {
                car_4m_num.value = Number(route.query.car1)
            }
            if (!isNull(route.query.car2)) {
                car_5m_num.value = Number(route.query.car2)
            }
            if (!isNull(route.query.car3)) {
                car_6m_num.value = Number(route.query.car3)
            }
            if (!isNull(route.query.car1) || !isNull(route.query.car2) || !isNull(route.query.car3)) {
                commitCar()
                isSearch = true
            }
            // 二輪車
            if (!isNull(route.query.bike1)) {
                car_bicycle_num.value = Number(route.query.bike1)
                commitTwoWheel()
                isSearch = true
            }
            if (!isNull(route.query.bike2)) {
                car_two_wheel_small_num.value = Number(route.query.bike2)
                commitTwoWheel()
                isSearch = true
            }
            if (!isNull(route.query.bike3)) {
                car_two_wheel_medium_num.value = Number(route.query.bike3)
                commitTwoWheel()
                isSearch = true
            }
            if (!isNull(route.query.bike4)) {
                car_two_wheel_large_num.value = Number(route.query.bike4)
            }
            if (!isNull(route.query.bike1) || !isNull(route.query.bike2) || !isNull(route.query.bike3) || !isNull(route.query.bike4)) {
                commitTwoWheel()
                isSearch = true
            }
            // ペット
            if (!isNull(route.query.pet1)) {
                cage_large_num.value = Number(route.query.pet1)
            }
            if (!isNull(route.query.pet2)) {
                cage_small_num.value = Number(route.query.pet2)
            }
            if (!isNull(route.query.pet1) || !isNull(route.query.pet2)) {
                commitCage()
                isSearch = true
            }
            // 外部サイトからでは無い場合
            if (!isSearch) {
                const search_data = getSearchConfirmationVacancyData()
                if (!isNull(search_data)) {
                    if (!isNull(search_data.dep_date)) dep_date.value = new Date(search_data.dep_date!)
                    if (!isNull(search_data.sea_route_code)) sea_route_code.value = search_data.sea_route_code!
                    if (!isNull(search_data.passenger_adult_num)) passenger_adult_num.value = search_data.passenger_adult_num!
                    if (!isNull(search_data.passenger_child_num)) passenger_child_num.value = search_data.passenger_child_num!
                    if (!isNull(search_data.passenger_infant_num)) passenger_infant_num.value = search_data.passenger_infant_num!
                    if (!isNull(search_data.passenger_baby_num)) passenger_baby_num.value = search_data.passenger_baby_num!
                    if (!isNull(search_data.car_3m_num)) car_3m_num.value = search_data.car_3m_num!
                    if (!isNull(search_data.car_4m_num)) car_4m_num.value = search_data.car_4m_num!
                    if (!isNull(search_data.car_5m_num)) car_5m_num.value = search_data.car_5m_num!
                    if (!isNull(search_data.car_6m_num)) car_6m_num.value = search_data.car_6m_num!
                    if (!isNull(search_data.car_bicycle_num)) car_bicycle_num.value = search_data.car_bicycle_num!
                    if (!isNull(search_data.car_two_wheel_small_num)) car_two_wheel_small_num.value = search_data.car_two_wheel_small_num!
                    if (!isNull(search_data.car_two_wheel_medium_num)) car_two_wheel_medium_num.value = search_data.car_two_wheel_medium_num!
                    if (!isNull(search_data.car_two_wheel_large_num)) car_two_wheel_large_num.value = search_data.car_two_wheel_large_num!
                    if (!isNull(search_data.cage_small_num)) cage_small_num.value = search_data.cage_small_num!
                    if (!isNull(search_data.cage_large_num)) cage_large_num.value = search_data.cage_large_num!

                    commitPassenger()
                    commitCar()
                    commitTwoWheel()
                    commitCage()
                    isSearch = true
                }
            }
            if (isSearch) {
                searchPlan()
            }

            // 検索条件
            $('#specify__data-item-date').on('click', function () {
                dep_date.value = null
                makeSearchConditionsValue()
            })
            $('#specify__data-item-route').on('click', function () {
                sea_route_code.value = ''
                makeSearchConditionsValue()
            })
            $('#specify__data-item-people').on('click', function () {
                passenger_adult_num.value = 0
                passenger_child_num.value = 0
                passenger_infant_num.value = 0
                passenger_baby_num.value = 0
                disp_passenger.value = ''
                makeSearchConditionsValue()
            })
            $('#specify__data-item-car').on('click', function () {
                car_num.value = 0
                car_3m_num.value = 0
                car_4m_num.value = 0
                car_5m_num.value = 0
                car_6m_num.value = 0
                disp_car.value = ''
                makeSearchConditionsValue()
            })
            $('#specify__data-item-bike').on('click', function () {
                car_bicycle_num.value = 0
                car_two_wheel_small_num.value = 0
                car_two_wheel_medium_num.value = 0
                car_two_wheel_large_num.value = 0
                disp_two_wheel.value = ''
                makeSearchConditionsValue()
            })
            $('#specify__data-item-pet').on('click', function () {
                cage_small_num.value = 0
                cage_large_num.value = 0
                disp_cage.value = ''
                makeSearchConditionsValue()
            })

            // カレンダー年月選択時処理
            $('.vdp-datepicker__calendar').on('click', function () {
                $('.vdp-datepicker__calendar > span').on('click', function () {
                    $('.datepicker-close-main > div:nth-of-type(2) > div > span').removeClass('calendar-fully-occupied')
                    $('.datepicker-close-main > div:nth-of-type(2) > div > span').removeClass('vacancy')
                    $('.datepicker-close-main > div:nth-of-type(2) > div > span').addClass('vacancy')
                })
            })

            // 検索履歴削除:特定条件下で自動リロードが挟まるので遅延実行
            setTimeout(() => {
                removeSearchConfirmationVacancyData()
            }, 300)

            console.log('↑↑↑ConfirmationVacancy.onMount↑↑↑')
        })

        return {
            $,
            searchedPlanList,
            roomSwiper,
            disabledDates,
            dep_date,
            sea_route_code,
            ship_schedule,
            disp_sea_route_code,

            passenger_detail_array,
            passenger_detail_baby_array,
            disp_passenger,
            passenger_adult_num,
            passenger_child_num,
            passenger_infant_num,
            passenger_baby_num,

            car_detail_array,
            car_num,
            disp_car,
            car_3m_num,
            car_4m_num,
            car_5m_num,
            car_6m_num,

            two_wheel_detail_array,
            disp_two_wheel,
            car_bicycle_num,
            car_two_wheel_small_num,
            car_two_wheel_medium_num,
            car_two_wheel_large_num,

            cage_detail_array,
            disp_cage,
            cage_small_num,
            cage_large_num,

            search_conditions_value,
            room_detail_seat_type,

            search_contract_number,
            search_empty_count,
            search_seat_type,
            search_plan_dep_date,

            updateDepDate,
            // 日付指定
            dispDepDay,

            //航路選択
            dispSeaRoute,

            // 乗船者
            incrementPassenger,
            decrementPassenger,
            dispPassengerNum,
            commitPassenger,

            // 車
            dispCarNum,
            incrementCar,
            decrementCar,
            commitCar,

            // 2輪
            incrementTwoWheel,
            decrementTwoWheel,
            dispTwoWheelNum,
            commitTwoWheel,

            // ペットルーム
            incrementCage,
            decrementCage,
            dispCageNum,
            commitCage,

            // 部屋情報
            get_seat_type_name,
            get_seat_type_room_information,
            get_seat_type_room_type,
            get_seat_type_view,
            get_seat_type_capacity,
            get_seat_type_wide,
            get_seat_type_facility,

            // プラン検索
            searchPlan,
            // 出港便の警告表示
            openShipScheduleWarning,
            // プラン詳細
            openPlanDetail,
            // 船室詳細
            openRoomDetails,
            // メッセージ表示
            displayMessage,
            // カレンダー日付選択
            selectPlanCalendarDepDate,

            // 幼児について表示
            aboutInfane,

            isRokko,
            isShowPlanList,
            isNull,

            images,
            swiperModules,
            swiperNavigation,
            swiperPagination,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'ConfirmationVacancy',
    data() {
        return {
            SEA_ROUTE_CODE_MIYAZAKI: SEA_ROUTE_CODE_MIYAZAKI as string,
            SEA_ROUTE_CODE_KOBE: SEA_ROUTE_CODE_KOBE as string,

            PASSENGER_TYPE_ADULT: PASSENGER_TYPE_ADULT as number,
            PASSENGER_TYPE_CHILD: PASSENGER_TYPE_CHILD as number,
            PASSENGER_TYPE_INFANT: PASSENGER_TYPE_INFANT as number,
            PASSENGER_TYPE_BABY: PASSENGER_TYPE_BABY as number,

            CAR_TYPE_BICYCLE: CAR_TYPE_BICYCLE as string,
            CAR_TYPE_TWO_WHEEL_SMALL: CAR_TYPE_TWO_WHEEL_SMALL as string,
            CAR_TYPE_TWO_WHEEL_MEDIUM: CAR_TYPE_TWO_WHEEL_MEDIUM as string,
            CAR_TYPE_TWO_WHEEL_LARGE: CAR_TYPE_TWO_WHEEL_LARGE as string,

            CAGE_TYPES_SMALL: CAGE_TYPES_SMALL as number,
            CAGE_TYPES_LARGE: CAGE_TYPES_LARGE as number,

            VEHICLE_RESTRICT_NONE: VEHICLE_RESTRICT_NONE as number,
            VEHICLE_RESTRICT_CAR: VEHICLE_RESTRICT_CAR as number,
            VEHICLE_RESTRICT_TWO_AXLE_CAR: VEHICLE_RESTRICT_TWO_AXLE_CAR as number,
            VEHICLE_RESTRICT_PASSENGER: VEHICLE_RESTRICT_PASSENGER as number,

            CHILD_RESTRICT_NONE: CHILD_RESTRICT_NONE as number,
            CHILD_RESTRICT_CHILD: CHILD_RESTRICT_CHILD as number,
            CHILD_RESTRICT_CHILD_OR_INFANT: CHILD_RESTRICT_CHILD_OR_INFANT as number,

            SEAT_TYPE_P_TW: SEAT_TYPE_P_TW as number,
            SEAT_TYPE_P_TR: SEAT_TYPE_P_TR as number,
            SEAT_TYPE_P_BA: SEAT_TYPE_P_BA as number,
            SEAT_TYPE_F_TW: SEAT_TYPE_F_TW as number,
            SEAT_TYPE_F_FO: SEAT_TYPE_F_FO as number,
            SEAT_TYPE_FWP_TW: SEAT_TYPE_FWP_TW as number,
            SEAT_TYPE_FWP_FO: SEAT_TYPE_FWP_FO as number,
            SEAT_TYPE_S: SEAT_TYPE_S as number,
            SEAT_TYPE_DS: SEAT_TYPE_DS as number,
            SEAT_TYPE_D: SEAT_TYPE_D as number,
            SEAT_TYPE_T: SEAT_TYPE_T as number,

            // ja:langs.ja,
            // DatePickerFormat: 'yyyy年 MM月 dd日（D）',
        }
    },
    components: {
        PlanList,
        ShipAnimationStart,
        ShipAnimationEnd,
        Swiper,
        SwiperSlide,
        CalendarPicker,
    },
    props: {},
    methods: {},
    mounted: function () {
        console.log('↓↓↓ConfirmationVacancy.mounted↓↓↓')
        scrollTo(0, 0)
        //set route
        $('#search__input-route').on('change', function () {
            const selected = $('#search__input-route').val()
            if (selected === '0') {
                $('#search__input-route').removeClass('search__input-route-set')
            } else {
                $('#search__input-route').addClass('search__input-route-set')
            }
        })
        $(function () {
            $('#search__input-route').click(function () {
                $('#search__set-route').toggle()
            })
            $('#input-set__cansel-route').click(function () {
                $('#search__set-route').hide()
            })
            $('#input-set__setting-route').click(function () {
                $('#search__set-route').hide()
            })
        })
        $('input[name="input-set__route"]').change(function () {
            // var routetext = $('input:radio[name="input-set__route"]:checked').val();
            // $('#search__input-route').val(routetext);
            $('#search__set-route').hide()
        })
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search__item-route').length) {
                $('#search__set-route').hide()
            }
        })

        //set people
        $(function () {
            $('#search__input-people').click(function () {
                $('#search__set-people').toggle()
            })
            $('#input-set__cansel-people').click(function () {
                $('#search__set-people').hide()
            })
        })
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search__item-people').length) {
                $('#search__set-people').hide()
            }
        })

        //set car
        $(function () {
            $('#search__input-car').click(function () {
                $('#search__set-car').toggle()
            })
            $('#input-set__cansel-car').click(function () {
                $('#search__set-car').hide()
            })
            $('#input-set__setting-car').click(function () {
                $('#search__set-car').hide()
                let size4 = $('#input-set__number-size4').val()
                let size5 = $('#input-set__number-size5').val()
                let size6 = $('#input-set__number-size6').val()
                let size4text = '4ｍ未満' + size4 + '台'
                let size5text = '5ｍ未満' + size5 + '台'
                let size6text = '6ｍ未満' + size6 + '台'
                let cartext = ''
                if (size4 !== '0') {
                    cartext = size4text
                    if (size5 !== '0' || size6 !== '0') {
                        cartext = cartext + ','
                    }
                }
                if (size5 !== '0') {
                    cartext = cartext + size5text
                    if (size6 !== '0') {
                        cartext = cartext + ','
                    }
                }
                if (size6 !== '0') {
                    cartext = cartext + size6text
                }
                $('#search__input-car').val(cartext)
            })
        })
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search__item-car').length) {
                $('#search__set-car').hide()
            }
        })

        //set bike
        $(function () {
            $('#search__input-bike').click(function () {
                $('#search__set-bike').toggle()
            })
            $('#input-set__cansel-bike').click(function () {
                $('#search__set-bike').hide()
            })
        })
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search__item-bike').length) {
                $('#search__set-bike').hide()
            }
        })

        //set pet
        $(function () {
            $('#search__input-pet').click(function () {
                $('#search__set-pet').toggle()
            })
            $('#input-set__cansel-pet').click(function () {
                $('#search__set-pet').hide()
            })
        })
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search__item-pet').length) {
                $('#search__set-pet').hide()
            }
        })

        //検索結果表示へ移動
        $(document).ready(function () {
            var landing = $('#specify').offset()!.top
            $('#btn-search').click(function () {
                $('html,body').animate(
                    {
                        scrollTop: landing,
                    },
                    'slow',
                )
                return false
            })
        })

        //numberplate open/close
        $(function () {
            $('.input__line-number .btn-number-close').click(function () {
                $(this).parent().parent().addClass('close')
            })
            $('.input__line-number .btn-number-open').click(function () {
                $(this).parent().parent().removeClass('close')
            })
        })

        // モーダル再読み込み
        try {
            const remodalInst = $('.remodal').remodal()
            remodalInst.reload()
        } catch (e) {
            console.log(e)
        }
        console.log('↑↑↑ConfirmationVacancy.mounted↑↑↑')
    },
})
</script>
<style></style>
