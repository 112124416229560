<template>
    <section class="animation">
        <div class="animation__container">
            <div class="animation__kobe">
                <img
                    v-if="sea_route_code == SEA_ROUTE_CODE_MIYAZAKI"
                    src="@/images/items/illust-miyazaki.png"
                    alt="宮崎のイラスト"
                    width="90"
                    height="53"
                /><img v-else src="@/images/items/illust-kobe.png" alt="神戸のイラスト" width="90" height="58" />
            </div>
            <div class="animation__ship">
                <img src="@/images/items/ship.png" alt="ろっこう" width="338" height="58" />
            </div>
            <div class="animation__miyazaki">
                <img
                    v-if="sea_route_code == SEA_ROUTE_CODE_MIYAZAKI"
                    src="@/images/items/illust-kobe.png"
                    alt="神戸のイラスト"
                    width="90"
                    height="58"
                /><img v-else src="@/images/items/illust-miyazaki.png" alt="宮崎のイラスト" width="90" height="53" />
            </div>
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { SEA_ROUTE_CODE_MIYAZAKI, SEA_ROUTE_CODE_KOBE } from '@/lib/util'

export default defineComponent({
    name: 'ShipAnimationStart',
    components: {},
    setup() {
        return {
            SEA_ROUTE_CODE_MIYAZAKI,
        }
    },
    computed: {},
    props: {
        sea_route_code: String,
    },
    data() {
        return {}
    },
    methods: {},
})
</script>

<style scoped></style>
