<template>
    <section>
        <div class="ticket__header">
            <h2 class="ticket__name">決済処理中</h2>
        </div>
        <div class="ticket__container">
            <p id="complate__text" class="complate__text">画面は閉じないでお待ちください</p>
        </div>
    </section>
    <!-- animation -->
    <ShipAnimationStart></ShipAnimationStart>
    <ShipAnimationEnd></ShipAnimationEnd>
    <!-- /animation -->
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, Ref, nextTick } from 'vue'
import $ from 'jquery'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import ShipAnimationStart from '@/components/common/ShipAnimationStart.vue'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { getBookingData, setBookingData, getHistoryDetailData, setHistoryDetailData } from '@/lib/localStorageUtil'
import { Booking, HistoryDetail } from '@/lib/types'
import { isNull, API_USERS, API_ORDER } from '@/lib/util'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()

        const err_messag: Ref<string[]> = ref(['クレジットカードの認証に失敗しました。', 'ご予約をやり直してください。'])

        const RegistrationCreditPayment = async (payment_key: string): Promise<string> => {
            let boarding_no = ''

            try {
                boarding_no = await apiClient
                    .get(`${API_ORDER}/registration/credit/payment/${payment_key}`)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        let boarding_no = data.boarding_no
                        let booking = getBookingData() as Booking
                        booking.boarding.boarding_no = data.boarding_no
                        setBookingData(booking)
                        store.dispatch('setIsProcessing', false)
                        return boarding_no
                    })
                    .catch((e: any) => {
                        store.dispatch('setIsProcessing', false)
                        // エラー処理
                        console.log(e.message)
                        if (e.response && e.response?.status == 402) {
                            err_messag.value = e.response?.data.toString().split(/\r\n|\n|\r/)
                        } else {
                            err_messag.value = ['クレジットカードの認証に失敗しました。', 'ご予約をやり直してください。']
                        }

                        context.emit('displayMessage', {
                            msg_title: 'クレジットカード認証エラー',
                            msg: err_messag.value,
                            close_btn: true,
                            close_event: backSettlementEntry,
                        })
                        return ''
                    })
            } catch (e) {
                console.log(e)
                store.dispatch('setIsProcessing', false)
            }

            return boarding_no
        }

        // 決済情報入力へ戻る
        const backSettlementEntry = () => {
            location.href = '/booking/settlement_entry'
        }

        onMounted(async () => {
            console.log('↓↓↓Credit3dsAuthenticated.onMounted↓↓↓')
            const route = useRoute()
            // キーの確認
            const payment_key = route.params.payment_key as string
            if (isNull(payment_key)) {
                // リダイレクト以外からの場合は不正
                router.push('/member/login')
                return
            }
            // 履歴の確認
            const history_data = getHistoryDetailData() as HistoryDetail
            if (history_data.redirect_url != null) {
                router.push(history_data.redirect_url)
                return
            }
            try {
                // モーダル再読み込み
                const remodalInst = $('.remodal').remodal()
                remodalInst.reload()
            } catch (e) {
                console.log(e)
            }
            store.dispatch('setIsProcessing', true)
            nextTick(async () => {
                // 処理実行時、リダイレクトURLを設定
                const history_detail = {
                    redirect_url: '/booking/confirmation_vacancy',
                } as HistoryDetail
                setHistoryDetailData(history_detail)
                let boarding_no = await RegistrationCreditPayment(payment_key)
                if (!isNull(boarding_no)) {
                    router.push('/booking/complete')
                } else {
                    // 乗船番号がない場合異常
                    context.emit('displayMessage', {
                        msg_title: 'クレジットカード認証エラー',
                        msg: err_messag.value,
                        close_btn: true,
                        close_event: backSettlementEntry,
                    })
                }
            })
            console.log('↑↑↑Credit3dsAuthenticated.onMounted↑↑↑')
        })

        return {}
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'Credit3dsAuthenticated',
    data() {
        return {}
    },
    components: {
        ShipAnimationStart,
        ShipAnimationEnd,
    },
    props: {},
    methods: {},
})
</script>

<style></style>
