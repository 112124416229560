<template>
    <li id="form__item" class="form__item" v-show="isShowPetCage">
        <div class="form__item-header">
            <h3 class="form__item-name">ペット情報</h3>
            <button class="btn-delete" v-on:click="deleteCage()">削除</button>
            <img class="form__item-icon" src="@/images/items/accessory-pet-01.svg" height="48" width="54" alt="ペット情報アイコン" />
        </div>
        <div class="form__item-container">
            <div class="input">
                <dl class="input__list">
                    <!-- line -->
                    <div class="input__line">
                        <!-- pet-room -->
                        <div id="input__item-pet-room" class="input__item input__item-pet-room">
                            <dt id="input__header-pet-room" class="input__header input__header-pet-room">
                                <label id="input__name-pet-room" class="input__name input__name-pet-room" for="input__pet-room">ペットルーム</label>
                                <span class="input__required">必須</span>
                            </dt>
                            <dd id="input__container-pet-room" class="input__container input__container-pet-room">
                                <input
                                    id="input__pet-room"
                                    class="input__form input__pet-room"
                                    type="text"
                                    name="input__pet-room"
                                    placeholder="ペットケージを選択してください"
                                    v-model="disp_cage"
                                    readonly
                                />
                                <div id="search__set-pet" class="search__set search__set-pet none">
                                    <ul class="input-set__list">
                                        <li id="input-set__item-bigroom" class="input-set__item input-set__item-bigroom">
                                            <label
                                                id="input-set__label-bigroom"
                                                class="input-set__label input-set__label-bigroom"
                                                for="input-set__number-bigroom"
                                                >大型ケージ</label
                                            >
                                            <div class="input-set__change">
                                                <button class="input-set__count minus" type="button" v-on:click="decrementCage(CAGE_TYPES_LARGE)">
                                                    －
                                                </button>
                                                <input
                                                    id="input-set__number-bigroom"
                                                    class="input-set__number input-set__number-bigroom"
                                                    type="text"
                                                    name="bigroom"
                                                    v-model="cage_large_num_tmp"
                                                    readonly
                                                />
                                                <button class="input-set__count plus" type="button" v-on:click="incrementCage(CAGE_TYPES_LARGE)">
                                                    ＋
                                                </button>
                                            </div>
                                        </li>
                                        <li id="input-set__item-smallroom" class="input-set__item input-set__item-smallroom">
                                            <label
                                                id="input-set__label-smallroom"
                                                class="input-set__label input-set__label-smallroom"
                                                for="input-set__number-smallroom"
                                                >中小型ケージ</label
                                            >
                                            <div class="input-set__change">
                                                <button class="input-set__count minus" type="button" v-on:click="decrementCage(CAGE_TYPES_SMALL)">
                                                    －
                                                </button>
                                                <input
                                                    id="input-set__number-smallroom"
                                                    class="input-set__number input-set__number-smallroom"
                                                    type="text"
                                                    name="smallroom"
                                                    v-model="cage_small_num_tmp"
                                                    readonly
                                                />
                                                <button class="input-set__count plus" type="button" v-on:click="incrementCage(CAGE_TYPES_SMALL)">
                                                    ＋
                                                </button>
                                            </div>
                                        </li>
                                        <li id="input-set__item_notes" class="input-set__item">
                                            <p>※ ペットケージにお預けの場合のみ選択してください。</p>
                                        </li>
                                        <li id="input-set__item_notes" class="input-set__item">
                                            <p>
                                                ※ 1ケージ2匹までとなります。<br />
                                                &emsp;<a href="https://www.miyazakicarferry.com/booking/precautions/#pet" target="_blank">詳しくはこちら</a>
                                            </p>
                                        </li>
                                    </ul>
                                    <div id="input-set__buttons" class="input-set__buttons">
                                        <button
                                            id="input-set__cansel-pet"
                                            class="btn input-set__cansel input-set__cansel-pet"
                                            type="button"
                                            v-on:click="cancelCage()"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            id="input-set__setting-pet"
                                            class="btn input-set__setting input-set__setting-pet"
                                            type="button"
                                            v-on:click="commitCage()"
                                        >
                                            設定
                                        </button>
                                    </div>
                                </div>
                            </dd>
                            <p class="input__error-text">ペットルームを選択してください</p>
                        </div>
                        <!-- pet-category -->
                        <div id="input__item-pet-category" class="input__item input__item-pet-category">
                            <dt id="input__header-pet-category" class="input__header input__header-pet-category">
                                <label id="input__name-pet-category" class="input__name input__name-pet-category" for="">ペット区分</label>
                                <span class="input__required">必須</span>
                            </dt>
                            <dd id="input__container-pet-category" class="input__container input__container-pet-category">
                                <input
                                    id="input__pet-category"
                                    class="input__select input__pet-category"
                                    type="text"
                                    placeholder="ペット区分を選択してください"
                                    v-model="disp_pet"
                                    v-on:click="togglePetCategory()"
                                    readonly
                                />
                                <div id="search__set-pet-category" class="search__set search__set-pet-category none">
                                    <ul class="input-set__list">
                                        <li id="input-set__item-dog" class="input-set__item input-set__item-dog">
                                            <label id="input-set__label-dog" class="input-set__label input-set__label-dog" for="input-set__number-dog"
                                                >犬</label
                                            >
                                            <div class="input-set__change">
                                                <button class="input-set__count minus" type="button" v-on:click="decrementPet(PET_TYPE_DOG)">
                                                    －
                                                </button>
                                                <input
                                                    id="input-set__number-dog"
                                                    class="input-set__number input-set__number-dog"
                                                    type="text"
                                                    name="dog"
                                                    v-model="dog_count_tmp"
                                                    readonly
                                                />
                                                <button class="input-set__count plus" type="button" v-on:click="incrementPet(PET_TYPE_DOG)">
                                                    ＋
                                                </button>
                                            </div>
                                        </li>
                                        <li id="input-set__item-cat" class="input-set__item input-set__item-cat">
                                            <label id="input-set__label-cat" class="input-set__label input-set__label-cat" for="input-set__number-cat"
                                                >猫</label
                                            >
                                            <div class="input-set__change">
                                                <button class="input-set__count minus" type="button" v-on:click="decrementPet(PET_TYPE_CAT)">
                                                    －
                                                </button>
                                                <input
                                                    id="input-set__number-cat"
                                                    class="input-set__number input-set__number-cat"
                                                    type="text"
                                                    name="cat"
                                                    v-model="cat_count_tmp"
                                                    readonly
                                                />
                                                <button class="input-set__count plus" type="button" v-on:click="incrementPet(PET_TYPE_CAT)">
                                                    ＋
                                                </button>
                                            </div>
                                        </li>
                                        <li id="input-set__item-animal" class="input-set__item input-set__item-animal">
                                            <label
                                                id="input-set__label-animal"
                                                class="input-set__label input-set__label-animal"
                                                for="input-set__number-animal"
                                                >その他</label
                                            >
                                            <div class="input-set__change">
                                                <button class="input-set__count minus" type="button" v-on:click="decrementPet(PET_TYPE_OTHER)">
                                                    －
                                                </button>
                                                <input
                                                    id="input-set__number-animal"
                                                    class="input-set__number input-set__number-animal"
                                                    type="text"
                                                    name="animal"
                                                    v-model="other_count_tmp"
                                                    readonly
                                                />
                                                <button class="input-set__count plus" type="button" v-on:click="incrementPet(PET_TYPE_OTHER)">
                                                    ＋
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                    <div id="input-set__buttons" class="input-set__buttons">
                                        <button
                                            id="input-set__cansel-pet-category"
                                            class="btn input-set__cansel input-set__cansel-pet-category"
                                            type="button"
                                            v-on:click="cancelPet()"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            id="input-set__setting-pet-category"
                                            class="btn input-set__setting input-set__setting-pet-category"
                                            type="button"
                                            v-on:click="commitPet()"
                                        >
                                            設定
                                        </button>
                                    </div>
                                </div>
                            </dd>
                            <p id="pet-error" class="input__error-text"></p>
                        </div>
                    </div>
                    <!-- line -->
                    <div class="input__line">
                        <!-- pet-info -->
                        <div id="input__item-pet-info" class="input__item input__item-pet-info">
                            <dt id="input__header-pet-info" class="input__header input__header-pet-info">
                                <label id="input__name-pet-info" class="input__name input__name-pet-info" for="input__pet-info">ペット情報</label>
                            </dt>
                            <dd id="input__container-pet-info" class="input__container input__container-pet-info">
                                <textarea
                                    id="input__pet-info"
                                    class="input__form input__pet-info"
                                    name="input__pet-info"
                                    v-model="pet_note"
                                    placeholder="例）犬（チワワ）1匹"
                                ></textarea>
                            </dd>
                        </div>
                    </div>
                    <!-- line -->
                    <div id="input__line-pet-note" class="input__line input__line-pet-note">
                        <p class="input__attention">
                            ペットルームの料金は、船内にて徴収しております(1ケージ2,100円)。予約時の料金には含まれておりません。
                        </p>
                        <!-- pet-note -->
                        <div id="input__item-pet-note-pack" class="input__item-pet-note-pack">
                            <div id="input__item-pet-note" class="input__item input__item-pet-note">
                                <dt id="input__header-pet-note" class="input__header input__header-pet-note">
                                    <label id="input__name-pet-note" class="input__name input__name-pet-note" for="">確認事項</label>
                                    <span class="input__required">必須</span>
                                    <span class="input__required-note">※すべての項目を確認し、チェックをしてください。</span>
                                </dt>
                                <dd id="input__container-pet-note" class="input__container input__container-pet-note">
                                    <div
                                        id="input__checkbox-pet-note-01"
                                        class="input__checkbox input__checkbox-pet-note input__checkbox-pet-note-01"
                                    >
                                        <input
                                            id="input__pet-note-01"
                                            class="input__checkbox-btn input__pet-note input__pet-note-01"
                                            type="checkbox"
                                            name="input__pet-note"
                                            v-model="confirmations[0]"
                                        />
                                        <label
                                            id="input__label-pet-note-01"
                                            class="input__checkbox-label input__label-pet-note-01"
                                            for="input__pet-note-01"
                                            >リード乗船が出来ませんので、船内は全身が入るケージに入れてのご移動をお願いします。
                                        </label>
                                    </div>
                                    <div
                                        id="input__checkbox-pet-note-02"
                                        class="input__checkbox input__checkbox-pet-note input__checkbox-pet-note-02"
                                    >
                                        <input
                                            id="input__pet-note-02"
                                            class="input__checkbox-btn input__pet-note input__pet-note-02"
                                            type="checkbox"
                                            name="input__pet-note"
                                            v-model="confirmations[1]"
                                        />
                                        <label
                                            id="input__label-pet-note-02"
                                            class="input__checkbox-label input__label-pet-note-02"
                                            for="input__pet-note-02"
                                            >ウィズペットルーム利用のお客様以外、客室へのペットの持込は禁止となります。</label
                                        >
                                    </div>
                                    <div
                                        id="input__checkbox-pet-note-03"
                                        class="input__checkbox input__checkbox-pet-note input__checkbox-pet-note-03"
                                    >
                                        <input
                                            id="input__pet-note-03"
                                            class="input__checkbox-btn input__pet-note input__pet-note-03"
                                            type="checkbox"
                                            name="input__pet-note"
                                            v-model="confirmations[2]"
                                        />
                                        <label
                                            id="input__label-pet-note-03"
                                            class="input__checkbox-label input__label-pet-note-03"
                                            for="input__pet-note-03"
                                            >乗船・下船の時以外は保管場所から連れ出さないでください。
                                        </label>
                                    </div>
                                    <div
                                        id="input__checkbox-pet-note-04"
                                        class="input__checkbox input__checkbox-pet-note input__checkbox-pet-note-04"
                                    >
                                        <input
                                            id="input__pet-note-04"
                                            class="input__checkbox-btn input__pet-note input__pet-note-04"
                                            type="checkbox"
                                            name="input__pet-note"
                                            v-model="confirmations[3]"
                                        />
                                        <label
                                            id="input__label-pet-note-04"
                                            class="input__checkbox-label input__label-pet-note-04"
                                            for="input__pet-note-04"
                                            >船内での愛玩動物の管理(食事、排泄物、汚物等の処理)はお客様自身で行ってください。<br />また、周りの迷惑になるような行為はご遠慮ください。
                                        </label>
                                    </div>
                                    <div
                                        id="input__checkbox-pet-note-05"
                                        class="input__checkbox input__checkbox-pet-note input__checkbox-pet-note-05"
                                    >
                                        <input
                                            id="input__pet-note-05"
                                            class="input__checkbox-btn input__pet-note input__pet-note-05"
                                            type="checkbox"
                                            name="input__pet-note"
                                            v-model="confirmations[4]"
                                        />
                                        <label
                                            id="input__label-pet-note-05"
                                            class="input__checkbox-label input__label-pet-note-05"
                                            for="input__pet-note-05"
                                        >
                                            以下に該当するペット及び動物は、船内へ乗船出来ません。
                                            <br />
                                            ア）猛禽、猛獣、爬虫類、毒保有の昆虫類、スカンク、猿、その他弊社が危険と判断するもの<br />
                                            イ）人に危害を加えるおそれのある特定8犬種(※1)及び弊社が指定した犬種、並びにそれに準じる雑種<br />
                                            ウ）弊社が定めるサイズのペットケージ等(※2)を持参されない、また、持参されてもそのペットケージ等に入らないペット<br />
                                            エ）お客さまご自身でペットケージ等へ入れ、持ち運びや移動が出来ないペット<br />
                                            オ）その他弊社がご予約時、乗船手続時を含め随時指定するペット<br />
                                            (※1)秋田犬、紀州犬、土佐犬、ジャーマン・シェパード、ドーベルマン、グレート・デン、セント・バーナード、
                                            アメリカン・スタッフォードシャー・テリア(アメリカン・ビットブル・テリア)
                                            <br />
                                            (※2)ペットケージ等の大きさは、以下に定める制限を設けます<br />
                                            &emsp;最大幅70cm以内、全長120cm以内、高さ90cm以内
                                        </label>
                                    </div>
                                </dd>
                                <label id="input__name-pet-note" class="input__name input__name-pet-note" for="">★ペットルーム入室時間</label>
                                <table class="input__table">
                                    <tr class="">
                                        <th class="">宮崎発</th>
                                        <th class="">神戸発</th>
                                    </tr>
                                    <tr class="">
                                        <td class="">乗船時～22:00&emsp;6:15～下船時</td>
                                        <td class="">乗船時～22:00&emsp;7:00～下船時</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <p class="input__error-text">確認事項をチェックしてください</p>
                    </div>
                </dl>
            </div>
        </div>
    </li>
    <li v-show="!isShowPetCage" class="form__plus">
        <button class="btn-border btn-border-plus" type="button" v-on:click="addCage()">ペットケージを追加する</button>
    </li>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, computed, nextTick } from 'vue'
import { API_USERS, API_ORDER, isNull } from '@/lib/util'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { CageDetail, Contract } from '@/lib/types'
import { CAGE_TYPES_LARGE, CAGE_TYPES_SMALL, SEAT_TYPE_FWP_TW, SEAT_TYPE_FWP_FO } from '@/lib/constants'
import $ from 'jquery'

const PET_TYPE_DOG = 0
const PET_TYPE_CAT = 1
const PET_TYPE_OTHER = 2

export default defineComponent({
    setup(props, context) {
        const contract = ref<Contract>()
        const confirmations = ref<boolean[]>([false, false, false, false, false])
        const CageList = ref<CageDetail[]>()
        const cage_small_num = ref(0)
        const cage_large_num = ref(0)
        const cage_small_num_tmp = ref(0)
        const cage_large_num_tmp = ref(0)
        const dog_count = ref(0)
        const cat_count = ref(0)
        const other_count = ref(0)
        const dog_count_tmp = ref(0)
        const cat_count_tmp = ref(0)
        const other_count_tmp = ref(0)
        const pet_note = ref('')
        const disp_pet = ref('')
        const disp_cage = ref('')
        const isShowPetCage = ref(false)

        const init = (cages: CageDetail[], dog: number, cat: number, other: number, note: string, target_contract: Contract) => {
            console.log('↓↓↓CageList.init↓↓↓')
            CageList.value = cages
            cage_small_num.value = 0
            cage_large_num.value = 0
            if (!isNull(CageList.value)) {
                for (let i = 0; i < CageList.value.length; i++) {
                    if (CageList.value[i].deleted) {
                        continue
                    }
                    if (CageList.value[i].cage_type == CAGE_TYPES_LARGE) {
                        cage_large_num.value++
                    } else if (CageList.value[i].cage_type == CAGE_TYPES_SMALL) {
                        cage_small_num.value++
                    }
                }
            }
            cage_small_num_tmp.value = cage_small_num.value
            cage_large_num_tmp.value = cage_large_num.value

            dog_count.value = dog
            dog_count_tmp.value = dog
            cat_count.value = cat
            cat_count_tmp.value = cat
            other_count.value = other
            other_count_tmp.value = other
            pet_note.value = note
            disp_cage.value = dispCageNum()
            disp_pet.value = dispPetNum()
            contract.value = target_contract
            if (props.isUpdate) {
                confirmations.value = [true, true, true, true, true]
            }
            setShowPetCage()

            console.log('↑↑↑CageList.init↑↑↑')
        }

        // ペットルーム
        const incrementCage = (cage_type: number) => {
            if (cage_type == CAGE_TYPES_SMALL) {
                cage_small_num_tmp.value += 1
            } else if (cage_type == CAGE_TYPES_LARGE) {
                cage_large_num_tmp.value += 1
            }
            $('#search__set-pet').show()
        }
        const decrementCage = (cage_type: number) => {
            if (cage_type == CAGE_TYPES_SMALL) {
                cage_small_num_tmp.value -= 1
                if (cage_small_num_tmp.value < 0) {
                    cage_small_num_tmp.value = 0
                }
            } else if (cage_type == CAGE_TYPES_LARGE) {
                cage_large_num_tmp.value -= 1
                if (cage_large_num_tmp.value < 0) {
                    cage_large_num_tmp.value = 0
                }
            }
            $('#search__set-pet').show()
        }
        const dispCageNum = () => {
            let values = []

            let cage_large_num = 0
            let cage_small_num = 0
            for (let i = 0; i < CageList.value!.length; i++) {
                if (CageList.value![i].cage_type == CAGE_TYPES_LARGE) {
                    cage_large_num++
                } else if (CageList.value![i].cage_type == CAGE_TYPES_SMALL) {
                    cage_small_num++
                }
            }

            if (cage_large_num > 0) {
                values.push(`大型${cage_large_num}部屋`)
            }
            if (cage_small_num > 0) {
                values.push(`中小型${cage_small_num}部屋`)
            }
            if (values.length == 0) {
                return 'なし'
            }
            return values.join(',')
        }
        const cancelCage = () => {
            $('#search__set-pet').hide()
            cage_small_num_tmp.value = cage_small_num.value
            cage_large_num_tmp.value = cage_large_num.value
        }
        const commitCage = () => {
            $('#search__set-pet').hide()
            cage_small_num.value = cage_small_num_tmp.value
            cage_large_num.value = cage_large_num_tmp.value

            disp_cage.value = ''
            if (cage_large_num.value > 0) {
                disp_cage.value += `大型${cage_large_num.value}部屋`
            }
            if (cage_small_num.value > 0) {
                if (!isNull(disp_cage.value)) {
                    disp_cage.value += ','
                }
                disp_cage.value += `中小型${cage_small_num.value}部屋`
            }
        }

        // ペット数
        const incrementPet = (pet_type: number) => {
            if (pet_type == PET_TYPE_DOG) {
                if (isNull(dog_count_tmp.value)) {
                    dog_count_tmp.value = 0
                }
                dog_count_tmp.value += 1
            } else if (pet_type == PET_TYPE_CAT) {
                if (isNull(cat_count_tmp.value)) {
                    cat_count_tmp.value = 0
                }
                cat_count_tmp.value += 1
            } else if (pet_type == PET_TYPE_OTHER) {
                if (isNull(other_count_tmp.value)) {
                    other_count_tmp.value = 0
                }
                other_count_tmp.value += 1
            }
            $('#search__set-pet-category').show()
        }
        const decrementPet = (pet_type: number) => {
            if (pet_type == PET_TYPE_DOG) {
                if (isNull(dog_count_tmp.value)) {
                    dog_count_tmp.value = 0
                }
                dog_count_tmp.value -= 1
                if (dog_count_tmp.value < 0) {
                    dog_count_tmp.value = 0
                }
            } else if (pet_type == PET_TYPE_CAT) {
                if (isNull(cat_count_tmp.value)) {
                    cat_count_tmp.value = 0
                }
                cat_count_tmp.value -= 1
                if (cat_count_tmp.value < 0) {
                    cat_count_tmp.value = 0
                }
            } else if (pet_type == PET_TYPE_OTHER) {
                if (isNull(other_count_tmp.value)) {
                    other_count_tmp.value = 0
                }
                other_count_tmp.value -= 1
                if (other_count_tmp.value < 0) {
                    other_count_tmp.value = 0
                }
            }
            $('#search__set-pet-category').show()
        }
        const dispPetNum = () => {
            let values = []

            if (dog_count.value > 0) {
                values.push(`犬${dog_count.value}匹`)
            }
            if (cat_count.value > 0) {
                values.push(`猫${cat_count.value}匹`)
            }
            if (other_count.value > 0) {
                values.push(`その他${other_count.value}匹`)
            }
            return values.join(',')
        }
        const cancelPet = () => {
            $('#search__set-pet-category').hide()
            dog_count_tmp.value = dog_count.value
            cat_count_tmp.value = cat_count.value
            other_count_tmp.value = other_count.value
        }
        const commitPet = () => {
            $('#search__set-pet-category').hide()
            // 乗船者人数確定時処理
            dog_count.value = dog_count_tmp.value
            cat_count.value = cat_count_tmp.value
            other_count.value = other_count_tmp.value

            disp_pet.value = dispPetNum()
        }
        const togglePetCategory = () => {
            $(function () {
                // console.log('toggle');
                // $('#search__set-pet-category').toggle();
            })
        }

        const getSmallNum = () => {
            // let cnt = 0;
            // for (let i = 0; i < CageList.value!.length; i ++) {
            //     if (CageList.value![i].cage_type == CAGE_TYPES_SMALL) {
            //         cnt++;
            //     }
            // }
            // return cnt;
            if (isShowPetCage.value) {
                return cage_small_num.value
            } else {
                return 0
            }
        }
        const getLargeNum = () => {
            // let cnt = 0;
            // for (let i = 0; i < CageList.value!.length; i ++) {
            //     if (CageList.value![i].cage_type == CAGE_TYPES_LARGE) {
            //         cnt++;
            //     }
            // }
            // return cnt;
            if (isShowPetCage.value) {
                return cage_large_num.value
            } else {
                return 0
            }
        }
        const getData = (): CageDetail[] => {
            let tmpCageList = [] as CageDetail[]

            if (isShowPetCage.value) {
                // 大型
                for (let i = 0; i < cage_large_num.value; i++) {
                    if (!isNull(CageList.value) && CageList.value!.length > tmpCageList.length) {
                        tmpCageList.push(CageList.value![i])
                    } else {
                        tmpCageList.push({
                            id: null,
                            cage_type: CAGE_TYPES_LARGE,
                            version: null,
                            deleted: false,
                        })
                    }
                    tmpCageList[tmpCageList.length - 1].cage_type = CAGE_TYPES_LARGE
                    tmpCageList[tmpCageList.length - 1].deleted = false
                }
                // 中小型
                for (let i = 0; i < cage_small_num.value; i++) {
                    if (!isNull(CageList.value) && CageList.value!.length > tmpCageList.length) {
                        tmpCageList.push(CageList.value![cage_large_num.value + i])
                    } else {
                        tmpCageList.push({
                            id: null,
                            cage_type: CAGE_TYPES_SMALL,
                            version: null,
                            deleted: false,
                        })
                    }
                    tmpCageList[tmpCageList.length - 1].cage_type = CAGE_TYPES_SMALL
                    tmpCageList[tmpCageList.length - 1].deleted = false
                }
            }
            if (!isNull(CageList.value) && CageList.value!.length > tmpCageList.length) {
                for (let i = tmpCageList.length; i < CageList.value!.length; i++) {
                    tmpCageList.push(CageList.value![i])
                    tmpCageList[i].deleted = true
                }
            }

            CageList.value = tmpCageList.filter((v) => !isNull(v.id) || v.deleted == false)
            return CageList.value!
        }
        const getPetData = (): any[] => {
            if (isShowPetCage.value) {
                return [dog_count.value, cat_count.value, other_count.value, pet_note.value]
            } else {
                return [0, 0, 0, '']
            }
        }
        // 入力チェック
        const chkValidation = (): number => {
            let err_count = 0

            // ペット不使用の場合エラーなし扱い
            if (!isShowPetCage.value) {
                return err_count
            }

            // エラー表示初期化
            $('#input__item-pet-room').removeClass('input__error')
            $('#input__item-pet-category').removeClass('input__error')
            $('#input__line-pet-note').removeClass('input__error')

            const cage_count = getSmallNum() + getLargeNum()
            if (cage_count == 0) {
                $('#input__item-pet-room').addClass('input__error')
                err_count++
            }
            const pet_count = dog_count.value + cat_count.value + other_count.value
            if (pet_count == 0) {
                $('#input__item-pet-category').addClass('input__error')
                $('#pet-error').text('ペット区分を選択してください')
                err_count++
            } else if (cage_count * 2 < pet_count) {
                $('#input__item-pet-category').addClass('input__error')
                $('#pet-error').text('ペットは1ケージ2匹までとなっております')
                err_count++
            }

            // 確認チェッゥ
            if (!isConfirmations()) {
                $('#input__line-pet-note').addClass('input__error')
                err_count++
            }

            return err_count
        }
        // 入力チェック（成約制限）
        const chkValidationContract = (): string[] => {
            let err_messages = [] as string[]
            let now = new Date()

            if (props.isUpdate) {
                // 更新時のみチェック
            }

            return err_messages
        }

        const isConfirmations = () => {
            for (let i = 0; i < confirmations.value.length; i++) {
                if (!confirmations.value[i]) {
                    return false
                }
            }
            return true
        }
        // ペットルーム表示有無設定
        const setShowPetCage = () => {
            if (props.seat_type == SEAT_TYPE_FWP_TW || props.seat_type == SEAT_TYPE_FWP_FO) {
                isShowPetCage.value = true
                return
            }
            if (!isNull(CageList.value) && CageList.value!.length > 0) {
                isShowPetCage.value = true
                return
            }
            isShowPetCage.value = false
        }
        // // ペットルーム表示有無
        // const isShowPetCage = computed(() => {
        //     if (props.seat_type == SEAT_TYPE_FWP_TW || props.seat_type == SEAT_TYPE_FWP_FO) {
        //         return true;
        //     }
        //     if (!isNull(CageList.value) && CageList.value!.length > 0) {
        //         return true;
        //     }

        //     return false;
        // });
        const addCage = () => {
            isShowPetCage.value = true
        }
        const deleteCage = () => {
            isShowPetCage.value = false
        }

        onMounted(() => {
            console.log('↓↓↓CageList.onMounted↓↓↓')
            console.log('↑↑↑CageList.onMounted↑↑↑')
        })

        return {
            CageList,
            confirmations,
            cage_small_num,
            cage_large_num,
            cage_small_num_tmp,
            cage_large_num_tmp,
            dog_count,
            cat_count,
            other_count,
            dog_count_tmp,
            cat_count_tmp,
            other_count_tmp,
            pet_note,
            disp_pet,
            disp_cage,

            init,
            incrementCage,
            decrementCage,
            incrementPet,
            decrementPet,
            cancelCage,
            commitCage,

            dispPetNum,
            cancelPet,
            commitPet,
            togglePetCategory,
            getSmallNum,
            getLargeNum,

            getData,
            getPetData,
            chkValidation,
            chkValidationContract,
            isShowPetCage,
            addCage,
            deleteCage,

            isConfirmations,

            CAGE_TYPES_LARGE,
            CAGE_TYPES_SMALL,
        }
    },
    emits: [],
    name: 'CageList',
    data() {
        return {
            PET_TYPE_DOG: PET_TYPE_DOG as number,
            PET_TYPE_CAT: PET_TYPE_CAT as number,
            PET_TYPE_OTHER: PET_TYPE_OTHER as number,
        }
    },

    components: {},
    props: {
        dep_date: String,
        sea_route_code: String,
        contract_number: String,
        seat_type: Number,
        isUpdate: Boolean,
    },
    mounted: function () {
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#input__container-pet-category').length) {
                $('div[id^="search__set-pet-category"]').hide()
            } else if ($(e.target).closest('#input-set__buttons').length) {
                // ボタン領域
                console.log('btn')
            } else {
                console.log('show3')
                $('div[id^="search__set-pet-category"]').show()
            }
        })

        // //set pet
        // $(function () {
        //     $('#search__input-pet').click(function () {
        //         console.log('search__set-pet.toggle');
        //         $('#search__set-pet').toggle();
        //     });
        //     $('#input-set__cansel-pet').click(function () {
        //         console.log('search__set-pet.hide');
        //         $('#search__set-pet').hide();
        //     });
        // });
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#input__container-pet-room').length) {
                console.log('search__set-pet.hide2')
                $('#search__set-pet').hide()
            } else if ($(e.target).closest('#input-set__buttons').length) {
                // ボタン領域
                console.log('btn2')
            } else {
                console.log('show4')
                $('#search__set-pet').show()
            }
        })
    },
})
</script>

<style scoped></style>
