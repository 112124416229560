<!-- eslint-disable no-irregular-whitespace -->
<template>
    <!-- car -->
    <li
        id="form__item"
        class="form__item"
        v-for="(boardingCar, idx) in boardingCarList"
        :key="idx"
        v-show="car_type == 0 && boardingCar.deleted != true"
    >
        <template v-if="car_type == 0 && boardingCar.deleted != true">
            <div class="form__item-header">
                <h3 class="form__item-name">乗用車</h3>
                <button class="btn-delete" type="button" v-on:click="confirmationDeleteCar(boardingCar.line_no)">削除</button>
                <img class="form__item-icon" src="@/images/items/accessory-car-01.svg" height="46" width="79" alt="乗用車アイコン" />
            </div>
            <div class="form__item-set">
                <select
                    id="form__passenger"
                    class="form__passenger input__select"
                    v-model="boardingCar.vehicle_id"
                    v-on:change="changeVehicleId(boardingCar.line_no)"
                >
                    <option class="" value="">車両情報から選択</option>
                    <template v-for="vehicle in vehicleList" :key="vehicle.id">
                        <option class="selectable" :value="vehicle.id">
                            {{ getListCarName(vehicle) }}
                        </option>
                    </template>
                </select>
                <div class="">
                    <input
                        :id="'form__regist-passenger-car' + boardingCar.line_no"
                        class="form__regist-passenger"
                        type="checkbox"
                        v-model="boardingCar.register_car"
                    />
                    <label class="check" :for="'form__regist-passenger-car' + boardingCar.line_no"
                        >車両情報に登録（ナンバーの入力が必須になります）</label
                    >
                </div>
            </div>
            <div class="form__item-container">
                <div class="input">
                    <dl class="input__list">
                        <!-- line -->
                        <div class="input__line input__line-driver">
                            <p class="input__attention">
                                ・車は4～6ｍ未満の自家用自動車に限ります。<br />
                                ・事業用自動車および無人車はweb予約の対象外となります。
                            </p>
                            <!-- driver -->
                            <div :id="'input__item-driver-' + boardingCar.line_no" class="input__item input__item-driver">
                                <dt id="input__header-driver" class="input__header input__header-driver">
                                    <label id="input__name-driver" class="input__name input__name-driver" for="input__driver">運転手</label>
                                    <span class="input__required">必須</span>
                                </dt>
                                <dd id="input__container-driver" class="input__container input__container-driver">
                                    <select
                                        id="input__category"
                                        class="input__select input__driver"
                                        name="input__driver"
                                        v-model="boardingCar.passenger_line_no"
                                        v-on:change="commitDriver"
                                    >
                                        <option id="input__adult" class="input__adult" :value="null">運転手を選択してください</option>
                                        <template v-for="passenger in driverList" :key="passenger.line_no">
                                            <option id="input__adult" class="input__adult" :value="passenger.line_no">
                                                {{ joinFirstNameAndLastName(passenger.family_name_katakana, passenger.given_name_katakana) }}
                                            </option>
                                        </template>
                                    </select>
                                </dd>
                                <p class="input__error-text">運転手を選択してください</p>
                            </div>
                        </div>
                        <!-- line -->
                        <div class="input__line">
                            <!-- car-length -->
                            <div :id="'input__item-car-length-' + boardingCar.line_no" class="input__item input__item-car-length">
                                <dt id="input__header-car-length" class="input__header input__header-car-length">
                                    <label id="input__name-car-length" class="input__name input__name-car-length" for="input__car-length">車長</label>
                                    <span class="input__required">必須</span>
                                </dt>
                                <dd id="input__container-car-length" class="input__container input__container-car-length">
                                    <input
                                        id="input__car-length"
                                        class="input__form input__car-length"
                                        type="number"
                                        name="input__car-length"
                                        min="3"
                                        max="6"
                                        v-model="boardingCar.car_length"
                                    />
                                    <span>ｍ未満</span>
                                </dd>
                                <p :id="'length-error-' + boardingCar.line_no" class="input__error-text"></p>
                                <p class="input__text">
                                    車長については車検証をご確認のうえ、対象m未満の数字を入力ください。<br />
                                    例）アクア：4,050mm　→　5m未満
                                </p>
                            </div>
                            <!-- car-model -->
                            <div id="input__item-car-model" class="input__item input__item-car-model">
                                <dt id="input__header-car-model" class="input__header input__header-car-model">
                                    <label id="input__name-car-model" class="input__name input__name-car-model" for="input__car-model">車種</label>
                                </dt>
                                <dd id="input__container-car-model" class="input__container input__container-car-model">
                                    <input
                                        id="input__car-model"
                                        class="input__form input__car-model"
                                        type="text"
                                        name="input__car-model"
                                        v-model="boardingCar.note"
                                    />
                                </dd>
                            </div>
                        </div>
                        <!-- line -->
                        <div :id="'input__line-number-car-' + car_type + '-' + boardingCar.line_no" class="input__line input__line-number">
                            <div id="input__item-number-wrapper" class="input__item-number-wrapper">
                                <div id="input__item-number-pack" class="input__item-number-pack">
                                    <!-- number-area -->
                                    <div id="input__item-number-area" class="input__item input__item-number-area">
                                        <dt id="input__header-number-area" class="input__header input__header-number-area">
                                            <label id="input__name-number-area" class="input__name input__name-number-area" for="">地域</label>
                                        </dt>
                                        <dd id="input__container-number-area" class="input__container input__container-number-area">
                                            <input
                                                id="input__number-area"
                                                class="input__form input__number-area"
                                                type="text"
                                                name="input__number-area"
                                                v-model="boardingCar.car_place_name"
                                            />
                                        </dd>
                                    </div>
                                    <!-- number-category -->
                                    <div id="input__item-number-category" class="input__item input__item-number-category">
                                        <dt id="input__header-numberplate" class="input__header input__header-number-category">
                                            <label id="input__name-number-category" class="input__name input__name-number-category" for=""
                                                >分類</label
                                            >
                                        </dt>
                                        <dd id="input__container-number-category" class="input__container input__container-number-category">
                                            <input
                                                id="input__number-category"
                                                class="input__form input__number-category"
                                                type="text"
                                                name="input__number-category"
                                                v-model="boardingCar.car_class_number"
                                            />
                                        </dd>
                                    </div>
                                    <!-- number-char -->
                                    <div id="input__item-number-char" class="input__item input__item-number-char">
                                        <dt id="input__header-number-char" class="input__header input__header-number-char">
                                            <label id="input__name-number-char" class="input__name input__name-number-char" for="">符号</label>
                                        </dt>
                                        <dd id="input__container-number-char" class="input__container input__container-number-char">
                                            <input
                                                id="input__number-char"
                                                class="input__form input__number-char"
                                                type="text"
                                                name="input__number-char"
                                                v-model="boardingCar.car_hiragana"
                                            />
                                        </dd>
                                    </div>
                                    <!-- number-plate -->
                                    <div
                                        :id="'input__item-number-plate-' + car_type + '-' + boardingCar.line_no"
                                        class="input__item input__item-number-plate"
                                    >
                                        <dt id="input__header-number-plate" class="input__header input__header-number-plate">
                                            <label id="input__name-number-plate" class="input__name input__name-number-plate" for=""
                                                >No.(1桁～4桁の数字のみ)</label
                                            >
                                            <span v-show="boardingCar.register_car" class="input__required">必須</span>
                                        </dt>
                                        <dd id="input__container-number-plate" class="input__container input__container-number-plate">
                                            <input
                                                id="input__number-plate"
                                                class="input__form input__number-plate"
                                                type="text"
                                                name="input__number-plate"
                                                v-model="boardingCar.car_number"
                                            />
                                        </dd>
                                        <p class="input__error-text">半角数字4桁以内で入力してください。</p>
                                    </div>
                                </div>
                                <div id="input__item-explain-car" class="input__item-explain input__item-explain-car">
                                    <img class="" src="@/images/items/plate-car.svg" width="247" height="67" alt="ナンバープレート説明画像" />
                                </div>
                            </div>
                            <div class="input__item-number-btn">
                                <button
                                    id="btn-number-open"
                                    class="btn-number-open input__item-number-open btn-number-open"
                                    v-on:click="openNumber(Number(car_type), boardingCar.line_no)"
                                >
                                    ナンバープレート詳細を記入する
                                </button>
                                <button
                                    id="btn-number-close"
                                    class="btn-number-close input__item-number-close btn-number-close"
                                    v-on:click="closeNumber(Number(car_type), boardingCar.line_no)"
                                >
                                    閉じる
                                </button>
                            </div>
                        </div>
                    </dl>
                </div>
            </div>
        </template>
    </li>
    <!-- car close -->
    <!-- bike -->
    <li id="form__item" class="form__item" v-for="(boardingCar, idx) in boardingCarList" :key="idx">
        <template v-if="car_type == 1 && boardingCar.deleted != true">
            <div class="form__item-header">
                <h3 class="form__item-name">二輪車（{{ idx + 1 }}）</h3>
                <button class="btn-delete" type="button" v-on:click="confirmationDeleteCar(boardingCar.line_no)">削除</button>
                <img class="form__item-icon" src="@/images/items/accessory-bike-01.svg" height="45" width="63" alt="二輪車アイコン" />
            </div>
            <div class="form__item-set">
                <select
                    id="form__passenger"
                    class="form__passenger input__select"
                    v-model="boardingCar.vehicle_id"
                    v-on:change="changeVehicleId(boardingCar.line_no)"
                >
                    <option class="" value="">車両情報から選択</option>
                    <template v-for="vehicle in vehicleList" :key="vehicle.id">
                        <option class="selectable" :value="vehicle.id">
                            {{ getListCarName(vehicle) }}
                        </option>
                    </template>
                </select>
                <div class="">
                    <input
                        :id="'form__regist-passenger-bike' + boardingCar.line_no"
                        class="form__regist-passenger"
                        type="checkbox"
                        v-model="boardingCar.register_car"
                    />
                    <label class="check" :for="'form__regist-passenger-bike' + boardingCar.line_no"
                        >車両情報に登録（ナンバーの入力が必須になります）</label
                    >
                </div>
            </div>
            <div class="form__item-container">
                <div class="input">
                    <dl class="input__list">
                        <!-- line -->
                        <div class="input__line input__line-bike-info">
                            <p class="input__attention">・事業用自動車および無人車はweb予約の対象外となります。</p>
                            <div class="input__item-bike-info-pack">
                                <!-- engine -->
                                <div :id="'input__item-engine-' + boardingCar.line_no" class="input__item input__item-engine">
                                    <dt id="input__header-engine" class="input__header input__header-engine">
                                        <label id="input__name-engine" class="input__name input__name-engine" for="input__engine">種別/排気量</label>
                                        <span class="input__required">必須</span>
                                    </dt>
                                    <dd id="input__container-engine" class="input__container input__container-engine">
                                        <select
                                            id="input__engine"
                                            class="input__select input__engine"
                                            name="input__engine"
                                            v-model="boardingCar.car_type_code"
                                        >
                                            <option id="input__" class="input__" value=""></option>
                                            <option class="selectable" :value="CAR_TYPE_BICYCLE">自転車</option>
                                            <option class="selectable" :value="CAR_TYPE_TWO_WHEEL_SMALL">125cc未満</option>
                                            <option class="selectable" :value="CAR_TYPE_TWO_WHEEL_MEDIUM">125cc〜750cc未満</option>
                                            <option class="selectable" :value="CAR_TYPE_TWO_WHEEL_LARGE">750cc以上</option>
                                        </select>
                                    </dd>
                                    <p class="input__error-text">種別/排気量を選択してください</p>
                                </div>
                                <!-- maker -->
                                <div id="input__item-maker" class="input__item input__item-maker">
                                    <dt id="input__header-maker" class="input__header input__header-maker">
                                        <label id="input__name-maker" class="input__name input__name-maker" for="input__maker">メーカー/車名</label>
                                    </dt>
                                    <dd id="input__container-maker" class="input__container input__container-maker">
                                        <input
                                            id="input__maker"
                                            class="input__form input__maker"
                                            type="text"
                                            name="input__maker"
                                            v-model="boardingCar.note"
                                        />
                                    </dd>
                                </div>
                            </div>
                        </div>
                        <!-- line -->
                        <div :id="'input__line-number-bike-' + car_type + '-' + boardingCar.line_no" class="input__line input__line-number">
                            <div id="input__item-number-wrapper" class="input__item-number-wrapper">
                                <div id="input__item-number-pack" class="input__item-number-pack">
                                    <!-- number-area -->
                                    <div id="input__item-number-area" class="input__item input__item-number-area">
                                        <dt id="input__header-number-area" class="input__header input__header-number-area">
                                            <label id="input__name-number-area" class="input__name input__name-number-area" for="">地域</label>
                                        </dt>
                                        <dd id="input__container-number-area" class="input__container input__container-number-area">
                                            <input
                                                id="input__number-area"
                                                class="input__form input__number-area"
                                                type="text"
                                                name="input__number-area"
                                                v-model="boardingCar.car_place_name"
                                            />
                                        </dd>
                                    </div>
                                    <!-- number-category -->
                                    <div id="input__item-number-category" class="input__item input__item-number-category">
                                        <dt id="input__header-numberplate" class="input__header input__header-number-category">
                                            <label id="input__name-number-category" class="input__name input__name-number-category" for=""
                                                >分類</label
                                            >
                                        </dt>
                                        <dd id="input__container-number-category" class="input__container input__container-number-category">
                                            <input
                                                id="input__number-category"
                                                class="input__form input__number-category"
                                                type="text"
                                                name="input__number-category"
                                                v-model="boardingCar.car_class_number"
                                            />
                                        </dd>
                                    </div>
                                    <!-- number-char -->
                                    <div id="input__item-number-char" class="input__item input__item-number-char">
                                        <dt id="input__header-number-char" class="input__header input__header-number-char">
                                            <label id="input__name-number-char" class="input__name input__name-number-char" for="">符号</label>
                                        </dt>
                                        <dd id="input__container-number-char" class="input__container input__container-number-char">
                                            <input
                                                id="input__number-char"
                                                class="input__form input__number-char"
                                                type="text"
                                                name="input__number-char"
                                                v-model="boardingCar.car_hiragana"
                                            />
                                        </dd>
                                    </div>
                                    <!-- number-plate -->
                                    <div
                                        :id="'input__item-number-plate-' + car_type + '-' + boardingCar.line_no"
                                        class="input__item input__item-number-plate"
                                    >
                                        <dt id="input__header-number-plate" class="input__header input__header-number-plate">
                                            <label id="input__name-number-plate" class="input__name input__name-number-plate" for=""
                                                >No.(1桁～5桁の数字のみ)</label
                                            >
                                            <span v-show="boardingCar.register_car" class="input__required">必須</span>
                                        </dt>
                                        <dd id="input__container-number-plate" class="input__container input__container-number-plate">
                                            <input
                                                id="input__number-plate"
                                                class="input__form input__number-plate"
                                                type="text"
                                                name="input__number-plate"
                                                v-model="boardingCar.car_number"
                                            />
                                        </dd>
                                        <p class="input__error-text">半角数字5桁以内で入力してください。</p>
                                    </div>
                                </div>
                                <div id="input__item-explain-bike" class="input__item-explain input__item-explain-bike">
                                    <img
                                        class="input__item-explain-bike-01"
                                        src="@/images/items/plate-bike-01.svg"
                                        width="247"
                                        height="112"
                                        alt="ナンバープレート説明画像01"
                                    />
                                    <img
                                        class="input__item-explain-bike-02"
                                        src="@/images/items/plate-bike-02.svg"
                                        width="247"
                                        height="85"
                                        alt="ナンバープレート説明画像02"
                                    />
                                </div>
                            </div>
                            <div class="input__item-number-btn">
                                <button
                                    id="btn-number-open"
                                    class="btn-number-open input__item-number-open"
                                    v-on:click="openNumber(Number(car_type), boardingCar.line_no)"
                                >
                                    ナンバープレート詳細を記入する
                                </button>
                                <button
                                    id="btn-number-close"
                                    class="btn-number-close input__item-number-close"
                                    v-on:click="closeNumber(Number(car_type), boardingCar.line_no)"
                                >
                                    閉じる
                                </button>
                            </div>
                        </div>
                    </dl>
                </div>
            </div>
        </template>
    </li>
    <!-- bike close -->
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, nextTick } from 'vue'
import $ from 'jquery'
import {
    API_USERS,
    API_ORDER,
    isNull,
    getVehicleDetail,
    CAR_TYPE_PASSENGER,
    CAR_TYPE_BICYCLE,
    CAR_TYPE_TWO_WHEEL_SMALL,
    CAR_TYPE_TWO_WHEEL_MEDIUM,
    CAR_TYPE_TWO_WHEEL_LARGE,
    getCarFare,
    getTwoWheelFare,
    joinFirstNameAndLastName,
    getCarPrice,
} from '@/lib/util'
import {
    PASSENGER_TYPE_ADULT,
    VEHICLE_RESTRICT_CAR,
    VEHICLE_RESTRICT_NONE,
    VEHICLE_RESTRICT_PASSENGER,
    VEHICLE_RESTRICT_TWO_AXLE_CAR,
} from '@/lib/constants'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { CarDetail, VehicleDetail, ContractCarFare, PassengerDetail, Contract, CancelPolicyDetail } from '@/lib/types'
import { getVehicleData } from '@/lib/localStorageUtil'
import { isNumeric } from '@/lib/validation_util'

export default defineComponent({
    setup(props, context) {
        const car_count = ref(0)
        const boardingCarList = ref<CarDetail[]>()
        const vehicleList = ref<VehicleDetail[]>()
        const driverList = ref<PassengerDetail[]>()
        const contract = ref<Contract>()
        const currentCancelPolicy = ref<CancelPolicyDetail | null>(null)

        const init = (boardingCars: CarDetail[], target_contract: Contract, cancel_policy: CancelPolicyDetail | null) => {
            console.log('↓↓↓BoardingCarList.init↓↓↓')
            boardingCarList.value = boardingCars
            car_count.value = boardingCarList.value.length
            contract.value = target_contract
            currentCancelPolicy.value = cancel_policy
            console.log('↑↑↑BoardingCarList.init↑↑↑')
            // nextTick(() => {
            //     setNumberClose();
            // })
        }

        const setNumberClose = () => {
            // 地域、分類、符号が入力されていない場合、ナンバー詳細を閉じる
            if (props.car_type == 0) {
                for (let i = 0; i < boardingCarList.value!.length; i++) {
                    $('#input__line-number-car-' + props.car_type + '-' + boardingCarList.value![i].line_no).removeClass('close')
                    if (
                        isNull(boardingCarList.value![i].car_class_number) &&
                        isNull(boardingCarList.value![i].car_hiragana) &&
                        isNull(boardingCarList.value![i].car_place_name)
                    ) {
                        $('#input__line-number-car-' + props.car_type + '-' + boardingCarList.value![i].line_no).addClass('close')
                    }
                }
            } else {
                for (let i = 0; i < boardingCarList.value!.length; i++) {
                    $('#input__line-number-bike-' + props.car_type + '-' + boardingCarList.value![i].line_no).removeClass('close')
                    if (
                        isNull(boardingCarList.value![i].car_class_number) &&
                        isNull(boardingCarList.value![i].car_hiragana) &&
                        isNull(boardingCarList.value![i].car_place_name)
                    ) {
                        $('#input__line-number-bike-' + props.car_type + '-' + boardingCarList.value![i].line_no).addClass('close')
                    }
                }
            }
        }

        const closeNumber = (car_type: number, line_no: number) => {
            if (car_type == 0) {
                $('#input__line-number-car-' + car_type + '-' + line_no).addClass('close')
            } else {
                $('#input__line-number-bike-' + car_type + '-' + line_no).addClass('close')
            }
        }

        const openNumber = (car_type: number, line_no: number) => {
            if (car_type == 0) {
                $('#input__line-number-car-' + car_type + '-' + line_no).removeClass('close')
            } else {
                $('#input__line-number-bike-' + car_type + '-' + line_no).removeClass('close')
            }
        }

        const addCar = () => {
            console.log('↓↓↓BoardingCarList.addCar↓↓↓')
            let new_line_no = 0
            for (let i = 0; i < boardingCarList.value!.length; i++) {
                if (new_line_no < boardingCarList.value![i].line_no) {
                    new_line_no = boardingCarList.value![i].line_no
                }
            }
            new_line_no += 1
            boardingCarList.value!.push({
                id: null,
                line_no: new_line_no,
                car_type_code: props.car_type == 0 ? CAR_TYPE_PASSENGER : '',
                car_length: props.car_type == 0 ? null : 3,
                car_width: 2.5,
                car_place_name: '',
                car_class_number: '',
                car_hiragana: '',
                car_number: '',
                note: '',
                deleted: false,
                register_car: false,
            } as CarDetail)
            console.log('↑↑↑BoardingCarList.addCar↑↑↑')
        }

        const getCarCount = (car_type: string, car_length: number): number => {
            let cnt = 0
            if (!boardingCarList.value) {
                return cnt
            }

            if (car_type == CAR_TYPE_PASSENGER) {
                for (let i = 0; i < boardingCarList.value!.length; i++) {
                    if (boardingCarList.value![i].deleted == true) {
                        continue
                    }
                    if (boardingCarList.value![i].car_type_code == car_type && boardingCarList.value![i].car_length == car_length) {
                        cnt++
                    }
                }
            } else {
                for (let i = 0; i < boardingCarList.value!.length; i++) {
                    if (boardingCarList.value![i].deleted == true) {
                        continue
                    }
                    if (boardingCarList.value![i].car_type_code == car_type) {
                        cnt++
                    }
                }
            }
            return cnt
        }

        const getData = (): CarDetail[] => {
            return boardingCarList.value!
        }

        // 料金計算
        const calcPrice = async (line_no: number) => {
            for (let i = 0; i < boardingCarList.value!.length; i++) {
                if (boardingCarList.value![i].line_no == line_no) {
                    if (boardingCarList.value![i].deleted == true) {
                        boardingCarList.value![i].unit_price = 0
                        boardingCarList.value![i].baf_amount = 0
                    } else if (props.car_type == 0 && boardingCarList.value![i].car_type_code == CAR_TYPE_PASSENGER) {
                        const contract_fare = await getCarFare(
                            props.dep_date!,
                            props.sea_route_code!,
                            props.contract_number!,
                            Number(boardingCarList.value![i].car_length),
                        )
                        boardingCarList.value![i].unit_price = contract_fare.person_fare
                        boardingCarList.value![i].baf_amount = contract_fare.baf
                    } else if (props.car_type == 1 && boardingCarList.value![i].car_type_code != CAR_TYPE_PASSENGER) {
                        const contract_fare = await getTwoWheelFare(
                            props.dep_date!,
                            props.sea_route_code!,
                            props.contract_number!,
                            boardingCarList.value![i].car_type_code,
                        )
                        boardingCarList.value![i].unit_price = contract_fare.person_fare
                        boardingCarList.value![i].baf_amount = contract_fare.baf
                    }
                    break
                }
            }
        }
        const calcPriceAll = async () => {
            if (!isNull(boardingCarList.value!)) {
                for (let i = 0; i < boardingCarList.value!.length; i++) {
                    await calcPrice(boardingCarList.value![i].line_no)
                }
            }
        }
        const getTotalPrice = (): number => {
            let total_price = 0
            if (!isNull(boardingCarList.value)) {
                for (let i = 0; i < boardingCarList.value!.length; i++) {
                    if (
                        (props.car_type == 0 && boardingCarList.value![i].car_type_code == CAR_TYPE_PASSENGER) ||
                        (props.car_type == 1 && boardingCarList.value![i].car_type_code != CAR_TYPE_PASSENGER)
                    ) {
                        total_price += getCarPrice(boardingCarList.value![i])
                    }
                }
            }
            return total_price
        }
        // 入力チェック
        const chkValidation = (): any[] => {
            let err_count = 0
            let err_messages = [] as string[]

            if (props.car_type == 0) {
                // 乗用車
                let used_driver_passenger_line_nos = [] as number[]
                let duplication_driver_passenger_line_nos = [] as number[]
                for (let i = 0; i < boardingCarList.value!.length; i++) {
                    $('#length-error-' + boardingCarList.value![i].line_no).text('')
                    // 削除はスキップ
                    if (boardingCarList.value![i].deleted == true) {
                        continue
                    }
                    if (boardingCarList.value![i].car_type_code == CAR_TYPE_PASSENGER) {
                        // エラー表示初期化
                        $('#input__item-driver-' + boardingCarList.value![i].line_no).removeClass('input__error')
                        $('#input__item-car-length-' + boardingCarList.value![i].line_no).removeClass('input__error')
                        $('#input__item-number-plate-' + props.car_type + '-' + boardingCarList.value![i].line_no).removeClass('input__error')
                        // 運転手
                        if (isNull(boardingCarList.value![i].passenger_line_no)) {
                            $('#input__item-driver-' + boardingCarList.value![i].line_no).addClass('input__error')
                            err_count++
                        } else {
                            if (used_driver_passenger_line_nos.includes(boardingCarList.value![i].passenger_line_no!)) {
                                duplication_driver_passenger_line_nos.push(boardingCarList.value![i].passenger_line_no!)
                            } else {
                                used_driver_passenger_line_nos.push(boardingCarList.value![i].passenger_line_no!)
                            }
                        }
                        // 車長
                        if (isNull(boardingCarList.value![i].car_length)) {
                            $('#input__item-car-length-' + boardingCarList.value![i].line_no).addClass('input__error')
                            $('#length-error-' + boardingCarList.value![i].line_no).text('車長を入力してください')
                            err_count++
                        } else if (!isNumeric(boardingCarList.value![i].car_length + '')) {
                            $('#input__item-car-length-' + boardingCarList.value![i].line_no).addClass('input__error')
                            $('#length-error-' + boardingCarList.value![i].line_no).text('車長は数字で入力してください')
                            err_count++
                        } else {
                            const length = isNull(boardingCarList.value![i].car_length) ? 0 : Number(boardingCarList.value![i].car_length)
                            if (length < 3 || length > 6) {
                                $('#input__item-car-length-' + boardingCarList.value![i].line_no).addClass('input__error')
                                $('#length-error-' + boardingCarList.value![i].line_no).text('web予約は6ｍ未満までの自家用車両が対象です')
                                err_count++
                            }
                        }
                        // No.
                        if (boardingCarList.value![i].register_car && isNull(boardingCarList.value![i].car_number)) {
                            $('#input__item-number-plate-' + props.car_type + '-' + boardingCarList.value![i].line_no).addClass('input__error')
                            err_count++
                        } else if (!isNull(boardingCarList.value![i].car_number)) {
                            // 半角数字のみ
                            if (!isNumeric(boardingCarList.value![i].car_number)) {
                                $('#input__item-number-plate-' + props.car_type + '-' + boardingCarList.value![i].line_no).addClass('input__error')
                                err_count++
                            }
                            // 4桁以下
                            if (boardingCarList.value![i].car_number.length > 4) {
                                $('#input__item-number-plate-' + props.car_type + '-' + boardingCarList.value![i].line_no).addClass('input__error')
                                err_count++
                            }
                        }
                    }
                }
                if (duplication_driver_passenger_line_nos.length > 0) {
                    for (let i = 0; i < boardingCarList.value!.length; i++) {
                        // 削除はスキップ
                        if (boardingCarList.value![i].deleted == true) {
                            continue
                        }
                        if (
                            boardingCarList.value![i].car_type_code == CAR_TYPE_PASSENGER &&
                            duplication_driver_passenger_line_nos.includes(boardingCarList.value![i].passenger_line_no!)
                        ) {
                            $('#input__item-driver-' + boardingCarList.value![i].line_no).addClass('input__error')
                        }
                    }
                    err_count++
                    err_messages.push('同じ方を複数の乗用車の運転手にすることはできません。')
                }
            } else {
                // 2輪車
                for (let i = 0; i < boardingCarList.value!.length; i++) {
                    // 削除はスキップ
                    if (boardingCarList.value![i].deleted == true) {
                        continue
                    }
                    if (boardingCarList.value![i].car_type_code != CAR_TYPE_PASSENGER) {
                        // エラー表示初期化
                        $('#input__item-engine-' + boardingCarList.value![i].line_no).removeClass('input__error')
                        $('#input__item-number-plate-' + props.car_type + '-' + boardingCarList.value![i].line_no).removeClass('input__error')
                        // 種別・排気量
                        if (isNull(boardingCarList.value![i].car_type_code)) {
                            $('#input__item-engine-' + boardingCarList.value![i].line_no).addClass('input__error')
                            err_count++
                        }
                        // No.
                        if (boardingCarList.value![i].register_car && isNull(boardingCarList.value![i].car_number)) {
                            $('#input__item-number-plate-' + props.car_type + '-' + boardingCarList.value![i].line_no).addClass('input__error')
                            err_count++
                        } else if (!isNull(boardingCarList.value![i].car_number)) {
                            // 半角数字のみ
                            if (!isNumeric(boardingCarList.value![i].car_number)) {
                                $('#input__item-number-plate-' + props.car_type + '-' + boardingCarList.value![i].line_no).addClass('input__error')
                                err_count++
                            }
                            // 4桁以下
                            if (boardingCarList.value![i].car_number.length > 5) {
                                $('#input__item-number-plate-' + props.car_type + '-' + boardingCarList.value![i].line_no).addClass('input__error')
                                err_count++
                            }
                        }
                    }
                }
            }

            return [err_count, err_messages]
        }
        // 入力チェック（成約制限）
        const chkValidationContract = (): string[] => {
            let err_messages = [] as string[]
            let now = new Date()

            // 車両制限
            if (!isNull(contract.value!.vehicle_restrict) && contract.value!.vehicle_restrict != VEHICLE_RESTRICT_NONE) {
                if (props.car_type == 0) {
                    // 乗用車
                    let no_car_flg = true
                    for (let i = 0; i < boardingCarList.value!.length; i++) {
                        // 削除はスキップ
                        if (boardingCarList.value![i].deleted == true) {
                            continue
                        }
                        if (boardingCarList.value![i].car_type_code == CAR_TYPE_PASSENGER) {
                            no_car_flg = false
                            if (contract.value!.vehicle_restrict == VEHICLE_RESTRICT_TWO_AXLE_CAR) {
                                // バイクのみ
                                err_messages.push(`こちらのプランは二輪車のみをご利用の場合ご予約可能となっております`)
                                break
                            }
                        }
                    }
                    if (no_car_flg) {
                        if (contract.value!.vehicle_restrict == VEHICLE_RESTRICT_CAR) {
                            // 車のみ
                            err_messages.push(`こちらのプランは乗用車をご利用の場合ご予約可能となっております`)
                        }
                    } else {
                        if (contract.value!.vehicle_restrict == VEHICLE_RESTRICT_PASSENGER) {
                            // 人のみ
                            err_messages.push(`こちらのプランは乗用車・二輪車のご利用はできません`)
                        }
                    }
                } else {
                    // 2輪車
                    let no_car_flg = true
                    for (let i = 0; i < boardingCarList.value!.length; i++) {
                        // 削除はスキップ
                        if (boardingCarList.value![i].deleted == true) {
                            continue
                        }
                        if (boardingCarList.value![i].car_type_code != CAR_TYPE_PASSENGER) {
                            no_car_flg = false
                            if (contract.value!.vehicle_restrict == VEHICLE_RESTRICT_CAR) {
                                // 車のみ
                                err_messages.push(`こちらのプランは乗用車のみをご利用の場合ご予約可能となっております`)
                                break
                            }
                            if (contract.value!.vehicle_restrict == VEHICLE_RESTRICT_PASSENGER) {
                                // 人のみ
                                err_messages.push(`こちらのプランは乗用車・二輪車のご利用はできません`)
                                break
                            }
                        }
                    }
                    if (no_car_flg) {
                        if (contract.value!.vehicle_restrict == VEHICLE_RESTRICT_TWO_AXLE_CAR) {
                            // バイクのみ
                            err_messages.push(`こちらのプランは二輪車をご利用の場合ご予約可能となっております`)
                        }
                    } else {
                        if (contract.value!.vehicle_restrict == VEHICLE_RESTRICT_PASSENGER) {
                            // 人のみ
                            err_messages.push(`こちらのプランは乗用車・二輪車のご利用はできません`)
                        }
                    }
                }
            }

            if (props.isUpdate) {
                // 更新時のみチェック
            }

            return err_messages
        }

        // 車両情報削除確認
        const confirmationDeleteCar = (line_no: number) => {
            deleteCar(line_no)
        }

        // 車両情報削除
        const deleteCar = (line_no: number) => {
            console.log('↓↓↓BoardingCar.deleteCar↓↓↓')
            let tmpArray = []
            for (let i = 0; i < boardingCarList.value!.length; i++) {
                if (boardingCarList.value![i].line_no != line_no) {
                    tmpArray.push(boardingCarList.value![i])
                } else {
                    if (!isNull(boardingCarList.value![i].id)) {
                        boardingCarList.value![i].deleted = true
                        tmpArray.push(boardingCarList.value![i])
                    }
                }
            }
            boardingCarList.value = tmpArray
            // ドライバーの設定を反映する
            commitDriver()
            console.log('↑↑↑BoardingCar.deleteCar↑↑↑')
        }

        // 車両情報から選択時処理
        const changeVehicleId = (line_no: number) => {
            for (let i = 0; i < boardingCarList.value!.length; i++) {
                if (boardingCarList.value![i].line_no == line_no) {
                    let vehicle = getVehicleDetail(boardingCarList.value![i].vehicle_id!)
                    if (!isNull(vehicle)) {
                        boardingCarList.value![i].car_type_code = vehicle!.car_type_code!
                        boardingCarList.value![i].car_length = vehicle!.car_length!
                        boardingCarList.value![i].car_width = 2.5
                        boardingCarList.value![i].car_place_name = vehicle!.car_place_name!
                        boardingCarList.value![i].car_class_number = vehicle!.car_class_number!
                        boardingCarList.value![i].car_hiragana = vehicle!.car_hiragana!
                        boardingCarList.value![i].car_number = vehicle!.car_number!
                        boardingCarList.value![i].note = vehicle!.car_name!
                        boardingCarList.value![i].register_car = false
                    }
                    calcPrice(line_no)
                }
            }
        }

        // ドライバーリスト設定
        const setDriverList = (passengerList: PassengerDetail[] | undefined) => {
            driverList.value = passengerList?.filter((p) => p.deleted != true && p.passenger_type == PASSENGER_TYPE_ADULT)
            if (!isNull(boardingCarList.value)) {
                for (let i = 0; i < boardingCarList.value!.length; i++) {
                    if (driverList.value?.filter((p) => p.line_no == boardingCarList.value![i].passenger_line_no).length == 0) {
                        boardingCarList.value![i].passenger_line_no = null
                    }
                }
            }
        }

        const getListCarName = (car: VehicleDetail): string => {
            if (isNull(car)) {
                return ''
            }
            if (car.car_type_code == CAR_TYPE_PASSENGER) {
                return `乗用車：${car.car_name}`
            } else if (car.car_type_code == CAR_TYPE_BICYCLE) {
                return `自転車：${car.car_name}`
            } else if (car.car_type_code == CAR_TYPE_TWO_WHEEL_SMALL) {
                return `125cc未満：${car.car_name}`
            } else if (car.car_type_code == CAR_TYPE_TWO_WHEEL_MEDIUM) {
                return `125cc〜750cc未満：${car.car_name}`
            } else if (car.car_type_code == CAR_TYPE_TWO_WHEEL_LARGE) {
                return `750cc以上：${car.car_name}`
            }
            return ''
        }

        /** ドライバーの設定をクリアする */
        const clearDriver = () => {
            if (!isNull(boardingCarList.value)) {
                for (let i = 0;i < boardingCarList.value!.length; i++) {
                    boardingCarList.value![i].passenger_line_no = null;
                }
            }
            commitDriver();
        }

        // ドライバーの設定を反映する
        const commitDriver = () => {
            let driver_passenger_line_nos = [] as any[];
            if (!isNull(boardingCarList.value)) {
                for (let i = 0;i < boardingCarList.value!.length; i++) {
                    if (boardingCarList.value![i].deleted != true) {
                        driver_passenger_line_nos.push({
                            passenger_line_no: boardingCarList.value![i].passenger_line_no,
                            car_line_no: boardingCarList.value![i].line_no
                        });
                    } else {
                        driver_passenger_line_nos.push({
                            passenger_line_no: -9999,
                            car_line_no: boardingCarList.value![i].line_no
                        });
                    }
                }
            }
            context.emit('commitDriver', driver_passenger_line_nos)
        }

        onMounted(() => {
            console.log('↓↓↓BoardingCarList.onMounted↓↓↓')
            vehicleList.value = []
            const tmpList = getVehicleData()
            for (let i = 0; i < tmpList.length; i++) {
                if (
                    (props.car_type == 0 && tmpList[i].car_type_code == CAR_TYPE_PASSENGER) ||
                    (props.car_type == 1 && tmpList[i].car_type_code != CAR_TYPE_PASSENGER)
                ) {
                    vehicleList.value.push(tmpList[i])
                }
            }
            console.log('↑↑↑BoardingCarList.onMounted↑↑↑')
        })

        return {
            car_count,
            boardingCarList,
            vehicleList,
            driverList,

            init,
            addCar,
            getCarCount,
            getData,
            calcPrice,
            calcPriceAll,
            getTotalPrice,
            chkValidation,
            chkValidationContract,
            setDriverList,
            getListCarName,
            closeNumber,
            openNumber,

                confirmationDeleteCar,
                deleteCar,
                changeVehicleId,
                joinFirstNameAndLastName,
                clearDriver,
                commitDriver,
            };
        },
        emits: ["commitDriver", "displayMessage"],
        name: 'BoardingCarList',
        data() {
            return {
                PASSENGER_TYPE_ADULT: PASSENGER_TYPE_ADULT as number,

            CAR_TYPE_PASSENGER: CAR_TYPE_PASSENGER as string,
            CAR_TYPE_BICYCLE: CAR_TYPE_BICYCLE as string,
            CAR_TYPE_TWO_WHEEL_SMALL: CAR_TYPE_TWO_WHEEL_SMALL as string,
            CAR_TYPE_TWO_WHEEL_MEDIUM: CAR_TYPE_TWO_WHEEL_MEDIUM as string,
            CAR_TYPE_TWO_WHEEL_LARGE: CAR_TYPE_TWO_WHEEL_LARGE as string,
        }
    },

    components: {},
    props: {
        dep_date: String,
        sea_route_code: String,
        contract_number: String,
        car_type: Number,
        isUpdate: Boolean,
    },
    methods: {},
    mounted: function () {
        //numberplate open/close
        // $(function () {
        //     $('.input__line-number .btn-number-close').click(function () {
        //         $(this).parent().parent().addClass('close');
        //     });
        //     $('.input__line-number .btn-number-open').click(function () {
        //         $(this).parent().parent().removeClass('close');
        //     });
        // });
    },
})
</script>

<style scoped></style>
