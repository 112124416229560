<template>
    <!-- login -->
    <section id="login" class="login">
        <div class="login__container">
            <section class="login__regist">
                <h2 class="login__regist-title">はじめてご利用される方へ</h2>
                <p class="login__regist-text">会員登録（無料）が必要となります。</p>
                <div class="login__func">
                    <h3 class="login__func-title">WEB予約会員になると、<br />さまざまな機能が利用できます</h3>
                    <dl class="login__func-list">
                        <div class="login__func-item">
                            <dt class="login__func-name">現在の予約確認</dt>
                            <dd class="login__func-text">WEB予約いただいた出発前の予約内容をご確認いただけます。</dd>
                        </div>
                        <div class="login__func-item">
                            <dt class="login__func-name">過去の予約確認</dt>
                            <dd class="login__func-text">WEB予約いただいた過去の予約履歴をご確認いただけます。また、領収書の出力が可能です。</dd>
                        </div>
                        <div class="login__func-item">
                            <dt class="login__func-name">その他</dt>
                            <dd class="login__func-text">
                                会員情報や同乗者・車両情報をご登録いただけます。また、メールマガジンを希望されると、お得な情報等をお届けします。
                            </dd>
                        </div>
                    </dl>
                </div>
                <div class="login__privacy">
                    <h3 class="login__privacy-title">個人情報に関して</h3>
                    <p class="login__privacy-text">
                        詳しくは<a href="/member/privacy_policy" target="_blank">プライバシーポリシー</a>をご覧ください。
                    </p>
                </div>
            </section>
            <section class="login__form">
                <h3 class="login__form-title">ログイン</h3>
                <dl class="login__form-list">
                    <div class="login__form-item">
                        <dt class="login__form-header">
                            <label class="login__form-name" for="login__form-input-id">利用者ID</label>
                        </dt>
                        <dd id="login__form-container-id" class="login__form-container">
                            <input
                                id="login__form-input-id"
                                class="login__form-input login__form-input-id"
                                type="text"
                                name="login__form-input-id"
                                v-model="member_id"
                            />
                        </dd>
                        <span id="error-text-id"><p class="input__error-text">利用者IDを入力してください</p></span>
                    </div>
                    <div class="login__form-item">
                        <dt class="login__form-header">
                            <label class="login__form-name" for="login__form-input-pass">パスワード</label>
                        </dt>
                        <dd id="login__form-container-pass" class="login__form-container login__form-container-pass password">
                            <input
                                id="login__form-input-pass"
                                class="login__form-input login__form-input-pass"
                                type="password"
                                name="login__form-input-pass"
                                v-model="password"
                            />
                            <button class="btn-disppass"></button>
                        </dd>
                        <span id="error-text-pass"><p class="input__error-text">パスワードを入力してください</p></span>
                        <span id="error-text-login"
                            ><p class="input__error-text">会員IDまたはパスワードが違います。有効な会員ID、パスワードを入力してください。</p></span
                        >
                        <span id="error-text-account"
                            ><p class="input__error-text">
                                ログイン失敗によるアカウントロックが発生しております。お急ぎの方はパスワードを変更してログインを再試行してください。
                            </p></span
                        >
                    </div>
                </dl>
                <div class="login__form-btn-container">
                    <button class="btn btn-login" type="button" v-on:click="login">ログイン</button>
                    <button class="btn btn-regist" type="button" v-on:click="createMember">新規利用登録（無料）</button>
                </div>
                <ul class="login__form-link-list">
                    <li class="login__form-link-item">
                        <a v-on:click="forgetMemberId">利用者IDを忘れた方はこちら</a>
                    </li>
                    <li class="login__form-link-item">
                        <a v-on:click="forgetPass">パスワードを忘れた方はこちら</a>
                    </li>
                </ul>
                <ul class="login__form-note-list">
                    <li class="login__form-note-item">ログインには、会員登録時に設定いただいた利用者IDとパスワードが必要です。</li>
                    <li class="login__form-note-item">2023年10月1日乗船分以降のご予約より会員システムが新しくなっております。</li>
                    <li class="login__form-note-item">9月30日までの乗船予約システムご利用のお客様は再度「新規会員登録」をお願いいたします。</li>
                    <li class="login__form-note-item">
                        セキュリティ確保のため、5回続けてパスワードの入力を間違えると、60分間ログインができない状態となります。60分経過後に再度入力いただくか、「パスワードを忘れた方はこちら」より、パスワードの再設定を行ってください。
                    </li>
                </ul>
            </section>
        </div>
    </section>
    <!-- /login -->
    <!-- confirm -->
    <button id="btn-terms-open-modal" data-remodal-target="terms" v-show="false">○</button>
    <section id="terms" class="confirm remodal normal" data-remodal-id="terms" data-remodal-options="closeOnOutsideClick: false">
        <template class="terms terms-top">
            <div class="terms__container">
                <div class="terms__header">
                    <h2 class="terms__name">会員規約</h2>
                    <button id="btn-terms_close-modal" class="confirm__close" data-remodal-action="cancel" v-show="false">×</button>
                </div>
                <ul id="terms__list-passenger" class="terms__list terms__list-passenger">
                    <li class="terms__item terms__item-header">
                        <ul class="terms__line terms__line-header">
                            <li class="terms__line-item terms__line-item-no">No.</li>
                            <li class="terms__line-item terms__line-item-status">状態</li>
                            <li class="terms__line-item terms__line-item-detail">内容</li>
                            <li class="terms__line-item terms__line-item-datetime">更新日</li>
                            <li class="terms__line-item terms__line-item-btn2"></li>
                        </ul>
                    </li>
                    <li class="terms__item">
                        <ul class="terms__line">
                            <li class="terms__line-item terms__line-item-no">1</li>
                            <li class="terms__line-item terms__line-item-status">
                                {{ isTermsStatus }}
                            </li>
                            <li class="terms__line-item terms__line-item-detail">会員規約</li>
                            <li class="terms__line-item terms__line-item-datetime">
                                {{ formatDate(terms_update_datetime, false, 2) }}
                            </li>
                            <li class="terms__line-item terms__line-item-btn2">
                                <button class="btn btn-data-change" v-on:click="displayTerms">表示</button>
                            </li>
                        </ul>
                    </li>
                    <li class="terms__item">
                        <ul class="terms__line">
                            <li class="terms__line-item terms__line-item-no">2</li>
                            <li class="terms__line-item terms__line-item-status">
                                {{ isPolicyStatus }}
                            </li>
                            <li class="terms__line-item terms__line-item-detail">プライバシーポリシー</li>
                            <li class="terms__line-item terms__line-item-datetime">
                                {{ formatDate(policy_update_datetime, false, 2) }}
                            </li>
                            <li class="terms__line-item terms__line-item-btn2">
                                <button class="btn btn-data-change" v-on:click="displayPolicy">表示</button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="confirm__btn">
                <button class="btn btn-back" type="button" v-on:click="closeTerms">閉じる</button>
            </div>
        </template>
    </section>
    <!-- /confirm -->
    <!-- animation -->
    <ShipAnimationStart></ShipAnimationStart>
    <ShipAnimationEnd></ShipAnimationEnd>
    <!-- /animation -->
</template>
<script lang="ts">
import { defineComponent, ref, nextTick, onMounted, computed } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import {
    API_USERS,
    API_ORDER,
    setMemberDetail,
    setCompanionMaster,
    setVehicleMaster,
    isNull,
    formatDate,
    formatDateTime,
    castStringToDate,
    getRouterPushProperties,
    setRouterPushProperties,
} from '@/lib/util'
import ShipAnimationStart from '@/components/common/ShipAnimationStart.vue'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { getMemberData, removeMemberData } from '@/lib/localStorageUtil'
import { isZenKatakana, isMail, isMemberId } from '@/lib/validation_util'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
    setup(props, context) {
        // ログイン
        const router = useRouter()
        const store = useStore()
        const recaptchaInstance = useReCaptcha()

        const member_id = ref('')
        const password = ref('')

        // 規約更新日時チェック
        const isTerms = ref(true)
        const terms_update_datetime = ref()
        // プライバシーポリシー更新日時チェック
        const isPolicy = ref(true)
        const policy_update_datetime = ref()

        // ログイン
        const login = () => {
            console.log('↓↓↓Login.login↓↓↓')
            // 規約更新日時チェック、プライバシーポリシー更新日時チェック初期化
            isTerms.value = true
            isPolicy.value = true
            $('#error-text-login').removeClass('input__error')
            $('#error-text-account').removeClass('input__error')
            // 入力チェック
            if (chkValidation()) {
                const postData = {
                    member_id: member_id.value,
                    password: password.value,
                }
                store.dispatch('setIsProcessing', true)
                nextTick(() => {})
                apiClient
                    .post(`${API_USERS}/login`, postData)
                    .then(async (res: AxiosResponse<any>) => {
                        const { status, data } = res
                        // store.dispatch('setIsProcessing', false);
                        localStorage.setItem('access', data.token)
                        context.emit('displayMessage', {
                            msg_title: 'ログイン完了',
                            msg: [
                                '<span style="font-weight:bold; color:#4169e1;">2時間以内に手続きを完了してください</span>',
                                '※ログイン完了から2時間経過すると自動的にログアウトいたします。',
                            ],
                            close_btn: true,
                        })
                        await loginAfter()
                    })
                    .catch((e: AxiosError) => {
                        console.log(e)
                        if (e.response?.status == 403) {
                            $('#error-text-account').addClass('input__error')
                        } else {
                            $('#error-text-login').addClass('input__error')
                        }
                        // エラー処理
                        console.log(e.message)
                        setTimeout(() => {
                            store.dispatch('setIsProcessing', false)
                        }, 400)
                    })
            }
            console.log('↑↑↑Login.login↑↑↑')
        }
        // ログイン後処理
        const loginAfter = async () => {
            let result = [] as any[]
            store.dispatch('setIsProcessing', true)
            await Promise.all([
                // メンバー情報取得
                setMemberDetail(),
                // 規約確認状況チェック
                chkTermsPrivacy(),
            ]).then((res) => (result = res))
            store.dispatch('setIsProcessing', false)
            // ストアに反映
            setStoreLoginStateFromMemberData()
            if (result[1]) {
                // 規約OK
                let router_push_properties = getRouterPushProperties()
                if (!isNull(router_push_properties) && !isNull(router_push_properties.move_after_login)) {
                    router.push(router_push_properties.move_after_login!)
                    router_push_properties.move_after_login = null
                    setRouterPushProperties({
                        isUpdate: false,
                        base_data: router_push_properties,
                    })
                    return
                } else {
                    // マイページへ移動
                    moveMyPage()
                }
            } else {
                // 規約NG
                // 会員情報を削除
                removeMemberData()
                // ストア会員情報更新
                store.dispatch('setMemberId', '')
                store.dispatch('setFamilyName', '')
                store.dispatch('setGivenName', '')
                store.dispatch('setEmail', '')
                store.dispatch('setIsLoggedIn', false)
                store.dispatch('setIsProcessing', false)
                // 規約合意画面表示
                $('#btn-terms-open-modal').click()
            }
        }
        // ログアウト
        const logout = () => {
            console.log('↓↓↓HeaderBar.logout↓↓↓')
            // 認証トークンを削除
            localStorage.removeItem('access')
            // 会員情報を削除
            removeMemberData()
            // ストア会員情報更新
            store.dispatch('setMemberId', '')
            store.dispatch('setFamilyName', '')
            store.dispatch('setGivenName', '')
            store.dispatch('setEmail', '')
            store.dispatch('setIsLoggedIn', false)
            console.log('↑↑↑HeaderBar.logout↑↑↑')
        }

        const setStoreLoginStateFromMemberData = (): void => {
            const member_detail = getMemberData()
            store.dispatch('setMemberId', member_detail.member_id)
            store.dispatch('setFamilyName', member_detail.family_name)
            store.dispatch('setGivenName', member_detail.given_name)
            store.dispatch('setEmail', member_detail.email)
            store.dispatch('setIsLoggedIn', !isNull(member_detail.member_id))
        }
        // 入力チェック
        const chkValidation = (): boolean => {
            let err_count = 0
            // エラー表示初期化
            $('#error-text-id').removeClass('input__error')
            $('#login__form-container-id').removeClass('input__error')
            $('#error-text-pass').removeClass('input__error')
            $('#login__form-container-pass').removeClass('input__error')
            // 会員ID
            if (isNull(member_id.value)) {
                $('#error-text-id').addClass('input__error')
                $('#login__form-container-id').addClass('input__error')
                err_count++
            }
            // パスワード
            if (isNull(password.value)) {
                $('#error-text-pass').addClass('input__error')
                $('#login__form-container-pass').addClass('input__error')
                err_count++
            }

            return err_count == 0
        }

        // 規約確認状況取得
        const chkTermsPrivacy = async (): Promise<boolean> => {
            let isTermsPrivacy = true
            console.log('↓↓↓Login.chkTermsPrivacy↓↓↓')
            await apiClient
                .get(`${API_USERS}/member/terms_privacy`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    const member_detail = getMemberData()
                    terms_update_datetime.value = castStringToDate(data.terms_update_datetime)
                    policy_update_datetime.value = castStringToDate(data.policy_update_datetime)
                    // 規約更新日時
                    if (new Date(member_detail.terms_confirm_datetime) < terms_update_datetime.value) {
                        isTerms.value = false
                        isTermsPrivacy = false
                    }
                    // プライバシーポリシー更新日時
                    if (new Date(member_detail.privacy_policy_confirm_datetime) < policy_update_datetime.value) {
                        isPolicy.value = false
                        isTermsPrivacy = false
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Login.chkTermsPrivacy↑↑↑')
            return isTermsPrivacy
        }
        // 会員規約確認状況更新
        const updTerms = async () => {
            console.log('↓↓↓Login.updTermsPrivacy↓↓↓')
            await apiClient
                .patch(`${API_USERS}/member/terms`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    isTerms.value = true
                    // terms_update_datetime.value = new Date();
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    store.dispatch('setIsProcessing', false)
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Login.updTermsPrivacy↑↑↑')
        }
        // プライバシーポリシー確認状況更新
        const updPrivacy = async () => {
            console.log('↓↓↓Login.updTermsPrivacy↓↓↓')
            await apiClient
                .patch(`${API_USERS}/member/privacy_policy`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    isPolicy.value = true
                    // policy_update_datetime.value = new Date();
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    store.dispatch('setIsProcessing', false)
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑Login.updTermsPrivacy↑↑↑')
        }
        // 規約画面を閉じる
        const closeTerms = async () => {
            $('#btn-terms_close-modal').click()
            nextTick(async () => {
                if (isTerms.value && isPolicy.value) {
                    await setMemberDetail()
                    // ストアに反映
                    setStoreLoginStateFromMemberData()
                    // マイページへ移動
                    moveMyPage()
                } else {
                    // 規約未確認なのでログアウト
                    logout()
                }
            })
        }
        // 会員規約の表示
        const displayTerms = () => {
            updTerms()
            window.open('/member/membership_terms', '_blank')
        }
        // プライバシーポリシーの表示
        const displayPolicy = () => {
            updPrivacy()
            window.open('/member/privacy_policy', '_blank')
        }
        const isTermsStatus = computed(() => {
            return isTerms.value ? '確認済' : '未確認'
        })
        const isPolicyStatus = computed(() => {
            return isPolicy.value ? '確認済' : '未確認'
        })

        // 新規会員登録
        const createMember = () => {
            router.push('/member/provisional_member_registration')
        }

        // IDを忘れた方（再設定）
        const forgetMemberId = () => {
            router.push('/member/id_notification')
        }

        // パスワードを忘れた方（再設定）
        const forgetPass = () => {
            router.push('/member/password_resetting')
        }

        // マイページへ
        const moveMyPage = () => {
            router.push('/member/my_page')
        }

        const initialized = () => {
            store.dispatch('setIsProcessing', false)
            //password disp
            $(function () {
                $('.password button').click(function () {
                    if ($(this).prev('input').is('[type=text]')) {
                        $(this).prev('input').attr('type', 'password')
                        $(this).removeClass('disppass')
                    } else if ($(this).prev('input').is('[type=password]')) {
                        $(this).prev('input').attr('type', 'text')
                        $(this).addClass('disppass')
                    }
                })
            })
        }

        onMounted(() => {
            console.log('↓↓↓Login.onMounted↓↓↓')
            scrollTo(0, 0)
            nextTick(() => {
                // モーダル再読み込み
                try {
                    const remodalInst = $('.remodal').remodal()
                    remodalInst.reload()
                } catch (e) {
                    console.log(e)
                }
            })
            console.log('↑↑↑Login.onMounted↑↑↑')
        })

        return {
            member_id,
            password,
            isTerms,
            terms_update_datetime,
            isTermsStatus,
            isPolicy,
            policy_update_datetime,
            isPolicyStatus,

            login,
            closeTerms,
            displayTerms,
            displayPolicy,
            createMember,
            forgetMemberId,
            forgetPass,
            initialized,
            formatDate,
            formatDateTime,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'LoginView',
    data() {
        return {}
    },
    components: {
        ShipAnimationStart,
        ShipAnimationEnd,
    },
    methods: {},
    mounted: function () {
        scrollTo(0, 0)
        this.initialized()
    },
})
</script>

<style></style>
