<template>
    <!-- header -->
    <header id="header" class="header">
        <div class="header__container">
            <div class="header__info">
                <div class="header__logo">
                    <a href="/"><img src="@/images/items/header-logo.svg" alt="宮崎カーフェリーWEB予約" width="318" height="56" /></a>
                </div>
                <template v-if="mobileView">
                    <nav id="icon-navi" class="icon-navi">
                        <ul class="icon-navi__list">
                            <li
                                id="icon-navi__item-mypage"
                                class="icon-navi__item icon-navi__item-mypage"
                                v-for="item in USER_NAVI_ITEMS"
                                :key="item.index"
                            >
                                <a v-on:click="login" v-if="!is_logged">ログイン</a>
                                <a v-bind:href="item.url" v-bind:target="item.target" v-on:click="setShowMenu(false)" v-else>マイページ</a>
                            </li>
                            <li id="icon-navi__item-menu" class="icon-navi__item icon-navi__item-menu" v-on:click="setShowMenu(!showMenu)">
                                <a>メニュー</a>
                            </li>
                        </ul>
                    </nav>
                    <nav id="icon-menu" class="my-menu" v-show="mobileView && showMenu">
                        <div class="resarvation">
                            <div class="resarvation__header">現在の予約件数</div>
                            <div class="resarvation__container">
                                <span>{{ reservation_count }}</span
                                >件
                            </div>
                        </div>
                        <ul id="icon-menu__list" class="my-menu__list">
                            <li class="my-menu__item" v-for="item in MY_MENU_ITEMS" :key="item.index">
                                <a v-bind:href="item.url" v-bind:target="item.target" v-on:click="setShowMenu(false)">
                                    {{ item.name }}
                                </a>
                            </li>
                        </ul>
                        <div class="my-menu__logout">
                            <a v-on:click="logout">ログアウト</a>
                        </div>
                    </nav>
                </template>
            </div>
            <div class="header__navi">
                <nav id="global-navi" class="global-navi">
                    <ul class="global-navi__list">
                        <li class="global-navi__item" v-for="item in GLOBAL_NAVI_ITEMS" :key="item.index">
                            <a v-bind:href="item.url" v-bind:target="item.target">
                                {{ item.name }}
                            </a>
                        </li>
                    </ul>
                </nav>
                <nav id="user-navi" class="user-navi" v-if="is_logged">
                    <ul class="user-navi__list" v-show="!mobileView">
                        <li
                            id="user-navi__item-mypage"
                            class="user-navi__item user-navi__item-mypage"
                            v-for="item in USER_NAVI_ITEMS"
                            :key="item.index"
                        >
                            <a v-bind:href="item.url" v-bind:target="item.target" v-on:click="setShowMenu(false)">マイページ</a>
                        </li>
                        <li id="user-navi__item-name" class="user-navi__item user-navi__item-name" v-on:click="setShowMenu(!showMenu)">
                            <a>{{ full_name }}さん</a>
                        </li>
                    </ul>
                </nav>
                <nav class="user-navi" v-if="!is_logged && !mobileView">
                    <ul class="user-navi__list">
                        <li class="user-navi__item user-navi__item-name">
                            <a v-on:click="login">ログイン</a>
                        </li>
                    </ul>
                </nav>
                <nav id="my-menu" class="my-menu" v-show="!mobileView && showMenu">
                    <div class="resarvation">
                        <div class="resarvation__header">現在の予約件数</div>
                        <div class="resarvation__container">
                            <span>{{ reservation_count }}</span
                            >件
                        </div>
                    </div>
                    <ul id="my-menu__list" class="my-menu__list">
                        <li class="my-menu__item" v-for="item in MY_MENU_ITEMS" :key="item.index">
                            <a v-bind:href="item.url" v-bind:target="item.target" v-on:click="setShowMenu(false)">
                                {{ item.name }}
                            </a>
                        </li>
                    </ul>
                    <div class="my-menu__logout">
                        <a v-on:click="logout">ログアウト</a>
                    </div>
                </nav>
            </div>
        </div>
    </header>
    <!-- /header -->
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { API_USERS, API_ORDER, setCompanionMaster, setVehicleMaster, joinFirstNameAndLastName, getId } from '@/lib/util'
import { removeMemberData } from '@/lib/localStorageUtil'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { useStore } from '@/store'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()

        const mobileView = ref(false)
        const showMenu = ref(false)

        const is_logged = computed(() => {
            return store.state.is_logged_in
        })
        const full_name = computed(() => {
            if (store.state.is_logged_in) {
                return joinFirstNameAndLastName(store.state.family_name, store.state.given_name)
            } else {
                return ''
            }
        })
        const reservation_count = ref(0)

        const GLOBAL_NAVI_ITEMS = ref([
            {
                index: 0,
                url: 'https://www.miyazakicarferry.com/',
                target: '_blank',
                name: '公式サイトへ',
            },
            {
                index: 1,
                url: 'https://www.miyazakicarferry.com/booking/precautions/',
                target: '_blank',
                name: '予約時の諸注意',
            },
        ])

        const USER_NAVI_ITEMS = ref([
            {
                index: 0,
                url: '/member/my_page',
                target: '_self',
                name: 'マイページ',
            },
        ])

        const MY_MENU_ITEMS = ref([
            {
                index: 0,
                url: '/member/my_page',
                target: '_self',
                name: 'マイページトップへ',
            },
            {
                index: 1,
                url: '/booking/current',
                target: '_self',
                name: '現在の予約',
            },
            {
                index: 2,
                url: '/booking/history',
                target: '_self',
                name: '過去の予約',
            },
            {
                index: 3,
                url: 'https://www.miyazakicarferry.com/faq/',
                target: '_blank',
                name: 'よくあるご質問',
            },
            {
                index: 4,
                url: 'https://www.miyazakicarferry.com/contact/',
                target: '_blank',
                name: 'お問い合わせ',
            },
        ])

        const logout = () => {
            console.log('↓↓↓HeaderBar.logout↓↓↓')
            // 認証トークンを削除
            localStorage.removeItem('access')
            // 会員情報を削除
            removeMemberData()
            // ストア会員情報更新
            store.dispatch('setMemberId', '')
            store.dispatch('setFamilyName', '')
            store.dispatch('setGivenName', '')
            store.dispatch('setEmail', '')
            store.dispatch('setIsLoggedIn', false)
            // ログイン画面へ遷移
            router.push('/member/login')
            console.log('↑↑↑HeaderBar.logout↑↑↑')
        }
        const login = () => {
            router.push('/member/login')
        }
        const checkLogged = async () => {
            console.log('↓↓↓HeaderBar.checkLogged↓↓↓')
            if (is_logged.value) {
                reservation_count.value = await getReservationCount()
            } else {
                reservation_count.value = 0
            }
            console.log('↑↑↑HeaderBar.checkLogged↑↑↑')
        }
        const getReservationCount = async (): Promise<number> => {
            console.log('↓↓↓HeaderBar.getReservationCount↓↓↓')
            let reservation_count = 0
            await apiClient
                .get(`${API_ORDER}/history/current`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    reservation_count = data.length
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })

            console.log('↑↑↑HeaderBar.getReservationCount↑↑↑')
            return reservation_count
        }

        const setShowMenu = (show: boolean) => {
            showMenu.value = show
        }

        const calculateWindowWidth = () => {
            mobileView.value = window.innerWidth < 768
        }

        // watch
        watch(
            () => store.getters.is_logged_in,
            function () {
                if (store.state.is_logged_in) {
                    setCompanionMaster(getId())
                    setVehicleMaster(getId())
                }
            },
        )

        onMounted(() => {
            checkLogged()
            calculateWindowWidth()
            window.addEventListener('resize', calculateWindowWidth)
        })

        return {
            mobileView,
            showMenu,
            is_logged,
            full_name,
            reservation_count,

            GLOBAL_NAVI_ITEMS,
            USER_NAVI_ITEMS,
            MY_MENU_ITEMS,

            logout,
            login,
            checkLogged,
            setShowMenu,
            calculateWindowWidth,
        }
    },
    name: 'HeaderBar',
    data() {
        return {}
    },
    components: {},
    methods: {},
})
</script>
<style scoped>
a {
    cursor: pointer;
}
</style>
