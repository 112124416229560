<template>
    <tr>
        <th>ペットルーム</th>
        <td id="search__item-pet">
            <span>{{ dispCages() }}</span>
            <button class="btn-border" type="button" v-on:click="toggleInputCage()">変更</button>
            <div :id="'search__set-pet-' + list_idx" class="search__set search__set-pet none" style="max-width: 40rem">
                <ul class="input-set__list">
                    <li id="input-set__item-bigroom" class="input-set__item input-set__item-bigroom">
                        <label id="input-set__label-bigroom" class="input-set__label input-set__label-bigroom" for="input-set__number-bigroom"
                            >大型</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementCage(CAGE_TYPES_LARGE)">－</button>
                            <input
                                id="input-set__number-bigroom"
                                class="input-set__number input-set__number-bigroom"
                                type="text"
                                name="bigroom"
                                v-model="cage_large_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementCage(CAGE_TYPES_LARGE)">＋</button>
                        </div>
                    </li>
                    <li id="input-set__item-smallroom" class="input-set__item input-set__item-smallroom">
                        <label id="input-set__label-smallroom" class="input-set__label input-set__label-smallroom" for="input-set__number-smallroom"
                            >中小型</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementCage(CAGE_TYPES_SMALL)">－</button>
                            <input
                                id="input-set__number-smallroom"
                                class="input-set__number input-set__number-smallroom"
                                type="text"
                                name="smallroom"
                                v-model="cage_small_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementCage(CAGE_TYPES_SMALL)">＋</button>
                        </div>
                    </li>
                </ul>
                <div class="input-set__buttons">
                    <button id="input-set__cansel-pet" class="btn input-set__cansel input-set__cansel-pet" type="button" v-on:click="cancelCage()">
                        キャンセル
                    </button>
                    <button id="input-set__setting-pet" class="btn input-set__setting input-set__setting-pet" type="button" v-on:click="commitCage()">
                        設定
                    </button>
                </div>
            </div>
        </td>
        <td class="detail__td-count">船内決済</td>
    </tr>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, ref } from 'vue'
import $ from 'jquery'
import { isNull } from '@/lib/util'
import { CAGE_TYPES_LARGE, CAGE_TYPES_SMALL } from '@/lib/constants'

export default defineComponent({
    setup(props, context) {
        const cage_small_num_tmp = ref(0)
        const cage_large_num_tmp = ref(0)

        // 初期化処理
        const init = async () => {
            console.log('↓↓↓CageList.init↓↓↓')
            console.log('↑↑↑CageList.init↑↑↑')
        }
        // 表示ペットルーム数
        const dispCages = (): string => {
            let values = []

            if (props.cage_large_num! > 0) {
                values.push(`大型：${props.cage_large_num}部屋`)
            }
            if (props.cage_small_num! > 0) {
                values.push(`中小型：${props.cage_small_num}部屋`)
            }

            return values.join(' ')
        }

        // ペットルーム数変更
        const incrementCage = (cage_type: number) => {
            if (cage_type == CAGE_TYPES_SMALL) {
                cage_small_num_tmp.value += 1
            } else if (cage_type == CAGE_TYPES_LARGE) {
                cage_large_num_tmp.value += 1
            }
        }
        const decrementCage = (cage_type: number) => {
            if (cage_type == CAGE_TYPES_SMALL) {
                cage_small_num_tmp.value -= 1
                if (cage_small_num_tmp.value < 0) {
                    cage_small_num_tmp.value = 0
                }
            } else if (cage_type == CAGE_TYPES_LARGE) {
                cage_large_num_tmp.value -= 1
                if (cage_large_num_tmp.value < 0) {
                    cage_large_num_tmp.value = 0
                }
            }
        }
        const cancelCage = () => {
            cage_small_num_tmp.value = props.cage_small_num!
            cage_large_num_tmp.value = props.cage_large_num!
            $('div[id^="search__set-pet-"]').hide()
        }
        const toggleInputCage = () => {
            console.log('↓↓↓CageList.toggleInputCar↓↓↓')
            cage_large_num_tmp.value = props.cage_large_num!
            cage_small_num_tmp.value = props.cage_small_num!
            $('#search__set-pet-' + props.list_idx).toggle()
            console.log('↑↑↑CageList.toggleInputCar↑↑↑')
        }
        const commitCage = async () => {
            // 乗船者人数確定時処理
            $('div[id^="search__set-pet-"]').hide()
            context.emit('commitCage', cage_large_num_tmp.value, cage_small_num_tmp.value)
        }

        return {
            cage_large_num_tmp,
            cage_small_num_tmp,

            init,
            dispCages,

            toggleInputCage,
            incrementCage,
            decrementCage,
            cancelCage,
            commitCage,
        }
    },
    emits: ['commitCage'],
    name: 'CageList',
    data() {
        return {
            CAGE_TYPES_SMALL: CAGE_TYPES_SMALL as number,
            CAGE_TYPES_LARGE: CAGE_TYPES_LARGE as number,
        }
    },

    components: {},
    props: {
        list_idx: Number,
        dep_date: String,
        sea_route_code: String,
        contract_number: String,
        grade: Number,
        seat_type: Number,
        cage_large_num: Number,
        cage_small_num: Number,
    },
    methods: {},
    mounted: function () {
        console.log('↓↓↓CageList.mounted↓↓↓')
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search__item-pet').length) {
                $('div[id^="search__set-pet-"]').hide()
            }
        })
        console.log('↑↑↑CageList.mounted↑↑↑')
    },
})
</script>

<style scoped></style>
