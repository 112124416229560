<template>
    <section class="sp-back">
        <p>恐れ入りますが、領収書は画面幅600px以上のPCの画面等でご確認ください。</p>
        <p>※スマートフォンの場合、画面を横向きにすると正しく表示される場合があります。</p>
        <button class="btn btn-print-back" onclick="window.close();">閉じる</button>
    </section>
    <section class="reciept">
        <div class="reciept__content">
            <div id="" class="reciept__data">
                <div id="" class="reciept__data-pack">
                    <div id="" class="reciept__data-note">
                        <div class="reciept__header">
                            <p id="" class="reciept__header-logo">
                                <img class="" src="@/images/items/reciept-logo.jpg" width="" height="" />
                            </p>
                            <div>
                                <p id="" class="reciept__header-number">No.web-{{ ('00000000' + receipt_no).slice(-8) }}</p>
                                <p id="" class="reciept__header-date">表示日：{{ formatDate(date_of_issue, false, 2, 0) }}</p>
                            </div>
                        </div>
                    </div>
                    <div id="" class="reciept__content">
                        <h1 id="" class="reciept__content-title" v-show="!receipt_print_flg">領収書</h1>
                        <h1 id="" class="reciept__content-title" v-show="receipt_print_flg">領収書（再発行）</h1>
                    </div>
                    <div id="" class="reciept__data-note">
                        <p id="" class="reciept__data-departure">{{ destination }}</p>
                    </div>
                    <div id="" class="reciept__data-note reciept__data-note-thin">
                        <p id="" class="reciept__content-note">下記金額正に領収いたしました</p>
                    </div>
                    <div id="" class="reciept__data-note reciept__data-note-fee">
                        <p id="" class="reciept__data-headline">金額</p>
                        <p id="" class="reciept__data-fee">¥{{ paid_total.toLocaleString() }}-</p>
                        <p id="" class="reciept__data-fee-tax">&emsp;(税込み)</p>
                    </div>
                    <div id="" class="reciept__data-tax reciept__data-note-thin">
                        <p id="" class="reciept__data-tax">
                            消費税{{ tax_rate }}％対象&nbsp;¥{{ paid_total.toLocaleString() }}-&emsp;消費税&nbsp;¥{{ tax_amount.toLocaleString() }}-
                        </p>
                    </div>
                    <div id="" class="reciept__data-note reciept__data-note-thin">
                        <p id="" class="reciept__data-headline"></p>
                        <!-- <p id="" class="reciept__data-tax">&emsp;消費税8％対象&emsp;¥999,999-(税込み)</p> -->
                    </div>
                    <div id="" class="reciept__data-note reciept__data-note-thin">
                        <p id="" class="reciept__data-headline">但し</p>
                        <p id="" class="reciept__data-for">{{ proviso }}</p>
                    </div>
                    <div id="" class="reciept__data-note reciept__data-note-thin">
                        <p id="" class="reciept__data-headline">乗船番号</p>
                        <p id="" class="reciept__data-boarding_no">{{ boarding_no }}</p>
                    </div>
                    <div id="" class="reciept__data-note reciept__data-note-thin">
                        <p id="" class="reciept__data-headline">乗船日</p>
                        <p id="" class="reciept__data-dep_date">{{ formatDate(dep_date, false, 2, 1) }}{{ sea_route_name }}</p>
                    </div>
                    <div id="" class="reciept__data-note reciept__data-note-thin">
                        <p id="" class="reciept__data-headline">備考</p>
                        <p id="" class="reciept__data-biko">現金・クレジットカード・その他のお支払い分を含みます</p>
                    </div>
                    <div id="" class="reciept__data-note reciept__data-note-thin">
                        <p id="" class="reciept__data-headline">&emsp;</p>
                    </div>
                    <p id="" class="reciept__data-text">本紙は電子的に保持している領収データを画面表示したものです</p>
                    <div class="reciept__footer">
                        <!-- <div class="reciept__footer-logo"><img class="" src="@/images/items/logo-long.png" width="" height=""></div> -->
                        <p class="reciept__footer-invoice">登録番号&emsp;{{ registration_number }}</p>
                        <p class="reciept__footer-text">宮崎カーフェリー株式会社</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <p class="print-size">※A5横サイズの場合</p>
    <section class="print-control">
        <div class="print-control-btn-pack">
            <button class="btn btn-print" onclick="window.print();">印刷</button>
            <button class="btn btn-print-back" onclick="window.close();">閉じる</button>
        </div>
        <p class="print-control-note">PDFで保存する場合は、印刷の標準機能より送信先から「Microsoft Print to PDF」を選択し印刷してください。</p>
    </section>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onMounted, ref } from 'vue'
import { getReceiptData } from '@/lib/localStorageUtil'
import { formatDate, castStringToDate } from '@/lib/util'

export default defineComponent({
    emits: ['showPrintReceipt'],
    name: 'ShowReceipt',
    setup(props, context) {
        const receipt_no = ref()
        const boarding_no = ref()
        const date_of_issue = ref()
        const destination = ref()
        const dep_date = ref()
        const sea_route_name = ref()
        const paid_total = ref(0)
        const proviso = ref('')
        const receipt_print_flg = ref(false)
        const registration_number = ref()
        const tax_rate = ref()
        const tax_amount = ref(0)

        onMounted(async () => {
            console.log('↓↓↓ShowReceipt.onMounted↓↓↓')
            context.emit('showPrintReceipt')
            let receipt_date = getReceiptData()
            console.log(receipt_date)

            receipt_no.value = receipt_date.receipt_no
            boarding_no.value = receipt_date.boarding_no
            date_of_issue.value = castStringToDate(receipt_date.date_of_issue)
            destination.value = receipt_date.destination
            dep_date.value = castStringToDate(receipt_date.dep_date)
            sea_route_name.value = receipt_date.sea_route_name
            paid_total.value = receipt_date.paid_total
            proviso.value = receipt_date.proviso
            receipt_print_flg.value = receipt_date.receipt_print_flg
            registration_number.value = receipt_date.registration_number
            tax_rate.value = receipt_date.tax_rate
            tax_amount.value = receipt_date.tax_amount
            console.log('↑↑↑ShowReceipt.onMounted↑↑↑')
        })

        return {
            receipt_no,
            boarding_no,
            date_of_issue,
            destination,
            dep_date,
            sea_route_name,
            paid_total,
            proviso,
            receipt_print_flg,
            registration_number,
            tax_rate,
            tax_amount,

            formatDate,
        }
    },
    components: {},
    data() {
        return {}
    },
    methods: {},
})
</script>
<style></style>
