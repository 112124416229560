<template>
    <main id="main" class="main">
        <!-- form -->
        <section class="form form-top">
            <div class="form__header">
                <h2 class="form__name">パスワードの再設定</h2>
            </div>
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <li id="form__item" class="form__item">
                        <div class="form__item-container">
                            <div class="input">
                                <dl class="input__list">
                                    <!-- line -->
                                    <div class="input__line input__line-pass">
                                        <div id="input__item-pass" class="input__item-pass-pack">
                                            <!-- pass -->
                                            <div id="input__item-pass" class="input__item input__item-pass">
                                                <dt id="input__header-pass" class="input__header input__header-pass">
                                                    <label id="input__name-pass" class="input__name input__name-pass" for="input__pass"
                                                        >新しいパスワード</label
                                                    >
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd id="input__container-pass" class="input__container input__container-pass password">
                                                    <input
                                                        id="input__pass"
                                                        class="input__form input__pass"
                                                        type="password"
                                                        name="input__pass"
                                                        v-model="password"
                                                    />
                                                    <button class="btn-disppass"></button>
                                                </dd>
                                                <p class="input__error-text">
                                                    {{ password_new_error_msg }}
                                                </p>
                                            </div>
                                            <!-- pass-confirm -->
                                            <div id="input__item-pass-confirm" class="input__item input__item-pass-confirm">
                                                <dt id="input__header-pass-confirm" class="input__header input__header-pass-confirm">
                                                    <label
                                                        id="input__name-pass-confirm"
                                                        class="input__name input__name-pass-confirm"
                                                        for="input__pass-confirm"
                                                        >新しいパスワード（確認用）</label
                                                    >
                                                    <span class="input__required">必須</span>
                                                </dt>
                                                <dd
                                                    id="input__container-pass-confirm"
                                                    class="input__container input__container-pass-confirm password"
                                                >
                                                    <input
                                                        id="input__pass-confirm"
                                                        class="input__form input__pass-confirm"
                                                        type="password"
                                                        name="input__pass-confirm"
                                                        v-model="password_confirm"
                                                    />
                                                    <button class="btn-disppass"></button>
                                                </dd>
                                                <p class="input__error-text">
                                                    {{ password_conf_error }}
                                                </p>
                                            </div>
                                        </div>
                                        <p class="input__text">
                                            パスワードは利用者IDを含まない8文字以上20文字以内の英大文字、英小文字、数字の組み合わせで入力してください。<br />
                                            例）Miyazaki1020<br />
                                            使用可能な記号&emsp;! @ # $ % ^ &amp; * ( ) _ + - = { } [ ] : &lt; &gt; , . ? / ; ' &quot; |
                                        </p>
                                    </div>
                                </dl>
                                <!-- <img src="@/images/items/recapchadummy.png" style="margin: 2rem auto 1rem;"> -->
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-next" type="button" v-on:click="changePass">パスワードを変更する</button>
            </div>
        </section>
        <!-- /form -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import $ from 'jquery'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { API_USERS, API_ORDER, isNull } from '@/lib/util'
import { isPassword, isPasswordLonger, isPasswordShorter, isPasswordCombination } from '@/lib/validation_util'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        const recaptchaInstance = useReCaptcha()

        const user_key = ref('')
        const password = ref('')
        const password_confirm = ref('')
        const password_new_error_msg = ref('')
        const password_conf_error = ref('')

        // 入力チェック
        const chkValidation = (): boolean => {
            let err_count = 0
            // エラー表示初期化
            $('#input__item-pass').removeClass('input__error')
            $('#input__item-pass-confirm').removeClass('input__error')
            password_new_error_msg.value = ''
            password_conf_error.value = ''

            // 新しいパスワード
            if (isNull(password.value) || !isPassword(password.value)) {
                $('#input__item-pass').addClass('input__error')
                err_count++
                if (isPasswordLonger(password.value)) {
                    password_new_error_msg.value = '新しいパスワードは20文字以内で入力してください。'
                } else if (isPasswordShorter(password.value)) {
                    password_new_error_msg.value = '新しいパスワードは8文字以上で入力してください。'
                } else if (isPasswordCombination(password.value)) {
                    password_new_error_msg.value = '新しいパスワードは英大文字、英小文字、数字の組み合わせで入力してください。'
                }
            }
            // 新しいパスワード（確認用）
            if (isNull(password_confirm.value)) {
                $('#input__item-pass-confirm').addClass('input__error')
                password_conf_error.value = '新しいパスワード（確認用）を入力してください'
                err_count++
            } else if (password.value != password_confirm.value) {
                $('#input__item-pass-confirm').addClass('input__error')
                password_conf_error.value = '新しいパスワードと確認用のパスワードは同じものを入力してください'
                err_count++
            }

            return err_count == 0
        }
        // パスワード変更
        const changePass = async () => {
            if (chkValidation()) {
                await recaptchaInstance?.recaptchaLoaded()
                const token = await recaptchaInstance?.executeRecaptcha('login')

                const postData = {
                    user_key: user_key.value,
                    password: password.value,
                    token: token,
                }

                store.dispatch('setIsProcessing', true)
                await apiClient
                    .patch(`${API_USERS}/login/password/resetting`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        store.dispatch('setIsProcessing', false)
                        context.emit('displayMessage', {
                            msg_title: 'パスワード再設定',
                            msg: ['パスワードの再設定が完了しました。'],
                            ok_btn: true,
                        })
                        // ログイン画面を表示
                        router.push('/member/login')
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        store.dispatch('setIsProcessing', false)
                        // エラー処理
                        console.log(e.message)
                        context.emit('displayMessage', {
                            msg_title: 'パスワード変更失敗',
                            msg: ['パスワードポリシーに従ったパスワードを入力してください。'],
                            ok_btn: true,
                        })
                    })
            }
        }
        // パスワード変更URL有効期限チェック
        const chkChangePass = () => {
            const postData = {
                user_key: user_key.value,
            }

            apiClient.post(`${API_USERS}/login/password/resetting/check`, postData).catch((e: AxiosError<{ error: string }>) => {
                // エラー処理
                console.log(e.message)
                context.emit('displayMessage', {
                    msg_title: 'パスワード変更',
                    msg: ['パスワード変更URLの有効期間が過ぎました。最初からやり直してください。'],
                    ok_btn: true,
                })
                router.push('/member/login')
            })
        }

        onMounted(() => {
            console.log('↓↓↓MemberRegistration.onMounted↓↓↓')
            const route = useRoute()
            user_key.value = route.params.user_key as string
            if (isNull(user_key.value)) {
                // メール外からの表示は不可
                router.push('/member/login')
            }
            chkChangePass()

            //password disp
            $(function () {
                $('.password button').click(function () {
                    if ($(this).prev('input').is('[type=text]')) {
                        $(this).prev('input').attr('type', 'password')
                        $(this).removeClass('disppass')
                    } else if ($(this).prev('input').is('[type=password]')) {
                        $(this).prev('input').attr('type', 'text')
                        $(this).addClass('disppass')
                    }
                })
            })
            console.log('↑↑↑MemberRegistration.onMounted↑↑↑')
        })

        return {
            password,
            password_confirm,
            password_new_error_msg,
            password_conf_error,

            changePass,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'ResetPassword',
    data() {
        return {}
    },
    components: {
        ShipAnimationEnd,
    },
    methods: {},
})
</script>
<style scoped></style>
