<!-- eslint-disable no-irregular-whitespace -->
<template>
    <section class="result">
        <div class="result__container" v-show="is_show">
            <div class="result__information">
                <div class="result__count" v-show="!is_searching">
                    客室タイプ<span>{{ roomTypeCount }}</span
                    >件
                </div>
                <div class="result__count" v-show="is_searching">部屋・プランを検索中・・・</div>
                <ul class="result__sort-list">
                    <li class="result__sort-item" v-on:click="sortList(0)">おすすめ順</li>
                    <li class="result__sort-item" v-on:click="sortList(1)">料金の安い順</li>
                </ul>
            </div>
            <ul id="result__list" class="result__list">
                <li v-for="(plan, idx) in planDataSorted" :key="idx" class="result__item">
                    <div class="result__item-container" v-if="!isNull(plan) && !isNull(plan.seat_type)">
                        <div class="result__item-header" v-if="!isNull(plan) && !isNull(plan.seat_type)">
                            <div class="result__name">
                                <a class="" v-on:click="openRoomDetails(plan.seat_type)">{{ get_seat_type_name(plan.seat_type) }}</a>
                            </div>
                        </div>
                        <div class="result__img">
                            <a class="" v-on:click="openRoomDetails(plan.seat_type)">
                                <img
                                    src="@/images/rooms/10/room10_01.jpg"
                                    fetchpriority="high"
                                    alt="プレミアムツイン画像01"
                                    width="266"
                                    height="200"
                                    v-if="plan.seat_type == 10"
                                />
                                <img
                                    src="@/images/rooms/10/room11_01.jpg"
                                    fetchpriority="high"
                                    alt="プレミアムトリプル画像01"
                                    width="266"
                                    height="200"
                                    v-if="plan.seat_type == 11"
                                />
                                <img
                                    src="@/images/rooms/10/room12_01.jpg"
                                    fetchpriority="high"
                                    alt="プレミアムバリアフリー画像01"
                                    width="266"
                                    height="200"
                                    v-if="plan.seat_type == 12"
                                />
                                <img
                                    src="@/images/rooms/10/room13_01.jpg"
                                    fetchpriority="high"
                                    alt="ファーストツイン画像01"
                                    width="266"
                                    height="200"
                                    v-if="plan.seat_type == 13"
                                />
                                <img
                                    src="@/images/rooms/10/room14_01.jpg"
                                    fetchpriority="high"
                                    alt="ファーストフォース画像01"
                                    width="266"
                                    height="200"
                                    v-if="plan.seat_type == 14"
                                />
                                <img
                                    src="@/images/rooms/10/room15_01.jpg"
                                    fetchpriority="high"
                                    alt="ファーストwithペットツイン画像01"
                                    width="266"
                                    height="200"
                                    v-if="plan.seat_type == 15"
                                />
                                <img
                                    src="@/images/rooms/10/room15_02.jpg"
                                    fetchpriority="high"
                                    alt="ファーストwithペットフォース画像01"
                                    width="266"
                                    height="200"
                                    v-if="plan.seat_type == 16"
                                />
                                <img
                                    src="@/images/rooms/10/room17_01.jpg"
                                    fetchpriority="high"
                                    alt="シングル画像01"
                                    width="266"
                                    height="200"
                                    v-if="plan.seat_type == 17"
                                />
                                <!-- <img src="@/images/rooms/10/room18_01.jpg" fetchpriority="high" alt="ドライバーシングル画像01" width="266" height="200" v-if="plan.seat_type==18"> -->
                                <img
                                    src="@/images/rooms/10/room19_01.jpg"
                                    fetchpriority="high"
                                    alt="ドミトリー画像01"
                                    width="266"
                                    height="200"
                                    v-if="plan.seat_type == 19"
                                />
                                <img
                                    src="@/images/rooms/10/room20_01.jpg"
                                    fetchpriority="high"
                                    alt="ツーリスト画像01"
                                    width="266"
                                    height="200"
                                    v-if="plan.seat_type == 20"
                                />
                            </a>
                        </div>
                        <table class="result__table">
                            <tbody>
                                <tr class="result__table-list">
                                    <th class="result__table-name result__table-name-plan">プラン</th>
                                    <th class="result__table-name result__table-name-remainig">
                                        <span v-show="!isNull(dep_date) || !isNull(plan.dep_date)">残り{{ display_unit(plan.seat_type) }}数</span>
                                    </th>
                                    <th class="result__table-name result__table-name-fee" colspan="3">運賃合計</th>
                                </tr>
                                <tr class="result__table-list" v-for="contract in plan.contracts" :key="contract.contract_number">
                                    <td class="result__table-data result__table-data-plan">
                                        <a>{{ contract.contract_name }}</a>
                                    </td>
                                    <td class="result__table-data result__table-data-remaining" v-if="!isNull(dep_date) || !isNull(plan.dep_date)">
                                        残り {{ contract.empty_count }}
                                        {{ display_unit(plan.seat_type) }}
                                    </td>
                                    <td class="result__table-data result__table-data-remaining" v-else></td>
                                    <td class="result__table-data result__table-data-fee">{{ contract.total_amount?.toLocaleString() }}円</td>
                                    <td class="result__table-data result__table-data-btn-half result__table-data-btn-half-left">
                                        <div class="result__calendar" id="plan_list_calendar">
                                            <EmptyCalendar
                                                v-model="plan.dep_date"
                                                mode="1"
                                                :sea_route_code="sea_route_code"
                                                :seat_type="plan.seat_type"
                                                :contract_number="contract.contract_number"
                                                :cabin_seat_count="useMinRoomSeatCount(plan.seat_type, passenger_adult_num!, passenger_child_num!, passenger_infant_num!, passenger_baby_num!, 0)"
                                                :car_count="car_3m_num! + car_4m_num! + car_5m_num! + car_6m_num!"
                                                :bicycle_count="car_bicycle_num! + car_two_wheel_small_num! + car_two_wheel_medium_num! + car_two_wheel_large_num!"
                                                @selectDate="selectPlanCalendarDepDate(plan, plan.seat_type, plan.dep_date, contract)"
                                            />
                                        </div>
                                    </td>
                                    <td class="result__table-data result__table-data-btn-half result__table-data-btn-half-right">
                                        <button
                                            v-show="isEmpty(contract.empty_count, plan.seat_type) || isNull(plan.dep_date)"
                                            class="btn btn-result"
                                            type="button"
                                            v-on:click="openPlanDetail(contract, plan.seat_type, plan.dep_date)"
                                        >
                                            詳細・予約
                                        </button>
                                        <button
                                            v-show="!isEmpty(contract.empty_count, plan.seat_type) && !isNull(plan.dep_date)"
                                            class="btn btn-full"
                                            type="button"
                                        >
                                            満{{ display_unit2(plan.seat_type) }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent, ref, Ref, computed, watch, nextTick } from 'vue'
import { useStore } from '@/store'
import $ from 'jquery'
import {
    API_USERS,
    API_ORDER,
    isNull,
    formatDate,
    nextMonth,
    lastMonthDate,
    useMinRoomSeatCount,
    getSeatTypeName,
    ROOM_INFORMATIONS,
    calcContractPrice,
    castStringToDate,
    calcDiffDays,
    SHIP_CODE_ROKKO,
    getMakeDateRange,
    getShipSchedule,
    getContract,
    useMaxRoomPassengerCount,
} from '@/lib/util'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { CarDetail } from '@/lib/types'
import {
    GRADE_P,
    GRADE_F,
    GRADE_S,
    GRADE_DS,
    GRADE_D,
    GRADE_T,
    SEAT_TYPE_P_TW,
    SEAT_TYPE_P_TR,
    SEAT_TYPE_P_BA,
    SEAT_TYPE_F_TW,
    SEAT_TYPE_F_FO,
    SEAT_TYPE_FWP_TW,
    SEAT_TYPE_FWP_FO,
    SEAT_TYPE_S,
    SEAT_TYPE_DS,
    SEAT_TYPE_D,
    SEAT_TYPE_T,
    DEP_STATUS_NORMAL,
    DEP_STATUS_CANCEL_WEATHER,
    DEP_STATUS_CANCEL_ETC,
    DEP_STATUS_DOCK,
    VEHICLE_RESTRICT_TWO_AXLE_CAR,
} from '@/lib/constants'
import EmptyCalendar from '@/components/common/EmptyCalendar.vue'

interface ContractFare {
    contract_name: string
    contract_number: string
    grade: number
    seat_type: number
    divide_type_id: number
    total_amount: number | null
    empty_count: number
}

export default defineComponent({
    setup(props, context) {
        const store = useStore()
        //【注意】javascriptの月と表示の月に1カ月のズレあり
        const disabledDates = ref({
            to: new Date(), // 本日以前の日付は選択不可
            from: nextMonth(new Date(), 12) as Date | null, // nextMonth(new Date(), 2), // 2ヶ月先以降の日付が選択不可
            dates: [] as Date[],
        })

        const calendar_cancel_weather_date = ref<Date[]>([])
        const calendar_cancel_etc_date = ref<Date[]>([])
        const calendar_dock_date = ref<Date[]>([])
        const calendar_fully_occupied_date = ref<Date[]>([])
        const no_schedule_date_arr = ref<Date[]>([])
        const car_detail_array = ref<CarDetail[]>()
        // const is_show = ref(false);
        const is_searching = ref(true)
        const sort_param = ref<number[]>([0, 1])
        const current_year_month = ref('')

        type Plan = {
            dep_date: Date
            grade: number
            seat_type: number
            contracts: ContractFare[]
        }
        const planData = ref<Plan[]>()
        const planDataSorted = ref<Plan[]>()

        const before_disp_calender_seat_type = ref<number>(0)
        const before_disp_calender_contract_number = ref<string>('')

        // 表示時処理
        const openPlanList = (data: any, CarDetailArray: CarDetail[]) => {
            console.log('↓↓↓PlanList.openPlanList↓↓↓')
            // is_show.value = false;
            is_searching.value = true
            car_detail_array.value = CarDetailArray
            planData.value = []
            planDataSorted.value = []
            if (!isNull(data)) {
                let keysArr = Object.keys(data)
                for (let i = 0; i < keysArr.length; i++) {
                    planData.value[Number(keysArr[i])] = {
                        dep_date: props.dep_date!,
                        grade: data[keysArr[i]][0].grade,
                        seat_type: Number(keysArr[i]),
                        contracts: [],
                    }
                    for (let j = 0; j < data[keysArr[i]].length; j++) {
                        let total_amount = data[keysArr[i]][j].total_amount
                        if ([SEAT_TYPE_FWP_TW, SEAT_TYPE_FWP_FO].includes(Number(keysArr[i]))) {
                            total_amount += data[keysArr[i]][j].with_pets_amount
                        }
                        let tmpContract: ContractFare = {
                            contract_number: data[keysArr[i]][j].contract_number,
                            contract_name: data[keysArr[i]][j].contract_name,
                            grade: data[keysArr[i]][j].grade,
                            seat_type: Number(keysArr[i]),
                            divide_type_id: data[keysArr[i]][j].divide_type_id,
                            total_amount: total_amount,
                            empty_count: isNull(data[keysArr[i]][j].empty_cabin) ? 0 : data[keysArr[i]][j].empty_cabin,
                        }
                        planData.value[Number(keysArr[i])].contracts.push(tmpContract)
                    }
                }

                let tmpPlanDataArray = []
                for (var j = 0; j < planData.value.length; j++) {
                    if (planData.value[j]) {
                        tmpPlanDataArray.push(planData.value[j])
                    }
                }
                planData.value = tmpPlanDataArray
            }
            // is_show.value = true;
            if (planData.value.length == 0) {
                context.emit('displayMessage', {
                    msg_title: '部屋・プラン検索',
                    msg: ['ご予約可能なプランはございません'],
                    close_btn: true,
                })
            }
            nextTick(async () => {
                // await setLowSeatEmptyCount();
                // sortList(sort_param.value[0]);
                setLowSeatEmptyCount()
                planDataSorted.value = planData.value!
                is_searching.value = false
            })
            console.log('↑↑↑PlanList.openPlanList↑↑↑')
        }
        const setPlanListContracts = async (data: any, CarDetailArray: CarDetail[]) => {
            console.log('↓↓↓PlanList.setPlanListContracts↓↓↓')
            // is_show.value = false;
            car_detail_array.value = CarDetailArray
            let tmpPlanData = [] as Plan[]
            // planDataSorted.value = [];
            if (!isNull(data)) {
                for (let i = 0; i < data.contract.length; i++) {
                    // 料金が取れないプランは表示しない
                    if (isNull(data.contract[i].total_amount)) {
                        continue
                    }
                    let tmpGrade: number = data.contract[i].grade
                    let seat_type_arr: number[] = []
                    if (tmpGrade == GRADE_P) {
                        seat_type_arr = [SEAT_TYPE_P_TW, SEAT_TYPE_P_TR, SEAT_TYPE_P_BA]
                    } else if (tmpGrade == GRADE_F) {
                        seat_type_arr = [SEAT_TYPE_F_TW, SEAT_TYPE_F_FO, SEAT_TYPE_FWP_TW, SEAT_TYPE_FWP_FO]
                    } else if (tmpGrade == GRADE_S) {
                        seat_type_arr = [SEAT_TYPE_S]
                    } else if (tmpGrade == GRADE_DS) {
                        seat_type_arr = [SEAT_TYPE_DS]
                    } else if (tmpGrade == GRADE_D) {
                        seat_type_arr = [SEAT_TYPE_D]
                    } else if (tmpGrade == GRADE_T) {
                        seat_type_arr = [SEAT_TYPE_T]
                    }
                    for (let j = 0; j < seat_type_arr.length; j++) {
                        let tmpSeatType: number = seat_type_arr[j]
                        if (!tmpPlanData![tmpSeatType]) {
                            tmpPlanData![tmpSeatType] = {
                                dep_date: props.dep_date!,
                                grade: tmpGrade,
                                seat_type: tmpSeatType,
                                contracts: [],
                            }
                        }
                        let tmpEmptyCount = 0
                        if (tmpSeatType < SEAT_TYPE_D) {
                            // 上等級
                            for (let k = 0; k < data.empty_cabin.length; k++) {
                                if (
                                    data.empty_cabin[k].seat_type == tmpSeatType &&
                                    data.empty_cabin[k].divide_type_id == data.contract[i].divide_type_id
                                ) {
                                    tmpEmptyCount = data.empty_cabin[k].empty_cabin
                                    break
                                }
                            }
                        } else {
                            // 下等級は後工程にて設定
                        }
                        let tmpContract: ContractFare = {
                            contract_number: data.contract[i].contract_number,
                            contract_name: data.contract[i].contract_name,
                            grade: tmpGrade,
                            seat_type: tmpSeatType,
                            divide_type_id: data.contract[i].divide_type_id,
                            total_amount: data.contract[i].total_amount,
                            empty_count: tmpEmptyCount,
                        }
                        if ([SEAT_TYPE_FWP_TW, SEAT_TYPE_FWP_FO].includes(tmpContract.seat_type)) {
                            tmpContract.total_amount += data.contract[i].with_pets_amount
                        }
                        tmpPlanData![tmpSeatType].contracts.push(tmpContract)
                    }
                }
                let tmpPlanDataArray = []
                for (var j = 0; j < tmpPlanData.length; j++) {
                    if (tmpPlanData[j]) {
                        tmpPlanDataArray.push(tmpPlanData[j])
                    }
                }
                tmpPlanData = tmpPlanDataArray
            }
            for (let i = 0; i < planData.value!.length; i++) {
                for (let j = 0; j < tmpPlanData.length; j++) {
                    if (planData.value![i].seat_type == tmpPlanData[j].seat_type) {
                        if (sort_param.value[0] == 0) {
                            tmpPlanData[j].contracts.sort((a, b) => Number(b.total_amount) - Number(a.total_amount))
                        } else {
                            tmpPlanData[j].contracts.sort((a, b) => Number(a.total_amount) - Number(b.total_amount))
                        }
                        planData.value![i].contracts = tmpPlanData[j].contracts
                        break
                    }
                }
            }
            // is_show.value = true;
            if (tmpPlanData.length == 0) {
                context.emit('displayMessage', {
                    msg_title: '部屋・プラン検索',
                    msg: ['ご予約可能なプランはございません'],
                    close_btn: true,
                })
            }
            nextTick(async () => {
                await setLowSeatEmptyCount()
            })
            console.log('↑↑↑PlanList.setPlanListContracts↑↑↑')
        }
        const clearPlanList = () => {
            planData.value = []
        }
        const setLowSeatEmptyCount = async () => {
            let functions = [] as any
            if (!isNull(planData.value)) {
                for (let i = 0; i < planData.value!.length; i++) {
                    // 上等級は設定済のはず
                    if (planData.value![i].seat_type < SEAT_TYPE_D) {
                        continue
                    }
                    for (let j = 0; j < planData.value![i].contracts.length; j++) {
                        // planData.value![i].contracts![j].empty_count = await getEmptyCount(planData.value![i].contracts![j].contract_number, planData.value![i].seat_type, props.dep_date!);
                        functions.push(
                            getEmptyCount(planData.value![i].contracts![j].contract_number, planData.value![i].seat_type, props.dep_date!, i, j),
                        )
                    }
                }
            }
            await Promise.all(functions)
        }
        // 並び順設定
        const sortList = (sort_type: number | null) => {
            console.log('↓↓↓PlanList.sortList↓↓↓')
            if (sort_type != null) {
                if (sort_param.value[0] == sort_type) {
                    sort_param.value[1] = (sort_param.value[1] + 1) % 2
                } else {
                    sort_param.value[0] = sort_type!
                    sort_param.value[1] = 0
                }
            }

            if (sort_param.value[0] == 0) {
                // おすすめ順
                planDataSorted.value = planData.value!.sort((a, b) => {
                    if (a.seat_type > b.seat_type) {
                        return 1
                    } else {
                        return -1
                    }
                }) as Plan[]
                for (let i = 0; i < planDataSorted.value.length; i++) {
                    planDataSorted.value[i].contracts = planDataSorted.value[i].contracts.sort((a, b) => {
                        let amount_a = isNull(a.total_amount) ? 0 : a.total_amount
                        let amount_b = isNull(b.total_amount) ? 0 : b.total_amount
                        if (amount_a! < amount_b!) {
                            return 1
                        } else if (amount_a! == amount_b!) {
                            if (a.contract_number > b.contract_number) {
                                return 1
                            } else {
                                return -1
                            }
                        } else {
                            return -1
                        }
                    })
                }
            } else {
                // 料金の安い順
                planDataSorted.value = planData.value!.sort((a, b) => {
                    if (a.seat_type < b.seat_type) {
                        return 1
                    } else {
                        return -1
                    }
                }) as Plan[]
                for (let i = 0; i < planDataSorted.value.length; i++) {
                    planDataSorted.value[i].contracts = planDataSorted.value[i].contracts.sort((a, b) => {
                        let amount_a = isNull(a.total_amount) ? 0 : a.total_amount
                        let amount_b = isNull(b.total_amount) ? 0 : b.total_amount
                        if (amount_a! > amount_b!) {
                            return 1
                        } else if (amount_a! == amount_b!) {
                            if (a.contract_number > b.contract_number) {
                                return 1
                            } else {
                                return -1
                            }
                        } else {
                            return -1
                        }
                    })
                }
            }
            console.log('↑↑↑PlanList.sortList↑↑↑')
        }
        // 空数取得
        const getEmptyCount = async (
            contract_number: string,
            seat_type: number,
            plan_dep_date: Date,
            plan_data_idx: number,
            contract_idx: number,
        ): Promise<number> => {
            let empty_count = 0
            if (
                (!isNull(props.dep_date) || !isNull(plan_dep_date)) &&
                !isNull(props.sea_route_code) &&
                !isNull(contract_number) &&
                !isNull(seat_type)
            ) {
                // 航路
                let seaRouteCode = props.sea_route_code
                let startDate = ''
                let endDate = ''
                // 開始日・終了日
                if (!isNull(plan_dep_date)) {
                    startDate = formatDate(plan_dep_date, true, 0)
                    endDate = formatDate(plan_dep_date, true, 0)
                } else {
                    startDate = formatDate(props.dep_date!, true, 0)
                    endDate = formatDate(props.dep_date!, true, 0)
                }
                let cabin_seat_count = useMinRoomSeatCount(
                    seat_type,
                    props.passenger_adult_num!,
                    props.passenger_child_num!,
                    props.passenger_infant_num!,
                    props.passenger_baby_num!,
                    0,
                )
                let car_count = props.car_3m_num! + props.car_4m_num! + props.car_5m_num! + props.car_6m_num!
                let bicycle_count =
                    props.car_bicycle_num! + props.car_two_wheel_small_num! + props.car_two_wheel_medium_num! + props.car_two_wheel_large_num!

                const postData = {
                    contract_number: contract_number,
                    seat_type: seat_type,
                    sea_route_code: seaRouteCode,
                    start_date: startDate,
                    end_date: endDate,
                    cabin_seat_count: cabin_seat_count,
                    car_count: car_count,
                    bicycle_count: bicycle_count,
                }
                empty_count = await apiClient
                    .post(`${API_ORDER}/calendar`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        planData.value![plan_data_idx].contracts![contract_idx].empty_count = data[0].empty_status
                        return data[0].empty_status
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        console.log(e.message)
                        planData.value![plan_data_idx].contracts![contract_idx].empty_count = 0
                        return 0
                    })
            }
            return empty_count
        }
        // 全空数取得
        const getAllEmptyCount = async () => {
            if (!isNull(planData.value) && !isNull(props.dep_date)) {
                let functions = [] as any
                for (let i = 0; i < planData.value!.length; i++) {
                    planData.value![i].dep_date = props.dep_date!
                    for (let j = 0; j < planData.value![i].contracts.length; j++) {
                        // planData.value![i].contracts[j].empty_count = await getEmptyCount(
                        //     planData.value![i].contracts[j].contract_number,
                        //     planData.value![i].contracts[j].seat_type,
                        //     planData.value![i].dep_date,
                        //     );
                        functions.push(
                            getEmptyCount(
                                planData.value![i].contracts[j].contract_number,
                                planData.value![i].contracts[j].seat_type,
                                planData.value![i].dep_date,
                                i,
                                j,
                            ),
                        )
                    }
                }
                await Promise.all(functions)
            }
        }
        // カレンダー乗船日選択
        const selectPlanCalendarDepDate = async (plan: Plan, seat_type: number, plan_dep_date: Date, contract: ContractFare) => {
            plan.dep_date = plan_dep_date
            if (!isNull(contract) && !isNull(plan_dep_date)) {
                await openPlanDetail(contract!, seat_type, plan_dep_date)
            } else {
                context.emit('selectPlanCalendarDepDate', plan_dep_date)
            }
        }
        // プラン詳細へ遷移
        const openPlanDetail = async (contract: ContractFare, seat_type: number, dep_date: Date) => {
            if (isNull(dep_date)) {
                context.emit('displayMessage', {
                    msg_title: '詳細・予約',
                    msg: ['乗船日を選択してください'],
                    close_btn: true,
                })
            } else {
                // 入力チェック
                let isValid = await chkValidation(seat_type, dep_date, contract.contract_number)
                if (!isValid) {
                    return
                } else {
                    // プラン詳細へ遷移
                    if (isNull(contract.empty_count) || contract.empty_count == 0) {
                        contract.empty_count = 999
                    }
                    context.emit('openPlanDetail', contract.contract_number, contract.empty_count, seat_type, dep_date)
                }
            }
        }
        // 入力チェック
        const chkValidation = async (seat_type: number, dep_date: Date, contract_number: string): Promise<boolean> => {
            let err_count = 0
            let err_messages = []
            let paying_passenger_count = 0
            if (!isNull(props.passenger_adult_num)) paying_passenger_count += props.passenger_adult_num!
            if (!isNull(props.passenger_child_num)) paying_passenger_count += props.passenger_child_num!
            // if (!isNull(props.passenger_infant_num)) paying_passenger_count += props.passenger_infant_num!;
            // if (!isNull(props.passenger_baby_num)) paying_passenger_count += props.passenger_baby_num!;

            // 運行状態チェック
            let shipSchedule = await getShipSchedule(formatDate(dep_date, true, 0), props.sea_route_code!)
            if (shipSchedule.dep_status != DEP_STATUS_NORMAL) {
                err_count++
                err_messages.push('選択された乗船日・航路は運航休止中のため、ご利用いただけません。')
            }
            // 最低人数チェック
            if (isNull(seat_type) || get_seat_type_min_count(seat_type) > paying_passenger_count) {
                err_count++
                err_messages.push(`${getSeatTypeName(seat_type)}は${get_seat_type_min_count(seat_type)}名からご利用いただけます。`)
            }
            let contract = await getContract(contract_number, formatDate(dep_date, true, 0, 0), props.sea_route_code!)
            // 車両制限チェック
            if (contract.vehicle_restrict == VEHICLE_RESTRICT_TWO_AXLE_CAR) {
                // バイクのみ
                let passenger_num = isNull(props.passenger_adult_num) ? 0 : props.passenger_adult_num!
                let bike_num = 0
                bike_num += isNull(props.car_bicycle_num) ? 0 : props.car_bicycle_num!
                bike_num += isNull(props.car_two_wheel_small_num) ? 0 : props.car_two_wheel_small_num!
                bike_num += isNull(props.car_two_wheel_medium_num) ? 0 : props.car_two_wheel_medium_num!
                bike_num += isNull(props.car_two_wheel_large_num) ? 0 : props.car_two_wheel_large_num!

                if (passenger_num > bike_num) {
                    err_count++
                    err_messages.push('こちらのプランは大人の人数は二輪車の台数までご利用いただけます。')
                }
            }

            if (err_count != 0) {
                context.emit('displayMessage', {
                    msg_title: '詳細・予約',
                    msg: err_messages,
                    close_btn: true,
                })
            }

            return err_count == 0
        }

        // 部屋詳細
        const openRoomDetails = (seat_type: number) => {
            console.log('↓↓↓PlanList.openRoomDetails↓↓↓')
            context.emit('openRoomDetails', seat_type)
            console.log('↑↑↑PlanList.openRoomDetails↑↑↑')
        }
        // 部屋タイプ名称取得
        const get_seat_type_name = (seat_type: number): string => {
            return getSeatTypeName(seat_type)
        }
        // お部屋情報取得
        const get_seat_type_room_information = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.information
                }
            }
            return ''
        }
        // お部屋タイプ取得
        const get_seat_type_room_type = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.room_type
                }
            }
            return ''
        }
        // 眺望取得
        const get_seat_type_view = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.view
                }
            }
            return ''
        }
        // 定員取得
        const get_seat_type_capacity = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.capacity
                }
            }
            return ''
        }
        // 最低人数取得
        const get_seat_type_min_count = (seat_type: number): number => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.min_count
                }
            }
            return 999
        }
        // 部屋の広さ取得
        const get_seat_type_wide = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.wide
                }
            }
            return ''
        }
        // 設備・備品取得
        const get_seat_type_facility = (seat_type: number): string => {
            for (const info of ROOM_INFORMATIONS) {
                if (info.seat_type == seat_type) {
                    return info.facility
                }
            }
            return ''
        }
        // 空き単位
        const display_unit = (seat_type: number): string => {
            if (seat_type == SEAT_TYPE_D || seat_type == SEAT_TYPE_T) {
                return '席'
            }
            return '部屋'
        }
        // 空き単位
        const display_unit2 = (seat_type: number): string => {
            if (seat_type == SEAT_TYPE_D || seat_type == SEAT_TYPE_T) {
                return '席'
            }
            return '室'
        }
        // 空室か？
        const isEmpty = (empty_count: number, seat_type: number): boolean => {
            if (empty_count == 0) {
                return false
            }
            let passenger_num = 0
            passenger_num += isNull(props.passenger_adult_num) ? 0 : props.passenger_adult_num!
            passenger_num += isNull(props.passenger_child_num) ? 0 : props.passenger_child_num!
            let empty_par_seat_count = useMaxRoomPassengerCount(seat_type, 0)
            if (passenger_num > empty_count * empty_par_seat_count) {
                return false
            }
            return true
        }

        const roomTypeCount = computed(() => {
            return planDataSorted.value?.length
        })
        const isRokko = computed(() => {
            return props.ship_code == SHIP_CODE_ROKKO
        })
        const is_show = computed(() => {
            return props.isShow
        })
        watch(is_show, (next, prev) => {
            if (is_show.value == false) {
                is_searching.value = true
            }
        })

        return {
            disabledDates,
            is_show,
            is_searching,

            planData,
            planDataSorted,
            openPlanList,
            setPlanListContracts,
            selectPlanCalendarDepDate,
            openPlanDetail,
            getAllEmptyCount,

            roomTypeCount,
            isRokko,

            openRoomDetails,
            clearPlanList,
            sortList,
            get_seat_type_name,
            get_seat_type_room_information,
            get_seat_type_room_type,
            get_seat_type_view,
            get_seat_type_capacity,
            get_seat_type_wide,
            get_seat_type_facility,
            display_unit,
            display_unit2,
            isEmpty,

            isNull,
            useMinRoomSeatCount,

            SEAT_TYPE_D,
            SEAT_TYPE_T,
        }
    },
    emits: ['openPlanDetail', 'openRoomDetails', 'selectPlanCalendarDepDate', 'displayMessage'],
    name: 'PlanList',
    data() {},

    components: {
        EmptyCalendar,
    },
    props: {
        isShow: Boolean,
        dep_date: Date,
        sea_route_code: String,
        ship_code: String,
        passenger_adult_num: Number,
        passenger_child_num: Number,
        passenger_infant_num: Number,
        passenger_baby_num: Number,
        car_num: Number,
        car_3m_num: Number,
        car_4m_num: Number,
        car_5m_num: Number,
        car_6m_num: Number,
        car_bicycle_num: Number,
        car_two_wheel_small_num: Number,
        car_two_wheel_medium_num: Number,
        car_two_wheel_large_num: Number,
        cage_small_num: Number,
        cage_large_num: Number,
    },
    methods: {},
    mounted: function () {
        console.log('↓↓↓PlanList.mounted↓↓↓')
        console.log('↑↑↑PlanList.mounted↑↑↑')
    },
})
</script>

<style scoped></style>
