<template>
    <tr>
        <th>二輪車</th>
        <td id="search__item-bike">
            <span>{{ dispBicycles() }}</span>
            <button class="btn-border" type="button" v-on:click="toggleInputTwoWheel()">変更</button>
            <div :id="'search__set-bike-' + list_idx" class="search__set search__set-bike none" style="max-width: 40rem">
                <ul class="input-set__list">
                    <li id="input-set__item-bicycle" class="input-set__item input-set__item-bicycle">
                        <label id="input-set__label-bicycle" class="input-set__label input-set__label-bicycle" for="input-set__number-bicycle"
                            >自転車</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementTwoWheel(CAR_TYPE_BICYCLE)">－</button>
                            <input
                                id="input-set__number-bicycle"
                                class="input-set__number input-set__number-bicycle"
                                type="text"
                                name="bicycle"
                                v-model="car_bicycle_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementTwoWheel(CAR_TYPE_BICYCLE)">＋</button>
                        </div>
                    </li>
                    <li id="input-set__item-under125" class="input-set__item input-set__item-under125">
                        <label id="input-set__label-under125" class="input-set__label input-set__label-under125" for="input-set__number-under125"
                            >125cc未満</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementTwoWheel(CAR_TYPE_TWO_WHEEL_SMALL)">－</button>
                            <input
                                id="input-set__number-under125"
                                class="input-set__number input-set__number-under125"
                                type="text"
                                name="under125"
                                v-model="car_two_wheel_small_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementTwoWheel(CAR_TYPE_TWO_WHEEL_SMALL)">＋</button>
                        </div>
                    </li>
                    <li id="input-set__item-under750" class="input-set__item input-set__item-under750">
                        <label id="input-set__label-under750" class="input-set__label input-set__label-under750" for="input-set__number-under750"
                            >125cc〜750cc未満</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementTwoWheel(CAR_TYPE_TWO_WHEEL_MEDIUM)">－</button>
                            <input
                                id="input-set__number-under750"
                                class="input-set__number input-set__number-under750"
                                type="text"
                                name="under750"
                                v-model="car_two_wheel_medium_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementTwoWheel(CAR_TYPE_TWO_WHEEL_MEDIUM)">＋</button>
                        </div>
                    </li>
                    <li id="input-set__item-over750" class="input-set__item input-set__item-over750">
                        <label id="input-set__label-over750" class="input-set__label input-set__label-over750" for="input-set__number-over750"
                            >750cc以上</label
                        >
                        <div class="input-set__change">
                            <button class="input-set__count minus" type="button" v-on:click="decrementTwoWheel(CAR_TYPE_TWO_WHEEL_LARGE)">－</button>
                            <input
                                id="input-set__number-over750"
                                class="input-set__number input-set__number-over750"
                                type="text"
                                name="over750"
                                v-model="car_two_wheel_large_num_tmp"
                                readonly
                            />
                            <button class="input-set__count plus" type="button" v-on:click="incrementTwoWheel(CAR_TYPE_TWO_WHEEL_LARGE)">＋</button>
                        </div>
                    </li>
                </ul>
                <div class="input-set__buttons">
                    <button
                        id="input-set__cansel-bike"
                        class="btn input-set__cansel input-set__cansel-bike"
                        type="button"
                        v-on:click="cancelTwoWheel()"
                    >
                        キャンセル
                    </button>
                    <button
                        id="input-set__setting-bike"
                        class="btn input-set__setting input-set__setting-bike"
                        type="button"
                        v-on:click="commitTwoWheel()"
                    >
                        設定
                    </button>
                </div>
            </div>
        </td>
        <td class="detail__td-count">
            {{ bicycle_amount.toLocaleString() + '円' }}
        </td>
    </tr>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, ref } from 'vue'
import $ from 'jquery'
import { CAR_TYPE_TWO_WHEEL_LARGE, CAR_TYPE_TWO_WHEEL_MEDIUM, CAR_TYPE_TWO_WHEEL_SMALL, CAR_TYPE_BICYCLE } from '@/lib/util'

export default defineComponent({
    setup(props, context) {
        const car_bicycle_num_tmp = ref(0)
        const car_two_wheel_small_num_tmp = ref(0)
        const car_two_wheel_medium_num_tmp = ref(0)
        const car_two_wheel_large_num_tmp = ref(0)

        // 初期化処理
        const init = async () => {
            console.log('↓↓↓BicycleList.init↓↓↓')
            console.log('↑↑↑BicycleList.init↑↑↑')
        }
        // 表示二輪車数
        const dispBicycles = (): string => {
            let values = []

            if (props.car_bicycle_num! > 0) {
                values.push(`自転車：${props.car_bicycle_num}台`)
            }
            if (props.car_two_wheel_small_num! > 0) {
                values.push(`125cc未満：${props.car_two_wheel_small_num}台`)
            }
            if (props.car_two_wheel_medium_num! > 0) {
                values.push(`125cc～750cc未満：${props.car_two_wheel_medium_num}台`)
            }
            if (props.car_two_wheel_large_num! > 0) {
                values.push(`750cc以上：${props.car_two_wheel_large_num}台`)
            }

            return values.join(' ')
        }

        // 二輪車数変更
        const toggleInputTwoWheel = () => {
            car_bicycle_num_tmp.value = props.car_bicycle_num!
            car_two_wheel_small_num_tmp.value = props.car_two_wheel_small_num!
            car_two_wheel_medium_num_tmp.value = props.car_two_wheel_medium_num!
            car_two_wheel_large_num_tmp.value = props.car_two_wheel_large_num!
            $('#search__set-bike-' + props.list_idx).toggle()
        }
        const incrementTwoWheel = (car_type: string) => {
            if (car_type == CAR_TYPE_BICYCLE) {
                car_bicycle_num_tmp.value += 1
            } else if (car_type == CAR_TYPE_TWO_WHEEL_SMALL) {
                car_two_wheel_small_num_tmp.value += 1
            } else if (car_type == CAR_TYPE_TWO_WHEEL_MEDIUM) {
                car_two_wheel_medium_num_tmp.value += 1
            } else if (car_type == CAR_TYPE_TWO_WHEEL_LARGE) {
                car_two_wheel_large_num_tmp.value += 1
            }
        }
        const decrementTwoWheel = (car_type: string) => {
            if (car_type == CAR_TYPE_BICYCLE) {
                car_bicycle_num_tmp.value -= 1
                if (car_bicycle_num_tmp.value < 0) {
                    car_bicycle_num_tmp.value = 0
                }
            } else if (car_type == CAR_TYPE_TWO_WHEEL_SMALL) {
                car_two_wheel_small_num_tmp.value -= 1
                if (car_two_wheel_small_num_tmp.value < 0) {
                    car_two_wheel_small_num_tmp.value = 0
                }
            } else if (car_type == CAR_TYPE_TWO_WHEEL_MEDIUM) {
                car_two_wheel_medium_num_tmp.value -= 1
                if (car_two_wheel_medium_num_tmp.value < 0) {
                    car_two_wheel_medium_num_tmp.value = 0
                }
            } else if (car_type == CAR_TYPE_TWO_WHEEL_LARGE) {
                car_two_wheel_large_num_tmp.value -= 1
                if (car_two_wheel_large_num_tmp.value < 0) {
                    car_two_wheel_large_num_tmp.value = 0
                }
            }
        }
        const cancelTwoWheel = () => {
            $('div[id^="search__set-bike-"]').hide()
        }
        const commitTwoWheel = async () => {
            // 乗船者人数確定時処理
            $('div[id^="search__set-bike-"]').hide()
            context.emit(
                'commitBicycle',
                car_bicycle_num_tmp.value,
                car_two_wheel_small_num_tmp.value,
                car_two_wheel_medium_num_tmp.value,
                car_two_wheel_large_num_tmp.value,
            )
        }

        return {
            car_bicycle_num_tmp,
            car_two_wheel_small_num_tmp,
            car_two_wheel_medium_num_tmp,
            car_two_wheel_large_num_tmp,

            CAR_TYPE_TWO_WHEEL_LARGE,
            CAR_TYPE_TWO_WHEEL_MEDIUM,
            CAR_TYPE_TWO_WHEEL_SMALL,
            CAR_TYPE_BICYCLE,

            init,
            dispBicycles,

            toggleInputTwoWheel,
            incrementTwoWheel,
            decrementTwoWheel,
            cancelTwoWheel,
            commitTwoWheel,
        }
    },
    emits: ['commitBicycle'],
    name: 'BicycleList',
    data() {
        return {}
    },

    components: {},
    props: {
        list_idx: Number,
        dep_date: String,
        sea_route_code: String,
        contract_number: String,
        grade: Number,
        seat_type: Number,
        car_bicycle_num: Number,
        car_two_wheel_small_num: Number,
        car_two_wheel_medium_num: Number,
        car_two_wheel_large_num: Number,
        bicycle_amount: Number,
    },
    methods: {},
    mounted: function () {
        $(document).on('click', function (e) {
            if (!$(e.target).closest('#search__item-bike').length) {
                $('div[id^="search__set-bike-"]').hide()
            }
        })
    },
})
</script>

<style scoped></style>
