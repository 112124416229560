import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from './store'
import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App)
    .use(store, key)
    .use(router)
    .use(VueReCaptcha, {
        siteKey: '6LcICCsmAAAAAB0f1GGgoZEYluTb8pD4GooHMCZX',
        loaderOptions: {
            autoHideBadge: true,
        },
    })
    .mount('#app')
