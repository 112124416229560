<!-- eslint-disable no-irregular-whitespace -->
<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">過去の予約</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- ticket -->
        <section class="ticket ticket-history none">
            <div class="ticket__container ticket__container-history">
                <div class="ticket__specify">
                    <ul class="specify__data-list">
                        <li :class="`specify__data-item specify__data-item-history ${isTargetCurrent ? '' : 'specify__data-item-disabled'}`">
                            <input type="checkbox" id="input__item-history" v-model="isTargetCurrent" /><span
                                v-on:click="changeIsTargetCurrent"
                                for="input__item-history"
                                >乗船履歴</span
                            >
                        </li>
                        <li :class="`specify__data-item specify__data-item-canseled ${isTargetCancel ? '' : 'specify__data-item-disabled'}`">
                            <input type="checkbox" id="input__item-canseled" v-model="isTargetCancel" /><span v-on:click="changeIsTargetCancel"
                                >キャンセル済</span
                            >
                        </li>
                    </ul>
                </div>
                <div class="ticket__information">
                    <div class="ticket__count">
                        件数<span>{{ historyCount }}</span
                        >件
                    </div>
                    <!-- <ul id ="" class="ticket__sort-list">
            <li class="ticket__sort-item" v-on:click="sortList(0)">出発日順</li>
            <li class="ticket__sort-item" v-on:click="sortList(1)">予約日順</li>
          </ul> -->
                </div>
                <!-- ticket__list -->
                <ul id="ticket__list" class="ticket__list">
                    <li class="ticket__item" v-for="(history, idx) in historyListSorted" :key="idx">
                        <div class="ticket__item-header">
                            <h3 class="ticket__item-name">チケット情報（{{ idx + 1 }}）</h3>
                        </div>
                        <div class="ticket__item-container">
                            <div class="output">
                                <dl class="output__list">
                                    <!-- line -->
                                    <div class="output__line">
                                        <!-- ticketnumber -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-ticketnumber">乗船番号</div>
                                            </dt>
                                            <dd class="output__container output__container-ticketnumber">
                                                <div class="output__data output__data-ticketnumber">
                                                    {{ history.boarding_no }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- fee -->
                                        <div class="output__item" v-show="!history.canceled">
                                            <dt class="output__header">
                                                <div class="output__name output__name-fee">運賃合計</div>
                                            </dt>
                                            <dd class="output__container output__container-fee">
                                                <div class="output__data output__data-fee">
                                                    {{ (isNull(history.amount) ? 0 : history.amount).toLocaleString() }}円
                                                </div>
                                            </dd>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="output__line">
                                        <!-- date -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-date">乗船日</div>
                                            </dt>
                                            <dd class="output__container output__container-date">
                                                <div class="output__data output__data-date">
                                                    {{ formatDate(history.dep_date, false, 2, 1) }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- route -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-route">ご利用区間</div>
                                            </dt>
                                            <dd class="output__container output__container-route">
                                                <div class="output__data output__data-route">
                                                    {{ history.sea_route_name }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- time -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-time">出航時刻</div>
                                            </dt>
                                            <dd class="output__container output__container-time">
                                                <div class="output__data output__data-time">
                                                    {{
                                                        formatDateTime(
                                                            castStringToDate(formatDate(history.dep_date, true, 0, 0) + ' ' + history.dep_time),
                                                            false,
                                                            1,
                                                        )
                                                    }}
                                                </div>
                                            </dd>
                                        </div>
                                        <!-- ship-name -->
                                        <div class="output__item">
                                            <dt class="output__header">
                                                <div class="output__name output__name-ship">客室タイプ</div>
                                            </dt>
                                            <dd class="output__container output__container-ship">
                                                <div class="output__data output__data-ship">
                                                    {{ history.seat_type_name }}
                                                </div>
                                            </dd>
                                        </div>
                                    </div>
                                    <dl class="output__status">
                                        <div class="output__status-pack">
                                            <!-- resevdate -->
                                            <div class="output__status-container output__status-container-resevdate">
                                                <dt class="output__status-name output__status-name-resevdate">予約日</dt>
                                                <dt class="output__status-data output__status-data-resevdate">
                                                    {{ formatDate(history.reserved_date, false, 2, 1) }}
                                                </dt>
                                            </div>
                                            <!-- resevstatus -->
                                            <div class="output__status-container output__status-container-resevstatus">
                                                <dt class="output__status-name output__status-name-resevstatus">状態</dt>
                                                <dt class="output__status-data output__status-data-resevstatus">
                                                    {{ history.booking_status_name }}
                                                </dt>
                                            </div>
                                            <!-- paymentstatus -->
                                            <div class="output__status-container output__status-container-resevstatus" v-show="!history.canceled">
                                                <dt class="output__status-name output__status-name-paymentstatus">入金状態</dt>
                                                <dt class="output__status-data output__status-data-paymentstatus">
                                                    {{ history.paid_status_name }}
                                                </dt>
                                            </div>
                                            <!-- duedate -->
                                            <!-- <div class="output__status-container output__status-container-duedate">
                        <dt class="output__status-name output__status-name-duedate">入金期限日</dt>
                        <dt class="output__status-data output__status-data-duedate">{{ isNull(history.min_transfer_deadline_date) ? '' : formatDate(history.min_transfer_deadline_date, false, 2, 1) }}</dt>
                      </div> -->
                                        </div>
                                    </dl>
                                    <!-- <div v-if="history.canceled == true" class="output__btn">
                    <button class="btn btn-bank" type="button" v-show="!isCounterPayment(history.payment_method)"
                      v-on:click="displayAuthori(history.boarding_no, history.payment_method)">お支払状況確認</button>
                    <button class="btn btn-confirm" type="button"
                      v-on:click="displayBoardingDetail(history.boarding_no, history.sea_route_code)">予約内容確認</button>
                  </div>
                  <div v-if="!canPrintReceipt(history)" class="output__btn">
                    <button class="btn btn-bank" type="button" v-show="!isCounterPayment(history.payment_method)"
                      v-on:click="displayAuthori(history.boarding_no, history.payment_method)">お支払状況確認</button>
                    <button class="btn btn-cansell" type="button">領収書発行</button>
                    <button class="btn btn-confirm" type="button"
                      v-on:click="displayBoardingDetail(history.boarding_no, history.sea_route_code)">予約内容確認</button>
                  </div>
                  </div> -->
                                    <div class="output__btn">
                                        <button
                                            class="btn btn-bank"
                                            type="button"
                                            v-show="!isCounterPayment(history.payment_method)"
                                            v-on:click="displayAuthori(history.boarding_no, history.payment_method)"
                                        >
                                            お支払状況確認
                                        </button>
                                        <button
                                            v-if="canPrintReceipt(history)"
                                            class="btn btn-confirm"
                                            type="button"
                                            v-on:click="displayNameSet(history.boarding_no)"
                                        >
                                            領収書発行
                                        </button>
                                        <button v-else class="btn btn-inactive" type="button">領収書発行</button>
                                        <button
                                            class="btn btn-confirm"
                                            type="button"
                                            v-on:click="displayBoardingDetail(history.boarding_no, history.sea_route_code)"
                                        >
                                            予約内容確認
                                        </button>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container">
                <button class="btn btn-mypage" type="button" v-on:click="moveMyPage">マイページに戻る</button>
                <button class="btn btn-mypage" type="button" v-on:click="moveBookingCurrent">現在の予約へ</button>
            </div>
        </section>
        <!-- /ticket -->
        <!-- history-confirm -->
        <button id="btn-history-confirm-open-modal" data-remodal-target="history-confirm" v-show="false">○</button>
        <button id="btn-history-confirm-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section id="history-confirm" class="confirm remodal normal" data-remodal-id="history-confirm">
            <div class="confirm__header">
                <h3 class="confirm__name">予約内容確認</h3>
            </div>
            <div class="confirm__container">
                <ul class="confirm__list">
                    <!-- ticket -->
                    <li class="confirm__item">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">チケット情報</h3>
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">乗船番号</dt>
                                        <dd class="confirm__disp-data">
                                            {{ boarding?.boarding_no }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">船室</dt>
                                        <dd class="confirm__disp-data">{{ dispSeatTypeName }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">プラン</dt>
                                        <dd class="confirm__disp-data">
                                            {{ contract?.contract_name }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ご利用区間</dt>
                                        <dd class="confirm__disp-data">
                                            {{ boarding?.sea_route_name }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">船名</dt>
                                        <dd class="confirm__disp-data">
                                            {{ boarding?.ship_name }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">乗船日</dt>
                                        <dd class="confirm__disp-data">
                                            {{ formatDate(castStringToDate(boarding?.dep_date), false, 2, 1) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">出航時刻</dt>
                                        <dd class="confirm__disp-data">
                                            {{
                                                formatDateTime(
                                                    castStringToDate(
                                                        formatDate(castStringToDate(boarding?.dep_date), true, 0, 0) + ' ' + boarding?.dep_time,
                                                    ),
                                                    false,
                                                    1,
                                                )
                                            }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- master -->
                    <li class="confirm__item">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">代表者</h3>
                            <img
                                class="confirm__item-icon"
                                src="@/images/items/accessory-ancer-01.svg"
                                height="48"
                                width="54"
                                alt="代表者情報アイコン"
                            />
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">区分</dt>
                                        <dd class="confirm__disp-data">
                                            {{ convertText(PASSENGER_TYPES, represent_passenger?.passenger_type) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">お名前</dt>
                                        <dd class="confirm__disp-data">
                                            {{
                                                joinFirstNameAndLastName(
                                                    represent_passenger?.family_name_katakana,
                                                    represent_passenger?.given_name_katakana,
                                                )
                                            }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">電話番号</dt>
                                        <dd class="confirm__disp-data">
                                            {{ boarding?.represent_passenger_tel }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">性別</dt>
                                        <dd class="confirm__disp-data">
                                            {{ convertText(GENDERS, represent_passenger?.gender) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">乗船時年齢</dt>
                                        <dd class="confirm__disp-data">{{ represent_passenger?.age }}歳</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">住所</dt>
                                        <dd class="confirm__disp-data">
                                            {{ boarding?.represent_passenger_post_code }}　{{
                                                convertText(PREFECTURES, boarding?.represent_passenger_prefectures)
                                            }}　{{ boarding?.represent_passenger_address }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">運賃</dt>
                                        <dd class="confirm__disp-data">{{ displayAmount.toLocaleString() }}円</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- passenger -->
                    <li class="confirm__item" v-for="(passenger, idx) in passenger_array" :key="idx">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">同行者</h3>
                            <img
                                class="confirm__item-icon"
                                src="@/images/items/accessory-strawhat-01.svg"
                                height="48"
                                width="54"
                                alt="同行者情報アイコン"
                            />
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">区分</dt>
                                        <dd class="confirm__disp-data">
                                            {{ convertText(PASSENGER_TYPES, passenger.passenger_type) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">お名前</dt>
                                        <dd class="confirm__disp-data">
                                            {{ joinFirstNameAndLastName(passenger.family_name_katakana, passenger.given_name_katakana) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">性別</dt>
                                        <dd class="confirm__disp-data">
                                            {{ convertText(GENDERS, passenger.gender) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">乗船時年齢</dt>
                                        <dd class="confirm__disp-data">{{ passenger.age }}歳</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- car -->
                    <li class="confirm__item" v-for="(car, idx) in car_array" :key="idx">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">乗用車</h3>
                            <img
                                class="confirm__item-icon"
                                src="@/images/items/accessory-car-01.svg"
                                height="48"
                                width="54"
                                alt="乗用車情報アイコン"
                            />
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">運転手</dt>
                                        <dd class="confirm__disp-data">代表者</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">車長</dt>
                                        <dd class="confirm__disp-data">{{ car.car_length }}m未満</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">車種</dt>
                                        <dd class="confirm__disp-data">{{ car.note }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ナンバープレート</dt>
                                        <dd class="confirm__disp-data">
                                            {{ car.car_place_name }} {{ car.car_class_number }} {{ car.car_hiragana }} {{ car.car_number }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- bike -->
                    <li class="confirm__item" v-for="(vehicle, idx) in vehicle_array" :key="idx">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">二輪車</h3>
                            <img
                                class="confirm__item-icon"
                                src="@/images/items/accessory-bike-01.svg"
                                height="48"
                                width="54"
                                alt="二輪車情報アイコン"
                            />
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">種別/排気量</dt>
                                        <dd class="confirm__disp-data">
                                            {{ getCarTypeName(vehicle.car_type_code) }}
                                        </dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">メーカー/車名</dt>
                                        <dd class="confirm__disp-data">{{ vehicle.note }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ナンバープレート</dt>
                                        <dd class="confirm__disp-data">
                                            {{ vehicle.car_place_name }}
                                            {{ vehicle.car_class_number }} {{ vehicle.car_hiragana }}
                                            {{ vehicle.car_number }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- note -->
                    <li class="confirm__item">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">特記事項</h3>
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">妊婦</dt>
                                        <dd class="confirm__disp-data">{{ displayPregnant }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">車椅子</dt>
                                        <dd class="confirm__disp-data">{{ displayWheelChair }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">医療用酸素ボンベ</dt>
                                        <dd class="confirm__disp-data">
                                            {{ displayOxygenCylinder }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- pet -->
                    <li class="confirm__item" v-show="isShowPetCage">
                        <div class="confirm__item-header">
                            <h3 class="confirm__item-name">ペット情報</h3>
                            <img
                                class="confirm__item-icon"
                                src="@/images/items/accessory-pet-01.svg"
                                height="48"
                                width="54"
                                alt="ペット情報アイコン"
                            />
                        </div>
                        <div class="confirm__item-container">
                            <div class="confirm__disp">
                                <dl class="confirm__disp-list">
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ペットルーム</dt>
                                        <dd class="confirm__disp-data">{{ displayCageNum }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ペット区分</dt>
                                        <dd class="confirm__disp-data">{{ displayPetNum }}</dd>
                                    </div>
                                    <div class="confirm__disp-line">
                                        <dt class="confirm__disp-name">ペット種類</dt>
                                        <dd class="confirm__disp-data">{{ boarding?.pet_note }}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="confirm__btn">
                <button class="btn btn-back" data-remodal-action="cancel">閉じる</button>
            </div>
        </section>
        <!-- /history-confirm -->
        <!-- bank -->
        <button id="btn-history-bank-open-modal" data-remodal-target="history-bank" v-show="false">○</button>
        <button id="btn-history-bank-close-modal" data-remodal-action="cancel" v-show="false">×</button>
        <section id="history-bank" class="confirm bank remodal normal" data-remodal-id="history-bank">
            <div class="confirm__header">
                <h3 class="confirm__name">お支払い状況</h3>
                <button class="confirm__close" data-remodal-action="cancel">×</button>
            </div>
            <div class="confirm__container">
                <ul class="confirm__list">
                    <!-- infoset -->
                    <template v-for="(authori, idx) in authori_array" :key="idx">
                        <template v-if="authori.payment_method == AUTHORI_PAYMENT_METHOD_CREDIT && idx == 0">
                            <li class="confirm__item" v-for="(authori_detail, idx2) in authori_credit_array" :key="idx2">
                                <div class="confirm__item-container">
                                    <div class="confirm__disp">
                                        <dl class="confirm__disp-list">
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">更新日時</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.sales_datetime }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">運賃（変更後）</dt>
                                                <dd class="confirm__disp-data">{{ authori_detail.amount?.toLocaleString() }}円</dd>
                                            </div>
                                            <div
                                                class="confirm__disp-line"
                                                v-show="authori_detail.cancel_amount != null && authori_detail.cancel_amount! > 0"
                                            >
                                                <dt class="confirm__disp-name">内キャンセル料</dt>
                                                <dd class="confirm__disp-data">{{ authori_detail.cancel_amount?.toLocaleString() }}円</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </li>
                        </template>
                        <template v-else-if="authori.payment_method == AUTHORI_PAYMENT_METHOD_PAY_EASY && idx == 0">
                            <li class="confirm__item" v-for="(authori_detail, idx3) in authori_payeasy_array" :key="idx3">
                                <template v-if="idx3 == 0">
                                    <div class="confirm__item-header">
                                        <h3 class="confirm__item-name">ご入金履歴</h3>
                                    </div>
                                </template>
                                <div class="confirm__item-container">
                                    <div class="confirm__disp">
                                        <dl class="confirm__disp-list">
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金期限日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.transfer_deadline_datetime }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.confirmed_sales_datetime }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">収納機関番号</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.payeasy_collection_agency_number }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">お客様番号</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.payeasy_customer_number }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">確認番号</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.payeasy_confirmation_number }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金額</dt>
                                                <dd class="confirm__disp-data">{{ authori_detail.amount?.toLocaleString() }}円</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </li>
                        </template>
                        <template v-else-if="authori.payment_method == AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE && idx == 0">
                            <li class="confirm__item" v-for="(authori_detail, idx4) in authori_convenience_array" :key="idx4">
                                <template v-if="idx4 == 0">
                                    <div class="confirm__item-header">
                                        <h3 class="confirm__item-name">ご入金履歴</h3>
                                    </div>
                                </template>
                                <div class="confirm__item-container">
                                    <div class="confirm__disp">
                                        <dl class="confirm__disp-list">
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金期限日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.transfer_deadline_datetime }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.confirmed_sales_datetime }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">コンビニ</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.convenience_name }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">お客様番号</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.convenience_receipt_number }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">確認番号</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ authori_detail.convenience_confirmation_number }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">入金額</dt>
                                                <dd class="confirm__disp-data">{{ authori_detail.amount?.toLocaleString() }}円</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </li>
                        </template>
                        <template v-if="remittance_array.length > 0 && idx == 0">
                            <li class="confirm__item" v-for="(remittance_detail, idx5) in remittance_array" :key="idx5">
                                <template v-if="idx5 == 0">
                                    <div class="confirm__item-header">
                                        <h3 class="confirm__item-name">ご返金履歴</h3>
                                    </div>
                                </template>
                                <div class="confirm__item-container">
                                    <div class="confirm__disp">
                                        <dl class="confirm__disp-list">
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">返金発生日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ remittance_detail.ins_date }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">返金日</dt>
                                                <dd class="confirm__disp-data">
                                                    {{ remittance_detail.deposit_date }}
                                                </dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">取消運賃</dt>
                                                <dd class="confirm__disp-data">{{ remittance_detail.payment_amount?.toLocaleString() }}円</dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">キャンセル料</dt>
                                                <dd class="confirm__disp-data">{{ remittance_detail.cancel_amount?.toLocaleString() }}円</dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">振込手数料</dt>
                                                <dd class="confirm__disp-data">{{ remittance_detail.transfer_fee?.toLocaleString() }}円</dd>
                                            </div>
                                            <div class="confirm__disp-line">
                                                <dt class="confirm__disp-name">返金額</dt>
                                                <dd class="confirm__disp-data">{{ remittance_detail.refunded_amount?.toLocaleString() }}円</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </template>
                </ul>
            </div>
            <div class="confirm__btn">
                <button class="btn btn-back" data-remodal-action="cancel">閉じる</button>
            </div>
        </section>
        <!-- /bank -->
        <!-- receipt nameset -->
        <button id="btn-nameset-open-modal" data-remodal-target="nameset" v-show="false">○</button>
        <section id="nameset" class="setting remodal normal" data-remodal-id="nameset">
            <div class="input">
                <dl class="input__list">
                    <!-- line -->
                    <div class="input__line">
                        <!-- destination -->
                        <div id="input__item-destination" class="input__item input__item-destination">
                            <dt id="input__header-destination" class="input__header input__header-destination">
                                <label id="input__name-destination" class="input__name input__name-destination" for="input__destination"
                                    >領収書宛名欄</label
                                >
                                <span class="input__required">必須</span>
                                <!-- </dt>
              <dt id="input__container-destination" class="input__container input__container-destination"> -->
                                <div id="input__destination-pack" class="input__destination-pack">
                                    <input
                                        id="input__destination"
                                        class="input__form input__destination"
                                        name="input__destination"
                                        type="text"
                                        v-model="destination"
                                    />
                                </div>
                            </dt>
                            <p class="input__error-text">領収書宛名欄を入力してください</p>
                        </div>
                    </div>
                </dl>
            </div>
            <div class="btn-container btn-container-modal">
                <button class="btn btn-back" data-remodal-action="cancel" id="btn-nameset-close-modal">キャンセル</button>
                <button class="btn btn-next" v-on:click="printReceipt">領収書発行</button>
            </div>
        </section>
        <!-- /receipt nameset -->
        <ShipAnimationEnd></ShipAnimationEnd>
    </main>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch, nextTick } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { setReceiptData } from '@/lib/localStorageUtil'
import {
    API_USERS,
    API_ORDER,
    CAR_TYPE_PASSENGER,
    isNull,
    castStringToDate,
    formatDate,
    formatDateTime,
    calcDiffDays,
    getCarTypeName,
    splitFullname,
    joinFirstNameAndLastName,
    DEFAULT_TWO_WHEEL_WIDTH,
    DEFAULT_CAR_WIDTH,
    getBoardingContract,
    getPaymentMethod,
    getSeatTypeName,
} from '@/lib/util'
import {
    convertText,
    PASSENGER_TYPES,
    GENDERS,
    PREFECTURES,
    AGENT_PAYMENT_STATUSES,
    CAGE_TYPES_LARGE,
    CAGE_TYPES_SMALL,
    AUTHORI_PAYMENT_METHOD_CREDIT,
    AUTHORI_PAYMENT_METHOD_PAY_EASY,
    AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE,
} from '@/lib/constants'
import ShipAnimationEnd from '@/components/common/ShipAnimationEnd.vue'
import {
    Boarding,
    PassengerDetail,
    CarDetail,
    Contract,
    CageDetail,
    BookingHistoryDetail,
    AuthoriHistoryDetail,
    AuthoriCreditHistoryDetail,
    AuthoriPayeasyHistoryDetail,
    AuthoriConvenienceHistoryDetail,
    RemittanceHistoryDetail,
    ReceiptDetail,
} from '@/lib/types'
import { isZenKatakana } from '@/lib/validation_util'

export default defineComponent({
    setup(props, context) {
        const router = useRouter()
        const store = useStore()
        const isTargetCurrent = ref(true)
        const isTargetCancel = ref(false)
        const historyList = ref<BookingHistoryDetail[]>([])
        const historyListSorted = ref<BookingHistoryDetail[]>([])
        const sort_param = ref<number[]>([0, 1])

        // 入金内容確認
        const authori_array = ref<AuthoriHistoryDetail[]>([])
        const authori_credit_array = ref<AuthoriCreditHistoryDetail[]>([])
        const authori_payeasy_array = ref<AuthoriPayeasyHistoryDetail[]>([])
        const authori_convenience_array = ref<AuthoriConvenienceHistoryDetail[]>([])
        // 返金情報確認
        const remittance_array = ref<RemittanceHistoryDetail[]>([])

        // 予約内容確認
        const boarding = ref<Boarding>()
        const represent_passenger = ref<PassengerDetail>()
        const contract = ref<Contract>()
        const passenger_array = ref<PassengerDetail[]>([])
        const car_array = ref<CarDetail[]>([])
        const vehicle_array = ref<CarDetail[]>([])
        const petcage_array = ref<CageDetail[]>([])

        const historyCount = computed(() => {
            return historyList.value.length
        })

        // 領収書発行
        const receipt_boarding_no = ref()
        const destination = ref()

        const sortList = (sort_type: number | null) => {
            console.log('↓↓↓BookingHistory.sortList↓↓↓')
            // if (sort_type != null) {
            //     if(sort_param.value[0] == sort_type) {
            //         sort_param.value[1] = (sort_param.value[1] + 1) % 2;
            //     } else {
            //         sort_param.value[0] = sort_type!;
            //         sort_param.value[1] = 0;
            //     }
            // }

            // if (sort_param.value[0] == 0) {
            //     // 出発日順
            //     if (sort_param.value[1] == 0) {
            //         // 昇順
            //         historyListSorted.value = historyList.value.sort((a, b) => {
            //             if (calcDiffDays(a.dep_date, b.dep_date) == 0) {
            //                 if (a.boarding_no > b.boarding_no) {
            //                     return 1;
            //                 } else {
            //                     return -1;
            //                 }
            //             } else if (a.dep_date > b.dep_date) {
            //                 return 1;
            //             } else {
            //                 return -1;
            //             }
            //         }) as BookingHistoryDetail[];
            //     } else {
            //         // 降順
            //         historyListSorted.value = historyList.value.sort((a, b) => {
            //             if (calcDiffDays(a.dep_date, b.dep_date) == 0) {
            //                 if (a.boarding_no > b.boarding_no) {
            //                     return -1;
            //                 } else {
            //                     return 1;
            //                 }
            //             } else if (a.dep_date > b.dep_date) {
            //                 return -1;
            //             } else {
            //                 return 1;
            //             }
            //         }) as BookingHistoryDetail[];
            //     }
            // } else {
            //     // 予約日順
            //     if (sort_param.value[1] == 0) {
            //         // 昇順
            //         historyListSorted.value = historyList.value.sort((a, b) => {
            //             if (calcDiffDays(a.reserved_date, b.reserved_date) == 0) {
            //                 if (a.boarding_no > b.boarding_no) {
            //                     return 1;
            //                 } else {
            //                     return -1;
            //                 }
            //             } else if (a.reserved_date > b.reserved_date) {
            //                 return 1;
            //             } else {
            //                 return -1;
            //             }
            //         }) as BookingHistoryDetail[];
            //     } else {
            //         // 降順
            //         historyListSorted.value = historyList.value.sort((a, b) => {
            //             if (calcDiffDays(a.reserved_date, b.reserved_date) == 0) {
            //                 if (a.boarding_no > b.boarding_no) {
            //                     return -1;
            //                 } else {
            //                     return 1;
            //                 }
            //             } else if (a.reserved_date > b.reserved_date) {
            //                 return -1;
            //             } else {
            //                 return 1;
            //             }
            //         }) as BookingHistoryDetail[];
            //     }
            // }

            // 出発日順：降順、乗船番号：昇順
            historyListSorted.value = historyList.value.sort((a, b) => {
                if (calcDiffDays(a.dep_date, b.dep_date) == 0) {
                    if (a.boarding_no > b.boarding_no) {
                        return 1
                    } else {
                        return -1
                    }
                } else if (a.dep_date > b.dep_date) {
                    return -1
                } else {
                    return 1
                }
            }) as BookingHistoryDetail[]
            console.log('↑↑↑BookingHistory.sortList↑↑↑')
        }

        // 乗船履歴取得
        const getBookingHistory = async (sort_type: number | null) => {
            historyList.value = []
            await getBookingHistoryCurrent()
            sortList(sort_type)
        }

        // 乗船履歴取得(過去)
        const getBookingHistoryCurrent = async () => {
            console.log('↓↓↓BookingHistory.getBookingHistoryCurrent↓↓↓')
            await apiClient
                .get(`${API_ORDER}/history/past`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        if (isTargetCurrent.value && data[i].canceled != true) {
                            historyList.value.push({
                                dep_date: castStringToDate(data[i].dep_date)!,
                                dep_time: data[i].dep_time,
                                boarding_no: data[i].boarding_no,
                                amount: data[i].amount,
                                seats: data[i].seats,
                                seat_type_name: getSeatTypeNames(data[i].seats),
                                contract_name: data[i].contract_name,
                                sea_route_code: data[i].sea_route_code,
                                sea_route_name: data[i].sea_route_name,
                                ship_name: data[i].ship_name,
                                reserved_date: castStringToDate(data[i].reserved_date)!,
                                booking_status: data[i].booking_status,
                                booking_status_name: data[i].booking_status_name,
                                payment_method: data[i].payment_method,
                                canceled: data[i].canceled,
                                min_transfer_deadline_date: isNull(data[i].min_transfer_deadline_date)
                                    ? null
                                    : castStringToDate(data[i].min_transfer_deadline_date),
                                paid_status_name: data[i].paid_status_name,
                                receipt_flg: data[i].receipt_flg,
                            })
                        }
                        if (isTargetCancel.value && data[i].canceled == true) {
                            historyList.value.push({
                                dep_date: castStringToDate(data[i].dep_date)!,
                                dep_time: data[i].dep_time,
                                boarding_no: data[i].boarding_no,
                                amount: data[i].amount,
                                seats: data[i].seats,
                                seat_type_name: getSeatTypeNames(data[i].seats),
                                contract_name: data[i].contract_name,
                                sea_route_code: data[i].sea_route_code,
                                sea_route_name: data[i].sea_route_name,
                                ship_name: data[i].ship_name,
                                reserved_date: castStringToDate(data[i].reserved_date)!,
                                booking_status: data[i].booking_status,
                                booking_status_name: data[i].booking_status_name,
                                payment_method: data[i].payment_method,
                                canceled: data[i].canceled,
                                min_transfer_deadline_date: isNull(data[i].min_transfer_deadline_date)
                                    ? null
                                    : castStringToDate(data[i].min_transfer_deadline_date),
                                paid_status_name: data[i].paid_status_name,
                                receipt_flg: data[i].receipt_flg,
                            })
                        }
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑BookingHistory.getBookingHistoryCurrent↑↑↑')
        }
        const changeIsTargetCurrent = () => {
            if (isTargetCurrent.value != true) {
                isTargetCurrent.value = true
                isTargetCancel.value = false
                getBookingHistory(null)
            }
        }
        const changeIsTargetCancel = () => {
            if (isTargetCancel.value != true) {
                isTargetCancel.value = true
                isTargetCurrent.value = false
                getBookingHistory(null)
            }
        }

        // 領収書印刷可否
        const canPrintReceipt = (history: BookingHistoryDetail) => {
            if (isTargetCurrent.value) {
                if (castStringToDate(formatDate(history.dep_date, true, 0, 0) + ' ' + history.dep_time)! > new Date()) {
                    return false
                }
            }
            if (history.receipt_flg === 0) {
                return false
            }
            return true
        }
        // 宛先入力表示
        const displayNameSet = async (boarding_no: number) => {
            console.log('↓↓↓BookingHistory.displayNameSet↓↓↓')
            receipt_boarding_no.value = boarding_no
            // location.href = '#nameset';
            $('#btn-nameset-open-modal').click()
            console.log('↑↑↑BookingHistory.displayNameSet↑↑↑')
        }
        // 入力チェック
        const chkReceiptValidation = (): number => {
            let err_count = 0

            // エラー表示初期化
            $('#input__item-destination').removeClass('input__error')
            // 宛先
            if (isNull(destination.value)) {
                $('#input__item-destination').addClass('input__error')
                err_count++
            }

            return err_count
        }
        // 領収書発行
        const printReceipt = async () => {
            console.log('↓↓↓BookingHistory.printReceipt↓↓↓')

            let err_count = 0
            err_count += chkReceiptValidation()
            if (err_count > 0) {
                return
            }

            const postData = {
                boarding_no: receipt_boarding_no.value,
                destination: destination.value,
            }
            let data = await apiClient
                .post(`${API_ORDER}/history/receipt_invoice`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    return data
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    return null
                })
            if (!isNull(data)) {
                let receipt_data = {
                    receipt_no: data.receipt_no,
                    boarding_no: data.boarding_no,
                    date_of_issue: data.date_of_issue,
                    destination: data.destination,
                    dep_date: data.dep_date,
                    sea_route_name: data.sea_route_name,
                    paid_total: data.paid_total,
                    proviso: data.proviso,
                    receipt_print_flg: data.receipt_print_flg,
                    registration_number: data.registration_number,
                    tax_rate: data.tax_rate,
                    tax_amount: data.tax_amount,
                } as ReceiptDetail
                setReceiptData(receipt_data)
                window.open('/booking/print_receipt', '_blank')
                // モーダルを閉じる
                $('#btn-nameset-close-modal').click()
            }

            console.log('↑↑↑BookingHistory.printReceipt↑↑↑')
        }
        // 予約内容確認
        const displayBoardingDetail = async (boarding_no: number, sea_route_code: string) => {
            // 予約内容取得
            try {
                if (!store.state.is_logged_in) {
                    router.push('/member/logout')
                    return
                }

                store.dispatch('setIsProcessing', true)
                await getBoardingDetail(boarding_no, sea_route_code)
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
                if (!store.state.is_logged_in) {
                    router.push('/member/logout')
                } else {
                    // location.href = '#history-confirm';
                    $('#btn-history-confirm-open-modal').click()
                }
            }
        }
        // 予約内容取得
        const getBoardingDetail = async (boarding_no: number, sea_route_code: string) => {
            await Promise.all([
                getBoardingDetailBoarding(boarding_no),
                getBoardingDetailContract(boarding_no, sea_route_code),
                getBoardingDetailPassenger(boarding_no),
                getBoardingDetailCar(boarding_no),
                getBoardingDetailCage(boarding_no),
            ])
            // ドライバー設定反映
            setPassengerCarRelation()
        }
        // 予約内容取得（乗船情報）
        const getBoardingDetailBoarding = async (boarding_no: number) => {
            console.log('↓↓↓BookingCurrent.getBoardingDetailBoarding↓↓↓')
            boarding.value = {} as Boarding
            await apiClient
                .get(`${API_ORDER}/boarding/${boarding_no}`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    boarding.value = {
                        boarding_no: data.boarding_no,
                        ship_schedule_id: data.ship_schedule_id,
                        dep_date: data.dep_date,
                        dep_time: data.dep_time,
                        sea_route_code: data.sea_route_code,
                        sea_route_name: data.sea_route_name,
                        ship_name: data.ship_name,
                        booking_status: data.booking_status,
                        department_code: data.department_code,
                        supplier_joined_code: data.supplier_joined_code,
                        personality_type: data.personality_type,
                        corporate_name: data.corporate_name,
                        represent_passenger_name: data.represent_passenger_name,
                        represent_passenger_tel: data.represent_passenger_tel,
                        represent_passenger_gender: represent_passenger.value?.gender,
                        represent_passenger_birthday: '',
                        represent_passenger_age: represent_passenger.value?.age,
                        represent_passenger_post_code: data.represent_passenger_post_code,
                        represent_passenger_prefectures: data.represent_passenger_prefectures,
                        represent_passenger_address: data.represent_passenger_address,
                        wheelchair: data.wheelchair,
                        wheelchair_lending: data.wheelchair_lending,
                        oxygen_cylinder: data.oxygen_cylinder != 0,
                        oxygen_cylinder_count: data.oxygen_cylinder,
                        pregnant: Boolean(data.pregnant),
                        pregnant_months: data.pregnant_months,
                        dog_count: data.dog_count,
                        cat_count: data.cat_count,
                        other_count: data.other_count,
                        pet_num: data.pet_num,
                        pet_note: data.pet_note,
                        paid_passenger_count: data.paid_passenger_count,
                        paid_vehicle_count: data.paid_vehicle_count,
                        passenger_paid_total: data.passenger_paid_total,
                        vehicle_paid_total: data.vehicle_paid_total,
                        sales_total: data.sales_total,
                        canceled: data.canceled,
                        divide_type_id: data.divide_type_id,
                        deleted: data.deleted,
                        version: data.version,
                        upd_by: data.upd_by,
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑BookingCurrent.getBoardingDetailBoarding↑↑↑')
        }
        // 車椅子
        const displayWheelChair = computed(() => {
            let value = ''
            if (!isNull(boarding.value)) {
                if (boarding.value!.wheelchair) {
                    if (boarding.value!.wheelchair_lending) {
                        value = '貸出希望'
                    } else {
                        value = '持ち込みあり'
                    }
                } else {
                    value = '持ち込みなし'
                }
            }
            return value
        })
        // 妊婦
        const displayPregnant = computed(() => {
            let value = ''
            if (!isNull(boarding.value)) {
                if (boarding.value!.pregnant) {
                    value += '妊婦がいます'
                    value += `（乗船時${boarding.value!.pregnant_months}カ月）`
                } else {
                    value += '妊婦はいません'
                }
            }
            return value
        })
        // 医療用酸素ボンベ
        const displayOxygenCylinder = computed(() => {
            let value = ''
            if (!isNull(boarding.value)) {
                if (!isNull(boarding.value!.oxygen_cylinder_count) && boarding.value!.oxygen_cylinder_count != 0) {
                    value = `持ち込みあり（持ち込み${boarding.value!.oxygen_cylinder_count}本）`
                } else {
                    value = '持ち込みなし'
                }
            }
            return value
        })
        // 予約内容取得（乗船者）
        const getBoardingDetailPassenger = async (boarding_no: number) => {
            console.log('↓↓↓BookingCurrent.getBoardingDetailPassenger↓↓↓')
            passenger_array.value = []
            await apiClient
                .get(`${API_ORDER}/boarding/${boarding_no}/passenger/`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].represent_flg) {
                            // 代表者
                            represent_passenger.value = {
                                id: data[i].id,
                                line_no: data[i].line_no,
                                represent_flg: Boolean(data[i].represent_flg),
                                driver: Boolean(data[i].driver),
                                family_name_katakana: splitFullname(data[i].name_kana)[0],
                                given_name_katakana: splitFullname(data[i].name_kana)[1],
                                birthday_year: null,
                                birthday_month: null,
                                birthday_day: null,
                                age: data[i].age,
                                passenger_type: data[i].passenger_type,
                                grade: data[i].grade,
                                gender: data[i].gender,
                                version: data[i].version,
                                car_row_no: null,
                                boarding_car_id: data[i].boarding_car_id,
                                mcf_member_id: data[i].mcf_member_id,
                                sales_detail_id: data[i].sales_detail_id,
                                regular_price: data[i].regular_price,
                                baf_amount: data[i].baf_amount,
                                reserved_room: !isNull(data[i].reserve_amount),
                                reserved_amount: data[i].reserve_amount,
                                with_pets_flg: !isNull(data[i].with_pets_amount),
                                with_pets_amount: data[i].with_pets_amount,
                                unit_price: data[i].unit_price,
                                discount_id: data[i].discount_id,
                                discount_rate: data[i].discount_rate,
                                discount_amount: data[i].discount_amount,
                                display_unit_price: data[i].display_unit_price,
                                display_baf_amount: data[i].display_baf_amount,
                                display_reserve_amount: data[i].display_reserve_amount,
                                display_with_pets_amount: data[i].display_with_pets_amount,
                                display_change_grade_amount: data[i].display_change_grade_amount,
                                cabin_id: data[i].cabin_id,
                                seat_type_name: data[i].seat_type_name,
                                seat_type: data[i].seat_type,
                                deleted: false,

                                register_companion: false,
                                companion_id: null,
                                unit_price_before: data[i].unit_price,
                                baf_amount_before: data[i].baf_amount,
                                with_pets_amount_before: data[i].with_pets_amount,
                                reserved_amount_before: data[i].reserve_amount,
                            }
                        } else {
                            // 同行者
                            passenger_array.value.push({
                                id: data[i].id,
                                line_no: data[i].line_no,
                                represent_flg: Boolean(data[i].represent_flg),
                                driver: Boolean(data[i].driver),
                                family_name_katakana: splitFullname(data[i].name_kana)[0],
                                given_name_katakana: splitFullname(data[i].name_kana)[1],
                                birthday_year: null,
                                birthday_month: null,
                                birthday_day: null,
                                age: data[i].age,
                                passenger_type: data[i].passenger_type,
                                grade: data[i].grade,
                                gender: data[i].gender,
                                version: data[i].version,
                                car_row_no: null,
                                boarding_car_id: data[i].boarding_car_id,
                                mcf_member_id: data[i].mcf_member_id,
                                sales_detail_id: data[i].sales_detail_id,
                                regular_price: data[i].regular_price,
                                baf_amount: data[i].baf_amount,
                                reserved_room: !isNull(data[i].reserve_amount),
                                reserved_amount: data[i].reserve_amount,
                                with_pets_flg: !isNull(data[i].with_pets_amount),
                                with_pets_amount: data[i].with_pets_amount,
                                unit_price: data[i].unit_price,
                                discount_id: data[i].discount_id,
                                discount_rate: data[i].discount_rate,
                                discount_amount: data[i].discount_amount,
                                display_unit_price: data[i].display_unit_price,
                                display_baf_amount: data[i].display_baf_amount,
                                display_reserve_amount: data[i].display_reserve_amount,
                                display_with_pets_amount: data[i].display_with_pets_amount,
                                display_change_grade_amount: data[i].display_change_grade_amount,
                                cabin_id: data[i].cabin_id,
                                seat_type_name: data[i].seat_type_name,
                                seat_type: data[i].seat_type,
                                deleted: false,

                                register_companion: false,
                                companion_id: null,
                                unit_price_before: data[i].unit_price,
                                baf_amount_before: data[i].baf_amount,
                                with_pets_amount_before: data[i].with_pets_amount,
                                reserved_amount_before: data[i].reserve_amount,
                            })
                        }
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑BookingCurrent.getBoardingDetailPassenger↑↑↑')
        }
        // 予約内容取得（車両）
        const getBoardingDetailCar = async (boarding_no: number) => {
            console.log('↓↓↓BookingCurrent.getBoardingDetailCar↓↓↓')
            car_array.value = []
            vehicle_array.value = []
            await apiClient
                .get(`${API_ORDER}/boarding/${boarding_no}/vehicle`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].car_type_code == CAR_TYPE_PASSENGER) {
                            car_array.value.push({
                                id: data[i].id,
                                line_no: data[i].line_no,
                                car_type_code: data[i].car_type_code,
                                car_length: data[i].car_length,
                                car_width: DEFAULT_CAR_WIDTH,
                                car_place_name: data[i].car_place_name,
                                car_class_number: data[i].car_class_number,
                                car_hiragana: data[i].car_hiragana,
                                car_number: data[i].car_number,
                                note: data[i].note,
                                version: data[i].version,
                                sales_detail_id: data[i].sales_detail_id,
                                regular_price: data[i].regular_price,
                                baf_amount: data[i].baf_amount,
                                load_amount: data[i].load_amount,
                                unload_amount: data[i].unload_amount,
                                unit_price: data[i].unit_price,
                                discount_id: data[i].discount_id,
                                discount_rate: data[i].discount_rate,
                                discount_amount: data[i].discount_amount,
                                display_unit_price: data[i].display_unit_price,
                                display_baf_amount: data[i].display_baf_amount,
                                display_load_amount: data[i].display_load_amount,
                                display_unload_amount: data[i].display_unload_amount,

                                deleted: false,

                                register_car: false,
                                passenger_line_no: null,
                                vehicle_id: null,
                                unit_price_before: data[i].unit_price,
                                baf_amount_before: data[i].baf_amount,
                            })
                        } else {
                            vehicle_array.value.push({
                                id: data[i].id,
                                line_no: data[i].line_no,
                                car_type_code: data[i].car_type_code,
                                car_length: data[i].car_length,
                                car_width: DEFAULT_TWO_WHEEL_WIDTH,
                                car_place_name: data[i].car_place_name,
                                car_class_number: data[i].car_class_number,
                                car_hiragana: data[i].car_hiragana,
                                car_number: data[i].car_number,
                                note: data[i].note,
                                version: data[i].version,
                                sales_detail_id: data[i].sales_detail_id,
                                regular_price: data[i].regular_price,
                                baf_amount: data[i].baf_amount,
                                load_amount: data[i].load_amount,
                                unload_amount: data[i].unload_amount,
                                unit_price: data[i].unit_price,
                                discount_id: data[i].discount_id,
                                discount_rate: data[i].discount_rate,
                                discount_amount: data[i].discount_amount,
                                display_unit_price: data[i].display_unit_price,
                                display_baf_amount: data[i].display_baf_amount,
                                display_load_amount: data[i].display_load_amount,
                                display_unload_amount: data[i].display_unload_amount,

                                deleted: false,

                                register_car: false,
                                passenger_line_no: null,
                                vehicle_id: null,
                                unit_price_before: data[i].unit_price,
                                baf_amount_before: data[i].baf_amount,
                            })
                        }
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑BookingCurrent.getBoardingDetailCar↑↑↑')
        }
        // 予約内容取得（ペットルーム）
        const getBoardingDetailCage = async (boarding_no: number) => {
            console.log('↓↓↓BookingCurrent.getBoardingDetailCage↓↓↓')
            petcage_array.value = []
            await apiClient
                .get(`${API_ORDER}/boarding/${boarding_no}/petcage`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        petcage_array.value.push({
                            id: data[i].id,
                            cage_type: data[i].cage_type,
                            version: data[i].version,
                            deleted: false,
                        })
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                })
            console.log('↑↑↑BookingCurrent.getBoardingDetailCage↑↑↑')
        }
        // ペット種類
        const displayPetNum = computed(() => {
            let values = []
            if (!isNull(boarding.value)) {
                if (boarding.value!.dog_count > 0) {
                    values.push(`犬 ${boarding.value?.dog_count}匹`)
                }
                if (boarding.value!.cat_count > 0) {
                    values.push(`猫 ${boarding.value?.cat_count}匹`)
                }
                if (boarding.value!.other_count > 0) {
                    values.push(`その他 ${boarding.value?.other_count}匹`)
                }
            }
            return values.join(',')
        })
        // ペット区分
        const displayCageNum = computed(() => {
            let values = []
            let large_cnt = 0
            let small_cnt = 0
            if (!isNull(petcage_array.value)) {
                for (let i = 0; i < petcage_array.value.length; i++) {
                    if (petcage_array.value[i].cage_type == CAGE_TYPES_LARGE) {
                        large_cnt++
                    } else if (petcage_array.value[i].cage_type == CAGE_TYPES_SMALL) {
                        small_cnt++
                    }
                }
            }
            if (large_cnt > 0) {
                values.push(`大型 ${large_cnt}部屋`)
            }
            if (small_cnt > 0) {
                values.push(`中小型 ${small_cnt}部屋`)
            }
            return values.join(',')
        })
        // ペットルーム表示有無
        const isShowPetCage = computed(() => {
            if (!isNull(petcage_array.value) && petcage_array.value!.length > 0) {
                return true
            }

            return false
        })
        // 予約内容取得（成約）
        const getBoardingDetailContract = async (boarding_no: number, sea_route_code: string) => {
            console.log('↓↓↓BookingCurrent.getBoardingDetailContract↓↓↓')
            contract.value = await getBoardingContract(String(boarding_no), sea_route_code)
            console.log('↑↑↑BookingCurrent.getBoardingDetailContract↑↑↑')
        }
        const setPassengerCarRelation = () => {
            if (!isNull(represent_passenger.value) && represent_passenger.value!.driver) {
                for (let j = 0; j < car_array.value.length; j++) {
                    if (car_array.value[j].id == represent_passenger.value!.boarding_car_id) {
                        represent_passenger.value!.car_row_no = car_array.value[j].line_no
                        car_array.value[j].passenger_line_no = represent_passenger.value!.line_no
                        break
                    }
                }
            }
            for (let i = 0; i < passenger_array.value.length; i++) {
                if (passenger_array.value[i].driver) {
                    for (let j = 0; j < car_array.value.length; j++) {
                        if (car_array.value[j].id == passenger_array.value[i].boarding_car_id) {
                            passenger_array.value[i].car_row_no = car_array.value[j].line_no
                            car_array.value[j].passenger_line_no = passenger_array.value[i].line_no
                            break
                        }
                    }
                }
            }
        }
        // 表示用料金
        const displayAmount = computed(() => {
            if (isNull(boarding.value?.boarding_no)) {
                return 0
            } else if (isNull(historyList) || historyList.value.length == 0) {
                return 0
            } else {
                for (let i = 0; i < historyList.value.length; i++) {
                    if (historyList.value[i].boarding_no == boarding.value!.boarding_no) {
                        return historyList.value[i].amount
                    }
                }
            }
            return 0
        })
        // 表示用予約内容部屋タイプ名
        const dispSeatTypeName = computed(() => {
            let seat_type_list = []
            let seat_name_list = [] as string[]
            if (!isNull(represent_passenger.value) && !isNull(represent_passenger.value!.seat_type)) {
                seat_type_list.push(represent_passenger.value!.seat_type)
            }
            if (!isNull(passenger_array.value)) {
                for (let i = 0; i < passenger_array.value.length; i++) {
                    if (!isNull(passenger_array.value[i].seat_type)) {
                        seat_type_list.push(passenger_array.value[i].seat_type)
                    }
                }
            }
            seat_type_list = [...new Set(seat_type_list)]
            for (let i = 0; i < seat_type_list.length; i++) {
                seat_name_list.push(getSeatTypeName(seat_type_list[i]))
            }
            return [...new Set(seat_name_list)].join('、')
        })

        // マイページに移動
        const moveMyPage = () => {
            router.push('/member/my_page')
        }

        // 現在の予約へ移動
        const moveBookingCurrent = () => {
            router.push('/booking/current')
        }

        // watch(isTargetCurrent,(next, prev) => {
        //     getBookingHistory(null);
        // });

        // watch(isTargetCancel,(next, prev) => {
        //     getBookingHistory(null);
        // });

        const isCounterPayment = (payment_method: string) => {
            return payment_method == '当日決済'
        }
        // 入金情報確認
        const displayAuthori = async (boarding_no: number, payment_method: string) => {
            // 入金内容取得
            try {
                if (!store.state.is_logged_in) {
                    router.push('/member/logout')
                    return
                }

                store.dispatch('setIsProcessing', true)
                let isErr = false
                await Promise.all([getAuthoriHistory(boarding_no), getAuthoriHistoryDetail(boarding_no, getPaymentMethod(payment_method)!)]).then(
                    (res) => {
                        for (let i = 0; i < res.length; i++) {
                            if (!res[i]) {
                                isErr = true
                                break
                            }
                        }
                    },
                )
                if (isErr) {
                    store.dispatch('setIsProcessing', false)
                    context.emit('displayMessage', {
                        msg_title: '現在の予約',
                        msg: ['入金情報の取得に失敗しました。'],
                        close_btn: true,
                    })
                    return
                }
            } catch (e) {
                console.log(e)
                return
            } finally {
                store.dispatch('setIsProcessing', false)
                if (!store.state.is_logged_in) {
                    router.push('/member/logout')
                } else {
                    // location.href = '#history-bank';
                    $('#btn-history-bank-open-modal').click()
                }
            }
        }
        const getAuthoriHistory = async (boarding_no: number): Promise<boolean> => {
            console.log('↓↓↓BookingCurrent.getAuthori↓↓↓')
            let isError = false
            authori_array.value = []
            await apiClient
                .get(`${API_ORDER}/boarding/${boarding_no}/authori`)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    for (let i = 0; i < data.length; i++) {
                        authori_array.value.push({
                            id: data[i].id,
                            payment_method: data[i].payment_method,
                            type: data[i].type,
                            result: data[i].result,
                            authori_datetime: data[i].authori_datetime,
                            amount: data[i].amount,
                            sales_datetime: data[i].sales_datetime,
                        })
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    isError = false
                })
            console.log('↑↑↑BookingCurrent.getAuthori↑↑↑')
            return !isError
        }
        const getAuthoriHistoryDetail = async (boarding_no: number, payment_method: number): Promise<boolean> => {
            console.log('↓↓↓BookingCurrent.getAuthoriDetail↓↓↓')
            let isError = false
            authori_credit_array.value = []
            authori_payeasy_array.value = []
            authori_convenience_array.value = []
            remittance_array.value = []

            if (payment_method == AUTHORI_PAYMENT_METHOD_CREDIT) {
                // クレジット
                await apiClient
                    .get(`${API_ORDER}/boarding/${boarding_no}/payment_credit`)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        for (let i = 0; i < data.length; i++) {
                            authori_credit_array.value.push({
                                cancel_amount: data[i].cancel_amount,
                                sales_datetime: data[i].sales_datetime,
                                modify_reason: data[i].modify_reason,
                                amount: data[i].amount,
                                seq: data[i].seq,
                            })
                        }
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        console.log(e.message)
                        isError = true
                    })
            } else if (payment_method == AUTHORI_PAYMENT_METHOD_PAY_EASY) {
                // Pay-easy
                await apiClient
                    .get(`${API_ORDER}/boarding/${boarding_no}/payment_payeasy`)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        for (let i = 0; i < data.authori_info.length; i++) {
                            authori_payeasy_array.value.push({
                                transfer_deadline_datetime: formatDate(
                                    castStringToDate(data.authori_info[i].transfer_deadline_datetime),
                                    false,
                                    1,
                                    0,
                                ),
                                confirmed_sales_datetime: formatDate(castStringToDate(data.authori_info[i].confirmed_sales_datetime), false, 1, 0),
                                amount: data.authori_info[i].amount,
                                payeasy_customer_number: data.authori_info[i].payeasy_customer_number,
                                payeasy_collection_agency_number: data.authori_info[i].payeasy_collection_agency_number,
                                payeasy_confirmation_number: data.authori_info[i].payeasy_confirmation_number,
                            })
                        }
                        for (let i = 0; i < data.remittance_info.length; i++) {
                            remittance_array.value.push({
                                ins_date: formatDate(castStringToDate(data.remittance_info[i].ins_date), false, 1, 0),
                                deposit_date: formatDate(castStringToDate(data.remittance_info[i].deposit_date), false, 1, 0),
                                refunded_status: data.remittance_info[i].refunded_status,
                                refunded_status_name: data.remittance_info[i].refunded_status_name,
                                refunded_amount: data.remittance_info[i].refunded_amount,
                                payment_amount: data.remittance_info[i].payment_amount,
                                cancel_amount: data.remittance_info[i].cancel_amount,
                                transfer_fee: data.remittance_info[i].transfer_fee,
                            })
                        }
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        console.log(e.message)
                        isError = true
                    })
            } else if (payment_method == AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE) {
                // コンビニ払い
                await apiClient
                    .get(`${API_ORDER}/boarding/${boarding_no}/payment_convenience`)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        for (let i = 0; i < data.authori_info.length; i++) {
                            authori_convenience_array.value.push({
                                transfer_deadline_datetime: formatDate(
                                    castStringToDate(data.authori_info[i].transfer_deadline_datetime),
                                    false,
                                    1,
                                    0,
                                ),
                                confirmed_sales_datetime: formatDate(castStringToDate(data.authori_info[i].confirmed_sales_datetime), false, 1, 0),
                                amount: data.authori_info[i].amount,
                                convenience_type: data.authori_info[i].convenience_type,
                                convenience_name: data.authori_info[i].convenience_name,
                                convenience_confirmation_number: data.authori_info[i].convenience_confirmation_number,
                                convenience_receipt_number: data.authori_info[i].convenience_receipt_number,
                            })
                        }
                        for (let i = 0; i < data.remittance_info.length; i++) {
                            remittance_array.value.push({
                                ins_date: formatDate(castStringToDate(data.remittance_info[i].ins_date), false, 1, 0),
                                deposit_date: formatDate(castStringToDate(data.remittance_info[i].deposit_date), false, 1, 0),
                                refunded_status: data.remittance_info[i].refunded_status,
                                refunded_status_name: data.remittance_info[i].refunded_status_name,
                                refunded_amount: data.remittance_info[i].refunded_amount,
                                payment_amount: data.remittance_info[i].payment_amount,
                                cancel_amount: data.remittance_info[i].cancel_amount,
                                transfer_fee: data.remittance_info[i].transfer_fee,
                            })
                        }
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        console.log(e.message)
                        isError = true
                    })
            }
            console.log('↑↑↑BookingCurrent.getAuthoriDetail↑↑↑')
            return !isError
        }

        // 部屋タイプ名取得
        const getSeatTypeNames = (seats: string | null): string => {
            if (isNull(seats)) {
                return ''
            }
            let seat_list = [...new Set(seats!.split(','))]
            let seat_name_list = []
            for (let i = 0; i < seat_list.length; i++) {
                seat_name_list.push(getSeatTypeName(Number(seat_list[i])))
            }
            return [...new Set(seat_name_list)].join('、')
        }

        onMounted(async () => {
            console.log('↓↓↓BookingHistory.onMounted↓↓↓')
            await getBookingHistory(null)
            nextTick(() => {
                try {
                    // モーダル再読み込み
                    const remodalInst = $('.remodal').remodal()
                    remodalInst.reload()
                } catch (e) {
                    console.log(e)
                }
            })
            $(document).on('opened', '.remodal', function () {
                setTimeout(function () {
                    $('.confirm__list').scrollTop(0)
                }, 30)
            })
            console.log('↑↑↑BookingHistory.onMounted↑↑↑')
        })

        return {
            historyList,
            historyListSorted,
            authori_array,
            authori_credit_array,
            authori_payeasy_array,
            authori_convenience_array,
            remittance_array,

            historyCount,
            boarding,
            displayWheelChair,
            displayPregnant,
            displayOxygenCylinder,
            represent_passenger,
            contract,
            passenger_array,
            car_array,
            vehicle_array,
            petcage_array,
            displayPetNum,
            displayCageNum,
            isShowPetCage,
            displayAmount,
            dispSeatTypeName,

            receipt_boarding_no,
            destination,

            sortList,
            getBookingHistory,
            changeIsTargetCurrent,
            changeIsTargetCancel,
            canPrintReceipt,
            displayNameSet,
            printReceipt,
            displayBoardingDetail,
            moveMyPage,
            moveBookingCurrent,

            isCounterPayment,
            displayAuthori,

            isNull,
            formatDate,
            formatDateTime,
            castStringToDate,
            convertText,
            getCarTypeName,
            joinFirstNameAndLastName,

            PASSENGER_TYPES,
            GENDERS,
            PREFECTURES,
            AGENT_PAYMENT_STATUSES,
            AUTHORI_PAYMENT_METHOD_CREDIT,
            AUTHORI_PAYMENT_METHOD_PAY_EASY,
            AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE,

            isTargetCurrent,
            isTargetCancel,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'BookingHistory',
    data() {
        return {}
    },
    components: {
        ShipAnimationEnd,
    },
    methods: {},
})
</script>
<style></style>
