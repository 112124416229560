<template>
    <div class="contrast__header">
        <h3 class="contrast__name">西暦和暦対照表</h3>
        <button class="contrast__close" data-remodal-action="cancel">×</button>
    </div>
    <div class="contrast__container">
        <ul class="contrast__list">
            <!-- master -->
            <li class="contrast__item">
                <div class="contrast__item-container">
                    <table class="contrast__data">
                        <tr>
                            <th>昭和1年</th>
                            <td>1926</td>
                        </tr>
                        <tr>
                            <th>昭和2年</th>
                            <td>1927</td>
                        </tr>
                        <tr>
                            <th>昭和3年</th>
                            <td>1928</td>
                        </tr>
                        <tr>
                            <th>昭和4年</th>
                            <td>1929</td>
                        </tr>
                        <tr>
                            <th>昭和5年</th>
                            <td>1930</td>
                        </tr>
                        <tr>
                            <th>昭和6年</th>
                            <td>1931</td>
                        </tr>
                        <tr>
                            <th>昭和7年</th>
                            <td>1932</td>
                        </tr>
                        <tr>
                            <th>昭和8年</th>
                            <td>1933</td>
                        </tr>
                        <tr>
                            <th>昭和9年</th>
                            <td>1934</td>
                        </tr>
                        <tr>
                            <th>昭和10年</th>
                            <td>1935</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>昭和11年</th>
                            <td>1936</td>
                        </tr>
                        <tr>
                            <th>昭和12年</th>
                            <td>1937</td>
                        </tr>
                        <tr>
                            <th>昭和13年</th>
                            <td>1938</td>
                        </tr>
                        <tr>
                            <th>昭和14年</th>
                            <td>1939</td>
                        </tr>
                        <tr>
                            <th>昭和15年</th>
                            <td>1940</td>
                        </tr>
                        <tr>
                            <th>昭和16年</th>
                            <td>1941</td>
                        </tr>
                        <tr>
                            <th>昭和17年</th>
                            <td>1942</td>
                        </tr>
                        <tr>
                            <th>昭和18年</th>
                            <td>1943</td>
                        </tr>
                        <tr>
                            <th>昭和19年</th>
                            <td>1944</td>
                        </tr>
                        <tr>
                            <th>昭和20年</th>
                            <td>1945</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>昭和21年</th>
                            <td>1946</td>
                        </tr>
                        <tr>
                            <th>昭和22年</th>
                            <td>1947</td>
                        </tr>
                        <tr>
                            <th>昭和23年</th>
                            <td>1948</td>
                        </tr>
                        <tr>
                            <th>昭和24年</th>
                            <td>1949</td>
                        </tr>
                        <tr>
                            <th>昭和25年</th>
                            <td>1950</td>
                        </tr>
                        <tr>
                            <th>昭和26年</th>
                            <td>1951</td>
                        </tr>
                        <tr>
                            <th>昭和27年</th>
                            <td>1952</td>
                        </tr>
                        <tr>
                            <th>昭和28年</th>
                            <td>1953</td>
                        </tr>
                        <tr>
                            <th>昭和29年</th>
                            <td>1954</td>
                        </tr>
                        <tr>
                            <th>昭和30年</th>
                            <td>1955</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>昭和31年</th>
                            <td>1956</td>
                        </tr>
                        <tr>
                            <th>昭和32年</th>
                            <td>1957</td>
                        </tr>
                        <tr>
                            <th>昭和33年</th>
                            <td>1958</td>
                        </tr>
                        <tr>
                            <th>昭和34年</th>
                            <td>1959</td>
                        </tr>
                        <tr>
                            <th>昭和35年</th>
                            <td>1960</td>
                        </tr>
                        <tr>
                            <th>昭和36年</th>
                            <td>1961</td>
                        </tr>
                        <tr>
                            <th>昭和37年</th>
                            <td>1962</td>
                        </tr>
                        <tr>
                            <th>昭和38年</th>
                            <td>1963</td>
                        </tr>
                        <tr>
                            <th>昭和39年</th>
                            <td>1964</td>
                        </tr>
                        <tr>
                            <th>昭和40年</th>
                            <td>1965</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>昭和41年</th>
                            <td>1966</td>
                        </tr>
                        <tr>
                            <th>昭和42年</th>
                            <td>1967</td>
                        </tr>
                        <tr>
                            <th>昭和43年</th>
                            <td>1968</td>
                        </tr>
                        <tr>
                            <th>昭和44年</th>
                            <td>1969</td>
                        </tr>
                        <tr>
                            <th>昭和45年</th>
                            <td>1970</td>
                        </tr>
                        <tr>
                            <th>昭和46年</th>
                            <td>1971</td>
                        </tr>
                        <tr>
                            <th>昭和47年</th>
                            <td>1972</td>
                        </tr>
                        <tr>
                            <th>昭和48年</th>
                            <td>1973</td>
                        </tr>
                        <tr>
                            <th>昭和49年</th>
                            <td>1974</td>
                        </tr>
                        <tr>
                            <th>昭和50年</th>
                            <td>1975</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>昭和51年</th>
                            <td>1976</td>
                        </tr>
                        <tr>
                            <th>昭和52年</th>
                            <td>1977</td>
                        </tr>
                        <tr>
                            <th>昭和53年</th>
                            <td>1978</td>
                        </tr>
                        <tr>
                            <th>昭和54年</th>
                            <td>1979</td>
                        </tr>
                        <tr>
                            <th>昭和55年</th>
                            <td>1980</td>
                        </tr>
                        <tr>
                            <th>昭和56年</th>
                            <td>1981</td>
                        </tr>
                        <tr>
                            <th>昭和57年</th>
                            <td>1982</td>
                        </tr>
                        <tr>
                            <th>昭和58年</th>
                            <td>1983</td>
                        </tr>
                        <tr>
                            <th>昭和59年</th>
                            <td>1984</td>
                        </tr>
                        <tr>
                            <th>昭和60年</th>
                            <td>1985</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>昭和61年</th>
                            <td>1986</td>
                        </tr>
                        <tr>
                            <th>昭和62年</th>
                            <td>1987</td>
                        </tr>
                        <tr>
                            <th>昭和63年</th>
                            <td>1988</td>
                        </tr>
                        <tr>
                            <th>昭和64年</th>
                            <td>1989</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>平成1年</th>
                            <td>1989</td>
                        </tr>
                        <tr>
                            <th>平成2年</th>
                            <td>1990</td>
                        </tr>
                        <tr>
                            <th>平成3年</th>
                            <td>1991</td>
                        </tr>
                        <tr>
                            <th>平成4年</th>
                            <td>1992</td>
                        </tr>
                        <tr>
                            <th>平成5年</th>
                            <td>1993</td>
                        </tr>
                        <tr>
                            <th>平成6年</th>
                            <td>1994</td>
                        </tr>
                        <tr>
                            <th>平成7年</th>
                            <td>1995</td>
                        </tr>
                        <tr>
                            <th>平成8年</th>
                            <td>1996</td>
                        </tr>
                        <tr>
                            <th>平成9年</th>
                            <td>1997</td>
                        </tr>
                        <tr>
                            <th>平成10年</th>
                            <td>1998</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>平成11年</th>
                            <td>1999</td>
                        </tr>
                        <tr>
                            <th>平成12年</th>
                            <td>2000</td>
                        </tr>
                        <tr>
                            <th>平成13年</th>
                            <td>2001</td>
                        </tr>
                        <tr>
                            <th>平成14年</th>
                            <td>2002</td>
                        </tr>
                        <tr>
                            <th>平成15年</th>
                            <td>2003</td>
                        </tr>
                        <tr>
                            <th>平成16年</th>
                            <td>2004</td>
                        </tr>
                        <tr>
                            <th>平成17年</th>
                            <td>2005</td>
                        </tr>
                        <tr>
                            <th>平成18年</th>
                            <td>2006</td>
                        </tr>
                        <tr>
                            <th>平成19年</th>
                            <td>2007</td>
                        </tr>
                        <tr>
                            <th>平成20年</th>
                            <td>2008</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>平成21年</th>
                            <td>2009</td>
                        </tr>
                        <tr>
                            <th>平成22年</th>
                            <td>2010</td>
                        </tr>
                        <tr>
                            <th>平成23年</th>
                            <td>2011</td>
                        </tr>
                        <tr>
                            <th>平成24年</th>
                            <td>2012</td>
                        </tr>
                        <tr>
                            <th>平成25年</th>
                            <td>2013</td>
                        </tr>
                        <tr>
                            <th>平成26年</th>
                            <td>2014</td>
                        </tr>
                        <tr>
                            <th>平成27年</th>
                            <td>2015</td>
                        </tr>
                        <tr>
                            <th>平成28年</th>
                            <td>2016</td>
                        </tr>
                        <tr>
                            <th>平成29年</th>
                            <td>2017</td>
                        </tr>
                        <tr>
                            <th>平成30年</th>
                            <td>2018</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>平成31年</th>
                            <td>2019</td>
                        </tr>
                    </table>
                    <table class="contrast__data">
                        <tr>
                            <th>令和1年</th>
                            <td>2019</td>
                        </tr>
                        <tr>
                            <th>令和2年</th>
                            <td>2020</td>
                        </tr>
                        <tr>
                            <th>令和3年</th>
                            <td>2021</td>
                        </tr>
                        <tr>
                            <th>令和4年</th>
                            <td>2022</td>
                        </tr>
                        <tr>
                            <th>令和5年</th>
                            <td>2023</td>
                        </tr>
                        <tr>
                            <th>令和6年</th>
                            <td>2024</td>
                        </tr>
                        <tr>
                            <th>令和7年</th>
                            <td>2025</td>
                        </tr>
                        <tr>
                            <th>令和8年</th>
                            <td>2026</td>
                        </tr>
                        <tr>
                            <th>令和9年</th>
                            <td>2027</td>
                        </tr>
                        <tr>
                            <th>令和10年</th>
                            <td>2028</td>
                        </tr>
                    </table>
                </div>
            </li>
        </ul>
    </div>
    <div class="contrast__btn">
        <button class="btn btn-back" data-remodal-action="cancel">閉じる</button>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    setup(props, context) {
        return {}
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'ContrastCalender',
    data() {
        return {}
    },
    components: {},
    methods: {},
})
</script>
<style></style>
