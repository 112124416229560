<template>
    <div class="confirm__header">
        <h3 class="confirm__name">入力内容のご確認</h3>
        <button id="btn-confirm-close" class="confirm__close" data-remodal-action="cancel">×</button>
    </div>
    <div class="confirm__container">
        <ul class="confirm__list">
            <!-- price -->
            <li class="confirm__item">
                <div class="confirm__item-header">
                    <h3 class="confirm__item-name">運賃</h3>
                </div>
                <div class="confirm__item-container">
                    <div class="confirm__disp">
                        <dl class="confirm__disp-list">
                            <div v-show="!isUpdate" class="confirm__disp-line">
                                <dt class="confirm__disp-name">合計金額</dt>
                                <dd class="confirm__disp-data">{{ disp_amount }}</dd>
                            </div>
                            <div v-show="isUpdate" class="confirm__disp-line">
                                <dt class="confirm__disp-name">変更前運賃</dt>
                                <dd class="confirm__disp-data">{{ booking?.amount_before?.toLocaleString() }}円</dd>
                            </div>
                            <div v-show="isUpdate" class="confirm__disp-line">
                                <dt class="confirm__disp-name">変更後運賃</dt>
                                <dd class="confirm__disp-data">{{ disp_amount }}</dd>
                            </div>
                            <div v-show="isUpdate" class="confirm__disp-line">
                                <dt class="confirm__disp-name">キャンセル料</dt>
                                <dd class="confirm__disp-data-cur-plus">
                                    {{ isDisplayAmount ? cancel_amount.toLocaleString() + '円' : '計算中' }}
                                </dd>
                            </div>
                            <div v-show="isUpdate" class="confirm__disp-line">
                                <dt class="confirm__disp-name">振込手数料</dt>
                                <dd class="confirm__disp-data-cur-plus">
                                    {{ isDisplayAmount ? transfer_charge.toLocaleString() + '円' : '計算中' }}
                                </dd>
                            </div>
                            <div v-show="isUpdate" class="confirm__disp-line">
                                <dt class="confirm__disp-name">差額</dt>
                                <dd :class="`${difference_amount >= 0 ? 'confirm__disp-data-cur-plus' : 'confirm__disp-data-cur-minus'}`">
                                    {{ isDisplayAmount ? difference_amount.toLocaleString() + '円' : '計算中' }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
            <!-- ticket -->
            <li class="confirm__item">
                <div class="confirm__item-header">
                    <h3 class="confirm__item-name">チケット情報</h3>
                </div>
                <div class="confirm__item-container">
                    <div class="confirm__disp">
                        <dl class="confirm__disp-list">
                            <div v-show="isUpdate" class="confirm__disp-line">
                                <dt class="confirm__disp-name">乗船番号</dt>
                                <dd class="confirm__disp-data">{{ boarding?.boarding_no }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">船室</dt>
                                <dd class="confirm__disp-data">
                                    {{ getSeatTypeName(booking?.seat_type) }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">プラン</dt>
                                <dd class="confirm__disp-data">
                                    {{ contract?.contract_name }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">ご利用区間</dt>
                                <dd class="confirm__disp-data">
                                    {{ getSeaRouteCodeName(booking?.sea_route_code) }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">船名</dt>
                                <dd class="confirm__disp-data">{{ boarding?.ship_name }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">乗船日</dt>
                                <dd class="confirm__disp-data">
                                    {{ formatDate(castStringToDate(boarding?.dep_date), false, 2, 1) }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">出航時刻</dt>
                                <dd class="confirm__disp-data">
                                    {{
                                        formatDateTime(
                                            castStringToDate(formatDate(castStringToDate(boarding?.dep_date), true, 0, 0) + ' ' + boarding?.dep_time),
                                            false,
                                            1,
                                        )
                                    }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
            <template v-for="(passenger, idx) in passenger_detail_array" :key="idx">
                <!-- master -->
                <li class="confirm__item" v-show="passenger.represent_flg && passenger.deleted != true">
                    <div class="confirm__item-header">
                        <h3 class="confirm__item-name">代表者</h3>
                        <img class="confirm__item-icon" src="@/images/items/accessory-ancer-01.svg" height="48" width="54" alt="代表者情報アイコン" />
                    </div>
                    <div class="confirm__item-container">
                        <div class="confirm__disp">
                            <dl class="confirm__disp-list">
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">区分</dt>
                                    <dd class="confirm__disp-data">
                                        {{ convertText(PASSENGER_TYPES, passenger?.passenger_type) }}
                                    </dd>
                                </div>
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">お名前</dt>
                                    <dd class="confirm__disp-data">
                                        {{ boarding?.represent_passenger_name }}
                                    </dd>
                                </div>
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">電話番号</dt>
                                    <dd class="confirm__disp-data">
                                        {{ boarding?.represent_passenger_tel }}
                                    </dd>
                                </div>
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">性別</dt>
                                    <dd class="confirm__disp-data">
                                        {{ convertText(GENDERS, passenger.gender) }}
                                    </dd>
                                </div>
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">乗船時年齢</dt>
                                    <dd class="confirm__disp-data">{{ passenger.age }}歳</dd>
                                </div>
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">住所</dt>
                                    <dd class="confirm__disp-data">
                                        {{ boarding?.represent_passenger_post_code }}
                                        {{ convertText(PREFECTURES, boarding?.represent_passenger_prefectures) }}
                                        {{ boarding?.represent_passenger_address }}
                                    </dd>
                                </div>
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">運賃</dt>
                                    <dd class="confirm__disp-data">{{ getPassengerPrice(passenger).toLocaleString() }}円</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </li>
                <!-- passenger -->
                <li class="confirm__item" v-show="!passenger.represent_flg && passenger.deleted != true">
                    <div class="confirm__item-header">
                        <h3 class="confirm__item-name">同行者</h3>
                        <img
                            class="confirm__item-icon"
                            src="@/images/items/accessory-strawhat-01.svg"
                            height="48"
                            width="54"
                            alt="同行者情報アイコン"
                        />
                    </div>
                    <div class="confirm__item-container">
                        <div class="confirm__disp">
                            <dl class="confirm__disp-list">
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">区分</dt>
                                    <dd class="confirm__disp-data">
                                        {{ convertText(PASSENGER_TYPES, passenger?.passenger_type) }}
                                    </dd>
                                </div>
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">お名前</dt>
                                    <dd class="confirm__disp-data">
                                        {{ joinFirstNameAndLastName(passenger.family_name_katakana, passenger.given_name_katakana) }}
                                    </dd>
                                </div>
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">性別</dt>
                                    <dd class="confirm__disp-data">
                                        {{ convertText(GENDERS, passenger.gender) }}
                                    </dd>
                                </div>
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">乗船時年齢</dt>
                                    <dd class="confirm__disp-data">{{ passenger.age }}歳</dd>
                                </div>
                                <div class="confirm__disp-line">
                                    <dt class="confirm__disp-name">運賃</dt>
                                    <dd class="confirm__disp-data">{{ getPassengerPrice(passenger).toLocaleString() }}円</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </li>
            </template>
            <!-- baby -->
            <li class="confirm__item" v-for="(baby, idx) in passenger_detail_baby_array" :key="idx" v-show="baby.deleted != true">
                <div class="confirm__item-header">
                    <h3 class="confirm__item-name">乳幼児</h3>
                    <img class="confirm__item-icon" src="@/images/items/accessory-strawhat-01.svg" height="48" width="54" alt="同行者情報アイコン" />
                </div>
                <div class="confirm__item-container">
                    <div class="confirm__disp">
                        <dl class="confirm__disp-list">
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">区分</dt>
                                <dd class="confirm__disp-data">
                                    {{ convertText(PASSENGER_TYPES, baby?.passenger_type) }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">お名前</dt>
                                <dd class="confirm__disp-data">
                                    {{ joinFirstNameAndLastName(baby.family_name_katakana, baby.given_name_katakana) }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">性別</dt>
                                <dd class="confirm__disp-data">
                                    {{ convertText(GENDERS, baby.gender) }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">乗船時年齢</dt>
                                <dd class="confirm__disp-data">{{ baby.age }}歳</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">運賃</dt>
                                <dd class="confirm__disp-data">{{ getPassengerPrice(baby).toLocaleString() }}円</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
            <!-- car -->
            <li class="confirm__item" v-for="(car, idx) in car_detail_array" :key="idx" v-show="car.deleted != true">
                <div class="confirm__item-header">
                    <h3 class="confirm__item-name">乗用車</h3>
                    <img class="confirm__item-icon" src="@/images/items/accessory-car-01.svg" height="48" width="54" alt="乗用車情報アイコン" />
                </div>
                <div class="confirm__item-container">
                    <div class="confirm__disp">
                        <dl class="confirm__disp-list">
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">運転手</dt>
                                <dd class="confirm__disp-data">
                                    {{ getDriverName(car.passenger_line_no) }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">車長</dt>
                                <dd class="confirm__disp-data">{{ car.car_length }}m未満</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">車種</dt>
                                <dd class="confirm__disp-data">{{ car.note }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">ナンバープレート</dt>
                                <dd class="confirm__disp-data">
                                    {{ car.car_place_name }} {{ car.car_class_number }} {{ car.car_hiragana }} {{ car.car_number }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">運賃</dt>
                                <dd class="confirm__disp-data">{{ getCarPrice(car).toLocaleString() }}円</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
            <!-- bike -->
            <li class="confirm__item" v-for="(two_wheel, idx) in two_wheel_detail_array" :key="idx" v-show="two_wheel.deleted != true">
                <div class="confirm__item-header">
                    <h3 class="confirm__item-name">二輪車</h3>
                    <img class="confirm__item-icon" src="@/images/items/accessory-bike-01.svg" height="48" width="54" alt="二輪車情報アイコン" />
                </div>
                <div class="confirm__item-container">
                    <div class="confirm__disp">
                        <dl class="confirm__disp-list">
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">種別/排気量</dt>
                                <dd class="confirm__disp-data">
                                    {{ getTwoWheelName(two_wheel.car_type_code) }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">メーカー/車名</dt>
                                <dd class="confirm__disp-data">{{ two_wheel.note }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">ナンバープレート</dt>
                                <dd class="confirm__disp-data">
                                    {{ two_wheel.car_place_name }}
                                    {{ two_wheel.car_class_number }} {{ two_wheel.car_hiragana }}
                                    {{ two_wheel.car_number }}
                                </dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">運賃</dt>
                                <dd class="confirm__disp-data">{{ getCarPrice(two_wheel).toLocaleString() }}円</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
            <!-- pet -->
            <li class="confirm__item" v-show="isShowPet">
                <div class="confirm__item-header">
                    <h3 class="confirm__item-name">ペット情報</h3>
                    <img class="confirm__item-icon" src="@/images/items/accessory-pet-01.svg" height="48" width="54" alt="ペット情報アイコン" />
                </div>
                <div class="confirm__item-container">
                    <div class="confirm__disp">
                        <dl class="confirm__disp-list">
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">ペットルーム</dt>
                                <dd class="confirm__disp-data">{{ dispCageNum }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">ペット区分</dt>
                                <dd class="confirm__disp-data">{{ dispPatNum }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">ペット種類</dt>
                                <dd class="confirm__disp-data">{{ boarding?.pet_note }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
            <!-- note -->
            <li class="confirm__item">
                <div class="confirm__item-header">
                    <h3 class="confirm__item-name">特記事項</h3>
                </div>
                <div class="confirm__item-container">
                    <div class="confirm__disp">
                        <dl class="confirm__disp-list">
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">妊婦</dt>
                                <dd class="confirm__disp-data">{{ disp_pregnant }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">車椅子</dt>
                                <dd class="confirm__disp-data">{{ disp_wheelchair }}</dd>
                            </div>
                            <div class="confirm__disp-line">
                                <dt class="confirm__disp-name">医療用酸素ボンベ</dt>
                                <dd class="confirm__disp-data">{{ disp_oxygen_cylinder }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="confirm__btn">
        <button class="btn btn-back" data-remodal-action="cancel">入力画面に戻る</button>
        <button v-show="!isUpdate" class="btn btn-next" type="button" v-on:click="confirmationBooking">決済情報入力へ進む</button>
        <button
            v-show="isUpdate"
            id="btnUpdateBooking"
            :class="`btn ${isDisplayAmount ? 'btn-next' : 'btn-back'}`"
            type="button"
            v-on:click="updateBooking"
        >
            予約を更新する
        </button>
    </div>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { Booking, Boarding, PassengerDetail, CarDetail, CageDetail, Contract } from '@/lib/types'
import {
    API_USERS,
    API_ORDER,
    isNull,
    joinFirstNameAndLastName,
    CAR_TYPE_BICYCLE,
    CAR_TYPE_TWO_WHEEL_SMALL,
    CAR_TYPE_TWO_WHEEL_MEDIUM,
    CAR_TYPE_TWO_WHEEL_LARGE,
    getStockCabin,
    getStockSeat,
    getStockCar,
    getStockBicycle,
    getStockPets,
    getContract,
    DEFAULT_TWO_WHEEL_WIDTH,
    DEFAULT_TWO_WHEEL_LENGTH,
    getPassengerPrice,
    getCarPrice,
    calcDiffDays,
    castStringToDate,
    getTransferCharge,
    setCompanionMaster,
    setVehicleMaster,
    getSeatTypeName,
    getSeaRouteCodeName,
    formatDate,
    formatDateTime,
    CAR_TYPE_PASSENGER,
} from '@/lib/util'
import {
    convertText,
    PASSENGER_TYPES,
    GENDERS,
    PREFECTURES,
    CAGE_TYPES_LARGE,
    CAGE_TYPES_SMALL,
    SEAT_TYPE_D,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    CANCEL_CALCULATION_METHODS_RETIO,
} from '@/lib/constants'
import { getBookingData } from '@/lib/localStorageUtil'

export default defineComponent({
    setup(props, context) {
        const store = useStore()
        const router = useRouter()
        const booking = ref<Booking>()
        const boarding = ref<Boarding>()
        const ship_schedule_id = ref()
        const contract = ref<Contract>()
        const passenger_detail_array = ref<PassengerDetail[]>()
        const passenger_detail_baby_array = ref<PassengerDetail[]>()
        const car_detail_array = ref<CarDetail[]>()
        const two_wheel_detail_array = ref<CarDetail[]>()
        const cage_detail_array = ref<CageDetail[]>([])
        const cancel_amount = ref(0)
        const transfer_charge = ref(0)
        const payment_method = ref()
        const isDisplayAmount = ref(false)
        const car_line_no_matting_table = ref<any[]>([])

        // 更新か？
        const isUpdate = computed(() => {
            // return !isNull(boarding.value?.boarding_no);
            return booking.value?.isUpdate
        })

        // 予約内容表示
        const dispBooking = async () => {
            console.log('↓↓↓ConfirmationBooking.dispBooking↓↓↓')
            let booking_data = getBookingData() as Booking
            booking.value = booking_data
            boarding.value = booking_data.boarding
            passenger_detail_array.value = booking_data.passenger_detail_array
            passenger_detail_baby_array.value = booking_data.passenger_detail_baby_array
            car_detail_array.value = booking_data.car_detail_array
            two_wheel_detail_array.value = booking_data.two_wheel_detail_array
            cage_detail_array.value = booking_data.cage_detail_array
            ship_schedule_id.value = boarding.value.ship_schedule_id

            if (isUpdate.value && difference_amount.value != 0) {
                if (payment_method.value >= 0) {
                    calcCancelAmount()
                }
                if (payment_method.value > 0 && difference_amount.value < 0) {
                    calcTransferCharge()
                }
            }
            isDisplayAmount.value = true
            contract.value = await getContract(booking.value.contract_number, booking.value.dep_date, booking.value.sea_route_code)
            makeCarLineNoMattingTable()
            console.log('↑↑↑ConfirmationBooking.dispBooking↑↑↑')
        }

        // ペットルーム
        const isShowPet = computed(() => {
            if (!isNull(dispCageNum.value)) {
                return true
            }
            return false
        })
        const dispCageNum = computed(() => {
            let values = []

            let cage_large_num = 0
            let cage_small_num = 0
            for (let i = 0; i < cage_detail_array.value!.length; i++) {
                if (cage_detail_array.value![i].deleted) {
                    continue
                }
                if (cage_detail_array.value![i].cage_type == CAGE_TYPES_LARGE) {
                    cage_large_num++
                } else if (cage_detail_array.value![i].cage_type == CAGE_TYPES_SMALL) {
                    cage_small_num++
                }
            }

            if (cage_large_num > 0) {
                values.push(`大型${cage_large_num}部屋`)
            }
            if (cage_small_num > 0) {
                values.push(`中小型${cage_small_num}部屋`)
            }
            return values.join(',')
        })
        const dispPatNum = computed(() => {
            if (isNull(cage_detail_array.value) || cage_detail_array.value.length == 0) {
                return ''
            } else {
                let values = []

                if (boarding.value!.dog_count > 0) {
                    values.push(`犬${boarding.value!.dog_count}匹`)
                }
                if (boarding.value!.cat_count > 0) {
                    values.push(`猫${boarding.value!.cat_count}匹`)
                }
                if (boarding.value!.other_count > 0) {
                    values.push(`その他${boarding.value!.other_count}匹`)
                }
                return values.join(',')
            }
        })

        // 決済情報入力へ進む
        const confirmationBooking = () => {
            location.href = '/booking/settlement_entry'
        }

        // 予約を更新する
        const updateBooking = async () => {
            if (!isDisplayAmount.value) {
                return
            }
            console.log('↓↓↓ConfirmationBooking.updateBooking↓↓↓')
            // 更新か？
            if (!isUpdate.value) {
                return
            }

            let err_count = 0
            try {
                store.dispatch('setIsProcessing', true)
                // 在庫チェック
                if (booking.value!.dep_date_before != booking.value!.dep_date) {
                    if (!(await chkStock())) {
                        err_count++
                    }
                }
                if (err_count == 0) {
                    err_count += await updCompanion()
                }
                // 車両情報更新
                if (err_count == 0) {
                    err_count += await updVehicle()
                }
                // 予約更新
                if (err_count == 0) {
                    let ret = await postUpdateBooking()
                    if (ret) {
                        $('#btn-confirm-close').click()
                        router.push('/booking/complete')
                    }
                }
            } catch (e) {
                console.log(e)
            } finally {
                store.dispatch('setIsProcessing', false)
            }

            console.log('↑↑↑ConfirmationBooking.updateBooking↑↑↑')
        }

        // 入力チェック（在庫チェック）
        const chkStock = async (): Promise<boolean> => {
            console.log('↓↓↓ConfirmationBooking.chkStock↓↓↓')
            let err_count = 0
            let booking = getBookingData() as Booking

            if (booking.seat_type < SEAT_TYPE_D) {
                // 船室
                let stock = await getStockCabin(
                    boarding.value!.divide_type_id!,
                    booking.seat_type,
                    booking.sea_route_code,
                    booking.dep_date,
                    booking.dep_date,
                    booking.headcount_distributions.length,
                )
                ship_schedule_id.value = stock.ship_schedule_id
                if (stock.empty_status == 0) {
                    err_count++
                }
            } else {
                // 船席
                let people_num = 0
                for (let i = 0; i < booking.passenger_detail_array.length; i++) {
                    if (
                        booking.passenger_detail_array[i].passenger_type == PASSENGER_TYPE_ADULT ||
                        booking.passenger_detail_array[i].passenger_type == PASSENGER_TYPE_CHILD
                    ) {
                        people_num++
                    }
                }
                let stock = await getStockSeat(
                    boarding.value!.divide_type_id!,
                    booking.seat_type,
                    booking.sea_route_code,
                    booking.dep_date,
                    booking.dep_date,
                    people_num,
                )
                ship_schedule_id.value = stock.ship_schedule_id
                if (stock.empty_status == 0) {
                    err_count++
                }
            }
            // 乗用車
            let car_num = 0
            car_num += booking.car_3m_num
            car_num += booking.car_4m_num
            car_num += booking.car_5m_num
            car_num += booking.car_6m_num
            if (car_num > 0) {
                let stock_car = await getStockCar(contract.value!.divide_type_id, booking.sea_route_code, booking.dep_date, booking.dep_date, car_num)
                if (stock_car.empty_status == 0) {
                    err_count++
                }
            }
            // 二輪車
            let bike_num = 0
            bike_num += booking.car_bicycle_num
            bike_num += booking.car_two_wheel_small_num
            bike_num += booking.car_two_wheel_medium_num
            bike_num += booking.car_two_wheel_large_num
            if (bike_num > 0) {
                let stock_bike = await getStockBicycle(
                    contract.value!.divide_type_id,
                    booking.sea_route_code,
                    booking.dep_date,
                    booking.dep_date,
                    bike_num,
                )
                if (stock_bike.empty_status == 0) {
                    err_count++
                }
            }
            // ペットルーム
            if (booking.cage_large_num > 0 || booking.cage_small_num > 0) {
                let stock_pets = await getStockPets(booking.sea_route_code, booking.dep_date, booking.cage_large_num, booking.cage_small_num)
                if (booking.cage_large_num > 0 && stock_pets.large_status == 0) {
                    err_count++
                }
                if (booking.cage_small_num && stock_pets.middle_status == 0) {
                    err_count++
                }
            }

            console.log('↑↑↑ConfirmationBooking.chkStock↑↑↑')
            return err_count == 0
        }

        // 同乗者情報更新
        const updCompanion = async (): Promise<number> => {
            let err_count = 0
            let companions = []

            let booking = getBookingData() as Booking
            // 同乗者
            for (let i = 0; i < booking.headcount_distributions.length; i++) {
                for (let j = 0; j < booking.headcount_distributions[i].passenger_detail_array.length; j++) {
                    let passenger = booking.headcount_distributions[i].passenger_detail_array[j]
                    if (passenger.register_companion) {
                        companions.push({
                            id: isNull(passenger.companion_id) ? null : Number(passenger.companion_id), // 同行者ID
                            passenger_type: Number(passenger.passenger_type), // 乗船者区分
                            family_name_katakana: passenger.family_name_katakana, // 氏名（セイ）
                            given_name_katakana: passenger.given_name_katakana, // 氏名（メイ）
                            sex: Number(passenger.gender), // 性別
                            member_id: passenger.mcf_member_id, // 会員ID
                            deleted: false, // 削除フラグ
                        })
                    }
                }
            }
            // 乳幼児
            for (let i = 0; i < booking.passenger_detail_baby_array.length; i++) {
                let passenger = booking.passenger_detail_baby_array[i]
                if (passenger.register_companion) {
                    companions.push({
                        id: isNull(passenger.companion_id) ? null : Number(passenger.companion_id), // 同行者ID
                        passenger_type: Number(passenger.passenger_type), // 乗船者区分
                        family_name_katakana: passenger.family_name_katakana, // 氏名（セイ）
                        given_name_katakana: passenger.given_name_katakana, // 氏名（メイ）
                        sex: Number(passenger.gender), // 性別
                        member_id: passenger.mcf_member_id, // 会員ID
                        deleted: false, // 削除フラグ
                    })
                }
            }

            if (companions.length > 0) {
                const postData = {
                    companion: companions,
                }
                await apiClient
                    .post(`${API_USERS}/companion`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        err_count++
                        console.log(e.message)
                        context.emit('displayMessage', {
                            msg_title: '乗船予約エラー',
                            msg: ['乗船予約に失敗しました。', 'ご予約をやり直してください。'],
                            close_btn: true,
                        })
                    })
                setCompanionMaster(store.state.member_id)
            }

            return err_count
        }

        // 車両情報更新
        const updVehicle = async (): Promise<number> => {
            let err_count = 0
            let vehicles = []

            let booking = getBookingData() as Booking
            for (let i = 0; i < booking.car_detail_array.length; i++) {
                let car = booking.car_detail_array[i]
                if (car.register_car) {
                    vehicles.push({
                        id: isNull(car.vehicle_id) ? null : Number(car.vehicle_id), // 車両ID
                        car_name: car.note, // 車名
                        car_type_code: car.car_type_code, // 車両種別コード
                        number: car.car_number, // No.
                        place_name: car.car_place_name, // 地域
                        class_number: car.car_class_number, // 分類
                        hiragana: car.car_hiragana, // 符号
                        length: Number(car.car_length), // 車長
                        deleted: false, // 削除フラグ
                    })
                }
            }
            for (let i = 0; i < booking.two_wheel_detail_array.length; i++) {
                let car = booking.two_wheel_detail_array[i]
                if (car.register_car) {
                    vehicles.push({
                        id: isNull(car.vehicle_id) ? null : Number(car.vehicle_id), // 車両ID
                        car_name: car.note, // 車名
                        car_type_code: car.car_type_code, // 車両種別コード
                        number: car.car_number, // No.
                        place_name: car.car_place_name, // 地域
                        class_number: car.car_class_number, // 分類
                        hiragana: car.car_hiragana, // 符号
                        length: isNull(car.car_length) ? null : Number(car.car_length), // 車長
                        deleted: false, // 削除フラグ
                    })
                }
            }

            if (vehicles.length > 0) {
                const postData = {
                    vehicle: vehicles,
                }
                await apiClient
                    .post(`${API_USERS}/vehicle`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        err_count++
                        console.log(e.message)
                        context.emit('displayMessage', {
                            msg_title: '乗船予約エラー',
                            msg: ['乗船予約に失敗しました。', 'ご予約をやり直してください。'],
                            close_btn: true,
                        })
                    })
                setVehicleMaster(store.state.member_id)
            }

            return err_count
        }

        // 予約情報更新
        const postUpdateBooking = async (): Promise<boolean> => {
            console.log('↓↓↓ConfirmationBooking.postUpdateBooking↓↓↓')
            let ret = false
            let booking = getBookingData() as Booking
            let rel_boarding = makeBoardingData()
            let rel_passenger = makePassengerData()
            let rel_boarding_car = makeBoardingCarData()
            let rel_pet_cage = makePetCageData()

            const postData = {
                boarding_no: boarding.value?.boarding_no + '',
                contract_number: booking.contract_number,
                ship_schedule_id: ship_schedule_id.value,
                sea_route_code: booking.sea_route_code,
                rel_boarding: rel_boarding,
                rel_passenger: rel_passenger,
                rel_boarding_car: rel_boarding_car,
                rel_pet_cage: rel_pet_cage,
                sales_total: booking.amount,
                add_send_emails: [],
                divide_type_id: contract.value?.divide_type_id,
                version: boarding.value?.version,
            }
            await apiClient
                .put(`${API_ORDER}/update`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    ret = true
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    // if (!isNull(e.response)) {
                    //   // 専用エラー
                    //   context.emit("displayMessage", '乗船予約更新エラー', ['専用のメッセージになる予定です', 'コード：'+(e.response as any).data], true, false, false);
                    //   return;
                    // }
                    // 汎用エラー
                    context.emit('displayMessage', {
                        msg_title: '乗船予約更新エラー',
                        msg: ['乗船予約の更新に失敗しました。', 'ご予約をやり直してください。'],
                        close_btn: true,
                    })
                    return
                })

            console.log('↑↑↑ConfirmationBooking.postUpdateBooking↑↑↑')
            return ret
        }

        // 乗船情報登録データ作成
        const makeBoardingData = () => {
            let booking = getBookingData() as Booking
            let boarding = booking.boarding
            let rel_boarding = {
                dep_date: boarding.dep_date,
                dep_time: boarding.dep_time,
                personality_type: Number(boarding.personality_type),
                corporate_name: boarding.corporate_name,
                represent_passenger_name: boarding.represent_passenger_name,
                represent_passenger_tel: boarding.represent_passenger_tel,
                represent_passenger_prefectures: boarding.represent_passenger_prefectures,
                represent_passenger_post_code: boarding.represent_passenger_post_code,
                represent_passenger_address: boarding.represent_passenger_address,
                wheelchair: boarding.wheelchair,
                wheelchair_lending: boarding.wheelchair_lending,
                pregnant: boarding.pregnant,
                pregnant_months: boarding.pregnant_months ? Number(boarding.pregnant_months) : 0,
                pet_num:
                    (boarding.dog_count ? Number(boarding.dog_count) : 0) +
                    (boarding.cat_count ? Number(boarding.cat_count) : 0) +
                    (boarding.other_count ? Number(boarding.other_count) : 0),
                oxygen_cylinder: boarding.oxygen_cylinder ? Number(boarding.oxygen_cylinder_count) : 0,
                dog_count: boarding.dog_count ? Number(boarding.dog_count) : 0,
                cat_count: boarding.cat_count ? Number(boarding.cat_count) : 0,
                other_count: boarding.other_count ? Number(boarding.other_count) : 0,
                pet_note: boarding.pet_note,
            }

            return rel_boarding
        }

        // 乗船者情報登録データ作成
        const makePassengerData = () => {
            let booking = getBookingData() as Booking
            let rel_passenger = [] as any[]
            let cabin = []
            let max_line_no = 0

            // 同行者
            for (let i = 0; i < booking.headcount_distributions.length; i++) {
                cabin.push([] as any)
                let reserved_flg = false
                let head_passenger_idx = -1
                for (let j = 0; j < booking.headcount_distributions[i].passenger_detail_array.length; j++) {
                    let passenger = booking.headcount_distributions[i].passenger_detail_array[j]
                    if (max_line_no < passenger.line_no!) {
                        max_line_no = passenger.line_no!
                    }

                    cabin[i].push({
                        seat_type: Number(booking.seat_type),
                        grade: Number(booking.grade),
                        passenger_id: passenger.id ? Number(passenger.id) : null,
                        line_no: Number(passenger.line_no),
                        name_kana: joinFirstNameAndLastName(passenger.family_name_katakana, passenger.given_name_katakana),
                        gender: Number(passenger.gender),
                        age: Number(passenger.age),
                        passenger_type: Number(passenger.passenger_type),
                        represent_flg: passenger.represent_flg,
                        with_pets_flg: passenger.with_pets_flg,
                        driver: passenger.driver,
                        reserved_room: passenger.reserved_room ? true : null,
                        // car_row_no: passenger.car_row_no ? Number(passenger.car_row_no) : null,
                        car_row_no: passenger.car_row_no ? getNewCarLineNo(passenger.car_row_no) : null,
                        amount: Number(passenger.unit_price),
                        baf: Number(passenger.baf_amount),
                        reserved_amount: Number(passenger.reserved_amount),
                        with_pets_amount: Number(passenger.with_pets_amount),
                        total: getPassengerPrice(passenger),
                        deleted: passenger.deleted,
                    })
                    if (passenger.deleted == false && passenger.reserved_room) {
                        reserved_flg = true
                    }
                    if (passenger.deleted == false && head_passenger_idx < 0) {
                        head_passenger_idx = j
                    }
                }
                if (reserved_flg == false && head_passenger_idx >= 0) {
                    cabin[i][head_passenger_idx].reserved_room = false
                }
            }
            // 乳幼児
            if (!isNull(booking.passenger_detail_baby_array)) {
                for (let i = 0; i < booking.passenger_detail_baby_array.length; i++) {
                    let passenger = booking.passenger_detail_baby_array[i]
                    passenger.line_no = max_line_no + 1
                    max_line_no = passenger.line_no!
                    cabin[0].push({
                        seat_type: Number(booking.seat_type),
                        grade: Number(booking.grade),
                        passenger_id: passenger.id ? Number(passenger.id) : null,
                        line_no: Number(passenger.line_no),
                        name_kana: joinFirstNameAndLastName(passenger.family_name_katakana, passenger.given_name_katakana),
                        gender: Number(passenger.gender),
                        age: Number(passenger.age),
                        passenger_type: Number(passenger.passenger_type),
                        represent_flg: passenger.represent_flg,
                        with_pets_flg: passenger.with_pets_flg,
                        driver: passenger.driver,
                        reserved_room: passenger.reserved_room ? true : null,
                        // car_row_no: passenger.car_row_no ? Number(passenger.car_row_no) : null,
                        car_row_no: passenger.car_row_no ? getNewCarLineNo(passenger.car_row_no) : null,
                        amount: Number(passenger.unit_price),
                        baf: Number(passenger.baf_amount),
                        reserved_amount: Number(passenger.reserved_amount),
                        with_pets_amount: Number(passenger.with_pets_amount),
                        total: getPassengerPrice(passenger),
                        deleted: passenger.deleted,
                    })
                }
            }

            rel_passenger = cabin
            return rel_passenger
        }

        // 車両情報登録データ作成
        const makeBoardingCarData = () => {
            let booking = getBookingData() as Booking
            let rel_boarding_car = [] as any[]
            let save_car_cnt = 0
            let delete_car_cnt = 0

            for (let i = 0; i < booking.car_detail_array.length; i++) {
                let car = booking.car_detail_array[i]
                rel_boarding_car.push({
                    id: car.id ? Number(car.id) : null,
                    // line_no: Number(car.line_no),
                    line_no: getNewCarLineNo(car.line_no),
                    car_type_code: car.car_type_code,
                    car_length: Number(car.car_length),
                    car_width: Number(car.car_width),
                    car_place_name: car.car_place_name,
                    car_class_number: car.car_class_number,
                    car_hiragana: car.car_hiragana,
                    car_number: car.car_number,
                    note: car.note,
                    amount: Number(car.unit_price),
                    baf: Number(car.baf_amount),
                    total: getCarPrice(car),
                    deleted: car.deleted,
                })
                if (car.deleted) {
                    delete_car_cnt += 1
                } else {
                    save_car_cnt += 1
                }
            }
            for (let i = 0; i < booking.two_wheel_detail_array.length; i++) {
                let car = booking.two_wheel_detail_array[i]
                let new_car_line_no = 0
                if (car.deleted) {
                    new_car_line_no = 99 - delete_car_cnt
                    delete_car_cnt += 1
                } else {
                    save_car_cnt += 1
                    new_car_line_no = save_car_cnt
                }
                rel_boarding_car.push({
                    id: car.id ? Number(car.id) : null,
                    // line_no: Number(car.line_no),
                    // line_no: getNewCarLineNo(car.line_no),
                    line_no: new_car_line_no,
                    car_type_code: car.car_type_code,
                    car_length: DEFAULT_TWO_WHEEL_LENGTH,
                    car_width: DEFAULT_TWO_WHEEL_WIDTH,
                    car_place_name: car.car_place_name,
                    car_class_number: car.car_class_number,
                    car_hiragana: car.car_hiragana,
                    car_number: car.car_number,
                    note: car.note,
                    amount: Number(car.unit_price),
                    baf: Number(car.baf_amount),
                    total: getCarPrice(car),
                    deleted: car.deleted,
                })
            }

            return rel_boarding_car
        }
        // 車両行番号対応表作成
        const makeCarLineNoMattingTable = () => {
            let booking = getBookingData() as Booking
            let save_car_line_no_idx = 1
            let delete_car_line_no_idx = 99
            car_line_no_matting_table.value = [] as any[]
            for (let i = 0; i < booking.car_detail_array.length; i++) {
                let car = booking.car_detail_array[i]
                if (car.deleted) {
                    car_line_no_matting_table.value.push({
                        origin_car_line_no: car.line_no,
                        new_car_line_no: delete_car_line_no_idx,
                    } as any)
                    delete_car_line_no_idx -= 1
                } else {
                    car_line_no_matting_table.value.push({
                        origin_car_line_no: car.line_no,
                        new_car_line_no: save_car_line_no_idx,
                    } as any)
                    save_car_line_no_idx += 1
                }
            }
            // for (let i = 0;i < booking.two_wheel_detail_array.length; i++) {
            //   let car = booking.two_wheel_detail_array[i];
            //   if (car.deleted) {
            //     car_line_no_matting_table.value.push({
            //       origin_car_line_no: car.line_no,
            //       new_car_line_no: delete_car_line_no_idx
            //     } as any);
            //     delete_car_line_no_idx += 1;
            //   } else {
            //     car_line_no_matting_table.value.push({
            //       origin_car_line_no: car.line_no,
            //       new_car_line_no: save_car_line_no_idx
            //     } as any);
            //     save_car_line_no_idx += 1;
            //   }
            // }
        }
        // 車両行番号取得
        const getNewCarLineNo = (origin_car_line_no: number) => {
            let new_car_line_no = null
            for (let i = 0; i < car_line_no_matting_table.value.length; i++) {
                if (car_line_no_matting_table.value[i].origin_car_line_no == origin_car_line_no) {
                    new_car_line_no = car_line_no_matting_table.value[i].new_car_line_no
                    break
                }
            }
            return new_car_line_no
        }

        // ペットケージ登録データ作成
        const makePetCageData = () => {
            let booking = getBookingData() as Booking
            let rel_pet_cage = [] as any[]

            for (let i = 0; i < booking.cage_detail_array.length; i++) {
                let cage = booking.cage_detail_array[i]
                rel_pet_cage.push({
                    id: cage.id ? Number(cage.id) : null,
                    cage_type: Number(cage.cage_type),
                    deleted: cage.deleted,
                })
            }

            return rel_pet_cage
        }

        // 運転手名取得
        const getDriverName = (passenger_line_no: number | null): string => {
            let driver_name = ''
            if (passenger_line_no != null && !isNull(passenger_detail_array.value)) {
                for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                    if (passenger_detail_array.value![i].line_no == passenger_line_no) {
                        driver_name = joinFirstNameAndLastName(
                            passenger_detail_array.value![i].family_name_katakana,
                            passenger_detail_array.value![i].given_name_katakana,
                        )
                    }
                }
            }
            return driver_name
        }
        // 2輪名称取得
        const getTwoWheelName = (car_type_code: string): string => {
            let two_wheel_name = ''
            if (car_type_code == CAR_TYPE_BICYCLE) {
                two_wheel_name = '自転車'
            } else if (car_type_code == CAR_TYPE_TWO_WHEEL_SMALL) {
                two_wheel_name = '125cc未満'
            } else if (car_type_code == CAR_TYPE_TWO_WHEEL_MEDIUM) {
                two_wheel_name = '125cc〜750cc未満'
            } else if (car_type_code == CAR_TYPE_TWO_WHEEL_LARGE) {
                two_wheel_name = '750cc以上'
            }

            return two_wheel_name
        }
        // 車椅子
        const disp_wheelchair = computed(() => {
            if (boarding.value?.wheelchair) {
                if (boarding.value?.wheelchair_lending) {
                    return '貸出希望'
                } else {
                    return '持ち込みあり'
                }
            } else {
                return '持ち込みなし'
            }
        })
        // 妊婦
        const disp_pregnant = computed(() => {
            if (boarding.value?.pregnant) {
                return `妊婦がいます（乗船時${boarding.value?.pregnant_months}カ月）`
            } else {
                return '妊婦はいません'
            }
        })
        // 酸素ボンベ
        const disp_oxygen_cylinder = computed(() => {
            if (!isNull(boarding.value?.oxygen_cylinder_count)) {
                return `持ち込みあり（持ち込み${boarding.value?.oxygen_cylinder_count}本）`
            } else {
                return '持ち込みなし'
            }
        })

        // 運賃合計
        const disp_amount = computed(() => {
            if (isNull(booking.value) || isNull(booking.value!.amount)) {
                return ''
            } else {
                return booking.value!.amount!.toLocaleString() + '円'
            }
        })
        // キャンセル料計算
        const calcCancelAmount = () => {
            let amount = 0
            let isCancelPassenger = false
            let isCancelCar = false
            let isCancelBike = false
            // キャンセル有無確認
            // 乗船者
            if (!isNull(passenger_detail_array.value)) {
                let del_count = 0
                let add_count = 0
                for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                    if (!isNull(passenger_detail_array.value![i].id) && passenger_detail_array.value![i].deleted) {
                        del_count++
                    } else if (isNull(passenger_detail_array.value![i].id) && !passenger_detail_array.value![i].deleted) {
                        add_count++
                    }
                }
                if (del_count > add_count) {
                    isCancelPassenger = true
                }
            }
            // 乗用車
            if (!isNull(car_detail_array.value)) {
                let del_count = 0
                let add_count = 0
                for (let i = 0; i < car_detail_array.value!.length; i++) {
                    if (!isNull(car_detail_array.value![i].id) && car_detail_array.value![i].deleted) {
                        del_count++
                    } else if (isNull(car_detail_array.value![i].id) && !car_detail_array.value![i].deleted) {
                        add_count++
                    }
                }
                if (del_count > add_count) {
                    isCancelCar = true
                }
            }
            // 二輪車
            if (!isNull(two_wheel_detail_array.value)) {
                let del_count = 0
                let add_count = 0
                for (let i = 0; i < two_wheel_detail_array.value!.length; i++) {
                    if (!isNull(two_wheel_detail_array.value![i].id) && two_wheel_detail_array.value![i].deleted) {
                        del_count++
                    } else if (isNull(two_wheel_detail_array.value![i].id) && !two_wheel_detail_array.value![i].deleted) {
                        add_count++
                    }
                }
                if (del_count > add_count) {
                    isCancelBike = true
                }
            }
            if (
                (isCancelPassenger || isCancelCar || isCancelBike) &&
                !isNull(booking.value) &&
                !isNull(booking.value!.amount_before) &&
                !isNull(booking.value?.cancel_policy_before) &&
                booking.value!.amount! < booking.value!.amount_before!
            ) {
                // 適用キャンセルポリシー取得
                let current_cancel_policy = null
                let cancel_day_num = calcDiffDays(castStringToDate(booking.value!.dep_date_before!)!, new Date())
                let current_day = -1

                const cancel_policy_sorted = booking.value!.cancel_policy_before?.sort((a, b) => a.day - b.day)

                for (let i = 0; i < cancel_policy_sorted!.length; i++) {
                    if (!current_cancel_policy) {
                        current_cancel_policy = booking.value!.cancel_policy_before![i]
                        continue
                    }
                    current_day = booking.value!.cancel_policy_before![i].day

                    if (current_day <= cancel_day_num) {
                        current_cancel_policy = booking.value!.cancel_policy_before![i]
                    }
                }
                if (!isNull(current_cancel_policy)) {
                    // キャンセル料計算
                    // 乗船者
                    if (isCancelPassenger) {
                        for (let i = 0; i < passenger_detail_array.value!.length; i++) {
                            if (passenger_detail_array.value![i].deleted) {
                                if (current_cancel_policy!.type == CANCEL_CALCULATION_METHODS_RETIO) {
                                    // ％
                                    amount +=
                                        Math.floor(
                                            ((passenger_detail_array.value![i].unit_price_before! +
                                                passenger_detail_array.value![i].baf_amount_before!) *
                                                current_cancel_policy!.passenger) /
                                                10,
                                        ) * 10
                                } else {
                                    // 円
                                    amount += Math.floor(current_cancel_policy!.passenger! / 10) * 10
                                }
                            }
                        }
                    }
                    // 乗用車
                    if (isCancelCar) {
                        for (let i = 0; i < car_detail_array.value!.length; i++) {
                            if (car_detail_array.value![i].deleted) {
                                if (current_cancel_policy!.type == CANCEL_CALCULATION_METHODS_RETIO) {
                                    // ％
                                    amount +=
                                        Math.floor(
                                            ((car_detail_array.value![i].unit_price_before! + car_detail_array.value![i].baf_amount_before!) *
                                                current_cancel_policy!.vehicle) /
                                                10,
                                        ) * 10
                                } else {
                                    // 円
                                    amount += Math.floor(current_cancel_policy!.vehicle! / 10) * 10
                                }
                            }
                        }
                    }
                    // 二輪車
                    if (isCancelBike) {
                        for (let i = 0; i < two_wheel_detail_array.value!.length; i++) {
                            if (two_wheel_detail_array.value![i].deleted) {
                                if (current_cancel_policy!.type == CANCEL_CALCULATION_METHODS_RETIO) {
                                    // ％
                                    amount +=
                                        Math.floor(
                                            ((two_wheel_detail_array.value![i].unit_price_before! +
                                                two_wheel_detail_array.value![i].baf_amount_before!) *
                                                current_cancel_policy!.vehicle) /
                                                10,
                                        ) * 10
                                } else {
                                    // 円
                                    amount += Math.floor(current_cancel_policy!.vehicle! / 10) * 10
                                }
                            }
                        }
                    }
                }
            }

            cancel_amount.value = amount
        }
        /**
         * オーソリ支払方法取得
         */
        const getPaymentMethod = async (boarding_no: number | null) => {
            console.log('↑↑↑ConfirmationBooking.getPaymentMethod↓↓↓')
            if (!isNull(boarding_no)) {
                payment_method.value = await apiClient
                    .get(`${API_ORDER}/boarding/${boarding_no}/authori`)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        return data[0].payment_method
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        console.log(e.message)
                        return -1
                    })
            } else {
                payment_method.value = -1
            }
            console.log('↑↑↑ConfirmationBooking.getPaymentMethod↑↑↑')
        }
        // 振込手数料計算
        const calcTransferCharge = async () => {
            let amount = 0
            amount = await getTransferCharge(booking.value!.dep_date)

            transfer_charge.value = amount
        }
        // 成約情報を取得（親から呼び出し）
        // 差額
        const difference_amount = computed(() => {
            if (isNull(booking.value) || isNull(booking.value!.amount_before)) {
                return 0
            }
            return booking.value!.amount! + (cancel_amount.value + transfer_charge.value) - booking.value!.amount_before!
        })

        onMounted(() => {
            dispBooking()
        })

        return {
            booking,
            boarding,
            passenger_detail_array,
            passenger_detail_baby_array,
            car_detail_array,
            two_wheel_detail_array,
            cage_detail_array,
            contract,

            isShowPet,
            dispCageNum,
            dispPatNum,
            isUpdate,

            disp_wheelchair,
            disp_pregnant,
            disp_oxygen_cylinder,
            disp_amount,
            cancel_amount,
            transfer_charge,
            difference_amount,
            isDisplayAmount,

            dispBooking,
            confirmationBooking,
            updateBooking,
            getDriverName,
            getTwoWheelName,
            getSeatTypeName,
            getSeaRouteCodeName,
            getPaymentMethod,

            convertText,
            joinFirstNameAndLastName,
            getPassengerPrice,
            getCarPrice,
            castStringToDate,
            formatDate,
            formatDateTime,
        }
    },
    emits: ['displayMessage'],
    name: 'ConfirmationBooking',
    data() {
        return {
            PASSENGER_TYPES: PASSENGER_TYPES,
            GENDERS: GENDERS,
            PREFECTURES: PREFECTURES,
        }
    },
    components: {},
    methods: {},
})
</script>

<style scoped></style>
